<template>
  <div
    :style="{ width: width + 'px', height: height + 'px' }"
    :class="$style.wrapper"
  >
    <div :class="$style.label">
      <p :style="{ color, fontSize: percentSize + 'px' }">{{ percent }}%</p>
      <p :style="{ color: labelColor, fontSize: labelSize + 'px' }">
        <slot />
      </p>
    </div>

    <svg :width="width" :height="height">
      <!--
        loader
        stroke-dasharray and stroke-dashoffset trick explained here
        https://codepen.io/xgad/post/svg-radial-progress-meters
      -->
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius"
        :stroke="color"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="strokeDashoffset"
        :fill="backgroundColor"
      />
    </svg>
  </div>
</template>

<script>
import { THEMES, DEFAULT_THEME } from '@/config/themes';

export default {
  props: {
    radius: {
      type: Number,
      required: false,
      default: 40
    },
    strokeWidth: {
      type: Number,
      required: false,
      default: 5
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#f9f9f9'
    },
    color: {
      type: String,
      required: false,
      default: THEMES[process.env.THEME || DEFAULT_THEME].primaryColor
    },
    labelColor: {
      type: String,
      required: false,
      default: '#b3b3b3'
    },
    labelSize: {
      type: Number,
      required: false,
      default: 15
    },
    percent: {
      type: Number,
      required: false,
      default: 0,
      validator: value => {
        return value > -1 && value < 101;
      }
    }
  },
  computed: {
    width() {
      return 2 * (this.radius + this.strokeWidth);
    },
    height() {
      return 2 * (this.radius + this.strokeWidth);
    },
    cx() {
      return this.width / 2;
    },
    cy() {
      return this.height / 2;
    },
    circumference() {
      // back to school :)
      return 2 * Math.PI * this.radius;
    },
    strokeDashoffset() {
      return this.circumference * (1 - this.percent / 100);
    },
    percentSize() {
      return this.radius * 0.5;
    }
  }
};
</script>

<style lang="scss" module>
@import './CircleProgress.scss';
</style>
