<template>
  <Layout variant="5">
    <template #pageContent>
      <UsersListBy />

      <template>
        <router-view />
      </template>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout/Layout';
import UsersListBy from '@/components/user-profile/UsersListBy/UsersListBy';

export default {
  components: {
    Layout,
    UsersListBy
  }
};
</script>
