<template>
  <div :class="$style.wrapper">
    <div class="box-shadow-white pt-1 pb-1 pl-2 pr-2">
      <span :class="$style.links">
        <ul>
          <li>{{ cohortName }}</li>
          <li>{{ moduleName }}</li>
        </ul>

        <router-link
          v-if="currentUserTeamSlug"
          :to="{
            name: 'team-profile',
            params: { teamSlug: currentUserTeamSlug }
          }"
          data-ignore="1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            xmlns:v="https://vecta.io/nano"
          >
            <path
              d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm24.4 29.3a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zM50 25.1a12.04 12.04 0 0 1 12 12 12.04 12.04 0 0 1-12 12 12.04 12.04 0 0 1-12-12 12.04 12.04 0 0 1 12-12zm-24.9 4.2a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zm60.5 34.5H66.3c1.4 2.8 2.1 5.8 2.1 9a2.26 2.26 0 0 1-2.3 2.3H33.8a2.26 2.26 0 0 1-2.3-2.3c-.1-3.1.7-6.2 2.1-9H14a1.58 1.58 0 0 1-1.6-1.6c-.1-3.6 1.3-7 3.7-9.5s5.7-3.8 9.1-3.8 6.6 1.4 9.1 3.8c1.5 1.7 2.6 3.7 3.2 5.8 3.4-3.2 7.9-4.9 12.5-4.9 4.5-.1 8.8 1.6 12.1 4.7.6-2.1 1.7-4 3.2-5.5 2.4-2.5 5.7-3.8 9.1-3.8s6.7 1.4 9.1 3.8c2.4 2.6 3.7 6 3.7 9.5 0 .8-.7 1.5-1.6 1.5z"
              fill="#cca14d"
            />
          </svg>
          Team Page
          <i class="icon pl-1 icon-chevron-right"></i>
        </router-link>
        <!-- <ListUnits :learningUnits="assignment.learning_units" /> -->
      </span>
      <h2 :title="assignment.slug">{{ assignment.name }}</h2>
      <p v-html="assignment.description" />
      <p :class="$style.teamLink">
        For accessing your Newrow team room, please follow the link to your team
        page.
      </p>

      <div :class="$style.details">
        <div>
          <h6 :class="$style.title">Documents</h6>

          <template v-if="!isLeadSessionAfterStart && isCurrentUserStudent">
            <Splide
              :class="$style.documents"
              v-if="learningResources.length"
              :options="
                isPhoneLayout
                  ? {
                      perPage: 1,
                      hasSliderWrapper: false,
                      gap: '0px',
                      padding: { left: '0px', right: '0px' },
                      arrows: 'slider',
                      trimSpace: true,
                      pagination: false
                    }
                  : {
                      perPage: 2,
                      hasSliderWrapper: false,
                      gap: '7px',
                      padding: { left: '28px', right: '28px' },
                      arrows: 'slider',
                      trimSpace: true,
                      pagination: false
                    }
              "
            >
              <SplideSlide
                v-for="(item, index) in learningResources"
                :key="index"
              >
                <DocumentCard :isEmpty="true" />
              </SplideSlide>
            </Splide>
          </template>
          <template v-else>
            <Splide
              :class="$style.documents"
              v-if="learningResources.length"
              :options="
                isPhoneLayout
                  ? {
                      perPage: 1,
                      hasSliderWrapper: false,
                      gap: '0px',
                      padding: { left: '0px', right: '0px' },
                      arrows: 'slider',
                      trimSpace: true,
                      pagination: false
                    }
                  : {
                      perPage: 2,
                      hasSliderWrapper: false,
                      gap: '7px',
                      padding: { left: '28px', right: '28px' },
                      arrows: 'slider',
                      trimSpace: true,
                      pagination: false
                    }
              "
            >
              <SplideSlide
                v-for="(item, index) in learningResources"
                :key="index"
              >
                <GradingCard :resource="item" />
              </SplideSlide>
            </Splide>

            <p v-else>
              No documents are linked to this assignment.
            </p>
          </template>
        </div>
        <div>
          <template v-if="leadSessionDeadline && leadSession.start_at">
            <template v-if="!isLeadSessionAfterStart">
              <h6 :class="$style.title">
                Starts at
              </h6>
              <p data-ignore="snapshot">{{ leadSessionStart | format }}</p>
            </template>

            <template
              v-if="isLeadSessionAfterStart && !isLeadSessionAfterDeadline"
            >
              <h6 :class="$style.title">
                Deadline
              </h6>
              <p data-ignore="snapshot">{{ leadSessionDeadline | format }}</p>

              <Timer
                :timerStart="leadSession.start_at"
                :deadline="leadSession.end_at"
                :currentTime="nowISO"
                :formats="customTimerFormats"
                :class="$style.deadline"
              >
                <template #before-deadline="{ checkTime }">
                  <strong data-ignore="snapshot">{{ checkTime }}</strong> left
                </template>
                <template #after-deadline>
                  <strong>0Days 0H</strong> left
                </template>
              </Timer>
            </template>

            <template v-if="isLeadSessionAfterDeadline">
              <h6 :class="$style.title">
                Deadline
              </h6>
              <p data-ignore="snapshot">
                {{ leadSession.end_at | format }}
              </p>

              <Timer
                :timerStart="leadSession.start_at"
                :deadline="leadSession.end_at"
                :currentTime="nowISO"
                :formats="customTimerFormats"
                :class="{
                  [$style.deadline]: true,
                  [$style.afterDeadline]: true
                }"
              >
                <template #before-deadline="{ checkTime }">
                  <strong data-ignore="snapshot">{{ checkTime }}</strong> left
                </template>
                <template #after-deadline>
                  <strong>0Days 0H</strong> left
                </template>
              </Timer>
            </template>
          </template>
          <p v-else>
            No deadline data
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import tz from 'moment-timezone';
// import ListUnits from '../ListUnits';

import Timer from '@/components/common/Timer/Timer';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import GradingCard from '../GradingCard';
import DocumentCard from '../DocumentCard';

import {
  isAssignmentAfterDeadline,
  isAssignmentAfterStart,
  getAssignmentDeadline,
  getAssignmentStart
} from '@/helpers/assignments';

// Formats are searched from index 0 and first one that satisfies the condition is used
// bear that in mind when overriding the default formats
import { TIMER_FORMATS } from '@/config/common';

import { GET_RESOURCE } from '@/store/actions.type';

const moduleShortRegexp = /Module\s*(?<moduleNumber>\d)*/i;

export default {
  name: 'Type2Header',
  components: {
    Timer,
    Splide,
    SplideSlide,
    GradingCard,
    DocumentCard
    // ListUnits
  },
  props: {
    assignment: {
      type: Object,
      default: () => {}
    },
    leadSession: {
      type: Object,
      default: () => {}
    },
    nowISO: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      learningResources: []
    };
  },
  filters: {
    format(dateISOString) {
      if (!dateISOString) return '';

      return moment
        .tz(dateISOString, 'Europe/Berlin')
        .format('DD MMM YYYY dddd hh:mm a z');
    }
  },
  watch: {
    assignment() {
      this.fetchLearningResources();
    }
  },
  computed: {
    ...mapGetters([
      'currentUserTeamSlug',
      'isPhoneLayout',
      'isCurrentUserStudent'
    ]),
    cohortName() {
      if (!this.leadSession) return '';
      if (!this.leadSession.cohort) return '';
      if (!this.leadSession.cohort.name) return '';

      return this.leadSession.cohort.name;
    },
    moduleName() {
      if (!this.leadSession) return '';
      if (!this.leadSession.module) return '';
      if (!this.leadSession.module.name) return '';

      // const found = this.leadSession.module.name.match(moduleShortRegexp);

      // if (found) {
      //   return `M${found.groups.moduleNumber}`;
      // }

      return this.leadSession.module.name;
    },
    leadSessionStart() {
      return getAssignmentStart(this.leadSession);
    },
    leadSessionDeadline() {
      return getAssignmentDeadline(this.leadSession);
    },
    isLeadSessionAfterDeadline() {
      return isAssignmentAfterDeadline(this.leadSession, this.nowISO);
    },
    isLeadSessionAfterStart() {
      return isAssignmentAfterStart(this.leadSession, this.nowISO);
    },
    customTimerFormats() {
      let customTimerFormats = [...TIMER_FORMATS];
      customTimerFormats[0] = {
        id: 'more-than-24-hours',
        condition: remainingDurationMs => {
          return (
            remainingDurationMs >= moment.duration(24, 'hours').asMilliseconds()
          );
        },
        formatFunction: (remainingDurationMs, deadlineISO, currentTimeISO) => {
          const fullDays = moment(deadlineISO).diff(
            moment(currentTimeISO),
            'days'
          );

          const remainingHours = moment(deadlineISO).diff(
            moment(currentTimeISO).add(fullDays, 'days'),
            'hours'
          );

          if (remainingHours === 0) {
            return `${fullDays} Days`;
          }

          if (remainingHours === 1) {
            return `${fullDays}Days ${remainingHours}H`;
          }

          if (remainingHours > 1) {
            return `${fullDays}Days ${remainingHours}H`;
          }
        }
      };

      return customTimerFormats;
    }
  },
  methods: {
    ...mapActions([GET_RESOURCE]),
    fetchLearningResources() {
      if (!this.assignment) return;
      if (!this.assignment.learning_resources) return;

      const promises = this.assignment.learning_resources.map(lr =>
        this.GET_RESOURCE(lr.slug)
      );

      Promise.all(promises)
        .then(data => {
          this.learningResources = data;
        })
        .catch(error => {});
    }
  },
  created() {
    this.fetchLearningResources();
  }
};
</script>

<style lang="scss" module>
@import '../styles/Header.scss';
</style>
