<template>
  <a @click="onFlipBookmark" :style="`${wrapperStyle}`" :class="$style.wrapper">
    <template v-if="!displayLoader">
      <img
        v-if="!isBookmarked"
        src="~@/assets/images/icons/library-icons/bookmark_I.svg"
      />
      <img
        v-if="isBookmarked"
        src="~@/assets/images/icons/library-icons/bookmark_II.svg"
      />
    </template>
    <template v-else>
      <i class="icon icon-hourglass-2" />
    </template>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  POST_RESOURCE_BOOKMARK,
  DELETE_RESOURCE_BOOKMARK
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import { ACTION_COMPLETE_TIMEOUT } from '@/config/forms';

export default {
  name: 'ResourceBookmark',
  props: {
    resourceSlug: {
      type: String,
      default: ''
    },
    wrapperStyle: {
      type: String,
      default: 'width: 36px; height: 45px; font-size: 32px;'
    }
  },
  data() {
    return {
      displayLoader: false,
      inProgress: false
    };
  },
  computed: {
    ...mapGetters(['hasResourceBookmark']),
    isBookmarked() {
      return this.hasResourceBookmark(this.resourceSlug);
    }
  },
  methods: {
    onFlipBookmark() {
      if (!this.resourceSlug) return;
      if (this.inProgress) return;

      this.inProgress = true;

      // Let's delay display of loader icon
      const loaderTimeoutId = setTimeout(() => {
        this.displayLoader = true;
      }, ACTION_COMPLETE_TIMEOUT);

      this.$store
        .dispatch(
          this.isBookmarked ? DELETE_RESOURCE_BOOKMARK : POST_RESOURCE_BOOKMARK,
          this.resourceSlug
        )
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          // Clear the timeout and set all to false no matter if successed/failed
          clearTimeout(loaderTimeoutId);
          this.inProgress = false;
          this.displayLoader = false;
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './ResourceBookmark.scss';
</style>
