var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},_vm._l((Object.keys(_vm.container.days)),function(dayISO,dayIndex){
var _obj;
return _c('div',{key:dayIndex,class:( _obj = {}, _obj[_vm.$style.current] = _vm.isCurrent(dayISO), _obj ),style:({
      gridColumnEnd: ("span " + (_vm.container.days[dayISO].length))
    }),attrs:{"data-cy":"day-container"}},[_c('DayHeader',{class:_vm.$style.header,attrs:{"dayISO":dayISO}}),_c('div',{class:_vm.$style.lessons,style:({
        gridTemplateRows: "1fr",
        gridTemplateColumns: ("repeat(" + (_vm.container.days[dayISO].length) + ", 1fr)")
      })},[_c('div',{class:_vm.$style.lesson},[(_vm.isLoading)?_c('InlineLoader'):(_vm.container.type === _vm.CYCLE_TYPES.flex && !_vm.isPhoneLayout)?[_c('div',[_c('p',{staticClass:"text-semibold"},[_vm._v("Flex Week")]),_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1),expression:"1",arg:"3"}],domProps:{"innerHTML":_vm._s(
                'As you travel back from your teaching hospital this week, make sure to schedule time to review the module and finish any open tasks that you may have.'
              )}})])]:(_vm.container.type === _vm.CYCLE_TYPES.flex && _vm.isPhoneLayout)?[_c('div',{class:_vm.$style.note},[_c('div',{class:_vm.$style.typeIcon},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"width":"75","src":require('@/assets/images/icons/empty_flex.svg')}})]),_c('div',[_c('p',{staticClass:"text-semibold"},[_vm._v("Flex Week")])])])]:(_vm.container.type === _vm.CYCLE_TYPES.cr && !_vm.isPhoneLayout)?[_c('div',[_c('p',{staticClass:"text-semibold",class:_vm.$style.title},[_vm._v(" Clinical Rotation ")]),(!_vm.isPhoneLayout)?_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1),expression:"1",arg:"3"}]},[_vm._v(" This is your clinical rotation phase. Class may be out, but the learning doesn’t stop! Head to the library to find videos and readings that will enhance your clinical experience. ")]):_vm._e(),_c('span',{class:_vm.$style.footerLink},[_c('router-link',{attrs:{"to":{
                  name: 'learning-units-current'
                }}},[_vm._v(" Check the Units... ")])],1)])]:(_vm.container.type === _vm.CYCLE_TYPES.cr && _vm.isPhoneLayout)?[_c('div',{class:_vm.$style.note},[_c('div',{class:_vm.$style.typeIcon},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"width":"75","src":require('@/assets/images/icons/empty_cr.svg')}})]),_c('div',[_c('p',{staticClass:"text-semibold"},[_vm._v("Clinical Rotation")]),_c('span',{class:_vm.$style.footerLink},[_c('router-link',{attrs:{"to":{
                    name: 'learning-units-current'
                  }}},[_vm._v(" Check Units ")])],1)])])]:(_vm.container.type === _vm.CYCLE_TYPES.holiday && !_vm.isPhoneLayout)?[_c('div',[_c('p',{staticClass:"text-semibold"},[_vm._v("It's Holiday!")]),_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1),expression:"1",arg:"3"}]},[_vm._v(" More time to study, yay! ")])])]:(_vm.container.type === _vm.CYCLE_TYPES.holiday && _vm.isPhoneLayout)?[_c('div',{class:_vm.$style.note},[_c('div',{class:_vm.$style.typeIcon},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"width":"75","src":require('@/assets/images/icons/empty_holiday.svg')}})]),_c('div',[_c('p',{staticClass:"text-semibold"},[_vm._v("It's Holiday!")])])])]:_c('p',{class:_vm.$style.centered},[_vm._v(" No sessions today. ")])],2)])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }