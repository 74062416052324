<template>
  <div>
    <FormInput
      v-if="conversations.length > 5"
      data-ref="search"
      :large="true"
      v-model="searchTerm"
      type="text"
      placeholder="Search a conversation"
      :class="$style.searchInput"
    />
    <ConversationsListDesktop
      :currentUserId="currentUserId"
      :currentConversationId="currentConversationId"
      :conversations="filterConversations"
      @select_conversation="onSelectConversation"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { GET_USER_PROFILES, OPEN_CONVERSATION } from '@/store/actions.type';
import { getUserFullName } from '@/helpers/users';

import ConversationsListDesktop from './ConversationsListDesktop';

export default {
  name: 'ConversationList',
  components: {
    ConversationsListDesktop
  },
  data() {
    return {
      searchTerm: '',
      searchRegExp: undefined
    };
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm) {
        this.searchRegExp = undefined;
      } else {
        this.searchRegExp = new RegExp(newSearchTerm, 'ig');
      }
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapGetters('chats', ['conversations', 'currentConversationId']),
    filterConversations() {
      if (this.searchTerm === '') return this.conversations;

      if (!Array.isArray(this.conversations)) return [];

      const foundConversations = this.conversations.filter(conversation => {
        const participantsFullNames = conversation.participants.map(user =>
          getUserFullName(user)
        );

        const foundMatch = participantsFullNames.find(name =>
          this.searchRegExp.test(name)
        );

        return foundMatch ? true : false;
      });

      return foundConversations;
    }
  },
  methods: {
    ...mapActions('chats', [OPEN_CONVERSATION]),
    async onSelectConversation(conversationId) {
      try {
        await this.OPEN_CONVERSATION(conversationId);
        this.$router
          .replace({
            name: 'conversation-existing',
            params: {
              conversationId
            }
          })
          .catch(error => void error);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    }
  },
  async created() {
    await this.$store
      .dispatch(GET_USER_PROFILES, { inactive: true })
      .catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
  }
};
</script>

<style lang="scss" module>
@import './ConversationsList.scss';
</style>
