<template>
  <div :class="$style.wrapper">
    <div :class="$style.wrapperTop">
      <div :class="$style.photo">
        <Avatar :user="currentUserProfile" size="55px" />
        <span :class="$style.dot" v-if="isUserOnline" />
      </div>
      <div id="menu-descr" :class="$style.about">
        <h6>
          <router-link
            :to="{ name: 'user-profile', params: { userId: currentUserId } }"
          >
            <UserName :user="currentUserProfile" />
          </router-link>
        </h6>
      </div>
      <div :class="$style.setting">
        <router-link
          :to="{
            name: 'user-profile-settings',
            params: { userId: currentUserId }
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            xmlns:v="https://vecta.io/nano"
          >
            <path
              d="M93.5 60.8l-5.7-3.3.2-1.3c.3-2 .5-4.1.5-6.2s-.2-4.2-.5-6.2l-.2-1.3 5.6-3.3c1.5-.8 2.7-2.1 3.4-3.7.8-2 .6-4.2-.4-6.1l-5.1-8.8c-1.9-3.3-6.2-4.5-9.6-2.6l-6 3.5-1-.8c-3.2-2.7-6.8-4.7-10.8-6.2l-1.2-.4V7.5c0-4-3.3-7.3-7.3-7.3h-9.6c-4 0-7.3 3.3-7.3 7.3v6.6l-1.2.4c-3.9 1.5-7.5 3.6-10.8 6.2l-1 .8-6-3.5a6.99 6.99 0 0 0-9.5 2.6l-5 8.9c-1.1 1.9-1.3 4.1-.5 6.1.6 1.6 1.8 2.9 3.2 3.6l5.8 3.3-.2 1.3c-.3 2-.5 4.1-.5 6.2s.2 4.2.5 6.2l.2 1.3-5.7 3.3c-1.5.8-2.7 2.1-3.4 3.7-.8 2-.6 4.2.4 6.1l5.1 8.9c1 1.6 2.5 2.7 4.2 3.1 1.8.5 3.7.3 5.4-.6l6-3.5 1 .8c3.2 2.6 6.8 4.7 10.8 6.2l1.2.4v6.6c0 4 3.3 7.3 7.3 7.3h9.6c4 0 7.3-3.3 7.3-7.3v-6.6l1.2-.4c3.9-1.5 7.5-3.6 10.7-6.2l1-.8 6.1 3.5c1.6.9 3.5 1.1 5.2.7 1.8-.5 3.4-1.7 4.3-3.3l5.1-8.8c1.1-1.9 1.3-4.1.4-6.1-.6-1.6-1.7-2.9-3.2-3.7zM50.7 67a16.99 16.99 0 0 1-17-17c0-9.4 7.7-17 17-17a16.99 16.99 0 1 1 0 34z"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <div :class="$style.links">
      <router-link
        v-if="currentUserTeamSlug"
        :to="{
          name: 'team-profile',
          params: { teamSlug: currentUserTeamSlug }
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          xmlns:v="https://vecta.io/nano"
        >
          <path
            d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm24.4 29.3a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zM50 25.1a12.04 12.04 0 0 1 12 12 12.04 12.04 0 0 1-12 12 12.04 12.04 0 0 1-12-12 12.04 12.04 0 0 1 12-12zm-24.9 4.2a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zm60.5 34.5H66.3c1.4 2.8 2.1 5.8 2.1 9a2.26 2.26 0 0 1-2.3 2.3H33.8a2.26 2.26 0 0 1-2.3-2.3c-.1-3.1.7-6.2 2.1-9H14a1.58 1.58 0 0 1-1.6-1.6c-.1-3.6 1.3-7 3.7-9.5s5.7-3.8 9.1-3.8 6.6 1.4 9.1 3.8c1.5 1.7 2.6 3.7 3.2 5.8 3.4-3.2 7.9-4.9 12.5-4.9 4.5-.1 8.8 1.6 12.1 4.7.6-2.1 1.7-4 3.2-5.5 2.4-2.5 5.7-3.8 9.1-3.8s6.7 1.4 9.1 3.8c2.4 2.6 3.7 6 3.7 9.5 0 .8-.7 1.5-1.6 1.5z"
            fill="#cca14d"
          />
        </svg>
        Team Page
      </router-link>
      <router-link to="/contacts/students">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          xmlns:v="https://vecta.io/nano"
        >
          <path
            d="M97.8 40.1a2.22 2.22 0 0 0 2.2-2.2V25.3a2.22 2.22 0 0 0-2.2-2.2l-7.7-.1h1V11.1C91.1 5 86.1 0 79.9 0H11.1C5 0 0 5 0 11.1v77.8C0 95 5 100 11.1 100H80c6.1 0 11.1-5 11.1-11.1v-19h6.7a2.22 2.22 0 0 0 2.2-2.2V55.1a2.22 2.22 0 0 0-2.2-2.2h-6.7V40.1h6.7zM45.5 21.5c7.6 0 13.7 6.1 13.7 13.7s-6.1 13.7-13.7 13.7-13.7-6.2-13.7-13.7c0-7.6 6.1-13.7 13.7-13.7zm18.4 57.1H27.1c-1.5-.1-2.7-1.2-2.7-2.7 0-5.8 2.2-11.4 6.2-15.6 3.9-4 9.3-6.2 14.9-6.2s11 2.2 14.9 6.2c4.1 4.2 6.3 9.8 6.2 15.6-.1 1.5-1.2 2.7-2.7 2.7z"
            fill="#cb9f53"
          />
        </svg>
        Contact Book
      </router-link>
      <router-link
        to="/admin"
        v-if="isCurrentUserTeachingStaff"
        :class="$style.adminButton"
        >Admin Page</router-link
      >
    </div>
    <div :class="$style.buttons">
      <StatusSwitcher />
      <a
        data-ref="logoutBtn"
        href="#"
        @click.prevent="onLogoutClick"
        :class="$style.logout"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          fill="#cca14e"
          xmlns:v="https://vecta.io/nano"
        >
          <path
            d="M266.5 431.3H56c-15.2 0-27.5-12.3-27.5-27.5V85.9c0-15.2 12.3-27.5 27.5-27.5h210.5c15.2 0 27.5 12.3 27.5 27.5v63.8c0 15.2-12.3 27.5-27.5 27.5S239 164.9 239 149.7v-36.3H83.5v262.8H239v-30c0-15.2 12.3-27.5 27.5-27.5S294 331 294 346.2v57.5c.1 15.3-12.2 27.6-27.5 27.6zM444 279.9H219.4c-15.2 0-27.5-12.3-27.5-27.5s12.3-27.5 27.5-27.5h155L341.5 194c-11.1-10.4-11.7-27.8-1.3-38.9s27.8-11.7 38.9-1.3l83.7 78.5c8.2 7.7 10.9 19.7 6.8 30.2-4.2 10.5-14.3 17.4-25.6 17.4z"
          />
          <path
            d="M366.3 361.4c-6.8 0-13.7-2.5-19-7.6-11-10.5-11.4-27.9-.9-38.9l77.7-81.5c10.5-11 27.9-11.4 38.9-.9s11.4 27.9.9 38.9l-77.7 81.5c-5.4 5.6-12.7 8.5-19.9 8.5z"
          />
        </svg>
        LOG OUT
      </a>
    </div>
    <div
      :class="{
        [$style.usersList]: true,
        [$style.scrollable]: shouldHaveScroll
      }"
      :style="listWrapperStyle"
      v-if="filteredUsersLength > 0 && isUserOnline"
    >
      <OnlineUserList
        @open_conversation="onOpenConversation"
        :users="filteredUsers"
        :size="`${AVATAR_SIZE}px`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Avatar from '@/components/common/User/Avatar';
import OnlineUserList from '@/components/common/OnlineUserList/OnlineUserList';
import StatusSwitcher from '@/components/common/StatusSwitcher/StatusSwitcher';

import {
  GET_CURRENT_USER_PROFILE,
  GET_TEAM_PROFILE,
  LOGOUT,
  CHANGE_USER_STATUS,
  GET_CONVERSATIONS,
  OPEN_CONVERSATION,
  OPEN_PLACEHOLDER_CONVERSATION
} from '@/store/actions.type';

import { PLACEHOLDER_ID, CONVERSATION_TYPES } from '@/config/conversations';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Avatar,
    OnlineUserList,
    StatusSwitcher
  },
  data() {
    return {
      filterUserStatus: ['online']
    };
  },
  computed: {
    ...mapGetters('chats', ['conversationsWithParticipants']),
    ...mapGetters([
      'currentUserId',
      'currentUserProfile',
      'currentTeamProfile',
      'currentUserTeamSlug',
      'currentUserCohorts',
      'isCurrentUserTeachingStaff'
    ]),
    filteredUsersLength() {
      if (!this.filteredUsers) return 0;

      return this.filteredUsers.length;
    },
    shouldHaveScroll() {
      return this.filteredUsersLength > this.MAX_USERS_IN_LIST;
    },
    listWrapperStyle() {
      if (this.shouldHaveScroll) {
        const totalListHeight =
          this.MAX_USERS_IN_LIST * (this.AVATAR_SIZE * 1.5); // 1.5 is there to compensate for padding/margin around avatars
        return `height: ${totalListHeight}px`;
      }

      return `height: auto`;
    },
    filteredUsers() {
      if (
        !this.currentTeamProfile ||
        !this.currentTeamProfile.members ||
        !Array.isArray(this.currentTeamProfile.members)
      ) {
        return [];
      }

      return this.currentTeamProfile.members
        .filter(member => this.filterUserStatus.includes(member.status))
        .filter(member => member.user_id !== this.currentUserId);
    },
    isUserOnline() {
      if (!this.currentUserId) return false;

      if (
        !this.currentTeamProfile ||
        !this.currentTeamProfile.members ||
        !Array.isArray(this.currentTeamProfile.members)
      ) {
        return false;
      }

      const currentUserInTeam = this.currentTeamProfile.members.find(
        member => member.user_id === this.currentUserId
      );

      if (!currentUserInTeam) return false;

      if (currentUserInTeam.status === 'online') return true;

      return false;
    }
  },
  async created() {
    this.MAX_USERS_IN_LIST = 5;
    this.AVATAR_SIZE = 40; // in pixels

    try {
      await this.$store.dispatch(GET_CURRENT_USER_PROFILE);

      if (this.currentUserTeamSlug) {
        await this.$store.dispatch(GET_TEAM_PROFILE, this.currentUserTeamSlug);
      }
      // Fetch conversations in order to be able to decide later if
      // new or existing conversation should be used after message
      // icon clicked
      await this.GET_CONVERSATIONS();
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  },
  methods: {
    ...mapActions('chats', [
      GET_CONVERSATIONS,
      OPEN_CONVERSATION,
      OPEN_PLACEHOLDER_CONVERSATION
    ]),
    onLogoutClick() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    onOpenConversation(userProfile) {
      const found = this.conversationsWithParticipants([
        this.currentUserId,
        userProfile.user_id
      ]);

      if (found.length === 0) {
        this.OPEN_PLACEHOLDER_CONVERSATION({
          type: CONVERSATION_TYPES.ADHOC,
          participant_ids: [this.currentUserId, userProfile.user_id],
          participants: [this.currentUserProfile, userProfile]
        })
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId: PLACEHOLDER_ID
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      } else {
        const conversationId = found[0].conversation_id;
        if (!conversationId) return;

        this.OPEN_CONVERSATION(conversationId)
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './UserMenu.scss';
</style>
