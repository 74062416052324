import mergeWith from 'lodash.mergewith';

import { addObjToArray } from '@/helpers/common';
import ApiService from '@/api/api.service';

import {
  GET_TEAMS,
  GET_TEAM_PROFILE,
  UPDATE_TEAM_PROFILE
} from './actions.type';

import {
  RESET_STATE,
  SET_TEAMS,
  ADD_TEAM_PROFILE,
  ADD_TEAM_PROFILE_SETTINGS,
  PURGE_TEAM_PROFILE_SETTINGS
} from './mutations.type';

export const state = {
  teams: [],
  teamProfiles: [],
  teamSettings: {
    name: null,
    description: null
  }
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  teams(state) {
    return state.teams;
  },

  teamProfiles(state) {
    return state.teamProfiles;
  },

  teamProfile: state => teamSlug => {
    if (!teamSlug) {
      return {};
    }

    return state.teamProfiles.find(team => team.slug === teamSlug) || {};
  },

  currentTeamProfile(state, getters) {
    return getters.teamProfile(getters.currentUserTeamSlug);
  },

  teamProfileSettings: state => teamSlug => {
    if (!teamSlug) {
      return initialStateCopy.teamSettings;
    }

    const team = state.teamProfiles.find(team => team.slug === teamSlug) || {};

    return mergeWith(
      {},
      team,
      state.teamSettings,
      (teamProperty, teamSettingsProperty) => {
        return teamSettingsProperty === null
          ? teamProperty
          : teamSettingsProperty;
      }
    );
  },

  cohortTeams: state => cohortId => {
    return state.teams.filter(team => team.cohort_id === cohortId);
  },

  cohortUsersByTeams: (state, getters) => cohortId => {
    return getters.cohortTeams(cohortId).reduce((memo, team) => {
      if (!memo[team.slug]) {
        memo[team.slug] = [];
      }

      memo[team.slug] = getters.teamMembers(team.slug);

      return memo;
    }, {});
  },

  cohortUsers: (state, getters) => cohortId => {
    return getters.cohortTeams(cohortId).reduce((memo, team) => {
      return [...memo, ...getters.teamMembers(team.slug)];
    }, []);
  },

  teamMembersCount: state => teamSlug => {
    const team = state.teamProfiles.find(team => team.slug === teamSlug) || {};

    return (team.members && team.members.length) || 0;
  },
  teamAvatarUrl: state => teamSlug => {
    if (!teamSlug) {
      return null;
    }

    const team = state.teamProfiles.find(team => team.slug === teamSlug) || {};

    let foundAvatar = {};

    if (team.avatars && team.avatars.length > 0) {
      foundAvatar = team.avatars.find(
        avatar => avatar.size === 'team_background'
      );
    }

    if (foundAvatar && foundAvatar.file && foundAvatar.file.state === 'ok') {
      return foundAvatar.file.path;
    }

    return null;
  },
  teamMembers: state => teamSlug => {
    if (!teamSlug) {
      return [];
    }

    const team = state.teamProfiles.find(team => team.slug === teamSlug) || {};

    if (!team.members) {
      return [];
    }

    return team.members.map(m => {
      // API gives us team members profiles in different format than
      // for another endpoints related to user profiles
      // In our components, we rely on consistent user profile,
      // that's why this map is required until API is updated
      // see CAN-190
      let member = { ...m };
      let avatar = member.avatar_file_path;

      delete member.avatar_file_path;

      member.avatars = {
        normal: avatar
      };

      return member;
    });
  },
  teamRooms: state => teamSlug => {
    if (!teamSlug) {
      return [];
    }

    const team = state.teamProfiles.find(team => team.slug === teamSlug) || {};

    if (!team.rooms) {
      return [];
    }

    return team.rooms;
  }
};

export const actions = {
  [GET_TEAMS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getTeams(params)
        .then(({ data }) => {
          context.commit(SET_TEAMS, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_TEAM_PROFILE](context, teamSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getTeamProfile(teamSlug)
        .then(({ data }) => {
          context.commit(ADD_TEAM_PROFILE, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_TEAM_PROFILE](context, { teamSlug, profileData }) {
    return new Promise((resolve, reject) => {
      ApiService.updateTeamProfile(teamSlug, profileData)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },

  [SET_TEAMS](state, teams) {
    state.teams = teams;
  },

  [ADD_TEAM_PROFILE](state, team) {
    // push a team to teams if it's not there yet
    // otherwise overwrite an existing team with a new, up-to-date, team
    state.teamProfiles = addObjToArray(state.teamProfiles, team, 'slug');
  },

  [ADD_TEAM_PROFILE_SETTINGS](state, teamSettings) {
    state.teamSettings = {
      ...state.teamSettings,
      ...teamSettings
    };
  },

  [PURGE_TEAM_PROFILE_SETTINGS](state) {
    state.teamSettings = initialStateCopy.teamSettings;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
