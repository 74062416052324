<template>
  <Layout variant="8">
    <template v-if="isCurrentUserTeachingStaff" #pageHero> </template>
    <template #pageSideA>
      <div
        :class="{
          [$style.side]: true,
          [$style.stickyNavigation]: !userSettingValue(SETTING_CODES.FIXED_NAV)
        }"
      >
        <SideWidgetBlogOverview />
      </div>
    </template>

    <template #pageContent>
      <h6 class=" main-title">
        Article Overview
      </h6>

      <BlogPostPlaceholder v-if="!previews.length" />
      <div v-else id="scroll" :class="$style.blogPosts">
        <BlogPostPreview
          v-for="item in previews"
          :key="item.post_id"
          :blogPost="item"
          class="box-shadow-white"
        />
        <div :class="$style.loadMore">
          <p class="button-text" @click="loadMore" v-if="hasMoreToLoad">
            See more…
          </p>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { SETTING_CODES } from '@/config/userSettings';
import { mapGetters, mapMutations } from 'vuex';
import { GET_BLOG_POSTS, GET_CURRENT_USER_PROFILE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { toServerFormat } from '@/helpers/datetime';
import { addObjToArray } from '@/helpers/common';
import Layout from '@/views/Layout/Layout';
import BlogPostPreview from '@/components/blog/BlogPostPreview/BlogPostPreview';
import BlogPostPlaceholder from '@/components/blog/BlogPostPlaceholder/BlogPostPlaceholder';
import SideWidgetBlogOverview from '@/components/blog/SideWidgetBlogOverview/SideWidgetBlogOverview';

export default {
  name: 'BlogList',
  components: {
    Layout,
    BlogPostPreview,
    SideWidgetBlogOverview,
    BlogPostPlaceholder
  },
  props: {
    itemsPerLoad: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      since: null,
      hasMoreToLoad: true,
      items: []
    };
  },
  computed: {
    ...mapGetters([
      'currentUserProfile',
      'isCurrentUserTeachingStaff',
      'userSettingValue'
    ]),
    previews() {
      const previews = this.items.reduce(
        (memo, item) => {
          if (item.published_at) {
            memo.published.push(item);
          } else {
            memo.drafts.push(item);
          }

          return memo;
        },
        { published: [], drafts: [] }
      );

      return [
        ...previews.drafts,
        ...previews.published.toSorted(
          (a, b) => Date.parse(b.published_at) - Date.parse(a.published_at)
        )
      ];
    },
    itemsLimit() {
      return this.items.length > this.itemsPerLoad
        ? this.items.length
        : this.itemsPerLoad;
    }
  },
  watch: {
    $route() {
      this.fetchItems({
        limit: this.itemsPerLoad,
        offset: 0,
        timestamp: toServerFormat(this.since),
        tag: this.$route && this.$route.params && this.$route.params.tag
      });
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;
    this.loadMore();
  },
  mounted() {
    this.$store.dispatch(GET_CURRENT_USER_PROFILE);
    this.since = moment();
  },
  methods: {
    setItems(items) {
      if (!items) {
        return;
      }

      this.items = items;
    },
    addItems(items) {
      if (!items || !items.length) {
        return;
      }

      this.items = items.reduce(
        (accumulator, post) => addObjToArray(accumulator, post, 'post_id'),
        this.items
      );
    },
    loadMore() {
      if (this.items || this.items.length) {
        const params = {
          limit: this.itemsLimit,
          offset: this.items.length,
          timestamp: toServerFormat(this.since),
          tag: this.$route && this.$route.params && this.$route.params.tag
        };

        this.$store
          .dispatch(GET_BLOG_POSTS, params)
          .then(items => {
            if (items.length < this.itemsLimit) {
              this.hasMoreToLoad = false;
            }

            this.addItems(items);
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    fetchItems(params) {
      this.$store
        .dispatch(GET_BLOG_POSTS, params)
        .then(items => {
          if (params.tag) {
            this.setItems(items);
          } else {
            this.addItems(items);
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './BlogList.scss';
</style>
