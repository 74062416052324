var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('div',{staticClass:"box-shadow-white pt-1 pb-1 pl-2 pr-2"},[_c('span',{class:_vm.$style.links},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.cohortName))]),_c('li',[_vm._v(_vm._s(_vm.moduleName))])]),(_vm.currentUserTeamSlug)?_c('router-link',{attrs:{"to":{
          name: 'team-profile',
          params: { teamSlug: _vm.currentUserTeamSlug }
        },"data-ignore":"1"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 100 100","xmlns:v":"https://vecta.io/nano"}},[_c('path',{attrs:{"d":"M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm24.4 29.3a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zM50 25.1a12.04 12.04 0 0 1 12 12 12.04 12.04 0 0 1-12 12 12.04 12.04 0 0 1-12-12 12.04 12.04 0 0 1 12-12zm-24.9 4.2a8.28 8.28 0 0 1 8.3 8.3 8.28 8.28 0 0 1-8.3 8.3c-4.6 0-8.3-3.8-8.3-8.3a8.28 8.28 0 0 1 8.3-8.3zm60.5 34.5H66.3c1.4 2.8 2.1 5.8 2.1 9a2.26 2.26 0 0 1-2.3 2.3H33.8a2.26 2.26 0 0 1-2.3-2.3c-.1-3.1.7-6.2 2.1-9H14a1.58 1.58 0 0 1-1.6-1.6c-.1-3.6 1.3-7 3.7-9.5s5.7-3.8 9.1-3.8 6.6 1.4 9.1 3.8c1.5 1.7 2.6 3.7 3.2 5.8 3.4-3.2 7.9-4.9 12.5-4.9 4.5-.1 8.8 1.6 12.1 4.7.6-2.1 1.7-4 3.2-5.5 2.4-2.5 5.7-3.8 9.1-3.8s6.7 1.4 9.1 3.8c2.4 2.6 3.7 6 3.7 9.5 0 .8-.7 1.5-1.6 1.5z","fill":"#cca14d"}})]),_vm._v(" Team Page "),_c('i',{staticClass:"icon pl-1 icon-chevron-right"})]):_vm._e()],1),_c('h2',{attrs:{"title":_vm.assignment.slug}},[_vm._v(_vm._s(_vm.assignment.name))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.assignment.description)}}),_c('p',{class:_vm.$style.teamLink},[_vm._v(" For accessing your Newrow team room, please follow the link to your team page. ")]),_c('div',{class:_vm.$style.details},[_c('div',[_c('h6',{class:_vm.$style.title},[_vm._v("Documents")]),(!_vm.isLeadSessionAfterStart && _vm.isCurrentUserStudent)?[(_vm.learningResources.length)?_c('Splide',{class:_vm.$style.documents,attrs:{"options":_vm.isPhoneLayout
                ? {
                    perPage: 1,
                    hasSliderWrapper: false,
                    gap: '0px',
                    padding: { left: '0px', right: '0px' },
                    arrows: 'slider',
                    trimSpace: true,
                    pagination: false
                  }
                : {
                    perPage: 2,
                    hasSliderWrapper: false,
                    gap: '7px',
                    padding: { left: '28px', right: '28px' },
                    arrows: 'slider',
                    trimSpace: true,
                    pagination: false
                  }}},_vm._l((_vm.learningResources),function(item,index){return _c('SplideSlide',{key:index},[_c('DocumentCard',{attrs:{"isEmpty":true}})],1)}),1):_vm._e()]:[(_vm.learningResources.length)?_c('Splide',{class:_vm.$style.documents,attrs:{"options":_vm.isPhoneLayout
                ? {
                    perPage: 1,
                    hasSliderWrapper: false,
                    gap: '0px',
                    padding: { left: '0px', right: '0px' },
                    arrows: 'slider',
                    trimSpace: true,
                    pagination: false
                  }
                : {
                    perPage: 2,
                    hasSliderWrapper: false,
                    gap: '7px',
                    padding: { left: '28px', right: '28px' },
                    arrows: 'slider',
                    trimSpace: true,
                    pagination: false
                  }}},_vm._l((_vm.learningResources),function(item,index){return _c('SplideSlide',{key:index},[_c('GradingCard',{attrs:{"resource":item}})],1)}),1):_c('p',[_vm._v(" No documents are linked to this assignment. ")])]],2),_c('div',[(_vm.leadSessionDeadline && _vm.leadSession.start_at)?[(!_vm.isLeadSessionAfterStart)?[_c('h6',{class:_vm.$style.title},[_vm._v(" Starts at ")]),_c('p',{attrs:{"data-ignore":"snapshot"}},[_vm._v(_vm._s(_vm._f("format")(_vm.leadSessionStart)))])]:_vm._e(),(_vm.isLeadSessionAfterStart && !_vm.isLeadSessionAfterDeadline)?[_c('h6',{class:_vm.$style.title},[_vm._v(" Deadline ")]),_c('p',{attrs:{"data-ignore":"snapshot"}},[_vm._v(_vm._s(_vm._f("format")(_vm.leadSessionDeadline)))]),_c('Timer',{class:_vm.$style.deadline,attrs:{"timerStart":_vm.leadSession.start_at,"deadline":_vm.leadSession.end_at,"currentTime":_vm.nowISO,"formats":_vm.customTimerFormats},scopedSlots:_vm._u([{key:"before-deadline",fn:function(ref){
                  var checkTime = ref.checkTime;
return [_c('strong',{attrs:{"data-ignore":"snapshot"}},[_vm._v(_vm._s(checkTime))]),_vm._v(" left ")]}},{key:"after-deadline",fn:function(){return [_c('strong',[_vm._v("0Days 0H")]),_vm._v(" left ")]},proxy:true}],null,false,627517553)})]:_vm._e(),(_vm.isLeadSessionAfterDeadline)?[_c('h6',{class:_vm.$style.title},[_vm._v(" Deadline ")]),_c('p',{attrs:{"data-ignore":"snapshot"}},[_vm._v(" "+_vm._s(_vm._f("format")(_vm.leadSession.end_at))+" ")]),_c('Timer',{class:( _obj = {}, _obj[_vm.$style.deadline] = true, _obj[_vm.$style.afterDeadline] = true, _obj ),attrs:{"timerStart":_vm.leadSession.start_at,"deadline":_vm.leadSession.end_at,"currentTime":_vm.nowISO,"formats":_vm.customTimerFormats},scopedSlots:_vm._u([{key:"before-deadline",fn:function(ref){
              var checkTime = ref.checkTime;
return [_c('strong',{attrs:{"data-ignore":"snapshot"}},[_vm._v(_vm._s(checkTime))]),_vm._v(" left ")]}},{key:"after-deadline",fn:function(){return [_c('strong',[_vm._v("0Days 0H")]),_vm._v(" left ")]},proxy:true}],null,false,627517553)})]:_vm._e()]:_c('p',[_vm._v(" No deadline data ")])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }