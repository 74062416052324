<template>
  <div :class="$style.wrapper" class="p-2">
    <h6 :class="$style.title">
      <span>Password</span>
    </h6>
    <div :class="$style.passwords">
      <FormLabel data-ref="oldPassword">
        <FormInputPassword
          v-model="oldPassword"
          data-cy="currentPassword"
          placeholder="Current password"
        />

        <FormFieldErrors
          v-if="newPasswordIncorrect"
          :errors="[{ message: 'Wrong password.' }]"
        />

        <FormFieldErrors v-else :errors="[{ message: oldPasswordMessage }]" />
      </FormLabel>

      <FormLabel data-ref="newPassword">
        <div :class="$style.newPassword">
          <div>
            <FormInputPassword
              v-model="newPassword"
              data-cy="newPassword"
              placeholder="New password"
            />
            <FormFieldErrors :errors="[{ message: newPasswordMessage }]" />
          </div>
          <FormInputPasswordStrength
            data-ref="passwordStrength"
            :password="newPassword"
            :isValid="isValid(newPassword)"
          />
        </div>
      </FormLabel>

      <FormLabel data-ref="newPasswordConfirm">
        <FormInputPassword
          v-model="newPasswordConfirm"
          data-cy="newPasswordConfirm"
          placeholder="Confirm new password."
        />
        <FormFieldErrors :errors="[{ message: confirmPasswordMessage }]" />
      </FormLabel>
      <FormButton
        data-ref="changePassword"
        :standard="true"
        @click="changePassword(oldPassword, newPassword)"
        data-cy="changePassword"
        :disabled="passwordChangeDisabled ? true : null"
        :class="$style.button"
        >Change My Password</FormButton
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['newPasswordIncorrect'],
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
      passwordChangeDisabled: false,
      oldPasswordMessage: '',
      newPasswordMessage: '',
      confirmPasswordMessage: ''
    };
  },
  computed: {
    ...mapGetters(['passwordRegex']),
    isValid() {
      return password => {
        const re = new RegExp(this.passwordRegex);
        return re.test(password);
      };
    }
  },
  methods: {
    isOldPasswordEmpty() {
      if (this.oldPassword !== null && this.oldPassword !== '') {
        return false;
      }

      return true;
    },
    isNewPasswordEmpty() {
      if (this.newPassword !== null && this.newPassword !== '') {
        return false;
      }

      return true;
    },
    isConfirmPasswordEmpty() {
      if (this.newPasswordConfirm !== null && this.newPasswordConfirm !== '') {
        return false;
      }

      return true;
    },
    validateOldPassword() {
      if (!this.isOldPasswordEmpty()) return true;

      return false;
    },
    validateNewPassword() {
      if (this.isNewPasswordEmpty()) return false;

      if (this.isValid(this.newPassword)) return true;

      return false;
    },
    validateConfirmPassword() {
      if (this.isConfirmPasswordEmpty()) return false;

      if (this.isValid(this.newPasswordConfirm)) return true;

      return false;
    },
    compareNewPasswords() {
      if (this.newPasswordConfirm === this.newPassword) return true;

      return false;
    },
    displayErrorMessages() {
      const isOldPasswordValid = this.validateOldPassword();
      const isNewPasswordValid = this.validateNewPassword();
      const isConfirmPasswordValid = this.validateConfirmPassword();
      const areNewPasswordsMatching = this.compareNewPasswords();

      if (!isOldPasswordValid) {
        this.oldPasswordMessage = 'Please enter a password.';
      }

      if (!isNewPasswordValid) {
        this.newPasswordMessage = 'Please use 6 characters or more.';
      }

      if (!isConfirmPasswordValid || !areNewPasswordsMatching) {
        this.confirmPasswordMessage =
          "Passwords don't match. Please try again.";
      }
    },
    clearErrorMessages() {
      this.oldPasswordMessage = '';
      this.newPasswordMessage = '';
      this.confirmPasswordMessage = '';
    },
    changePassword() {
      this.clearErrorMessages();

      const isOldPasswordValid = this.validateOldPassword();
      const isNewPasswordValid = this.validateNewPassword();
      const isConfirmPasswordValid = this.validateConfirmPassword();
      const areNewPasswordsMatching = this.compareNewPasswords();

      if (
        isOldPasswordValid & isNewPasswordValid &&
        isConfirmPasswordValid &&
        areNewPasswordsMatching
      ) {
        return this.$emit(
          'change_password',
          this.oldPassword,
          this.newPassword
        );
      } else {
        this.displayErrorMessages();
      }
    }
  }
};
</script>

<style module lang="scss">
@import './PasswordChange.scss';
</style>
