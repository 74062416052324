<template>
  <div v-if="posts" :class="$style.wrapper" class="box-shadow-white">
    <h6 :class="$style.header">
      Most recent posts
    </h6>

    <div :class="$style.post" v-for="(post, index) in posts" :key="index">
      <div :class="$style.avatar">
        <Avatar :user="post.author" size="45px" />
      </div>
      <div :class="$style.text">
        <div :class="$style.title" v-line-clamp:1="1.2">
          <router-link
            :to="{
              name: 'user-profile',
              params: { userId: post.author.user_id }
            }"
          >
            <UserName :preTitles="true" :user="post.author" />
          </router-link>
          posted in
          <router-link
            :to="{
              name: 'topic-posts',
              params: {
                forumSlug: post.topic.forum_slug,
                topicSlug: post.topic.slug,
                postId: post.post_id
              }
            }"
          >
            <span v-html="post.topic.subject" />
          </router-link>
          on
          <time :datetime="post.created_at"> {{ createdAt(post) }}</time>
        </div>
        <div
          v-html="post.content"
          v-line-clamp:2="1.2"
          :class="$style.content"
          class="paragraph-normal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Avatar from '@/components/common/User/Avatar';

export default {
  methods: {
    createdAt(post) {
      return post && moment(post.created_at).format('DD MMM, HH:mm');
    }
  },
  components: {
    Avatar
  },
  props: {
    posts: {
      type: Array
    }
  }
};
</script>

<style lang="scss" module>
@import './RecentPosts.scss';
</style>
