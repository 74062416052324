<template>
  <DoNotTrack>
    <slot name="prefix">
      <span class="prefix">{{ getUserNamePart('prefix') }}</span>
    </slot>
    <slot>
      <span>{{ getUserNamePart('fullUserName') }}</span>
    </slot>
    <slot name="suffix">
      <span class="suffix">{{ getUserNamePart('suffix') }}</span>
    </slot>
  </DoNotTrack>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getUserFullName } from '@/helpers/users';

import { GET_USER_PROFILE } from '@/store/actions.type';

export default {
  name: 'Name',
  props: {
    userId: {
      type: Number
    },
    user: {
      type: [String, Object]
    },
    prefixSeparator: {
      type: String,
      default: ' '
    },
    suffixSeparator: {
      type: String,
      default: ', '
    },
    preTitles: {
      type: Boolean,
      default: false
    },
    postTitles: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapGetters(['userProfile']),
    foundUserProfile() {
      if (this.user) {
        return this.user;
      }

      if (!Number.isNaN(this.userId)) {
        const foundUserProfile = this.userProfile(this.userId);

        if (foundUserProfile && foundUserProfile.hasOwnProperty('user_id'))
          return foundUserProfile;
      }

      return undefined;
    }
  },
  methods: {
    ...mapActions([GET_USER_PROFILE]),
    getUserProfile() {
      if (typeof this.user === 'string') return;

      if (this.user && this.user.hasOwnProperty('user_id')) return;

      if (!Number.isNaN(parseInt(this.userId))) {
        const foundUserProfile = this.userProfile(this.userId);

        if (foundUserProfile && foundUserProfile.hasOwnProperty('user_id'))
          return;

        this.GET_USER_PROFILE(this.userId);
      }
    },
    getUserNamePart(part) {
      if (!this.foundUserProfile) return '';

      // We might have received the user name formatted already
      // In that case let's just return it
      if (typeof this.foundUserProfile === 'string')
        return this.foundUserProfile;

      // If we received an object, it's probably user profile
      // In that case let's return whatever a helper finds
      if (typeof this.foundUserProfile === 'object') {
        switch (part) {
          case 'prefix':
            if (!this.preTitles) return '';

            return this.foundUserProfile.pre_nominal_title
              ? `${this.foundUserProfile.pre_nominal_title}${this.prefixSeparator}`
              : '';
            break;
          case 'suffix':
            if (!this.postTitles) return '';

            return this.foundUserProfile.post_nominal_title
              ? `${this.suffixSeparator}${this.foundUserProfile.post_nominal_title}`
              : '';
            break;
          default:
            return getUserFullName(this.foundUserProfile);
        }
      }

      // In all other cases render empty string
      return '';
    }
  }
};
</script>
