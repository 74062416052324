<template>
  <div class="box-shadow-white" :class="$style.wrapper">
    <div v-if="showHeaderBar" :class="$style.header">
      <p :class="$style.headerName">
        Discussion topic
      </p>
      <p class="bold" :class="$style.headerStats">
        <i class="icon icon-comments-o" />
      </p>
      <p v-if="showRecentPosters" :class="$style.headerPosters">
        Recent posters
      </p>
    </div>

    <div v-for="topic in topics" :key="topic.slug" :class="$style.topic">
      <div :class="$style.title">
        <p class="paragraph-large text-semibold">
          <router-link
            :to="{
              name: 'topic-posts',
              params: { forumSlug, topicSlug: topic.slug }
            }"
          >
            <span v-html="topic.subject" />
          </router-link>
        </p>
        <div v-if="topic.author">
          Posted by
          <span :class="$style.author">
            <UserName :preTitles="true" :user="topic.author" />
          </span>
          on
          <time :datetime="getDate" :class="$style.published">
            {{ getDate(topic) }}
          </time>
        </div>
      </div>

      <div :class="$style.stats">
        <div>
          <i v-if="!showHeaderBar" class="icon icon-comments-o" />
          {{ topic.post_count }}
        </div>
      </div>

      <div v-if="showRecentPosters" :class="$style.posters">
        <AvatarGroup
          v-if="topic.recent_posters"
          :users="topic.recent_posters"
          :counter="false"
          size="45px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import AvatarGroup from '@/components/common/AvatarGroup/AvatarGroup';

export default {
  components: {
    AvatarGroup
  },
  props: {
    forumSlug: {
      type: String
    },
    topics: {
      type: Array
    },
    showHeaderBar: {
      type: Boolean,
      default: true
    },
    showRecentPosters: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getDate(topic) {
      return moment(topic.created_at).format('DD MMM, YYYY');
    }
  }
};
</script>

<style module lang="scss">
@import './ForumTopicsList.scss';
</style>
