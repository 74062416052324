<template>
  <div
    :class="$style.wrapper"
    :style="{
      gridTemplateColumns: `repeat(${days}, 1fr)`
    }"
  >
    <div
      v-for="(dayISO, dayIndex) in Object.keys(container.days)"
      :key="dayIndex"
      :class="{
        [$style.current]: isCurrent(dayISO)
      }"
    >
      <DayHeader
        :class="$style.header"
        :dayISO="dayISO"
        class="box-shadow-white"
      />
    </div>
    <div
      class="box-shadow-white"
      :class="{ [$style.spanned]: true }"
      :style="{
        gridColumnStart: 1,
        gridColumnEnd: `span ${days}`
      }"
    >
      <InlineLoader v-if="isLoading" />

      <div
        :class="$style.lesson"
        v-else-if="container.type === CYCLE_TYPES.onboarding"
      >
        <!-- empty? -->
      </div>
      <div
        :class="$style.lesson"
        v-else-if="container.type === CYCLE_TYPES.study"
      >
        <div>
          <p class="text-semibold">No sessions for this week</p>
          <p>
            Self-study time allows you to work on any open tasks that you may
            have.
          </p>
          <p>Feel free to schedule this time as you see appropriate.</p>
        </div>
      </div>
      <div
        :class="$style.lesson"
        v-else-if="container.type === CYCLE_TYPES.flex"
      >
        <template v-if="fullSizeNote">
          <div :class="$style.note">
            <div :class="$style.typeIcon">
              <img
                width="75"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_flex.svg')"
              />
            </div>
            <div>
              <p class="text-semibold">Flex Week</p>
              <p>
                As you travel back from your teaching hospital this week, make
                sure to schedule time to review the module and finish any open
                tasks that you may have.
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="text-semibold">Flex Week</p>
            <p>
              As you travel back from your teaching hospital this week, make
              sure to schedule time to review the module and finish any open
              tasks that you may have.
            </p>
          </div>
        </template>
      </div>
      <div
        :class="$style.lesson"
        v-else-if="container.type === CYCLE_TYPES.holiday"
      >
        <template v-if="fullSizeNote">
          <div :class="$style.note">
            <div :class="$style.typeIcon">
              <img
                width="75"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_holiday.svg')"
              />
            </div>
            <div>
              <p class="text-semibold">It's Holiday!</p>
              <p>
                More time to study, yay!
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="text-semibold">It's Holiday!</p>
            <p>
              More time to study, yay!
            </p>
          </div>
        </template>
      </div>
      <div :class="$style.lesson" v-else-if="container.type === CYCLE_TYPES.cr">
        <template v-if="fullSizeNote">
          <div :class="$style.note">
            <div :class="$style.typeIcon">
              <img
                width="75"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_cr.svg')"
              />
            </div>
            <div>
              <p class="text-semibold" :class="$style.title">
                Clinical Rotation
              </p>
              <p>
                This is your clinical rotation phase. Class may be out, but the
                learning doesn’t stop! Head to the library to find videos and
                readings that will enhance your clinical experience.
              </p>
              <span :class="$style.footerLink">
                <router-link
                  :to="{
                    name: 'learning-units-current'
                  }"
                >
                  Check the Units...
                </router-link>
              </span>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="text-semibold" :class="$style.title">Clinical Rotation</p>
            <p>
              This is your clinical rotation phase. Class may be out, but the
              learning doesn’t stop! Head to the library to find videos and
              readings that will enhance your clinical experience.
            </p>
            <span :class="$style.footerLink">
              <router-link
                :to="{
                  name: 'learning-units-current'
                }"
              >
                Check the Units...
              </router-link>
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { CYCLE_TYPES } from '@/config/schedule';

import DayHeader from '@/views/MiniSchedule/DayHeader';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

export default {
  name: 'EmptyPlaceholder',
  components: {
    DayHeader,
    InlineLoader
  },
  props: {
    container: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  },
  computed: {
    days() {
      if (!this.container) return 0;

      return Object.keys(this.container.days).length;
    },
    fullSizeNote() {
      return this.days > 2;
    }
  },
  methods: {
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    }
  }
};
</script>

<style lang="scss" module>
@import './MiniSchedule.scss';
@import './MiniScheduleDaysSpanEmpty.scss';
@import './WeekTypeFlex.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>
