<template>
  <div id="EmojiPicker" :class="['emoji-picker', { dark }]">
    <EmojiList
      :data="mapEmojis"
      :category="currentCategory"
      :filter="filterEmoji"
      :emojiWithBorder="emojiWithBorder"
      :emojiSize="emojiSize"
      :emojisByRow="emojisByRow"
      :continuousList="continuousList"
      @select="onSelectEmoji"
    />
  </div>
</template>

<script>
import { Emoji, emojisDefault } from './emojis.js';

import EmojiList from './EmojiList.vue';

export default {
  components: {
    EmojiList
  },
  props: {
    customEmojis: {
      default: () => emojisDefault
    },
    limitFrequently: {
      type: Number,
      default: 15
    },
    emojisByRow: {
      type: Number,
      default: 5
    },
    continuousList: {
      type: Boolean,
      default: false
    },
    emojiSize: {
      type: Number,
      default: 32
    },
    emojiWithBorder: {
      type: Boolean,
      default: true
    },
    dark: { type: Boolean, default: false },
    initialCategory: {
      type: String,
      default: 'Peoples'
    },
    exceptEmojis: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      currentCategory: this.initialCategory,
      filterEmoji: '',
      mapEmojis: {}
    };
  },

  created() {
    // Create map
    this.mapperEmojisCategory(this.customEmojis);
    this.restoreFrequentlyEmojis();
  },

  computed: {
    categoriesFiltered() {
      return this.customCategories.filter(
        category => !this.exceptCategories.includes(category)
      );
    }
  },

  watch: {
    customEmojis(newEmojis) {
      if (newEmojis && newEmojis.length) {
        this.mapEmojis = {};
        this.mapperEmojisCategory(newEmojis);
      }
    }
  },

  beforeDestroy() {
    this.mapEmojis = {};
  },

  methods: {
    onSearch(term) {
      this.filterEmoji = term;
    },

    onSelectEmoji(emoji) {
      this.updateFrequently(emoji);
      this.$emit('select', emoji);
    },

    onChangeCategory(category) {
      this.$emit('changeCategory', category);
    },

    changeCategory(category) {
      const hasEmojis = this.mapEmojis[category.name].length;
      this.currentCategory = category.name;

      if (hasEmojis) {
        this.onChangeCategory(category);
      }
    },

    mapperEmojisCategory(emojis) {
      this.mapEmojis = emojis
        .filter(emoji => {
          return !emoji.aliases.some(alias =>
            this.exceptEmojis.includes(alias)
          );
        })
        .reduce(
          (memo, emoji) => {
            const _category = emoji.category;

            if (!memo[_category]) {
              memo[_category] = [];
            }

            memo[_category].push(emoji);

            return memo;
          },
          { Frequently: [] }
        );
    },

    async updateFrequently(emoji) {
      const oldEmojis = this.mapEmojis['Frequently'];
      const emojis = [...new Set([emoji, ...oldEmojis])];

      this.mapEmojis['Frequently'] = emojis.slice(0, this.limitFrequently);

      await this.saveFrequentlyEmojis(emojis);
    },

    async restoreFrequentlyEmojis() {
      const jsonMapIndexEmojis = localStorage.getItem('frequentlyEmojis');

      const mapIndexEmojis = JSON.parse(jsonMapIndexEmojis) || [];
      this.mapEmojis['Frequently'] = mapIndexEmojis.map(
        index => this.customEmojis[index]
      );
    },

    async saveFrequentlyEmojis(emojis) {
      const mapIndexEmojis = emojis.map(emoji => {
        return this.customEmojis.indexOf(emoji);
      });

      localStorage.setItem('frequentlyEmojis', JSON.stringify(mapIndexEmojis));
    }
  }
};
</script>

<style lang="scss" scoped>
.emoji-picker {
  --ep-color-bg: #f0f0f0;
  --ep-color-sbg: #f6f6f6;
  --ep-color-border: #e4e4e4;
  --ep-color-text: #4a4a4a;
  --ep-color-active: #009688;

  display: inline-flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  flex-direction: column;
  align-items: center;
  background-color: var(--ep-color-bg);
  border-radius: 4px;
  border: 1px solid var(--ep-color-border);
  overflow: hidden;
  width: 325px;
  user-select: none;

  @media screen and (max-width: 325px) {
    width: 100%;
  }
}

.dark {
  --ep-color-bg: #191b1a;
  --ep-color-sbg: #212221;
  --ep-color-border: #3e3d42;
  --ep-color-text: #f0f0f0;
  --ep-color-active: #009688;
}
</style>
