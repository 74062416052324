export const state = {
  unreadCount: 0,
  conversations: [],
  current_conversation: {
    id: null,
    messages: [],
    readTimeout: null
  },
  polling_cancel_tokens: [],
  placeholder_conversation: {
    type: null,
    participantIds: [],
    participants: [],
    object_id: null
  },
  is_ready_to_start: false,
  users_search_results: [],
  selected_participant_ids: []
};

export const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy
