<template>
  <div>
    <NotFound v-if="display404">
      <p>This team doesn't exist.</p>
      <p>
        Go to
        <router-link
          :to="{
            name: 'dashboard'
          }"
        >
          home page.
        </router-link>
      </p>
    </NotFound>
    <Layout variant="4" v-else>
      <template #pageHero>
        <TopWidgetTeamProfile
          :team="teamProfile(teamSlug)"
          :user_id="currentUserId"
          @open_team_conversation="onOpenTeamConversation"
        />
      </template>
      <template #pageSideA>
        <SideWidgetTeamMembers :teamMembers="teamMembers(teamSlug)" />
      </template>
      <template #pageContent>
        <h6>Latest activity</h6>
        <NotificationsPlaceholder />
      </template>
      <template #pageSideB>
        <SideWidgetTeamRooms
          :user="currentUserProfile"
          :rooms="teamRooms(teamSlug)"
        />
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { hasErrorStatus } from '@/helpers/errors';
import { PLACEHOLDER_ID, CONVERSATION_TYPES } from '@/config/conversations';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import TopWidgetTeamProfile from '@/components/team-profile/TopWidgetTeamProfile/TopWidgetTeamProfile';
import SideWidgetTeamRooms from '@/components/team-profile/SideWidgetTeamRooms/SideWidgetTeamRooms';
import SideWidgetTeamMembers from '@/components/team-profile/SideWidgetTeamMembers/SideWidgetTeamMembers';
import NotificationsPlaceholder from '@/components/common/NotificationsPlaceholder/NotificationsPlaceholder';

import {
  GET_CURRENT_USER_PROFILE,
  GET_TEAM_PROFILE,
  GET_CONVERSATIONS,
  OPEN_PLACEHOLDER_CONVERSATION,
  OPEN_CONVERSATION
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    NotFound,
    Layout,
    TopWidgetTeamProfile,
    SideWidgetTeamMembers,
    SideWidgetTeamRooms,
    NotificationsPlaceholder
  },
  data() {
    return {
      display404: false
    };
  },
  computed: {
    ...mapGetters('chats', [
      'conversationsWithParticipants',
      'canStartTeamConversation'
    ]),
    ...mapGetters([
      'teamProfile',
      'teamRooms',
      'currentUserId',
      'teamMembers',
      'currentUserProfile',
      'streamTeam'
    ]),
    teamSlug() {
      return this.$route.params.teamSlug;
    },
    teamName() {
      const team = this.teamProfile(this.teamSlug);
      if (team && team.name) {
        return team.name;
      }

      return '';
    }
  },
  watch: {
    $route() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('chats', [
      GET_CONVERSATIONS,
      OPEN_CONVERSATION,
      OPEN_PLACEHOLDER_CONVERSATION
    ]),
    fetchData() {
      this.$store.dispatch(GET_TEAM_PROFILE, this.teamSlug).catch(error => {
        if (hasErrorStatus(404, error)) {
          this.display404 = true;
        } else {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      });

      Promise.all([
        this.$store.dispatch(GET_CURRENT_USER_PROFILE),
        this.GET_CONVERSATIONS()
      ]).catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    },
    onOpenTeamConversation({ teamId, teamSlug }) {
      const teamMembers = this.teamMembers(teamSlug);
      const participantsIds = teamMembers.map(member => member.user_id);

      const found = this.conversationsWithParticipants(
        [this.currentUserId, ...participantsIds],
        CONVERSATION_TYPES.TEAM
      );

      if (found.length === 0) {
        this.OPEN_PLACEHOLDER_CONVERSATION({
          type: CONVERSATION_TYPES.TEAM,
          participant_ids: [this.currentUserId, ...participantsIds],
          participants: [this.currentUserProfile, ...teamMembers],
          object_id: teamId
        })
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId: PLACEHOLDER_ID
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });

        return;
      }

      if (found.length === 1) {
        this.startConversation(found[0].conversation_id);
      } else {
        // sort the conversations so that the most
        // recently created one will be the first item
        found.sort((a, b) => {
          const a_created = new Date(a.created_at);
          const b_created = new Date(b.created_at);
          return a_created.valueOf() < b_created.valueOf();
        });

        this.startConversation(found[0].conversation_id);
      }
    },
    startConversation(conversationId) {
      if (conversationId) {
        this.OPEN_CONVERSATION(conversationId)
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './TeamProfile.scss';
</style>
