<template>
  <div :class="$style.wrapper">
    <h6>Faq Topics</h6>
    <ul :class="$style.adminFaqTopics" class="box-shadow-white p-2">
      <li v-for="(topic, index) in faqTopics" :key="index">
        <p
          class="text-semibold"
          :class="{
            [$style.link]: true,
            [$style.active]: pageOpened(topic.faq_topic_id)
          }"
          @click="changeFaqSlug(topic)"
        >
          {{ topic.title }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    faqTopics: {
      type: Array,
      required: true
    }
  },
  watch: {
    $route() {
      this.pageOpened(this.$route.params.topicId);
    }
  },
  created() {
    if (!this.$route.params.topicId) {
      this.changeFaqSlug(this.faqTopics[0]);
    }
  },
  methods: {
    changeFaqSlug(topic) {
      this.$router.push({
        name: 'admin-faq-topic',
        params: {
          topicId: topic.faq_topic_id
        }
      });
    },
    pageOpened(topicId) {
      if (this.$route.params.topicId === topicId) {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminFaq.scss';
@import '../AdminTables.scss';
</style>
