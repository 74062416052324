var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('router-link',{class:_vm.$style.icon,attrs:{"to":{
      name: 'learning-unit-detail',
      params: {
        learningUnitId: ("" + (_vm.unit.unit_id)),
        moduleVersionId: _vm.moduleVersionId
      },
      query: Object.assign({}, _vm.$route.query)
    }}},[_c('LearningUnitCategoryIcon')],1),_c('router-link',{staticClass:"divider",class:_vm.$style.title,attrs:{"to":{
      name: 'learning-unit-detail',
      params: {
        learningUnitId: ("" + (_vm.unit.unit_id)),
        moduleVersionId: _vm.moduleVersionId
      },
      query: Object.assign({}, _vm.$route.query)
    }}},[(_vm.isMobileLayout)?_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1.2),expression:"1.2",arg:"3"}],staticClass:"semibold",attrs:{"title":_vm.unit.description}},[_vm._v(" "+_vm._s(_vm.unit.label)+" ")]):_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:2",value:(1.2),expression:"1.2",arg:"2"}],staticClass:"semibold",attrs:{"title":_vm.unit.description}},[_vm._v(" "+_vm._s(_vm.unit.label)+" ")])]),(!_vm.isMobileLayout)?_c('span',[(_vm.sessions && _vm.sessions.length)?_c('ul',{class:_vm.$style.sessions},_vm._l((_vm.sessions),function(session,index){return _c('li',{key:index},[_c('router-link',{class:_vm.$style.link,attrs:{"to":{
            name: 'learning-unit-detail',
            params: {
              learningUnitId: ("" + (_vm.unit.unit_id)),
              moduleVersionId: _vm.moduleVersionId
            },
            query: Object.assign({}, _vm.$route.query)
          }}},[_c('strong',[_vm._v(_vm._s(session.type))]),_vm._v(" - "+_vm._s(_vm.format(session.start_at))+" ")])],1)}),0):_c('ul',{class:( _obj = {}, _obj[_vm.$style.sessions] = true, _obj[_vm.$style.empty] = true, _obj )},[_c('li',[_vm._v(" No session scheduled ")])])]):_vm._e(),(!_vm.isMobileLayout)?_c('router-link',{class:_vm.$style.objectives,attrs:{"to":{
      name: 'learning-unit-detail',
      params: {
        learningUnitId: ("" + (_vm.unit.unit_id)),
        moduleVersionId: _vm.moduleVersionId
      },
      query: Object.assign({}, _vm.$route.query)
    }}},[_vm._v(" Obj: "),_c('strong',[_vm._v(_vm._s(_vm.unit.lo_count))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }