<template><span /></template>
<script>
import { LOGOUT } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  created() {
    this.$store
      .dispatch(LOGOUT)
      .then(() => {
        this.$router.push({ name: 'login' }).then(() => {
          /* Perform a page reload to get user-related code out of execution stack */
          window.location.reload();
        });
      })
      .catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
  }
};
</script>
