import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { periodsByTime } from '@/helpers/schedule';

import { VIEW_AS_OBJECT_TYPES } from '@/config/schedule';

import {
  GET_SCHEDULE_WEEK,
  GET_SCHEDULE_WEEK_CURRENT,
  GET_USER_KVSTORE,
  SET_VIEW_AS_ACTION,
  PATCH_USER_KVSTORE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    nowISO: {
      type: String,
      default: moment().toISOString(true)
    }
  },
  data() {
    return {
      rules: [
        {
          r: [0, 0, 0, 0],
          n: 'Current week of current module for current user',
          h: async () => {
            try {
              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 0, 1, 0],
          n: 'Current week of current module for specific user',
          h: async () => {
            try {
              const userId = this.routeValue.user
                ? this.routeValue.user
                : this.persistedViewAsUserId
                ? this.persistedViewAsUserId
                : this.asUserId;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                asUserId: userId
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asUser: userId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 0, 0, 1],
          n: 'Current week of current module for specific cohort',
          h: async () => {
            try {
              const cohortId = this.routeValue.cohort
                ? this.routeValue.cohort
                : this.persistedViewAsCohortId
                ? this.persistedViewAsCohortId
                : this.asCohortId;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                asCohortId: cohortId
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asCohort: cohortId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 0, 1, 1],
          n: 'Throws error (can not view as user and cohort at once)',
          h: this.handleParamError
        },
        {
          r: [0, 1, 0, 0],
          n: 'Specific week of current module for current user',
          h: async () => {
            try {
              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week
              });

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 1, 1, 0],
          n: 'Specific week of current module for specific user',
          h: async () => {
            try {
              const userId = this.routeValue.user
                ? this.routeValue.user
                : this.persistedViewAsUserId
                ? this.persistedViewAsUserId
                : this.asUserId;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week,
                asUserId: userId
              });

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asUser: userId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 1, 0, 1],
          n: 'Specific week of current module for specific cohort',
          h: async () => {
            try {
              const cohortId = this.routeValue.cohort
                ? this.routeValue.cohort
                : this.persistedViewAsCohortId
                ? this.persistedViewAsCohortId
                : this.asCohortId;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week,
                asCohortId: cohortId
              });

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asCohort: cohortId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [0, 1, 1, 1],
          n: 'Throws error (can not view as user and cohort at once)',
          h: this.handleParamError
        },
        {
          r: [1, 0, 0, 0],
          n: 'Closest week of specific module for current user',
          h: async () => {
            try {
              const currentForUser = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint
              });

              const moduleVersionId =
                currentForUser.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                moduleVersionId
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 0, 1, 0],
          n: 'Closest week of specific module for specific user',
          h: async () => {
            try {
              const userId = this.routeValue.user
                ? this.routeValue.user
                : this.persistedViewAsUserId
                ? this.persistedViewAsUserId
                : this.asUserId;

              const currentForUser = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                asUserId: this.routeValue.user
              });

              const moduleVersionId =
                currentForUser.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                moduleVersionId,
                asUserId: userId
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asUser: userId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 0, 0, 1],
          n: 'Closest week of specific module for specific cohort',
          h: async () => {
            try {
              const cohortId = this.routeValue.cohort
                ? this.routeValue.cohort
                : this.persistedViewAsCohortId
                ? this.persistedViewAsCohortId
                : this.asCohortId;

              const currentForCohort = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                asCohortId: this.routeValue.cohort
              });

              const moduleVersionId =
                currentForCohort.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const {
                module_version_id,
                calendar_week_id,
                weeks
              } = await this.GET_SCHEDULE_WEEK_CURRENT({
                personal: this.shouldUsePersonalEndpoint,
                moduleVersionId,
                asCohortId: cohortId
              });

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest ? closest.calendar_week_id : calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asCohort: cohortId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 0, 1, 1],
          n: 'Throws error (can not view as user and cohort at once)',
          h: this.handleParamError
        },
        {
          r: [1, 1, 0, 0],
          n: 'Specific week of specific module for current user',
          h: async () => {
            try {
              const weekForCurrentUser = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week
              });

              const moduleVersionId =
                weekForUser.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const { module_version_id, weeks } = await this.GET_SCHEDULE_WEEK(
                {
                  personal: this.shouldUsePersonalEndpoint,
                  moduleVersionId,
                  calendarWeekId: this.routeValue.week
                }
              );

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: this.routeValue.week
                },
                query: {
                  ...this.$route.query
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 1, 1, 0],
          n: 'Specific week of specific module for specific user',
          h: async () => {
            try {
              const userId = this.routeValue.user
                ? this.routeValue.user
                : this.persistedViewAsUserId
                ? this.persistedViewAsUserId
                : this.asUserId;

              const weekForUser = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week,
                asUserId: this.routeValue.user
              });

              const moduleVersionId =
                weekForUser.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const { module_version_id, weeks } = await this.GET_SCHEDULE_WEEK(
                {
                  personal: this.shouldUsePersonalEndpoint,
                  moduleVersionId,
                  calendarWeekId: this.routeValue.week,
                  asUserId: userId
                }
              );

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest.calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asUser: userId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 1, 0, 1],
          n: 'Specific week of specific module for specific cohort',
          h: async () => {
            try {
              const cohortId = this.routeValue.cohort
                ? this.routeValue.cohort
                : this.persistedViewAsCohortId
                ? this.persistedViewAsCohortId
                : this.asCohortId;

              const weekForCohort = await this.GET_SCHEDULE_WEEK({
                personal: this.shouldUsePersonalEndpoint,
                calendarWeekId: this.routeValue.week,
                asCohortId: cohortId
              });

              const moduleVersionId =
                weekForCohort.module_version_id !== this.routeValue.module
                  ? this.routeValue.module
                  : null;

              const { module_version_id, weeks } = await this.GET_SCHEDULE_WEEK(
                {
                  personal: this.shouldUsePersonalEndpoint,
                  moduleVersionId,
                  calendarWeekId: this.routeValue.week,
                  asCohortId: this.routeValue.cohort
                }
              );

              if (!this.isWeekIdWithinWeeks(this.routeValue.week, weeks)) {
                this.redirectToUndefined();
                return;
              }

              const closest = this.getClosestWeek(weeks);

              const destination = {
                name: this.shouldUsePersonalEndpoint
                  ? 'schedule-week-detail'
                  : 'schedule-module-version-week-detail',
                params: {
                  moduleVersionId: module_version_id,
                  weekId: closest.calendar_week_id
                },
                query: {
                  ...this.$route.query,
                  ...{
                    asCohort: cohortId
                  }
                }
              };

              this.$router.replace(destination).catch(() => {});
            } catch (error) {
              this.handleParamError({ error: error.toString() });
            }
          }
        },
        {
          r: [1, 1, 1, 1],
          n: 'Throws error (can not view as user and cohort at once)',
          h: this.handleParamError
        }
      ]
    };
  },
  watch: {
    $route() {
      this.processRule();
    }
  },
  computed: {
    ...mapGetters([
      'currentUserId',
      'asUserId',
      'asCohortId',
      'getUserKVStoreValue',
      'shouldSeeFacilitatorSwitch',
      'shouldUsePersonalEndpoint'
    ]),
    persistedViewAs() {
      return this.getUserKVStoreValue('view_as');
    },
    persistedViewAsUserId() {
      if (!this.isViewAsPersisted) return undefined;
      if (this.persistedViewAs.object_type !== VIEW_AS_OBJECT_TYPES.user)
        return undefined;

      return this.persistedViewAs.object_id;
    },
    persistedViewAsCohortId() {
      if (!this.isViewAsPersisted) return undefined;
      if (this.persistedViewAs.object_type !== VIEW_AS_OBJECT_TYPES.cohort)
        return undefined;

      return this.persistedViewAs.object_id;
    },
    isViewAsUserPersisted() {
      return this.persistedViewAsUserId ? true : false;
    },
    isViewAsCohortPersisted() {
      return this.persistedViewAsCohortId ? true : false;
    },
    isViewAsPersisted() {
      return this.persistedViewAs && this.persistedViewAs.object_type
        ? true
        : false;
    },
    routeHas() {
      // Do not mess with order of these keys! They're used to match a record in this.rules and their order is important
      // A rule with [0,0,1,0] means that module and week is not known (current ones are figured out) and view-as for a user is requested
      return {
        module: this.hasURLParam('moduleVersionId'),
        week: this.hasURLParam('weekId'),
        user: this.hasURLQuery('asUser'),
        cohort: this.hasURLQuery('asCohort')
      };
    },
    routeRule() {
      // Turns this.routeHas into an array of ones and zeroes (see above)
      return Object.values(this.routeHas).map((value, key) => {
        const userKeyIndex = Object.keys(this.routeHas).findIndex(
          keyValue => keyValue === 'user'
        );
        const cohortKeyIndex = Object.keys(this.routeHas).findIndex(
          keyValue => keyValue === 'cohort'
        );

        if (key === userKeyIndex && !value && this.isViewAsUserPersisted)
          return 1;
        if (key === userKeyIndex && !value && this.asUserId) return 1;

        if (key === cohortKeyIndex && !value && this.isViewAsCohortPersisted)
          return 1;
        if (key === cohortKeyIndex && !value && this.asCohortId) return 1;

        return value === true ? 1 : 0;
      });
    },
    routeValue() {
      return {
        module: this.routeHas.module
          ? parseInt(this.$route.params.moduleVersionId)
          : NaN,
        week: this.routeHas.week ? parseInt(this.$route.params.weekId) : NaN,
        user: this.routeHas.user ? parseInt(this.$route.query.asUser) : NaN,
        cohort: this.routeHas.cohort
          ? parseInt(this.$route.query.asCohort)
          : NaN
      };
    }
  },
  methods: {
    ...mapActions([
      SET_VIEW_AS_ACTION,
      PATCH_USER_KVSTORE,
      GET_SCHEDULE_WEEK,
      GET_SCHEDULE_WEEK_CURRENT,
      GET_USER_KVSTORE
    ]),
    hasURLParam(param) {
      if (this.$route && this.$route.params && this.$route.params[param]) {
        return true;
      }

      return false;
    },
    hasURLQuery(param) {
      if (this.$route && this.$route.query && this.$route.query[param]) {
        return true;
      }

      return false;
    },
    handleParamError(debugObject) {
      this.redirectToUndefined(debugObject);
    },
    getMatchingRule(resultsArray) {
      const found = this.rules.find(rule => {
        return rule.r.every(
          (result, resultIndex) => result === resultsArray[resultIndex]
        );
      });

      return found ? found : undefined;
    },
    redirectToUndefined(debugObject) {
      const matchingRule = this.getMatchingRule(this.routeRule);

      this.$router
        .replace({
          name: 'schedule-undefined',
          params: {
            debugObject: {
              ...debugObject,
              ...{
                ruleName: matchingRule && matchingRule.n,
                rule: JSON.stringify(this.routeValue)
              }
            }
          }
        })
        .catch(() => {});
    },
    isWeekIdWithinWeeks(weekId, weeks) {
      if (!weekId) return false;
      if (!Array.isArray(weeks)) return false;
      if (!weeks.length) return false;

      const found = weeks.find(week => week.calendar_week_id === weekId);

      return found ? true : false;
    },
    getClosestWeek(weeks) {
      const weeksByTime = periodsByTime(
        weeks,
        this.nowISO,
        false,
        'start_date',
        'end_date'
      );

      const is = {
        allInPast:
          weeksByTime &&
          weeksByTime.all &&
          weeksByTime.all.length &&
          weeksByTime.all.length === weeksByTime.past.length,
        allInFuture:
          weeksByTime &&
          weeksByTime.all &&
          weeksByTime.all.length &&
          weeksByTime.all.length === weeksByTime.future.length,
        someInPresent:
          weeksByTime &&
          weeksByTime.all &&
          weeksByTime.all.length &&
          weeksByTime.present.length > 0
      };

      if (is.allInPast) {
        const lastPastWeek = weeksByTime.past[weeksByTime.past.length - 1];
        return lastPastWeek ? lastPastWeek : undefined;
      } else if (is.allInFuture) {
        const firstFutureWeek = weeksByTime.future[0];
        return firstFutureWeek ? firstFutureWeek : undefined;
      } else if (is.someInPresent) {
        const firstPresentWeek = weeksByTime.present[0];
        return firstPresentWeek ? firstPresentWeek : undefined;
      } else {
        return undefined;
      }
    },
    processRule() {
      const found = this.getMatchingRule(this.routeRule);

      if (!found) {
        this.redirectToUndefined();
        return;
      }

      // console.warn('scenario:', JSON.stringify(found.r), found.n);
      found.h();
    }
  },
  async created() {
    try {
      await this.GET_USER_KVSTORE();

      // Set initial value of "Facilitated" switch to `false` for users that are supposed to see it
      if (
        this.getUserKVStoreValue('schedule_facilitated') === undefined &&
        this.shouldSeeFacilitatorSwitch
      ) {
        await this.PATCH_USER_KVSTORE({ schedule_facilitated: false });
      }

      if (this.routeValue.user && !this.isViewAsPersisted) {
        await this.SET_VIEW_AS_ACTION({
          view_as: {
            object_type: VIEW_AS_OBJECT_TYPES.user,
            object_id: this.routeValue.user
          }
        });
      }

      if (this.routeValue.cohort && !this.isViewAsPersisted) {
        await this.SET_VIEW_AS_ACTION({
          view_as: {
            object_type: VIEW_AS_OBJECT_TYPES.cohort,
            object_id: this.routeValue.cohort
          }
        });
      }

      if (!this.routeValue.user && this.isViewAsUserPersisted) {
        await this.SET_VIEW_AS_ACTION({
          view_as: {
            object_type: VIEW_AS_OBJECT_TYPES.user,
            object_id: this.persistedViewAsUserId
          }
        });
      }

      if (!this.routeValue.cohort && this.isViewAsCohortPersisted) {
        await this.SET_VIEW_AS_ACTION({
          view_as: {
            object_type: VIEW_AS_OBJECT_TYPES.cohort,
            object_id: this.persistedViewAsCohortId
          }
        });
      }

      this.processRule();
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });

      this.redirectToUndefined();
    }
  },
  render() {
    return;
  }
};
