<template>
  <div>
    <ConversationNewMobile
      :isReadyToStart="isReadyToStart"
      @ready_to_start="onReadyToStart"
      @init_existing="openExistingConversation"
      @init_new="openPlaceholderConversation"
      v-if="isMobileLayout"
    />
    <ConversationNewDesktop
      :isReadyToStart="isReadyToStart"
      @ready_to_start="onReadyToStart"
      @init_existing="openExistingConversation"
      @init_new="openPlaceholderConversation"
      v-else
    />
  </div>
</template>

<script>
import {
  OPEN_PLACEHOLDER_CONVERSATION,
  OPEN_CONVERSATION
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import { PLACEHOLDER_ID } from '@/config/conversations';

import { mapGetters, mapActions } from 'vuex';
import ConversationNewDesktop from './ConversationNewDesktop';
import ConversationNewMobile from './ConversationNewMobile';

export default {
  name: 'ConversationNew',
  components: {
    ConversationNewDesktop,
    ConversationNewMobile
  },
  data() {
    return {
      isReadyToStart: false
    };
  },
  computed: {
    ...mapGetters(['isMobileLayout'])
  },
  methods: {
    ...mapActions('chats', [OPEN_PLACEHOLDER_CONVERSATION, OPEN_CONVERSATION]),
    onReadyToStart(value) {
      this.isReadyToStart = value;
    },
    openExistingConversation(conversationId) {
      if (!conversationId) return;

      this.OPEN_CONVERSATION(conversationId)
        .then(() => {
          this.$router.push({
            name: 'conversation-existing',
            params: {
              conversationId
            }
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        });
    },
    openPlaceholderConversation(payload) {
      if (!payload) return;

      this.OPEN_PLACEHOLDER_CONVERSATION(payload)
        .then(() => {
          this.$router.push({
            name: 'conversation-existing',
            params: {
              conversationId: PLACEHOLDER_ID
            }
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        });
    }
  }
};
</script>
