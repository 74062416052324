var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',[_vm._v("Learning Objective Units")]),(_vm.units)?_c('vue-good-table',{attrs:{"pagination-options":{
      enabled: true
    },"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'unitId')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
            name: 'learning-unit-detail',
            params: { learningUnitId: props.row.unitId }
          },"target":"_blank"}},[_vm._v(_vm._s(props.row.unitId))])],1):(props.column.field === 'unitName')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'learning-unit-detail',
            params: { learningUnitId: props.row.unitId }
          },"target":"_blank"}},[_vm._v(_vm._s(props.row.unitName))])],1):_vm._e()]}}],null,false,195836603)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }