<template>
  <div :class="$style.wrapper">
    <ExamAnswerMultipleChoice
      v-if="componentType === QUESTION_TYPE.checkbox"
      data-ref="checkbox"
      :maxChoices="maxChoices"
      :options="options"
      :disabled="disabled ? true : null"
      :styleAsResults="styleAsResults"
      @input="onMultipleSelected"
    />

    <ExamAnswerSingleChoice
      v-if="componentType === QUESTION_TYPE.radio"
      data-ref="radio"
      :options="options"
      :disabled="disabled ? true : null"
      :styleAsResults="styleAsResults"
      @input="onSingleSelected"
    />

    <template v-if="componentType === QUESTION_TYPE.text">
      <template v-if="styleAsResults">
        <div
          v-if="!textAnswer"
          data-ref="placeholder"
          :class="$style.noTextAnswer"
          class="text-light-grey"
        >
          This question was left blank.
        </div>
        <div v-else v-html="textAnswer" />
      </template>
      <template v-else>
        <Editor
          :key="questionId"
          data-ref="text"
          :value="textAnswer"
          placeholder="Answer goes here..."
          :disabled="disabled ? true : null"
          @input="onFreeTextSubmitted"
        />
      </template>
    </template>

    <template v-if="componentType === QUESTION_TYPE.editor">
      <template v-if="styleAsResults">
        <div
          v-if="!collaborativeAnswer"
          :class="$style.noTextAnswer"
          class="text-light-grey"
        >
          This question was left blank.
        </div>
        <div
          data-ref="collaborativeEditorAnswer"
          v-html="collaborativeAnswer"
          data-cy="editor_answer"
        />
      </template>
      <template v-else>
        <!-- No collaboration during the assessment! -->
      </template>
    </template>

    <template v-if="componentType === QUESTION_TYPE.viewer">
      <template v-if="styleAsResults">
        <PdfResourceViewer :resourceSlug="resourceSlug" />
      </template>
      <template v-else>
        <!-- No viewer during the assessment! -->
      </template>
    </template>
  </div>
</template>

<script>
import { QUESTION_KIND, QUESTION_TYPE } from '@/config/assessments';

import ExamAnswerMultipleChoice from '@/components/exams/ExamAnswerMultipleChoice/ExamAnswerMultipleChoice';
import ExamAnswerSingleChoice from '@/components/exams/ExamAnswerSingleChoice/ExamAnswerSingleChoice';
import Editor from '@/components/common/Editor/Editor';
import PdfResourceViewer from '@/components/common/PdfResourceViewer/PdfResourceViewer';

export default {
  components: {
    ExamAnswerMultipleChoice,
    ExamAnswerSingleChoice,
    Editor,
    PdfResourceViewer
  },
  props: {
    questionId: {
      type: Number,
      default: undefined
    },
    kind: {
      type: String,
      default: QUESTION_KIND['options']
    },
    maxChoices: {
      type: Number,
      default: undefined
    },
    options: {
      type: Array,
      default: () => []
    },
    textAnswer: {
      type: String,
      default: ''
    },
    collaborativeAnswer: {
      type: String,
      default: ''
    },
    collaborativeAnswerPath: {
      type: String,
      default: ''
    },
    resourceSlug: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styleAsResults: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.QUESTION_TYPE = QUESTION_TYPE;
  },
  computed: {
    componentType() {
      if (
        this.kind === QUESTION_KIND['options'] &&
        this.maxChoices &&
        parseInt(this.maxChoices) === 1
      ) {
        return QUESTION_TYPE.radio;
      }

      if (
        this.kind === QUESTION_KIND['options'] &&
        (!this.maxChoices || parseInt(this.maxChoices) !== 1)
      ) {
        return QUESTION_TYPE.checkbox;
      }

      if (this.kind === QUESTION_KIND['free-text']) {
        return QUESTION_TYPE.text;
      }

      if (this.kind === QUESTION_KIND['collaborative-editor']) {
        return QUESTION_TYPE.editor;
      }

      if (this.kind === QUESTION_KIND['file-upload']) {
        return QUESTION_TYPE.viewer;
      }

      return null;
    }
  },
  methods: {
    onSingleSelected(selectedValue) {
      const selectedIntValue = parseInt(selectedValue);

      if (!selectedIntValue) return;

      this.$emit('answered', {
        question_id: this.questionId,
        checked_option_ids: [selectedIntValue]
      });
    },
    onMultipleSelected(selectedValues) {
      this.$emit('answered', {
        question_id: this.questionId,
        checked_option_ids: selectedValues
      });
    },
    onFreeTextSubmitted(submittedText) {
      this.$emit('answered', {
        question_id: this.questionId,
        text_entry: submittedText
      });
    },
    setPathPDF(resource) {
      const pdfViewerPath = `/viewer/web/viewer.html?file=`;

      if (resource.file && !resource.file.path.includes('http')) {
        return `${pdfViewerPath}/${resource.file.path}`;
      }

      return `${pdfViewerPath}${resource.file.path}`;
    }
  }
};
</script>

<style lang="scss" module>
@import './ExamAnswer.scss';
</style>
