import moment from 'moment';
import { SESSION_PROGRESS, GRADING_TYPES, COLUMNS } from '@/config/grading';
import { ALLOW_JOIN_IN_ADVANCE_MIN } from '@/config/schedule';

const SCORE_NEEDS_VALUE = `Score points need to be provided`;
const SCORE_NEEDS_MAX = `Maximum reachable score points need to be provided`;
const SCORE_NEEDS_MIN = `Minimum reachable score points need to be provided`;
const SCORE_CANT_OVERFLOW = `Score points above total are not allowed`;
const SCORE_CANT_UNDERFLOW = `Score points below minimum are not allowed`;
const SCORE_MIN_CANT_OVERFLOW_MAX = `Minimum score points above maximum are not allowed`;
const SCORE_MAX_CANT_UNDERFLOW_MIN = `Maximum score points below minimum are not allowed`;

export const getGradingErrors = (
  scorePoints = NaN,
  scoreMaxPoints = NaN,
  scoreMinPoints = NaN
) => {
  const errors = [];
  const isNumberScorePoints = !Number.isNaN(scorePoints);
  const isNumberScoreMaxPoints = !Number.isNaN(scoreMaxPoints);
  const isNumberScoreMinPoints = !Number.isNaN(scoreMinPoints);

  if (!isNumberScorePoints) {
    errors.push(SCORE_NEEDS_VALUE);
  }

  if (!isNumberScoreMaxPoints) {
    errors.push(SCORE_NEEDS_MAX);
  }

  if (!isNumberScoreMinPoints) {
    errors.push(SCORE_NEEDS_MIN);
  }

  if (
    isNumberScorePoints &&
    isNumberScoreMinPoints &&
    scorePoints < scoreMinPoints
  ) {
    errors.push(SCORE_CANT_UNDERFLOW);
  }

  if (
    isNumberScorePoints &&
    isNumberScoreMaxPoints &&
    scorePoints > scoreMaxPoints
  ) {
    errors.push(SCORE_CANT_OVERFLOW);
  }

  if (
    isNumberScoreMaxPoints &&
    isNumberScoreMinPoints &&
    scoreMinPoints > scoreMaxPoints
  ) {
    errors.push(SCORE_MIN_CANT_OVERFLOW_MAX);
  }

  if (
    isNumberScoreMaxPoints &&
    isNumberScoreMinPoints &&
    scoreMaxPoints < scoreMinPoints
  ) {
    errors.push(SCORE_MAX_CANT_UNDERFLOW_MIN);
  }

  return errors;
};

export const isEvaluationPublished = session => {
  if (!session) return undefined;

  if (session.evaluation === null) return false;

  const isPublished =
    typeof session.evaluation === 'object' &&
    session.evaluation.hasOwnProperty('published_at') &&
    moment(session.evaluation.published_at).isValid();

  return isPublished;
};

export const getSessionProgress = (session, nowISO) => {
  if (!session) return undefined;

  const m_start_at = moment(session.start_at);
  const m_end_at = moment(session.end_at);
  const m_joinable_at = moment(session.start_at).subtract(
    ALLOW_JOIN_IN_ADVANCE_MIN,
    'minute'
  );
  const m_now = moment(nowISO);

  if (m_now.isBefore(m_joinable_at)) {
    return SESSION_PROGRESS.DISTANT;
  } else if (
    m_now.isSameOrAfter(m_joinable_at) &&
    m_now.isSameOrBefore(m_start_at)
  ) {
    return SESSION_PROGRESS.IMMINENT;
  } else if (
    m_now.isSameOrAfter(m_start_at) &&
    m_now.isSameOrBefore(m_end_at)
  ) {
    return SESSION_PROGRESS.RUNNING;
  } else if (m_now.isAfter(m_end_at)) {
    return SESSION_PROGRESS.ENDED;
  } else {
    return undefined;
  }
};

export const getSessionGradingComponent = session => {
  if (session && session.grading && session.grading.grading_component) {
    return session.grading.grading_component;
  }

  return undefined;
};

export const getSessionColumns = session => {
  switch (getSessionGradingComponent(session)) {
    case GRADING_TYPES.TYPENULL:
      return [COLUMNS.student];
      break;
    case GRADING_TYPES.TYPE1: // Active participation
      return [
        COLUMNS.attendance,
        COLUMNS.student,
        COLUMNS.score,
        COLUMNS.comment
      ];
      break;
    case GRADING_TYPES.TYPE2: // Group assignment
      return [COLUMNS.attendance, COLUMNS.student, COLUMNS.comment];
      break;
    case GRADING_TYPES.TYPE3: // Attendance only
      return [COLUMNS.attendance, COLUMNS.student, COLUMNS.comment];
      break;
    case GRADING_TYPES.TYPE4: // Legacy active participation
      return [COLUMNS.student, COLUMNS.score, COLUMNS.comment];
      break;
    case GRADING_TYPES.TYPE5: // Legacy group assignment
      return [COLUMNS.attendance, COLUMNS.student];
      break;
    default:
      return [];
  }
};
