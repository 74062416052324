import ApiService from '@/api/api.service';
import JwtService from '@/api/jwt.service';
import { REGISTER_CONFIG } from '@/config/registration';

import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  INITIATE_PASSWORD_RESET,
  RESET_PASSWORD
} from './actions.type';

import {
  SET_AUTH,
  PURGE_AUTH,
  RESET_STATE,
  SET_PASSWORD_RESET_TOKEN,
  PURGE_PASSWORD_RESET_TOKEN
} from './mutations.type';

const getResponseToken = response => {
  return response.headers['x-new-auth-token'];
};

export const state = {
  session: {},
  isAuthenticated: !!JwtService.getToken(),
  passwordResetToken: null
};

export const getters = {
  session(state) {
    return state.session;
  },
  isAuthenticated() {
    return state.isAuthenticated;
  },
  passwordResetToken(state) {
    return state.passwordResetToken;
  },
  currentUserId(state) {
    return state.session.user_id;
  }
};

export const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.login(credentials.email, credentials.password)
        .then(response => {
          const data = response.data;

          const payload = {
            session: { ...data, email: credentials.email },
            token: getResponseToken(response)
          };

          context.commit(SET_AUTH, payload);

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [LOGOUT](context) {
    context.commit('chats/PURGE_CONVERSATION', null, { root: true });
    context.commit('chats/RESET_STATE', null, { root: true });
    context.commit('groupwork/RESET_STATE', null, { root: true });
    context.commit('blog/RESET_STATE', null, { root: true });
    context.commit('grading/RESET_STATE', null, { root: true });
    context.commit(RESET_STATE);
    context.commit(PURGE_AUTH);
  },

  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.validateSession()
          .then(response => {
            const payload = {
              session: { ...response.data },
              token: getResponseToken(response)
            };

            context.commit(SET_AUTH, payload);
            resolve();
          })
          .catch(error => {
            context.commit(PURGE_AUTH);
            reject(error);
          });
      } else {
        context.commit(PURGE_AUTH);
        reject();
      }
    });
  },

  [INITIATE_PASSWORD_RESET](context, email) {
    return new Promise((resolve, reject) => {
      ApiService.initiatePasswordReset(email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [RESET_PASSWORD](context, newPassword) {
    return new Promise((resolve, reject) => {
      ApiService.resetPassword(context.state.passwordResetToken, newPassword)
        .then(response => {
          context.commit(PURGE_PASSWORD_RESET_TOKEN);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.session = { ...state.session, ...payload.session };
    JwtService.saveToken(payload.token);
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.session = {};
    JwtService.destroyToken();
  },

  [SET_PASSWORD_RESET_TOKEN](state, token) {
    state.passwordResetToken = token;
  },

  [PURGE_PASSWORD_RESET_TOKEN](state) {
    state.passwordResetToken = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
