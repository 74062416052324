var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: true,
      initialSortBy: { field: 'approvedAt', type: 'desc' }
    },"pagination-options":{
      enabled: true
    },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'operationId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.operationId))])]):(props.column.field === 'reference')?_c('span',[_c('a',{attrs:{"href":props.row.reference.startsWith('/#')
              ? props.row.reference
              : ("#" + (props.row.reference)),"target":"_blank"}},[_vm._v("Visit Page")])]):(props.column.field === 'requestedBy')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.userId }
          }}},[_vm._v(" "+_vm._s(props.row.requestedBy)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.userId))])])],1):(props.column.field === 'approvedBy')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.approvedId }
          }}},[_vm._v(" "+_vm._s(props.row.approvedBy)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.approvedId))])])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }