<template>
  <div :class="$style.wrapper" data-cy="assignment-type4">
    <!-- TYPE4 -->
    <Type1Header
      :assignment="assignment"
      :leadSession="leadSession"
      :nowISO="nowISO"
    />

    <div :class="$style.pageSection" v-if="featuresAssignmentResources">
      <h2>
        Resources
      </h2>

      <AssignmentResources
        class="box-shadow-white pt-1 pb-1 pl-2 pr-2"
        :assignment="assignment"
        :leadSession="leadSession"
      />
    </div>

    <div :class="{ [$style.pageSection]: true, [$style.withModal]: true }">
      <h2>
        Discussion
      </h2>

      <i
        @click="showModal = true"
        class="icon icon-help-circle"
        title="Show Help"
      />

      <Comments
        class="box-shadow-white pt-2 pb-2 pl-2 pr-2 overflow-visible"
        objectType="assignment"
        :objectId="leadSession.assignment_id"
      />
    </div>

    <ModalNew
      :class="$style.modalWrapper"
      :showModal="showModal"
      :closeWithClickOutside="true"
      v-transfer-dom
      @close="showModal = false"
    >
      <div :class="$style.description">
        <i class="icon icon-info-circle color-info" />

        <div>
          Discussion is where session-related questions or comments between the
          students and the facilitator can take place, either prior or after the
          class. When posting a comment, both the facilitator and the students
          will be notified via email. When replying to a comment - only people
          in the thread will be notified.
        </div>
      </div>
    </ModalNew>

    <div :class="{ [$style.gradingTitles]: true, [$style.pageSection]: true }">
      <h2>
        Grading
      </h2>
      <p v-if="rubricURL" class="text-bold">
        <a :href="rubricURL" target="_blank">
          See Grading Rubric
        </a>
      </p>
    </div>

    <InlineLoader v-if="!sessions.length" />

    <template v-else v-for="(session, index) of sessions">
      <ContainerSession
        :key="`session-${session.scheduled_assignment_id}`"
        :data-cy="`container-session-${index}`"
        class="box-shadow-white"
        :session="session"
        :collapsed="shouldStartCollapsed(session)"
        :canPreview="canPreview(session)"
        :canEdit="canEdit(session)"
        :canPublish="canPublish(session)"
        :isPublished="isPublished(session)"
        :hasEdits="hasEdits(session)"
        :isInAction="isInAction"
        :nowISO="nowISO"
        v-slot="{ currentMode }"
      >
        <GradingSession
          :session="session"
          :currentMode="currentMode"
          @has_errors="handleErrors"
          @has_edits="handleEdits"
          @in_action="handleInAction"
        />
      </ContainerSession>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContainerSession from '../ContainerSession';

import Type1Header from '../Type1/Type1Header';
import GradingSession from '../GradingSession';

import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import AssignmentResources from '../AssignmentResources';
import Comments from '@/views/Comments/Comments';
import { isEvaluationPublished, getSessionProgress } from '@/helpers/grading';
import { SESSION_PROGRESS } from '@/config/grading';

export default {
  name: 'Type4',
  components: {
    InlineLoader,
    Type1Header,
    ContainerSession,
    GradingSession,
    AssignmentResources,
    Comments
  },
  props: {
    focusAtId: {
      type: Number,
      default: NaN
    },
    nowISO: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isInAction: false,
      showModal: false,
      errors: [],
      edits: []
    };
  },
  computed: {
    ...mapGetters('grading', [
      'assignment',
      'sessions',
      'leadSession',
      'rubricURL',
      'featuresAssignmentResources'
    ])
  },
  methods: {
    canPreview(session) {
      return !this.hasErrors(session);
    },
    canEdit(session) {
      if (
        [SESSION_PROGRESS.RUNNING, SESSION_PROGRESS.ENDED].includes(
          getSessionProgress(session, this.nowISO)
        )
      ) {
        return !this.isPublished(session);
      }

      return false;
    },
    canPublish(session) {
      if (
        [SESSION_PROGRESS.RUNNING, SESSION_PROGRESS.ENDED].includes(
          getSessionProgress(session, this.nowISO)
        )
      ) {
        return !this.hasErrors(session) && !this.isPublished(session);
      }

      return false;
    },
    isPublished(session) {
      return isEvaluationPublished(session);
    },
    handleInAction(maybeInAction) {
      this.isInAction = maybeInAction;
    },
    shouldStartCollapsed(session) {
      return parseInt(session.scheduled_assignment_id) !== this.focusAtId;
    },
    hasErrors(session) {
      const saErrors = this.errors.filter(origin => {
        return origin.startsWith(`${session.scheduled_assignment_id}_`);
      });

      return saErrors.length > 0 ? true : false;
    },
    handleErrors(event) {
      if (event.has_errors === true) {
        this.errors.push(event.origin);
      } else {
        const foundItem = this.errors.find(e => e === event.origin);
        if (foundItem) {
          this.errors.splice(event.origin, 1);
        }
      }
    },
    hasEdits(session) {
      const saEdits = this.edits.filter(origin => {
        return origin.startsWith(`${session.scheduled_assignment_id}_`);
      });

      return saEdits.length > 0 ? true : false;
    },
    handleEdits(event) {
      if (event.has_edits === true) {
        this.edits.push(event.origin);
      } else {
        const foundItem = this.edits.find(e => e === event.origin);
        if (foundItem) {
          this.edits.splice(event.origin, 1);
        }
      }
    }
  }
};
</script>

<style lang="scss" module>
@import '../styles/Grading.scss';
</style>
