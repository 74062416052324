/**
 * Directive for transfering DOM outside of template.
 * Very useful for modals and other stuff dealing with z-index values.
 * Use:
 *   Vue.directive('transfer-dom', transferDOM);
 *   <Modal v-transfer-dom> renders modal DOM as a last element inside <body> (default)
 *   <Modal v-transfer-dom:container> renders modal DOM as a last element inside `#container` element
 *   <Modal v-transfer-dom:container.prepend> renders modal DOM as a first element inside `#container` element
 */

const setupTransfer = (el, bindings) => {
  let container = bindings.arg
    ? document.getElementById(bindings.arg)
    : document.body; // default append to <body>
  if (container) {
    bindings.modifiers.prepend && container.firstChild
      ? container.insertBefore(el, container.firstChild) // prepend
      : container.appendChild(el); // append
  } else {
    throw new Error(
      `transfer-dom directive could not find #${bindings.arg} element.`
    );
  }
};

const tearDownTransfer = el => {
  if (el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export default {
  inserted: (el, bindings) => setupTransfer(el, bindings),
  unbind: (el, bindings) => tearDownTransfer(el, bindings)
};
