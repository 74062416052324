<template>
  <Layout variant="5">
    <template slot="pageContent">
      <nav :class="$style.header">
        <h5>
          Assessments&nbsp;<span
            v-if="filteredAssignments && filteredAssignments.length"
            >({{
              facilitatedModel || isViewingAsCohort
                ? `${filteredAssignments.length} out of `
                : ''
            }}{{ itemsTotal }})</span
          >
        </h5>

        <div :class="$style.filters">
          <FormButton
            :ghostFilter="true"
            :class="{
              [$style.button]: true,
              [$style.selected]: status === GRADING_PROGRESS.PENDING
            }"
            @click="handleChangeStatus(GRADING_PROGRESS.PENDING)"
          >
            Pending
          </FormButton>
          <FormButton
            :ghostFilter="true"
            :class="{
              [$style.button]: true,
              [$style.selected]: status === GRADING_PROGRESS.EVALUATED
            }"
            @click="handleChangeStatus(GRADING_PROGRESS.EVALUATED)"
          >
            Evaluated
          </FormButton>
          <FormButton
            :ghostFilter="true"
            :class="{
              [$style.button]: true,
              [$style.selected]: status === GRADING_PROGRESS.ALL
            }"
            @click="handleChangeStatus(GRADING_PROGRESS.ALL)"
          >
            All
          </FormButton>

          <div v-if="isCurrentUserTeachingStaff" :class="$style.facilitated">
            <strong>My Sessions</strong>
            <FormThreeWaySwitchAutoSave
              name="facilitatedModel"
              data-cy="facilitated"
              :value="facilitatedModel"
              :modelValue="facilitatedModel"
              :disabled="false"
              :action="saveFacilitated"
              :validation="() => true"
              @validation_result="() => true"
              @success="facilitatedModel = !facilitatedModel"
              @error="() => true"
              @state="() => true"
              :withActionIndicator="false"
              :colors="threeWaySwitchColors"
            />
          </div>

          <div v-if="isCurrentUserTeachingStaff" :class="$style.viewAs">
            <SingleItemSuggest
              :cohortId="asCohortId"
              :allowedSuggestionTypes="allowedSuggestionTypes"
              @selected_cohort_id="handleCohortIdSelected"
              @cleared="handleSelectionCleared"
            />
          </div>
        </div>
      </nav>

      <section :class="$style.table">
        <header class="box-shadow">
          <span @click="setParam('sort', getNextSortOption('session'))">
            Session&nbsp;<i
              class="icon icon-chevron-down"
              v-if="sort === 'session-desc'"
            ></i
            ><i class="icon icon-chevron-up" v-if="sort === 'session-asc'"></i>
          </span>
          <span @click="setParam('sort', getNextSortOption('cohort'))">
            Cohort&nbsp;<i
              class="icon icon-chevron-down"
              v-if="sort === 'cohort-desc'"
            ></i
            ><i class="icon icon-chevron-up" v-if="sort === 'cohort-asc'"></i>
          </span>
          <span @click="setParam('sort', getNextSortOption('type'))">
            Type&nbsp;<i
              class="icon icon-chevron-down"
              v-if="sort === 'type-desc'"
            ></i
            ><i class="icon icon-chevron-up" v-if="sort === 'type-asc'"></i>
          </span>
          <span @click="setParam('sort', getNextSortOption('facilitator'))">
            Facilitator&nbsp;<i
              class="icon icon-chevron-down"
              v-if="sort === 'facilitator-desc'"
            ></i
            ><i
              class="icon icon-chevron-up"
              v-if="sort === 'facilitator-asc'"
            ></i>
          </span>
          <span @click="setParam('sort', getNextSortOption('status'))">
            Status&nbsp;<i
              class="icon icon-chevron-down"
              v-if="sort === 'status-desc'"
            ></i
            ><i class="icon icon-chevron-up" v-if="sort === 'status-asc'"></i>
          </span>
          <span>
            <!-- bs indicator -->
          </span>
        </header>

        <template v-if="isLoading">
          <InlineLoader />
        </template>

        <template
          v-else-if="filteredAssignments && filteredAssignments.length < 1"
        >
          <main :class="$style.empty">
            No items.
          </main>
        </template>

        <template v-else>
          <template v-for="(row, index) in pageOfItems">
            <Item
              v-if="
                pageOfItemsDetails.find(
                  i => i.scheduled_assignment_id === row.scheduled_assignment_id
                )
              "
              :key="index"
              :item="
                pageOfItemsDetails.find(
                  i => i.scheduled_assignment_id === row.scheduled_assignment_id
                )
              "
            />
          </template>
        </template>

        <footer v-if="filteredAssignments && filteredAssignments.length">
          <div>
            <FilterSelect
              :class="$style.perPage"
              :value="itemsPerPage"
              @change="changeItemsPerPage"
              :action="updateItemsPerPage"
              :submenuVisible="submenuVisible"
              :clearSuccessAfter="1"
              @submenu_visibility="handleSubmenuChange"
            >
              <template #title>
                <span>Rows: {{ itemsPerPage }}</span>
              </template>
              <template #options>
                <option
                  v-for="(value, index) in perPageOptions"
                  :value="value"
                  :key="`option-${index}`"
                >
                  {{ value }}
                </option>
              </template>
              <template #items>
                <template v-for="(value, index) in perPageOptions">
                  <span :key="`option-${index}`" @click="simulateChange(value)">
                    {{ value }}
                  </span>
                </template>
              </template>
            </FilterSelect>
          </div>

          <Pagination
            :items="filteredAssignments"
            :pageSize="itemsPerPage"
            :maxPages="maxPages"
            :initialPage="parseInt(page)"
            @changePage="handleChangePage"
          />
          <div></div>
        </footer>
      </section>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import Layout from '@/views/Layout/Layout';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import Pagination from '@/components/common/Pagination/Pagination';
import Item from '@/views/Grading/AssignmentsIndexItem';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';
import SingleItemSuggest from '@/views/Schedule/SingleItemSuggest';

import { THEMES, DEFAULT_THEME } from '@/config/themes';
const THEME_COLORS = THEMES[process.env.THEME || DEFAULT_THEME];

import {
  GET_ASSIGNMENTS,
  GET_BULK_USER_PROFILES,
  GET_BULK_ASSIGNMENTS,
  SET_VIEW_AS_ACTION,
  PATCH_USER_KVSTORE,
  GET_USER_KVSTORE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR, ADD_USER_PROFILE } from '@/store/mutations.type';
import { ITEMS_PER_PAGE_GRADING_INDEX } from '@/config/localStorage';
import { arraysEqual, removeNullItemsFromObj } from '@/helpers/common';
import { GRADING_PROGRESS } from '@/config/grading';
import { VIEW_AS_OBJECT_TYPES } from '@/config/schedule';

const sortOptionGenerator = function*(prefix) {
  while (true) {
    yield `${prefix}-asc`;
    yield `${prefix}-`;
    yield `${prefix}-desc`;
  }
};

const moduleShortRegexp = /Module\s*(?<moduleNumber>\d)*/i;

const sortOptions = {
  session: sortOptionGenerator('session'),
  cohort: sortOptionGenerator('cohort'),
  type: sortOptionGenerator('type'),
  facilitator: sortOptionGenerator('facilitator'),
  status: sortOptionGenerator('status')
};

export default {
  name: 'AssignmentsIndex',
  components: {
    Layout,
    InlineLoader,
    Pagination,
    Item,
    FilterSelect,
    SingleItemSuggest
  },
  props: {
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 25, 50]
    },
    maxPages: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      isLoading: false,
      submenuVisible: false,
      pageOfItems: [],
      pageOfItemsDetails: []
    };
  },
  computed: {
    ...mapGetters([
      'isCurrentUserStudent',
      'isCurrentUserTeachingStaff',
      'asUserId',
      'asCohortId',
      'currentUserId',
      'isViewingAsCohort',
      'getUserKVStoreValue'
    ]),
    ...mapGetters('grading', ['assignments']),
    allowedSuggestionTypes() {
      return [VIEW_AS_OBJECT_TYPES.cohort];
    },
    status() {
      return this.$route.query.status;
    },
    sort() {
      return this.$route.query.sort;
    },
    page() {
      return this.$route.query.page;
    },
    itemsTotal() {
      if (!this.assignments) return NaN;

      return this.assignments.length;
    },
    itemsPerPage() {
      return parseInt(this.$route.query.perpage);
    },
    queryParams() {
      return this.transformQueryParams(this.$route.query);
    },
    filteredAssignments() {
      let items = this.assignments;

      if (!items) return [];

      if (this.asCohortId) {
        items = items.filter(item => {
          return item.cohort_id === this.asCohortId;
        });
      }

      if (!this.isCurrentUserTeachingStaff) return items;

      if (!this.facilitatedModel === true) {
        return items;
      }

      return items.filter(item => {
        if (!item) return false;
        if (!item.facilitators) return false;

        return item.facilitators.includes(this.currentUserId);
      });
    },
    facilitatorIds() {
      if (!this.assignments) return [];
      if (!this.assignments.length) return [];

      return this.assignments.reduce((memo, item) => {
        if (!item) return memo;
        if (!Array.isArray(item.facilitators)) return memo;

        return Array.from(new Set([...memo, ...item.facilitators]));
      }, []);
    },
    pageOfItemsIds() {
      if (!this.pageOfItems) return [];
      if (!this.pageOfItems.length) return [];

      return this.pageOfItems.reduce((memo, item) => {
        if (!item) return memo;
        if (!item.scheduled_assignment_id) return memo;

        return Array.from(new Set([...memo, item.scheduled_assignment_id]));
      }, []);
    },
    routeHas() {
      // Do not mess with order of these keys! They're used to match a record in this.rules and their order is important
      // A rule with [0,0,1,0] means that module and week is not known (current ones are figured out) and view-as for a user is requested
      return {
        module: this.hasURLParam('moduleVersionId'),
        week: this.hasURLParam('weekId'),
        user: this.hasURLQuery('asUser'),
        cohort: this.hasURLQuery('asCohort')
      };
    },
    routeValue() {
      return {
        module: this.routeHas.module
          ? parseInt(this.$route.params.moduleVersionId)
          : NaN,
        week: this.routeHas.week ? parseInt(this.$route.params.weekId) : NaN,
        user: this.routeHas.user ? parseInt(this.$route.query.asUser) : NaN,
        cohort: this.routeHas.cohort
          ? parseInt(this.$route.query.asCohort)
          : NaN
      };
    },
    facilitatedModel: {
      get() {
        return this.getUserKVStoreValue('schedule_facilitated');
      },
      async set(newValue) {
        try {
          await this.PATCH_USER_KVSTORE({ schedule_facilitated: newValue });
          await this.GET_USER_KVSTORE();
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      }
    },
    threeWaySwitchColors() {
      return {
        true: THEME_COLORS['primaryColor'],
        false: THEME_COLORS['light-grey'],
        undefined: THEME_COLORS['light-grey']
      };
    }
  },
  watch: {
    queryParams(newValue, oldValue) {
      this.getAssignments();
    },
    facilitatorIds(newValue, oldValue) {
      if (!oldValue.length && newValue.length) {
        this.$store
          .dispatch(GET_BULK_USER_PROFILES, { ids: newValue })
          .then(data => {
            data.map(profile => {
              this.$store.commit(ADD_USER_PROFILE, profile);
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, {
              error,
              clientMessage: JSON.stringify(
                error && error.response && error.response.data
              ),
              log: true
            });
          });
      }
    },
    pageOfItemsIds(newValue, oldValue) {
      if (arraysEqual(oldValue, newValue)) return;
      if (!newValue.length) return;

      this.GET_BULK_ASSIGNMENTS({ ids: newValue })
        .then(data => {
          this.pageOfItemsDetails = data;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            clientMessage: JSON.stringify(
              error && error.response && error.response.data
            ),
            log: true
          });
        });
    }
  },
  methods: {
    ...mapActions('grading', [GET_ASSIGNMENTS, GET_BULK_ASSIGNMENTS]),
    ...mapActions([SET_VIEW_AS_ACTION, PATCH_USER_KVSTORE, GET_USER_KVSTORE]),
    saveFacilitated() {
      return Promise.resolve();
    },
    async handleCohortIdSelected(cohortId) {
      try {
        await this.setViewAsCohort(cohortId);
        this.handleViewAsCohortIdRoute(cohortId);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    handleViewAsCohortIdRoute(newCohortId) {
      if (!newCohortId) {
        this.$router
          .push({
            name: 'gradebook',
            query: this.$route.query
          })
          .catch(() => {});
        return;
      } else {
        this.$router
          .push({
            name: 'gradebook',
            query: {
              ...this.$route.query,
              ...{
                asCohort: newCohortId
              }
            }
          })
          .catch(() => {});
        return;
      }

      return;
    },
    async handleSelectionCleared() {
      try {
        await this.SET_VIEW_AS_ACTION();

        if (this.isCurrentUserAdmin || this.isCurrentUserTeachingStaff) {
          const queryClone = { ...this.$route.query };
          delete queryClone.asCohort;

          this.$router
            .push({
              name: 'gradebook',
              query: queryClone
            })
            .catch(() => {});
          return;
        } else {
          // TODO: Figure out when it is called? For students only?
          // Should there be a different method? Maybe a SET_VIEW_AS_ACTION call instead?
          this.handleViewAsUserIdRoute(NaN);
        }
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    setViewAsCohort(cohortId) {
      return this.SET_VIEW_AS_ACTION({
        view_as: {
          object_type: VIEW_AS_OBJECT_TYPES.cohort,
          object_id: cohortId
        }
      });
    },
    simulateChange(selectedValue) {
      let select = this.$el.querySelector('footer select');
      select.value = selectedValue;

      const event = new MouseEvent('change', {
        view: window,
        bubbles: true,
        cancelable: false
      });

      select.dispatchEvent(event);
    },
    updateItemsPerPage() {
      return Promise.resolve(() => {}).then(() => {
        this.handleSubmenuChange(false);
      });
    },
    changeItemsPerPage(newValue) {
      this.setPref(ITEMS_PER_PAGE_GRADING_INDEX, parseInt(newValue));
      this.setParams({ perpage: parseInt(newValue) });
    },
    handleSubmenuChange(value) {
      this.submenuVisible = value;
    },
    transformQueryParams(params) {
      let offset;
      const pageParamInt = parseInt(params.page);
      const page = Number.isNaN(pageParamInt) ? 1 : pageParamInt;
      const parsedPerPage = parseInt(params.perpage);

      if (page < 0) {
        offset = 0;
      } else if (page === 0) {
        offset = 0;
      } else if (page === 1) {
        offset = 0;
      } else {
        offset = (page - 1) * parsedPerPage;
      }

      return {
        status: params.status,
        limit: parsedPerPage,
        offset: offset
      };
    },
    handleChangeStatus(newStatus) {
      this.setParams({ status: newStatus, page: '1' });
    },
    handleChangePage({ currentPage, pageOfItems }) {
      this.pageOfItems = pageOfItems;
      this.setParams({ page: `${currentPage === 0 ? 1 : currentPage}` });
    },
    getNextSortOption(prefix) {
      return sortOptions[prefix].next().value;
    },
    async getAssignments() {
      try {
        this.isLoading = true;
        await this.GET_ASSIGNMENTS(this.queryParams);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          clientMessage: JSON.stringify(error.response.data),
          log: true
        });
        this.isLoading = false;
      }
    },
    setParam(paramName, paramValue) {
      void paramName;
      void paramValue;
    },
    setParams(params) {
      this.$router
        .replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            ...params
          }
        })
        .catch(() => {});
    },
    getPref(key) {
      return window.localStorage.getItem(key);
    },
    setPref(key, value) {
      window.localStorage.setItem(key, value);
    },
    hasURLParam(param) {
      if (this.$route && this.$route.params && this.$route.params[param]) {
        return true;
      }

      return false;
    },
    hasURLQuery(param) {
      if (this.$route && this.$route.query && this.$route.query[param]) {
        return true;
      }

      return false;
    }
  },
  async created() {
    this.GRADING_PROGRESS = GRADING_PROGRESS;

    let initialItemsPerPage;

    if (!this.getPref(ITEMS_PER_PAGE_GRADING_INDEX)) {
      this.setPref(ITEMS_PER_PAGE_GRADING_INDEX, 10);
    }

    const prefItemsPerPage = parseInt(
      this.getPref(ITEMS_PER_PAGE_GRADING_INDEX)
    );

    if (
      Number.isNaN(prefItemsPerPage) ||
      !this.perPageOptions.includes(prefItemsPerPage)
    ) {
      initialItemsPerPage = 10;
    } else {
      initialItemsPerPage = prefItemsPerPage;
    }

    if (this.routeHas.cohort && !this.isViewingAsCohort) {
      try {
        await this.setViewAsCohort(this.routeValue.cohort);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }

    await this.GET_USER_KVSTORE();

    const initialParams = {
      ...{
        status: this.isCurrentUserStudent
          ? GRADING_PROGRESS.EVALUATED
          : GRADING_PROGRESS.PENDING,
        sort: 'session-desc',
        page: '1',
        perpage: `${initialItemsPerPage}`,
        asCohort: this.isViewingAsCohort ? this.asCohortId : null
      },
      ...this.$route.query
    };
    this.setParams(removeNullItemsFromObj(initialParams));

    this.getAssignments();
  }
};
</script>

<style lang="scss" module>
@import './styles/Table.scss';
</style>
