<template>
  <div
    :class="{
      [$style.gridContainer]: true,
      [$style[`layout-${variant}`]]: isVariantValid ? true : false
    }"
  >
    <header
      v-if="shouldShowPageHeader"
      :class="{
        [$style.pageHeader]: true,
        [$style.unstick]: userSettingValue(SETTING_CODES.FIXED_NAV) === true
      }"
    >
      <slot name="pageHeader">
        <PageHeader :hideNavigationItems="hideNavigationItems" />
      </slot>
    </header>
    <section :class="$style.pageServiceArea" v-if="shouldShowPageServiceArea">
      <slot name="pageServiceArea">
        <AnnouncementNotice
          v-if="announcement && announcement.content"
          data-ref="globalAnnouncement"
          :message="announcement.content"
          :slug="announcement.slug"
        />

        <ErrorNotice
          v-if="globalErrorClientMessage"
          data-ref="globalError"
          :message="globalErrorClientMessage"
        />

        <Notice
          v-if="hasNewerVersion"
          data-ref="globalNotice"
          type="info"
          :dismissable="false"
        >
          <template #icon>
            <i class="icon icon-info" @click="showVersions = !showVersions" />
          </template>
          <template #default>
            New version of the platform is available. Please reload this page.
            <p v-if="showVersions">
              {{ versions }}
            </p>
          </template>
        </Notice>
      </slot>
    </section>
    <header :class="$style.pageHero" v-if="shouldShowPageHero">
      <slot name="pageHero"></slot>
    </header>
    <aside :class="$style.pageSideA" v-if="shouldShowPageSideA">
      <slot name="pageSideA"></slot>
    </aside>
    <content :class="$style.pageContent">
      <slot name="pageContent"></slot>
    </content>
    <aside :class="$style.pageSideB" v-if="shouldShowPageSideB">
      <slot name="pageSideB"></slot>
    </aside>
    <footer :class="$style.pageFooter" v-if="shouldShowPageFooter">
      <slot name="pageFooter">
        <PageFooter :hideNavigationItems="hideNavigationItems" />
      </slot>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SETTING_CODES } from '@/config/userSettings';
import { NEWER_VERSION_EVENT } from '@/config/tracking';

import Notice from '@/components/notices/Notice/Notice';
import ErrorNotice from '@/components/notices/ErrorNotice/ErrorNotice';
import AnnouncementNotice from '@/components/notices/AnnouncementNotice/AnnouncementNotice';

import PageHeader from '@/components/common/PageHeader/PageHeader';
import PageFooter from '@/components/common/PageFooter/PageFooter';

// According to LAYOUT_DOCS
const VARIANTS = ['1', '2', '3', '4', '5', '6', '7', '8'];
const LAYOUT_DOCS =
  'https://app.nuclino.com/DEH-Platform/Development-Overview/Page-layouts-e550666e-a98b-425b-ae87-6b967ec6f743';

export default {
  name: 'Layout',
  components: {
    PageHeader,
    PageFooter,
    AnnouncementNotice,
    ErrorNotice,
    Notice
  },
  props: {
    variant: {
      type: String,
      default: '1'
    },
    hideNavigationItems: {
      type: Boolean
    }
  },
  data() {
    return {
      hasNewerVersion: false,
      showVersions: false,
      versions: {
        runningVersion: undefined,
        availableVersion: undefined
      }
    };
  },
  computed: {
    ...mapGetters([
      'globalErrorClientMessage',
      'userSettingValue',
      'announcements'
    ]),
    announcement() {
      return this.announcements && this.announcements.length
        ? this.announcements[0]
        : undefined;
    },
    isVariantValid() {
      return this.variant && VARIANTS.includes(this.variant);
    },
    shouldShowPageHeader() {
      if (!this.isVariantValid) return false;

      // Display in all variants except the following ones
      return ['6'].includes(this.variant) ? false : true;
    },
    shouldShowPageFooter() {
      // Display in all variants with pageHeader
      return this.shouldShowPageHeader;
    },
    shouldShowPageServiceArea() {
      if (this.announcement && this.announcement.content) return true;

      if (this.globalErrorClientMessage) return true;

      if (this.hasNewerVersion) return true;

      return false;
    },
    shouldShowPageSideA() {
      if (!this.isVariantValid) return false;

      // Display in all variants except the following ones
      return ['3', '5', '6', '7'].includes(this.variant) ? false : true;
    },
    shouldShowPageSideB() {
      if (!this.isVariantValid) return false;

      // Display in all variants except the following ones
      return ['2', '5', '6', '7', '8'].includes(this.variant) ? false : true;
    },
    shouldShowPageHero() {
      if (!this.isVariantValid) return false;

      // Display only in following variants
      return ['4', '7', '8'].includes(this.variant) ? true : false;
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;

    if (!VARIANTS.includes(this.variant)) {
      throw new Error(`Invalid layout variant! Consult ${LAYOUT_DOCS}`);
    }

    this.$bus.$on(NEWER_VERSION_EVENT, versions => {
      this.hasNewerVersion = true;
      this.versions = versions;
    });
  }
};
</script>

<style lang="scss" module>
@import '~@/assets/css/generated-variables.scss';
@import '~@/assets/css/media.scss';

// Import shared grid defaults
@import './Layout.scss';

.gridContainer.layout-1 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageContent'
    'pageSideA'
    'pageSideB'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageSideA pageContent pageSideB .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-2 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageContent'
    'pageSideA'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageSideA pageContent pageContent .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-3 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageContent'
    'pageSideB'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageContent pageContent pageSideB .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-4 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageHero'
    'pageContent'
    'pageSideA'
    'pageSideB'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageHero pageHero pageHero .'
      '. pageSideA pageContent pageSideB .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-5 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageContent'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageContent pageContent pageContent .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-6 {
  min-height: unset; // This is different from other layout variants!

  grid-template-columns: 1fr;
  grid-template-areas:
    'pageServiceArea'
    'pageContent';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageContent pageContent pageContent .';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-7 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageHero'
    'pageContent'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageHero pageHero pageHero .'
      '. pageContent pageContent pageContent .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}

.gridContainer.layout-8 {
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'pageHero'
    'pageContent'
    'pageSideA'
    'pageFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. pageHero pageHero pageHero .'
      '. pageSideA pageContent pageContent .'
      'pageFooter pageFooter pageFooter pageFooter pageFooter';
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}
</style>
