<template>
  <Layout variant="7">
    <template #pageContent>
      <NotFound v-if="display404">
        This post doesn't exist
      </NotFound>

      <template v-if="!display404 && post">
        <div :class="$style.wrapper">
          <div
            :class="{
              official: hasTag('official'),
              'save-the-date': hasTag('save-the-date'),
              'platform-updates': hasTag('platform-news')
            }"
            class="box-shadow-white overflow-visible"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1830 135"
              :class="$style.decoration"
            >
              <g>
                <path
                  d="M911.5.5H.5v20.4s427.2 94.3 911 94.3S1829 20.9 1829 20.9V.5H911.5z"
                />
              </g>
            </svg>
            <div :class="$style.contentWrapper">
              <BlogPostHeader
                :title="post.title"
                :author="post.author"
                :publishedAtISO="post.published_at"
                :tag="post.tag"
                :cohortIds="post.cohort_ids"
              />
              <BlogPostExcerpt :excerpt="post.excerpt" />
              <BlogPostContent :content="post.content" />

              <Reactions
                v-if="isFeedbackEnabled"
                :comment="postWithReactions"
                @new_reaction="onNewReaction"
                @add_reaction="onIncreaseReactionCount"
                @remove_reaction="onDecreaseReactionCount"
              />
            </div>
          </div>
          <div
            :class="{ [$style.pageSection]: true, [$style.withModal]: true }"
            v-if="isFeedbackEnabled"
          >
            <h2>
              Discussion
            </h2>

            <i
              @click="showModal = true"
              class="icon icon-help-circle"
              title="Show Help"
            />

            <Comments
              v-if="postId"
              class="box-shadow-white pt-2 pb-2 pl-2 pr-2 overflow-visible"
              objectType="blog_post"
              :objectId="postId"
            />
          </div>

          <ModalNew
            :class="$style.modalWrapper"
            :showModal="showModal"
            :closeWithClickOutside="true"
            v-transfer-dom
            @close="showModal = false"
          >
            <div :class="$style.description">
              <i class="icon icon-info-circle color-info" />

              <div>
                Discussion is where students, or other platform users, can
                comment, post feedback or ask questions on the blogpost content.
                When posting a comment, the author of the blog will be notified
                via email. When replying to a comment - the author and they
                people in the thread will be notified.
              </div>
            </div>
          </ModalNew>
        </div>
      </template>
    </template>
  </Layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { hasErrorStatus } from '@/helpers/errors';

import {
  GET_BLOG_POST,
  GET_CURRENT_USER_PROFILE,
  GET_NEW_DISCUSSIONS_REACTIONS,
  POST_NEW_DISCUSSIONS_REACTION
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import BlogPostHeader from '@/components/blog/BlogPostHeader/BlogPostHeader';
import BlogPostContent from '@/components/blog/BlogPostContent/BlogPostContent';
import BlogPostExcerpt from '@/components/blog/BlogPostExcerpt/BlogPostExcerpt';
import Comments from '@/views/Comments/Comments';
import Reactions from '@/views/Comments/Reactions';

export default {
  name: 'BlogDetail',
  components: {
    NotFound,
    Layout,
    BlogPostHeader,
    BlogPostContent,
    BlogPostExcerpt,
    Comments,
    Reactions
  },
  data() {
    return {
      display404: false,
      showModal: false,
      reactions: [],
      postDetail: undefined
    };
  },
  computed: {
    ...mapGetters(['currentUserProfile', 'isCurrentUserTeachingStaff']),
    post() {
      return this.postDetail ? this.postDetail : undefined;
    },
    postId() {
      return parseInt(this.$route.params.postId);
    },
    postWithReactions() {
      return { ...this.post, ...{ reactions: this.reactions } };
    },
    isFeedbackEnabled() {
      return this.post?.feedback_enabled || false;
    }
  },
  async created() {
    this.$store.dispatch(GET_CURRENT_USER_PROFILE).catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });

    try {
      await this.fetchInitialData();
      await this.fetchReactions();
    } catch (error) {
      if (hasErrorStatus(404, error)) {
        this.display404 = true;
      } else {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    }
  },
  methods: {
    ...mapActions([GET_BLOG_POST]),
    ...mapActions('discussions', [
      POST_NEW_DISCUSSIONS_REACTION,
      GET_NEW_DISCUSSIONS_REACTIONS
    ]),
    ...mapMutations('blog', ['addPostDetail']),
    hasTag(examinedTag) {
      return this.post && this.post.tag && this.post.tag.code === examinedTag;
    },
    async onNewReaction({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'blog_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction
          }
        });

        await this.fetchReactions();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async onIncreaseReactionCount({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'blog_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction
          }
        });

        await this.fetchReactions();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async onDecreaseReactionCount({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'blog_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction,
            delete: true
          }
        });

        await this.fetchReactions();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    fetchReactions() {
      return new Promise(async (resolve, reject) => {
        try {
          const received = await this.GET_NEW_DISCUSSIONS_REACTIONS({
            objectType: 'blog_post',
            objectId: this.postId
          });

          this.reactions = received;

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchInitialData() {
      return new Promise(async (resolve, reject) => {
        try {
          const received = await this.GET_BLOG_POST(this.postId);
          this.postDetail = received;

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};
</script>

<style lang="scss" module>
@import './BlogDetail.scss';
</style>
