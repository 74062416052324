<template>
  <div :class="$style.wrapper">
    <NotFound v-if="display404">
      This resource doesn't exist
    </NotFound>
    <Layout variant="8" v-else class="resource-detail">
      <template #pageHero>
        <h6 class="main-title" :class="$style.header">
          {{ resource.title }}
        </h6>
      </template>
      <template #pageContent>
        <ResourceDetail :resource="resource" />
      </template>

      <template #pageSideA v-if="!isPhoneLayout">
        <div
          :class="{
            [$style.side]: true,
            [$style.stickyNavigation]: !userSettingValue(
              SETTING_CODES.FIXED_NAV
            )
          }"
        >
          <SideWidgetResourceDetail :resource="resource" />
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hasErrorStatus } from '@/helpers/errors';
import { SETTING_CODES } from '@/config/userSettings';

import { GET_RESOURCE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import ResourceDetail from '@/components/resources/ResourceDetail/ResourceDetail';
import SideWidgetResourceDetail from '@/components/resources/SideWidgetResourceDetail/SideWidgetResourceDetail';

export default {
  components: {
    NotFound,
    Layout,
    ResourceDetail,
    SideWidgetResourceDetail
  },
  data() {
    return {
      display404: false,
      resource: {},
      // temporary mock
      topics: []
    };
  },
  computed: {
    ...mapGetters(['userSettingValue']),
    ...mapGetters(['isPhoneLayout']),
    resourceSlug() {
      return this.$route.params.resourceSlug;
    }
  },
  watch: {
    $route() {
      this.fetchResource();
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;

    this.fetchResource();
  },
  methods: {
    fetchResource() {
      this.$store
        .dispatch(GET_RESOURCE, this.resourceSlug)
        .then(resource => {
          this.resource = resource;
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    }
  }
};
</script>

<style lang="scss" module>
@import 'LibraryResource.scss';
</style>
