export default {
  methods: {
    // focus on input inside an element with ref
    focusInput(ref) {
      if (ref && ref.$el) {
        ref.$el.getElementsByTagName('input')[0].focus();
      }
    }
  },
  // focus on a first input in the component
  mounted() {
    if (this && this.$el) {
      const inputs = this.$el.getElementsByTagName('input');
      if (inputs && inputs.length > 0) {
        inputs[0].focus();
      }
    }
  }
};
