<template>
  <div v-if="resource" :class="$style.wrapper">
    <div :class="$style.wrapperContent">
      <div
        :class="$style.attachment"
        v-if="
          resource.type === 'pdf' ||
            resource.type === 'audio' ||
            resource.embedded_html
        "
      >
        <template v-if="resource.type === 'pdf'">
          <iframe
            :src="setPathPDF(resource)"
            width="100%"
            height="700"
            data-cy="resource_detail_pdf"
          />
        </template>

        <template v-else-if="resource.type === 'audio'">
          <iframe
            :src="`/audio/web/audio.html?file=${resource.file.path}`"
            width="100%"
          />
        </template>

        <template v-else>
          <div v-html="resource.embedded_html" />
        </template>
      </div>

      <div v-if="resource.tags" :class="$style.tags">
        <p class="text-dark-grey">Filed under</p>
        <p class="text-dark-grey">{{ tags }}</p>
      </div>

      <SideWidgetResourceDetail
        :resource="resource"
        :class="$style.resourceDetail"
        v-if="isPhoneLayout && displayRelated"
      />

      <div
        class="box-shadow-white"
        :class="$style.learningWrapper"
        v-if="displayRelated"
      >
        <template v-if="hasLearningUnits">
          <div :class="$style.header" @click="flipDisplayLUs(displayLUs)">
            <p class="text-bold paragraph-large" :class="$style.headerText">
              Related Learning Units
            </p>
            <i class="icon icon-chevron-down" v-if="!displayLUs" />
            <i class="icon icon-chevron-up" v-if="displayLUs" />
          </div>

          <TransitionExpand>
            <div v-if="displayLUs">
              <ul :class="$style.list">
                <li v-for="(unit, index) in resource.lo_units" :key="index">
                  <router-link
                    :to="{
                      name: 'learning-unit-detail',
                      params: {
                        learningUnitId: unit.lo_unit_id
                      }
                    }"
                    >{{ unit.name }}</router-link
                  >
                </li>
              </ul>
            </div>
          </TransitionExpand>
        </template>
        <template v-else>
          <div :class="$style.emptyHeader">
            <p class="text-bold paragraph-large" :class="$style.headerText">
              Related Learning Units
            </p>
            <p class="placeholder" :class="$style.text">
              There are no Learning Units to show for this Resource
            </p>
          </div>
        </template>

        <template v-if="hasLearningObjectives">
          <div :class="$style.header" @click="flipDisplayLOs(displayLOs)">
            <p class="text-bold paragraph-large" :class="$style.headerText">
              Related Learning Objectives
            </p>
            <i class="icon icon-chevron-down" v-if="!displayLOs" />
            <i class="icon icon-chevron-up" v-if="displayLOs" />
          </div>

          <TransitionExpand>
            <div v-if="displayLOs">
              <ul :class="$style.list">
                <li
                  v-for="(objective, index) in resource.learning_objectives"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: 'library',
                      query: {
                        objective: objective.learning_objective_id
                      }
                    }"
                    >{{ objective.name }}</router-link
                  >
                </li>
              </ul>
            </div>
          </TransitionExpand>
        </template>
        <template v-else>
          <div :class="$style.emptyHeader">
            <p class="text-bold paragraph-large" :class="$style.headerText">
              Related Learning Objectives
            </p>
            <p class="placeholder" :class="$style.text">
              There are no Learning Objectives to show for this Resource
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getProviderInfo } from '@/helpers/resources';
import TransitionExpand from '@/components/common/TransitionExpand/TransitionExpand';
import SideWidgetResourceDetail from '@/components/resources/SideWidgetResourceDetail/SideWidgetResourceDetail';

export default {
  components: {
    TransitionExpand,
    SideWidgetResourceDetail
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    displayRelated: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data() {
    return {
      displayLUs: true,
      displayLOs: true
    };
  },
  computed: {
    ...mapGetters(['isPhoneLayout']),
    tags() {
      return this.resource.tags.map(tag => tag.label).join(', ');
    },
    provider() {
      return getProviderInfo(this.resource);
    },
    hasLearningUnits() {
      return (
        this.resource &&
        this.resource.lo_units &&
        this.resource.lo_units.length > 0
      );
    },
    hasLearningObjectives() {
      return (
        this.resource &&
        this.resource.learning_objectives &&
        this.resource.learning_objectives.length > 0
      );
    }
  },
  methods: {
    flipDisplayLUs(oldValue) {
      this.displayLUs = !oldValue;
    },
    flipDisplayLOs(oldValue) {
      this.displayLOs = !oldValue;
    },
    setPathPDF(resource) {
      const pdfViewerPath = `/viewer/web/viewer.html?file=`;

      if (resource.file && !resource.file.path.includes('http')) {
        return `${pdfViewerPath}/${resource.file.path}`;
      }

      return `${pdfViewerPath}${resource.file.path}`;
    }
  }
};
</script>

<style module lang="scss">
@import './ResourceDetail.scss';
</style>
