<template>
  <div :class="$style.wrapper">
    <div :class="$style.content">
      <div :class="$style.section">
        <router-link :to="{ name: 'dashboard' }" :class="$style.logo">
          <img
            :class="$style.logo"
            :src="require(`@/assets/images/logos/theme-${THEME}_logo-grey.png`)"
            role="banner"
            alt="candena"
          />
        </router-link>

        <div :class="$style.socialIcons">
          <a
            href="https://www.facebook.com/a.degree.smarter"
            target="_blank"
            rel="noopener"
            ><i class="icon icon-facebook"
          /></a>
          <a
            href="https://twitter.com/adegreesmarter"
            target="_blank"
            rel="noopener"
            ><i class="icon icon-twitter"
          /></a>
          <a
            href="https://www.linkedin.com/company/edu-a-degree-smarter/"
            target="_blank"
            rel="noopener"
            ><i class="icon icon-linkedin"
          /></a>
          <a
            href="https://www.instagram.com/edu_a_degree_smarter/"
            target="_blank"
            rel="noopener"
            ><i class="icon icon-instagram"
          /></a>
        </div>

        <div
          v-if="!hideNavigationItems"
          class="paragraph-small"
          :class="$style.copyright"
        >
          All rights reserved
        </div>
      </div>

      <template v-if="!hideNavigationItems">
        <div :class="$style.section">
          <p class="text-semibold">About EDU</p>
          <ul>
            <a href="https://medical.edu.mt/" target="_blank" rel="noopener">
              <li>EDU Website</li>
            </a>
            <a
              href="https://info.edu.edu.mt/privacy-policy/"
              target="_blank"
              rel="noopener"
            >
              <li>Privacy Policy</li>
            </a>
            <a
              href="https://info.edu.edu.mt/imprint/"
              target="_blank"
              rel="noopener"
            >
              <li>Imprint</li>
            </a>
            <a
              href="https://info.edu.edu.mt/terms-of-service/"
              target="_blank"
              rel="noopener"
            >
              <li>Terms & Conditions</li>
            </a>
          </ul>
        </div>

        <div :class="$style.section">
          <p class="text-semibold">Resources</p>
          <ul>
            <a href="https://info.edu.edu.mt/" target="_blank">
              <li>EDU Info Centre</li>
            </a>
          </ul>
        </div>

        <div :class="$style.contactSection">
          <p class="text-semibold">Help & Support</p>

          <ul>
            <router-link to="/faq">
              <li>FAQ</li>
            </router-link>

            <a href="mailto:support@edu.edu.mt">Contact us</a>
          </ul>
          <div>
            <FormButton
              @click="handleOpened"
              :class="$style.reportProblem"
              data-cy="report_form_open"
              >Report an Issue
            </FormButton>
          </div>
        </div>
      </template>
    </div>

    <ModalNew
      :showModal="showModal"
      v-transfer-dom
      @close="handleClosed"
      :class="{
        [$style.modalWrapper]: true,
        [$style.successWindow]: showSuccess
      }"
    >
      <ReportProblem :displaySuccess="showSuccess" @success="handleReported" />
    </ModalNew>

    <iframe
      src="/api/v1/integrations/ditki/login"
      style="width:0;height:0;border:0; border:none;position:absolute;"
    />
  </div>
</template>

<script>
import { DEFAULT_THEME } from '@/config/themes';

import ReportProblem from '@/components/common/ReportProblem/ReportProblem';

export default {
  components: {
    ReportProblem
  },
  props: {
    hideNavigationItems: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      showSuccess: false
    };
  },
  methods: {
    handleOpened() {
      this.showModal = true;
    },
    handleClosed() {
      this.showModal = false;
      this.showSuccess = false;
    },
    handleReported() {
      this.showSuccess = true;
    }
  },
  created() {
    this.THEME = process.env.THEME || DEFAULT_THEME;
  }
};
</script>

<style lang="scss" module>
@import './PageFooter.scss';
</style>
