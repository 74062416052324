var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"variant":"5"}},[_c('template',{slot:"pageContent"},[(_vm.isLoading)?[_c('InlineLoader')]:(_vm.assignments.length)?[_c('div',{staticClass:"box-shadow-white",class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.perex},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/icons/group-work.svg"),"width":"120px","alt":""}})]),_c('div',[_c('h6',[_vm._v(" There is no Group Assignment available this week. ")]),(_vm.hasFutureItem)?_c('p',[_vm._v(" Next Group Assignment will be available on "),_c('router-link',{attrs:{"to":{
                  name: 'assignment-detail',
                  params: {
                    assignmentId:
                      _vm.futureItem.parent_assignment_id ||
                      _vm.futureItem.assignment_id,
                    scheduledAssignmentId: _vm.futureItem.scheduled_assignment_id
                  }
                }}},[_vm._v(_vm._s(_vm.formatDate(_vm.futureItem.start_at)))])],1):_vm._e()]),_c('p')]),(_vm.hasPastItems)?_c('div',[_c('p',{class:_vm.$style.tableHeading},[_vm._v(" Take a look at the latest Group Assignments ")]),_c('section',{class:_vm.$style.table},[(_vm.isCurrentUserTeachingStaff)?_vm._l((_vm.pastItems.slice(
                  _vm.numberOfPastAssignments
                )),function(row,index){return _c('Item',{key:index,attrs:{"item":row}})}):_vm._l((_vm.pastItems),function(row,index){return _c('Item',{key:index,attrs:{"item":row}})}),_c('footer')],2)]):_vm._e()])]:[_c('div',{class:_vm.$style.empty},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"src":require("@/assets/images/icons/group-work.svg"),"height":"250px","alt":""}}),_c('p',{staticClass:"text-light-grey paragraph-large text-bold semibold"},[_vm._v(" There is no Group Assignment available for this module. ")])])]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }