<template>
  <div
    @click="handleRowClick(item)"
    class="box-shadow-white"
    :class="$style.item"
  >
    <div :class="$style.session">
      <template v-if="isGroupAssignment(item) === true">
        <p>
          Deadline:
          <strong>{{ formatDeadline(item.end_at) }}</strong>
        </p>
      </template>
      <template v-else>
        <p>
          {{ formatDate(item.start_at) }}
          <strong
            >{{ formatTime(item.start_at) }}&nbsp;-&nbsp;{{
              formatTime(item.end_at)
            }}</strong
          >
        </p>
      </template>

      <p v-line-clamp:1="1">
        <strong>{{ item.name }}</strong>
      </p>

      <template v-if="isGroupAssignment(item) === true">
        <p>&nbsp;</p>
      </template>
      <template v-else>
        <p>
          {{ sessionType(item) }}
        </p>
      </template>
    </div>

    <div :class="$style.attendees">
      <p :class="$style.schedule">
        <span>{{ cohortName }}</span>
        &nbsp;
        <span>{{ moduleName }}</span>
      </p>
      <p>
        {{
          userGroups
            .filter(ug => ug.group_type !== 'cohort')
            .map(ug => (ug.object_name ? ug.object_name : ''))
            .join(', ') || '&mdash;'
        }}
      </p>
    </div>

    <div :class="$style.gradingType">
      <p>{{ gradingType }}</p>
    </div>

    <div :class="$style.facilitators">
      <p v-for="(userId, index) in item.facilitators" :key="index">
        <UserName v-if="userId" :user="userProfile(userId)" />
      </p>
    </div>

    <div :class="$style.gradingState">
      <span
        v-if="item.evaluation_status === EVALUATION_STATUS_DONE"
        class="grading-state-evaluated paragraph-small"
        data-cy="grading-state"
      >
        EVALUATED
      </span>
      <span
        v-if="item.evaluation_status === GRADING_PROGRESS.PENDING"
        class="grading-state-pending paragraph-small"
        data-cy="grading-state"
      >
        PENDING
      </span>
    </div>

    <div :class="$style.arrow">
      <i class="icon icon-chevron-right" />
    </div>
  </div>
</template>

<script>
// TODO: Request a change of API results for endpoint /api/v1/assignments/arr:X,Y,Z
const CHANGE_ME_TO_GRADING_TYPES_CONSTANT_VALUE = 2;

import { mapGetters } from 'vuex';
import moment from 'moment';
import { GRADING_PROGRESS, EVALUATION_STATUS_DONE } from '@/config/grading';
import { getAssignmentType } from '@/helpers/schedule';
const moduleShortRegexp = /Module\s*(?<moduleNumber>\d)*/i;

export default {
  name: 'AssignmentsIndexItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['userProfile']),
    cohortName() {
      if (!this.item) return '';
      if (!this.item.cohort) return '';
      if (!this.item.cohort.name) return '';

      return this.item.cohort.name;
    },
    moduleName() {
      if (!this.item) return '';
      if (!this.item.module) return '';
      if (!this.item.module.name) return '';

      const found = this.item.module.name.match(moduleShortRegexp);

      if (found) {
        return `M${found.groups.moduleNumber}`;
      }

      return this.item.module.name;
    },
    userGroups() {
      if (!this.item) return [];
      if (!this.item.user_groups) return [];

      if (Array.isArray(this.item.user_groups)) return this.item.user_groups;

      return [this.item.user_groups];
    },
    gradingType() {
      if (!this.item) return null;
      if (!this.item.grading) return null;
      if (!this.item.grading.assessment_criterias) return null;
      if (!this.item.grading.assessment_criterias.label) return null;

      return this.item.grading.assessment_criterias.label;
    }
  },
  methods: {
    formatDate(dateISOString) {
      return moment(dateISOString).format('ddd DD.MM.YYYY');
    },
    formatTime(dateISOString) {
      return moment(dateISOString).format('H:mm');
    },
    formatDeadline(dateISOString) {
      return moment(dateISOString).format('ddd DD.MM.YYYY H:mm');
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'assignment-detail',
        params: {
          assignmentId: row.parent_assignment_id || row.assignment_id,
          scheduledAssignmentId: row.scheduled_assignment_id
        }
      });

      return;
    },
    sessionType(item) {
      return getAssignmentType(item);
    },
    isGroupAssignment(item) {
      if (!item) return undefined;
      if (!item.grading) return undefined;

      // Can't use GRADING_TYPES.TYPE2 for comparing because API doesn't provide the `grading_component` attribute value,
      // but only the `assessment_template_id` value :(
      return (
        item.grading.assessment_template_id ===
        CHANGE_ME_TO_GRADING_TYPES_CONSTANT_VALUE
      );
    }
  },
  created() {
    this.GRADING_PROGRESS = GRADING_PROGRESS;
    this.EVALUATION_STATUS_DONE = EVALUATION_STATUS_DONE;
  }
};
</script>

<style lang="scss" module>
@import './styles/IndexItem.scss';
</style>
