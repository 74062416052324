var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Assignment Notes")]),_c('router-link',{class:_vm.$style.homeButton,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
          enabled: true,
          initialSortBy: _vm.loadUrlWithSorting()
        }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return (props.row.assignmentUrl)?[(props.column.field === 'assignmentId')?_c('span',{ref:props.row.assignmentNoteId},[_c('router-link',{attrs:{"to":{
                name: 'admin-assignment-node-detail',
                params: { assignmentNoteId: props.row.assignmentNoteId }
              }}},[_vm._v(" "+_vm._s(props.row.assignmentId)+" ")])],1):(props.column.field === 'assignmentName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-assignment-node-detail',
                params: { assignmentNoteId: props.row.assignmentNoteId }
              }}},[_vm._v(" "+_vm._s(props.row.assignmentName)+" ")])],1):(props.column.field === 'unitName')?_c('span',[(props.row.unitName)?_c('router-link',{attrs:{"to":{
                name: 'learning-unit-detail',
                params: { learningUnitId: props.row.unitId }
              }}},[_vm._v(" "+_vm._s(props.row.unitName)+" ")]):_c('span',[_vm._v("-")])],1):(props.column.field === 'authorName')?_c('span',[(props.row.authorName)?_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.authorId }
              }}},[_vm._v(_vm._s(props.row.authorName)+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.authorId))])]):_c('span',[_vm._v("-")])],1):(props.column.field === 'link')?_c('span',[_c('a',{attrs:{"href":props.row.link,"target":"_blank"}},[_vm._v("visit page")])]):_vm._e()]:undefined}}],null,true)})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }