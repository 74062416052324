<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h2>Business Logic</h2>
          <div :class="$style.homeButton">
            <router-link to="/admin" :class="$style.button">
              <i class="icon icon-home"
            /></router-link>
          </div>
        </div>
        <AdminSpecTable
          v-for="spec in specs"
          :key="spec.domain_id"
          :class="$style.table"
          :spec="spec"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import AdminSpecTable from '@/views/Admin/AdminSpecs/AdminSpecTable';

import { GET_ADMIN_SPECS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    AdminSpecTable
  },
  data() {
    return {
      specs: [],
      display404: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchSpecs();
    }
  },
  methods: {
    fetchSpecs() {
      this.$store
        .dispatch(GET_ADMIN_SPECS)
        .then(specs => {
          this.specs = specs;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminSpecs.scss';
</style>
