import { RECENT_CONVERSATION_KEY } from '@/config/localStorage';
import { state, initialStateCopy } from './state';
import { removeDuplicateObjByKey } from '@/helpers/common';

import {
  RESET_STATE,
  SET_GLOBAL_ERROR,
  SET_CONVERSATIONS,
  SET_UNREAD_COUNT,
  SET_CONVERSATION_ID,
  PURGE_CONVERSATION,
  PURGE_PLACEHOLDER_CONVERSATION,
  MERGE_MESSAGES,
  SET_READ_TIMEOUT,
  SET_PLACEHOLDER_CONVERSATION,
  SET_POLLING_CANCEL_TOKEN,
  SET_READY_TO_START,
  SET_USERS_SEARCH_RESULTS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  SET_PARTICIPANTS
} from '@/store/mutations.type';

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      } else {
        state[prop] = undefined;
      }
    }
  },
  [SET_CONVERSATIONS](state, conversations) {
    state.conversations = conversations;
  },
  [SET_CONVERSATION_ID](state, conversationId) {
    state.current_conversation.id = conversationId;
    window.localStorage.setItem(RECENT_CONVERSATION_KEY, conversationId);
  },
  [SET_UNREAD_COUNT](state, count) {
    state.unreadCount = count;
  },
  [SET_READ_TIMEOUT](state, readTimeout) {
    state.current_conversation.readTimeout = readTimeout;
  },
  [SET_PLACEHOLDER_CONVERSATION](
    state,
    { type, participant_ids, participants, object_id }
  ) {
    state.placeholder_conversation = {
      type: type,
      participantIds: participant_ids,
      participants: participants,
      object_id: object_id
    };
  },
  [MERGE_MESSAGES](state, messages) {
    let currentConversationMessages = [
      ...state.current_conversation.messages,
      ...messages
    ];

    // do not include same messages more times
    currentConversationMessages = removeDuplicateObjByKey(
      currentConversationMessages,
      'id'
    );

    state.current_conversation.messages = currentConversationMessages;
  },
  [PURGE_PLACEHOLDER_CONVERSATION](state) {
    state.placeholder_conversation = {
      type: null,
      participantIds: [],
      participants: [],
      object_id: null
    };
  },
  [PURGE_CONVERSATION](state) {
    // stop polling immediatelly
    // cancel can be null in case we haven't started polling yet
    state.polling_cancel_tokens.map(cancel => {
      if (cancel) {
        cancel();
      }
    });
    state.polling_cancel_tokens = [];

    // do not trigger read conversation action in case a conversation
    // is not active anymore and timeout has not been executed yet
    // (this can happen when users clicks on conversation and leaves it
    // very quickly)
    const readTimeout = state.current_conversation.readTimeout;
    if (readTimeout) {
      clearTimeout(readTimeout);
    }

    state.current_conversation = {
      id: null,
      messages: [],
      readTimeout: null
    };
  },
  [SET_POLLING_CANCEL_TOKEN](state, cancelToken) {
    state.polling_cancel_tokens = [...state.polling_cancel_tokens, cancelToken];
  },
  [SET_READY_TO_START](state, value) {
    state.is_ready_to_start = value;
  },
  [SET_USERS_SEARCH_RESULTS](state, foundUserProfiles) {
    state.users_search_results = foundUserProfiles;
  },
  [ADD_PARTICIPANT](state, participantId) {
    state.selected_participant_ids = [
      ...state.selected_participant_ids,
      participantId
    ];
  },
  [REMOVE_PARTICIPANT](state, participantId) {
    const foundIndex = state.selected_participant_ids.indexOf(participantId);

    if (foundIndex !== -1) {
      state.selected_participant_ids.splice(foundIndex, 1);
    }
  },
  [SET_PARTICIPANTS](state, participantIds) {
    state.selected_participant_ids = participantIds;
  }
};
