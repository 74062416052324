<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.alignItemsTop]: lifecycleState === playerStates.results,
      [$style.alignItemsCenter]: lifecycleState !== playerStates.results
    }"
  >
    <NotFound v-if="display403">
      This assessment doesn't belong to current user
    </NotFound>

    <NotFound v-else-if="display404">
      This particular assessment doesn't exist
    </NotFound>

    <template v-else-if="lifecycleState === playerStates.new">
      <!-- Skipped automatically to in_progress -->
    </template>

    <template v-else-if="lifecycleState === playerStates.resume">
      <div :class="{ [$style.lobbyMessage]: true, [$style.started]: true }">
        <img
          src="~@/assets/images/icons/continue_the_quiz.svg"
          :class="$style.icon"
        />
        <h2 class="uppercase">CONTINUE THE QUIZ</h2>
        <p>
          You've started this quiz already; do you want to continue with your
          previous answers or start again from the beginning?
        </p>

        <div :class="$style.navigation">
          <FormButton
            :standard="true"
            @click="continueQuiz"
            data-cy="exam_resume"
            >Continue
          </FormButton>

          <FormButton
            :ghost="true"
            @click="endAndStartNewQuiz"
            data-cy="exam_restart"
            >Start the Quiz
          </FormButton>
        </div>
      </div>
    </template>

    <template v-else-if="lifecycleState === playerStates.running">
      <QuizPlayer @completed="onComplete" @error="handleError" />
    </template>

    <template v-else-if="lifecycleState === playerStates.completed">
      <div :class="{ [$style.lobbyMessage]: true, [$style.completed]: true }">
        <span class="icon icon-check-circle" />
        <h2 class="uppercase">QUIZ COMPLETED</h2>
        <p>
          Well done! You have completed the self-assessment quiz
          <template v-if="quizName">
            <br />
            <strong>{{ quizName }}</strong>
          </template>
        </p>

        <div :class="$style.navigation">
          <FormButton
            :standard="true"
            @click="goToResults"
            data-cy="go_to_results"
            >Review Answers
          </FormButton>
          <FormButton :ghost="true" @click="startNewQuiz" data-cy="exam_start"
            >Retake the Quiz
          </FormButton>
        </div>
      </div>
    </template>

    <template v-else-if="lifecycleState === playerStates.retrospect">
      <QuizPlayer @completed="onReversedComplete" @error="handleError" />
    </template>

    <template v-else-if="lifecycleState === playerStates.ended">
      <div :class="{ [$style.lobbyMessage]: true, [$style.ended]: true }">
        <img
          src="~@/assets/images/icons/retake_the_quiz.svg"
          :class="$style.icon"
        />
        <h2 class="uppercase">RETAKE THE QUIZ</h2>
        <p>
          You have completed this quiz before. Review your answers or take the
          quiz again.
        </p>

        <div :class="$style.navigation">
          <FormButton :ghost="true" @click="goReverse" v-if="reverseAllowed"
            >Go Back to Questions</FormButton
          >
          <FormButton
            :standard="true"
            @click="startNewQuiz"
            data-cy="exam_start"
            >Start the Quiz
          </FormButton>
          <FormButton :ghost="true" @click="goToResults" data-cy="go_to_results"
            >Review Answers
          </FormButton>
        </div>
      </div>
    </template>

    <template v-else-if="lifecycleState === playerStates.results">
      <QuizResults
        data-ref="assessmentDetail"
        :attempt="attempt"
        @retake="startNewQuiz"
        @close="$emit('close')"
      />
    </template>

    <template v-else>
      <!-- Every other state should be considered a bug -->
      <Debug
        :print="{
          quizId,
          attemptId,
          hasStarted,
          lifecycleState,
          reverseAllowed,
          reverseActive,
          firstNotAnsweredQuestionIndex
        }"
      />
    </template>

    <Notice
      type="error"
      v-if="errorMessage"
      :class="$style.errorMessage"
      data-cy="quiz_errors"
      contentStyle="padding-right: 28px"
      :dismissable="true"
      @dismiss="handleErrorDismiss"
    >
      {{ errorMessage }}
    </Notice>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SET_GLOBAL_ERROR, RESET_STATE } from '@/store/mutations.type';
import { playerStates } from '@/config/quizPlayer';
import { VIRTUAL_PAGES } from '@/config/tracking';
import { hasErrorStatus, getResponse } from '@/helpers/errors';

import Notice from '@/components/notices/Notice/Notice';
import Debug from '@/components/common/Debug/Debug';
import NotFound from '@/components/common/NotFound/NotFound';
import QuizPlayer from '@/views/Assessments/RepeatableMultiChoiceQuiz/QuizPlayer';
import QuizResults from '@/views/Assessments/RepeatableMultiChoiceQuiz/QuizResults';

export default {
  name: 'Quiz',
  components: {
    Notice,
    Debug,
    NotFound,
    QuizPlayer,
    QuizResults
  },
  props: {
    currentTime: {
      type: String,
      default: ''
    },
    quizId: undefined
  },
  data() {
    return {
      errorMessage: '',
      display403: false,
      display404: false
    };
  },
  computed: {
    ...mapGetters('quiz', [
      'lifecycleState',
      'reverseAllowed',
      'reverseActive',
      'firstNotAnsweredQuestionIndex',
      'hasStarted',
      'attempt',
      'attemptId',
      'quizName'
    ])
  },
  methods: {
    ...mapActions('quiz', [
      'quizAttempts',
      'quizStart',
      'quizAttemptEnd',
      'quizAttemptRemove',
      'fetchAttempt'
    ]),
    ...mapMutations('quiz', [
      'setReverseAllowed',
      'setReverseActive',
      'setContinueActive',
      'setResultsView'
    ]),
    handleErrorDismiss() {
      this.display403 = false;
      this.display404 = false;
      this.errorMessage = '';
    },
    handleError(error) {
      const response = getResponse(error);

      if (response) {
        // Errors from HTTP Response
        const responseData = response.data;
        const responseMessage = `${response.status} ${response.statusText}: ${responseData.message}`;

        switch (response.status) {
          case 403:
            this.display403 = true;
            this.$store.commit(`quiz/${RESET_STATE}`);
            break;
          case 404:
            this.errorMessage = "This particular assessment doesn't exist";
            this.$store.commit(`quiz/${RESET_STATE}`);
            break;
          case 412:
            this.errorMessage = responseMessage;
            break;
          default:
            this.errorMessage = responseMessage;
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      } else {
        // Other errors like CORS when no request made and thus no response
        this.errorMessage = error;
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async onComplete() {
      try {
        await this.quizAttemptEnd({
          quizId: this.quizId,
          quizAttemptId: this.attemptId
        });

        this.setReverseAllowed(true);
      } catch (error) {
        this.handleError(error);
      }
    },
    onReversedComplete() {
      this.setReverseActive(false);
    },
    async startNewQuiz() {
      try {
        await this.quizStart(this.quizId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async endAndStartNewQuiz() {
      try {
        await this.quizAttemptRemove({
          quizId: this.quizId,
          quizAttemptId: this.attemptId
        });
        await this.quizStart(this.quizId);
      } catch (error) {
        this.handleError(error);
      }
    },
    continueQuiz() {
      this.setContinueActive(true);
    },
    goReverse() {
      this.setReverseActive(true);
    },
    goToResults() {
      this.setResultsView(true);
    }
  },
  beforeCreate() {
    this.playerStates = playerStates;
  },
  async created() {
    this.$mf.logFullPath(
      `${this.$route.fullPath}/${VIRTUAL_PAGES.TEST_PLAYER}`
    );

    try {
      await this.quizAttempts(this.quizId);

      if (this.lifecycleState === this.playerStates.new) {
        await this.startNewQuiz();
      } else {
        await this.fetchAttempt({
          quizId: this.quizId,
          attemptId: this.attemptId
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  },
  destroyed() {
    this.$mf.logFullPath(this.$route.fullPath);
  }
};
</script>

<style lang="scss" module>
@import './Quiz.scss';
</style>
