<template>
  <div>
    <template v-if="isLoading">
      <InlineLoader />
    </template>

    <template v-else>
      <div :class="$style.groupSubmission">
        <table class="grades">
          <thead>
            <th
              v-if="columns.includes(COLUMNS.attendance)"
              :class="$style.columnAttendance"
            >
              Attend.
            </th>
            <th
              v-if="columns.includes(COLUMNS.student)"
              :class="$style.columnStudent"
            >
              Students
            </th>
          </thead>
          <tbody :class="$style.innerGrid">
            <GradingUser
              v-for="(user, index) of participants"
              :key="`group-work-user-${index}`"
              :data-cy="`grading-user-${index}`"
              :user="user"
              :session="session"
              :currentMode="currentMode"
              :scope="SCOPES.group"
              @update-succes="handleUpdate"
              @update-error="handleError"
              @in_action="handleInAction"
              @has_edits="relayHasEdits"
              :isLastRow="index === participants.length - 1"
            />
          </tbody>
        </table>

        <div>
          <h6 :class="$style.header">
            Team Submission
          </h6>

          <template v-if="isCurrentUserStudent">
            <DocumentCard
              :isEmpty="true"
              v-if="
                [SESSION_PROGRESS.DISTANT, SESSION_PROGRESS.IMMINENT].includes(
                  sessionProgress
                )
              "
            >
              You'll be able to upload your submission as soon as the assignment
              starts.
            </DocumentCard>

            <DocumentCard
              :isEmpty="true"
              v-else-if="
                !hasSubmission && sessionProgress === SESSION_PROGRESS.ENDED
              "
            >
              There is no submission for this assignment from this team.
            </DocumentCard>
            <GroupWorkUpload
              v-else
              :forceStep="submissionUploadStep"
              :submission="submission"
              :resource="submissionResource"
              @uploading="onUploadingSubmission"
              @success="onUploadedSubmission"
              @error="onUploadSubmissionError"
              @remove_upload="onRemoveUpload"
              :canRemoveUpload="canRemoveUpload"
              :canReupload="canReupload"
              data-cy="students-upload"
            />
          </template>
          <template v-if="isCurrentUserTeachingStaff">
            <GradingCard
              :submission="submission"
              :resource="submissionResource"
              :uploadFeedbackFile="uploadFeedbackFile"
            />
          </template>
        </div>

        <div>
          <h6 :class="$style.header">
            Tutor Grading
          </h6>
          <template v-if="inViewMode">
            <DocumentCard
              v-if="!hasFeedbackResourceSlug"
              :isEmpty="true"
              :class="{ [$style.uploadMissing]: uploadFeedbackGradeIsMissing }"
            />

            <GradingCard
              v-else
              :resource="feedbackResource"
              :uploadFeedbackFile="uploadFeedbackFile"
              :currentMode="currentMode"
              :class="{ [$style.uploadMissing]: uploadFeedbackGradeIsMissing }"
            />
          </template>

          <template v-if="inEditMode">
            <GradingGroupWorkUpload
              :forceStep="gradingUploadStep"
              :resource="feedbackResource"
              :uploadFeedbackFile="uploadFeedbackFile"
              @uploading="onUploading"
              @success="onUploaded"
              @error="onUploadError"
              @remove_upload="onRemoveUpload"
              data-cy="tutors-upload"
            />
          </template>
        </div>
        <div :class="$style.groupWorkSubgrid">
          <div :class="$style.groupWork">
            <div :class="$style.groupWorkFeedback">
              <h6>Comment</h6>

              <template v-if="inViewMode">
                <template v-if="isCurrentUserStudent">
                  <div
                    :class="{
                      [$style.viewOnlyTextarea]: true
                    }"
                  >
                    <p v-if="textFeedbackModel">{{ textFeedbackModel }}</p>
                    <p v-else class="placeholder">
                      No comment received from the Facilitator.
                    </p>
                  </div>
                </template>
                <template v-if="isCurrentUserTeachingStaff">
                  <div
                    :class="{
                      [$style.viewOnlyTextarea]: true,
                      [$style.missing]: textFeedbackGradeIsMissing
                    }"
                  >
                    <p v-if="textFeedbackModel">{{ textFeedbackModel }}</p>
                    <p v-else class="placeholder">
                      No comment received from the Facilitator.
                    </p>
                  </div>
                </template>
              </template>

              <template v-if="inEditMode">
                <template v-if="isCurrentUserTeachingStaff">
                  <FormTextareaAutoSave
                    ref="textFeedbackModel"
                    rows="10"
                    v-model="textFeedbackModel"
                    placeholder="Write a comment..."
                    :action="textFeedbackSave"
                    :validation="textFeedbackValidate"
                    @validation_result="textFeedbackValidationResult"
                    @state="actionStateChangeHandler"
                    @success="textFeedbackSaveSuccessHandler"
                    @error="textFeedbackSaveErrorHandler"
                    :disabled="inputsDisabled"
                    data-cy="feedback"
                  />

                  <span v-if="textFeedbackIsValid === false">
                    Please provide valid feedback
                  </span>
                </template>
              </template>
            </div>

            <div
              :class="{
                [$style.groupWorkGrade]: true
              }"
            >
              <h6>Grade</h6>
              <div
                :class="{
                  [$style.score]: true
                }"
              >
                <template v-if="inViewMode">
                  <template v-if="isCurrentUserStudent">
                    <span
                      :class="{
                        [$style.viewOnlyScore]: true
                      }"
                    >
                      {{ scoreModel ? scoreModel : '-' }} </span
                    >&nbsp;/&nbsp;{{
                      Number.isNaN(scoreGradeMax) ? '-' : scoreGradeMax
                    }}
                  </template>
                  <template v-if="isCurrentUserTeachingStaff">
                    <span
                      :class="{
                        [$style.viewOnlyScore]: true,
                        [$style.missing]: scoreGradeIsMissing
                      }"
                    >
                      {{ scoreModel ? scoreModel : '-' }} </span
                    >&nbsp;/&nbsp;{{
                      Number.isNaN(scoreGradeMax) ? '-' : scoreGradeMax
                    }}
                  </template>
                </template>
                <template v-if="inEditMode">
                  <template v-if="isCurrentUserTeachingStaff">
                    <FormInputNumberAutoSave
                      ref="scoreModel"
                      v-model="scoreModel"
                      :min="scoreGradeMin"
                      :max="scoreGradeMax"
                      :action="scoreSave"
                      :validation="scoreValidate"
                      @validation_result="scoreValidationResult"
                      @state="actionStateChangeHandler"
                      :disabled="inputsDisabled"
                      @success="scoreSaveSuccessHandler"
                      @error="scoreSaveErrorHandler"
                      :class="{
                        [$style.invalid]: scoreIsValid === false
                      }"
                      data-cy="score"
                    />
                    <span
                      :class="{
                        [$style.invalid]:
                          scoreGradeMaxIsValid === false ||
                          scoreGradeMinIsValid === false
                      }"
                      >&nbsp;/{{ scoreGradeMax }}</span
                    >
                  </template>
                </template>
              </div>
              <div></div>
              <div
                :class="{
                  [$style.invalid]: errors.length > 0
                }"
              >
                <p v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import {
  mapInstanceActions,
  mapInstanceGetters,
  mapInstanceMutations
} from '@urbn/vuex-helpers';
// import Debug from '@/components/common/Debug/Debug';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import GradingUser from '../GradingUser';
import DocumentCard from '../DocumentCard';
import GradingCard from '../GradingCard';

import GroupWorkUpload from './GroupWorkUpload';
import GradingGroupWorkUpload from './GradingGroupWorkUpload';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  MODES,
  TYPES,
  COLUMNS,
  SCOPES,
  SESSION_PROGRESS
} from '@/config/grading';
import {
  getSessionProgress,
  getGradingErrors,
  getSessionColumns
} from '@/helpers/grading';
import { STATES } from '@/config/forms';

import gradingGroup from '@/store/modules/gradingGroup/gradingGroup';
import intervalParse from 'math-interval-parser';

import {
  GET_BULK_USER_PROFILES,
  GET_RESOURCE,
  SAVE_GRADING,
  REMOVE_GRADING,
  SUBMIT_GROUPWORK,
  GET_ASSIGNMENT
} from '@/store/actions.type';

import { removeDuplicateObjByKey } from '@/helpers/common';
import { GRADING_TYPES } from '@/config/grading';
import { isEvaluationPublished } from '@/helpers/grading';
import { logError } from '@/helpers/errors';

const name = 'gradingGroup';

const getNamespace = cmp => {
  const userGroupId = cmp.userGroupId;
  const assignmentId = cmp.session.assignment_id;
  const scheduledAssignmentId = cmp.session.scheduled_assignment_id;

  return `${name}-${assignmentId}-${scheduledAssignmentId}-${userGroupId}`;
};

export default {
  name: 'GradingGroupWork',
  beforeCreate() {
    this.SCOPES = SCOPES;
    this.COLUMNS = COLUMNS;
  },
  created() {
    this.TYPES = TYPES;
    this.SCOPES = SCOPES;
    this.SESSION_PROGRESS = SESSION_PROGRESS;
    this.getSubmissionResource(this.submissionResourceSlug);
    this.isLoading = false;

    const namespace = getNamespace(this);
    this.NS = namespace;

    if (this.NS && !this.$store.hasModule(this.NS)) {
      this.$store.registerModule(this.NS, gradingGroup);
    } else {
      // console.info(`Existing namespace ${this.NS}`);
    }

    // When registering modules dynamically
    // watchers of module getters need to be declared manually as well
    this.$watch(
      'hasEdits',
      function(newValue) {
        this.reportEdits(newValue);
      },
      { immediate: true }
    );

    this.setInitialEvaluations();
  },
  components: {
    InlineLoader,
    GradingUser,
    DocumentCard,
    GradingCard,
    GroupWorkUpload,
    GradingGroupWorkUpload
    // Debug
  },
  props: {
    currentMode: {
      type: String,
      default: MODES.VIEW
    },
    session: {
      type: Object,
      required: true
    },
    userGroupId: {
      type: Number,
      required: true
    },
    nowISO: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      isUploading: false,
      isUploadingSubmission: false,
      profiles: [],
      scoreIsValid: undefined,
      feedbackModel: undefined,
      textFeedbackIsValid: undefined,
      submissionResource: undefined,
      feedbackResource: undefined,
      errorMessage: undefined,
      errors: [],
      isInAction: false,
      state: STATES.idle
    };
  },
  watch: {
    submission(submission) {
      if (!submission) return;

      this.getSubmissionResource(submission.resource_slug);
    },
    session() {
      // If session data is re-fetched in parent view
      // we need to use freshly fetched data from API
      // to set correct values in form
      this.setInitialEvaluations();
    }
  },
  computed: {
    ...mapGetters([
      'isCurrentUserTeachingStaff',
      'isCurrentUserStudent',
      'currentUserId'
    ]),
    ...mapInstanceGetters(getNamespace, [
      'scoreGrade',
      'scoreGradeId',
      'scoreGradeMin',
      'scoreGradeMax',
      'scoreGradeMinIsValid',
      'scoreGradeMaxIsValid',
      'scoreGradeIsMissing',
      'textFeedbackGrade',
      'textFeedbackGradeId',
      'textFeedbackGradeMandatory',
      'textFeedbackGradeIsMissing',
      'uploadFeedbackGrade',
      'uploadFeedbackGradeId',
      'uploadFeedbackFile',
      'uploadFeedbackGradeMandatory',
      'uploadFeedbackGradeIsMissing',
      'hasEdits'
    ]),
    inViewMode() {
      return this.currentMode === MODES.VIEW;
    },
    inEditMode() {
      return this.currentMode === MODES.EDIT;
    },
    assignmentId() {
      if (!this.session) return undefined;
      if (!this.session.assignment_id) return undefined;

      return this.session.assignment_id;
    },
    scheduledAssignmentId() {
      if (!this.session) return undefined;
      if (!this.session.scheduled_assignment_id) return undefined;

      return this.session.scheduled_assignment_id;
    },
    participants() {
      if (!this.userGroup) return [];
      if (!this.userGroup.members) return [];

      const participantsClone = [...this.userGroup.members];

      return participantsClone.sort((a, b) => {
        const aInt = parseInt(a.user_id);
        const bInt = parseInt(b.user_id);

        if (aInt < bInt) {
          return -1;
        }
        if (aInt > bInt) {
          return 1;
        }

        return 0;
      });
    },
    userGroup() {
      if (!this.session) return undefined;

      if (this.isCurrentUserStudent) {
        const filteredUserGroups = this.session.user_groups.filter(
          ug => ug.user_group_id === this.userGroupId
        );

        return filteredUserGroups.find(ug =>
          ug.members.find(m => m.user_id === this.currentUserId)
        );
      }

      const found = this.session.user_groups.find(
        ug => ug.user_group_id === this.userGroupId
      );

      return found ? found : undefined;
    },
    scoreModel: {
      get() {
        return this.scoreGrade;
      },
      set(newValue) {
        this.scoreGradeSet(newValue);
      }
    },
    textFeedbackModel: {
      get() {
        return this.textFeedbackGrade;
      },
      set(newValue) {
        this.textFeedbackGradeSet(newValue);
      }
    },
    grades() {
      if (!this.session) return [];
      if (!this.session.grading) return [];
      if (!this.session.grading.grades) return [];

      return this.session.grading.grades;
    },
    columns() {
      return getSessionColumns(this.session);

      // if (!this.session) return [];
      // if (!this.session.grading) return [];
      // if (!this.session.grading.grades) return [];

      // return this.session.grading.grades
      //   .filter(g => g.scope === SCOPES.user)
      //   .map(g => g.grade_type);
    },
    hasErrors() {
      if (this.columns.includes(COLUMNS.score)) {
        if (this.errors.length) return true;
        if (this.scoreGradeMaxIsValid === false) return true;
      }

      return false;
    },
    hasSubmission() {
      if (!this.submission) return false;

      return this.submission && this.submission.submission_id ? true : false;
    },
    submissionResourceSlug() {
      if (!this.submission || !this.submission.resource_slug) {
        return undefined;
      }

      return this.submission.resource_slug;
    },
    inputsDisabled() {
      return this.inViewMode ? true : false;
    },
    sortedParticipants() {
      const participantsClone = [...this.participants];

      return participantsClone.sort((a, b) => {
        const aInt = parseInt(a.user_id);
        const bInt = parseInt(b.user_id);

        if (aInt < bInt) {
          return -1;
        }
        if (aInt > bInt) {
          return 1;
        }

        return 0;
      });
    },
    submission() {
      if (!this.session) return undefined;
      if (!this.session.submission) return undefined;

      const submissions = Array.isArray(this.session.submission)
        ? this.session.submission
        : [this.session.submission];

      const found = submissions.find(
        submission => submission.user_group_id === this.userGroupId
      );

      return found ? found : undefined;
    },
    sessionProgress() {
      return getSessionProgress(this.session, this.nowISO);
    },
    canRemoveUpload() {
      return false;
    },
    canReupload() {
      return this.sessionProgress === SESSION_PROGRESS.RUNNING;
    },
    isUploadDisabled() {
      return this.sessionProgress !== SESSION_PROGRESS.RUNNING;
    },
    submissionUploadStep() {
      if (this.errorMessage) {
        return 'error';
      }

      if (this.isUploadingSubmission) {
        return 'uploading';
      }

      if (!this.isUploadingSubmission) {
        return this.hasSubmission ? 'success' : 'waiting';
      }

      logError('Undefined submissionUploadStep');
      return undefined;
    },
    hasFeedbackResourceSlug() {
      if (!this.feedbackResource) return false;

      return this.feedbackResource && this.feedbackResource.slug ? true : false;
    },
    gradingUploadStep() {
      if (this.errorMessage) {
        return 'error';
      }

      if (this.isUploading) {
        return 'uploading';
      }

      if (!this.isUploading) {
        return this.hasFeedbackResourceSlug ? 'success' : 'waiting';
      }

      logError('Undefined gradingUploadStep');
      return undefined;
    }
  },
  methods: {
    ...mapActions([GET_RESOURCE]),
    ...mapInstanceActions(getNamespace, [SAVE_GRADING, REMOVE_GRADING]),
    ...mapActions('grading', [GET_ASSIGNMENT]),
    ...mapActions('groupWorkNext', [SUBMIT_GROUPWORK]),
    ...mapInstanceMutations(getNamespace, [
      'scoreGradeIdSet',
      'scoreGradeSet',
      'scoreGradeMinSet',
      'scoreGradeMaxSet',
      'scoreGradeMandatorySet',
      'textFeedbackGradeSet',
      'textFeedbackGradeIdSet',
      'textFeedbackGradeMandatorySet',
      'uploadFeedbackGradeSet',
      'uploadFeedbackGradeIdSet',
      'uploadFeedbackGradeMandatorySet',
      'uploadFeedbackFileSet'
    ]),
    handleInAction(maybeInAction) {
      this.isInAction = maybeInAction;
      this.$emit('in_action', maybeInAction);
    },
    reportEdits(maybeHasEdits) {
      this.$emit('has_edits', {
        origin: `${this.scheduledAssignmentId}_${this.userGroupId}`,
        has_edits: maybeHasEdits
      });
    },
    relayHasEdits(maybeHasEdits) {
      this.$emit('has_edits', maybeHasEdits);
    },
    actionStateChangeHandler(newState) {
      this.state = newState;

      const maybeInAction = [STATES.in_action, STATES.typing].includes(
        newState
      );

      this.$emit('in_action', maybeInAction);
    },
    isPublished(session) {
      return isEvaluationPublished(session);
    },
    teamMembers(team) {
      if (!team) return [];

      if (this.isCurrentUserStudent) {
        return team.members.filter(m => m.user_id === this.currentUserId);
      }

      return team.members;
    },
    getErrors(scorePoints, scoreMaxPoints, scoreMinPoints) {
      return getGradingErrors(scorePoints, scoreMaxPoints, scoreMinPoints);
    },
    async getFeedbackResource(resourceSlug) {
      if (!resourceSlug) return;

      try {
        this.feedbackResource = await this.GET_RESOURCE(resourceSlug);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async getSubmissionResource(resourceSlug) {
      if (!resourceSlug) return;

      try {
        this.submissionResource = await this.GET_RESOURCE(resourceSlug);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    handleViewResource(event) {},
    handleUpdate(grading) {},
    scoreValidate(value) {
      this.errors = this.getErrors(
        value,
        this.scoreGradeMax,
        this.scoreGradeMin
      );

      return this.errors.length ? false : true;
    },
    scoreValidationResult(maybeValid) {
      this.scoreIsValid = maybeValid;
    },
    scoreSave(newValue) {
      return this.SAVE_GRADING({
        assignmentId: this.assignmentId,
        scheduledAssignmentId: this.scheduledAssignmentId,
        payload: {
          user_group_id: this.userGroupId,
          grade_id: this.scoreGradeId,
          grade: newValue
        }
      });
    },
    scoreSaveSuccessHandler() {
      this.$emit('update-succes', {});
    },
    scoreSaveErrorHandler(error) {
      this.$emit('update-error', error);
    },
    textFeedbackSave(newValue) {
      return this.SAVE_GRADING({
        assignmentId: this.assignmentId,
        scheduledAssignmentId: this.scheduledAssignmentId,
        payload: {
          user_group_id: this.userGroupId,
          grade_id: this.textFeedbackGradeId,
          grade: newValue
        }
      });
    },
    textFeedbackValidate() {
      // No restriction on feedback at this moment
      return true;
    },
    textFeedbackValidationResult(maybeValid) {
      this.textFeedbackIsValid = maybeValid;
    },
    textFeedbackSaveSuccessHandler() {
      this.$emit('update-succes', {});
    },
    textFeedbackSaveErrorHandler(error) {
      this.$emit('update-error', error);
    },
    handleError(error) {
      throw new Error('Not implemented!');
    },
    onUploading() {
      this.isUploading = true;
      this.handleInAction(true);
      this.errorMessage = undefined;
    },
    onUploadingSubmission() {
      this.isUploadingSubmission = true;
      this.handleInAction(true);
      this.errorMessage = undefined;
    },
    onUploadSubmissionError(error) {
      this.isUploadingSubmission = false;
      this.handleInAction(false);
      this.errorMessage = error;
    },
    onUploadError(error) {
      this.isUploading = false;
      this.handleInAction(false);
      this.errorMessage = error;
    },
    async onUploadedSubmission({ resource }) {
      this.isUploadingSubmission = false;
      const backupResource = { ...this.submissionResource };

      try {
        this.errorMessage = null;

        await this.SUBMIT_GROUPWORK({
          assignmentId: this.assignmentId,
          scheduledAssignmentId: this.scheduledAssignmentId,
          payload: {
            resource_slug: resource.slug,
            type: GRADING_TYPES.TYPE2
          }
        });

        await this.getSubmissionResource(resource.slug);
        await this.GET_ASSIGNMENT(this.assignmentId);
        this.handleInAction(false);
      } catch (error) {
        this.submissionResource = backupResource;
        this.errorMessage = error;
        this.handleInAction(false);
      }
    },
    async onUploaded({ resource }) {
      this.isUploading = false;
      const backupResource = { ...this.feedbackResource };

      try {
        this.errorMessage = null;

        await this.SAVE_GRADING({
          assignmentId: this.assignmentId,
          scheduledAssignmentId: this.scheduledAssignmentId,
          payload: {
            user_id: null,
            user_group_id: this.userGroupId,
            grade_id: this.uploadFeedbackGradeId,
            grade: resource.slug
          }
        });

        await this.getFeedbackResource(resource.slug);
        await this.GET_ASSIGNMENT(this.assignmentId);
        this.handleInAction(false);
      } catch (error) {
        this.feedbackResource = backupResource;
        this.errorMessage = error;
        this.handleInAction(false);
      }
    },
    async onRemoveUpload() {
      const backupResource = { ...this.feedbackResource };

      try {
        this.errorMessage = null;

        await this.REMOVE_GRADING({
          assignmentId: this.assignmentId,
          scheduledAssignmentId: this.scheduledAssignmentId,
          gradeId: this.uploadFeedbackGradeId,
          userGroupId: this.userGroupId
        });

        this.feedbackResource = undefined;
        this.handleInAction(false);
      } catch (error) {
        this.feedbackResource = backupResource;
        this.errorMessage = error;
        this.handleInAction(false);
      }
    },
    setInitialEvaluations() {
      const {
        uploadFeedbackGradeId,
        uploadFeedbackGrade,
        uploadFeedbackFile,
        uploadFeedbackGradeMandatory
      } = this.getInitialUploadFeedback(
        this.session,
        this.userGroupId,
        this.NS
      );

      // Students are not supposed to see grading until published
      if (
        this.isCurrentUserStudent &&
        this.isPublished(this.session) === false
      ) {
        this.uploadFeedbackGradeIdSet(uploadFeedbackGradeId);
        this.uploadFeedbackGradeSet(undefined);
        this.uploadFeedbackFileSet(undefined);
        this.uploadFeedbackGradeMandatorySet(uploadFeedbackGradeMandatory);
      } else {
        this.uploadFeedbackGradeIdSet(uploadFeedbackGradeId);
        this.uploadFeedbackGradeSet(uploadFeedbackGrade);
        this.uploadFeedbackFileSet(uploadFeedbackFile);
        this.uploadFeedbackGradeMandatorySet(uploadFeedbackGradeMandatory);

        if (this.uploadFeedbackGrade) {
          this.getFeedbackResource(this.uploadFeedbackGrade);
        }
      }

      const {
        textFeedbackGradeId,
        textFeedbackGrade,
        textFeedbackGradeMandatory
      } = this.getInitialTextFeedback(this.session, this.userGroupId, this.NS);

      // Students are not supposed to see grading until published
      if (
        this.isCurrentUserStudent &&
        this.isPublished(this.session) === false
      ) {
        this.textFeedbackGradeIdSet(undefined);
        this.textFeedbackGradeSet(undefined);
        this.textFeedbackGradeMandatorySet(textFeedbackGradeMandatory);
      } else {
        this.textFeedbackGradeIdSet(textFeedbackGradeId);
        this.textFeedbackGradeSet(textFeedbackGrade);
        this.textFeedbackGradeMandatorySet(textFeedbackGradeMandatory);
      }

      const {
        scoreGradeId,
        scoreGrade,
        scoreGradeMin,
        scoreGradeMax,
        scoreGradeMandatory
      } = this.getInitialScore(this.session, this.userGroupId, this.NS);

      // Students are not supposed to see grading until published
      if (
        this.isCurrentUserStudent &&
        this.isPublished(this.session) === false
      ) {
        this.scoreGradeIdSet(scoreGradeId);
        this.scoreGradeSet(undefined);
        this.scoreGradeMinSet(scoreGradeMin);
        this.scoreGradeMaxSet(scoreGradeMax);
        this.scoreGradeMandatorySet(scoreGradeMandatory);
      } else {
        this.scoreGradeIdSet(scoreGradeId);
        this.scoreGradeSet(scoreGrade);
        this.scoreGradeMinSet(scoreGradeMin);
        this.scoreGradeMaxSet(scoreGradeMax);
        this.scoreGradeMandatorySet(scoreGradeMandatory);
      }
    },
    getInitialUploadFeedback(session, userGroupId, namespace) {
      const noEvaluation = {
        uploadFeedbackGradeId: undefined,
        uploadFeedbackGrade: undefined,
        uploadFeedbackFile: undefined,
        uploadFeedbackGradeMandatory: undefined
      };

      const foundCriteria = this.grades
        .filter(g => g.scope === SCOPES.group)
        .filter(g => g.grade_type === TYPES.upload);

      if (!foundCriteria.length) {
        logError(`${namespace}: no criteria`);
        return noEvaluation;
      }

      if (foundCriteria.length !== 1) {
        logError(
          `${namespace}: ambiguous criteria ${JSON.stringify(foundCriteria)}`
        );
        return noEvaluation;
      }

      const criteria = foundCriteria[0];

      if (!session.evaluation || !session.evaluation.items) {
        return {
          ...noEvaluation,
          ...{
            uploadFeedbackGradeId: criteria.grade_id,
            uploadFeedbackGradeMandatory: criteria.mandatory
          }
        };
      }

      const foundEvaluations = session.evaluation.items.filter(
        i => i.user_group_id === userGroupId && criteria.grade_id === i.grade_id
      );

      if (foundEvaluations.length > 1) {
        logError(
          `${namespace}: ambiguous evaluations ${JSON.stringify(
            foundEvaluations
          )}`
        );
        return noEvaluation;
      }

      if (!foundEvaluations.length) {
        return {
          ...noEvaluation,
          ...{
            uploadFeedbackGradeId: criteria.grade_id,
            uploadFeedbackGradeMandatory: criteria.mandatory
          }
        };
      }

      const evaluation = foundEvaluations[0];

      return {
        uploadFeedbackGradeId: evaluation.grade_id,
        uploadFeedbackGrade: evaluation.grade_data,
        uploadFeedbackGradeMandatory: criteria.mandatory,
        uploadFeedbackFile: evaluation.file
      };
    },
    getInitialTextFeedback(session, userGroupId, namespace) {
      const noEvaluation = {
        textFeedbackGradeId: undefined,
        textFeedbackGrade: undefined,
        textFeedbackGradeMandatory: undefined
      };

      const foundCriteria = this.grades
        .filter(g => g.scope === SCOPES.group)
        .filter(g => g.grade_type === TYPES.feedback);

      if (!foundCriteria.length) {
        // logError(`${namespace}: no criteria`);
        return noEvaluation;
      }

      if (foundCriteria.length !== 1) {
        logError(
          `${namespace}: ambiguous criteria ${JSON.stringify(foundCriteria)}`
        );
        return noEvaluation;
      }

      const criteria = foundCriteria[0];

      if (!session.evaluation || !session.evaluation.items) {
        return {
          ...noEvaluation,
          ...{
            textFeedbackGradeId: criteria.grade_id,
            textFeedbackGradeMandatory: criteria.mandatory
          }
        };
      }

      const foundEvaluations = session.evaluation.items.filter(
        i => i.user_group_id === userGroupId && criteria.grade_id === i.grade_id
      );

      if (foundEvaluations.length > 1) {
        logError(
          `${namespace}: ambiguous evaluations ${JSON.stringify(
            foundEvaluations
          )}`
        );
        return noEvaluation;
      }

      if (!foundEvaluations.length) {
        return {
          ...noEvaluation,
          ...{
            textFeedbackGradeId: criteria.grade_id,
            textFeedbackGradeMandatory: criteria.mandatory
          }
        };
      }

      const evaluation = foundEvaluations[0];

      return {
        textFeedbackGradeId: evaluation.grade_id,
        textFeedbackGrade: evaluation.grade_data,
        textFeedbackGradeMandatory: criteria.mandatory
      };
    },
    getInitialScore(session, userGroupId, namespace) {
      const noEvaluation = {
        scoreGradeId: undefined,
        scoreGrade: NaN,
        scoreGradeMin: NaN,
        scoreGradeMax: NaN,
        scoreGradeMandatory: undefined
      };

      const foundCriteria = this.grades
        .filter(g => g.scope === SCOPES.group)
        .filter(g => g.grade_type === TYPES.score);

      if (!foundCriteria.length) {
        // logError(`${namespace}: no criteria`);
        return noEvaluation;
      }

      if (foundCriteria.length !== 1) {
        logError(
          `${namespace}: ambiguous criteria ${JSON.stringify(foundCriteria)}`
        );
        return noEvaluation;
      }

      const criteria = foundCriteria[0];
      const score_range = intervalParse(criteria.score_range);
      let score_min,
        score_max = NaN;

      if (score_range) {
        score_min = score_range.from.included
          ? score_range.from.value
          : score_range.from.value + 1;
        score_max = score_range.to.included
          ? score_range.to.value
          : score_range.to.value - 1;
      }

      if (!session.evaluation || !session.evaluation.items) {
        return {
          ...noEvaluation,
          ...{
            scoreGradeId: criteria.grade_id,
            scoreGradeMin: score_min,
            scoreGradeMax: score_max,
            scoreGradeMandatory: criteria.mandatory
          }
        };
      }

      const foundEvaluations = session.evaluation.items.filter(
        i => i.user_group_id === userGroupId && criteria.grade_id === i.grade_id
      );

      if (foundEvaluations.length > 1) {
        logError(
          `${namespace}: ambiguous evaluations ${JSON.stringify(
            foundEvaluations
          )}`
        );
        return noEvaluation;
      }

      if (!foundEvaluations.length) {
        return {
          ...noEvaluation,
          ...{
            scoreGradeId: criteria.grade_id,
            scoreGradeMin: score_min,
            scoreGradeMax: score_max,
            scoreGradeMandatory: criteria.mandatory
          }
        };
      }

      const evaluation = foundEvaluations[0];

      return {
        scoreGradeId: evaluation.grade_id,
        scoreGrade: evaluation.grade_data,
        scoreGradeMin: score_min,
        scoreGradeMax: score_max,
        scoreGradeMandatory: criteria.mandatory
      };
    }
  }
};
</script>

<style lang="scss" module>
@import '../styles/Columns.scss';
@import '../styles/GradingSession.scss';
</style>
