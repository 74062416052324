<template>
  <div>
    <nav :class="$style.wrapper">
      <router-link :to="{ name: 'dashboard' }" exact>Home</router-link>
      <router-link
        :to="{ name: 'schedule' }"
        :class="{ 'router-link-exact-active': isActive('/schedule') }"
        >Schedule
      </router-link>
      <router-link
        :to="{
          name: 'learning-units-current'
        }"
        :class="{ 'router-link-exact-active': isActive('/learning') }"
        >Units</router-link
      >
      <router-link
        v-if="isCurrentUserStudent === true"
        :to="{ name: 'group-work' }"
        :class="{ 'router-link-exact-active': isActive('/group-work') }"
      >
        Group work</router-link
      >
      <router-link
        :to="{ name: 'library' }"
        :class="{ 'router-link-exact-active': isActive('/library') }"
        >Library
      </router-link>
      <router-link
        :to="{ name: 'gradebook' }"
        :class="{ 'router-link-exact-active': isActive('/gradebook') }"
      >
        Gradebook</router-link
      >
      <router-link
        :to="{ name: 'blog' }"
        :class="{ 'router-link-exact-active': isActive('/blog') }"
        >News</router-link
      >
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isCurrentUserStudent'])
  },
  methods: {
    isActive(pathFragment) {
      return (
        this.$router &&
        this.$router.currentRoute &&
        this.$router.currentRoute.fullPath.startsWith(pathFragment)
      );
    }
  }
};
</script>

<style lang="scss" module>
@import './MainNav.scss';
</style>
