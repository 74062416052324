<template>
  <NotFound v-if="display404" />
  <div v-else :class="$style.wrapper">
    <Layout variant="2">
      <!-- SIDE WIDGET -->
      <template #pageSideA>
        <router-link :class="$style.sideHeader" :to="status"
          ><i class="icon icon-chevron-left" /> Back to
          <span class="capitalize">{{ status }}</span></router-link
        >
        <AdminRecommendedResourceDetailSideWidget
          :detail="detail"
          :editing="editing"
          :emptyFieldsErrorModal="emptyFieldsErrorModal"
          @titleChange="handleTitleChange"
          @commentChange="handleCommentChange"
          @selectedObjectivesChange="handleSelectedObjectivesChange"
        />
      </template>

      <!-- MIDDLE -->
      <template #pageContent>
        <!-- MIDDLE HEADER -->
        <div :class="$style.header">
          <span :class="$style.headerLeft">
            <h6>
              Resource detail
            </h6>
            <span class="adminPrimaryId">{{ detail.resource_id }}</span>
            <span
              :class="{
                [$style.statusIcon]: true,
                [$style.rejected]: detail.status == 'rejected',
                [$style.approved]: detail.status == 'approved'
              }"
              class="paragraph-small"
              >{{ detail.status }}</span
            >
          </span>
          <div :class="$style.buttons">
            <div v-if="detail.status == 'pending'">
              <!-- in editing mode display save/discard buttons -->
              <div v-if="editing" :class="$style.buttonsGroup">
                <FormButton :class="$style.greenButton" @click="save"
                  >Save changes</FormButton
                >
                <FormButton :class="$style.rejectButton" @click="discard"
                  >Discard changes</FormButton
                >
              </div>

              <!-- in non-editing mode display approve/reject and edit buttons -->
              <div v-else :class="$style.buttonsGroup">
                <FormButton
                  v-if="!editing"
                  :class="$style.editButton"
                  :ghost="true"
                  @click="editing = !editing"
                  ><i class="icon icon-pencil"
                /></FormButton>
                <FormButton
                  :class="$style.greenButton"
                  @click="showApproveResourceModal = true"
                  >Approve</FormButton
                >
                <FormButton
                  :class="$style.rejectButton"
                  @click="showDeleteResourceModal = true"
                  >Reject</FormButton
                >
              </div>
            </div>
            <div :class="$style.homeButton">
              <span v-if="detail.status == 'pending'">| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>

        <!-- RESOURCE DETAIL -->
        <div
          class="box-shadow-white p-2 text-center"
          v-if="resourceDetail && resourceDetail.type == 'link'"
        >
          <h6>Link to the resource:</h6>
          <a
            class="btn standard"
            :class="$style.link"
            :href="resourceDetail.content"
            target="_blank"
            >{{ resourceDetail.content }}
          </a>
        </div>

        <ResourceDetail
          v-if="resourceDetail"
          :resource="resourceDetail"
          :displayRelated="false"
        />

        <div v-else class="box-shadow-white p-2">
          <p class="placeholder">No resource detail available</p>
        </div>

        <!-- MODALS - Missing inpit, Approve, Reject -->
        <ModalNew
          :class="$style.adminModal"
          :showModal="emptyFieldsErrorModal"
          v-transfer-dom
          @close="emptyFieldsErrorModal = false"
        >
          <p class="text-center">
            Please make sure you entered title and selected at least one
            learning objective.
          </p>
        </ModalNew>

        <ModalNew
          :class="$style.adminModal"
          :showModal="showApproveResourceModal"
          v-transfer-dom
          @close="handleApproveModalClosed"
        >
          <div :class="$style.adminRecommendedResourceDetailModal">
            <p>Do you want to approve this recommended resource?</p>
            <div :class="$style.checkbox">
              <FormLabel>
                <FormCheckbox :value="'yes'" v-model="teamCheck" />
                I agree with CT Team
              </FormLabel>
              <FormLabel>
                <FormCheckbox :value="'yes'" v-model="copyrightCheck" />
                I agree with Copyrights Check
              </FormLabel>
            </div>

            <p
              v-if="emptyFieldsErrorMsg"
              :class="$style.error"
              class="text-center"
            >
              Please make sure you entered title and selected at least one
              learning objective.
            </p>

            <div :class="$style.buttons">
              <FormButton
                @click="approve"
                :standard="true"
                :disabled="!teamCheck || !copyrightCheck"
                >Approve</FormButton
              >
              <FormButton :ghost="true" @click="handleApproveModalClosed"
                >Cancel</FormButton
              >
            </div>
          </div>
        </ModalNew>

        <ConfirmationModal
          :question="deleteResourceQuestion"
          @handleClosed="showDeleteResourceModal = false"
          @agreedAction="reject()"
          :showModal="showDeleteResourceModal"
          :optionOne="'Reject'"
          :optionOneStyle="'red'"
        />
      </template>
    </Layout>
  </div>
</template>

<script>
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  GET_ADMIN_RECOURCE_PENDING_DETAIL,
  SET_ADMIN_RECOURCE_PENDING_DETAIL,
  GET_RESOURCE
} from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminRecommendedResourceDetailSideWidget from './AdminRecommendedResourceDetailSideWidget';
import ConfirmationModal from '@/components/admin/ConfirmationModal/ConfirmationModal';
import ResourceDetail from '@/components/resources/ResourceDetail/ResourceDetail';

export default {
  components: {
    Layout,
    NotFound,
    AdminRecommendedResourceDetailSideWidget,
    ConfirmationModal,
    ResourceDetail
  },
  data() {
    return {
      display404: false,
      detail: {},
      editing: false,
      title: '',
      comment: '',
      selectedObjectives: [],
      emptyFieldsErrorModal: false,
      emptyFieldsErrorMsg: false,
      deleteResourceQuestion:
        'Are you sure you want to reject this resource recommendation?',
      showDeleteResourceModal: false,
      showApproveResourceModal: false,
      teamCheck: false,
      copyrightCheck: false,
      status: '',
      resourceDetail: null
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchResources();
    }
  },
  methods: {
    ...mapActions([SET_ADMIN_RECOURCE_PENDING_DETAIL, GET_RESOURCE]),
    fetchResources() {
      this.$store
        .dispatch(
          GET_ADMIN_RECOURCE_PENDING_DETAIL,
          this.$route.params.resourceId
        )
        .then(detail => {
          this.detail = detail;
          this.status = detail.status;

          if (detail.title) {
            this.title = detail.title;
          }

          if (this.detail.resource_slug) {
            this.GET_RESOURCE(this.detail.resource_slug).then(resource => {
              this.resourceDetail = resource;
            });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleApproveModalClosed() {
      this.showApproveResourceModal = false;
      this.teamCheck = false;
      this.copyrightCheck = false;
      this.emptyFieldsErrorMsg = false;
    },
    handleTitleChange(newTitle) {
      this.title = newTitle;
    },
    handleCommentChange(newComment) {
      this.comment = newComment;
    },
    handleSelectedObjectivesChange(newObjectives) {
      this.selectedObjectives = newObjectives;
    },
    discard() {
      this.editing = false;
    },
    save() {
      if (this.selectedObjectives.length == 0 || !this.title.trim()) {
        this.emptyFieldsErrorModal = true;
      } else {
        this.SET_ADMIN_RECOURCE_PENDING_DETAIL({
          resourceId: this.$route.params.resourceId,
          status: 'pending',
          title: this.title,
          comment: this.comment,
          selectedLearningObjectives: this.selectedObjectives
        })
          .then(response => {
            this.editing = false;
            this.fetchResources();
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    approve() {
      if (this.selectedObjectives.length == 0 || !this.title.trim()) {
        this.emptyFieldsErrorMsg = true;
      } else {
        this.SET_ADMIN_RECOURCE_PENDING_DETAIL({
          resourceId: this.$route.params.resourceId,
          status: 'approved',
          title: this.title,
          comment: this.comment,
          selectedLearningObjectives: this.selectedObjectives
        })
          .then(() => {
            this.$router.push({
              name: 'admin-approved-resources'
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    reject() {
      this.SET_ADMIN_RECOURCE_PENDING_DETAIL({
        resourceId: this.$route.params.resourceId,
        status: 'rejected',
        title: this.title,
        comment: this.comment,
        selectedLearningObjectives: this.selectedObjectives
      })
        .then(() => {
          this.$router.push({
            name: 'admin-rejected-resources'
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminRecommendedResources.scss';
@import '../AdminTables.scss';
</style>
