<template>
  <div :class="$style.wrapper" class="box-shadow-white p-2">
    <router-link :to="{ name: 'user-profile' }" data-cy="preview">
      <FormButton :ghost="true" :class="$style.myProfileButton"
        >Preview</FormButton
      >
    </router-link>
    <div :class="$style.avatar">
      <AvatarUpload
        :user="user"
        :userId="user.user_id"
        :class="$style.avatarUpload"
        data-ref="badge"
      />
    </div>
    <div :class="$style.info">
      <div :class="$style.name" class="item-locked" data-ref="name">
        <h2>
          <span> <UserName :user="user" /> </span><i class="icon" />
        </h2>
      </div>

      <div :class="$style.bio">
        <div>
          <form :class="$style.location">
            <FormLabel :class="$style.city">
              <i class="icon icon-map-marker" />
              <FormInputAutoSave
                data-ref="cityOfResidence"
                data-cy="cityOfResidence"
                v-model="cityOfResidence"
                placeholder="Your city ..."
                :action="updateProfile('city_of_residence')"
                :large="true"
                :class="$style.cityInput"
              />
            </FormLabel>

            <FormLabel>
              <FilterSelect
                data-ref="country"
                data-cy="country"
                :class="$style.countryInput"
                v-model="country"
                :submenuVisible="countrySubmenuVisible"
                :disabled="cityOfResidence ? null : true"
                @submenu_visibility="handleCountrySubmenuChange"
                :action="updateProfileAndCloseMenu('country_id')"
              >
                <template #title>
                  <span>{{ countryName }}</span>
                </template>

                <template #options>
                  <option
                    v-for="(country, index) in domainCountries"
                    :value="country.country_id"
                    :key="`option-${index}`"
                  >
                    {{ country.name }}
                  </option>
                </template>
                <template #items>
                  <template v-for="(country, index) in domainCountries">
                    <span
                      :value="country.country_id"
                      :key="index"
                      @click="simulateChange(country.country_id)"
                    >
                      {{ country.name }}
                    </span>
                  </template>
                </template>
              </FilterSelect>
            </FormLabel>
          </form>

          <div :class="$style.separator" />
        </div>

        <FormTextareaAutoSave
          data-ref="bio"
          data-cy="bio"
          v-model="bio"
          :showCounter="true"
          :maxCount="500"
          :rows="4"
          :action="updateProfile('bio')"
          placeholder="Introduce yourself briefly to give your profile visitors a better idea of who you are."
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_USER_PROFILE } from '@/store/actions.type';
import { ADD_USER_PROFILE_SETTINGS } from '@/store/mutations.type';

import { getUserLocation } from '@/helpers/users';

import AvatarUpload from '@/components/uploads/AvatarUpload/AvatarUpload';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

export default {
  components: {
    AvatarUpload,
    FilterSelect
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      countrySubmenuVisible: false
    };
  },
  methods: {
    updateProfile(param) {
      return newValue => {
        return this.$store
          .dispatch(UPDATE_USER_PROFILE, {
            userId: this.user.user_id,
            profileData: {
              [param]: newValue,
              city_of_residence: this.cityOfResidence // required by API
            }
          })
          .catch(error => {
            void error;
          });
      };
    },
    updateProfileAndCloseMenu(param) {
      // newValue is passed by this.action(event.target.value) call in FilterSelect
      return newValue => {
        return this.$store
          .dispatch(UPDATE_USER_PROFILE, {
            userId: this.user.user_id,
            profileData: {
              [param]: newValue,
              city_of_residence: this.cityOfResidence // required by API
            }
          })
          .catch(error => {
            void error;
          })
          .then(() => {
            this.countrySubmenuVisible = false;
          });
      };
    },
    simulateChange(selectedValue) {
      let select = this.$el.querySelector('select');
      select.value = selectedValue;

      const event = new MouseEvent('change', {
        view: window,
        bubbles: true,
        cancelable: false
      });

      select.dispatchEvent(event);
    },
    handleCountrySubmenuChange(newSubmenuState) {
      this.countrySubmenuVisible = newSubmenuState;
    }
  },
  computed: {
    ...mapGetters(['userProfileSettings', 'domainCountries', 'hasAvatars']),
    userId() {
      if (!this.user) return undefined;
      if (!this.user.user_id) return undefined;

      return parseInt(this.user.user_id);
    },
    userLocation() {
      return getUserLocation(this.user, this.domainCountries);
    },
    teamName() {
      if (
        this.user &&
        this.user.teams &&
        this.user.teams[0] &&
        this.user.teams[0].name
      ) {
        return this.user.teams[0].name;
      }

      return '';
    },
    countryName() {
      if (!this.country) return 'Country';

      const found = this.domainCountries.find(item => {
        return item.country_id === this.country;
      });

      if (found && found.name) return found.name;

      return 'Country';
    },
    bio: {
      get() {
        const profile = this.userProfileSettings(this.userId);

        if (!profile || !profile.bio) {
          return undefined;
        }

        return profile.bio;
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, { bio: value });
      }
    },
    cityOfResidence: {
      get() {
        const profile = this.userProfileSettings(this.userId);

        if (
          !profile ||
          !profile.profile_data ||
          !profile.profile_data.city_of_residence
        ) {
          return undefined;
        }

        return profile.profile_data.city_of_residence;
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          city_of_residence: value
        });
      }
    },
    country: {
      get() {
        const profile = this.userProfileSettings(this.userId);

        if (!profile || !profile.country_id) {
          return undefined;
        }

        return profile.country_id;
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, { country_id: value });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './TopWidgetUserSettings.scss';
</style>
