<template>
  <span v-click-outside="hidePicker" class="reactions">
    <template v-for="(reactionObj, index) of reactions">
      <Tooltip :key="index" @show="getReactionsNames(reactionObj)">
        <button
          class="textonly"
          @click="
            hasReacted(reactionObj) === true
              ? onRemoveReaction(reactionObj)
              : onAddReaction(reactionObj)
          "
          :class="{
            [$style.reaction]: true,
            [$style.pill]: true,
            [$style.reacted]: hasReacted(reactionObj) === true ? true : false
          }"
          :data-cy="`reaction-${index}`"
          :id="`reaction-${index}`"
        >
          {{ reactionObj.reaction }}
          <small>{{ reactionObj.count }}</small>
        </button>

        <template #inner>{{ reactionTitle }}</template>
      </Tooltip>
    </template>

    <button
      @click="flipPicker"
      :class="{ [$style.emoji]: true, [$style.pill]: true }"
      data-cy="reaction-picker"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 -0.5 5.043 5.122"
      >
        <path
          d="M105.833 150.283a2.117 2.117 0 1 0 0-4.233 2.117 2.117 0 0 0 0 4.233zm0-3.836a1.72 1.72 0 1 1 0 3.44 1.72 1.72 0 0 1 0-3.44zm-1.058.926a.265.265 0 1 1 .529 0 .265.265 0 0 1-.529 0zm1.587 0a.265.265 0 1 1 .53 0 .265.265 0 0 1-.53 0zm.265 1.27.34.204a1.322 1.322 0 0 1-2.268 0l.34-.204a.925.925 0 0 0 1.588 0z"
          style="stroke-width:.132292"
          transform="translate(-103.717 -146.05)"
        />
        <path
          d="M108.772 149.751v.344a.172.172 0 0 1-.172.172h-.745v.745a.172.172 0 0 1-.172.172h-.344a.172.172 0 0 1-.172-.172v-.745h-.745a.172.172 0 0 1-.172-.172v-.344c0-.095.077-.172.172-.172h.745v-.745c0-.095.077-.172.172-.172h.344c.095 0 .172.077.172.172v.745h.745c.095 0 .172.077.172.172z"
          style="fill-opacity:1;stroke:#fff;stroke-width:.375276;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          transform="translate(-103.717 -146.05)"
        />
      </svg>

      <EmojiPickerCustom
        v-if="shouldDisplayPicker"
        :showSearch="false"
        :showCategories="false"
        :emojiWithBorder="false"
        :exceptEmojis="[
          'confounded',
          'triumph',
          'angry',
          'rage',
          'pout',
          'lying_face',
          'nauseated_face',
          'japanese_ogre',
          'japanese_goblin',
          'hankey',
          'poop',
          'shit',
          'middle_finger',
          'fu'
        ]"
        @select="onSelected"
        :class="$style.emojis"
      />
    </button>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { emojisDefault } from '@/components/common/EmojiPickerCustom/emojis.js';
import { MAYBE_GET_USER_PROFILES } from '@/store/actions.type';
import { getUserFullName } from '@/helpers/users';
import { logError } from '@/helpers/errors';

import ClickOutside from 'vue-click-outside';

export default {
  name: 'Reactions',
  directives: {
    ClickOutside
  },
  data() {
    return {
      shouldDisplayPicker: false,
      reactionTitle: '...'
    };
  },
  props: {
    comment: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['currentUserId', 'userProfile']),
    reactions() {
      if (!this.comment) return [];
      if (!Array.isArray(this.comment.reactions)) return [];
      const reactionsCopy = [...this.comment.reactions];

      return reactionsCopy.sort((itemA, itemB) => {
        if (itemA.reaction > itemB.reaction) {
          return 1;
        } else if (itemA.reaction < itemB.reaction) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  },
  methods: {
    ...mapActions([MAYBE_GET_USER_PROFILES]),
    hasReacted(reactionObj) {
      if (!reactionObj) return undefined;
      if (!Array.isArray(reactionObj.user_ids)) return undefined;

      return reactionObj.user_ids.includes(this.currentUserId);
    },
    onSelected(emojiPickerObj) {
      const foundMyReaction = this.reactions.find(
        r =>
          r.reaction === emojiPickerObj.data &&
          r.user_ids.includes(this.currentUserId)
      );

      const foundSameReaction = this.reactions.find(
        r => r.reaction === emojiPickerObj.data
      );

      if (foundMyReaction) {
        this.hidePicker();
        return;
      }

      if (foundSameReaction) {
        this.onAddReaction(foundSameReaction);
        this.hidePicker();
        return;
      }

      this.$emit('new_reaction', {
        reactionObj: {
          count: 1,
          reaction: emojiPickerObj.data,
          user_ids: [this.currentUserId]
        },
        comment: this.comment
      });

      this.hidePicker();
    },
    onAddReaction(reactionObj) {
      const obj = JSON.parse(JSON.stringify(reactionObj));
      this.$emit('add_reaction', {
        reactionObj: {
          ...obj,
          ...{
            count: obj.count + 1,
            user_ids: [...obj.user_ids, this.currentUserId]
          }
        },
        comment: this.comment
      });
    },
    onRemoveReaction(reactionObj) {
      const obj = JSON.parse(JSON.stringify(reactionObj));
      this.$emit('remove_reaction', {
        reactionObj: {
          ...obj,
          ...{
            count: obj.count - 1,
            user_ids: obj.user_ids.filter(id => id !== this.currentUserId)
          }
        },
        comment: this.comment
      });
    },
    flipPicker() {
      this.shouldDisplayPicker = !this.shouldDisplayPicker;
    },
    showPicker() {
      this.shouldDisplayPicker = true;
    },
    hidePicker() {
      this.shouldDisplayPicker = false;
    },
    getEmojiTitle(reactionObj) {
      if (!emojisDefault) return '';

      const found = emojisDefault.find(emoji => emoji.data === reactionObj);

      return found
        ? found.aliases.map(alias => alias.replace('_', ' ')).join(', ')
        : '';
    },
    async getReactionsNames(reactionObj) {
      const userIds = reactionObj?.user_ids || [];
      const emoji = this.getEmojiTitle(reactionObj.reaction);

      try {
        await this.MAYBE_GET_USER_PROFILES(userIds);

        const userNames = userIds
          .map(userId => this.userProfile(userId))
          .map(profile => getUserFullName(profile));

        this.reactionTitle = `${userNames.join(', ')} reacted with ${emoji}`;
      } catch (error) {
        logError(error);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './Reactions.scss';
</style>
