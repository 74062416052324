export const CONVERSATIONS_POLLING_MS = 30 * 1000;
export const MESSAGES_POLLING_MS = 60 * 1000;
export const READ_AFTER_MS = 2 * 1000;

export const PARTICIPANTS_LIMIT = 25;

export const CONVERSATION_TYPES = {
  ADHOC: 'ad_hoc',
  TEAM: 'team',
  CLINICAL_GROUP: 'clinical_group'
};

// Anytime a conversation is created and doesn't have any sent message yet
// it has the following conversation id
export const PLACEHOLDER_ID = 'lobby';
