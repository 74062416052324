<template>
  <label v-bind="$attrs" :class="$style.wrapper">
    <slot />
  </label>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<style lang="scss" module>
@import 'FormLabel.scss';
</style>
