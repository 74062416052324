<template>
  <div :class="$style.messageForm">
    <form
      @submit="onSendMessage"
      @keydown.shift.enter="onSendMessage"
      @keydown.enter.exact="onSendMessage"
    >
      <DoNotTrack style="width: 100%">
        <div :class="$style.textareaWrapper">
          <div :class="$style.expander" data-ref="expander">
            <textarea
              v-model="message"
              placeholder="Write your message..."
              @input="updateTextAreaHeight"
              :maxlength="maxCharLength"
              rows="1"
            />
          </div>

          <div v-click-outside="hideEmojis" :class="$style.emoji">
            <i @click="showEmojis" class="icon icon-smile-o" />
            <VEmojiPicker
              v-if="showEmojiPicker"
              :showSearch="false"
              @select="onSelectEmoji"
              :class="$style.emojis"
            />
          </div>
        </div>
      </DoNotTrack>
      <FormButton
        type="submit"
        :standard="true"
        :disabled="currentConversation ? null : true"
        :class="$style.submit"
      >
        <i class="icon icon-paper-plane" />
      </FormButton>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VEmojiPicker } from 'v-emoji-picker';
import ClickOutside from 'vue-click-outside';

import { logError } from '@/helpers/errors';
import { charactersCounter } from '@/helpers/conversations';

import { SEND_MESSAGE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  directives: {
    ClickOutside
  },
  components: {
    VEmojiPicker
  },
  props: {
    // Promise, accepts message through parameter
    onMessageSend: {
      type: Function
    }
  },
  data() {
    return {
      message: '',
      maxCount: 4000,
      showEmojiPicker: false
    };
  },
  computed: {
    ...mapGetters('chats', ['currentConversation']),
    maxCharLength() {
      if (this.countdown === '') return 0;

      return this.countdown > 0 ? this.maxCount : 0;
    },
    countdown() {
      return charactersCounter(this.message, this.maxCount);
    },
    trimmedMessage() {
      if (this.message === null) {
        return '';
      }

      return this.message.trim();
    }
  },
  methods: {
    ...mapActions('chats', [SEND_MESSAGE]),
    updateTextAreaHeight(event) {
      // https://css-tricks.com/auto-growing-inputs-textareas/
      this.$el.querySelector('[data-ref=expander]').dataset.value =
        event.target.value;
    },
    hideEmojis() {
      this.showEmojiPicker = false;
    },
    showEmojis() {
      this.showEmojiPicker = true;
    },
    onSelectEmoji(dataEmoji) {
      if (this.countdown === '') return;

      // emojis count as 4 characters
      if (this.countdown > 3) {
        this.message += dataEmoji.data;
      }

      this.hideEmojis();
    },
    onSendMessage(evt) {
      if (!this.currentConversation) return;

      if (evt.shiftKey && evt.keyCode === 13) {
        return;
      }

      evt.preventDefault();

      this.$emit('sendMessage', this.message);

      if (this.trimmedMessage) {
        this.onMessageSend(this.trimmedMessage)
          .then(() => {
            this.message = '';
            this.updateTextAreaHeight({ target: { value: '' } });
          })
          .catch(error => {
            logError(error);
          });
      }

      this.$el.querySelector('textarea').style.height = 'auto';
    }
  }
};
</script>

<style lang="scss" module>
@import './MessageForm.scss';
</style>
