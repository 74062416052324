// Use nodejs module export style

module.exports = {
  white: '#fff',
  'theme-name': 'candena',
  primaryColor: '#02b1d9',
  'background-color': '#F5F4F2',
  'grey-border': '1px solid #d0cfce',
  'border-color': '#d0cfce',
  'background-gray': '#f6f6f6',
  'light-grey': '#b2b2b2',
  'dark-grey': '#888888',
  'basic-dark': '#000000',
  'light-yellow': '#fdf8ed',
  'light-red': '#ffdbe0',
  'dark-red': '#9b293a',
  'light-green': '#d0ecd8',
  'dark-green': '#6cb781',
  'light-blue': '#d6edf1',
  'dark-blue': '#517ea7',
  'dark-clinical-rotation': '#6abfb7',
  'max-content-width': '565px',
  'aside-width-small': '203px',
  'aside-width': '249px',
  'header-height': '42px',
  'no-title': '41px',
  'blog-category-color-1': '#7f6495',
  'blog-category-color-2': '#5c7f81',
  'blog-category-color-3': '#8b5e3b',
  'error-message': '#ff3e3e',
  'title-text-black': '#333333',
  'font-size': '14px',
  'font-size-large': '16px',
  newrow: '#00a287' // https://app.zeplin.io/project/602bde01f776d521f16e859a/styleguide
};
