<template>
  <div :class="$style.list">
    <h2>Category -> icon mapping</h2>
    <p>
      <a
        href="https://github.com/candena/app/commits/master/app-platform/src/views/Learning/LearningUnitIcons.vue"
        target="_blank"
        >History of changes</a
      >
    </p>

    <div
      v-for="(category, index) in categories"
      :key="index"
      :class="$style.item"
    >
      <span>id: {{ category.category_id }}</span>
      <LearningUnitCategoryIcon :category="category" />
      <strong>{{ category.name }}</strong>
    </div>
  </div>
</template>

<script>
import LearningUnitCategoryIcon from './LearningUnitCategoryIcon';

export default {
  name: 'LearningUnitIcons',
  components: {
    LearningUnitCategoryIcon
  },
  computed: {
    categories() {
      return [
        { category_id: 1, name: 'Miscellaneous' },
        { category_id: 2, name: 'Biochemistry and Biology' },
        { category_id: 3, name: 'Collaborative / Communication Skills' },
        { category_id: 4, name: 'Anaesthesiology' },
        { category_id: 5, name: 'Collaborative & Communication Skills' },
        { category_id: 6, name: 'Anatomy' },
        { category_id: 7, name: 'Evidence Based Medicine' },
        { category_id: 8, name: 'Emergency Medicine' },
        { category_id: 9, name: 'Practical Procedures Skills' },
        { category_id: 10, name: 'Physiology' },
        { category_id: 11, name: 'Biology' },
        { category_id: 12, name: 'Terminology' },
        { category_id: 13, name: 'Microbiology and Infectology' },
        { category_id: 14, name: 'Dermatology' },
        { category_id: 15, name: 'Internal Medicine' },
        { category_id: 16, name: 'Medicine of Aging and the Elderly' },
        { category_id: 17, name: 'Orthopaedics / Traumatology' },
        { category_id: 18, name: 'Neurology' },
        { category_id: 19, name: 'Surgery' },
        { category_id: 20, name: 'Urology' },
        { category_id: 21, name: 'Physiology' },
        { category_id: 22, name: 'General Medicine / Family Medicine' },
        { category_id: 23, name: 'WHO Top 10' },
        { category_id: 24, name: 'ORL' },
        { category_id: 25, name: 'Obstetrics / Gynaecology' },
        { category_id: 26, name: 'Paediatrics' },
        { category_id: 27, name: 'Pharmacology and Toxicology' },
        { category_id: 28, name: 'Cytology / Histology / Embryology' },
        { category_id: 29, name: 'Immunology / Allergology' },
        { category_id: 30, name: 'Palliative Care' },
        { category_id: 33, name: 'Physics' },
        { category_id: 34, name: 'Chemistry' },
        { category_id: 35, name: 'Sociology' },
        { category_id: 36, name: 'Leading Symptoms' },
        { category_id: 37, name: 'CANMeds' },
        { category_id: 38, name: 'Pathology' },
        { category_id: 39, name: 'Mental Health / Psychiatry' },
        { category_id: 40, name: 'Integrative' },
        { category_id: 41, name: 'Nutrition' },
        { category_id: 42, name: 'Medical Psychology' },
        { category_id: 43, name: 'Laboratory Medicine' },
        { category_id: 44, name: 'Diagnostics Evaluation' },
        { category_id: 45, name: 'Endocrine Pancreas' },
        { category_id: 46, name: 'Metabolic Syndrome & Diabetes' },
        { category_id: 47, name: 'General Pharmacology' },
        { category_id: 48, name: 'Introduction to Neuroanatomy' },
        { category_id: 49, name: 'The Hypothalamus' },
        { category_id: 50, name: 'The Thyroid Gland' },
        { category_id: 51, name: 'Blood and Haematopoiesis' },
        { category_id: 52, name: 'Cardiac Output and Blood Volume' },
        { category_id: 53, name: 'Medical Law and Ethics' },
        {
          category_id: 54,
          name:
            'Preventive Medicine, Occupational Medicine, Environmental Medicine and Public Health'
        },
        { category_id: 55, name: 'Research and Research Methods' },
        {
          category_id: 56,
          name: 'Statistics / Research Methodology / Clinical Informatics'
        },
        { category_id: 57, name: 'Human Genetics' },
        { category_id: 58, name: 'Reproductive Medicine' },
        { category_id: 59, name: 'Forensic Medicine' },
        { category_id: 60, name: 'Blood Pressure' },
        { category_id: 61, name: 'CanMEDS' },
        {
          category_id: 62,
          name: 'Clinical Chemistry, Laboratory, Radiology and Nuclear Medicine'
        },
        { category_id: 63, name: 'SRL' },
        { category_id: 64, name: 'CanMEDS Communicator' },
        { category_id: 65, name: 'CanMEDS Medical Expert' },
        { category_id: 66, name: 'CanMEDS Collaborator' },
        { category_id: 67, name: 'Scholar' },
        { category_id: 68, name: 'Communicator' },
        { category_id: 69, name: 'Collaborator' },
        { category_id: 70, name: 'Chemistry and Biochemistry' },
        { category_id: 71, name: 'Mentoring' },
        { category_id: 72, name: 'Microbiology and Infectology' },
        { category_id: 73, name: 'Clinical Rotation' },
        { category_id: 74, name: 'Psychiatry and Psychotherapy' },
        { category_id: 75, name: 'Embryology and Histology' },
        { category_id: 76, name: 'General Practice' },
        { category_id: 77, name: 'Pharmacology' },
        { category_id: 78, name: 'Obstetrics and Gynaecology' },
        { category_id: 79, name: 'Physics and Chemistry' },
        { category_id: 80, name: 'Medical Psychology and Sociology' },
        {
          category_id: 81,
          name: 'Legal Medicine, Medical Ethics and Forensics'
        },
        { category_id: 82, name: 'Preventive Medicine and Public Health' },
        { category_id: 83, name: 'Medical English' },
        { category_id: 84, name: 'EBM' },
        { category_id: 85, name: 'Professionalism and Social Competence' },
        { category_id: 86, name: 'Comunicator' },
        {
          category_id: 87,
          name:
            'Preventive Medicine, Occupational Medicine, Environmental Medicine and Public Health'
        },
        { category_id: 88, name: 'SHIDA' },
        { category_id: 89, name: 'Onboarding' },
        { category_id: 90, name: 'Medical Expert' },
        {
          category_id: 91,
          name:
            'Preventive Medicine, Occupational Medicine, Environmental Medicine and Public Health'
        },
        { category_id: 92, name: 'Leader' },
        { category_id: 93, name: 'Emergency Medicine and Anaesthesia' },
        { category_id: 94, name: 'Health Advocate' },
        { category_id: 95, name: 'Professional' },
        {
          category_id: 104,
          name: 'Traumatology, Orthopaedics and Rehabilitation'
        },
        { category_id: 105, name: 'Ophthalmology' },
        { category_id: 106, name: 'Social Compentence' },
        { category_id: 107, name: 'Clinical Reasoning' },
        { category_id: 108, name: 'Diagnostic imaging' },
        {
          category_id: 109,
          name: 'Clinical Chemistry, Laboratory, Radiology and Nuclear Medicine'
        },
        { category_id: 110, name: 'Pharmacology and Toxicology' },
        { category_id: 111, name: 'Biochemistry and Biology' },
        { category_id: 112, name: 'Medicine of Aging and the Elderly' },
        {
          category_id: 113,
          name:
            'Preventive Medicine, Occupational Medicine, Environmental Medicine and Public Health'
        },
        { category_id: 114, name: 'Psychiatry and Psychotherapy' },
        { category_id: 115, name: 'Professionalism and Soft Skills' },
        { category_id: 116, name: 'General Practice' },
        { category_id: 117, name: 'Research and Research Methods' },
        {
          category_id: 118,
          name: 'Traumatology, Orthopaedics and Rehabilitation'
        },
        { category_id: 119, name: 'Physics and Chemistry' },
        {
          category_id: 120,
          name: 'Legal Medicine, Medical Ethics and Forensics'
        },
        { category_id: 121, name: 'Nursing Skills' },
        { category_id: 122, name: 'Radiology / Nuclear Medicine' },
        { category_id: 123, name: 'Biochemistry' },
        { category_id: 124, name: 'Microbiology / Infectiology' },
        { category_id: 125, name: 'Pharmacology / Pharmacotherapy' },
        { category_id: 126, name: 'Geriatrics' },
        {
          category_id: 127,
          name: 'Epidemiology / Preventive Medicine / Hygiene'
        },
        {
          category_id: 128,
          name: 'Clinical Medicine and Professionalism and Soft Skills'
        },
        {
          category_id: 129,
          name: 'Hygiene, Preventive Medicine and Public Health'
        },
        { category_id: 132, name: 'General Practice and Family Medicine' },
        { category_id: 133, name: 'Oncology and Palliative Care' },
        { category_id: 134, name: 'Clinical Medicine' },
        { category_id: 135, name: 'Elective' }
      ];
    }
  }
};
</script>

<style lang="scss" module>
.list {
  max-width: 33%;
  margin: 0 auto;
}

.item {
  display: grid;
  grid-template-columns: 56px auto 1fr;
  grid-gap: 0 14px;
  margin: 7px;
  padding: 7px;

  & > * {
    align-self: center;
  }

  &:nth-child(odd) {
    background-color: #efefef;
  }

  svg {
    min-width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
  }
}
</style>
