<template>
  <div
    :class="{
      [$style.gridContainer]: true,
      [$style.layoutConversations]: true
    }"
  >
    <header
      :class="{
        [$style.pageHeader]: true,
        [$style.unstick]: userSettingValue(SETTING_CODES.FIXED_NAV) === true
      }"
    >
      <slot name="pageHeader">
        <PageHeader :hideNavigationItems="hideNavigationItems" />
      </slot>
    </header>
    <section :class="$style.pageServiceArea">
      <slot name="pageServiceArea">
        <AnnouncementNotice
          v-if="announcement && announcement.content"
          data-ref="globalAnnouncement"
          :message="announcement.content"
          :slug="announcement.slug"
        />

        <ErrorNotice
          v-if="globalErrorClientMessage"
          data-ref="globalError"
          :message="globalErrorClientMessage"
        />

        <Notice
          v-if="hasNewerVersion"
          data-ref="globalNotice"
          type="info"
          :dismissable="false"
        >
          <template #icon>
            <i class="icon icon-info" @click="showVersions = !showVersions" />
          </template>
          <template #default>
            New version of the platform is available. Please reload this page.
            <p v-if="showVersions">
              {{ versions }}
            </p>
          </template>
        </Notice>
      </slot>
    </section>
    <header :class="$style.sideHeader">
      <slot name="sideHeader"></slot>
    </header>
    <aside :class="$style.sideContent" class="box-shadow-white">
      <slot name="sideContent"></slot>
    </aside>
    <header :class="$style.contentHeader">
      <slot name="contentHeader"></slot>
    </header>
    <header :class="$style.contentSubHeader">
      <slot name="contentSubHeader"></slot>
    </header>
    <content :class="$style.content" class="box-shadow-white">
      <slot name="content"></slot>
    </content>
    <footer :class="$style.contentFooter">
      <slot name="contentFooter"></slot>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SETTING_CODES } from '@/config/userSettings';
import { NEWER_VERSION_EVENT } from '@/config/tracking';

import Notice from '@/components/notices/Notice/Notice';
import ErrorNotice from '@/components/notices/ErrorNotice/ErrorNotice';
import AnnouncementNotice from '@/components/notices/AnnouncementNotice/AnnouncementNotice';

import PageHeader from '@/components/common/PageHeader/PageHeader';

export default {
  name: 'LayoutConversations',
  components: {
    PageHeader,
    AnnouncementNotice,
    ErrorNotice,
    Notice
  },
  props: {
    hideNavigationItems: {
      type: Boolean
    }
  },
  data() {
    return {
      hasNewerVersion: false,
      showVersions: false,
      versions: {
        runningVersion: undefined,
        availableVersion: undefined
      }
    };
  },
  computed: {
    ...mapGetters([
      'globalErrorClientMessage',
      'userSettingValue',
      'announcements'
    ]),
    announcement() {
      return this.announcements && this.announcements.length
        ? this.announcements[0]
        : undefined;
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;

    this.$bus.$on(NEWER_VERSION_EVENT, versions => {
      this.hasNewerVersion = true;
      this.versions = versions;
    });
  }
};
</script>

<style lang="scss" module>
@import '~@/assets/css/generated-variables.scss';
@import '~@/assets/css/media.scss';

// Import shared grid defaults
@import './Layout.scss';

.gridContainer {
  grid-row-gap: 0;
  min-height: unset;
}

.sideHeader {
  grid-area: sideHeader;
  margin: 7px 14px;
}

.sideContent {
  grid-area: sideContent;
  overflow-x: hidden;
  overflow-y: auto;
}

.contentHeader {
  grid-area: contentSubHeader;
}

.contentSubHeader {
  grid-area: contentSubHeader;
}

.content {
  grid-area: content;
  overflow-x: hidden;
  overflow-y: auto;
}

.contentFooter {
  grid-area: contentFooter;
  margin: 14px;

  @include tabletUp {
    margin: 14px 0 42px 0;
  }
}

.gridContainer.layout-conversations {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'contentHeader'
    'content'
    'contentFooter';

  @include tabletUp {
    grid-template-columns: 1fr $aside-width-small minmax(0, $max-content-width) $aside-width-small 1fr;
    grid-template-rows: auto auto auto auto 1fr auto;
    grid-template-areas:
      'pageHeader pageHeader pageHeader pageHeader pageHeader'
      '. pageServiceArea pageServiceArea pageServiceArea .'
      '. sideHeader contentHeader contentHeader .'
      '. sideContent contentSubHeader contentSubHeader .'
      '. sideContent content content .'
      '. . contentFooter contentFooter .';
  }

  @media only screen and (min-width: 1100px) {
    grid-template-columns: 1fr $aside-width minmax(0, $max-content-width) $aside-width 1fr;
  }
}
</style>
