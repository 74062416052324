<template>
  <div>
    <div v-html="processedText" :class="[textClass ? textClass : '']"></div>
    <span v-show="shouldTruncate">
      <a
        href="#"
        :class="[linkClass ? linkClass : 'button-text-link']"
        v-show="isCollapsed"
        @click="setToExpanded($event)"
        >{{ expandLabel }}</a
      >
      <a
        href="#"
        :class="[linkClass ? linkClass : 'button-text-link']"
        v-show="isExpanded"
        @click="setToCollapsed($event)"
        >{{ collapseLabel }}</a
      >
    </span>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isExpanded: false
    };
  },
  props: {
    seeMore: {
      type: String
    },
    seeLess: {
      type: String
    },
    text: {
      type: String
    },
    textClass: {
      type: String,
      default: ''
    },
    linkClass: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 80
    }
  },
  computed: {
    isCollapsed() {
      return !this.isExpanded;
    },
    shouldTruncate() {
      if (!this.text) return false;

      if (this.text.length > this.limit) return true;

      return false;
    },
    expandLabel() {
      if (this.seeMore) return this.seeMore;

      return 'See more…';
    },
    collapseLabel() {
      if (this.seeLess) return this.seeLess;

      return 'See less…';
    },
    processedText() {
      if (!this.text) return '';

      if (!this.isExpanded && this.shouldTruncate) {
        const textCopy = this.text;

        return `${textCopy.substring(0, this.limit)}…`;
      } else {
        return this.text;
      }
    }
  },
  methods: {
    setToExpanded(e) {
      e.preventDefault();
      this.isExpanded = true;
    },
    setToCollapsed(e) {
      e.preventDefault();
      this.isExpanded = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './SeeMore.scss';
</style>
