<template>
  <SideWidget
    :class="$style.wrapper"
    class="box-shadow-white p-1"
    :title="false"
  >
    <template #content>
      <div :class="$style.logo" v-if="getProviderLogo(resource)">
        <a :href="getProviderInfo(resource).url" target="_blank"
          ><img
            :src="require(`@/assets/images/logos/${getProviderLogo(resource)}`)"
        /></a>
      </div>
      <div :class="$style.defaultLogo" v-else>
        <img :src="require('@/assets/images/logos/theme-edu_logo.png')" />
      </div>

      <div :class="$style.link">
        <a
          :href="resource.content"
          class="btn standard link"
          target="_blank"
          rel="noopener"
        >
          Open the link
        </a>
      </div>

      <div :class="$style.voting">
        <ResourceFeedback v-if="resource.feedback" :resource="resource" />
      </div>

      <div :class="$style.description">
        <p class="text-semibold">Description</p>
        <SeeMore :limit="500" :text="resource.description" />
      </div>
    </template>
  </SideWidget>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getProviderInfo,
  getProviderLogo,
  getProviderColor
} from '@/helpers/resources';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import ResourceFeedback from '@/components/resources/ResourceFeedback/ResourceFeedback';

export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  components: {
    SideWidget,
    ResourceFeedback
  },
  computed: {
    ...mapGetters(['resourceGroups'])
  },
  created() {
    this.getProviderLogo = getProviderLogo;
    this.getProviderColor = getProviderColor;
    this.getProviderInfo = getProviderInfo;
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetResourceDetail.scss';
</style>
