<template>
  <div
    :style="
      `border-color: ${getProviderColor(
        resource
      )}; box-shadow: 2px 2px 0px 2px ${getProviderColor(resource)}`
    "
    :class="$style.wrapper"
  >
    <router-link
      :to="{
        name: 'library-resource',
        params: { resourceSlug: resource.slug }
      }"
      target="_blank"
    >
      <header :title="resource.title">
        <img
          v-if="getLibraryIcon(resource)"
          :src="
            require(`@/assets/images/icons/library-icons/${getLibraryIcon(
              resource
            )}`)
          "
        />

        <img
          v-if="getProviderLogo(resource)"
          :src="require(`@/assets/images/logos/${getProviderLogo(resource)}`)"
        />
      </header>
    </router-link>

    <router-link
      :to="{
        name: 'library-resource',
        params: { resourceSlug: resource.slug }
      }"
      target="_blank"
    >
      <main :title="resource.title">
        <p v-line-clamp:3="1">{{ resource.title }}</p>
      </main>
    </router-link>

    <footer>
      <div>
        <slot></slot>
      </div>

      <ResourceBookmark
        :resourceSlug="resource.slug"
        wrapperStyle="width: 32px; height: 24px; font-size: 20px;"
      />
    </footer>
  </div>
</template>

<script>
import {
  getLibraryIcon,
  getProviderLogo,
  getProviderColor
} from '@/helpers/resources';

import ResourceBookmark from '@/components/resources/ResourceBookmark/ResourceBookmark';

export default {
  name: 'ResourceCardSmall',
  components: {
    ResourceBookmark
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  created() {
    this.getLibraryIcon = getLibraryIcon;
    this.getProviderLogo = getProviderLogo;
    this.getProviderColor = getProviderColor;
  }
};
</script>

<style lang="scss" module>
@import './ResourceCardSmall.scss';
</style>
