<template>
  <div :class="$style.wrapper" class="box-shadow-white p-2">
    <div :class="$style.adminFaqList" v-if="filteredFaqQuestions">
      <div
        v-for="(question, index) in filteredFaqQuestions"
        :key="index"
        :class="$style.item"
      >
        <i
          @click="handleOpened(question)"
          :class="$style.edit"
          class="icon icon-edit"
        />
        <span>
          <h6 @click="toggleQuestion(index)">
            {{ question.question }}
            <i
              v-if="openQuestions.includes(index)"
              class="icon icon-chevron-up"
              :class="$style.arrow"
            />
            <i
              v-if="!openQuestions.includes(index)"
              class="icon icon-chevron-down"
              :class="$style.arrow"
            />
          </h6>
          <p v-show="openQuestions.includes(index)" :class="$style.answer">
            <span v-html="question.answer" />
          </p>
        </span>
      </div>
    </div>
    <p v-else class="placeholder">No questions for this topic</p>

    <ModalNew
      :class="{ [$style.adminModal]: true, [$style.wideModal]: true }"
      :showModal="showModal"
      v-transfer-dom
      @close="handleClosed"
    >
      <AdminFaqForm
        :faqTopics="faqTopics"
        :editedQuestion="editedQuestion"
        @createOrUpdateFaq="updateFaqQuestion"
      />
    </ModalNew>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AdminFaqForm from '@/components/admin/AdminFaqForm/AdminFaqForm';
import { ADMIN_UPDATE_FAQ_QUESTION } from '@/store/actions.type';
export default {
  data() {
    return {
      openQuestions: [],
      showModal: false,
      editedQuestion: {}
    };
  },
  components: {
    AdminFaqForm
  },
  props: {
    faqQuestions: {
      type: Array,
      required: true
    },
    faqTopics: {
      type: Array,
      required: true
    }
  },
  watch: {
    $route() {
      this.filteredFaqQuestions;
      this.openQuestions = [];
    }
  },
  computed: {
    filteredFaqQuestions() {
      return this.faqQuestions.filter(
        question =>
          question.faq_topic_id === parseInt(this.$route.params.topicId)
      );
    }
  },
  methods: {
    ...mapActions([ADMIN_UPDATE_FAQ_QUESTION]),
    updateFaqQuestion(faqData) {
      this.$store
        .dispatch(ADMIN_UPDATE_FAQ_QUESTION, faqData)
        .then(() => {
          this.$emit('fetchQuestions');
          this.handleClosed();
        })
        .then(() => {
          this.$router.push({
            name: 'admin-faq-topic',
            params: {
              topicId: faqData.payload.faqTopicId
            }
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    toggleQuestion(index) {
      let arrayIndex = this.openQuestions.indexOf(index);

      if (this.openQuestions.includes(index)) {
        this.openQuestions.splice(arrayIndex, 1);
      } else {
        this.openQuestions.push(index);
      }
    },
    handleOpened(question) {
      this.showModal = true;

      if (question) {
        this.editedQuestion = question;
      }
    },
    handleClosed() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminFaq.scss';
@import '../AdminTables.scss';
</style>
