<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'id'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'admin-recommended-resources-detail',
              params: { resourceId: props.row.resourceId }
            }"
          >
            {{ props.row.id }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'name'">
          <router-link
            :class="$style.resourceName"
            :to="{
              name: 'admin-recommended-resources-detail',
              params: { resourceId: props.row.resourceId }
            }"
          >
            {{ props.row.name }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'resourceId'">
          <span :ref="props.row.resourceId" class="adminSecondaryId">{{
            props.row.resourceId
          }}</span>
        </span>
        <span v-else-if="props.column.field === 'author'">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.author.user_id }
            }"
          >
            {{ props.row.author.first_name + ' ' + props.row.author.last_name }}
          </router-link>
          <p class="adminSecondaryId">{{ props.row.author.user_id }}</p>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';

import { mapGetters } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { GET_ADMIN_RESOURCES_PENDING } from '@/store/actions.type';

import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      resources: [],
      columns: [
        {
          label: 'Note ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'File name',
          field: 'name',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('name')
          }
        },
        {
          label: 'Resource ID',
          field: 'resourceId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('resourceId')
          }
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('status')
          }
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('type')
          }
        },
        {
          label: 'Created at',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('createdAt')
          }
        },
        {
          label: 'Author',
          field: 'author',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('author')
          }
        }
      ],
      rows: [],
      scrollToId: null
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.params.resourceId) {
        vm.scrollToId = from.params.resourceId;
      }
    });
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchResources();
    }
  },
  methods: {
    fetchResources() {
      this.$store
        .dispatch(GET_ADMIN_RESOURCES_PENDING)
        .then(resources => {
          this.resources = resources;
        })
        .then(resources => {
          this.createRows();
        })
        .then(() => {
          if (this.scrollToId) {
            this.$refs[this.scrollToId].scrollIntoView({ block: 'center' });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      if (!this.resources.length) return [];

      this.resources.filter(resource => {
        this.rows.push({
          id: resource.assignment_note_id || '',
          resourceId: resource.resource_id || '',
          name: resource.file_name || '',
          status: resource.status || '',
          type: resource.type || '',
          createdAt:
            moment(resource.created_at).format('DD.MM.yyyy HH:mm') || '',
          author: resource.user || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'name':
          return this.$route.query.name;
          break;
        case 'resourceId':
          return this.$route.query.resourceId;
          break;
        case 'status':
          return this.$route.query.status;
          break;
        case 'type':
          return this.$route.query.type;
          break;
        case 'createdAt':
          return this.$route.query.createdAt;
          break;
        case 'author':
          return this.$route.query.author;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        name: column.name || [],
        resourceId: column.resourceId || [],
        status: column.status || [],
        type: column.type || [],
        createdAt: column.createdAt || [],
        author: column.author || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminRecommendedResources.scss';
</style>
