<template>
  <div :class="$style.wrapper">
    <div :class="$style.searchUser" v-click-outside="hideDropdown">
      <!-- Hide input and show User name when selected -->
      <div v-if="userId" :class="$style.selectedUser">
        <p>{{ selectedUserName }}</p>
        <i @click="clearSelectedUser()" class="icon icon-close" />
      </div>

      <!-- Show input to search user -->
      <div v-else>
        <FormInput
          v-model="searchTerm"
          :placeholder="'Search user to add ( By name or ID ) ...'"
        />
        <i :class="$style.searchIcon" class="icon icon-plus" />
        <AvatarSelect
          v-if="dropdownVisible && searchTerm"
          :class="$style.avatarSelect"
          :users="filterUsers"
          :searchRegExp="searchRegExp"
          size="35px"
          @select="selectUser"
        />
      </div>
    </div>
    <FormButton :class="$style.greenButton" @click="addUser()" :standard="true"
      >Add New User
    </FormButton>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { getUserFullName } from '@/helpers/users';
import debounce from 'lodash.debounce';
import ClickOutside from 'vue-click-outside';

import {
  GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS,
  ADD_USER_TO_GROUP,
  GET_USER_PROFILE
} from '@/store/actions.type';
import {
  SET_ADMIN_USERS_SEARCH_RESULTS,
  SET_GLOBAL_ERROR
} from '@/store/mutations.type';

import AvatarSelect from '@/components/common/AvatarSelect/AvatarSelect';

const DEBOUNCE_WAIT_MS = 500;

export default {
  components: {
    AvatarSelect
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      selectedUserName: '',
      userId: null,
      dropdownVisible: false,
      searchTerm: '',
      searchRegExp: undefined
    };
  },
  props: {
    eligibleUsersGroupId: {
      type: Number
    },
    addUserGroupId: {
      type: Number
    }
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm) {
        this.searchRegExp = undefined;
        this.SET_ADMIN_USERS_SEARCH_RESULTS([]);
      }

      this.searchRegExp = new RegExp(newSearchTerm, 'ig');
      this.searchUsers(newSearchTerm);
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'usersSearchResults']),
    filterUsers() {
      if (!Array.isArray(this.usersSearchResults)) return [];
      return this.usersSearchResults;
    }
  },
  methods: {
    ...mapMutations([SET_ADMIN_USERS_SEARCH_RESULTS]),
    addUser() {
      this.$store
        .dispatch(ADD_USER_TO_GROUP, {
          userGroupId: this.addUserGroupId,
          user_id: this.userId
        })
        .then(() => {
          this.$emit('reloadMembers');
          this.clearSelectedUser();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    searchUsers: debounce(function(searchTerm) {
      this.$store
        .dispatch(
          GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS,
          this.eligibleUsersGroupId
        )
        .then(eligibleUsers => {
          this.SET_ADMIN_USERS_SEARCH_RESULTS({ eligibleUsers, searchTerm });
        })
        .then(() => {
          this.dropdownVisible = true;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        });
    }, DEBOUNCE_WAIT_MS),
    selectUser(userId) {
      this.userId = userId;
      this.$store.dispatch(GET_USER_PROFILE, userId).then(() => {
        this.selectedUserName = getUserFullName(this.userProfile(this.userId));
      });
    },
    clearSelectedUser() {
      this.userId = null;
      this.searchTerm = '';
      this.selectedUserName = '';
    },
    hideDropdown() {
      this.dropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" module>
@import '@/views/Admin/AdminTables.scss';
@import './AdminSearchNewUser.scss';
</style>
