<template>
  <div>
    <NotFound v-if="display404">
      <p>This forum doesn't exist.</p>
      <p>
        Go to
        <router-link
          :to="{
            name: 'discussions'
          }"
        >
          list of forums.
        </router-link>
      </p>
    </NotFound>
    <Layout variant="5" v-else>
      <template v-if="forum" #pageContent>
        <div :class="$style.header">
          <h2 class="uppercase" :class="$style.title">{{ forum.name }}</h2>
          <FormButton
            :class="$style.btn"
            :standard="true"
            @click="showNewTopicForm = true"
          >
            <i class="icon icon-edit" />
            <span>New discussion</span>
          </FormButton>
        </div>

        <TopicNewForm
          v-if="showNewTopicForm"
          @close="closeNewTopicForm"
          @submit="createNewTopic"
        />

        <ForumTopicsList :forumSlug="forumSlug" :topics="topics" />
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  GET_DISCUSSION_FORUM,
  GET_DISCUSSION_FORUM_TOPICS,
  CREATE_NEW_DISCUSSION_FORUM_TOPIC
} from '@/store/actions.type';

import { hasErrorStatus } from '@/helpers/errors';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import ForumTopicsList from '@/components/discussions/ForumTopicsList/ForumTopicsList';
import TopicNewForm from '@/components/discussions/TopicNewForm/TopicNewForm';

export default {
  components: {
    NotFound,
    Layout,
    ForumTopicsList,
    TopicNewForm
  },
  data() {
    return {
      display404: false,
      showNewTopicForm: false
    };
  },
  computed: {
    ...mapGetters('forumTopics', [
      'forum',
      'topics',
      'newTopicSubject',
      'newTopicContent',
      'newTopicHasErrors'
    ]),
    forumSlug() {
      return this.$route.params.forumSlug;
    }
  },
  created() {
    this.fetchForum();
    this.fetchForumTopics();
  },
  destroyed() {
    this.purge();
  },
  methods: {
    ...mapMutations('forumTopics', [
      'setForum',
      'setTopics',
      'purgeNewTopic',
      'setNewTopicSubjectErrors',
      'setNewTopicContentErrors',
      'purgeNewTopicErrors',
      'purge'
    ]),
    closeNewTopicForm() {
      this.showNewTopicForm = false;
    },
    fetchForum() {
      this.$store
        .dispatch(GET_DISCUSSION_FORUM, this.forumSlug)
        .then(forum => {
          this.setForum(forum);
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    },
    fetchForumTopics() {
      this.$store
        .dispatch(GET_DISCUSSION_FORUM_TOPICS, this.forumSlug)
        .then(topics => {
          this.setTopics(topics);
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    },
    checkNewTopicForm() {
      if (!this.newTopicSubject) {
        this.setNewTopicSubjectErrors([{ message: 'Please fill in subject' }]);
      }

      if (!this.newTopicContent) {
        this.setNewTopicContentErrors([{ message: 'Please fill in content' }]);
      }

      return !this.newTopicHasErrors;
    },
    async createNewTopic() {
      this.purgeNewTopicErrors();

      if (!this.checkNewTopicForm()) {
        return;
      }

      try {
        await this.$store.dispatch(CREATE_NEW_DISCUSSION_FORUM_TOPIC, {
          forumSlug: this.forumSlug,
          topicSubject: this.newTopicSubject,
          topicContent: this.newTopicContent
        });

        this.closeNewTopicForm();
        this.purgeNewTopic();
        this.fetchForumTopics();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './ForumTopics.scss';
</style>
