var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePicker),expression:"hidePicker"}],staticClass:"reactions"},[_vm._l((_vm.reactions),function(reactionObj,index){
var _obj;
return [_c('Tooltip',{key:index,on:{"show":function($event){return _vm.getReactionsNames(reactionObj)}},scopedSlots:_vm._u([{key:"inner",fn:function(){return [_vm._v(_vm._s(_vm.reactionTitle))]},proxy:true}],null,true)},[_c('button',{staticClass:"textonly",class:( _obj = {}, _obj[_vm.$style.reaction] = true, _obj[_vm.$style.pill] = true, _obj[_vm.$style.reacted] = _vm.hasReacted(reactionObj) === true ? true : false, _obj ),attrs:{"data-cy":("reaction-" + index),"id":("reaction-" + index)},on:{"click":function($event){_vm.hasReacted(reactionObj) === true
            ? _vm.onRemoveReaction(reactionObj)
            : _vm.onAddReaction(reactionObj)}}},[_vm._v(" "+_vm._s(reactionObj.reaction)+" "),_c('small',[_vm._v(_vm._s(reactionObj.count))])])])]}),_c('button',{class:( _obj = {}, _obj[_vm.$style.emoji] = true, _obj[_vm.$style.pill] = true, _obj ),attrs:{"data-cy":"reaction-picker"},on:{"click":_vm.flipPicker}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 -0.5 5.043 5.122"}},[_c('path',{staticStyle:{"stroke-width":".132292"},attrs:{"d":"M105.833 150.283a2.117 2.117 0 1 0 0-4.233 2.117 2.117 0 0 0 0 4.233zm0-3.836a1.72 1.72 0 1 1 0 3.44 1.72 1.72 0 0 1 0-3.44zm-1.058.926a.265.265 0 1 1 .529 0 .265.265 0 0 1-.529 0zm1.587 0a.265.265 0 1 1 .53 0 .265.265 0 0 1-.53 0zm.265 1.27.34.204a1.322 1.322 0 0 1-2.268 0l.34-.204a.925.925 0 0 0 1.588 0z","transform":"translate(-103.717 -146.05)"}}),_c('path',{staticStyle:{"fill-opacity":"1","stroke":"#fff","stroke-width":".375276","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},attrs:{"d":"M108.772 149.751v.344a.172.172 0 0 1-.172.172h-.745v.745a.172.172 0 0 1-.172.172h-.344a.172.172 0 0 1-.172-.172v-.745h-.745a.172.172 0 0 1-.172-.172v-.344c0-.095.077-.172.172-.172h.745v-.745c0-.095.077-.172.172-.172h.344c.095 0 .172.077.172.172v.745h.745c.095 0 .172.077.172.172z","transform":"translate(-103.717 -146.05)"}})]),(_vm.shouldDisplayPicker)?_c('EmojiPickerCustom',{class:_vm.$style.emojis,attrs:{"showSearch":false,"showCategories":false,"emojiWithBorder":false,"exceptEmojis":[
        'confounded',
        'triumph',
        'angry',
        'rage',
        'pout',
        'lying_face',
        'nauseated_face',
        'japanese_ogre',
        'japanese_goblin',
        'hankey',
        'poop',
        'shit',
        'middle_finger',
        'fu'
      ]},on:{"select":_vm.onSelected}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }