<template>
  <div class="category-title">{{ name }}</div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.category-title {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 5px 0 0 16px;
  color: #676666;

  &:not(:first-of-type) {
    padding: 10px 0 0 16px;
  }
}
</style>
