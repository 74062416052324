<template>
  <div class="file-upload-area">
    <slot><img src="~@/assets/images/icons/Upload.png"/></slot>
    <input
      :accept="mimeTypesToCSV"
      type="file"
      data-ref="file_input"
      @change="onChange"
      data-cy="upload_input"
    />
  </div>
</template>

<script>
// The component emits following events:
//
// 'inserted' with { file, errors (empty) } payload
// where file is a File object and errors is an array which can
// contain validation errors (FileTooLargeError, InvalidFileTypeError).
//
// 'removed' with no payload
// when a file is removed (this situation cannot occur when
// dropping a file but it is stil possible
// when a user clicks the dropbox and cancels the file select dialog).
//
// 'validation' with { file, errors (not empty) } payload
//
// This component does NOT render any error messages
// It just tells the parent through 'validation' event and
// the parent is supposed to take care of rendering

import {
  FileTooLargeError,
  FileNameTooLongError,
  InvalidFileTypeError,
  isValidMimeType,
  isValidSize,
  isValidFileName
} from '@/helpers/files';

export default {
  props: {
    maxSizeInMb: {
      type: Number
    },
    allowedMimeTypes: {
      type: Array
    }
  },
  computed: {
    mimeTypesToCSV() {
      if (!this.allowedMimeTypes) return undefined;

      return this.allowedMimeTypes.join(',');
    }
  },
  methods: {
    onChange(e) {
      if (e.target.files.length === 0) {
        this.$emit('removed');
        return;
      }

      const file = e.target.files[0];

      const payload = {
        file,
        errors: []
      };

      if (!isValidFileName(file.name)) {
        payload.errors.push(new FileNameTooLongError(`Filename is too long`));
      }

      if (!isValidSize(file.size, this.maxSizeInMb)) {
        payload.errors.push(new FileTooLargeError('File is too large'));
      }

      if (!isValidMimeType(file.type, this.allowedMimeTypes)) {
        payload.errors.push(new InvalidFileTypeError('Invalid file type'));
      }

      if (payload.errors.length) {
        this.$emit('validation', payload);
      } else {
        this.$emit('inserted', payload);
      }

      // Makes sure that the input value is reset after every change
      // If the upload would fail and the same file name would
      // be selected by the user, the change event would not
      // be triggered without this
      if (this.$el && this.$el.querySelector('[data-ref=file_input]')) {
        this.$el.querySelector('[data-ref=file_input]').value = null;
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './FileUploadArea.scss';
</style>
