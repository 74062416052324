<template>
  <SideWidget
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperGray]: !teamMembers.length
    }"
    :gray="true"
    :title="false"
  >
    <template #content>
      <div class="box-shadow-white" :class="$style.members">
        <template>
          <p class="text-bold paragraph-large" :class="$style.title">My Team</p>
          <div @click="messageTo" :class="$style.chat">
            <i class="icon icon-comment" />
          </div>
          <p
            :class="$style.placeholder"
            class="placeholder"
            v-if="!teamMembers.length"
          >
            This is where you can see and contact your team.
          </p>
          <ul v-if="teamMembers.length">
            <li v-for="(member, index) in teamMembers" :key="index">
              <router-link
                :to="{
                  name: 'user-profile',
                  params: { userId: member.user_id }
                }"
              >
                <Avatar :user="member" size="30px" />
                <p :class="$style.name">
                  <UserName :user="member" />
                </p>
              </router-link>
            </li>
          </ul>
        </template>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import Avatar from '@/components/common/User/Avatar';
import { PLACEHOLDER_ID, CONVERSATION_TYPES } from '@/config/conversations';

import {
  OPEN_PLACEHOLDER_CONVERSATION,
  OPEN_CONVERSATION
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Avatar,
    SideWidget
  },
  props: {
    teamMembers: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('chats', ['conversationsWithParticipants']),
    ...mapGetters(['currentUserId', 'currentTeamProfile', 'currentUserProfile'])
  },
  methods: {
    ...mapActions('chats', [OPEN_CONVERSATION, OPEN_PLACEHOLDER_CONVERSATION]),
    messageTo() {
      const teamMembers = this.teamMembers;
      const participantsIds = teamMembers.map(member => member.user_id);

      const found = this.conversationsWithParticipants(
        [this.currentUserId, ...participantsIds],
        CONVERSATION_TYPES.TEAM
      );

      if (found.length === 0) {
        this.OPEN_PLACEHOLDER_CONVERSATION({
          type: CONVERSATION_TYPES.TEAM,
          participant_ids: [this.currentUserId, ...participantsIds],
          participants: [this.currentUserProfile, ...teamMembers],
          object_id: this.currentTeamProfile.team_id
        })
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId: PLACEHOLDER_ID
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });

        return;
      }

      if (found.length === 1) {
        this.startConversation(found[0].conversation_id);
      } else {
        // sort the conversations so that the most
        // recently created one will be the first item
        found.sort((a, b) => {
          const a_created = new Date(a.created_at);
          const b_created = new Date(b.created_at);
          return a_created.valueOf() < b_created.valueOf();
        });

        this.startConversation(found[0].conversation_id);
      }
    },
    startConversation(conversationId) {
      if (conversationId) {
        this.OPEN_CONVERSATION(conversationId)
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetTeamMembers.scss';
</style>
