<template>
  <div :class="$style.wrapper" class="box-shadow-white" v-if="user">
    <h6 :class="$style.authorTitle">{{ title || fullUserName(user) }}</h6>
    <div :class="$style.authorInfo">
      <div :class="$style.avatarHolder">
        <AvatarUploadSmall :userId="user.user_id" data-ref="badge" />
      </div>

      <div :class="$style.authorInfoRight">
        <router-link
          v-if="title"
          tag="h6"
          :class="$style.authorName"
          :to="{ name: 'user-profile', params: { userId: user.user_id } }"
        >
          {{ fullUserName(user) }}
        </router-link>
        <Badge v-if="user.role" :user="user" />
        <div
          :class="$style.authorId"
          class="adminSecondaryId"
          v-if="user.user_id"
        >
          ID: {{ user.user_id }}
        </div>
        <div v-if="studentId" :class="$style.authorId" class="adminSecondaryId">
          Student ID: {{ studentId }}
        </div>
      </div>
    </div>

    <ModalNew
      :showModal="showModal"
      v-transfer-dom
      @close="showModal = false"
      :class="$style.adminUserInfoModal"
    >
      <h5>Upload new avatar</h5>
      <AvatarUploadSmall
        :userId="user.user_id"
        data-ref="badge"
        :class="$style.avatarUploadSmall"
      />
    </ModalNew>
  </div>
  <div v-else :class="$style.wrapper" class="box-shadow-white">
    <h6 :class="$style.authorTitle">{{ title || fullUserName(user) }}</h6>
    <div :class="$style.authorInfo" class="placeholder">No author provided</div>
  </div>
</template>

<script>
import Badge from '@/components/common/User/Badge';
import { getUserFullName } from '@/helpers/users';
import AvatarUploadSmall from '@/components/uploads/AvatarUploadSmall/AvatarUploadSmall';

import { UPLOAD_ADMIN_AVATAR, DELETE_ADMIN_AVATAR } from '@/store/actions.type';

//import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Badge,
    AvatarUploadSmall
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    },
    title: {
      type: String
    },
    studentId: {
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    fullUserName(user) {
      if (user && user.name) {
        return getUserFullName(user.name);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminUserInfo.scss';
</style>
