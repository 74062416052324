export const PROVIDERS = {
  ['ditki']: {
    logo: {
      normal: 'ditki.png',
      white: 'ditki_white.png'
    },
    url: 'https://drawittoknowit.com/',
    color: '#2e8ebf'
  },
  // Compensation for typo in https://github.com/candena/api/blob/master/migrations/00161_resoure_providers.sql#L8
  ['ditky']: {
    logo: {
      normal: 'ditki.png',
      white: 'ditki_white.png'
    },
    url: 'https://drawittoknowit.com/',
    color: '#2e8ebf'
  },
  ['amboss']: {
    logo: {
      normal: 'amboss.png',
      white: 'amboss_white.png'
    },
    url: 'https://www.amboss.com/us',
    color: '#009ba4'
  },
  ['oxford']: {
    logo: {
      normal: 'oup.png'
    },
    url: 'https://global.oup.com/?cc=cz',
    color: '#122f53'
  },
  ['3d4medical']: {
    logo: {
      normal: '4medical.png'
    },
    url: 'https://3d4medical.com/',
    color: '#357c7b'
  },
  ['biodigital']: {
    logo: {
      normal: 'biodigital.jpg'
    },
    url: 'https://www.biodigital.com/',
    color: '#de3838'
  },
  ['edu']: {
    logo: {
      normal: 'edu.png'
    },
    url: 'https://medical.edu.mt/',
    color: '#cca14f'
  },
  ['proquest']: {
    logo: {
      normal: 'proQuest.png'
    }
  },
  ['none']: {
    logo: {
      normal: undefined,
      white: undefined
    },
    url: undefined,
    color: '#cca14f'
  }
};
