<template>
  <div :class="$style.wrapper">
    <div :class="$style.avatar" class="with-badge">
      <Avatar :user="user" :size="isMobileLayout ? '60px' : '110px'" />
      <Badge class="small" :user="user" />
    </div>
    <p class="text-semibold" :class="$style.name">
      <UserName :preTitles="true" :user="user" />
    </p>
    <div :class="$style.separator" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Avatar from '@/components/common/User/Avatar';
import Badge from '@/components/common/User/Badge';

export default {
  components: {
    Avatar,
    Badge
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['isMobileLayout'])
  }
};
</script>

<style lang="scss" module>
@import './TopicPostAuthor.scss';
</style>
