var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Cohorts ("+_vm._s(_vm.totalRecords)+")")]),_c('div',{class:_vm.$style.buttons},[_c('FormButton',{class:_vm.$style.greenButton,on:{"click":_vm.handleOpened}},[_c('i',{staticClass:"icon icon-user-plus"}),_vm._v(" Create cohort ")]),_c('div',{class:_vm.$style.homeButton},[_c('span',[_vm._v("| ")]),_c('router-link',{class:_vm.$style.button,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1)],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
          enabled: true,
          initialSortBy: _vm.loadUrlWithSorting()
        },"pagination-options":{
          enabled: true,
          infoFn: function (params) { return _vm.setTotalRecords(params.totalRecords); }
        },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
                name: 'admin-cohort-detail',
                params: { cohortId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.id)+" ")])],1):(props.column.field === 'name')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-cohort-detail',
                params: { cohortId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1):(props.column.field === 'currentModule')?_c('span',[(props.row.currentModule)?_c('router-link',{attrs:{"to":{
                name: 'admin-module-detail',
                params: { moduleId: props.row.moduleId }
              }}},[_vm._v(" "+_vm._s(props.row.currentModule)+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.moduleId))])]):_c('span',{staticClass:"text-bold"},[_vm._v("-")])],1):(props.column.field === 'students')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-students',
                params: { cohort_name: props.row.name }
              }}},[_vm._v(" "+_vm._s(props.row.students)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('ModalNew',{directives:[{name:"transfer-dom",rawName:"v-transfer-dom"}],class:_vm.$style.adminModal,attrs:{"showModal":_vm.showModal},on:{"close":_vm.handleClosed}},[_c('AdminCreateCohort',{on:{"reloadPage":function($event){return _vm.fetchCohorts()}}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }