<template>
  <SideWidget :class="$style.wrapper" v-if="nowISO">
    <template #name
      ><span v-line-clamp:1="1.2"
        >My Deadlines
        <span :class="$style.week" class="paragraph-normal">{{
          currentPhaseName
        }}</span></span
      ></template
    >

    <template #content>
      <div class="box-shadow-white" :class="$style.content">
        <div :class="$style.contentBoxPlaceholder">
          <img src="~@/assets/images/icons/excmark.svg" />

          Check your
          <router-link
            :to="{
              name: 'group-work'
            }"
          >
            Group Work </router-link
          >.
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import { mapGetters } from 'vuex';

import SideWidget from '@/components/common/SideWidget/SideWidget';

import { GET_MODULE_PHASES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget
  },
  props: {
    nowISO: {
      type: String,
      required: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch(GET_MODULE_PHASES);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  },
  computed: {
    ...mapGetters(['currentPhaseSlug', 'phaseName']),
    currentPhaseName() {
      return this.phaseName(this.currentPhaseSlug(this.nowISO));
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetDeadlines.scss';
</style>
