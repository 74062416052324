import ApiService from '@/api/api.service';

import { addObjToArray } from '@/helpers/common';
import { logWarning } from '@/helpers/errors';
import {
  GET_RESOURCE,
  POLL_FILE_RESOURCE,
  CREATE_FILE_RESOURCE,
  GET_RESOURCES,
  GET_RESOURCES_COUNT,
  GET_RESOURCE_GROUPS,
  GET_RESOURCE_GROUP,
  POST_RESOURCE_BOOKMARK,
  DELETE_RESOURCE_BOOKMARK,
  GET_RESOURCE_BOOKMARKS,
  GET_RESOURCE_PROVIDERS,
  GET_RESOURCES_LOGICAL_TYPES,
  DELETE_RESOURCE_FEEDBACK,
  GET_RESOURCE_FEEDBACK
} from './actions.type';
import {
  RESET_STATE,
  SET_RESOURCE_GROUPS,
  ADD_RESOURCE_GROUP,
  SET_RESOURCE_BOOKMARKS,
  SET_RESOURCES_LOGICAL_TYPES,
  SET_RESOURCE_PROVIDERS,
  SET_RESOURCE_UPLOAD_IN_PROGRESS
} from './mutations.type';

const RESOURCES_GROUP_ATTRIBUTE = 'resource_group_id';

const findResourceGroup = (state, groupCode) => {
  if (!state.resourceGroups || !state.resourceGroups.length) {
    return {};
  }

  return (
    state.resourceGroups.find(
      group =>
        // eslint-disable-next-line no-prototype-builtins
        group.hasOwnProperty(RESOURCES_GROUP_ATTRIBUTE) &&
        group[RESOURCES_GROUP_ATTRIBUTE] === groupCode
    ) || {}
  );
};

export const state = {
  resourceBookmarks: [],
  resourceGroups: [],
  resourcesLogicalTypes: [],
  resourceProviders: [],
  resourceUploading: false
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  resourceBookmarks(state) {
    return state.resourceBookmarks;
  },
  resourceUploading(state) {
    return state.resourceUploading;
  },
  resourceProviders(state) {
    return state.resourceProviders;
  },
  resourceGroups(state) {
    return state.resourceGroups;
  },
  resourceGroup: state => groupCode => {
    return findResourceGroup(state, groupCode);
  },
  nonFeaturedResourceGroups(state, getters) {
    if (!state.resourceGroups || !state.resourceGroups.length) {
      return [];
    }

    return state.resourceGroups.filter(group =>
      // eslint-disable-next-line no-prototype-builtins
      group.hasOwnProperty(RESOURCES_GROUP_ATTRIBUTE)
    );
  },

  hasResourceBookmark: state => resourceSlug => {
    if (!state.resourceBookmarks || !resourceSlug) {
      return false;
    }

    return state.resourceBookmarks.some(
      bookmark => bookmark.slug === resourceSlug
    );
  },

  resourcesLogicalTypes(state) {
    return state.resourcesLogicalTypes;
  }
};

export const actions = {
  [GET_RESOURCE](context, resourceSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getResource(resourceSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_RESOURCE_FEEDBACK](context, resourceSlug) {
    return new Promise((resolve, reject) => {
      ApiService.removeResourceFeedback(resourceSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCE_FEEDBACK](context, { resourceSlug, is_upvote, note }) {
    return new Promise((resolve, reject) => {
      ApiService.setResourceFeedback(resourceSlug, is_upvote, note)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async [POLL_FILE_RESOURCE](
    context,
    { resourceSlug, maxRequests = 30, delay = 1000 }
  ) {
    let requestsCount = 0;
    let resource = {
      file: {
        state: 'pending'
      }
    };

    while (resource.file.state !== 'ok' && requestsCount <= maxRequests) {
      if (requestsCount === maxRequests) {
        return Promise.reject('Max requests limit exceeded');
      }

      await new Promise(resolve => setTimeout(resolve, delay));

      try {
        resource = await context.dispatch(GET_RESOURCE, resourceSlug);
        requestsCount += 1;

        if (!resource.file) {
          return Promise.reject('This is not a file resource');
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.resolve(resource);
  },

  [CREATE_FILE_RESOURCE](context, { file, type, title, ownerIds }) {
    return new Promise((resolve, reject) => {
      ApiService.createFileResource(file, type, title, ownerIds)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCES](context, searchParams) {
    return new Promise((resolve, reject) => {
      ApiService.getResources(searchParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCES_COUNT](context, searchParams) {
    return new Promise((resolve, reject) => {
      ApiService.getResourcesCount(searchParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCES_LOGICAL_TYPES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getResourcesLogicalTypes()
        .then(({ data }) => {
          context.commit(SET_RESOURCES_LOGICAL_TYPES, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCE_GROUPS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getResourceGroups()
        .then(({ data }) => {
          context.commit(SET_RESOURCE_GROUPS, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCE_GROUP](context, resourceGroupCode) {
    return new Promise((resolve, reject) => {
      ApiService.getResourceGroup(resourceGroupCode)
        .then(({ data }) => {
          context.commit(ADD_RESOURCE_GROUP, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCE_BOOKMARKS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getResourceBookmarks()
        .then(({ data }) => {
          context.commit(SET_RESOURCE_BOOKMARKS, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_RESOURCE_PROVIDERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getResourceProviders()
        .then(({ data }) => {
          context.commit(SET_RESOURCE_PROVIDERS, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_RESOURCE_BOOKMARK](context, resourceSlug) {
    return new Promise((resolve, reject) => {
      ApiService.addResourceBookmark(resourceSlug)
        .then(() => {
          context.dispatch(GET_RESOURCE_BOOKMARKS);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_RESOURCE_BOOKMARK](context, resourceSlug) {
    return new Promise((resolve, reject) => {
      ApiService.removeResourceBookmark(resourceSlug)
        .then(() => {
          context.dispatch(GET_RESOURCE_BOOKMARKS);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [SET_RESOURCE_GROUPS](state, resourceGroups) {
    state.resourceGroups = resourceGroups;
  },
  [ADD_RESOURCE_GROUP](state, resourceGroup) {
    // push resource group to resource groups if it's not there yet
    // otherwise overwrite an existing group with a new, up-to-date, group
    state.resourceGroups = addObjToArray(
      state.resourceGroups,
      resourceGroup,
      'resource_group_id'
    );
  },
  [SET_RESOURCE_BOOKMARKS](state, resourceBookmarks) {
    state.resourceBookmarks = resourceBookmarks;
  },
  [SET_RESOURCES_LOGICAL_TYPES](state, resourcesLogicalTypes) {
    state.resourcesLogicalTypes = resourcesLogicalTypes;
  },
  [SET_RESOURCE_PROVIDERS](state, resourceProviders) {
    state.resourceProviders = resourceProviders;
  },
  [SET_RESOURCE_UPLOAD_IN_PROGRESS](state, resourceMaybeUploading) {
    state.resourceUploading = resourceMaybeUploading;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
