/** * Render list of errors as an unordered list. * Accept error objects with
message attribute or errors array. */
<template>
  <ul v-if="errors && errors.length > 0" :class="$style.wrapper">
    <li v-for="(error, index) in errors" :key="index">
      <i class="icon icon-close" />
      {{ error.message || error }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: false
    }
  }
};
</script>

<style lang="scss" module>
@import './FormErrors.scss';
</style>
