var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.detail)?_c('SideWidget',{class:_vm.$style.wrapper,attrs:{"title":false},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"box-shadow-white",class:_vm.$style.section},[_c('p',{staticClass:"bold",class:_vm.$style.sectionTitle},[_vm._v("Resource title:")]),(_vm.editingMode)?_c('FormInput',{model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}):_c('p',[_vm._v(_vm._s(_vm.detail.title))]),_c('p',{staticClass:"bold",class:_vm.$style.sectionTitle},[_vm._v("Resource type:")]),_c('p',[_vm._v(_vm._s(_vm.detail.type))]),_c('p',{staticClass:"bold",class:_vm.$style.sectionTitle},[_vm._v("Source:")]),_c('p',[_vm._v(_vm._s(_vm.resourceType))]),_c('p',{staticClass:"bold",class:_vm.$style.sectionTitle},[_vm._v("Author:")]),(_vm.detail.user && _vm.detail.user.name)?_c('p',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: _vm.detail.user.user_id }
          }}},[_vm._v(_vm._s(_vm.detail.user.name.first_name)+" "+_vm._s(_vm.detail.user.name.last_name))])],1):_vm._e(),_c('p',{staticClass:"bold",class:_vm.$style.sectionTitle},[_vm._v("Unit:")]),(_vm.detail.learning_unit && _vm.detail.learning_unit.name)?_c('router-link',{attrs:{"to":{
          name: 'learning-unit-detail',
          params: {
            learningUnitId: _vm.detail.learning_unit.unit_id
          }
        }}},[_vm._v(_vm._s(_vm.detail.learning_unit.name))]):_c('p',{staticClass:"placeholder"},[_vm._v("No unit")])],1),(_vm.detail)?_c('div',{staticClass:"box-shadow-white",class:_vm.$style.section},[_c('p',{staticClass:"bold",class:_vm.$style.objectivesTitle},[_vm._v("Learning objectives")]),(_vm.editingMode)?_c('div',{class:_vm.$style.editSection},[(_vm.filteredObjectives)?_c('div',[_vm._l((_vm.filteredObjectives),function(objective,i){return _c('div',{key:i},[_c('FormLabel',{class:_vm.$style.objective},[_c('FormCheckbox',{attrs:{"value":objective.learning_objective_id},model:{value:(_vm.selectedObjectives),callback:function ($$v) {_vm.selectedObjectives=$$v},expression:"selectedObjectives"}}),_c('span',[_vm._v(_vm._s(i + 1)+". "+_vm._s(objective.name))])],1)],1)}),(
              _vm.detail.learning_objectives &&
                _vm.detail.learning_objectives.length > 5
            )?_c('p',{staticClass:"button-text",on:{"click":function($event){_vm.showMoreObjectives = !_vm.showMoreObjectives}}},[_vm._v(" Show "+_vm._s(_vm.showMoreObjectives ? 'less' : 'more')+" ")]):_vm._e()],2):_c('div',{staticClass:"placeholder"},[_vm._v(" No learning objectives to select from ")])]):_c('div',[(_vm.detail.selected_learning_objectives)?_c('div',_vm._l((_vm.filteredSelectedObjectives),function(objective,i){return _c('div',{key:i,class:_vm.$style.objective},[_c('span',[_vm._v(_vm._s(objective.number)+". "+_vm._s(objective.name))])])}),0):_c('div',{staticClass:"placeholder",class:_vm.$style.objective},[_vm._v(" No learning objective selected ")]),(
            _vm.detail.selected_learning_objectives &&
              _vm.detail.selected_learning_objectives.length > 5
          )?_c('p',{staticClass:"button-text",on:{"click":function($event){_vm.showMoreObjectives = !_vm.showMoreObjectives}}},[_vm._v(" Show "+_vm._s(_vm.showMoreObjectives ? 'less' : 'more')+" ")]):_vm._e()]),_c('p',{staticClass:"bold"},[_vm._v("Comment:")]),(_vm.editingMode)?_c('FormTextarea',{attrs:{"placeholder":"comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}):_c('div',[(_vm.detail.comment)?_c('p',[_vm._v(_vm._s(_vm.detail.comment))]):_c('p',{staticClass:"placeholder"},[_vm._v("None")])])],1):_vm._e()]},proxy:true}],null,false,472762056)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }