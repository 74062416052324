export default {
  created() {
    window.location.href = '/';

    return;
  },
  render() {
    return;
  }
};
