import ApiService from '@/api/api.service';

import {
  GET_MODULE_GROUP_WORKS,
  GET_CURRENT_GROUP_WORK_ASSIGNMENT_ID,
  SUBMIT_GROUPWORK
} from '@/store/actions.type';
import { RESET_STATE } from '@/store/mutations.type';

export const state = {
  assignment: undefined,
  submission: undefined,
  resource: undefined
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  assignment(state) {
    return state.assignment;
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  }
};

export const actions = {
  [SUBMIT_GROUPWORK](
    context,
    { assignmentId, scheduledAssignmentId, payload }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.submitGroupwork(assignmentId, scheduledAssignmentId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_CURRENT_GROUP_WORK_ASSIGNMENT_ID]() {
    return new Promise((resolve, reject) => {
      ApiService.getCurrentPhaseGroupWork()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_MODULE_GROUP_WORKS](context, moduleVersionId = 'current') {
    return new Promise((resolve, reject) => {
      ApiService.getModuleGroupWorks(moduleVersionId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
