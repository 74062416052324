<template>
  <div :class="$style.wrapper">
    <NotFound v-if="display404">
      This topic doesn't exist
    </NotFound>
    <template v-else>
      <h6 id="scroll" class="main-title">
        {{ topicName }}
      </h6>
      <div :class="$style.content" class="box-shadow-white pr-2 pl-2 pb-1">
        <div
          v-for="(faq, index) in faqs"
          :key="faq.id"
          :class="$style.question"
        >
          <p class="theme-color" @click="toggleQuestion(index)">
            <i
              v-if="openQuestions.includes(index)"
              class="icon icon-chevron-up"
            />
            <i
              v-if="!openQuestions.includes(index)"
              class="icon icon-chevron-down"
            />
            Q:
            {{ faq.question }}
          </p>
          <transition name="fadeInUp fadeInDown">
            <div v-show="openQuestions.includes(index)" :class="$style.answer">
              <span v-html="faq.answer" />
              <div :class="$style.backLink" @click="scrollTop">
                Back to top
              </div>
            </div>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { hasErrorStatus } from '@/helpers/errors';

import { GET_FAQ_TOPIC_QUESTIONS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';

export default {
  components: {
    NotFound
  },
  data() {
    return {
      faqs: [],
      openQuestions: [],
      display404: false
    };
  },
  computed: {
    ...mapGetters(['faqTopic']),
    topicName() {
      if (this.faqTopic(this.$route.params.topicSlug)) {
        return this.faqTopic(this.$route.params.topicSlug).name;
      }

      return '';
    }
  },
  watch: {
    $route() {
      this.openQuestions = [];
      this.fetchFaqs();
    }
  },
  created() {
    this.fetchFaqs();
  },
  methods: {
    scrollTop() {
      let top = document.querySelector('#scroll').offsetTop;
      window.scrollTo(0, top);
    },
    fetchFaqs() {
      this.$store
        .dispatch(GET_FAQ_TOPIC_QUESTIONS, this.$route.params.topicSlug)
        .then(faqs => {
          this.faqs = faqs;
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    },
    toggleQuestion(index) {
      let arrayIndex = this.openQuestions.indexOf(index);

      if (this.openQuestions.includes(index)) {
        this.openQuestions.splice(arrayIndex, 1);
      } else {
        this.openQuestions.push(index);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './FaqTopic.scss';
</style>
