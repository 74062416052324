<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <router-link
            to="/admin/modules"
            class="btn standard"
            :class="$style.backButton"
            ><i class="icon icon-chevron-left"
          /></router-link>
          <h6>
            {{ moduleDetail.module_name }}
            <span class="adminPrimaryId">{{ moduleDetail.module_id }}</span>
          </h6>
          <div :class="$style.buttons">
            <router-link to="/admin" :class="$style.homeButton">
              <i class="icon icon-home"
            /></router-link>
          </div>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :class="$style.table"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'cohort'">
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.cohort[1] }
                }"
                >{{ props.row.cohort[0] }}
                <span class="adminSecondaryId">{{
                  props.row.cohort[1]
                }}</span></router-link
              >
            </span>
            <span v-else-if="props.column.field === 'units'">
              <span
                class="text-semibold"
                :class="{
                  [$style.enabled]: props.row.units === 'Enabled',
                  [$style.disabled]: props.row.units === 'Disabled'
                }"
                >{{ props.row.units }}
                <i
                  class="icon icon-edit"
                  @click="handleOpenedUnits()"
                  v-if="moduleDetail.options.disable_units"
                />
              </span>
            </span>
            <span v-else-if="props.column.field === 'scheduleGroup'">
              <span
                class="text-semibold"
                :class="{
                  [$style.enabled]: props.row.scheduleGroup === 'Enabled',
                  [$style.disabled]: props.row.scheduleGroup === 'Disabled'
                }"
                >{{ props.row.scheduleGroup }}
                <i
                  class="icon icon-edit"
                  @click="handleOpenedSchedule()"
                  v-if="moduleDetail.options.disable_schedule_groups"
              /></span>
            </span>
          </template>
        </vue-good-table>

        <AdminModuleDetailUnits
          v-if="moduleDetail && moduleDetail.lo_units"
          :units="moduleDetail.lo_units"
          :class="$style.moduleTable"
        />
        <AdminModuleDetailPhases
          v-if="moduleDetail && moduleDetail.cohort_phases"
          :phases="moduleDetail.cohort_phases"
          :class="$style.moduleTable"
        />

        <ConfirmationModal
          :question="questionUnits"
          @handleClosed="handleClosed"
          @agreedAction="updateModuleSetting('disable_units')"
          :showModal="showUnitsModal"
          :optionOne="'Enable'"
          :optionOneStyle="'green'"
        />

        <ConfirmationModal
          :question="questionSchedule"
          @handleClosed="handleClosed"
          @agreedAction="updateModuleSetting('disable_schedule_groups')"
          :showModal="showScheduleModal"
          :optionOne="'Enable'"
          :optionOneStyle="'green'"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Layout from '@/views/Layout/Layout';
import { VueGoodTable } from 'vue-good-table';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminModuleDetailUnits from '@/views/Admin/AdminModules/AdminModuleDetailUnits';
import AdminModuleDetailPhases from '@/views/Admin/AdminModules/AdminModuleDetailPhases';
import ConfirmationModal from '@/components/admin/ConfirmationModal/ConfirmationModal';

import {
  GET_ADMIN_MODULE_DETAIL,
  UPDATE_ADMIN_MODULE_DETAIL_SETTINGS
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    VueGoodTable,
    NotFound,
    AdminModuleDetailUnits,
    AdminModuleDetailPhases,
    ConfirmationModal
  },
  data() {
    return {
      display404: false,
      moduleDetail: [],
      showUnitsModal: false,
      showScheduleModal: false,
      questionUnits: 'Are you sure you want to enable units?',
      questionSchedule: 'Are you sure you want to enable schedule group?',
      columns: [
        {
          label: 'Published at',
          field: 'published',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm'
        },
        {
          label: 'Cohort',
          field: 'cohort'
        },
        {
          label: 'Schedule duration',
          field: 'scheduleDuration'
        },
        {
          label: 'Units',
          field: 'units'
        },
        {
          label: 'Schedule group',
          field: 'scheduleGroup'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchModuleDetail();
    }
  },
  methods: {
    ...mapActions([UPDATE_ADMIN_MODULE_DETAIL_SETTINGS]),
    fetchModuleDetail() {
      this.$store
        .dispatch(GET_ADMIN_MODULE_DETAIL, this.$route.params.moduleId)
        .then(module => {
          this.moduleDetail = module;
        })
        .then(() => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.rows = [];

      if (!this.moduleDetail) return [];

      this.rows.push({
        units: this.unitsStatus(this.moduleDetail) || '',
        scheduleDuration:
          moment(this.moduleDetail.schedule.start_date).format('DD.MM.yyyy') +
            '  -  ' +
            moment(this.moduleDetail.schedule.end_date).format('DD.MM.yyyy') ||
          '',
        scheduleGroup: this.scheduleGroupStatus(this.moduleDetail) || '',
        published:
          moment(this.moduleDetail.published_at).format('DD.MM.yyyy HH:mm') ||
          '',
        cohort: this.cohortColumn(this.moduleDetail.cohort) || ''
      });
    },
    cohortColumn(cohort) {
      if (cohort) {
        return [cohort.name, cohort.cohort_id];
      }
    },
    updateModuleSetting(type) {
      this.$store
        .dispatch(UPDATE_ADMIN_MODULE_DETAIL_SETTINGS, {
          moduleId: this.moduleDetail.module_id,
          optionName: type
        })
        .then(() => {
          this.fetchModuleDetail();
          this.handleClosed();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    unitsStatus(module) {
      if (module.options && module.options.disable_units) {
        return 'Disabled';
      }

      return '-';
    },
    scheduleGroupStatus(module) {
      if (module.options && module.options.disable_schedule_groups) {
        return 'Disabled';
      }

      return '-';
    },
    handleOpenedUnits() {
      this.showUnitsModal = true;
    },
    handleOpenedSchedule() {
      this.showScheduleModal = true;
    },
    handleClosed() {
      this.showUnitsModal = false;
      this.showScheduleModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminModuleDetail.scss';
</style>
