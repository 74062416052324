<template>
  <div>
    <h6>Learning Objective Units</h6>
    <vue-good-table
      :pagination-options="{
        enabled: true
      }"
      @on-column-filter="updateFilters"
      :columns="columns"
      :rows="rows"
      compactMode
      @on-sort-change="onSortChange"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      v-if="units"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'unitId'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'learning-unit-detail',
              params: { learningUnitId: props.row.unitId }
            }"
            target="_blank"
            >{{ props.row.unitId }}</router-link
          >
        </span>
        <span v-else-if="props.column.field === 'unitName'">
          <router-link
            :to="{
              name: 'learning-unit-detail',
              params: { learningUnitId: props.row.unitId }
            }"
            target="_blank"
            >{{ props.row.unitName }}</router-link
          >
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    VueGoodTable
  },
  props: {
    units: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      display404: false,
      searchParams: {},
      columns: [
        {
          label: 'Unit ID',
          field: 'unitId',
          type: 'number',
          width: '120px',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('unitId')
          }
        },
        {
          label: 'Name',
          field: 'unitName',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('unitName')
          }
        },
        {
          label: 'LO(s) count',
          field: 'unitCount',
          type: 'number',
          width: '120px',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('unitCount')
          }
        }
      ],
      rows: []
    };
  },
  created() {
    this.createUnitRows();
  },
  methods: {
    createUnitRows() {
      this.units.filter(unit => {
        this.rows.push({
          unitId: unit.jsonb_build_object.unit_id || '',
          unitName: unit.jsonb_build_object.label || '',
          unitCount: unit.jsonb_build_object.lo_count || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        unitsSort: sort[0].field || [],
        unitsOrder: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({
            ...this.$route.query,
            ...this.searchParams,
            ...sorting
          });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.unitsOrder) {
        return {
          field: this.$route.query.unitsSort,
          type: this.$route.query.unitsOrder
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'unitId':
          return this.$route.query.unitId;
          break;
        case 'unitName':
          return this.$route.query.unitName;
          break;
        case 'unitCount':
          return this.$route.query.unitCount;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        unitId: column.unitId || [],
        unitName: column.unitName || [],
        unitCount: column.unitCount || []
      };

      this.updateQueryFilters({ ...this.$route.query, ...this.searchParams });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
