<template>
  <div :class="$style.wrapper" class="box-shadow-white">
    <template v-if="!unitLearningObjectives.length">
      <p class="paragraph-small">
        No Learning Objective available for this unit.
      </p>
    </template>

    <template v-else>
      <template v-if="!selectedLearningObjective">
        <header :class="$style.listHeader">
          <h6>
            Learning Objectives &nbsp;({{ unitLearningObjectives.length }})
          </h6>
        </header>

        <ol>
          <li
            v-for="(lo, index) in unitLearningObjectives"
            @click="onSelected(lo.learning_objective_id)"
            :key="index"
            :class="$style.activeMobile"
          >
            {{ lo.name }}
          </li>
        </ol>
      </template>

      <template v-else>
        <header>
          <nav
            :class="$style.objectives"
            @click="onSelected(selectedLearningObjective)"
          >
            <span>
              <i class="icon icon-chevron-left" />
              Learning Objectives
            </span>

            <h6>Resources</h6>
          </nav>
          <nav :class="$style.resources">
            <ul>
              <li
                :class="{
                  [$style.active]:
                    origin === RESOURCE_ORIGINS.core ? true : false
                }"
              >
                <a @click="selectOrigin(RESOURCE_ORIGINS.core)">
                  Core ({{ coreResources.length }})
                </a>
              </li>
              <li
                :class="{
                  [$style.active]:
                    origin === RESOURCE_ORIGINS.recommended ? true : false,
                  [$style.withModal]: true
                }"
              >
                <a @click="selectOrigin(RESOURCE_ORIGINS.recommended)">
                  Recommended ({{ recommendedItems.length }})
                </a>

                <i
                  @click="showModal = true"
                  class="icon icon-help-circle"
                  title="Resources added by our faculty members based on their experience in the topic area. These resources are not core or mandatory and might not necessarily be covered in exams, but are helpful additions to provide you with a better overview on the topics covered in the Units."
                />
              </li>
            </ul>
          </nav>
        </header>

        <ModalNew
          :class="$style.modalWrapper"
          :showModal="showModal"
          :closeWithClickOutside="true"
          v-transfer-dom
          @close="showModal = false"
        >
          <div :class="$style.description">
            <i class="icon icon-info-circle color-info" />

            <div>
              Resources added by our faculty members based on their experience
              in the topic area. These resources are not core or mandatory and
              might not necessarily be covered in exams, but are helpful
              additions to provide you with a better overview on the topics
              covered in the Units.
            </div>
          </div>
        </ModalNew>

        <template v-if="!originResources.length">
          <p :class="$style.empty">
            There are no {{ RESOURCE_ORIGINS[origin] }} resources related to the
            selected Learning Objective.
          </p>
        </template>

        <template v-else-if="origin === RESOURCE_ORIGINS.recommended">
          <section v-if="recommendedLinks.length">
            <h6>Links</h6>
            <ol>
              <li v-for="(resource, index) in recommendedLinks" :key="index">
                <strong>{{ resource.title }}</strong>
                <br />
                <a
                  :href="resource.content"
                  target="_blank"
                  :title="resource.content"
                >
                  {{ resource.content }}
                </a>
                <div :class="$style.submittedBy">
                  <p><em>By:</em></p>
                  <p v-line-clamp:1="1">
                    <UserName :user="resource.submitted_by" />
                  </p>

                  <p><em>Uploaded:</em></p>
                  <p>
                    <span>{{ formatDate(resource.created_at) }}</span>
                  </p>
                </div>
              </li>
            </ol>
          </section>

          <section v-if="recommendedVideos.length">
            <h6>Videos</h6>
            <ul>
              <li v-for="(resource, index) in recommendedVideos" :key="index">
                <div v-html="resource.embedded_html" />
                <p>
                  <strong>{{ resource.title }}</strong>
                </p>
                <p>{{ resource.description }}</p>
                <div :class="$style.submittedBy">
                  <p><em>By:</em></p>
                  <p v-line-clamp:1="1">
                    <UserName :user="resource.submitted_by" />
                  </p>

                  <p><em>Uploaded:</em></p>
                  <p>
                    <span>{{ formatDate(resource.created_at) }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </section>

          <section v-if="recommendedFiles.length">
            <h6>PDFs</h6>
            <ul :class="$style.cards">
              <li v-for="(resource, index) in recommendedFiles" :key="index">
                <ResourceCardSmall
                  :resource="resource"
                  :class="$style.card"
                  :withHoverEffect="false"
                  class="border"
                >
                  <div :class="$style.submittedByCondensed">
                    <p><em>By:</em></p>
                    <p v-line-clamp:1="1">
                      <UserName :user="resource.submitted_by" />
                    </p>
                  </div>
                </ResourceCardSmall>
              </li>
            </ul>
          </section>
        </template>

        <template v-else>
          <ul :class="$style.cards">
            <li v-for="(resource, index) in originResources" :key="index">
              <ResourceCardSmall
                :resource="resource"
                :class="$style.card"
                :withHoverEffect="false"
                class="border"
              />
            </li>
          </ul>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import ResourceCardSmall from '@/components/resources/ResourceCard/ResourceCardSmall';

const RESOURCE_ORIGINS = {
  core: 'core',
  recommended: 'recommended'
};

export default {
  name: 'RelatedObjectsMobile',
  components: {
    ResourceCardSmall
  },
  props: {
    learningUnitId: {
      type: String,
      default: undefined
    },
    unitLearningObjectives: {
      type: Array,
      default: () => []
    },
    objectiveRelatedResources: {
      type: Array,
      default: () => []
    },
    selectedLearningObjective: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      origin: RESOURCE_ORIGINS.core,
      showModal: false
    };
  },
  computed: {
    coreResources() {
      if (!this.objectiveRelatedResources) return [];

      return this.objectiveRelatedResources.filter(
        item => item.community_based === false
      );
    },
    recommendedItems() {
      if (!this.objectiveRelatedResources) return [];

      return this.objectiveRelatedResources.filter(
        item => item.community_based === true
      );
    },
    originResources() {
      if (this.origin === this.RESOURCE_ORIGINS.core) {
        return this.coreResources;
      }

      if (this.origin === this.RESOURCE_ORIGINS.recommended) {
        return this.recommendedItems;
      }

      return [];
    },
    recommendedFiles() {
      if (!this.originResources) return [];

      return this.originResources.filter(
        res => res.type === 'pdf' && res.logical_type_code === 'text'
      );
    },
    recommendedLinks() {
      if (!this.originResources) return [];

      return this.originResources.filter(
        res => res.type === 'link' && res.logical_type_code === 'text'
      );
    },
    recommendedVideos() {
      if (!this.originResources) return [];

      return this.originResources.filter(
        res => res.type === 'link' && res.logical_type_code === 'video'
      );
    }
  },
  methods: {
    onSelected(selectedLearningObjectiveId) {
      this.$emit('lo_selected', selectedLearningObjectiveId);
    },
    selectOrigin(originKey) {
      this.origin = originKey;
    },
    formatDate(date) {
      return moment(date).format('DD.MM.yyyy');
    }
  },
  created() {
    this.RESOURCE_ORIGINS = RESOURCE_ORIGINS;
  }
};
</script>

<style lang="scss" module>
@import './RelatedObjectsMobile.scss';
</style>
