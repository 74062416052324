<template>
  <span
    :class="['emoji', { border: withBorder }]"
    :style="styleSize"
    :title="getEmojiTitle(emoji)"
    v-html="emoji.data"
  />
</template>

<script>
export default {
  props: {
    emoji: {
      type: Object
    },
    size: {
      type: Number
    },
    withBorder: {
      type: Boolean
    }
  },
  computed: {
    styleSize() {
      return {
        fontSize: `${this.size - 5}px`,
        lineHeight: `${this.size}px`,
        height: `${this.size}px`,
        width: `${this.size}px`
      };
    }
  },
  methods: {
    getEmojiTitle(emoji) {
      return emoji
        ? emoji.aliases.map(alias => alias.replace('_', ' ')).join(', ')
        : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.emoji {
  display: inline-block;
  text-align: center;
  padding: 3px;
  box-sizing: content-box;
  overflow: hidden;
  transition: transform 0.2s;

  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.border:hover {
  background: #00000010;
  border-radius: 8px;
}
</style>
