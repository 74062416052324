<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      compactMode
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom'
      }"
    >
      <div slot="table-actions">
        <h6 :class="$style.tableHeader">
          Cohort Phases
        </h6>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'phaseId'">
          <p class="adminPrimaryId">{{ props.row.phaseId }}</p>
        </span>

        <span v-else-if="props.column.field === 'phaseModule'">
          <router-link
            :to="{
              name: 'admin-module-detail',
              params: { moduleId: props.row.phaseModule[1] }
            }"
          >
            {{ props.row.phaseModule[0] }}
            <p class="adminSecondaryId">{{ props.row.phaseModule[1] }}</p>
          </router-link>
        </span>

        <span v-else-if="props.column.field === 'gaAssignment'">
          <router-link
            v-if="props.row.assignmentId && props.row.scheduledAssignmentId"
            :to="{
              name: 'assignment-detail',
              params: {
                assignmentId: props.row.assignmentId,
                scheduledAssignmentId: props.row.scheduledAssignmentId
              }
            }"
            v-html="props.row.gaAssignment"
          >
          </router-link>
          <p v-else>-</p>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';

import { VueGoodTable } from 'vue-good-table';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    VueGoodTable
  },
  props: {
    cohortPhases: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchParams: {},
      columns: [
        {
          label: 'Phase ID',
          type: 'number',
          field: 'phaseId',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('phaseId')
          }
        },
        {
          label: 'Phase name',
          field: 'phaseName',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('phaseName')
          }
        },
        {
          label: 'Phase type',
          field: 'phaseType',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('phaseType')
          }
        },
        {
          label: 'Duration',
          field: 'phaseDuration',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('phaseDuration')
          }
        },
        {
          label: 'GA name / deadline',
          field: 'gaAssignment',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('gaAssignment')
          }
        },
        {
          label: 'Module name',
          field: 'phaseModule',
          tdClass: this.currentPhase,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('phaseModule')
          }
        }
      ],
      rows: []
    };
  },
  watch: {
    cohortPhases() {
      this.createPhaseRows();
    }
  },
  methods: {
    createPhaseRows() {
      this.rows = [];

      if (!this.cohortPhases) return [];

      this.cohortPhases.filter(phase => {
        this.rows.push({
          phaseName: phase.label || '',
          phaseDuration: this.cohortPhaseDuration(phase) || '',
          phaseType: phase.phase_type || '',
          phaseId: phase.cohort_phase_id || '',
          gaAssignment: this.gaAssignment(phase) || '',
          assignmentId:
            phase.ga_assignment && phase.ga_assignment.assignment_id,
          scheduledAssignmentId:
            phase.ga_assignment && phase.ga_assignment.scheduled_assignment_id,
          phaseModule: [phase.module_name, phase.module_id] || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        phaseSort: sort[0].field || [],
        phaseOrder: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({
            ...this.$route.query,
            ...this.searchParams,
            ...sorting
          });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.phaseOrder) {
        return {
          field: this.$route.query.phaseSort,
          type: this.$route.query.phaseOrder
        };
      }

      return {};
    },
    gaAssignment(phase) {
      if (
        !phase.ga_assignment ||
        !phase.ga_assignment.end_at ||
        !phase.ga_assignment.name
      ) {
        return null;
      }

      return `${phase.ga_assignment.name}
      <br/>
      ${moment(phase.ga_assignment.end_at).format('DD.MM.yyyy - HH:mm')}`;
    },
    cohortPhaseDuration(phase) {
      if (!phase) {
        return null;
      }

      return (
        moment(phase.start_date).format('DD.MM.yyyy') +
        ' - ' +
        moment(phase.end_date).format('DD.MM.yyyy')
      );
    },
    currentPhase(phase) {
      // get both dates from already merged phaseDuration and check if moment() is between them
      var dates = phase.phaseDuration.split(' - ');

      var startDate = moment(dates[0], 'DD.MM.yyyy');
      var endDate = moment(dates[1], 'DD.MM.yyyy');

      if (moment().isBetween(startDate, endDate, 'day', '[]')) {
        return 'currentPhaseHighligt';
      }
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'phaseId':
          return this.$route.query.phaseId;
          break;
        case 'phaseName':
          return this.$route.query.phaseName;
          break;
        case 'phaseType':
          return this.$route.query.phaseType;
          break;
        case 'phaseDuration':
          return this.$route.query.phaseDuration;
          break;
        case 'gaAssignment':
          return this.$route.query.gaAssignment;
          break;
        case 'phaseModule':
          return this.$route.query.phaseModule;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        phaseId: column.phaseId || [],
        phaseName: column.phaseName || [],
        phaseType: column.phaseType || [],
        phaseDuration: column.phaseDuration || [],
        gaAssignment: column.gaAssignment || [],
        phaseModule: column.phaseModule || []
      };

      this.updateQueryFilters({ ...this.$route.query, ...this.searchParams });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    reloadMembers() {
      this.$emit('reloadMembers');
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
