<template>
  <div :class="$style.wrapper">
    <div :style="loaderStyle" :class="$style.loader" />

    <p :style="{ color: labelColor, fontSize: labelSize + 'px' }">
      <slot />
    </p>
  </div>
</template>

<script>
import { THEMES, DEFAULT_THEME } from '@/config/themes';

export default {
  props: {
    radius: {
      type: Number,
      required: false,
      default: 15
    },
    strokeWidth: {
      type: Number,
      required: false,
      default: 3
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '$background-gray'
    },
    color: {
      type: String,
      required: false,
      default: THEMES[process.env.THEME || DEFAULT_THEME].primaryColor
    },
    labelColor: {
      type: String,
      required: false,
      default: '#b3b3b3'
    },
    labelSize: {
      type: Number,
      required: false,
      default: 15
    }
  },
  computed: {
    loaderStyle() {
      return {
        width: `${this.radius * 2}px`,
        height: `${this.radius * 2}px`,
        border: `${this.strokeWidth}px solid ${this.backgroundColor}`,
        borderTop: `${this.strokeWidth}px solid ${this.color}`,
        backgroundColor: `${this.backgroundColor}`
      };
    }
  }
};
</script>

<style lang="scss" module>
@import './CircleLoader.scss';
</style>
