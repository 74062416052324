<template>
  <div @keydown.enter="emitChange">
    <div :class="$style.wrapper">
      <FilterSelect
        data-ref="phaseSelect"
        :class="$style.phaseSelectBox"
        :submenuVisible="phaseSubmenuVisible"
        :withBorder="false"
        @submenu_visibility="handlePhaseSubmenuChange"
      >
        <template #title>
          <span>Phase</span>
        </template>
        <template #items>
          <fieldset v-for="module in modules" :key="module.module_name">
            <FormLabel>
              {{ module.module_name }}
            </FormLabel>

            <FormLabel v-for="phase in module.phases" :key="phase.slug">
              <FormCheckbox
                :value="phase"
                v-model="phaseFilter"
                @change="emitChange"
              />
              {{ phase.name }}
            </FormLabel>
          </fieldset>
        </template>
      </FilterSelect>

      <FilterSelect
        data-ref="typeSelect"
        :class="$style.typeSelectBox"
        :submenuVisible="typeSubmenuVisible"
        :withBorder="false"
        @submenu_visibility="handleTypeSubmenuChange"
      >
        <template #title>
          <span>Type</span>
        </template>
        <template #items>
          <FormLabel v-for="(logicalType, index) in logicalTypes" :key="index">
            <FormCheckbox
              :value="logicalType"
              v-model="logicalTypeFilter"
              @change="emitChange"
            />
            {{ logicalType }}
          </FormLabel>
        </template>
      </FilterSelect>

      <div data-ref="text" :class="$style.text">
        <input v-model="textFilter" placeholder="Search in the Library" />
        <div
          :class="$style.search"
          data-ref="search"
          @click="emitChange"
          @keydown.enter="emitChange"
        >
          <i class="icon icon-search" />
        </div>
      </div>
    </div>
    <div
      v-if="displayPills"
      data-ref="pills"
      :class="$style.wrapper"
      class="theme-color"
    >
      <div data-ref="filters" :class="$style.filters">
        <span>Filtered: </span>
        <span
          v-for="(phase, index) in phaseFilter"
          :key="phase.slug"
          :class="$style.pill"
          @click="unsetFilterFor('phase', index)"
        >
          {{ phase.name }} <span>x</span>
        </span>
        <span
          v-for="(type, index) in typeFilter"
          :key="type.slug"
          :class="$style.pill"
          @click="unsetFilterFor('type', index)"
        >
          {{ type.name }} <span>x</span>
        </span>
        <span
          v-for="(logicalType, index) in logicalTypeFilter"
          :key="index"
          :class="$style.pill"
          @click="unsetFilterFor('logicalType', index)"
        >
          {{ logicalType }} <span>x</span>
        </span>
        <span
          v-for="(unit, index) in unitFilter"
          :key="index"
          :class="$style.pill"
          @click="unsetFilterFor('unit', index)"
        >
          {{ unit.name }} <span>x</span>
        </span>
        <span
          v-for="(objective, index) in objectiveFilter"
          :key="index"
          :class="$style.pill"
          @click="unsetFilterFor('objective', index)"
        >
          {{ objective.name }} <span>x</span>
        </span>
      </div>

      <FormButton
        data-ref="clear"
        :ghost="true"
        :class="$style.clearBtn"
        @click="clearFilters()"
      >
        Clear
      </FormButton>
    </div>
  </div>
</template>

<script>
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

export default {
  components: {
    FilterSelect
  },
  props: ['modules', 'types', 'logicalTypes', 'units', 'objectives', 'filters'],
  data() {
    return {
      phaseFilter: undefined,
      typeFilter: undefined,
      logicalTypeFilter: undefined,
      unitFilter: undefined,
      objectiveFilter: undefined,
      textFilter: null,
      phaseSubmenuVisible: false,
      typeSubmenuVisible: false,
      unitSubmenuVisible: false,
      objectiveSubmenuVisible: false
    };
  },
  computed: {
    displayPills() {
      return !!(
        (this.phaseFilter && this.phaseFilter.length) ||
        (this.typeFilter && this.typeFilter.length) ||
        (this.logicalTypeFilter && this.logicalTypeFilter.length) ||
        (this.unitFilter && this.unitFilter.length) ||
        (this.objectiveFilter && this.objectiveFilter.length)
      );
    },
    filtersAreEmpty() {
      return (
        !this.phaseFilter.length &&
        !this.typeFilter.length &&
        !this.logicalTypeFilter.length &&
        !this.unitFilter.length &&
        !this.objectiveFilter.length &&
        !this.textFilter
      );
    },
    phases() {
      let modulesPhases = [];

      if (!this.modules) return [];

      this.modules.forEach(module => modulesPhases.push(...module.phases));
      return modulesPhases;
    }
  },
  created() {
    this.buildFilters();
  },
  watch: {
    filters: {
      handler(newVal) {
        this.buildFilters();
      },
      deep: true
    },
    modules: {
      handler() {
        // This is a bit of a hack. Modules are loaded asynchronously
        // and the select should be rebuilt once they are loaded
        // It would be cleaner to call this.buildFilters()
        // but in order to keep things fast I only refresh the phases filter
        // because especially LOs tend to have thousands of items and
        // it makes things slow to iterate over them
        this.phaseFilter = this.buildSlugFilter(
          this.phases,
          this.filters && this.filters.phase
        );
      },
      deep: true
    }
  },
  methods: {
    handlePhaseSubmenuChange(newSubmenuState) {
      this.phaseSubmenuVisible = newSubmenuState;
    },
    handleTypeSubmenuChange(newSubmenuState) {
      this.typeSubmenuVisible = newSubmenuState;
    },
    handleUnitSubmenuChange(newSubmenuState) {
      this.unitSubmenuVisible = newSubmenuState;
    },
    handleObjectiveSubmenuChange(newSubmenuState) {
      this.objectiveSubmenuVisible = newSubmenuState;
    },
    buildFilters() {
      this.phaseFilter = this.buildSlugFilter(
        this.phases,
        this.filters && this.filters.phase
      );

      this.typeFilter = this.buildSlugFilter(
        this.types,
        this.filters && this.filters.type
      );
      this.logicalTypeFilter = this.buildValueFilter(
        this.logicalTypes,
        this.filters && this.filters.logicalType
      );

      this.unitFilter = this.buildLearningFilter(
        this.units,
        this.filters && this.filters.unit,
        'lo_unit_id'
      );

      this.objectiveFilter = this.buildLearningFilter(
        this.objectives,
        this.filters && this.filters.objective,
        'learning_objective_id'
      );

      this.textFilter = (this.filters && this.filters.text) || null;
    },
    // from allFilterOptions leave only those with slug matching some of filterValues
    buildSlugFilter(allFilterOptions, filterValues) {
      if (!allFilterOptions || !filterValues) {
        return [];
      }

      filterValues = Array.isArray(filterValues)
        ? filterValues
        : [filterValues];

      allFilterOptions = Array.isArray(allFilterOptions)
        ? allFilterOptions
        : [];

      return allFilterOptions.filter(filterOption => {
        return filterValues.some(value => value === filterOption.slug);
      });
    },
    buildLearningFilter(allFilterOptions, filterValues, onAttribute) {
      if (!filterValues) {
        return [];
      }

      if (!onAttribute) {
        return [];
      }

      filterValues = Array.isArray(filterValues)
        ? filterValues
        : [filterValues];

      if (!allFilterOptions) {
        return [];
      }

      allFilterOptions = Array.isArray(allFilterOptions)
        ? allFilterOptions
        : [];

      return allFilterOptions.filter(filterOption => {
        return filterValues.some(
          value => `${value}` === `${filterOption[onAttribute]}`
        );
      });
    },
    // from allFilterOptions leave only those with values matching some of filterValues
    buildValueFilter(allFilterOptions, filterValues) {
      if (!allFilterOptions || !filterValues) {
        return [];
      }

      filterValues = Array.isArray(filterValues)
        ? filterValues
        : [filterValues];

      return allFilterOptions.filter(filterOption => {
        return filterValues.some(value => value.includes(filterOption));
      });
    },
    clearFilters() {
      this.phaseFilter = [];
      this.typeFilter = [];
      this.logicalTypeFilter = [];
      this.unitFilter = [];
      this.objectiveFilter = [];
      this.textFilter = null;

      this.emitChange();
    },
    unsetFilterFor(criteria, index) {
      if (criteria === 'phase') this.phaseFilter.splice(index, 1);
      if (criteria === 'type') this.typeFilter.splice(index, 1);
      if (criteria === 'logicalType') this.logicalTypeFilter.splice(index, 1);
      if (criteria === 'unit') this.unitFilter.splice(index, 1);
      if (criteria === 'objective') this.objectiveFilter.splice(index, 1);
      this.emitChange();
    },
    emitChange() {
      let eventName;
      if (this.filtersAreEmpty) {
        eventName = 'clear';
      } else {
        eventName = 'update';
      }

      this.$emit(eventName, {
        phase: this.phaseFilter.map(filter => filter.slug),
        type: this.typeFilter.map(filter => filter.slug),
        logicalType: this.logicalTypeFilter,
        unit: this.unitFilter.map(filter => filter.slug),
        objective: this.objectiveFilter.map(filter => filter.slug),
        text: this.textFilter
      });
    }
  }
};
</script>

<style lang="scss" module>
@import './ResourcesFilter.scss';
</style>
