<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template slot="pageContent">
      <div :class="$style.wrapper">
        <h6>Operations</h6>

        <div :class="$style.header">
          <div :class="$style.tables">
            <router-link
              to="/admin/operations/pending"
              class="btn ghost"
              :class="{
                [$style.selected]: $route.name === 'admin-pending-operations'
              }"
              >Operations ( {{ numberOfPending }} )</router-link
            >
            <router-link
              to="/admin/operations/approved"
              class="btn ghost"
              :class="{
                [$style.approvedButton]: true,
                [$style.selected]: $route.name === 'admin-approved-operations'
              }"
              >Approved</router-link
            >
            <router-link
              to="/admin/operations/rejected"
              class="btn ghost"
              :class="{
                [$style.rejectedButton]: true,
                [$style.selected]: $route.name === 'admin-rejected-operations'
              }"
              >Rejected</router-link
            >
          </div>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <router-view @pendingCount="pendingCount" />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '@/views/Layout/Layout';

import { GET_PENDING_OPERATIONS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout
  },
  data() {
    return {
      display404: false,
      numberOfPending: 0
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.countPendingOperations();
    }
  },
  methods: {
    pendingCount(numberOfPending) {
      this.numberOfPending = numberOfPending;
    },
    countPendingOperations() {
      this.$store
        .dispatch(GET_PENDING_OPERATIONS)
        .then(operations => {
          if (operations) {
            this.numberOfPending = operations.length;
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminOperations.scss';
</style>
