<template>
  <Notice
    :class="$style.wrapper"
    :type="'warning'"
    v-if="message && slug"
    @dismiss="handleDismiss"
  >
    <template #icon>
      <img src="~@/assets/images/icons/list_icon.png" />
    </template>
    <p>Announcement: <span v-html="message" /></p>
  </Notice>
</template>

<script>
import Notice from '@/components/notices/Notice/Notice';
import { HIDE_ANNOUNCEMENTS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Notice
  },
  props: {
    message: {
      type: String
    },
    slug: {
      type: String
    }
  },
  methods: {
    async handleDismiss() {
      try {
        await this.$store.dispatch(HIDE_ANNOUNCEMENTS, this.slug);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AnnouncementNotice.scss';
</style>
