<template>
  <div :class="$style.wrapper">
    <div :class="$style.search">
      <div :class="$style.inputWrapper" v-click-outside="handleClickOutside">
        <div :class="$style.visibleInputWrapper">
          <ul :class="$style.users" v-if="participantProfiles.length">
            <li
              v-for="(participant, index) in participantProfiles"
              :key="index"
              class="paragraph-small"
              @click.stop="removeParticipant(participant)"
            >
              <UserName :preTitles="true" :user="participant" />
              <i class="icon icon-close" />
            </li>
          </ul>
          <FormInput
            data-ref="search"
            :large="true"
            v-model="searchTerm"
            type="text"
            :placeholder="displayPlaceholder"
          />
        </div>
        <AvatarSelect
          :class="$style.avatarSelect"
          v-click-outside="hideDropdown"
          v-if="showDropdown"
          :users="filterUsers"
          :searchRegExp="searchRegExp"
          size="35px"
          @select="addParticipant"
        />
        <div :class="{ [$style.loader]: true, [$style.on]: isSearching }" />
        <i :class="$style.searchIcon" class="icon icon-search" />
      </div>
      <FormButton
        :class="$style.startConversation"
        @click="onConversationInit"
        :standard="true"
        :disabled="isReadyToStart ? null : true"
        >Start
        <span :class="$style.startIcon">
          <img
            width="18"
            height="18"
            v-svg-inline
            :src="require(`@/assets/images/icons/chat.svg`)"
        /></span>
      </FormButton>
    </div>
  </div>
</template>

<script>
import { CONVERSATION_TYPES, PARTICIPANTS_LIMIT } from '@/config/conversations';

import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import { SEARCH_USER_PROFILES, GET_USER_PROFILE } from '@/store/actions.type';
import {
  SET_GLOBAL_ERROR,
  SET_READY_TO_START,
  SET_USERS_SEARCH_RESULTS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  SET_PARTICIPANTS
} from '@/store/mutations.type';
import focusMixin from '@/mixins/focusMixin';

import ClickOutside from 'vue-click-outside';
import AvatarSelect from '@/components/common/AvatarSelect/AvatarSelect';

const DEBOUNCE_WAIT_MS = 500;

export default {
  name: 'ConversationNewDesktop',
  directives: {
    ClickOutside
  },
  components: {
    AvatarSelect
  },
  mixins: [focusMixin],
  data() {
    return {
      searchTerm: '',
      isSearching: false,
      searchRegExp: undefined,
      showAvatarSelect: false
    };
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'userProfiles',
      'currentUserId',
      'currentUserProfile'
    ]),
    ...mapGetters('chats', [
      'conversationsWithParticipants',
      'isReadyToStart',
      'usersSearchResults',
      'selectedParticipantIds',
      'conversations',
      'currentConversationId'
    ]),
    displayPlaceholder() {
      if (this.participantProfiles.length) {
        return '';
      }

      return 'Start a conversation with ...';
    },
    showDropdown() {
      return (
        !this.isSearching && (this.showAvatarSelect || this.searchTerm !== '')
      );
    },
    filterUsers() {
      if (!Array.isArray(this.usersSearchResults)) return [];

      const usersExclCurrent = this.usersSearchResults.filter(
        user => user.user_id !== this.currentUserId
      );

      return usersExclCurrent;
    },
    participantProfiles() {
      if (!this.userProfiles) return [];

      return this.selectedParticipantIds.map(id =>
        this.userProfiles.find(user => id == user.user_id)
      );
    }
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm) {
        this.searchRegExp = undefined;
        this.SET_USERS_SEARCH_RESULTS([]);
      }

      this.searchRegExp = new RegExp(newSearchTerm, 'ig');
      this.searchUsers(newSearchTerm);
    },
    participantProfiles(newValue) {
      if (newValue.length > 0) {
        this.SET_READY_TO_START(true);
      } else {
        this.SET_READY_TO_START(false);
      }
    }
  },
  methods: {
    ...mapMutations('chats', [
      SET_READY_TO_START,
      SET_USERS_SEARCH_RESULTS,
      ADD_PARTICIPANT,
      REMOVE_PARTICIPANT,
      SET_PARTICIPANTS
    ]),
    addParticipant(userId) {
      this.searchTerm = '';

      const found = this.selectedParticipantIds.includes(userId);

      if (!found && this.selectedParticipantIds.length < PARTICIPANTS_LIMIT) {
        this.ADD_PARTICIPANT(userId);

        // Fetch profiles of users that are missing in store
        this.selectedParticipantIds.map(participantId => {
          const found = this.userProfiles.find(
            user => user.user_id === participantId
          );

          if (!found) {
            this.$store.dispatch(GET_USER_PROFILE, participantId);
          }
        });

        // Focus search input again
        this.$el.querySelector('[data-ref=search]').focus();
      }
    },
    removeParticipant(userProfile) {
      const found = this.selectedParticipantIds.includes(userProfile.user_id);

      if (found) {
        this.REMOVE_PARTICIPANT(userProfile.user_id);
      }
    },
    searchUsers: debounce(function(searchTerm) {
      this.isSearching = true;
      this.$store
        .dispatch(SEARCH_USER_PROFILES, { inactive: true, name: searchTerm })
        .then(users => {
          this.SET_USERS_SEARCH_RESULTS(users);
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        })
        .then(() => {
          this.isSearching = false;
        });
    }, DEBOUNCE_WAIT_MS),
    hideDropdown() {
      this.showAvatarSelect = false;
    },
    handleClickOutside() {
      if (this.conversations.length === 0) return;

      if (
        this.selectedParticipantIds.length === 0 &&
        this.currentConversationId
      ) {
        this.$router
          .replace({
            name: 'conversation-existing',
            params: {
              conversationId: this.currentConversationId
            }
          })
          .catch(() => {});
      }
    },
    onConversationInit() {
      if (this.selectedParticipantIds.length === 0) return;

      const foundConversations = this.conversationsWithParticipants([
        this.currentUserId,
        ...this.selectedParticipantIds
      ]);

      if (foundConversations.length === 0) {
        this.$emit('init_new', {
          type: CONVERSATION_TYPES.ADHOC,
          participant_ids: [...this.selectedParticipantIds, this.currentUserId],
          participants: [...this.participantProfiles, this.currentUserProfile]
        });

        this.SET_PARTICIPANTS([]);
      } else {
        this.$emit('init_existing', foundConversations[0].conversation_id);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationNewDesktop.scss';
</style>
