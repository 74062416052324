import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { periodsByTime } from '@/helpers/schedule';
import { sortingByDate } from '@/helpers/common';

import { FROM_OLDEST } from '@/config/dates';
import { VIEW_AS_OBJECT_TYPES } from '@/config/schedule';

import {
  GET_MODULES_TEMP,
  GET_SCHEDULE_WEEK,
  GET_SCHEDULE_WEEK_CURRENT,
  GET_USER_KVSTORE,
  SET_VIEW_AS_ACTION,
  PATCH_USER_KVSTORE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    nowISO: {
      type: String,
      default: moment().toISOString(true)
    }
  },
  data() {
    return {
      modules: [],
      cohortId: null
    };
  },
  computed: {
    ...mapGetters([
      'asCohortId',
      'selectedCohortId',
      'isViewingAsCohort',
      'getUserKVStoreValue'
    ]),
    persistedViewAs() {
      return this.getUserKVStoreValue('view_as');
    },
    persistedViewAsCohortId() {
      if (!this.isViewAsPersisted) return undefined;
      if (this.persistedViewAs.object_type !== VIEW_AS_OBJECT_TYPES.cohort)
        return undefined;

      return this.persistedViewAs.object_id;
    },
    isViewAsCohortPersisted() {
      return this.persistedViewAsCohortId ? true : false;
    },
    isViewAsPersisted() {
      return this.persistedViewAs && this.persistedViewAs.object_type
        ? true
        : false;
    },
    routeHas() {
      // Do not mess with order of these keys! They're used to match a record in this.rules and their order is important
      // A rule with [0,1] means that module is not known (current ones are figured out) and view-as for a cohort is requested
      return {
        module: this.hasURLParam('moduleVersionId'),
        cohort: this.hasURLQuery('asCohort')
      };
    },
    routeValue() {
      return {
        module: this.routeHas.module
          ? parseInt(this.$route.params.moduleVersionId)
          : NaN,
        cohort: this.routeHas.cohort
          ? parseInt(this.$route.query.asCohort)
          : NaN
      };
    }
  },
  methods: {
    ...mapActions([
      GET_MODULES_TEMP,
      SET_VIEW_AS_ACTION,
      PATCH_USER_KVSTORE,
      GET_SCHEDULE_WEEK,
      GET_SCHEDULE_WEEK_CURRENT,
      GET_USER_KVSTORE
    ]),
    hasURLParam(param) {
      if (this.$route && this.$route.params && this.$route.params[param]) {
        return true;
      }

      return false;
    },
    hasURLQuery(param) {
      if (this.$route && this.$route.query && this.$route.query[param]) {
        return true;
      }

      return false;
    },
    handleParamError() {
      //
    },
    redirectToUndefined(debugObject) {
      this.handleParamError(debugObject);
      return;
    },
    getClosestModule() {
      const modulesByTime = this.periodsByTime(
        this.modules,
        this.nowISO,
        false,
        'start_date',
        'end_date'
      );

      const is = {
        allInPast:
          modulesByTime &&
          modulesByTime.all &&
          modulesByTime.all.length &&
          modulesByTime.all.length === modulesByTime.past.length,
        allInFuture:
          modulesByTime &&
          modulesByTime.all &&
          modulesByTime.all.length &&
          modulesByTime.all.length === modulesByTime.future.length,
        someInPresent:
          modulesByTime &&
          modulesByTime.all &&
          modulesByTime.all.length &&
          modulesByTime.present.length > 0
      };

      if (is.allInPast) {
        const lastPastModule =
          modulesByTime.past[modulesByTime.past.length - 1];
        return lastPastModule ? lastPastModule : undefined;
      } else if (is.allInFuture) {
        const firstFutureModule = modulesByTime.future[0];
        return firstFutureModule ? firstFutureModule : undefined;
      } else if (is.someInPresent) {
        const firstPresentModule = modulesByTime.present[0];
        return firstPresentModule ? firstPresentModule : undefined;
      } else {
        return undefined;
      }
    },
    periodsByTime(periods, dateISOString) {
      const result = {
        past: [],
        present: [],
        future: [],
        all: periods
      };

      const pivot = moment(dateISOString);

      if (
        Object.prototype.toString.call(periods) !== '[object Array]' ||
        !dateISOString ||
        !pivot.isValid()
      ) {
        return undefined;
      }

      if (periods.length === 0) {
        return result;
      }

      const validPeriods = periods.filter(
        period =>
          period.start_date &&
          moment(period.start_date).isValid() &&
          period.end_date &&
          moment(period.end_date).isValid()
      );

      if (validPeriods.length === 0) {
        return result;
      }

      sortingByDate(validPeriods, 'start_date', FROM_OLDEST);

      validPeriods.map(period => {
        const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

        const m_periodStart = moment(period.start_date);
        const m_periodEnd = moment(period.end_date);

        if (pivot.isBetween(m_periodStart, m_periodEnd, null, INCLUSIVITY)) {
          result.present.push(period);
          return;
        }

        if (pivot.isBefore(m_periodStart)) {
          result.future.push(period);
          return;
        }

        if (pivot.isAfter(m_periodEnd)) {
          result.past.push(period);
        }
      });

      return result;
    }
  },
  async created() {
    try {
      await this.GET_USER_KVSTORE();

      if (this.routeHas.cohort) {
        this.cohortId = this.routeValue.cohort;
      } else {
        if (this.isViewAsCohortPersisted) {
          this.cohortId = this.persistedViewAsCohortId;
        } else if (this.asCohortId) {
          this.cohortId = this.asCohortId;
        } else if (this.selectedCohortId) {
          this.cohortId = this.selectedCohortId;
        }
      }

      this.modules = await this.GET_MODULES_TEMP({
        as_cohort_id: this.cohortId ? this.cohortId : null
      });

      const { module_version_id } = this.getClosestModule();

      const destination = {
        name: 'learning-units',
        params: {
          moduleVersionId: module_version_id
        },
        query: {
          ...this.$route.query,
          ...{
            asCohort: this.cohortId
          }
        }
      };

      await this.$router.replace(destination);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });

      this.redirectToUndefined();
    }
  },
  render() {
    return;
  }
};
