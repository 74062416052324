<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Users ({{ totalRecords }})</h6>
          <div :class="$style.buttons">
            <FormButton @click="handleOpened" :class="$style.greenButton"
              ><i class="icon icon-user-plus" />Create user
            </FormButton>
            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
          :pagination-options="{
            enabled: true,
            infoFn: params => setTotalRecords(params.totalRecords)
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <router-link
                class="adminPrimaryId"
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
              >
                {{ props.row.id }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'firstName'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
              >
                {{ props.row.firstName }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'lastName'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
              >
                {{ props.row.lastName }}
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>

      <ModalNew
        :class="$style.adminModal"
        :showModal="showModal"
        v-transfer-dom
        @close="handleClosed"
      >
        <AdminCreateUser @reloadPage="fetchUsers()" />
      </ModalNew>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { GET_ADMIN_USERS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminCreateUser from '@/components/admin/AdminCreateUser/AdminCreateUser';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable,
    AdminCreateUser
  },
  data() {
    return {
      showModal: false,
      totalRecords: 0,
      searchParams: {},
      display404: false,
      users: [],
      columns: [
        {
          label: 'User ID',
          field: 'id',
          type: 'number',
          filterOptions: {
            enabled: true,
            styleClass: 'columnId',
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'First name',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('firstName')
          }
        },
        {
          label: 'Last name',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('lastName')
          }
        },
        {
          label: 'Job title',
          field: 'title',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('title')
          }
        },
        {
          label: 'Primary role',
          field: 'role',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('role')
          }
        },
        {
          label: 'Last activity',
          field: 'activity',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy - HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('activity')
          }
        },
        {
          label: 'Created at',
          field: 'created',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy - HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('created')
          }
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchUsers();
    }
  },
  methods: {
    setTotalRecords(totalRecords) {
      this.totalRecords = totalRecords;
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'firstName':
          return this.$route.query.firstName;
          break;
        case 'lastName':
          return this.$route.query.lastName;
          break;
        case 'title':
          return this.$route.query.title;
          break;
        case 'role':
          return this.$route.query.role;
          break;
        case 'activity':
          return this.$route.query.activity;
          break;
        case 'created':
          return this.$route.query.created;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        firstName: column.firstName || [],
        lastName: column.lastName || [],
        title: column.title || [],
        role: column.role || [],
        activity: column.activity || [],
        created: column.created || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    fetchUsers() {
      this.$store
        .dispatch(GET_ADMIN_USERS)
        .then(users => {
          this.users = users;
        })
        .then(users => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    formatTime(time) {
      if (!time) {
        return null;
      }

      return moment(time).format('DD.MM.yyyy - HH:mm');
    },
    createRows() {
      this.rows = [];

      this.users.filter(user => {
        this.rows.push({
          id: user.user_id || [],
          firstName: user.name.first_name || [],
          lastName: user.name.last_name || [],
          title: user.job_title || [],
          role: user.role_code || [],
          activity: this.formatTime(user.last_activity),
          created: this.formatTime(user.created_at)
        });
      });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    handleOpened() {
      this.showModal = true;
    },
    handleClosed() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminTables.scss';
</style>
