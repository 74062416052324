<template>
  <SideWidget :class="$style.wrapper">
    <template #name>FAQ</template>

    <template #content>
      <ul class="theme-color box-shadow-white pt-1 pb-1 pl-2 pr-2">
        <li v-for="faqTopic in faqTopics" :key="faqTopic.id">
          <router-link
            :to="{ name: 'faq-topic', params: { topicSlug: faqTopic.slug } }"
            >{{ faqTopic.name }}
          </router-link>
        </li>
      </ul>
    </template>
  </SideWidget>
</template>

<script>
import SideWidget from '@/components/common/SideWidget/SideWidget';

export default {
  components: {
    SideWidget
  },
  props: {
    faqTopics: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetFaqTopics.scss';
</style>
