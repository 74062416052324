import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import common from './common.module';
import schedule from './schedule.module';
import teams from './teams.module';
import domain from './domain.module';
import meta from './meta.module';
import errors from './errors.module';
import faqs from './faqs.module';
import users from './users.module';
import resources from './resources.module';
import settings from './settings.module';
import kvstore from './kvstore.module';
import admin from './admin.module';

// new structure (pls. see modules/README.md)
import actions from './actions';

import discussionCategories from './modules/discussionCategories/discussionCategories';
import discussions from './modules/discussions/discussions';
import forumTopics from './modules/forumTopics/forumTopics';
import topicPosts from './modules/topicPosts/topicPosts';
import groupwork from './modules/groupwork/groupwork';
import groupWorkNext from './modules/groupWorkNext/groupWorkNext';
import assessment from './modules/assessment/assessment';
import learning from './modules/learning/learning';
import library from './modules/library/library';
import grading from './modules/grading/grading';
import quiz from './modules/quiz/quiz';
import tasks from './modules/tasks/tasks';
import chats from './modules/chats/chats';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    actions,
    auth,
    common,
    schedule,
    teams,
    domain,
    meta,
    errors,
    faqs,
    users,
    resources,
    settings,
    kvstore,
    admin,
    groupWorkNext: {
      namespaced: true,
      ...groupWorkNext
    },
    groupwork: {
      namespaced: true,
      ...groupwork
    },
    discussions: {
      namespaced: true,
      ...discussions
    },
    discussionCategories: {
      namespaced: true,
      ...discussionCategories
    },
    forumTopics: {
      namespaced: true,
      ...forumTopics
    },
    topicPosts: {
      namespaced: true,
      ...topicPosts
    },
    assessment: {
      namespaced: true,
      ...assessment
    },
    learning: {
      namespaced: true,
      ...learning
    },
    library: {
      namespaced: true,
      ...library
    },
    grading: {
      namespaced: true,
      ...grading
    },
    quiz: {
      namespaced: true,
      ...quiz
    },
    tasks: {
      namespaced: true,
      ...tasks
    },
    chats: {
      namespaced: true,
      ...chats
    }
  }
});
