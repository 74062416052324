<template>
  <Layout variant="5">
    <template #pageContent>
      <template v-if="isLoading">
        <InlineLoader />
      </template>
      <template v-else>
        <div :class="$style.header">
          <div v-if="shouldSeeViewAs === true" :class="$style.viewAs">
            <strong>View as:</strong>
            <SingleItemSuggest
              :userId="asUserId"
              :cohortId="asCohortId"
              :allowedSuggestionTypes="allowedSuggestionTypes"
              @selected_user_id="handleUserIdSelected"
              @selected_cohort_id="handleCohortIdSelected"
              @cleared="handleSelectionCleared"
            />
          </div>

          <div v-if="shouldSeeWeekInput === true" :class="$style.weekInput">
            <strong>Week:</strong>
            <CalendarWeekInput
              :selectedWeekStartAt="selectedWeekStartAt"
              @calendar_week_selected="onCalendarWeekSelected"
              :weeks="weeks"
            />
          </div>

          <div
            v-if="shouldSeeFacilitatorSwitch === true"
            :class="$style.facilitatorInput"
          >
            <strong>My Sessions</strong>
            <FormThreeWaySwitchAutoSave
              name="facilitatedModel"
              data-cy="facilitated"
              :value="facilitatedModel"
              :modelValue="facilitatedModel"
              :disabled="false"
              :action="saveFacilitated"
              :validation="() => true"
              @validation_result="() => true"
              @success="facilitatedModel = !facilitatedModel"
              @error="() => true"
              @state="() => true"
              :withActionIndicator="false"
              :colors="threeWaySwitchColors"
            />
          </div>
        </div>

        <template>
          <div
            :class="$style.modulesNavigation"
            v-if="shouldSeeModulesNavigation === true"
          >
            <span v-if="isMobileLayout" :class="$style.header">
              <h5>Schedule</h5>
              <p>{{ selectedWeekName }}</p>
            </span>
            <ModulesNavigation
              :modules="modules"
              :runningModuleVersionId="currentModuleVersionId"
              :onlyRunning="false"
              :onlyRunningOrFuture="true"
            />
          </div>
          <div
            :class="{
              [$style.mobileGrid]: true,
              [$style.withVerticalColumn]: isViewingAsCohort
            }"
          >
            <div
              :class="$style.weeksNavigation"
              v-if="shouldSeeWeeksNavigation === true"
            >
              <WeeksNavigation
                :weeks="weeks"
                :currentWeekId="currentWeekId"
                @selected_week="onSelectedWeek"
                :key="`${currentWeekId}-${selectedWeekId}`"
              />
            </div>

            <div>
              <TickTack :emitAfterTicks="10" emit="update" @update="updateNow">
                <template v-slot="{ currentTime }">
                  <WeekDetailEmpty
                    v-if="isLoadingWeekData"
                    :weekDetail="weekDetail"
                    :weekType="undefined"
                    :key="selectedWeekType"
                  >
                    <div class="lesson">
                      <div class="note">
                        <div>
                          <InlineLoader />
                        </div>
                      </div>
                    </div>
                  </WeekDetailEmpty>

                  <WeekDetailEmpty
                    v-else-if="
                      isCurrentUserAdmin &&
                        !isViewingAsUser &&
                        !isViewingAsCohort
                    "
                    :weekDetail="weekDetail"
                    :weekType="undefined"
                    :key="selectedWeekType"
                  >
                    <div class="lesson">
                      <div class="note">
                        <div>
                          <div
                            v-html="
                              `<p><i class='icon icon-search'></i> Please search and select a name or a cohort to view</p>`
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </WeekDetailEmpty>

                  <WeekDetailEmpty
                    v-else-if="
                      isCurrentUserAdmin &&
                        isViewingAsUser &&
                        asUserRole !== ROLES.student &&
                        !isViewingAsCohort &&
                        selectedWeekId &&
                        weekSessions.length === 0
                    "
                    :weekDetail="weekDetail"
                    :weekType="undefined"
                    :key="selectedWeekType"
                  >
                    <div class="lesson">
                      <div class="note">
                        <div>
                          <img
                            width="150"
                            v-svg-inline
                            :src="
                              require('@/assets/images/icons/empty_teaching_staff.svg')
                            "
                          />
                        </div>
                        <div>
                          <h5>No sessions for this week</h5>
                          <div
                            v-html="
                              '<p>Did you know that pending sessions can get easily heartbroken if they wait to be evaluated more than 2 days?</p>'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </WeekDetailEmpty>

                  <WeekDetailEmpty
                    v-else-if="
                      !isCurrentUserAdmin &&
                        isCurrentUserTeachingStaff &&
                        isViewingAsCohort &&
                        !isViewingAsUser &&
                        selectedWeekId &&
                        weekSessions.length === 0
                    "
                    :weekDetail="weekDetail"
                    :weekType="selectedWeekType"
                    :key="selectedWeekType"
                  />

                  <WeekDetailEmpty
                    v-else-if="
                      !isCurrentUserAdmin &&
                        isCurrentUserTeachingStaff &&
                        selectedWeekId &&
                        weekSessions.length === 0
                    "
                    :weekDetail="weekDetail"
                    :weekType="undefined"
                    :key="selectedWeekType"
                  >
                    <div class="lesson">
                      <div class="note">
                        <div>
                          <img
                            width="150"
                            v-svg-inline
                            :src="
                              require('@/assets/images/icons/empty_teaching_staff.svg')
                            "
                          />
                        </div>
                        <div>
                          <h5>No sessions for this week</h5>
                          <div
                            v-html="
                              '<p>Did you know that pending sessions can get easily heartbroken if they wait to be evaluated more than 2 days?</p>'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </WeekDetailEmpty>

                  <WeekDetailEmpty
                    v-else-if="selectedWeekId && weekSessions.length === 0"
                    :weekDetail="weekDetail"
                    :weekType="selectedWeekType"
                    :key="selectedWeekType"
                  >
                    <div class="lesson">
                      <div class="note">
                        <div>
                          <InlineLoader />
                        </div>
                      </div>
                    </div>
                  </WeekDetailEmpty>

                  <WeekDetailMobile
                    v-else-if="
                      selectedWeekId &&
                        weekSessions.length > 0 &&
                        isMobileLayout
                    "
                    :nowISO="nowISO"
                    :weekDetail="weekDetail"
                    :weekType="selectedWeekType"
                    :highlightFacilitated="
                      facilitatedModel && shouldSeeFacilitatorSwitch
                    "
                  >
                    {{ currentTime }}
                  </WeekDetailMobile>

                  <WeekDetail
                    v-else-if="selectedWeekId && weekSessions.length > 0"
                    :nowISO="nowISO"
                    :weekDetail="weekDetail"
                    :weekType="selectedWeekType"
                    :highlightFacilitated="
                      facilitatedModel && shouldSeeFacilitatorSwitch
                    "
                    :selectedModuleVersionId="selectedModuleVersionId"
                  >
                    {{ currentTime }}
                  </WeekDetail>

                  <div v-else-if="!selectedModuleVersionId">
                    Please select a module.
                  </div>

                  <div v-else>
                    <!-- When no week information is available but module version id is known -->
                  </div>
                </template>
              </TickTack>
            </div>
          </div>

          <CalendarLink :class="$style.calendar" />
        </template>
      </template>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import {
  GET_SCHEDULE_WEEK,
  GET_SCHEDULE_WEEK_CURRENT,
  GET_USER_KVSTORE,
  PATCH_USER_KVSTORE,
  SET_VIEW_AS_ACTION,
  GET_MODULES
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import { CYCLE_TYPES, VIEW_AS_OBJECT_TYPES } from '@/config/schedule';
import { romanize } from '@/helpers/common';
import { ROLES } from '@/config/users';
import { THEMES, DEFAULT_THEME } from '@/config/themes';

const THEME_COLORS = THEMES[process.env.THEME || DEFAULT_THEME];

import Layout from '@/views/Layout/Layout';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import CalendarLink from '@/components/common/CalendarLink/CalendarLink';
import ModulesNavigation from '@/views/Schedule/ModulesNavigation';
import WeeksNavigation from '@/views/Schedule/WeeksNavigation';
import WeekDetail from '@/views/Schedule/WeekDetail';
import WeekDetailMobile from '@/views/Schedule/WeekDetailMobile';
import WeekDetailEmpty from '@/views/Schedule/WeekDetailEmpty';
import SingleItemSuggest from '@/views/Schedule/SingleItemSuggest';
import TickTack from '@/components/common/TickTack/TickTack';
import CalendarWeekInput from './CalendarWeekInput';

export default {
  name: 'Schedule',
  components: {
    InlineLoader,
    CalendarLink,
    ModulesNavigation,
    WeeksNavigation,
    Layout,
    TickTack,
    WeekDetail,
    WeekDetailMobile,
    WeekDetailEmpty,
    SingleItemSuggest,
    CalendarWeekInput
  },
  props: {
    forceNowISO: {
      type: String,
      default: undefined
    },
    maxRecords: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      nowISO: this.forceNowISO ? this.forceNowISO : moment().toISOString(true),
      modules: [],
      weeks: [],
      weekSessions: [],
      currentModuleVersionId: undefined,
      currentWeekId: undefined,
      selectedWeekStartAt: undefined,
      selectedWeekEndAt: undefined,
      selectedWeekType: undefined,
      isLoading: true,
      isLoadingWeekData: true,
      hasError: false
    };
  },
  watch: {
    $route() {
      this.initViewAs();
      this.fetchWeekData();
    }
  },
  computed: {
    ...mapGetters([
      'isMobileLayout',
      'currentUserId',
      'asUserId',
      'asUserProfile',
      'asCohortId',
      'asUserRole',
      'isCurrentUserStudent',
      'isCurrentUserAdmin',
      'isCurrentUserTeachingStaff',
      'getUserKVStoreValue',
      'shouldSeeFacilitatorSwitch',
      'isViewingAsUser',
      'isViewingAsCohort',
      'shouldUsePersonalEndpoint',
      'allowedSuggestionTypes'
    ]),
    routeHas() {
      // Do not mess with order of these keys! They're used to match a record in this.rules and their order is important
      // A rule with [0,0,1,0] means that module and week is not known (current ones are figured out) and view-as for a user is requested
      return {
        module: this.hasURLParam('moduleVersionId'),
        week: this.hasURLParam('weekId'),
        user: this.hasURLQuery('asUser'),
        cohort: this.hasURLQuery('asCohort')
      };
    },
    routeValue() {
      return {
        module: this.routeHas.module
          ? parseInt(this.$route.params.moduleVersionId)
          : NaN,
        week: this.routeHas.week ? parseInt(this.$route.params.weekId) : NaN,
        user: this.routeHas.user ? parseInt(this.$route.query.asUser) : NaN,
        cohort: this.routeHas.cohort
          ? parseInt(this.$route.query.asCohort)
          : NaN
      };
    },
    threeWaySwitchColors() {
      return {
        true: THEME_COLORS['primaryColor'],
        false: THEME_COLORS['light-grey'],
        undefined: THEME_COLORS['light-grey']
      };
    },
    selectedWeek() {
      if (!this.routeHas.week) return {};

      return this.weeks.find(
        week => week.calendar_week_id === this.routeValue.week
      );
    },
    selectedModuleVersionId() {
      return this.routeValue.module;
    },
    selectedWeekId() {
      return this.routeValue.week;
    },
    upcomingDays() {
      return Array(this.maxRecords)
        .fill(0)
        .map((dummyValue, index) => {
          void dummyValue;

          if (index === 0)
            return moment(this.selectedWeekStartAt)
              .startOf('isoWeek')
              .toISOString();

          return moment(this.selectedWeekStartAt)
            .startOf('isoWeek')
            .add(index, 'day')
            .toISOString();
        });
    },
    upcommingLessons() {
      return this.upcomingDays.reduce((memo, dayISO) => {
        memo[dayISO] = this.getLessonsForDay(dayISO);

        return memo;
      }, {});
    },
    weekDetail() {
      let totalItems = 0;
      let dayItems = 0;

      return Object.entries(this.upcommingLessons).reduce((memo, day) => {
        const dayISO = day[0];
        const dayLessons = day[1];

        // If there's no key for current dayISO, let's create one
        if (!memo[dayISO]) {
          memo[dayISO] = [];
        }

        // If the daiISO has no lessons increment the total
        if (dayLessons.length === 0) {
          totalItems++;
        } else {
          dayLessons
            .sort((a, b) => {
              const m_start_a = moment(a.start_at);
              const m_start_b = moment(b.start_at);

              if (m_start_a.isSame(m_start_b)) {
                return 0;
              } else if (m_start_a.isBefore(m_start_b)) {
                return -1;
              } else {
                return 1;
              }
            })
            .map(lesson => {
              memo[dayISO].push(lesson);
              totalItems++;
              dayItems++;
            });
        }

        // Reset day lessons counter
        dayItems = 0;

        return memo;
      }, {});
    },
    weekTypeGroups() {
      return this.weeks.reduce((memo, week) => {
        const weekType = week.phase_type;
        const group = {
          type: weekType,
          weeks: []
        };

        if (!memo.length) {
          group.weeks.push(week);
          memo.push(group);
          return memo;
        }

        const lastGroup = memo[memo.length - 1];
        const lastGroupType = lastGroup.type;

        if (
          lastGroupType === weekType &&
          ![CYCLE_TYPES.study].includes(weekType)
        ) {
          lastGroup.weeks.push(week);
        } else {
          group.weeks.push(week);
          memo.push(group);
        }

        return memo;
      }, []);
    },
    weekTypeGroupLabels() {
      let studyTypeCounter = 0,
        flexTypeCounter = 0,
        crTypeCounter = 0;

      return this.weekTypeGroups.map(group => {
        if (group.type === CYCLE_TYPES.study) {
          studyTypeCounter++;
          return `Week ${studyTypeCounter}`;
        }

        if (group.type === CYCLE_TYPES.flex) {
          flexTypeCounter++;
          return `Flex Week ${romanize(flexTypeCounter)}`;
        }

        if (group.type === CYCLE_TYPES.onboarding) {
          return 'Onboarding';
        }

        if (group.type === CYCLE_TYPES.cr) {
          crTypeCounter++;
          return `Clinical rotation ${romanize(crTypeCounter)}`;
        }

        if (group.type === CYCLE_TYPES.holiday) {
          return 'Holiday';
        }
      });
    },
    groupWeeks() {
      return this.weekTypeGroups.map(group => {
        return group.weeks.map(week => week.calendar_week_id);
      });
    },
    weekNames() {
      return this.groupWeeks.reduce((memo, val, groupIndex) => {
        val.map(week => {
          memo[week] = this.weekTypeGroupLabels[groupIndex];
        });

        return memo;
      }, {});
    },
    selectedWeekName() {
      if (!this.routeHas.week) return undefined;

      return this.weekNames[this.routeValue.week];
    },
    shouldSeeViewAs() {
      if (this.isCurrentUserStudent) return false;
      if (this.isCurrentUserAdmin) return true;
      if (this.isCurrentUserTeachingStaff) return true;

      return undefined;
    },
    modulesNavigationRoles() {
      return [ROLES.student];
    },
    calendarWeekSelectorRoles() {
      return [
        ROLES.mentor,
        ROLES.tutor,
        ROLES.expert,
        ROLES.staff,
        ROLES.moduleLeader
      ];
    },
    shouldSeeWeekInput() {
      // Student
      if (this.isCurrentUserStudent) return false;

      // Admin
      if (this.isCurrentUserAdmin) {
        if (
          this.isViewingAsUser &&
          this.calendarWeekSelectorRoles.includes(this.asUserRole)
        ) {
          return true;
        } else {
          return false;
        }
      }

      // Teaching staff
      if (this.isCurrentUserTeachingStaff) {
        if (!this.isViewingAsCohort) return true;
      }

      return undefined;
    },
    shouldSeeModulesNavigation() {
      // Student
      if (this.isCurrentUserStudent) return true;

      // Admin
      if (this.isCurrentUserAdmin) {
        if (this.isViewingAsCohort) return true;

        if (
          this.isViewingAsUser &&
          this.modulesNavigationRoles.includes(this.asUserRole)
        )
          return true;
      }

      // Teaching staff
      if (this.isCurrentUserTeachingStaff) {
        if (this.isViewingAsCohort) return true;
      }

      return undefined;
    },
    shouldSeeWeeksNavigation() {
      return this.shouldSeeModulesNavigation;
    },
    facilitatedModel: {
      get() {
        return this.getUserKVStoreValue('schedule_facilitated');
      },
      async set(newValue) {
        try {
          await this.PATCH_USER_KVSTORE({ schedule_facilitated: newValue });
          await this.GET_USER_KVSTORE();
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      }
    }
  },
  async created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
    this.ROLES = ROLES;

    this.initViewAs();

    try {
      this.isLoading = true;

      await this.fetchUserKVStore();
      this.modules = await this.GET_MODULES({
        as_cohort_id: this.isViewingAsCohort ? this.asCohortId : null,
        as_user_id: this.isViewingAsUser ? this.asUserId : null
      });

      this.fetchWeekData();

      this.isLoading = false;
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      this.hasError = true;
      this.isLoading = false;
      return;
    }
  },
  methods: {
    ...mapActions([
      SET_VIEW_AS_ACTION,
      PATCH_USER_KVSTORE,
      GET_USER_KVSTORE,
      GET_MODULES
    ]),
    saveFacilitated() {
      return Promise.resolve();
    },
    onCalendarWeekSelected(weekStartISOString) {
      const foundWeekId = this.isoStringToWeekId(weekStartISOString);
      if (foundWeekId) {
        this.onSelectedWeek(foundWeekId);
      }
    },
    async handleUserIdSelected(userId) {
      try {
        await this.setViewAsUser(userId);
        this.handleViewAsUserIdRoute(userId);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async handleCohortIdSelected(cohortId) {
      try {
        await this.setViewAsCohort(cohortId);
        this.handleViewAsCohortIdRoute(cohortId);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async handleSelectionCleared() {
      try {
        await this.SET_VIEW_AS_ACTION();

        if (this.isCurrentUserAdmin || this.isCurrentUserTeachingStaff) {
          this.$router
            .push({
              name: 'schedule'
            })
            .catch(() => {});
          return;
        } else {
          // TODO: Figure out when it is called? For students only?
          // Should there be a different method? Maybe a SET_VIEW_AS_ACTION call instead?
          this.handleViewAsUserIdRoute(NaN);
        }
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    setViewAsUser(userId) {
      return this.SET_VIEW_AS_ACTION({
        view_as: {
          object_type: VIEW_AS_OBJECT_TYPES.user,
          object_id: userId
        }
      });
    },
    setViewAsCohort(cohortId) {
      return this.SET_VIEW_AS_ACTION({
        view_as: {
          object_type: VIEW_AS_OBJECT_TYPES.cohort,
          object_id: cohortId
        }
      });
    },
    async initViewAs() {
      if (this.routeHas.user && !this.isViewingAsUser) {
        try {
          this.setViewAsUser(this.routeValue.user);
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      } else if (this.routeHas.cohort && !this.isViewingAsCohort) {
        try {
          await this.setViewAsCohort(this.routeValue.cohort);
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      }
    },
    handleViewAsUserIdRoute(newUserId) {
      if (!newUserId) {
        this.$router
          .push({
            name: 'schedule',
            query: {}
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: 'schedule',
            query: {
              asUser: newUserId
            }
          })
          .catch(() => {});
      }

      return;
    },
    handleViewAsCohortIdRoute(newCohortId) {
      if (!newCohortId) {
        this.$router
          .push({
            name: 'schedule',
            query: {}
          })
          .catch(() => {});
        return;
      } else {
        this.$router
          .push({
            name: 'schedule',
            query: {
              asCohort: newCohortId
            }
          })
          .catch(() => {});
        return;
      }

      return;
    },
    updateNow(dateISO) {
      this.nowISO = this.forceNowISO ? this.forceNowISO : dateISO;
    },
    onSelectedWeek(calendarWeekId) {
      if (this.selectedModuleVersionId) {
        this.$router.push({
          name: 'schedule-module-version-week-detail',
          params: {
            moduleVersionId: this.selectedModuleVersionId,
            weekId: calendarWeekId
          },
          query: this.$route.query
        });
      } else {
        this.$router.push({
          name: 'schedule-week-detail',
          params: {
            weekId: calendarWeekId
          },
          query: this.$route.query
        });
      }
    },
    fetchUserKVStore() {
      return this.$store.dispatch(GET_USER_KVSTORE);
    },
    getWeekType(weekId) {
      if (!Array.isArray(this.weeks)) return undefined;

      const found = this.weeks.find(week => week.calendar_week_id === weekId);

      return found && found.phase_type ? found.phase_type : undefined;
    },
    async fetchWeekData() {
      if (!this.routeHas.week) {
        this.weeks = [];
        this.weekSessions = [];
        this.selectedWeekStartAt = undefined;
        this.selectedWeekEndAt = undefined;
        this.selectedWeekType = undefined;

        return;
      }

      this.isLoadingWeekData = true;

      // At first we're ignoring any specific weekId to obtain current week and module for current/specific user/cohort
      const currentParams = {
        asUserId: this.asUserId,
        asCohortId: this.asCohortId,
        personal: this.shouldUsePersonalEndpoint
      };

      const current = await this.$store.dispatch(
        GET_SCHEDULE_WEEK_CURRENT,
        currentParams
      );
      this.currentModuleVersionId = current.module_version_id;
      this.currentWeekId = current.calendar_week_id;

      // Now let's make a request to obtain specific week
      // The module_version_id param is passed only when trying to get other module then the current one
      const specificParams = {
        moduleVersionId:
          this.currentModuleVersionId !== this.routeValue.module
            ? this.routeValue.module
            : NaN,
        calendarWeekId: this.routeValue.week,
        asUserId: this.asUserId,
        asCohortId: this.asCohortId,
        personal: this.shouldUsePersonalEndpoint
      };

      const {
        module_version_id,
        calendar_week_id,
        weeks,
        sessions,
        start_date,
        end_date
      } = await this.$store.dispatch(
        this.routeHas.week ? GET_SCHEDULE_WEEK : GET_SCHEDULE_WEEK_CURRENT,
        specificParams
      );

      this.weeks = weeks;
      this.weekSessions = sessions || [];
      this.selectedWeekStartAt = start_date;
      this.selectedWeekEndAt = end_date;
      this.selectedWeekType = this.getWeekType(calendar_week_id);

      this.isLoadingWeekData = false;
    },
    getLessonsForDay(dayISO) {
      const m_dayStart = moment(dayISO).startOf('day');
      const m_dayEnd = moment(dayISO).endOf('day');

      const lessons = this.weekSessions.filter(event => {
        const m_event_start = moment(event.start_at);
        const m_event_end = moment(event.end_at);

        if (!m_event_start.isValid() || !m_event_end.isValid()) return false;

        // Filter out assignments of group_work kind
        if (event.kind === 'group_work') return false;

        // Check if an event takes place during dayISO, maybe even part of it
        const isEventWithinDay =
          m_event_start.isBetween(m_dayStart, m_dayEnd, undefined, '[]') ||
          m_event_end.isBetween(m_dayStart, m_dayEnd, undefined, '[]');

        return isEventWithinDay;
      });

      return lessons;
    },
    isoStringToWeekId(isoStringWeekStart) {
      const found = this.weeks.find(week => {
        const m_start_date = moment(week.start_date).startOf('week');
        const m_selected = moment(isoStringWeekStart).startOf('week');
        return m_selected.isSame(m_start_date, 'day');
      });

      return found ? found.calendar_week_id : undefined;
    },
    hasURLParam(param) {
      if (this.$route && this.$route.params && this.$route.params[param]) {
        return true;
      }

      return false;
    },
    hasURLQuery(param) {
      if (this.$route && this.$route.query && this.$route.query[param]) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style lang="scss" module>
@import './Schedule.scss';
</style>
