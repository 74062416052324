import axios from 'axios';
import ApiService from '@/api/api.service';
import { logError } from '@/helpers/errors';
import { sumUnread } from '@/helpers/conversations';
import { PLACEHOLDER_ID, READ_AFTER_MS } from '@/config/conversations';

import {
  GET_CONVERSATIONS,
  GET_MESSAGES,
  GET_UNREAD_COUNT,
  CREATE_CONVERSATION,
  OPEN_CONVERSATION,
  OPEN_PLACEHOLDER_CONVERSATION,
  POLL_MESSAGES,
  SCHEDULE_MARK_AS_READ,
  SEND_MESSAGE
} from '@/store/actions.type';

import {
  SET_GLOBAL_ERROR,
  SET_CONVERSATIONS,
  SET_UNREAD_COUNT,
  SET_CONVERSATION_ID,
  SET_READ_TIMEOUT,
  SET_PLACEHOLDER_CONVERSATION,
  MERGE_MESSAGES,
  PURGE_GLOBAL_ERROR,
  PURGE_CONVERSATION,
  PURGE_PLACEHOLDER_CONVERSATION,
  SET_POLLING_CANCEL_TOKEN
} from '@/store/mutations.type';

const REASON_CANCEL = 'cancel';
const REASON_UNEXPECTED = 'unexpected';

export const actions = {
  [GET_CONVERSATIONS]({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      ApiService.getConversations()
        .then(({ data }) => {
          commit(SET_CONVERSATIONS, data);

          commit(SET_UNREAD_COUNT, sumUnread(data));

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_UNREAD_COUNT]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.getTotalUnreadMessagesCount()
        .then(({ data }) => {
          commit(SET_UNREAD_COUNT, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [OPEN_CONVERSATION]({ commit, dispatch }, conversationId) {
    return new Promise((resolve, reject) => {
      commit(PURGE_CONVERSATION);
      commit(SET_CONVERSATION_ID, conversationId);

      if (conversationId === PLACEHOLDER_ID) {
        resolve();
      } else {
        dispatch(GET_MESSAGES)
          .then(() => {
            resolve();
            dispatch(POLL_MESSAGES);
            dispatch(SCHEDULE_MARK_AS_READ).catch(error => {
              logError(error);
            });
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  [OPEN_PLACEHOLDER_CONVERSATION](
    { commit },
    { type, participant_ids, participants, object_id } = {}
  ) {
    return new Promise(resolve => {
      commit(PURGE_CONVERSATION);
      commit(SET_CONVERSATION_ID, PLACEHOLDER_ID);
      commit(SET_PLACEHOLDER_CONVERSATION, {
        type,
        participant_ids,
        participants,
        object_id
      });

      resolve();
    });
  },
  [SCHEDULE_MARK_AS_READ](
    { state, getters, commit, dispatch },
    delay = READ_AFTER_MS
  ) {
    const conversationId = getters.currentConversationId;

    return new Promise((resolve, reject) => {
      const previousReadTimeout = state.current_conversation.readTimeout;
      if (previousReadTimeout) {
        clearTimeout(previousReadTimeout);
      }

      const readTimeout = setTimeout(() => {
        ApiService.readConversation(conversationId)
          .then(() => {
            // to keep partial unread indicators always up-to-date
            dispatch(GET_CONVERSATIONS);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      }, delay);
      commit(SET_READ_TIMEOUT, readTimeout);
    });
  },
  [GET_MESSAGES](
    { commit, getters },
    { after, before, poll, timeout, cancelToken } = {}
  ) {
    if (getters.currentConversationId === PLACEHOLDER_ID) {
      return new Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const currentConversationId = getters.currentConversationId;

      ApiService.getConversationMessages(
        currentConversationId,
        after,
        before,
        poll,
        timeout,
        cancelToken
      )
        .then(({ data }) => {
          commit(MERGE_MESSAGES, data);
          resolve();
        })
        .catch(errors => {
          // do not save server error in following situations:

          // 1. we cancelled the request (when purging current conversation)
          if (axios.isCancel(errors)) {
            reject(REASON_CANCEL);
            return;
          }

          // 2. polling is running and timeout error occured (this situation is
          // not considered to be error situation because of long-polling algorithm
          // - we will just send a new request when timeout exceeded)
          if (poll && errors.code && errors.code === 'ECONNABORTED') {
            reject();
            return;
          }

          reject(REASON_UNEXPECTED);
        });
    });
  },
  [POLL_MESSAGES]({ getters, commit, dispatch }, timeout) {
    // save cancel function to store so we can use it when purging
    // a curent conversation
    // https://github.com/axios/axios#cancellation
    const cancelToken = new axios.CancelToken(cancel => {
      // an executor function receives a cancel function as a parameter
      commit(SET_POLLING_CANCEL_TOKEN, cancel);
    });

    // our API requires 'after' parameter for polling to work
    let after;
    const lastMessage = getters.currentConversationMessages.slice(-1)[0];
    if (lastMessage) {
      after = lastMessage.created_at;
    } else {
      after = new Date().toISOString();
    }

    let rejectReason;

    // long-polling
    dispatch(GET_MESSAGES, {
      after,
      before: undefined,
      poll: true,
      timeout,
      cancelToken
    })
      .then(messages => {
        if (messages && messages.length) {
          dispatch(SCHEDULE_MARK_AS_READ).catch(error => {
            // probably no need to display this problem to a user
            // since it won't cause much harm, but let's not swallow it
            logError(error);
          });
        }
      })
      .catch(thrownError => {
        rejectReason = thrownError;
      })
      .then(() => {
        if (rejectReason === REASON_UNEXPECTED) {
          commit(SET_GLOBAL_ERROR, {
            error: rejectReason,
            clientMessage: rejectReason
          });
          return new Promise(resolve => {
            setTimeout(resolve, 30000);
          });
        } else {
          commit(PURGE_GLOBAL_ERROR, null, { root: true });
        }
      })
      .then(() => {
        if (rejectReason !== REASON_CANCEL && getters.currentConversationId) {
          dispatch(POLL_MESSAGES, timeout);
        }
      });
  },
  [CREATE_CONVERSATION]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.createConversation(payload).then(response => {
        dispatch(GET_CONVERSATIONS)
          .then(() => {
            resolve(response.headers['x-conversation-id']);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  },
  async [SEND_MESSAGE]({ state, getters, commit, dispatch }, message) {
    if (state.current_conversation.id === PLACEHOLDER_ID) {
      try {
        const placeholder = state.placeholder_conversation;
        const createdConversationId = await dispatch(CREATE_CONVERSATION, {
          type: placeholder.type,
          participant_ids: placeholder.participantIds,
          object_id: placeholder.object_id
        });
        await dispatch(OPEN_CONVERSATION, createdConversationId);

        commit(PURGE_PLACEHOLDER_CONVERSATION);
      } catch (error) {
        return new Promise.reject(error);
      }
    }

    return new Promise((resolve, reject) => {
      const currentConversationId = getters.currentConversationId;

      ApiService.sendConversationMessage(currentConversationId, message)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
