<template>
  <div :class="$style.wrapper">
    <SideWidget>
      <template #name>Test Your Knowledge</template>
      <template #content>
        <template v-if="state === STATES.initial">
          <InlineLoader />
        </template>
        <template v-else-if="state === STATES.empty">
          <Card
            :class="{
              [$style.widgetCard]: true
            }"
            :isDisabled="true"
            :isEmpty="true"
          />
        </template>
        <template v-else-if="state === STATES.list">
          <Card
            v-for="(item, index) in quizzes"
            :key="index"
            :class="[$style.widgetCard]"
            @card_click="onCardClick(item)"
            :data-cy="`card-flexible-${item.slug}`"
          >
            <template #content>
              <p class="text-semibold" v-line-clamp:3="1.2" :title="item.name">
                {{ item.name }}
              </p>
              <p class="paragraph-small">
                Multiple choice
              </p>
            </template>
          </Card>
        </template>
        <template v-else>
          <Debug
            :print="{
              quizzes: quizzes
            }"
          />
        </template>
      </template>
    </SideWidget>
    <ModalNew
      :showModal="showModal"
      v-transfer-dom
      @close="onModalClose"
      @before_destroy="onModalClose"
    >
      <template v-if="quizId">
        <Quiz :quizId="quizId" @close="onModalClose" :currentTime="null" />
      </template>
      <template v-else>
        <Notice :type="'error'" :dismissable="false">
          <template #icon>
            <i class="icon icon-exclamation-triangle" />
          </template>
          <p class="text-semibold">Assessment {{ quizId }} not found</p>
        </Notice>
      </template>
    </ModalNew>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import {
  GET_ASSESSMENTS,
  GET_SCHEDULED_ASSIGNMENT
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR, RESET_STATE } from '@/store/mutations.type';
import { ASSESSMENT_TYPES, ASSESSMENT_ORIGINS } from '@/config/assessments';
import Debug from '@/components/common/Debug/Debug';
import SideWidget from '@/components/common/SideWidget/SideWidget';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import Quiz from '@/views/Assessments/RepeatableMultiChoiceQuiz/Quiz';
import Notice from '@/components/notices/Notice/Notice';
import Card from '@/components/common/Card/Card';

export default {
  components: {
    Debug,
    SideWidget,
    InlineLoader,
    Card,
    Quiz,
    Notice
  },
  data() {
    return {
      quizzes: [],
      quizId: undefined,
      showModal: false,
      state: 'initial'
    };
  },
  computed: {
    ...mapGetters(['currentUserProfile'])
  },
  watch: {
    quizzes: {
      handler(newValue, oldValue) {
        if (Array.isArray(newValue)) {
          this.state =
            newValue.length === 0 ? this.STATES.empty : this.STATES.list;
        } else {
          this.state = undefined;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('quiz', ['quizzesCurrent']),
    ...mapMutations('quiz', [RESET_STATE]),
    async getQuizzes() {
      try {
        this.quizzes = await this.quizzesCurrent();
      } catch (error) {
        this.state = this.STATES.empty;
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    onCardClick(item) {
      if (!item) return;

      this.quizId = item.quiz_id;
      this.showModal = true;
    },
    onModalClose() {
      this.showModal = false;
      this.RESET_STATE();
      this.quizId = undefined;
    }
  },
  beforeCreate() {
    this.STATES = {
      initial: 'initial',
      empty: 'empty',
      list: 'list'
    };
  },
  created() {
    this.systemLanguage =
      this.currentUserProfile && this.currentUserProfile.system_language
        ? this.currentUserProfile.system_language
        : 'en';

    this.getQuizzes();
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetAssessments.scss';
</style>
