<template>
  <KeyboardControlled
    :listLength="items.length"
    selectedEvent="selected"
    focusedEvent="focused"
    @selected="onKeyboardSelected"
    @focused="onKeyboardFocused"
  >
    <!-- eslint-disable -->
    <template v-slot="{ selectedIndex }">
      <template v-if="isSearching || isTyping">
        <div>Searching {{ searchTerm }}...</div>
      </template>
      <template v-else-if="items.length">
        <template v-for="(group, groupIndex) in groups">
          <span
            v-if="
              groups.length > 1 && getGroupItems(indexedItems, group).length
            "
            class="items-list-title"
          >
            {{ group.title }}
          </span>

          <ul
            v-if="getGroupItems(indexedItems, group).length"
            class="items-list-wrapper"
          >
            <li
              v-for="(item, i) in getGroupItems(indexedItems, group)"
              :class="{ [$style.active]: item.globalIndex === selectedIndex }"
            >
              <a @click="selectRelay(item)" :key="i">
                <template v-if="group.code === SUGGESTION_TYPES.user">
                  <Avatar :clickable="false" :user="item" size="25px" />&nbsp;
                  <DoNotTrack>
                    <HighlightMatch
                      :text="group.titleFn(item)"
                      :regExp="searchRegExp"
                    />
                  </DoNotTrack>
                </template>
                <template v-else-if="group.code === SUGGESTION_TYPES.cohort">
                  <HighlightMatch
                    :text="group.titleFn(item)"
                    :regExp="searchRegExp"
                  />
                </template>
              </a>
            </li>
          </ul>
        </template>
      </template>
      <template v-else>
        No results for this search
      </template>
    </template>
  </KeyboardControlled>
</template>

<script>
import KeyboardControlled from '@/components/common/KeyboardControlled/KeyboardControlled';
import HighlightMatch from '../HighlightMatch';
import Avatar from '@/components/common/User/Avatar';

import { SUGGESTION_GROUPS, SUGGESTION_TYPES } from '@/config/common';

export default {
  components: {
    KeyboardControlled,
    HighlightMatch,
    Avatar
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    groups: {
      type: Array,
      default() {
        return [];
      }
    },
    searchRegExp: {
      type: RegExp
    },
    searchTerm: {
      type: String
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    isTyping: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    indexedItems() {
      let indexedItems = [];

      this.groups.map(group => {
        this.getGroupItems(this.items, group).map(item =>
          indexedItems.push(item)
        );
      });

      return indexedItems.map((item, index) => {
        return { ...item, ...{ globalIndex: index } };
      });
    }
  },
  data() {
    return {
      selectedItemIndex: undefined
    };
  },
  created() {
    this.SUGGESTION_GROUPS = SUGGESTION_GROUPS;
    this.SUGGESTION_TYPES = SUGGESTION_TYPES;
  },
  methods: {
    onKeyboardFocused(selectedIndex) {
      if (selectedIndex < 0 || selectedIndex === undefined) return;

      this.selectedItemIndex = selectedIndex;
    },
    onKeyboardSelected(selectedIndex) {
      if (selectedIndex < 0 || selectedIndex === undefined) return;

      this.selectedItemIndex = selectedIndex;
      this.selectRelay(this.indexedItems[selectedIndex]);
    },
    selectRelay(selectedObject) {
      if (!selectedObject) return;

      this.$emit('select', {
        code: selectedObject.item_type,
        id: selectedObject[`${selectedObject.item_type}_id`]
      });
    },
    getGroupItems(items, group) {
      return items.filter(item => group.filterFn(item));
    }
  }
};
</script>

<style module lang="scss">
@import './ItemSelect.scss';
</style>
