<template>
  <div :class="$style.modules" data-cy="modules_nav">
    <div
      v-for="(moduleObject, index) in modulesFiltered"
      :key="index"
      :class="{
        [$style.module]: true,
        [$style.active]: isActive(index, moduleObject),
        [$style.inactive]: !isActive(index, moduleObject),
        // [$style.disabled]: hasUnitsDisabled(moduleObject),
        [$style.running]:
          moduleObject.module_version_id === runningModuleVersionId,
        [$style.selected]: moduleObject.module_version_id === routeParamValue
      }"
    >
      <slot
        v-bind:moduleObject="moduleObject"
        v-bind:isActive="isActive(index, moduleObject)"
      >
        <template v-if="isActive(index, moduleObject)">
          <router-link
            :to="{
              name: 'schedule-module-version-detail',
              params: {
                moduleVersionId: moduleObject.module_version_id
              },
              query: $route.query
            }"
          >
            {{ moduleObject.name }}
          </router-link>
        </template>
        <template v-else>
          {{ moduleObject.name }}
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { GET_MODULES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  name: 'ModulesNavigation',
  props: {
    modules: {
      type: Array,
      default: () => []
    },
    runningModuleVersionId: {
      type: [String, Number],
      default: undefined
    },
    onlyRunnnig: {
      type: Boolean,
      default: false
    },
    onlyRunningOrFuture: {
      type: Boolean,
      default: false
    },
    checkForDisabledUnits: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapGetters([
      'asCohortId',
      'asUserId',
      'isViewingAsCohort',
      'isViewingAsUser'
    ]),
    modulesReversed() {
      return [...this.modules].reverse();
    },
    modulesFiltered() {
      if (this.onlyRunnnig === true) {
        const found = Array.isArray(this.modulesReversed)
          ? this.modulesReversed.filter(
              mod => mod.module_version_id === this.runningModuleVersionId
            )
          : [];

        return found;
      }

      if (this.onlyRunningOrFuture === true) {
        const found = Array.isArray(this.modulesReversed)
          ? this.modulesReversed.filter(mod =>
              moment().isSameOrBefore(moment(mod.end_date))
            )
          : [];

        return found;
      }

      return this.modulesReversed;
    },
    runningModule() {
      if (this.runningModuleVersionId) {
        const found = this.modulesFiltered.filter(
          mod =>
            mod &&
            mod.hasOwnProperty('module_version_id') &&
            mod.module_version_id === this.runningModuleVersionId
        );
        return found.length === 1 ? found[0] : undefined;
      }

      return undefined;
    },
    routeParamValue() {
      const found = parseInt(this.$route.params.moduleVersionId);

      if (Number.isNaN(found)) return undefined;

      return found;
    }
  },
  methods: {
    isActive(index, moduleObject) {
      if (this.checkForDisabledUnits) {
        return !this.hasUnitsDisabled(moduleObject);
      }

      return true;
    },
    hasUnitsDisabled(moduleObject) {
      if (
        moduleObject &&
        moduleObject.hasOwnProperty('disable_units') &&
        moduleObject.disable_units === true
      ) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style lang="scss" module>
@import './ModulesNavigation.scss';
</style>
