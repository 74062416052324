<template>
  <div class="box-shadow-white" :class="$style.wrapper">
    <template v-if="team && team.slug">
      <div :class="$style.background">
        <img
          v-if="team && team.slug && teamAvatarUrl(team.slug)"
          :src="teamAvatarUrl(team.slug)"
          alt=""
        />
        <img v-else src="~@/assets/images/bg/TeamHeaderDefault.jpg" />
        <!-- <span><i class="icon icon-upload"/> Upload Banner</span> -->
      </div>

      <div :class="$style.avatars">
        <AvatarGroup :users="teamMembers(team.slug)" />
      </div>

      <div :class="$style.info">
        <div data-ref="about" :class="$style.about">
          <FormInputAutoSave
            data-ref="name"
            v-model="name"
            placeholder="Team name ..."
            :action="updateProfile()"
          />
          <p class="text-light-grey">{{ membersCount }}</p>
          <!-- <span><i class="icon icon-map-marker" /> 9 countries</span> -->
        </div>

        <div data-ref="description" :class="$style.description">
          <FormTextareaAutoSave
            :showCounter="true"
            :maxCount="200"
            v-model="description"
            :rows="3"
            :action="updateProfile()"
            placeholder="We are ..."
          />
          <p class="paragraph-small">
            Introduce your team.
          </p>
        </div>
      </div>
      <router-link
        :to="{
          name: 'team-profile',
          params: { teamSlug: currentUserTeamSlug }
        }"
      >
        <FormButton :ghost="true" :class="$style.teamProfileButton"
          >Go back to Profile</FormButton
        >
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_TEAM_PROFILE } from '@/store/actions.type';
import { ADD_TEAM_PROFILE_SETTINGS } from '@/store/mutations.type';
import AvatarGroup from '@/components/common/AvatarGroup/AvatarGroup';

export default {
  components: {
    AvatarGroup
  },
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateProfile() {
      return () => {
        return this.$store
          .dispatch(UPDATE_TEAM_PROFILE, {
            teamSlug: this.currentUserTeamSlug,
            profileData: {
              description: this.description,
              name: this.name
            }
          })
          .catch(error => {
            void error;
          });
      };
    }
  },
  computed: {
    ...mapGetters([
      'currentUserTeamSlug',
      'teamProfileSettings',
      'teamMembersCount',
      'teamMembers',
      'domainCountries',
      'teamAvatarUrl'
    ]),
    membersCount() {
      const count = this.teamMembersCount(this.team.slug);
      return `${count} members`;
    },
    description: {
      get() {
        return this.teamProfileSettings(this.team.slug).description;
      },
      set(value) {
        this.$store.commit(ADD_TEAM_PROFILE_SETTINGS, {
          description: value
        });
      }
    },
    validateName() {
      const re = new RegExp(/([eE]rror)/);
      return !re.test(this.name);
    },
    name: {
      get() {
        return this.teamProfileSettings(this.team.slug).name;
      },
      set(value) {
        this.$store.commit(ADD_TEAM_PROFILE_SETTINGS, {
          name: value
        });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './TopWidgetTeamSettings.scss';
</style>
