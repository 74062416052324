<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.disabled]: isDisabled,
      [$style.isEmpty]: isEmpty
    }"
    @click="onCardClick"
  >
    <span :class="$style.icon">
      <slot name="image" :emitEvent="emitEvent">
        <img
          v-svg-inline
          :src="require(`@/assets/images/icons/self_assessment.svg`)"
        />
      </slot>
    </span>

    <span :class="$style.content">
      <slot name="loader" />
      <slot name="content" :emitEvent="emitEvent"> </slot>

      <h5 class="noAssessment" v-if="isEmpty">
        No self-assessment to display.
      </h5>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isEmpty: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCardClick() {
      this.$emit('card_click');
    },
    onImageClick() {
      this.$emit('image_click');
    },
    emitEvent(mouseEvent, params) {
      if (!params || !params.eventName) {
        throw new Error(`
Custom event name not provided!
Usage example:
<Card @my_custom_event="handleMyCustomEvent">
  <template #content="{emitEvent}">
    <p class="text-semibold paragraph-large"@click="emitEvent($event, { eventName: 'my_custom_event' })">
  </template>
</Card>

Read more at https://www.smashingmagazine.com/2019/07/using-slots-vue-js/`);
      } else {
        this.$emit(params.eventName);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './Card.scss';
</style>
