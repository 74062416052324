<template>
  <span v-if="calendarUrl && clipboardApiSupported === true">
    <input :value="calendarUrl" type="hidden" />

    <template v-if="copied">
      <a @click="copied = !copied" class="button-text color-success">
        <i class="icon icon-check"></i>
        Link Copied!
      </a>
    </template>
    <template v-else>
      <a @click="copyToClipboard" class="button-text">
        <i class="icon icon-clone"></i>
        Copy Calendar Link
      </a>
    </template>

    <a :href="INSTRUCTIONS_PAGE_URL" target="_blank" class="button-text">
      <i class="icon icon-info-circle"></i> See Instructions
    </a>
  </span>
  <span v-else-if="calendarUrl && clipboardApiSupported === false">
    Calendar Link: {{ calendarUrl }}

    <a :href="INSTRUCTIONS_PAGE_URL" target="_blank" class="button-text">
      <i class="icon icon-info-circle"></i> See Instructions
    </a>
  </span>
  <span v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CALENDAR_URL } from '@/store/actions.type';
import copy from 'clipboard-copy';
import { logError } from '@/helpers/errors';

const INSTRUCTIONS_PAGE_URL = 'https://info.edu.edu.mt/subscribe-to-schedule/';

export default {
  name: 'CalendarLink',
  data() {
    return {
      clipboardApiSupported: true,
      copied: false
    };
  },
  computed: {
    ...mapGetters(['calendarUrl'])
  },
  methods: {
    ...mapActions([GET_CALENDAR_URL]),
    copyToClipboard() {
      let inputEl = this.$el.querySelector('input[type=hidden]');
      copy(inputEl.value)
        .then(() => {
          this.copied = true;
        })
        .catch(error => {
          logError(error);
          this.copied = false;
        });
    }
  },
  async created() {
    this.INSTRUCTIONS_PAGE_URL = INSTRUCTIONS_PAGE_URL;

    try {
      await this.GET_CALENDAR_URL();
    } catch (error) {
      logError(error);
    }
  }
};
</script>
