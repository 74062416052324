<template>
  <div
    :class="{
      ['document-card']: true,
      ['disabled']: isDisabled,
      ['empty']: isEmpty
    }"
  >
    <slot v-if="!isEmpty" :emitEvent="emitEvent">
      <InlineLoader />
    </slot>
    <slot v-else :emitEvent="emitEvent"> </slot>
  </div>
</template>

<script>
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

export default {
  name: 'DocumentCard',
  components: {
    InlineLoader
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isEmpty: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitEvent(mouseEvent, params) {
      if (!params || !params.eventName) {
        throw new Error(`
Custom event name not provided!
Usage example:
<Card @my_custom_event="handleMyCustomEvent">
  <template #content="{emitEvent}">
    <p class="text-semibold"@click="emitEvent($event, { eventName: 'my_custom_event' })">
  </template>
</Card>

Read more at https://www.smashingmagazine.com/2019/07/using-slots-vue-js/`);
      } else {
        this.$emit(params.eventName, mouseEvent);
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/css/generated-variables.scss';

.document-card {
  border: 3px solid $primaryColor;
  border-radius: 20px;
  padding: 18px;
  // margin: 7px;
  width: 210px;
  height: 270px;

  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.document-card.disabled {
  cursor: not-allowed;

  & > * {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.document-card.empty {
  border: 3px dashed $border-color;
  background-color: $background-gray;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}
</style>
