<template>
  <div data-cy="pdf_resource_viewer">
    <template v-if="resource && resource.type === 'pdf'">
      <iframe
        :src="setPathPDF(resource)"
        width="100%"
        height="100%"
        style="height: 99vh;"
      />
    </template>
    <template v-else>
      <slot name="empty">No file to display.</slot>
    </template>
  </div>
</template>

<script>
import { hasErrorStatus } from '@/helpers/errors';
import { GET_RESOURCE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    resourceSlug: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      resource: undefined
    };
  },
  created() {
    this.fetchResource();
  },
  watch: {
    foundResourceSlug() {
      this.fetchResource();
    }
  },
  computed: {
    foundResourceSlug() {
      if (this.resourceSlug) return this.resourceSlug;

      if (this.$route.params.resourceSlug)
        return this.$route.params.resourceSlug;

      return undefined;
    }
  },
  methods: {
    setPathPDF(resource) {
      const pdfViewerPath = `/viewer/web/viewer.html?file=`;

      if (resource.file && !resource.file.path.includes('http')) {
        return `${pdfViewerPath}/${resource.file.path}`;
      }

      return `${pdfViewerPath}${resource.file.path}`;
    },
    fetchResource() {
      if (!this.foundResourceSlug) return;

      this.$store
        .dispatch(GET_RESOURCE, this.foundResourceSlug)
        .then(resource => {
          this.resource = resource;
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    }
  }
};
</script>
