<template>
  <div
    class="box-shadow-white"
    :class="$style.wrapper"
    role="region"
    aria-label="Team information"
  >
    <template v-if="team">
      <div :class="$style.background">
        <img
          v-if="team && team.slug && teamAvatarUrl(team.slug)"
          :src="teamAvatarUrl(team.slug)"
          alt=""
        />
        <img v-else src="~@/assets/images/bg/TeamHeaderDefault.jpg" />
      </div>
      <div :class="$style.avatars">
        <AvatarGroup :users="teamMembers(team.slug)" />
      </div>
      <div :class="$style.info">
        <div :class="$style.about">
          <h2 class="header-lowercase">
            {{ team.name }}
            <template v-if="isMember">
              <router-link :to="{ name: 'team-profile-settings' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  xmlns:v="https://vecta.io/nano"
                >
                  <path
                    d="M93.5 60.8l-5.7-3.3.2-1.3c.3-2 .5-4.1.5-6.2s-.2-4.2-.5-6.2l-.2-1.3 5.6-3.3c1.5-.8 2.7-2.1 3.4-3.7.8-2 .6-4.2-.4-6.1l-5.1-8.8c-1.9-3.3-6.2-4.5-9.6-2.6l-6 3.5-1-.8c-3.2-2.7-6.8-4.7-10.8-6.2l-1.2-.4V7.5c0-4-3.3-7.3-7.3-7.3h-9.6c-4 0-7.3 3.3-7.3 7.3v6.6l-1.2.4c-3.9 1.5-7.5 3.6-10.8 6.2l-1 .8-6-3.5a6.99 6.99 0 0 0-9.5 2.6l-5 8.9c-1.1 1.9-1.3 4.1-.5 6.1.6 1.6 1.8 2.9 3.2 3.6l5.8 3.3-.2 1.3c-.3 2-.5 4.1-.5 6.2s.2 4.2.5 6.2l.2 1.3-5.7 3.3c-1.5.8-2.7 2.1-3.4 3.7-.8 2-.6 4.2.4 6.1l5.1 8.9c1 1.6 2.5 2.7 4.2 3.1 1.8.5 3.7.3 5.4-.6l6-3.5 1 .8c3.2 2.6 6.8 4.7 10.8 6.2l1.2.4v6.6c0 4 3.3 7.3 7.3 7.3h9.6c4 0 7.3-3.3 7.3-7.3v-6.6l1.2-.4c3.9-1.5 7.5-3.6 10.7-6.2l1-.8 6.1 3.5c1.6.9 3.5 1.1 5.2.7 1.8-.5 3.4-1.7 4.3-3.3l5.1-8.8c1.1-1.9 1.3-4.1.4-6.1-.6-1.6-1.7-2.9-3.2-3.7zM50.7 67a16.99 16.99 0 0 1-17-17c0-9.4 7.7-17 17-17a16.99 16.99 0 1 1 0 34z"
                  />
                </svg>
              </router-link>
            </template>
          </h2>
          <p class="text-semibold text-light-grey">{{ membersCount }}</p>
          <!-- <p class="text-light-grey"><i class="icon icon-map-marker" /> 9 countries</p> -->
        </div>

        <div :class="$style.description">
          {{ team.description }}
        </div>

        <!--<div class="badge-gray">
          growth area
        </div> -->
      </div>

      <!-- <div :class="$style.follow">
        <a href="#">follow</a>
      </div> -->
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AvatarGroup from '@/components/common/AvatarGroup/AvatarGroup';

export default {
  components: {
    AvatarGroup
  },
  props: {
    team: {
      type: Object,
      default: () => {}
    },
    user_id: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(['teamMembersCount', 'teamMembers', 'teamAvatarUrl']),
    isMember() {
      if (!this.user_id || !this.team || !this.team.members) {
        return false;
      }

      const found = this.team.members.map(member => member.user_id);

      return found.includes(this.user_id);
    },
    membersCount() {
      const count = this.teamMembersCount(this.team.slug);
      return `${count} members`;
    }
  }
};
</script>

<style module lang="scss">
@import './TopWidgetTeamProfile.scss';
</style>
