<template>
  <div
    data-cy="minischedule-container"
    :class="{
      ['type-onboarding']: container.type === CYCLE_TYPES.onboarding,
      ['type-study']: container.type === CYCLE_TYPES.study,
      ['type-clinical-rotation']: container.type === CYCLE_TYPES.cr,
      ['type-holiday']: container.type === CYCLE_TYPES.holiday,
      ['type-flex']: container.type === CYCLE_TYPES.flex,
      ['type-undefined']: container.type === undefined,
      ['empty']: isEmpty === true
    }"
    :style="{
      gridColumnEnd: `span ${slots}`
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { CYCLE_TYPES } from '@/config/schedule';

export default {
  name: 'MiniScheduleContainer',
  props: {
    slots: {
      type: Number,
      default: NaN
    },
    days: {
      type: Number,
      default: NaN
    },
    isEmpty: {
      type: Boolean,
      default: undefined
    },
    container: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  }
};
</script>
