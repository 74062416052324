<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.disabled]: disabled || isLoading
    }"
  >
    <ckeditor
      v-model="content"
      :config="mergedConfig"
      :editor="editor"
      :disabled="disabled || isLoading ? true : null"
      @ready="onEditorReady"
      @input="handleInput"
    ></ckeditor>

    <div :class="$style.counter">
      <span class="counter" />
    </div>

    <CircleLoader
      v-if="isLoading"
      :radius="60"
      :strokeWidth="6"
      :class="$style.loader"
    >
      <span>{{ loadingText }}</span>
    </CircleLoader>
  </div>
</template>

<script>
// NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';

import CircleLoader from '@/components/common/CircleLoader/CircleLoader';

import { DEFAULT_CONFIG } from '@/config/editor';

export default {
  components: {
    CircleLoader
  },
  props: {
    value: {
      type: String
    },
    config: {
      type: Object, // Will be merged with DEFAULT_CONFIG and used
      default: () => DEFAULT_CONFIG
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: ''
    }
  },
  computed: {
    content: {
      get() {
        // Editor's parent component sets a v-model and
        // its's contents is passed to Editor inside "value" prop
        // We're just passing it down to ckeditor component here
        return this.value;
      },
      set(content) {
        // When v-model is changed inside the ckeditor component
        // this setter is called and it just relays the new content
        // the parent of Editor component and let's it decide
        // what should be done with it
        this.$emit('input', content);
      }
    },
    mergedConfig() {
      let mergedConfig = { ...DEFAULT_CONFIG, ...this.config };

      if (this.placeholder) {
        mergedConfig.placeholder = this.placeholder;
      }

      return mergedConfig;
    }
  },
  data() {
    return {
      editor: ClassicEditor
    };
  },
  methods: {
    onEditorReady(editor) {
      this.setupWordCount(editor);
      this.editorInstance = editor;
    },
    setupWordCount(editor) {
      const wordCountPlugin = editor.plugins.get('WordCount');
      const wordCountWrapper = this.$el.querySelector('.counter');

      wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
    },
    handleInput(data, evt, editor) {
      void data;
      void evt;

      if (!editor) return;

      const plainTextContent = viewToPlainText(
        editor.editing.view.document.getRoot()
      );

      this.$emit('plain_text', plainTextContent);
    }
  }
};
</script>

<style lang="scss" module>
@import './Editor.scss';
</style>
