<template>
  <span
    :class="{
      ['active']: canJoin === true,
      ['inactive']: canJoin === false
    }"
  >
    <template v-if="isJoinable && canJoin">
      <NewRoomLauncher :user="user" :room="room">
        <slot :room="room">
          <h5 class="join-button uppercase">
            Join!
          </h5>
        </slot>
      </NewRoomLauncher>
    </template>
    <template v-else>
      <slot name="not-joinable" :room="room"> </slot>
    </template>
  </span>
</template>

<script>
import moment from 'moment';

import { NEWROW_ROLES } from '@/config/users';

import NewRoomLauncher from '@/components/common/NewRoomLauncher/NewRoomLauncher';

export default {
  name: 'NewRoomLauncherBeforeStart',
  components: { NewRoomLauncher },
  props: {
    nowISO: {
      type: String,
      default: moment().toISOString()
    },
    minutesBeforeSessionStart: {
      type: Number,
      default: 5
    },
    user: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    }
  },
  computed: {
    room() {
      return this.getRoom(this.session);
    },
    isJoinable() {
      if (!this.user || !this.user.user_id) return false;

      const roomDetails = this.getRoom(this.session);

      return roomDetails && roomDetails.newrow_context_id ? true : false;
    },
    canJoin() {
      const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

      const m_event_start = this.isJoinable
        ? moment(this.session.start_at).subtract(
            this.minutesBeforeSessionStart,
            'minutes'
          )
        : moment(this.session.start_at);
      const m_event_end = moment(this.session.end_at);

      if (!m_event_start.isValid()) return false;
      if (!m_event_end.isValid()) return false;

      return moment(this.nowISO).isBetween(
        m_event_start,
        m_event_end,
        null,
        INCLUSIVITY
      );
    },
    isUserTeachingStaff() {
      return !!(this.user && this.user.teaching_staff);
    }
  },
  methods: {
    getRoom(session) {
      if (!session) return {};

      let newrow_context_id = undefined;
      let newrow_room_name = undefined;

      if (Object.keys(session).includes('newrow_context')) {
        // Processing /api/v1/schedule/upcoming response
        newrow_context_id =
          session.newrow_context && session.newrow_context.context_id;
        newrow_room_name =
          session.newrow_context && session.newrow_context.room_name;
      } else {
        // Processing /api/v1/schedule/weeks/current
        newrow_context_id = session.nc && session.nc.context_id;
        newrow_room_name = session.nc && session.nc.room_name;
      }

      if (!newrow_context_id) return {};
      if (!newrow_room_name) return {};

      let role = NEWROW_ROLES.student;

      if (this.isUserTeachingStaff === true) {
        role = NEWROW_ROLES.instructor;
      }

      return {
        newrow_context_id,
        newrow_room_name,
        role
      };
    }
  }
};
</script>
