<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template slot="pageContent">
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>
            Create new post
          </h6>
          <div :class="$style.buttons">
            <router-link to="/admin" :class="$style.homeButton">
              <i class="icon icon-home"
            /></router-link>
          </div>
        </div>

        <!-- // CREATE BLOG // -->
        <div v-if="!selectingCohorts" class="box-shadow-white">
          <div
            :class="{ [$style.contentCreate]: true, [$style.firstStep]: true }"
          >
            <div :class="$style.progressBar">
              <div
                :class="{
                  [$style.dot]: true,
                  [$style.active]: !selectingCohorts
                }"
              />
              <div
                :class="{
                  [$style.dot]: true,
                  [$style.active]: selectingCohorts
                }"
              />
            </div>
            <div :class="$style.progressBarText">
              <p :class="{ [$style.active]: !selectingCohorts }">Create Blog</p>
              <p :class="{ [$style.active]: selectingCohorts }">
                Select Cohorts
              </p>
            </div>
            <FormLabel v-if="tags">
              <span>Select a Category</span>
              <FilterSelect
                :submenuVisible="tagSubmenuVisible"
                @submenu_visibility="selectTag"
                :class="$style.tags"
              >
                <template #title>
                  <span v-if="selectedTag">{{ selectedTag.label }}</span>
                  <span v-else>{{ 'Select a Category' }}</span>
                </template>
                <template #items>
                  <fieldset
                    v-for="tag in tags"
                    :value="tag.label"
                    :key="tag.label"
                    @click="handleTagChange(tag)"
                  >
                    <FormLabel>
                      {{ tag.label }}
                    </FormLabel>
                  </fieldset>
                </template>
              </FilterSelect>
            </FormLabel>
            <FormLabel>
              <span>Title</span>
              <FormInput
                :placeholder="'Blog post title ...'"
                v-model="newTitle"
              />
            </FormLabel>
            <FormLabel>
              <span>Short Description</span>
              <FormTextarea
                :placeholder="'Blog post description ...'"
                v-model="newExcerpt"
              />
            </FormLabel>

            <span :class="$style.label" class="text-semibold">Content</span>
            <Editor v-model="newContent" :class="$style.editor" />

            <FormLabel :class="$style.labelNextToInput" class="pt-1">
              <span class="pr-1">Reactions and discussion allowed</span>
              <FormCheckbox v-model="feedbackEnabled" />
            </FormLabel>

            <FormFieldErrors
              :class="$style.error"
              :errors="[{ message: errorMessages }]"
            />

            <div :class="$style.confirmButtons">
              <FormButton
                :standard="true"
                @click="createNewPost"
                :class="$style.greenButton"
                >Create draft</FormButton
              >
              <FormButton :ghost="true" @click="discardChanges"
                >Cancel</FormButton
              >
            </div>
          </div>
        </div>

        <!-- // SELECT COHORTS // -->
        <div class="box-shadow-white" v-else>
          <div
            :class="{ [$style.contentCreate]: true, [$style.secondStep]: true }"
          >
            <div :class="$style.progressBar">
              <div
                :class="{
                  [$style.dot]: true,
                  [$style.active]: !selectingCohorts
                }"
              />
              <div
                :class="{
                  [$style.dot]: true,
                  [$style.active]: selectingCohorts
                }"
              />
            </div>
            <div :class="$style.progressBarText">
              <p :class="{ [$style.active]: !selectingCohorts }">Create Blog</p>
              <p :class="{ [$style.active]: selectingCohorts }">
                Select Cohorts
              </p>
            </div>
            <h6 class="text-primary" v-line-clamp:1="1">
              <span class="text-basic">Select cohorts for </span>
              <span class="text-primary" v-html="postDetail.title" />
            </h6>
            <AdminCohortPicker
              :currentCohortsFromBE="postDetail.cohorts"
              :postId="postDetail.post_id"
            />

            <div :class="$style.confirmButtons">
              <FormButton
                :standard="true"
                @click="goToDraft"
                :class="$style.greenButton"
                >Done</FormButton
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Layout from '@/views/Layout/Layout';
import Editor from '@/components/common/Editor/Editor';
import AdminCohortPicker from '@/components/admin/AdminCohortPicker/AdminCohortPicker';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

import { CREATE_ADMIN_BLOG, GET_BLOG_POST_TAGS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    Editor,
    AdminCohortPicker,
    FilterSelect
  },
  data() {
    return {
      display404: false,
      selectedTag: null,
      newTitle: null,
      newExcerpt: null,
      newContent: null,
      feedbackEnabled: true,
      errorMessages: '',
      leaveRouter: false,
      selectingCohorts: false,
      postDetail: [],
      tags: [],
      tagSubmenuVisible: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.$store
        .dispatch(GET_BLOG_POST_TAGS, {
          category: 'blog_posts'
        })
        .then(tags => {
          this.tags = tags;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    // prevent accidentaly leaving unsaved changes
    if (!this.leaveRouter) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      );
      answer ? next() : next(false);
    } else {
      next();
    }
  },
  methods: {
    ...mapActions([CREATE_ADMIN_BLOG]),
    checkInputs() {
      this.errorMessages = '';

      if (
        !this.selectedTag ||
        !this.newTitle ||
        !this.newTitle.trim() ||
        !this.newExcerpt ||
        !this.newExcerpt.trim() ||
        !this.newContent ||
        !this.newContent.trim()
      ) {
        this.errorMessages = 'Please fill out all the inputs.';
      } else {
        return true;
      }

      return false;
    },
    createNewPost() {
      this.leaveRouter = true;

      if (this.checkInputs()) {
        this.CREATE_ADMIN_BLOG({
          title: this.newTitle,
          excerpt: this.newExcerpt,
          content: this.newContent,
          feedback_enabled: this.feedbackEnabled,
          tag_id: this.selectedTag.tag_id
        })
          .then(response => {
            this.postDetail = response;
            this.selectingCohorts = true;
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    discardChanges() {
      this.leaveRouter = true;

      if (this.leaveRouter) {
        this.$router.push({
          name: 'admin-blog-published'
        });
      }
    },
    goToDraft() {
      this.$router.push({
        name: 'admin-blog-detail',
        params: {
          postId: this.postDetail.post_id
        }
      });
    },
    handleTagChange(tag) {
      this.selectedTag = tag;
      this.tagSubmenuVisible = false;
    },
    selectTag(newSubmenuState) {
      this.tagSubmenuVisible = newSubmenuState;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminCreateBlog.scss';
@import '../AdminTables.scss';
</style>
