// contains data for currently active library search

import ApiService from '@/api/api.service';
import { toServerFormat } from '@/helpers/datetime';

import {
  GET_LEARNING_UNITS,
  GET_LEARNING_OBJECTIVES,
  GET_LEARNING_CATEGORIES
} from '@/store/actions.type';

const ITEMS_PER_LOAD = 20;

export const state = {
  phase: [],
  type: [],
  logicalType: [],
  unit: [],
  objective: [],
  text: [],
  resources: [],
  resourcesCount: undefined,
  resourcesPerLoad: ITEMS_PER_LOAD,
  offset: 0,
  since: null, // Marks the time when user performed the search
  definedLearningUnits: [],
  definedLearningObjectives: [],
  definedLearningCategories: []
};

export const getters = {
  resources(state) {
    return state.resources;
  },
  resourcesCount(state) {
    return state.resourcesCount;
  },
  resourcesPerLoad(state) {
    return state.resourcesPerLoad;
  },
  offset(state) {
    return state.offset;
  },
  since(state) {
    return state.since;
  },
  stateContainsFilters(state) {
    return (
      state.phase.length > 0 ||
      state.type.length > 0 ||
      state.logicalType.length > 0 ||
      state.unit.length > 0 ||
      state.objective.length > 0 ||
      state.text.length > 0
    );
  },
  filters(state) {
    return {
      phase: state.phase,
      type: state.type,
      logicalType: state.logicalType,
      unit: state.unit,
      objective: state.objective,
      text: state.text
    };
  },
  loadParams(state, getters) {
    return {
      ...getters.filters,
      ...{
        limit: state.resourcesPerLoad,
        timestamp: toServerFormat(state.since)
      }
    };
  },
  loadMoreParams(state, getters) {
    return {
      ...getters.filters,
      ...{
        offset: state.resources.length,
        limit: state.resourcesPerLoad,
        timestamp: toServerFormat(state.since)
      }
    };
  },
  definedLearningUnits(state) {
    return state.definedLearningUnits;
  },
  definedLearningObjectives(state) {
    return state.definedLearningObjectives;
  },
  definedLearningCategories(state) {
    return state.definedLearningCategories;
  }
};

export const actions = {
  [GET_LEARNING_UNITS](context, moduleVersionId = 'current') {
    return new Promise((resolve, reject) => {
      ApiService.getLearningUnits(moduleVersionId)
        .then(({ data }) => {
          context.commit('setDefinedLearningUnits', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_LEARNING_OBJECTIVES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getLearningObjectives()
        .then(({ data }) => {
          context.commit('setDefinedLearningObjectives', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_LEARNING_CATEGORIES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getLearningCategories()
        .then(({ data }) => {
          context.commit('setDefinedLearningCategories', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  setResources(state, resources) {
    state.resources = resources;
  },
  setResourcesCount(state, resourcesCount) {
    state.resourcesCount = resourcesCount;
  },
  setStateOffset(state, filters) {
    if (!filters) return;

    state.offset = parseInt(filters.offset) || 0;
  },
  setStateFilters(state, filters) {
    const { phase, type, logicalType, unit, objective, text } = filters;

    state.phase = phase;
    state.type = type;
    state.logicalType = logicalType;
    state.unit = unit;
    state.objective = objective;
    state.text = text;
  },
  setStateInitialFilters(state) {
    state.phase = [];
    state.type = [];
    state.logicalType = [];
    state.unit = [];
    state.objective = [];
    state.text = [];
  },
  setSince(state, since) {
    state.since = since;
  },
  setDefinedLearningUnits(state, units) {
    state.definedLearningUnits = units;
  },
  setDefinedLearningObjectives(state, objectives) {
    state.definedLearningObjectives = objectives;
  },
  setDefinedLearningCategories(state, categories) {
    state.definedLearningCategories = categories;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
