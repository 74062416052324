<template>
  <SideWidget
    :class="{
      'item-locked': locked,
      [$style.wrapper]: true,
      [$style.locked]: locked
    }"
    :title="false"
    class="box-shadow-white p-2"
  >
    <template #content>
      <div :class="$style.content">
        <i class="icon" v-if="locked" />
        <div :class="$style.mobile" v-if="createdDate">
          <p>Joined EDU in:</p>
          <p>{{ createdDate }}</p>
        </div>

        <div :class="$style.mobile">
          <div>
            <p>Cohort</p>
            <router-link to="/contacts/students" v-if="cohortName">{{
              cohortName
            }}</router-link>
            <p class="placeholder" v-else>
              No Cohort assigned.
            </p>
          </div>

          <div>
            <p>Team</p>
            <router-link
              :to="{ name: 'team-profile', params: { teamSlug: teamSlug } }"
              v-if="teamName"
              >{{ teamName }}
            </router-link>
            <p class="placeholder" v-else>
              No Team assigned.
            </p>
          </div>
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import moment from 'moment';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import { SEARCH_USER_PROFILES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget
  },
  data() {
    return {
      cohortProfile: {} // User profile with cohort memebership data is only returned by the user search endpoint
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    userFirstName() {
      this.fetchCohortData();
    }
  },
  computed: {
    userId() {
      if (!this.user) return undefined;
      if (!this.user.user_id) return undefined;

      return parseInt(this.user.user_id);
    },
    userFirstName() {
      if (!this.userId) return undefined;

      return this.user.first_name;
    },
    team() {
      if (this.user && this.user.team) {
        return this.user.team;
      } else if (this.user && this.user.teams && this.user.teams[0]) {
        return this.user.teams[0];
      }

      return undefined;
    },
    teamSlug() {
      if (!this.team) return undefined;

      return this.team.slug;
    },
    teamName() {
      if (!this.team) return undefined;

      return this.team.name;
    },
    createdDate() {
      if (!this.user) return '';
      if (!this.user.created_at) return '';

      const m_created = moment(this.user.created_at);

      if (m_created.isValid() && m_created.isAfter('2018-01-01T00:00:00Z')) {
        return m_created.format('MMMM YYYY');
      }

      return '';
    },
    cohortName() {
      if (this.cohortProfile && this.cohortProfile.cohorts) {
        return this.cohortProfile.cohorts[0].name;
      }

      return undefined;
    }
  },
  created() {
    this.fetchCohortData();
  },
  methods: {
    async fetchCohortData() {
      if (!this.userFirstName) return;

      try {
        const cohortProfiles = await this.$store.dispatch(
          SEARCH_USER_PROFILES,
          {
            inactive: true,
            name: this.userFirstName
          }
        );

        const found = cohortProfiles.find(u => u.user_id === this.userId);

        this.cohortProfile = found ? found : {};
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './SideWidgetUserProfileTeam.scss';
</style>
