<template>
  <Messages
    :conversation="currentConversation"
    :currentUserId="currentUserId"
    :messages="currentConversationMessages"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import Messages from './Messages';

export default {
  name: 'ConversationExisting',
  components: {
    Messages
  },
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapGetters('chats', [
      'currentConversation',
      'currentConversationMessages'
    ])
  }
};
</script>
