<template>
  <div :class="$style.wrapper" v-html="excerpt" />
</template>

<script>
export default {
  props: {
    excerpt: {
      type: String
    }
  }
};
</script>

<style module lang="scss">
@import './BlogPostExcerpt.scss';
</style>
