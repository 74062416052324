<template>
  <span :class="classes" class="form-input">
    <input
      v-bind="$attrs"
      type="number"
      :value="value"
      v-on="eventListeners"
    /> </span
></template>

<script>
import debounce from 'lodash.debounce';
import {
  STATES,
  DEBOUNCE_INPUT_DELAY,
  ACTION_COMPLETE_TIMEOUT,
  CLEAR_SUCCESS_AFTER,
  CLEAR_ERROR_AFTER
} from '@/config/forms';

export default {
  inheritAttrs: false,
  props: {
    value: {},
    action: {
      type: Function, // Parent should pass Function that returns a Function that returns a Promise
      required: false
    },
    validation: {
      type: Function, // Parent should pass Function that returns a Boolean
      required: false
    },
    stepping: {
      type: Boolean,
      required: false,
      default: false
    },
    delayLoaderDisplay: {
      type: Number,
      default: ACTION_COMPLETE_TIMEOUT
    },
    clearSuccessAfter: {
      type: Number,
      default: CLEAR_SUCCESS_AFTER
    },
    clearErrorAfter: {
      type: Number,
      default: CLEAR_ERROR_AFTER
    }
  },
  data() {
    return {
      actionTimeoutId: undefined,
      successTimeoutId: undefined,
      errorTimeoutId: undefined,
      debouncedActionHandler: undefined
    };
  },
  computed: {
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        input: this.inputEventHandler,
        mousewheel: event => {
          // Prevent stepping of values using mouse wheel
          // Chrome browser uses `mousewheel` event
          if (!this.stepping) {
            event.preventDefault();
          }
        },
        wheel: event => {
          // Prevent stepping of values using mouse wheel
          // Firefox browser uses `wheel` event
          if (!this.stepping) {
            event.preventDefault();
          }
        },
        keydown: event => {
          // Prevent stepping of values using arrow keys
          if (!this.stepping) {
            const ArrowUpKeyCode = 38;
            const ArrowDownKeyCode = 40;

            if (
              event.keyCode === ArrowUpKeyCode ||
              event.keyCode === ArrowDownKeyCode
            ) {
              event.preventDefault();
            }
          }
        }
      });
    }
  },
  created() {
    this.debouncedActionHandler = debounce(
      this.actionHandler,
      DEBOUNCE_INPUT_DELAY
    );
  },
  methods: {
    actionHandler(newValue) {
      let validationResult = undefined;

      if (typeof this.validation !== 'function') {
        throw new Error('Validation function is required!');
      } else {
        validationResult = this.validation(newValue);
        const isBooleanResult = typeof validationResult === 'boolean';

        if (isBooleanResult) {
          this.$emit('validation_result', validationResult);
        } else {
          throw new Error('Validation must return Boolean!');
        }
      }

      if (this.action && validationResult === true) {
        this.setActionTimeout(() => {
          this.$emit('state', STATES.in_action);
        }, this.delayLoaderDisplay);

        this.action(newValue)
          .then(() => {
            this.removeActionTimeout(this.actionTimeoutId);

            this.$emit('state', STATES.success);
            this.$emit('success'); // Allow for direct use of @success at parent

            if (this.clearSuccessAfter && !this.successTimeoutId) {
              this.setSuccessTimeout(() => {
                this.$emit('state', STATES.idle);
              }, this.clearSuccessAfter);
            }
          })
          .catch(error => {
            this.removeActionTimeout(this.actionTimeoutId);

            this.$emit('state', STATES.error);
            this.$emit('error', error); // Allow for direct use of @error at parent

            if (this.clearErrorAfter && !this.errorTimeoutId) {
              this.setErrorTimeout(() => {
                this.$emit('state', STATES.idle);
              }, this.clearErrorAfter);
            }
          });
      } else {
        this.$emit('state', STATES.idle);
      }
    },
    inputEventHandler(event) {
      this.removeAllTimeouts();

      this.$emit('state', STATES.typing);
      this.$emit('input', parseFloat(event.target.value));
      this.debouncedActionHandler(parseFloat(event.target.value));
    },
    setActionTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.actionTimeoutId = window.setTimeout(callback, delay);
    },
    setSuccessTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.successTimeoutId = window.setTimeout(callback, delay);
    },
    setErrorTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.errorTimeoutId = window.setTimeout(callback, delay);
    },
    removeActionTimeout(actionTimeoutId) {
      if (!actionTimeoutId) return;

      window.clearTimeout(actionTimeoutId);
      this.actionTimeoutId = undefined;
    },
    removeSuccessTimeout(successTimeoutId) {
      if (!successTimeoutId) return;

      window.clearTimeout(successTimeoutId);
      this.successTimeoutId = undefined;
    },
    removeErrorTimeout(errorTimeoutId) {
      if (!errorTimeoutId) return;

      window.clearTimeout(errorTimeoutId);
      this.errorTimeoutId = undefined;
    },
    removeAllTimeouts() {
      this.removeActionTimeout(this.actionTimeoutId);
      this.removeSuccessTimeout(this.successTimeoutId);
      this.removeErrorTimeout(this.errorTimeoutId);
    }
  },
  beforeDestroy() {
    this.removeAllTimeouts();
  }
};
</script>
