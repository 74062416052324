<template>
  <div :class="$style.wrapper" class="theme-border-bottom">
    <TopicPostAuthor v-if="post" :user="post.author" :class="$style.author" />

    <div v-if="topic" :class="$style.content">
      <h2 class="header-lowercase" v-html="topic.subject" />

      <div :class="$style.stats" class="paragraph-small">
        <time :datetime="topic.created_at">{{ createdAt }}</time>
      </div>

      <div id="grow" :class="{ [$style.gradient]: gradient }">
        <div
          :class="$style.mainContent"
          v-if="post"
          v-html="post.content"
          class="foldContent"
        />
      </div>
      <a v-if="showFoldBtn" :class="$style.btnToggle" @click="foldPost()">{{
        foldText
      }}</a>
      <button
        data-ref="button"
        type="button"
        :class="$style.btnFollow"
        class="theme-bg"
        @click="onFollowClick"
      >
        <span class="icon" :class="followIconClass" />
        {{ followText }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import TopicPostAuthor from '@/components/discussions/TopicPostAuthor/TopicPostAuthor';

const HEIGHT = 500;

export default {
  components: {
    TopicPostAuthor
  },
  data() {
    return {
      gradient: true,
      foldText: 'See more…',
      showFoldBtn: false
    };
  },
  props: {
    topic: {
      type: Object
    },
    post: {
      type: Object
    }
  },
  computed: {
    createdAt() {
      return (
        this.topic &&
        moment(this.topic.created_at).format('DD MMM, YYYY | HH:mm')
      );
    },
    isSubscribed() {
      return this.topic && this.topic.is_subscribed ? true : false;
    },
    followIconClass() {
      return this.isSubscribed ? 'icon-bookmark' : 'icon-bookmark-o';
    },
    followText() {
      return this.isSubscribed ? 'Unfollow Discussion' : 'Follow Discussion';
    }
  },
  methods: {
    foldPost() {
      var growDiv = this.$el.querySelector('#grow');

      if (growDiv && growDiv.clientHeight > HEIGHT) {
        growDiv.style.height = HEIGHT + 'px';
        this.foldText = 'See more…';
        this.gradient = true;
      }

      if (growDiv && growDiv.clientHeight <= HEIGHT) {
        var wrapper = this.$el.querySelector('.foldContent');
        growDiv.style.height = wrapper.clientHeight + 'px';
        this.foldText = 'See less…';
        this.gradient = false;
      }
    },
    onFollowClick() {
      if (this.isSubscribed) {
        this.$emit('unsubscribe');
      } else {
        this.$emit('subscribe');
      }
    }
  },
  mounted() {
    var growDiv = this.$el.querySelector('#grow');

    // check if content is less than HEIGHTpx and then generate button
    if (growDiv && growDiv.clientHeight <= HEIGHT) {
      this.showFoldBtn = false;
      growDiv.style.height = 'auto';
      this.gradient = false;
    } else if (growDiv && growDiv.clientHeight > HEIGHT) {
      this.showFoldBtn = true;
      growDiv.style.height = HEIGHT + 'px';
    }
  }
};
</script>

<style lang="scss" module>
@import './TopicIntroductionPost.scss';
</style>
