<template>
  <div :class="$style.wrapper">
    <template v-if="resourceGroup">
      <h6 class="main-title">
        {{ resourceGroup.name }}
      </h6>
      <div :class="$style.content">
        <splide :options="options">
          <splide-slide
            v-for="resource in resourceGroup.resources"
            :key="resource.slug"
          >
            <ResourceCard :resource="resource" :class="$style.card" />
          </splide-slide>
        </splide>
      </div>
    </template>
  </div>
</template>

<script>
import ResourceCard from '@/components/resources/ResourceCard/ResourceCard';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  components: {
    ResourceCard,
    SplideSlide,
    Splide
  },
  data() {
    return {
      options: {
        pagination: false,
        perPage: 4,
        padding: 40,
        gap: 20,
        trimSpace: false,
        breakpoints: {
          1100: {
            perPage: 3
          },
          850: {
            perPage: 2
          },
          767: {
            destroy: true
          }
        }
      }
    };
  },
  props: {
    resourceGroup: {
      type: Object,
      required: true
    }
  }
};
</script>

<style module lang="scss">
@import './ResourcesSlider.scss';
</style>
