<template>
  <div :class="$style.wrapper">
    <div
      class="border overflow-visible"
      :class="$style.contentWrapper"
      :style="
        `border-color: ${getProviderColor(
          resource
        )}; box-shadow: 4px 4px 0px 3px ${getProviderColor(resource)}`
      "
    >
      <router-link :class="$style.link" :target="target" :to="linkTo">
        <div :class="$style.iconsTop">
          <div v-if="getLibraryIcon(resource)" :class="$style.typeIcon">
            <img
              :src="
                require(`@/assets/images/icons/library-icons/${getLibraryIcon(
                  resource
                )}`)
              "
            />
          </div>

          <div
            v-if="getProviderLogo(resource)"
            :class="{
              [$style.providerLogo]: true,
              [$style.biodigital]: getProviderLogo(resource) == 'biodigital.jpg'
            }"
          >
            <img
              :src="
                require(`@/assets/images/logos/${getProviderLogo(resource)}`)
              "
            />
          </div>
        </div>
        <div :class="$style.text">
          <p class="semibold paragraph-large" v-line-clamp:3="1.7">
            {{ resource.title }}
          </p>
          <!-- <p> no BE data </p> -->
        </div>

        <div :class="$style.iconsBottom">
          <span :class="$style.modules">
            <!-- no BE data -->
          </span>
          <div :class="$style.bookmark" v-on:click.prevent>
            <ResourceBookmark :resourceSlug="resource.slug" />
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getLibraryIcon,
  getProviderLogo,
  getProviderColor
} from '@/helpers/resources';
import ResourceBookmark from '@/components/resources/ResourceBookmark/ResourceBookmark';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    resource: {
      type: Object,
      required: true
    },
    target: {
      type: String,
      default: '_self'
    },
    to: {
      type: Object,
      default: () => {
        return {
          name: 'library-resource'
        };
      }
    }
  },
  components: {
    ResourceBookmark
  },
  computed: {
    ...mapGetters(['hasResourceBookmark']),
    tags() {
      return this.resource.tags.map(tag => tag.label).join(', ');
    },
    linkTo() {
      return {
        ...{ params: { resourceSlug: this.resource.slug } },
        ...this.to
      };
    }
  },
  created() {
    this.getLibraryIcon = getLibraryIcon;
    this.getProviderLogo = getProviderLogo;
    this.getProviderColor = getProviderColor;
  }
};
</script>

<style lang="scss" module>
@import './ResourceCard.scss';
</style>
