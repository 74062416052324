<template>
  <div class="file-upload">
    <template v-if="step === UPLOAD_STEP.waiting">
      <div class="waiting" data-cy="upload_is_waiting">
        <slot name="waiting">
          <FileUploadArea
            :maxSizeInMb="maxSizeInMb"
            :allowedMimeTypes="allowedMimeTypes"
            :errors="errors"
            @inserted="onInserted"
            @removed="onRemoved"
            @validation="onValidation"
          >
            <img src="~@/assets/images/icons/Upload.png" />
            <p class="paragraph-large text-bold">
              <slot>upload your files</slot>
            </p>
            <p class="paragraph-small">
              Drag and drop your files or select them from your computer.<br />
              Allowed file types are {{ humanizeMimeTypes(allowedMimeTypes) }}
            </p>

            <a class="btn standard link">upload</a>
          </FileUploadArea>
        </slot>
      </div>
    </template>

    <template v-if="step === UPLOAD_STEP.uploading">
      <div class="uploading" data-cy="upload_is_uploading">
        <slot name="uploading">
          <CircleLoader
            :radius="80"
            :strokeWidth="5"
            backgroundColor="#fff"
            :color="primaryColor"
          >
            Uploading...
          </CircleLoader>
        </slot>
      </div>
    </template>

    <template v-if="step === UPLOAD_STEP.processing">
      <div class="processing" data-cy="upload_is_processing">
        <slot name="processing">
          <CircleLoader
            :radius="80"
            :strokeWidth="5"
            backgroundColor="#fff"
            :color="primaryColor"
          >
            Processing...
          </CircleLoader>
        </slot>
      </div>
    </template>

    <template v-if="step === UPLOAD_STEP.error">
      <div class="error" data-cy="upload_is_error">
        <slot name="error">
          <FileUploadArea
            :maxSizeInMb="maxSizeInMb"
            :allowedMimeTypes="allowedMimeTypes"
            :errors="errors"
            @inserted="onInserted"
            @removed="onRemoved"
            @validation="onValidation"
          >
            <img
              src="~@/assets/images/icons/UploadFailed.png"
              alt="File upload failed!"
            />
            <p class="paragraph-large text-bold">File upload failed!</p>
            <p class="paragraph-small" v-if="errors.length === 1">
              {{ errors[0] }}
            </p>

            <ol v-else>
              <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
            </ol>

            <a class="btn standard link">retry</a>
          </FileUploadArea>
        </slot>
      </div>
    </template>

    <template v-if="step === UPLOAD_STEP.success">
      <div class="success" data-cy="upload_is_success">
        <slot name="success">
          <img
            src="~@/assets/images/icons/space-ship.png"
            alt="File uploaded successfully"
          />

          <p class="paragraph-large text-bold">File uploaded successfully</p>

          Your file has been uploaded and is stored with us safely.
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import CircleLoader from '@/components/common/CircleLoader/CircleLoader';
import FileUploadArea from '@/components/common/FileUploadArea/FileUploadArea';

import { THEMES, DEFAULT_THEME } from '@/config/themes';
import { UPLOAD_STEP } from '@/config/files';
import { humanizeMimeTypes } from '@/helpers/files';

export default {
  components: {
    CircleLoader,
    FileUploadArea
  },
  props: {
    step: {
      type: String,
      validator: value => Object.values(UPLOAD_STEP).includes(value),
      required: true,
      default: UPLOAD_STEP.waiting
    },
    maxSizeInMb: {
      type: Number
    },
    allowedMimeTypes: {
      type: Array
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onInserted(payload) {
      this.$emit('inserted', payload);
    },
    onRemoved() {
      this.$emit('removed');
    },
    onValidation(payload) {
      this.$emit('validation', payload);
    }
  },
  created() {
    this.UPLOAD_STEP = UPLOAD_STEP;
    this.humanizeMimeTypes = humanizeMimeTypes;
    this.primaryColor = THEMES[process.env.THEME || DEFAULT_THEME].primaryColor;
  }
};
</script>

<style lang="scss" module>
@import './FileUploadBase.scss';
</style>
