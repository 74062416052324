var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lesson"},[(_vm.isViewingAsStudent || _vm.isViewingAsCohort)?[_c('p',{class:_vm.$style.duration},[_vm._v(_vm._s(_vm.duration(_vm.lesson)))]),_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:2",value:(1.35),expression:"1.35",arg:"2"}],class:_vm.$style.name},[_c('router-link',{attrs:{"to":{
          name: 'assignment-detail',
          params: {
            assignmentId: _vm.lesson.parent_assignment_id || _vm.lesson.assignment_id,
            scheduledAssignmentId: _vm.lesson.scheduled_assignment_id
          }
        }}},[_vm._v(" "+_vm._s(_vm.lesson.name)+" ")])],1),_c('p',{class:_vm.$style.type},[_vm._v(_vm._s(_vm.label(_vm.sessionType(_vm.lesson))))]),(_vm.lesson && _vm.lesson.learning_units && _vm.lesson.learning_units.length)?_c('ListUnits',{class:_vm.$style.learningUnit,attrs:{"learningUnits":_vm.lesson.learning_units}}):_vm._e(),_c('ul',{class:_vm.$style.facilitators,attrs:{"data-cy":"facilitators"}},_vm._l((_vm.facilitators),function(facilitator,index){return _c('li',{key:index},[(facilitator && facilitator.user_id)?_c('router-link',{staticClass:"semibold",attrs:{"to":{
            name: 'user-profile',
            params: {
              userId: facilitator.user_id
            }
          }}},[_c('UserName',{attrs:{"user":facilitator}})],1):_vm._e()],1)}),0),_c('NewRoomLauncherBeforeStart',{staticClass:"launcher",attrs:{"user":_vm.currentUserProfile,"session":_vm.lesson,"nowISO":_vm.nowISO},scopedSlots:_vm._u([{key:"not-joinable",fn:function(){
          var _obj;
return [(_vm.showFeedbackLink)?_c('a',{staticClass:"btn ghost",class:_vm.$style.feedback,attrs:{"href":_vm.lesson.session_feedback_url,"target":"_blank"},on:{"click":function($event){return _vm.buttonClickHandler(true)}}},[_vm._v(" Give Feedback ")]):_c('router-link',{class:( _obj = {}, _obj[_vm.$style.assignmentDetail] = true, _obj['btn'] = true, _obj ),attrs:{"data-cy":"assignment-detail-link","to":{
            name: 'assignment-detail',
            params: {
              assignmentId:
                _vm.lesson.parent_assignment_id || _vm.lesson.assignment_id,
              scheduledAssignmentId: _vm.lesson.scheduled_assignment_id
            }
          }},nativeOn:{"click":function($event){return _vm.buttonClickHandler(false)}}},[_vm._v(" View Details ")])]},proxy:true},{key:"default",fn:function(){return undefined},proxy:true}],null,false,1976488038)})]:_vm._e(),(_vm.isViewingAsTeachingStaff)?[_c('p',{class:_vm.$style.duration},[_vm._v(_vm._s(_vm.duration(_vm.lesson)))]),_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:2",value:(1.35),expression:"1.35",arg:"2"}],class:_vm.$style.name},[_c('router-link',{attrs:{"to":{
          name: 'assignment-detail',
          params: {
            assignmentId: _vm.lesson.parent_assignment_id || _vm.lesson.assignment_id,
            scheduledAssignmentId: _vm.lesson.scheduled_assignment_id
          }
        }}},[_vm._v(" "+_vm._s(_vm.lesson.name)+" ")])],1),_c('div',{class:_vm.$style.groups},[_vm._v(" "+_vm._s(_vm.getModuleName() ? ((_vm.getModuleName()) + ", ") : '')+" "),_vm._l((Object.values(_vm.userGroupsByType)),function(groupType,index){return [_c('span',{key:("group-type-" + index)},_vm._l((groupType),function(userGroup,index){return _c('span',{key:("group-" + index),class:_vm.$style.group},[_vm._v(" "+_vm._s(userGroup.object_name)+" ")])}),0)]})],2),_c('NewRoomLauncherBeforeStart',{staticClass:"launcher",attrs:{"user":_vm.currentUserProfile,"session":_vm.lesson,"nowISO":_vm.nowISO,"minutesBeforeSessionStart":60},scopedSlots:_vm._u([{key:"not-joinable",fn:function(ref){
        var _obj;

        var room = ref.room;return [(room && room.newrow_room_name)?_c('div',{class:( _obj = {}, _obj[_vm.$style.classRoom] = true, _obj[_vm.$style.joinable] = false, _obj )},[_vm._v(" NewRow "+_vm._s(room.newrow_room_name)+" ")]):_vm._e()]}},{key:"default",fn:function(ref){
        var _obj;

        var room = ref.room;return [_c('div',{class:( _obj = {}, _obj[_vm.$style.classRoom] = true, _obj[_vm.$style.joinable] = true, _obj )},[_vm._v(" NewRow "+_vm._s(room.newrow_room_name)+" "),_c('button',{class:_vm.$style.inlineBtn},[_vm._v("Open")])])]}}],null,false,2784367045)}),_c('router-link',{class:( _obj = {}, _obj[_vm.$style.assignmentDetail] = true, _obj['btn'] = true, _obj['standard'] = _vm.hasLessonEnded ? true : false, _obj ),style:({
        color: _vm.hasLessonEnded ? 'white' : ''
      }),attrs:{"data-cy":"assignment-detail-link","to":{
        name: 'assignment-detail',
        params: {
          assignmentId: _vm.lesson.parent_assignment_id || _vm.lesson.assignment_id,
          scheduledAssignmentId: _vm.lesson.scheduled_assignment_id
        }
      }}},[_vm._v(" "+_vm._s(_vm.hasLessonEnded ? 'Enter Grading' : 'View Details')+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }