<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapperHeader">
        <h2>Admin Page</h2>
      </div>

      <div :class="$style.wrapper">
        <h6>Cohorts Overview</h6>
        <AdminOverviewTable :class="$style.calendarSection" />
        <AdminDashboardStatistics :class="$style.section" />
        <FormButton :standard="true"
          ><router-link to="admin/specs" class="btn standard"
            >Business Logic</router-link
          ></FormButton
        >
        <AdminDashboardTables :class="$style.section" />
        <AdminDashboardOperations :class="$style.section" />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminDashboardStatistics from '@/views/Admin/AdminDashboard/AdminDashboardStatistics';
import AdminDashboardTables from '@/views/Admin/AdminDashboard/AdminDashboardTables';
import AdminDashboardOperations from '@/views/Admin/AdminDashboard/AdminDashboardOperations';
import AdminOverviewTable from '@/components/admin/AdminOverviewTable/AdminOverviewTable';

export default {
  components: {
    Layout,
    NotFound,
    AdminDashboardStatistics,
    AdminDashboardTables,
    AdminDashboardOperations,
    AdminOverviewTable
  },
  data() {
    return {
      display404: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminDashboard.scss';
</style>
