<template>
  <span class="user">
    <template v-if="withAvatarLink">
      <router-link
        :to="{
          name: 'user-profile',
          params: {
            userId: foundUserProfileId
          }
        }"
        class="user-avatar"
      >
        <Avatar :user="foundUserProfile" :size="size" />
      </router-link>
    </template>

    <template v-else>
      <Avatar :user="foundUserProfile" :size="size" class="user-avatar" />
    </template>

    <template v-if="withNameLink">
      <router-link
        :to="{
          name: 'user-profile',
          params: {
            userId: foundUserProfileId
          }
        }"
        class="user-name"
      >
        <UserName :user="foundUserProfile" />
      </router-link>
    </template>

    <template v-else>
      <UserName :user="foundUserProfile" class="user-name" />
    </template>
  </span>
</template>

<script>
import Avatar from '@/components/common/User/Avatar';
import { mapGetters, mapActions } from 'vuex';
import { MAYBE_GET_USER_PROFILE } from '@/store/actions.type';

export default {
  name: 'User',
  components: {
    Avatar
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String,
      required: false,
      default: '25px'
    },
    withAvatarLink: {
      type: Boolean,
      default: true
    },
    withNameLink: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: NaN
    }
  },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapGetters(['userProfile']),
    foundUserProfile() {
      if (this.user) {
        return this.user;
      }

      if (!Number.isNaN(this.userId)) {
        const foundUserProfile = this.userProfile(this.userId);

        if (foundUserProfile && foundUserProfile.hasOwnProperty('user_id'))
          return foundUserProfile;
      }

      return undefined;
    },
    foundUserProfileId() {
      if (
        this.foundUserProfile &&
        this.foundUserProfile.hasOwnProperty('user_id')
      ) {
        return this.foundUserProfile.user_id;
      }

      return undefined;
    }
  },
  methods: {
    ...mapActions([MAYBE_GET_USER_PROFILE]),
    getUserProfile() {
      if (typeof this.user === 'string') return;

      if (this.user && this.user.hasOwnProperty('user_id')) return;

      this.MAYBE_GET_USER_PROFILE(this.userId);
    }
  }
};
</script>

<style lang="scss">
@import './User.scss';
</style>
