<template>
  <div :class="$style.wrapper">
    <div :class="$style.search">
      <div :class="$style.inputWrapper">
        <div :class="$style.visibleInputWrapper">
          <FormInput
            data-ref="search"
            :large="true"
            v-model="searchTerm"
            type="text"
            :placeholder="displayPlaceholder"
          />
          <i :class="$style.searchIcon" class="icon icon-search" />
        </div>
        <div :class="$style.usersBox">
          <ul :class="$style.users">
            <li
              v-for="(participant, index) in participantProfiles"
              :key="index"
              @click="removeParticipant(participant)"
            >
              <UserName :preTitles="true" :user="participant" />
              <i class="icon icon-close" />
            </li>
          </ul>
        </div>
        <ul :class="$style.contacts">
          <li
            v-for="(participant, index) in selectedProfiles"
            :key="`selected-${index}`"
            :class="$style.selected"
            @click="removeParticipant(participant)"
          >
            <span :class="$style.user">
              <Avatar :user="participant" size="40px" :class="$style.avatar" />
              <UserName :preTitles="true" :user="participant" />
            </span>
            <img
              width="18"
              height="18"
              v-svg-inline
              :class="$style.check"
              :src="require(`@/assets/images/icons/check.svg`)"
            />
          </li>
          <li
            v-for="(participant, index) in availableProfiles"
            :key="`available-${index}`"
            @click="addParticipant(participant.user_id)"
          >
            <span :class="$style.user">
              <Avatar :user="participant" size="40px" :class="$style.avatar" />
              <UserName :user="participant" />
            </span>
          </li>
        </ul>
        <div :class="{ [$style.loader]: true, [$style.on]: isSearching }" />
      </div>
    </div>
  </div>
</template>

<script>
import { CONVERSATION_TYPES, PARTICIPANTS_LIMIT } from '@/config/conversations';

import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import {
  SEARCH_USER_PROFILES,
  GET_USER_PROFILES,
  GET_USER_PROFILE
} from '@/store/actions.type';
import {
  SET_GLOBAL_ERROR,
  SET_READY_TO_START,
  SET_USERS_SEARCH_RESULTS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT
} from '@/store/mutations.type';

import Avatar from '@/components/common/User/Avatar';

const DEBOUNCE_WAIT_MS = 500;

export default {
  name: 'ConversationNewDesktop',
  components: {
    Avatar
  },
  data() {
    return {
      searchTerm: '',
      isSearching: false,
      searchRegExp: undefined,
      showAvatarSelect: false
    };
  },
  computed: {
    ...mapGetters(['userProfile', 'userProfiles', 'currentUserId']),
    ...mapGetters('chats', ['usersSearchResults', 'selectedParticipantIds']),
    displayPlaceholder() {
      if (this.participantProfiles.length) {
        return '';
      }

      return 'Start a conversation with ...';
    },
    showDropdown() {
      return (
        !this.isSearching && (this.showAvatarSelect || this.searchTerm !== '')
      );
    },
    filterUsers() {
      if (this.searchTerm === '')
        return this.userProfiles.filter(
          user => user.user_id !== this.currentUserId
        );

      if (!Array.isArray(this.usersSearchResults)) return [];

      const usersExclCurrent = this.usersSearchResults.filter(
        user => user.user_id !== this.currentUserId
      );

      return usersExclCurrent;
    },
    participantProfiles() {
      if (!this.userProfiles) return [];

      return this.selectedParticipantIds.map(id =>
        this.userProfiles.find(user => id == user.user_id)
      );
    },
    selectedProfiles() {
      return this.filterUsers.filter(p =>
        this.selectedParticipantIds.includes(p.user_id)
      );
    },
    availableProfiles() {
      return this.filterUsers.filter(
        p => !this.selectedParticipantIds.includes(p.user_id)
      );
    }
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm) {
        this.searchRegExp = undefined;
        this.SET_USERS_SEARCH_RESULTS([]);
      }

      this.searchRegExp = new RegExp(newSearchTerm, 'ig');
      this.searchUsers(newSearchTerm);
    },
    participantProfiles(newValue) {
      if (newValue.length > 0) {
        this.SET_READY_TO_START(true);
      } else {
        this.SET_READY_TO_START(false);
      }
    }
  },
  methods: {
    ...mapMutations('chats', [
      SET_READY_TO_START,
      SET_USERS_SEARCH_RESULTS,
      ADD_PARTICIPANT,
      REMOVE_PARTICIPANT
    ]),
    addParticipant(userId) {
      const found = this.selectedParticipantIds.includes(userId);

      if (!found && this.selectedParticipantIds.length < PARTICIPANTS_LIMIT) {
        this.ADD_PARTICIPANT(userId);

        // Fetch profiles of users that are missing in store
        this.selectedParticipantIds.map(participantId => {
          const found = this.userProfiles.find(
            user => user.user_id === participantId
          );

          if (!found) {
            this.$store.dispatch(GET_USER_PROFILE, participantId);
          }
        });
      }
    },
    removeParticipant(userProfile) {
      this.REMOVE_PARTICIPANT(userProfile.user_id);
    },
    searchUsers: debounce(function(searchTerm) {
      this.isSearching = true;
      this.$store
        .dispatch(SEARCH_USER_PROFILES, { inactive: true, name: searchTerm })
        .then(users => {
          this.SET_USERS_SEARCH_RESULTS(users);
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        })
        .then(() => {
          this.isSearching = false;
        });
    }, DEBOUNCE_WAIT_MS),
    hideDropdown() {
      this.showAvatarSelect = false;
    }
  },
  created() {
    this.$store.dispatch(GET_USER_PROFILES, { inactive: true }).catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  }
};
</script>

<style lang="scss" module>
@import './ConversationNewMobile.scss';
</style>
