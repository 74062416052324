<template>
  <div :class="$style.wrapper">
    <h6 v-if="title" class="main-title">
      <slot name="name" />
    </h6>

    <div :class="$style.content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gray: {
      default: false
    },
    title: {
      default: true
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidget.scss';
</style>
