<template>
  <header
    :class="{ [$style.wrapper]: true, [$style.collapsed]: collapsed === true }"
  >
    <ListFacilitators
      :facilitators="session.facilitators"
      :class="$style.facilitators"
    />

    <span :class="$style.sessionType">{{ sessionType(session) }}</span>
    <h5 :class="$style.sessionDuration" data-ignore="snapshot">
      {{ session | duration }}
    </h5>

    <div :class="$style.launcher">
      <a
        :href="session.session_feedback_url"
        target="_blank"
        :class="$style.feedback"
        class="btn ghost"
        v-if="showFeedbackLink"
      >
        Give Feedback
      </a>

      <NewRoomLauncherBeforeStart
        :user="currentUserProfile"
        :session="session"
        :nowISO="nowISO"
        class="launcher"
        data-cy="launcher"
      >
        <h5 :class="$style.joinAction" class="join-button uppercase">
          Join!
        </h5>
      </NewRoomLauncherBeforeStart>
    </div>
    <div :class="$style.gradingState">
      <span
        v-if="
          sessionGradingComponent !== GRADING_TYPES.TYPENULL &&
            hasEnded === true &&
            isPublished === true
        "
        class="grading-state-evaluated"
        data-cy="grading-state"
      >
        &#x25CF; EVALUATED
      </span>
      <span
        v-if="
          sessionGradingComponent !== GRADING_TYPES.TYPENULL &&
            hasEnded === true &&
            isPublished === false
        "
        class="grading-state-pending"
        data-cy="grading-state"
      >
        &#x25CF; PENDING
      </span>
    </div>

    <a @click="$emit('flip', !collapsed)" :class="$style.flipLink" class="link">
      <i class="icon icon-chevron-down" v-if="collapsed === true" />
      <i class="icon icon-chevron-up" v-if="collapsed === false" />
    </a>
  </header>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { GRADING_TYPES } from '@/config/grading';
import NewRoomLauncherBeforeStart from '@/components/common/NewRoomLauncherBeforeStart/NewRoomLauncherBeforeStart';
import ListFacilitators from './ListFacilitators';
import { getSessionGradingComponent } from '@/helpers/grading';
import { getAssignmentType } from '@/helpers/schedule';

export default {
  name: 'ContainerSessionHeader',
  components: { NewRoomLauncherBeforeStart, ListFacilitators },
  filters: {
    duration(session) {
      const m_start = moment(session.start_at);
      const m_end = moment(session.end_at);

      if (m_start.isValid() && m_end.isValid()) {
        return `${m_start.format('D MMM YYYY')}, ${m_start.format(
          'HH:mm'
        )} - ${m_end.format('HH:mm')}`;
      }

      return '';
    }
  },
  props: {
    session: {
      type: Object,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: undefined
    },
    nowISO: {
      type: String,
      required: true
    },
    isPublished: {
      type: Boolean,
      default: undefined
    },
    hasEnded: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(['currentUserProfile']),
    sessionGradingComponent() {
      return getSessionGradingComponent(this.session);
    },
    showFeedbackLink() {
      if (!this.session) return false;
      if (!this.session.session_feedback_url) return false;

      const m_now = moment(this.nowISO);
      const m_end = moment(this.session.end_at);

      if (m_now.isValid() && m_end.isValid() && m_now.isSameOrAfter(m_end)) {
        return true;
      }

      return false;
    }
  },
  created() {
    this.GRADING_TYPES = GRADING_TYPES;
  },
  methods: {
    sessionType(session) {
      return getAssignmentType(session);
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/ContainerHeader.scss';
</style>
