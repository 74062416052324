<template>
  <div :class="$style.wrapper">
    <router-link
      :to="{
        name: 'learning-unit-detail',
        params: {
          learningUnitId: `${unit.unit_id}`,
          moduleVersionId: moduleVersionId
        },
        query: {
          ...$route.query
        }
      }"
      :class="$style.icon"
    >
      <LearningUnitCategoryIcon />
    </router-link>

    <router-link
      :to="{
        name: 'learning-unit-detail',
        params: {
          learningUnitId: `${unit.unit_id}`,
          moduleVersionId: moduleVersionId
        },
        query: {
          ...$route.query
        }
      }"
      :class="$style.title"
      class="divider"
    >
      <p
        v-if="isMobileLayout"
        class="semibold"
        :title="unit.description"
        v-line-clamp:3="1.2"
      >
        {{ unit.label }}
      </p>
      <p v-else class="semibold" :title="unit.description" v-line-clamp:2="1.2">
        {{ unit.label }}
      </p>
    </router-link>

    <span v-if="!isMobileLayout">
      <ul :class="$style.sessions" v-if="sessions && sessions.length">
        <li v-for="(session, index) in sessions" :key="index">
          <router-link
            :to="{
              name: 'learning-unit-detail',
              params: {
                learningUnitId: `${unit.unit_id}`,
                moduleVersionId: moduleVersionId
              },
              query: {
                ...$route.query
              }
            }"
            :class="$style.link"
          >
            <strong>{{ session.type }}</strong> -
            {{ format(session.start_at) }}
          </router-link>
        </li>
      </ul>
      <ul :class="{ [$style.sessions]: true, [$style.empty]: true }" v-else>
        <li>
          No session scheduled
        </li>
      </ul>
    </span>
    <router-link
      v-if="!isMobileLayout"
      :to="{
        name: 'learning-unit-detail',
        params: {
          learningUnitId: `${unit.unit_id}`,
          moduleVersionId: moduleVersionId
        },
        query: {
          ...$route.query
        }
      }"
      :class="$style.objectives"
    >
      Obj: <strong>{{ unit.lo_count }}</strong>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import LearningUnitCategoryIcon from './LearningUnitCategoryIcon';

export default {
  name: 'LearningUnit',
  components: {
    LearningUnitCategoryIcon
  },
  props: {
    unit: {
      type: Object,
      default() {
        return {
          unit_id: undefined,
          category: {
            category_id: undefined
          }
        };
      }
    },
    types: {
      type: Array,
      default() {
        return [];
      }
    },
    moduleVersionId: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['isMobileLayout']),
    sessions() {
      if (!this.unit) return [];

      if (!this.unit.sessions) return [];

      return this.unit.sessions;
    }
  },
  methods: {
    onClick() {
      this.$emit('lu_click', this.unit);
    },
    format(dateISOString) {
      return moment(dateISOString).format('DD.MM.YYYY');
    }
  }
};
</script>

<style lang="scss" module>
@import './LearningUnit.scss';
</style>
