<template>
  <Layout variant="6">
    <template #pageContent>
      <h1>UpToDate integration</h1>
      <p>
        You should see a new tab or browser window opened automatically with
        UpToDate content now.
      </p>
      <p>
        In case your don't, please disable browser pop-up protection for this
        specific site (<a
          target="_blank"
          href="https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-pop-ups-and-redirects-from-a-site"
          >Instructions</a
        >
        for Chrome browser).
      </p>
      <p>&nbsp;</p>
      <UpToDateLauncher>
        <div class="join-button btn standard link">
          Try again
        </div>
      </UpToDateLauncher>
    </template>
  </Layout>
</template>

<script>
import UpToDateLauncher from '@/components/common/UpToDateLauncher/UpToDateLauncher';
import Layout from '@/views/Layout/Layout';

export default {
  components: { UpToDateLauncher, Layout }
};
</script>
