<template>
  <ul v-if="pager.pages && pager.pages.length" class="pagination">
    <li class="page-item first" :class="{ disabled: pager.currentPage === 1 }">
      <a class="page-link" @click="setPage(1)">{{ labels.first }}</a>
    </li>
    <li
      class="page-item previous"
      :class="{ disabled: pager.currentPage === 1 }"
    >
      <a class="page-link" @click="setPage(pager.currentPage - 1)">{{
        labels.previous
      }}</a>
    </li>
    <li
      v-for="page in pager.pages"
      :key="page"
      class="page-item page-number"
      :class="{ active: pager.currentPage === page }"
    >
      <a class="page-link" @click="setPage(page)">{{ page }}</a>
    </li>
    <li
      class="page-item next"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a class="page-link" @click="setPage(pager.currentPage + 1)">{{
        labels.next
      }}</a>
    </li>
    <li
      class="page-item last"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a class="page-link" @click="setPage(pager.totalPages)">{{
        labels.last
      }}</a>
    </li>
  </ul>
</template>
<script>
import { paginate } from '@/helpers/pagination';

const defaultLabels = {
  first: 'First',
  last: 'Last',
  previous: 'Previous',
  next: 'Next'
};

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPages: {
      type: Number,
      default: 10
    },
    labels: {
      type: Object,
      default: () => defaultLabels
    }
  },
  data() {
    return {
      pager: {}
    };
  },
  watch: {
    items() {
      this.setPage(this.initialPage);
    },
    pageSize() {
      this.setPage(this.initialPage);
    }
  },
  created() {
    if (!this.$listeners.changePage) {
      throw 'Missing required event listener: "changePage"';
    }

    this.setPage(this.initialPage);
  },
  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages);

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

      // update pager
      this.pager = pager;

      // prevent changing of page number to NaN
      if (Number.isNaN(pager.currentPage)) {
        return;
      }

      // emit change page event to parent component
      this.$emit('changePage', { pageOfItems, currentPage: pager.currentPage });
    }
  }
};
</script>
