<template>
  <div>
    <div class="wrapper" v-if="weeks && weeks.length">
      <p class="text-semibold">Weeks:</p>
      <div
        class="weeks"
        :style="{
          gridTemplateRows: `1fr`,
          gridTemplateColumns: `repeat(${weeks.length}, minmax(0,1fr)) auto`
        }"
      >
        <div
          v-for="(week, weekIndex) in weeks"
          :key="`week-${weekIndex}`"
          @click="setSelectedWeek(week.calendar_week_id)"
          :title="week.phase_type"
          :class="{
            ['week']: true,
            ['current']: week.calendar_week_id === currentWeekId,
            ['selected']: week.calendar_week_id === selectedWeekId,
            ['type-onboarding']: week.phase_type === CYCLE_TYPES.onboarding,
            ['type-study']: week.phase_type === CYCLE_TYPES.study,
            ['type-clinical-rotation']: week.phase_type === CYCLE_TYPES.cr,
            ['type-holiday']: week.phase_type === CYCLE_TYPES.holiday,
            ['type-flex']: week.phase_type === CYCLE_TYPES.flex
          }"
        >
          <img
            width="20"
            v-svg-inline
            v-if="
              week.phase_type === CYCLE_TYPES.onboarding &&
                week.calendar_week_id !== selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.onboarding &&
                week.calendar_week_id === selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study_selected.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.study &&
                week.calendar_week_id !== selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.study &&
                week.calendar_week_id === selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study_selected.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.flex &&
                week.calendar_week_id !== selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_flex.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.flex &&
                week.calendar_week_id === selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_flex_selected.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.cr &&
                week.calendar_week_id !== selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_cr.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.cr &&
                week.calendar_week_id === selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_cr_selected.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.holiday &&
                week.calendar_week_id !== selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study.svg')"
          />
          <img
            width="20"
            v-svg-inline
            v-else-if="
              week.phase_type === CYCLE_TYPES.holiday &&
                week.calendar_week_id === selectedWeekId
            "
            :src="require('@/assets/images/icons/nav_study_selected.svg')"
          />
        </div>
        <div
          v-if="!isMobileLayout"
          :class="{
            ['terminator']: true,
            ['type-onboarding']: getLastType(weeks) === CYCLE_TYPES.onboarding,
            ['type-study']: getLastType(weeks) === CYCLE_TYPES.study,
            ['type-clinical-rotation']: getLastType(weeks) === CYCLE_TYPES.cr,
            ['type-holiday']: getLastType(weeks) === CYCLE_TYPES.holiday,
            ['type-flex']: getLastType(weeks) === CYCLE_TYPES.flex
          }"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { CYCLE_TYPES } from '@/config/schedule';

export default {
  name: 'WeeksNavigation',
  props: {
    weeks: {
      type: Array,
      default: () => []
    },
    currentWeekId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(['isMobileLayout']),
    selectedWeekId() {
      return parseInt(this.$route.params.weekId);

      return undefined;
    }
  },
  methods: {
    setSelectedWeek(calendarWeekId) {
      if (!calendarWeekId) return;
      this.$emit('selected_week', calendarWeekId);
    },
    getLastType(weeks) {
      if (!Array.isArray(weeks)) return undefined;

      const lastWeek = weeks[weeks.length - 1];

      if (lastWeek && lastWeek.phase_type) {
        return lastWeek.phase_type;
      }

      return undefined;
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  }
};
</script>

<!-- Watch out! Following styles are NOT modules, but globals! -->
<style lang="scss">
@import './WeekDetailGlobals.scss';
@import './WeeksNavigation.scss';
@import './WeeksNavigationMobile.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>
