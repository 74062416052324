<template>
  <div
    v-if="hasRoleLabel"
    :style="{ backgroundColor: user.role.color }"
    class="badge"
  >
    {{ roleName }}
  </div>
</template>

<script>
import { ROLES } from '@/config/users';

export default {
  name: 'Badge',
  props: {
    user: {
      type: Object
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasRoleLabel() {
      if (this.user && this.user.role && this.user.role.label) {
        return true;
      }

      return false;
    },
    roleName() {
      if (this.hasRoleLabel) {
        switch (this.user.role.code) {
          case ROLES.student:
            return this.user.role.label;
            break;

          default:
            return this.user.job_title
              ? this.user.job_title
              : this.user.role.label;
        }
      }

      return '';
    }
  }
};
</script>

<style lang="scss">
@import './Badge.scss';
</style>
