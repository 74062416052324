<template>
  <div
    :class="{
      [$style.wrapper]: true,
      ['type-onboarding']: weekType === CYCLE_TYPES.onboarding,
      ['type-study']: weekType === CYCLE_TYPES.study,
      ['type-clinical-rotation']: weekType === CYCLE_TYPES.cr,
      ['type-holiday']: weekType === CYCLE_TYPES.holiday,
      ['type-flex']: weekType === CYCLE_TYPES.flex,
      ['type-undefined']: weekType === undefined
    }"
  >
    <div :class="$style.containers" data-cy="week_days">
      <div
        v-for="(day, dayIndex) in Object.keys(weekDetail)"
        :key="dayIndex"
        :class="{
          [$style.dayContainer]: true,
          ['current']: isCurrent(day),
          ['empty']: isEmpty(day),
          ['highlight']: highlightFacilitated,
          ['highlight-partial']: isFacilitatingDay(day)
        }"
        data-cy="week_day"
      >
        <div class="day-header">
          <h4 class="uppercase bold">{{ toWeekDay(day) }}</h4>
          <h4 class="normal">{{ toHeaderFormat(day) }}</h4>
        </div>
        <div>
          <Lesson
            v-for="(lesson, lessonIndex) in weekDetail[day]"
            :key="lessonIndex"
            :lesson="lesson"
            :nowISO="nowISO"
            :isActive="isActive(lesson)"
            :class="{
              ['active']: isActive(lesson),
              ['facilitated']: isCurrentUserFacilitating(lesson)
            }"
            :selectedModuleVersionId="selectedModuleVersionId"
          />

          <div v-if="isEmpty(day)" class="lesson">
            <p>No sessions today.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import Lesson from '@/views/Schedule/Lesson';

import { FALLBACK_LANGUAGE } from '@/config/locale';
import { CYCLE_TYPES } from '@/config/schedule';

export default {
  name: 'WeekDetail',
  components: {
    Lesson
  },
  props: {
    nowISO: {
      type: String,
      required: true
    },
    weekDetail: {
      type: Object,
      default: () => {}
    },
    weekType: {
      type: String,
      default: undefined
    },
    highlightFacilitated: {
      type: Boolean,
      default: undefined
    },
    selectedModuleVersionId: {
      type: Number
    }
  },
  data() {
    return {
      purgeBeforeMoment: moment().subtract(2, 'weeks')
    };
  },
  computed: {
    ...mapGetters(['currentUserProfile', 'isCurrentUserFacilitating']),
    nowDayIndex() {
      return moment(this.nowISO).isoWeekday();
    },
    nowDayArrayIndex() {
      return this.nowDayIndex - 1;
    }
  },
  methods: {
    isFacilitatingDay(dayISO) {
      if (!this.weekDetail[dayISO]) return undefined;

      const foundFacilitated = this.weekDetail[dayISO].filter(lesson => {
        return this.isCurrentUserFacilitating(lesson);
      });

      return foundFacilitated && foundFacilitated.length ? true : false;
    },
    isEmpty(dayISO) {
      if (!this.weekDetail[dayISO]) return undefined;

      return this.weekDetail[dayISO].length === 0;
    },
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    },
    isActive(lesson) {
      const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

      const m_event_start = moment(lesson.start_at);
      const m_event_end = moment(lesson.end_at);

      if (!m_event_start.isValid()) return false;
      if (!m_event_end.isValid()) return false;

      return moment(this.nowISO).isBetween(
        m_event_start,
        m_event_end,
        null,
        INCLUSIVITY
      );
    },
    toWeekDay(dayISO) {
      const m_dayStartISO = moment(dayISO).startOf('day');

      return moment(dayISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('ddd');
    },
    toHeaderFormat(periodStartISO) {
      const m_dayStartISO = moment(periodStartISO).startOf('day');

      return moment(periodStartISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('DD.MM.YYYY');
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  }
};
</script>

<!-- Watch out! Following styles are NOT modules, but globals! -->
<style lang="scss">
@import './WeekDetailGlobals.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>

<style lang="scss" module>
@import './WeekDetail.scss';
</style>
