<template>
  <transition>
    <div
      class="modal"
      data-cy="modal"
      v-if="showModal"
      @click="closeFromOutside"
    >
      <nav>
        <slot name="header">
          <i
            class="icon icon-close"
            @click="closeFromIcon"
            data-cy="modal_close"
          />
        </slot>
      </nav>
      <main>
        <slot />
      </main>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showModal: {
      default: false
    },
    closeWithClickOutside: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showModal() {
      document.querySelector('body, html').style.overflow = this.showModal
        ? 'hidden'
        : 'visible';
    }
  },
  methods: {
    closeFromOutside(pointerEvent) {
      if (this.clickOutsideToClose === false) {
        return;
      }

      if (
        pointerEvent.target.className.includes('modal') ||
        pointerEvent.target.localName === 'nav'
      ) {
        this.$emit('close');
      }
    },
    closeFromIcon() {
      this.$emit('close');
    }
  },
  beforeDestroy() {
    this.$emit('before_destroy');
  }
};
</script>

<style lang="scss">
@import './ModalNew.scss';
</style>
