import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      errors: []
    };
  },
  computed: {
    ...mapGetters(['emailRegex']),
    emailErrors() {
      return this.errors.filter(e => e.path === 'email');
    },
    validateEmail() {
      const re = new RegExp(this.emailRegex);
      return re.test(this.email);
    }
  }
};
