import FormCheckbox from './FormCheckbox/FormCheckbox';
import FormToggleAutoSave from './FormToggleAutoSave/FormToggleAutoSave';
import FormThreeWaySwitchAutoSave from './FormThreeWaySwitchAutoSave/FormThreeWaySwitchAutoSave';
import FormThreeWaySwitchReadOnly from './FormThreeWaySwitchAutoSave/FormThreeWaySwitchReadOnly';
import FormError from './FormError/FormError';
import FormErrors from './FormErrors/FormErrors';
import FormFieldErrors from './FormFieldErrors/FormFieldErrors';
import FormGroup from './FormGroup/FormGroup';
import FormInput from './FormInput/FormInput';
import FormInputAutoSave from './FormInputAutoSave/FormInputAutoSave';
import FormInputPassword from './FormInputPassword/FormInputPassword';
import FormInputPasswordStrength from './FormInputPasswordStrength/FormInputPasswordStrength';
import FormInputNumber from './FormInputNumber/FormInputNumber';
import FormInputDate from './FormInputDate/FormInputDate';
import FormInputNumberAutoSave from './FormInputNumberAutoSave/FormInputNumberAutoSave';
import FormLabel from './FormLabel/FormLabel';
import FormRadio from './FormRadio/FormRadio';
import FormTextarea from './FormTextarea/FormTextarea';
import FormTextareaAutoSave from './FormTextareaAutoSave/FormTextareaAutoSave';
import FormButton from './FormButton/FormButton';
import FormButtonGroup from './FormButtonGroup/FormButtonGroup';

export default {
  install(Vue) {
    Vue.component('FormCheckbox', FormCheckbox);
    Vue.component('FormToggleAutoSave', FormToggleAutoSave);
    Vue.component('FormThreeWaySwitchAutoSave', FormThreeWaySwitchAutoSave);
    Vue.component('FormThreeWaySwitchReadOnly', FormThreeWaySwitchReadOnly);
    Vue.component('FormError', FormError);
    Vue.component('FormFieldErrors', FormFieldErrors);
    Vue.component('FormErrors', FormErrors);
    Vue.component('FormGroup', FormGroup);
    Vue.component('FormInput', FormInput);
    Vue.component('FormInputAutoSave', FormInputAutoSave);
    Vue.component('FormInputPassword', FormInputPassword);
    Vue.component('FormInputPasswordStrength', FormInputPasswordStrength);
    Vue.component('FormInputNumber', FormInputNumber);
    Vue.component('FormInputDate', FormInputDate);
    Vue.component('FormInputNumberAutoSave', FormInputNumberAutoSave);
    Vue.component('FormLabel', FormLabel);
    Vue.component('FormRadio', FormRadio);
    Vue.component('FormTextarea', FormTextarea);
    Vue.component('FormTextareaAutoSave', FormTextareaAutoSave);
    Vue.component('FormButton', FormButton);
    Vue.component('FormButtonGroup', FormButtonGroup);
  }
};
