<template>
  <TickTack :emitAfterTicks="10" emit="update" @update="updateNow">
    <!-- eslint-disable -->
    <Layout variant="5">
      <template #pageContent>
        <InlineLoader v-if="isLoading" />
        <template v-if="!isLoading">
          <span :class="$style.backLink">
            <router-link :to="backLink">
              <i class="icon icon-chevron-left" /> See all {{ unitModuleName }}
            </router-link>
          </span>

          <div :class="$style.unitDetail" class="box-shadow-white">
            <header>
              <!--<div :class="$style.categories">
                <div
                  v-if="hasUnitCategory"
                  v-for="(category, index) in unitCategories"
                  :index="index"
                  :class="$style.category"
                >
                  <LearningUnitCategoryIcon :category="category" />
                  <p class="bold">{{ category.name }}</p>
                </div>
              </div>-->

              <h1 :class="$style.title">{{ unitName }}</h1>
            </header>
            <div :class="$style.content">
              <div>
                <div
                  :class="{
                    [$style.narrative]: true,
                    [$style.empty]: !hasNarrative
                  }"
                >
                  <template v-if="!hasNarrative">
                    <p>There is no narrative available for this unit.</p>
                  </template>
                  <SeeMore
                    v-else
                    :limit="300"
                    :text="unitDetail.description"
                    textClass="display-inline"
                  />
                </div>
                <div :class="$style.outcomes" v-if="hasUnitOutcomes">
                  <img
                    v-svg-inline
                    :src="require(`@/assets/images/icons/outcomes.svg`)"
                  />
                  <div>
                    <h6>Unit Outcomes</h6>

                    <ul>
                      <li
                        v-for="(outcome, index) in unitOutcomes"
                        :index="index"
                      >
                        {{ outcome.description }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-else
                  :class="{ [$style.outcomes]: true, [$style.empty]: true }"
                >
                  <img
                    v-svg-inline
                    :src="require(`@/assets/images/icons/outcomes.svg`)"
                  />
                  <div>
                    <h6>Unit Outcomes</h6>

                    <ul>
                      <li>
                        There is no outcome available for this unit.
                      </li>
                    </ul>
                  </div>
                </div>
                <div :class="$style.learningSessions">
                  <h6>
                    Synchronous Sessions:
                  </h6>

                  <div
                    v-if="!hasUnitSessions"
                    :class="$style.noLearningSessions"
                  >
                    <p>
                      This is where you see the dates and the facilitator when a
                      session is scheduled for this Unit.
                    </p>
                    <p>
                      <sup>*</sup> Self-Study and Clinical Rotation Units are
                      not facilitated by any teacher. Feel free to reach out to
                      your tutor or mentor if you have any questions.
                    </p>
                  </div>

                  <table v-if="hasUnitSessions">
                    <tr v-for="(session, index) in unitSessions" :index="index">
                      <td :class="$style.session">
                        <router-link
                          :to="{
                            name: 'assignment-detail',
                            params: {
                              assignmentId: session.assignment_id,
                              scheduledAssignmentId:
                                session.scheduled_assignment_id
                            }
                          }"
                          :class="$style.link"
                        >
                          <h6>{{ session.type }}</h6>

                          <time>{{ format(session.start_at) }}</time>
                        </router-link>
                      </td>
                      <td>
                        <ul :class="$style.facilitators" data-cy="facilitators">
                          <li
                            v-for="(facilitator, index) in getFacilitators(
                              session
                            )"
                            :key="index"
                          >
                            <router-link
                              v-if="isFacilitatorLinkable(facilitator)"
                              :to="{
                                name: 'user-profile',
                                params: {
                                  userId: facilitator.user_id
                                }
                              }"
                              :class="{
                                [$style.link]: true,
                                [$style.observer]: false
                              }"
                            >
                              <Avatar :user="facilitator" size="28px" />
                              <UserName
                                :user="facilitator"
                                :withTitles="true"
                              />
                            </router-link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <RelatedAssessmentsDesktop :unitQuizzes="unitQuizzes" />
            </div>
          </div>

          <RelatedObjectsDesktop
            v-if="!isPhoneLayout"
            :learningUnitId="learningUnitId"
            :unitLearningObjectives="unitLearningObjectives"
            :objectiveRelatedResources="objectiveRelatedResources"
            :selectedLearningObjective="selectedLearningObjective"
            @lo_selected="onSelected"
          />

          <RelatedObjectsMobile
            v-if="isPhoneLayout"
            :learningUnitId="learningUnitId"
            :unitLearningObjectives="unitLearningObjectives"
            :objectiveRelatedResources="objectiveRelatedResources"
            :selectedLearningObjective="selectedLearningObjective"
            @lo_selected="onSelected"
          />
        </template>
      </template>
    </Layout>
  </TickTack>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import Layout from '@/views/Layout/Layout';
import Avatar from '@/components/common/User/Avatar';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import TickTack from '@/components/common/TickTack/TickTack';

import {
  GET_LEARNING_UNIT,
  GET_SCHEDULE_WEEK_CURRENT
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { ASSESSMENT_TYPES } from '@/config/assessments';

import {
  removeDuplicateObjByKey,
  removeNullItemsFromObj
} from '@/helpers/common';

// import LearningUnitCategoryIcon from './LearningUnitCategoryIcon';
import RelatedObjectsDesktop from './RelatedObjectsDesktop';
import RelatedObjectsMobile from './RelatedObjectsMobile';
import RelatedAssessmentsDesktop from './RelatedAssessmentsDesktop';

export default {
  name: 'LearningUnitDetail',
  components: {
    Layout,
    Avatar,
    // LearningUnitCategoryIcon,
    RelatedObjectsDesktop,
    RelatedObjectsMobile,
    RelatedAssessmentsDesktop,
    InlineLoader,
    TickTack
  },
  data() {
    return {
      nowISO: moment().toISOString(true),
      isLoading: true,
      selectedLearningObjective: undefined,
      unitDetail: undefined,
      weeks: []
    };
  },
  computed: {
    ...mapGetters(['isPhoneLayout', 'getModuleByVersionId']),
    learningUnitId() {
      return this.$route.params.learningUnitId;

      return undefined;
    },
    unitModuleName() {
      const found = this.getModuleByVersionId(this.unitModuleVersionId);

      return found ? found.name : '';
    },
    unitName() {
      return this.unitDetail && this.unitDetail.name;
    },
    hasNarrative() {
      return !!(this.unitDetail && this.unitDetail.description);
    },
    hasUnitCategory() {
      return this.unitCategories.length > 0;
    },
    unitCategories() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.category)) return [];

      return this.unitDetail.category.filter(category => category.category_id);
    },
    unitQuizzes() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.quizzes)) return [];

      return this.unitDetail.quizzes;
    },
    unitLearningObjectives() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.learning_objectives)) return [];

      return this.unitDetail.learning_objectives.filter(
        objective => objective.learning_objective_id
      );
    },
    hasUnitOutcomes() {
      return this.unitOutcomes.length > 0;
    },
    unitOutcomes() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.learning_outcomes)) return [];

      return this.unitDetail.learning_outcomes.filter(
        outcome => outcome.learning_outcome_id
      );
    },
    unitLearningResources() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.learning_resources)) return [];

      return removeDuplicateObjByKey(
        this.unitDetail.learning_resources.filter(resource => resource.slug),
        'slug'
      );
    },
    objectiveRelatedResources() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.learning_resources)) return [];

      const foundResources = this.unitLearningResources.filter(resource => {
        const objectives = (resource && resource.learning_objectives) || [];
        const objectiveIds = objectives.map(o => o.learning_objective_id);

        return (
          resource.slug && objectiveIds.includes(this.selectedLearningObjective)
        );
      });

      const resourcesWithLOBasedPosition = foundResources.reduce(
        (memo, resource) => {
          const lo = resource.learning_objectives.find(
            o => o.learning_objective_id === this.selectedLearningObjective
          );

          const resourceObjectivePosition = {
            ...resource,
            ...{ lo_based_position: lo.resource_position }
          };
          memo.push(resourceObjectivePosition);

          return memo;
        },
        []
      );

      return resourcesWithLOBasedPosition.sort((a, b) => {
        const positionA = a.lo_based_position;
        const positionB = b.lo_based_position;

        if (positionA < positionB) {
          return -1;
        } else if (positionA > positionB) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    hasUnitSessions() {
      return this.unitSessions.length > 0;
    },
    unitSessions() {
      if (!this.unitDetail) return [];

      if (!Array.isArray(this.unitDetail.sessions)) return [];

      return this.unitDetail.sessions.filter(
        assignment => assignment.scheduled_assignment_id
      );
    },
    hasModuleVersionIdParam() {
      return !!(
        this.$route &&
        this.$route.params &&
        this.$route.params.moduleVersionId
      );
    },
    unitModuleVersionId() {
      if (this.hasModuleVersionIdParam === true) {
        return this.$route.params.moduleVersionId;
      } else if (this.unitDetail && this.unitDetail.module_version_id) {
        return this.unitDetail.module_version_id;
      } else {
        return undefined;
      }
    },
    backLink() {
      return {
        name: 'learning-units',
        params: {
          moduleVersionId: this.unitModuleVersionId
        },
        query: removeNullItemsFromObj({
          asCohort: this.hasModuleVersionIdParam
            ? parseInt(this.$route.query.asCohort)
            : null
        })
      };
    }
  },
  watch: {
    async $route(newValue, oldValue) {
      // When only query params change skip data fetching
      if (oldValue.path !== newValue.path) {
        await this.fetchData(this.learningUnitId);
      }

      this.selectLearningObjectiveFromRoute();
    },
    selectedLearningObjective(newValue) {
      const newQuery = { ...this.$route.query, ...{ lo_id: newValue } };
      this.$router
        .replace({ params: this.$route.params, query: newQuery })
        .catch(() => {});
    }
  },
  methods: {
    ...mapActions('learning', [GET_LEARNING_UNIT]),
    ...mapActions([GET_SCHEDULE_WEEK_CURRENT]),
    format(dateISOString) {
      if (dateISOString) {
        const m_date = moment(dateISOString);
        if (m_date.isValid()) {
          return m_date.format('DD.MM.YYYY');
        }
      }

      if (dateISOString === null) {
        return 'undefined';
      }

      return '';
    },
    onSelected(objectiveId) {
      if (!objectiveId) return;

      if (this.selectedLearningObjective === objectiveId) {
        this.selectedLearningObjective = undefined;
      } else {
        this.selectedLearningObjective = objectiveId;
      }
    },
    fetchData(learningUnitId) {
      return new Promise(async (resolve, reject) => {
        if (!learningUnitId) resolve();

        try {
          this.isLoading = true;
          this.unitDetail = await this.GET_LEARNING_UNIT(learningUnitId);
          const current = await this.GET_SCHEDULE_WEEK_CURRENT();
          this.weeks = current && current.weeks ? current.weeks : [];
          this.isLoading = false;
          resolve();
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          this.isLoading = false;
          reject(error);
        }
      });
    },
    updateNow(dateISO) {
      this.nowISO = dateISO;
    },
    selectLearningObjectiveFromRoute(onCreated = false) {
      if (this.unitLearningObjectives.length) {
        const availableIds = this.unitLearningObjectives.map(
          lo => lo.learning_objective_id
        );
        const queryId = parseInt(this.$route.query.lo_id);
        const firstId = availableIds[0];

        const isQueryIdValid = Number.isNaN(queryId) ? false : true;
        const isQueryIdAvailable = availableIds.includes(queryId);

        if (isQueryIdValid && isQueryIdAvailable) {
          this.selectedLearningObjective = queryId;
          return;
        }

        if (isQueryIdValid && !isQueryIdAvailable) {
          this.selectedLearningObjective = undefined;
          return;
        }

        if (!isQueryIdValid && onCreated) {
          this.selectedLearningObjective = firstId;
          return;
        } else {
          this.selectedLearningObjective = undefined;
        }
      } else {
        this.selectedLearningObjective = undefined;
      }
    },
    isFacilitatorLinkable(facilitator) {
      if (!facilitator) return false;

      return facilitator.user_id ? true : false;
    },
    getFacilitators(session) {
      if (!session) return [];

      if (Object.keys(session).includes('facilitators')) {
        if (
          Array.isArray(session.facilitators) &&
          session.facilitators.length
        ) {
          return session.facilitators;
        }
      } else if (Object.keys(session).includes('facilitator')) {
        if (session.facilitator) return [session.facilitator];
      }

      // In every other case return empty array
      return [];
    }
  },
  async created() {
    try {
      await this.fetchData(this.learningUnitId);
      // When first entering the LU Details page LOs should be displayed by default, not the resources.
      if (this.isPhoneLayout) {
        this.selectLearningObjectiveFromRoute(false);
      } else {
        this.selectLearningObjectiveFromRoute(true);
      }
    } catch (error) {
      void error;
    }
  }
};
</script>

<style lang="scss" module>
@import './LearningUnitDetail.scss';
</style>
