var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.resource)?_c('DocumentCard',{attrs:{"isEmpty":true}}):_c('DocumentCard',{class:_vm.$style.card},[_c('header',[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],class:_vm.$style.icon,attrs:{"src":require("@/assets/images/icons/document.svg")}}),_c('span',[(_vm.currentMode === _vm.MODES.EDIT)?_c('IconButton',{on:{"click":function($event){return _vm.$emit('remove_upload')}}},[_c('i',{staticClass:"icon icon-remove"})]):_vm._e()],1)]),_c('main',[_c('section',{class:_vm.$style.primary},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.resourceTitleOrFileName))])])]),_c('section',{class:( _obj = {}, _obj[_vm.$style.secondary] = true, _obj[_vm.$style.withAttempts] = _vm.submission, _obj )},[(_vm.submission)?[_c('p',{class:_vm.$style.small},[_c('UserName',{attrs:{"userId":_vm.submission.resource_user_id}})],1),_c('p',{class:_vm.$style.small,attrs:{"data-ignore":"snapshot"}},[_vm._v(" "+_vm._s(_vm._f("format")(_vm.submission && _vm.submission.created_at))+" ")]),_c('p',{class:_vm.$style.small},[_vm._v("# "+_vm._s(_vm.submission.atttempt_no))])]:[_c('p',{class:_vm.$style.small},[_c('UserName',{attrs:{"userId":_vm.resource && _vm.resource.owner_ids && _vm.resource.owner_ids[0]}})],1),_c('p',{class:_vm.$style.small,attrs:{"data-ignore":"snapshot"}},[_vm._v(" "+_vm._s(_vm._f("format")(_vm.uploadFeedbackFile && _vm.uploadFeedbackFile.created_at))+" ")])]],2)]),_c('footer',[_c('router-link',{attrs:{"to":{
        name: 'pdf-view',
        params: {
          resourceSlug: _vm.resourceSlug
        }
      },"target":"_blank"}},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"src":require("@/assets/images/icons/view.svg")}}),_vm._v(" View ")]),_c('a',{attrs:{"href":_vm.resourceFilepath,"download":""}},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"src":require("@/assets/images/icons/download.svg")}}),_vm._v(" Download ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }