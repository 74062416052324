<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template slot="pageContent">
      <div :class="$style.wrapper">
        <h6>Blog Posts</h6>

        <div :class="$style.header">
          <div :class="$style.buttonsLeft">
            <router-link
              to="/admin/blog/published"
              class="btn ghost"
              :class="{
                [$style.selected]: $route.name === 'admin-blog-published'
              }"
              >Published</router-link
            >
            <router-link
              to="/admin/blog/drafts"
              class="btn ghost"
              :class="{
                [$style.selected]: $route.name === 'admin-blog-drafts'
              }"
              >Drafts</router-link
            >
          </div>
          <div :class="$style.buttonsRight">
            <router-link
              to="/admin/blog/new"
              class="btn standard"
              :class="$style.greenButton"
              >Create draft</router-link
            >
            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '@/views/Layout/Layout';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout
  },
  data() {
    return {
      display404: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminBlog.scss';
@import '../AdminTables.scss';
</style>
