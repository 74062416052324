<template>
  <div :class="$style.resources">
    <header>
      <a @click="collapsed = !collapsed" :class="$style.flipLink" class="link">
        <i class="icon icon-chevron-down" v-if="collapsed" />
        <i class="icon icon-chevron-up" v-if="!collapsed" />
      </a>
    </header>

    <content>
      <div :class="$style.main">
        1. Main resources: &nbsp;
        <ListUnits :learningUnits="leadSession.learning_units" />
      </div>
      <div :class="$style.additional">
        2. Uploaded by the facilitator:
      </div>
      <TransitionExpand>
        <div v-if="!collapsed">
          <div :class="$style.additional">
            <div :class="$style.files">
              <strong>Files</strong>
              <div v-if="hasNoteResources">
                <ul>
                  <li
                    v-for="(resource, index) in noteResources"
                    :key="index"
                    :class="$style.resource"
                  >
                    <a :class="$style.info" @click="onOpen(resource.slug)">
                      <button :class="$style.resourceIcon">
                        <template v-if="resource.type === 'image'">
                          <i class="icon icon-file-image-o"></i>
                        </template>
                        <template v-else-if="resource.type === 'link'">
                          <i class="icon icon-link-o"></i>
                        </template>
                        <template v-else-if="resource.type === 'pdf'">
                          <i class="icon icon-file-pdf-o"></i>
                        </template>
                        <template v-else>
                          <i class="icon icon-file-o"></i>
                        </template>
                      </button>

                      {{ resource.title }}
                    </a>

                    <button
                      :class="$style.removeIcon"
                      @click="onDeleteUpload(resource.slug)"
                      v-if="currentMode === MODES.EDIT"
                    >
                      <i class="icon icon-close2"></i>
                    </button>

                    <ResourceDownloadLink
                      :resource="resource"
                      v-if="currentMode === MODES.VIEW"
                      :class="{
                        [$style.downloadLink]: true,
                        ['btn']: !isMobileLayout
                      }"
                    >
                      <img
                        v-svg-inline
                        :src="require(`@/assets/images/icons/download.svg`)"
                      />

                      <span v-if="!isMobileLayout">&nbsp; Download</span>
                    </ResourceDownloadLink>
                  </li>
                </ul>
              </div>
              <p v-else><small>No files uploaded yet.</small></p>

              <ResourceUpload
                v-if="currentMode === MODES.EDIT"
                @success="onUploaded"
              />
            </div>

            <div :class="$style.notes">
              <strong>Notes</strong>

              <template v-if="currentMode === MODES.VIEW">
                <SeeMore
                  :text="noteTextModel"
                  :limit="1500"
                  v-if="hasNoteText || noteTextModel"
                />

                <p v-else><small>No notes added yet.</small></p>
              </template>

              <template v-if="currentMode === MODES.EDIT">
                <Editor
                  ref="textFeedbackModel"
                  rows="10"
                  v-model="noteTextModel"
                  placeholder="Write a note..."
                  :disabled="inputsDisabled"
                  data-cy="noteText"
                />

                <span v-if="noteTextIsValid === false">
                  Please provide valid note
                </span>
              </template>
            </div>
          </div>

          <Notice
            v-if="hasErrors"
            type="error"
            :contentStyle="'max-width: 33%; padding: 7px;'"
            @dismiss="errors = []"
            :class="$style.errors"
          >
            {{ errors.join(', ') }}
          </Notice>

          <footer v-if="isCurrentUserTeachingStaff">
            <template v-if="inViewMode">
              <template v-if="!isNotePublished">
                <FormButton
                  :standard="hasNoteTextModel || hasNoteText ? false : true"
                  :ghost="hasNoteTextModel || hasNoteText ? true : false"
                  :disabled="resourceUploading"
                  @click="currentMode = MODES.EDIT"
                >
                  Edit
                </FormButton>

                <FormButton
                  :standard="hasNoteTextModel"
                  :ghost="!hasNoteTextModel"
                  :disabled="resourceUploading"
                  v-if="hasNoteTextModel || hasNoteResources"
                  @click="noteSaveAndPublish"
                >
                  Publish
                </FormButton>
              </template>

              <template v-if="isNotePublished">
                <FormButton
                  :standard="true"
                  :ghost="false"
                  :disabled="resourceUploading"
                  @click="currentMode = MODES.EDIT"
                >
                  Edit
                </FormButton>

                <template v-if="usersNotified">
                  <!-- <button
                    class="btn"
                    :class="$style.notImplemented"
                    @click="notifyUsers"
                  >
                    <i class="icon icon-exclamation-triangle"></i> &nbsp; Not
                    Implemented! Yet...
                  </button> -->
                  <!-- Notifications disabled for now as per https://candena.slack.com/archives/CD9B12HEU/p1675800392540699 -->
                  <!-- <button class="btn" :class="$style.notified" @click="notifyUsers"><i class="icon icon-check"></i> &nbsp; Notification Sent! </button> -->
                </template>
                <template v-else>
                  <!-- <button
                    class="btn"
                    :class="$style.notifyUsers"
                    @click="notifyUsers"
                  >
                    <i class="icon icon-envelope-o"></i> &nbsp; Notify students
                  </button> -->
                </template>
              </template>
            </template>

            <template v-if="inEditMode">
              <template v-if="!isNotePublished">
                <FormButton
                  :standard="true"
                  :disabled="resourceUploading"
                  @click="currentMode = MODES.VIEW"
                >
                  Preview
                </FormButton>

                <FormButton
                  :ghost="true"
                  :disabled="resourceUploading"
                  @click="noteTextDiscard"
                >
                  Cancel
                </FormButton>
              </template>

              <template v-if="isNotePublished">
                <FormButton
                  :standard="true"
                  :disabled="resourceUploading"
                  @click="noteTextSave"
                >
                  Save changes
                </FormButton>

                <FormButton
                  :ghost="true"
                  :disabled="resourceUploading"
                  @click="noteTextDiscard"
                >
                  Discard
                </FormButton>
              </template>
            </template>
          </footer>
        </div>
      </TransitionExpand>
    </content>
  </div>
</template>

<script>
import TransitionExpand from '@/components/common/TransitionExpand/TransitionExpand';
import Notice from '@/components/notices/Notice/Notice';
import ListUnits from './ListUnits';
import { MODES } from '@/config/grading';
import { STATES } from '@/config/forms';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_ASSIGNMENT,
  POST_ASSIGNMENT_NOTE_RESOURCES,
  DELETE_ASSIGNMENT_NOTE_RESOURCES,
  POST_ASSIGNMENT_NOTE_TEXT,
  POST_ASSIGNMENT_NOTE_PUBLISH,
  GET_RESOURCE,
  GET_USER_KVSTORE,
  PATCH_USER_KVSTORE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { getErrorBody } from '@/helpers/errors';

import ResourceUpload from '@/components/uploads/ResourceUpload/ResourceUpload';
import ResourceDownloadLink from '@/components/common/ResourceDownloadLink/ResourceDownloadLink';
import SeeMore from '@/components/common/SeeMore/SeeMore';
import Editor from '@/components/common/Editor/Editor';

export default {
  name: 'AssignmentResources',
  components: {
    TransitionExpand,
    ListUnits,
    ResourceUpload,
    Editor,
    ResourceDownloadLink,
    SeeMore,
    Notice
  },
  props: {
    assignment: {
      type: Object
    },
    leadSession: {
      type: Object
    }
  },
  data() {
    return {
      currentMode: MODES.VIEW,
      state: STATES.idle,
      noteTextIsValid: undefined,
      noteTextModel: undefined,
      usersNotified: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters([
      'isCurrentUserTeachingStaff',
      'getUserKVStoreValue',
      'isMobileLayout',
      'resourceUploading'
    ]),
    hasErrors() {
      return this.errors.length > 0;
    },
    collapsed: {
      get() {
        return this.getUserKVStoreValue('resource_uploads_collapsed');
      },
      async set(newValue) {
        try {
          await this.PATCH_USER_KVSTORE({
            resource_uploads_collapsed: newValue
          });
          await this.GET_USER_KVSTORE();
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      }
    },
    assignmentId() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.assignmentId
      ) {
        return parseInt(this.$route.params.assignmentId);
      }

      return undefined;
    },
    inViewMode() {
      return this.currentMode === MODES.VIEW;
    },
    inEditMode() {
      return this.currentMode === MODES.EDIT;
    },
    inputsDisabled() {
      return this.inViewMode ? true : false;
    },
    hasNote() {
      return this.assignment && this.assignment.assignment_note ? true : false;
    },
    hasNoteText() {
      return this.hasNote && this.assignment.assignment_note.note;
    },
    hasNoteTextModel() {
      return this.noteTextModel !== undefined ? true : false;
    },
    noteText() {
      if (!this.hasNoteText) return undefined;

      return this.assignment.assignment_note.note;
    },
    hasUnsavedChanges() {
      return this.noteText !== this.noteTextModel;
    },
    isNotePublished() {
      return this.hasNote && this.assignment.assignment_note.published_at;
    },
    hasNoteResources() {
      return (
        this.hasNote &&
        this.assignment.assignment_note.resources &&
        this.assignment.assignment_note.resources.length > 0
      );
    },
    noteResources() {
      if (this.hasNoteResources) {
        return this.assignment.assignment_note.resources;
      }

      return [];
    },
    noteResourcesSlugs() {
      if (this.hasNoteResources) {
        return this.noteResources.map(res => res.slug);
      }

      return [];
    }
  },
  methods: {
    ...mapActions('grading', [GET_ASSIGNMENT]),
    ...mapActions([
      POST_ASSIGNMENT_NOTE_RESOURCES,
      POST_ASSIGNMENT_NOTE_TEXT,
      POST_ASSIGNMENT_NOTE_PUBLISH,
      DELETE_ASSIGNMENT_NOTE_RESOURCES,
      GET_RESOURCE,
      GET_USER_KVSTORE,
      PATCH_USER_KVSTORE
    ]),
    async onOpen(resourceSlug) {
      try {
        const { file } = await this.GET_RESOURCE(resourceSlug);

        if (file && file.path) {
          window.open(file.path, '_blank');
        }
      } catch {
        this.errors = ['Oops, failed to open the uploaded file.'];
      }
    },
    async onUploaded(response) {
      if (!response || !response.resource) return;

      const newResourceSlug = response.resource.slug;
      const payload = {
        resource_slugs: [...this.noteResourcesSlugs, newResourceSlug]
      };

      try {
        this.errors = [];
        await this.POST_ASSIGNMENT_NOTE_RESOURCES({
          assignmentId: this.assignmentId,
          payload
        });
        await this.GET_ASSIGNMENT(this.assignmentId);
      } catch {
        this.errors = [
          'Oops, failed to attach the uploaded file to assignment.'
        ];
      }
    },
    async onDeleteUpload(resourceSlug) {
      if (!resourceSlug) return;

      try {
        this.errors = [];
        await this.DELETE_ASSIGNMENT_NOTE_RESOURCES({
          assignmentId: this.assignmentId,
          resourceSlug
        });
        await this.GET_ASSIGNMENT(this.assignmentId);
      } catch {
        this.errors = ['Oops, failed to remove the uploaded file.'];
      }
    },
    async noteTextSave() {
      try {
        this.errors = [];
        await this.POST_ASSIGNMENT_NOTE_TEXT({
          assignmentId: this.assignmentId,
          payload: {
            note: this.noteTextModel
          }
        });

        await this.GET_ASSIGNMENT(this.assignmentId);

        this.currentMode = MODES.VIEW;
      } catch {
        this.errors = ['Oops, failed to save the note text.'];
      }
    },
    noteTextDiscard() {
      this.noteTextModel = this.noteText;
      this.currentMode = MODES.VIEW;
    },
    async noteSaveAndPublish() {
      try {
        await this.POST_ASSIGNMENT_NOTE_TEXT({
          assignmentId: this.assignmentId,
          payload: {
            note: this.noteTextModel
          }
        });

        await this.POST_ASSIGNMENT_NOTE_PUBLISH({
          assignmentId: this.assignmentId,
          payload: {}
        });

        await this.GET_ASSIGNMENT(this.assignmentId);
      } catch (error) {
        this.errors = ['Oops, failed to publish the note.'];
      }
    },
    notifyUsers() {
      this.usersNotified = !this.usersNotified;
    }
  },
  async created() {
    this.MODES = MODES;
    this.noteTextModel = this.hasNoteText
      ? this.assignment.assignment_note.note
      : undefined;

    try {
      await this.GET_USER_KVSTORE();
    } catch (error) {
      void error;
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/AssignmentResources.scss';
</style>
