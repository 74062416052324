<template>
  <div :class="classes" class="form-input">
    <InputNumber
      v-bind="$attrs"
      :action="action"
      :value="value"
      @state="updateState"
      @input="relayInput"
      @success="relaySuccess"
      @error="relayError"
      @validation_result="relayValidationResult"
    />
    <div :class="$style.info" v-if="withActionIndicator">
      <img
        :class="{
          [$style.state]: true,
          [$style.blinking]: isBlinking
        }"
        v-if="getStateIcon(state)"
        :title="stateTitle"
        :src="require('@/assets/images/icons/' + getStateIcon(state))"
      />
      <span v-else :class="$style.state">&nbsp;</span>
    </div>
  </div>
</template>

<script>
import { STATES } from '@/config/forms';
import InputNumber from '@/components/forms/InputNumber/InputNumber';

export default {
  name: 'FormInputNumberAutoSave',
  components: {
    InputNumber
  },
  props: {
    value: {},
    action: {
      type: Function // Parent must pass Function that returns a Function that returns a Promise
    },
    inActionTitle: {
      type: String,
      default: 'Saving...'
    },
    onSuccessTitle: {
      type: String,
      default: 'Saved'
    },
    onErrorTitle: {
      type: String
    },
    withActionIndicator: {
      type: Boolean,
      default: true
    },
    withBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      state: STATES.idle,
      errorMessage: ''
    };
  },
  computed: {
    isBlinking() {
      return this.state === STATES.typing;
    },
    classes() {
      const classes = [this.$style.wrapper];

      if (!this.stepping) {
        classes.push(this.$style.noStepping);
      }

      if (!this.withActionIndicator) {
        classes.push(this.$style.noActionIndicator);
      }

      if (this.withBorder) {
        classes.push(this.$style.whiteBackground);
      }

      return classes;
    },
    stateTitle() {
      let titleText = '';

      switch (this.state) {
        case STATES.in_action:
          titleText = this.inActionTitle;
          break;
        case STATES.success:
          titleText = this.onSuccessTitle;
          break;
        case STATES.error:
          if (this.onErrorTitle && this.errorMessage) {
            titleText = this.onErrorTitle;
            titleText += '\n';
            titleText += this.errorMessage;
          }

          if (!this.onErrorTitle && this.errorMessage) {
            titleText += this.errorMessage;
          }

          break;
        default:
          titleText = '';
      }

      return titleText;
    }
  },
  methods: {
    updateState(newState) {
      this.state = newState;
      this.$emit('state', newState);
    },
    relayInput(inputValue) {
      this.$emit('input', inputValue);
    },
    relaySuccess(newValue) {
      this.$emit('success', newValue);
    },
    relayError(newValue) {
      this.$emit('error', newValue);
    },
    relayValidationResult(validationResult) {
      this.$emit('validation_result', validationResult);
    },
    getStateIcon(state) {
      if (state === STATES.typing) return 'saving.svg';
      if (state === STATES.in_action) return 'saving.svg';
      if (state === STATES.success) return 'saved.svg';
      if (state === STATES.error) return 'not_saved.svg';

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import '../FormInput/FormInput.scss';
@import './FormInputNumberAutoSave.scss';
</style>
