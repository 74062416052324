import moment from 'moment';
import { FROM_OLDEST } from '@/config/dates';
import { sortingByDate } from '@/helpers/common';
import { logWarning } from '@/helpers/errors';
import { ALLOW_JOIN_IN_ADVANCE_MIN } from '@/config/schedule';

function getPeriodIdent(period) {
  const group =
    (period && period.user_groups && period.user_groups.user_group_id) ||
    'unknown';

  return `${period.assignment_id}-${
    period.scheduled_assignment_id
  }-ugid_${group}-"${period.name || 'noname'}"`;
}

/**
 * Decides if current period overlaps with next/previous one
 * This is done for every period in given array
 * By default matching previous/next end/start is allowed (non-strict mode)
 * Returns array of true/false values, one for every period
 */
export const findOverlaps = (
  periods = [],
  strict = false,
  start_attr = 'start_at',
  end_attr = 'end_at'
) => {
  const OVERLAPING = true;
  const NOT_OVERLAPING = false;

  const foundOverlaps = periods.map((current, index) => {
    const m_current_start = moment(current[start_attr]);
    const m_current_end = moment(current[end_attr]);

    // Do not tolerate end before start
    if (!m_current_end.isAfter(m_current_start)) {
      logWarning(
        `${current[end_attr]} is before ${
          current[start_attr]
        } (${getPeriodIdent(current)})`,
        { silenceSentry: true }
      );
      return OVERLAPING;
    }

    const next = periods[index + 1];

    if (next) {
      const m_next_start = moment(next[start_attr]);
      const m_next_end = moment(next[end_attr]);

      // Tolerate current end and next start to be the same
      if (m_next_start.isSame(m_current_end)) {
        if (strict)
          logWarning(
            `${getPeriodIdent(current)} end (${
              current[end_attr]
            }) and  ${getPeriodIdent(next)} start (${
              next[start_attr]
            }) are the same`,
            { silenceSentry: true }
          );
        return strict ? OVERLAPING : NOT_OVERLAPING;
      }

      // But anything else is an overlap
      if (!m_next_start.isAfter(m_current_end)) {
        logWarning(
          `${getPeriodIdent(next)} start (${
            next[start_attr]
          }) is not after ${getPeriodIdent(current)} end (${
            current[end_attr]
          })`,
          { silenceSentry: true }
        );
        return OVERLAPING;
      }
    }

    const previous = periods[index - 1];
    if (previous) {
      const m_previous_start = moment(previous[start_attr]);
      const m_previous_end = moment(previous[end_attr]);

      // Tolerate current start and previous end to be the same
      if (m_current_start.isSame(m_previous_end)) {
        if (strict)
          logWarning(
            `${getPeriodIdent(current)} start (${
              current[start_attr]
            }) and  ${getPeriodIdent(previous)} end (${
              previous[end_attr]
            }) are the same`,
            { silenceSentry: true }
          );
        return strict ? OVERLAPING : NOT_OVERLAPING;
      }
      // But anything else is an overlap
      if (!m_current_start.isAfter(m_previous_end)) {
        logWarning(
          `${getPeriodIdent(current)} start (${
            current[start_attr]
          }) is not after ${getPeriodIdent(previous)} end (${
            previous[end_attr]
          })`,
          { silenceSentry: true }
        );
        return OVERLAPING;
      }
    }

    return NOT_OVERLAPING;
  });

  return foundOverlaps;
};

/**
 * Receives an array of objects with ISO formatted `start_at` and `end_at` properties (periods)
 * and puts them into corresponding "buckets" by comparing the properties to a given dateISOString
 * Returns Object with past/future/present/all keys, every key holds an array of periods
 */
export const periodsByTime = (
  periods,
  dateISOString,
  strictOverlaps,
  start_attr = 'start_at',
  end_attr = 'end_at',
  bypassOverlaps = false
) => {
  const result = {
    past: [],
    present: [],
    future: [],
    all: periods
  };

  const pivot = moment(dateISOString);

  if (!Array.isArray(periods) || !dateISOString || !pivot.isValid()) {
    return undefined;
  }

  if (periods.length === 0) {
    return result;
  }

  const validPeriods = periods.filter(
    period =>
      period[start_attr] &&
      moment(period[start_attr]).isValid() &&
      period[end_attr] &&
      moment(period[end_attr]).isValid()
  );

  if (validPeriods.length === 0) {
    return result;
  }

  sortingByDate(validPeriods, start_attr, FROM_OLDEST);

  if (
    !bypassOverlaps &&
    findOverlaps(validPeriods, strictOverlaps, start_attr, end_attr).includes(
      true
    )
  ) {
    return undefined;
  }

  validPeriods.map(period => {
    const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

    const m_periodStart = moment(period[start_attr]);
    const m_periodEnd = moment(period[end_attr]);

    if (pivot.isBetween(m_periodStart, m_periodEnd, null, INCLUSIVITY)) {
      result.present.push(period);
      return;
    }

    if (pivot.isBefore(m_periodStart)) {
      result.future.push(period);
      return;
    }

    if (pivot.isAfter(m_periodEnd)) {
      result.past.push(period);
    }
  });

  return result;
};

/**
 * TODO: tests, description
 */
export const nextPeriodEnd = (
  periodsByTime,
  dateISOString,
  periodsAhead = 1
) => {
  if (!dateISOString || !moment(dateISOString).isValid())
    return new Error('Valid pivot time required!');

  if (!parseInt(periodsAhead))
    return new Error('Periods ahead parameter must be an Integer!');

  const todayEnd = moment(dateISOString)
    .endOf('day')
    .toISOString(true);

  if (!periodsByTime || !periodsByTime.all || periodsByTime.all.length === 0) {
    // If there are no periods at all, return end of the day
    return todayEnd;
  }

  if (periodsByTime.future.length >= periodsAhead) {
    // If there's exactly or more than periodsAhead periods in future
    const found = periodsByTime.future[periodsAhead - 1];
    return found && found.end_at ? found.end_at : undefined;
  }

  if (
    periodsByTime.future.length > 0 &&
    periodsByTime.future.length < periodsAhead
  ) {
    // If there's less than periodsAhead periods in future
    const found = periodsByTime.future[periodsByTime.future.length - 1];
    return found && found.end_at ? found.end_at : undefined;
  }

  if (periodsByTime.future.length === 0 && periodsByTime.present.length > 0) {
    // If there are no periods in future, but there are some in present
    const found = periodsByTime.present[periodsByTime.present.length - 1];
    return found && found.end_at ? found.end_at : undefined;
  }

  if (
    periodsByTime.all.length > 0 &&
    periodsByTime.future.length === 0 &&
    periodsByTime.present.length === 0 &&
    periodsByTime.past.length === 0
  ) {
    // If there are only periods with null start_at & end_at times
    return todayEnd;
  }

  // Now there are only past periods left
  // let's return the last one
  const found = periodsByTime.past[periodsByTime.past.length - 1];
  return found && found.end_at ? found.end_at : undefined;
};

/**
 * And returns Array of weekdays ISO strings between periodStart and periodEnd
 */
export const weekdaysBetween = function(periodStart, periodEnd) {
  // Weekday indexes according to https://momentjs.com/docs/#/get-set/day/
  const SATURDAY = 6;
  const SUNDAY = 0;

  if (!periodStart && !periodEnd) return [];

  const m_periodStart = moment(periodStart).startOf('day');
  const m_periodEnd = moment(periodEnd).endOf('day');

  if (!m_periodStart.isValid() || !m_periodEnd.isValid()) {
    logWarning('periodStart or periodEnd is not a valid moment', {
      silenceSentry: true
    });
    return [];
  }

  if (m_periodEnd.isBefore(m_periodStart)) {
    logWarning('periodEnd is before periodStart', { silenceSentry: true });
    return [];
  }

  let currentDay = m_periodStart.clone();
  let weekdays = [];

  while (!currentDay.isAfter(m_periodEnd)) {
    if (![SATURDAY, SUNDAY].includes(currentDay.day())) {
      weekdays.push(currentDay.toISOString(true));
    }

    currentDay = moment(currentDay).add(1, 'days');
  }

  return weekdays;
};

export const canJoinSession = function(lesson, nowISO) {
  if (!lesson) return false;
  if (!moment(nowISO).isValid()) return false;

  const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/
  const LONG_KEY = 'newrow_context';
  const SHORT_KEY = 'nc';
  const lessonKeys = Object.keys(lesson);

  const hasNewRowAttribute = lessonKeys.some(key =>
    [LONG_KEY, SHORT_KEY].includes(key)
  );

  if (!hasNewRowAttribute) return false;

  const foundKey = lessonKeys.includes(LONG_KEY) ? LONG_KEY : SHORT_KEY;

  const isJoinable =
    lesson[foundKey] && lesson[foundKey].context_id ? true : false;

  const m_event_start = isJoinable
    ? moment(lesson.start_at).subtract(ALLOW_JOIN_IN_ADVANCE_MIN, 'minutes')
    : moment(lesson.start_at);
  const m_event_end = moment(lesson.end_at);

  if (!m_event_start.isValid()) return false;
  if (!m_event_end.isValid()) return false;

  return moment(nowISO).isBetween(
    m_event_start,
    m_event_end,
    null,
    INCLUSIVITY
  );
};

export const getWeekType = function(weeks, weekId) {
  if (!Array.isArray(weeks) || weeks.length === 0) return undefined;

  const found = weeks.find(week => week.calendar_week_id === weekId);

  return found && found.phase_type ? found.phase_type : undefined;
};

export const getDayType = function(
  dayISO,
  currentWeekType,
  nextWeekType,
  nowISO = moment().toISOString(true)
) {
  const m_this_week = moment(nowISO);
  const m_next_week = moment(nowISO).add(1, 'weeks');
  const m_day = moment(dayISO);

  if (!m_this_week.isValid()) return undefined;
  if (!m_next_week.isValid()) return undefined;
  if (!m_day.isValid()) return undefined;

  if (m_day.isSame(m_this_week, 'isoWeek')) {
    return currentWeekType;
  }

  if (m_day.isSame(m_next_week, 'isoWeek')) {
    return nextWeekType;
  }

  return undefined;
};

export const getAssignmentType = function(assignment) {
  if (assignment && assignment.session_type) return assignment.session_type;

  // Fallback to assignment_type if session_type not present
  // Deprecated, replaced with session_type
  if (assignment && assignment.assignment_type)
    return assignment.assignment_type;

  return '';
};
