<template>
  <Layout variant="6">
    <template #pageContent>
      <h1>Internal status</h1>

      <h2>Frontend</h2>
      <p>
        Running code from a commit with hash:
        <strong>
          <code>{{ frontendCommitHash }}</code>
        </strong>
      </p>
      <p v-if="isDirty(frontendCommitHash)" class="p-1">
        <Notice
          :contentStyle="'max-width: 100%; padding: 7px;'"
          :dismissable="false"
        >
          Note that the commit ends with '-dirty' which means that at the time
          of deploy there were file modifications that have not been committed
          and/or there are new untracked files on top of that commit.
        </Notice>
      </p>
      <p class="p-1">
        <a :href="frontendGithubLink" target="_blank" class="btn link"
          >Search repository for the commit</a
        >
      </p>

      <h2>Backend</h2>
      <p>
        Running code from a commit with hash:
        <strong>
          <code>{{ backendCommitHash }}</code>
        </strong>
      </p>
      <p class="p-1">
        <Notice
          :contentStyle="'max-width: 100%; padding: 7px;'"
          :dismissable="false"
          type="info"
        >
          Note that it's a hash baked into the API binary during compilation.
          The server might be actually running different commit (for example
          with updated migrations code without need to compile new API server
          binary)
        </Notice>
      </p>
      <p v-if="isDirty(backendCommitHash)" class="p-1">
        <Notice
          :contentStyle="'max-width: 100%; padding: 7px;'"
          :dismissable="false"
        >
          Note that the commit ends with '-dirty' which means that at the time
          of deploy there were file modifications that have not been committed
          and/or there are new untracked files on top of that commit.
        </Notice>
      </p>
      <p class="p-1">
        <a :href="backendGithubLink" target="_blank" class="btn link"
          >Search repository for the commit</a
        >
      </p>
    </template>
  </Layout>
</template>

<script>
import Notice from '@/components/notices/Notice/Notice';
import Layout from '@/views/Layout/Layout';
import { logError } from '@/helpers/errors';

export default {
  components: {
    Layout,
    Notice
  },
  data() {
    return {
      backend: undefined
    };
  },
  computed: {
    frontendCommitHash() {
      return process.env.BUILD_HASH;
    },
    frontendGithubLink() {
      return this.repositorySearchLink(
        'app',
        this.trimHash(this.frontendCommitHash)
      );
    },
    backendCommitHash() {
      return this.backend && this.backend.hash ? this.backend.hash : undefined;
    },
    backendGithubLink() {
      return this.repositorySearchLink(
        'api',
        this.trimHash(this.backendCommitHash)
      );
    }
  },
  methods: {
    isDirty(hash) {
      return hash ? hash.endsWith('-dirty') : undefined;
    },
    trimHash(hash) {
      if (!hash) return undefined;

      if (this.isDirty(hash) === true) {
        const parts = hash.split('-dirty');
        return parts[0];
      }

      return hash;
    },
    repositorySearchLink(repo, hash) {
      return `https://github.com/candena/${repo}/search?q=${hash}&type=commits`;
    }
  },
  async created() {
    const backendStatusURL =
      process.env.NODE_ENV === 'production'
        ? '/internal/status'
        : `${process.env.API_DOMAIN}/internal/status`;

    let customHeaders = new Headers();
    customHeaders.append('pragma', 'no-cache');
    customHeaders.append('cache-control', 'no-cache');

    fetch(backendStatusURL, { method: 'GET', headers: customHeaders })
      .then(response => response.json())
      .then(data => {
        this.backend = data;
      })
      .catch(error => {
        logError(error);
      });
  }
};
</script>

<style lang="scss" module>
@import './AppInternalStatus.scss';
</style>
