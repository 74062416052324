<template>
  <ul v-if="errors && errors.length" :class="$style.wrapper">
    <li v-for="(error, i) in errors" :key="i">
      {{ error.message }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
</script>

<style lang="scss" module>
@import 'FormFieldErrors';
</style>
