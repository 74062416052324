// Based on @ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter

import store from '@/store';
import { UPLOAD_TYPES } from '@/config/files';
import { CREATE_FILE_RESOURCE, POLL_FILE_RESOURCE } from '@/store/actions.type';
import { SET_RESOURCE_UPLOAD_IN_PROGRESS } from '@/store/mutations.type';

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';

export default class SimpleUploadAdapter extends Plugin {
  static get requires() {
    return [FileRepository];
  }

  static get pluginName() {
    return 'SimpleUploadAdapter';
  }

  init() {
    const options = this.editor.config.get('simpleUpload');

    if (!options) {
      return;
    }

    this.editor.plugins.get(FileRepository).createUploadAdapter = loader => {
      return new Adapter(loader, options);
    };
  }
}

class Adapter {
  /**
   * Creates a new adapter instance.
   *
   * @param {module:upload/filerepository~FileLoader} loader
   * @param {module:upload/adapters/simpleuploadadapter~SimpleUploadConfig} options
   */
  constructor(loader, options) {
    /**
     * FileLoader instance to use during the upload.
     *
     * @member {module:upload/filerepository~FileLoader} #loader
     */
    this.loader = loader;

    /**
     * The configuration of the adapter.
     *
     * @member {module:upload/adapters/simpleuploadadapter~SimpleUploadConfig} #options
     */
    this.options = options;
  }

  /**
   * Starts the upload process.
   *
   * @see module:upload/filerepository~UploadAdapter#upload
   * @returns {Promise}
   */
  upload() {
    store.commit(SET_RESOURCE_UPLOAD_IN_PROGRESS, true);
    // upload start callback?
    return this.loader.file.then(
      file =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
          try {
            const imgUrl = await this._uploadAndPollImage(file);
            resolve(imgUrl);
          } catch (error) {
            reject(error);
          } finally {
            store.commit(SET_RESOURCE_UPLOAD_IN_PROGRESS, false);
          }
        })
    );
  }

  /**
   * Aborts the upload process.
   *
   * @see module:upload/filerepository~UploadAdapter#abort
   * @returns {Promise}
   */
  abort() {
    return new Promise.reject('Not implemented!');
  }

  /**
   * Creates and polls for an image resource.
   *
   * @returns {Promise}
   */
  async _uploadAndPollImage(img) {
    let resource;

    try {
      resource = await store.dispatch(CREATE_FILE_RESOURCE, {
        file: img,
        type: UPLOAD_TYPES.image,
        title: img.name,
        ownerIds: [store.getters.currentUserId]
      });
    } catch (error) {
      return Promise.reject(error);
    }

    if (resource.file.state === 'ok') {
      return Promise.resolve({ default: resource.file.path });
    }

    try {
      resource = await store.dispatch(POLL_FILE_RESOURCE, {
        resourceSlug: resource.slug
      });
      return Promise.resolve({ default: resource.file.path });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
