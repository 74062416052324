// These are "resourec types" supported by our API for user uploads
// They are weird, let's be honest. They're not file extensions, they're not MIME types, just a set of keywords, really
// Depending on the keyword a resource would be treated differently on resource detail page etc.
// For example a resource tile would have a specific icon for 'image' type and a different one for 'pdf', stuff like that`
export const UPLOAD_TYPES = {
  pdf: 'pdf',
  document: 'document',
  image: 'image'
};

// This is a structure for FE side of things that is a mappping of file extensions to MIME types,
// together with a mapping to upload type supported be the API (see above)
// Everything else is derived from this structure
export const MEDIA_TYPES = [
  {
    mime: 'application/pdf',
    uploadType: UPLOAD_TYPES.pdf,
    extensions: ['pdf']
  },
  {
    mime: 'application/vnd.oasis.opendocument.text',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['odt']
  },
  {
    mime: 'application/vnd.oasis.opendocument.presentation',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['odp']
  },
  {
    mime: 'application/msword',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['doc']
  },
  {
    mime:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['docx']
  },
  {
    mime: 'application/vnd.ms-powerpoint',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['ppt']
  },
  {
    mime:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    uploadType: UPLOAD_TYPES.document,
    extensions: ['pptx']
  },
  {
    mime: 'image/jpeg',
    uploadType: UPLOAD_TYPES.image,
    extensions: ['jpg', 'jpeg']
  },
  {
    mime: 'image/png',
    uploadType: UPLOAD_TYPES.image,
    extensions: ['png']
  }

  // GIF is not supported by API, errors with "unsupported image format, supported formats are [.jpg .jpeg .png]"

  // {
  //   mime: 'image/gif',
  //   uploadType: UPLOAD_TYPES.image,
  //   extensions: ['gif']
  // }
];

export const FILE_EXTENSIONS = MEDIA_TYPES.reduce((memo, item) => {
  const extensionsMap = new Map(item.extensions.map(ext => [ext, ext]));
  return { ...memo, ...Object.fromEntries(extensionsMap) };
}, {});

export const MIME_TYPES_BY_FILE_EXTENSION = Object.values(
  FILE_EXTENSIONS
).reduce((memo, ext) => {
  const foundMediaType = MEDIA_TYPES.find(mType =>
    mType.extensions.includes(ext)
  );

  if (!foundMediaType) return memo;

  return { ...memo, ...{ [ext]: foundMediaType.mime } };
}, {});

export const UPLOAD_STEP = {
  waiting: 'waiting',
  uploading: 'uploading',
  processing: 'processing',
  error: 'error',
  success: 'success'
};

export const FILENAME_MAX_LENGTH = 200;
