<template>
  <div :class="$style.wrapper">
    <template v-if="displaySuccess">
      <div :class="$style.success">
        <i class="icon icon-check-circle"></i>
        <h1 data-cy="success_header">
          Your issue has been submitted!
        </h1>
        <p>A member of our Support Team will be in touch with you shortly.</p>
        <p>Thank you!</p>
      </div>
    </template>
    <template v-else>
      <div :class="$style.header">
        <div>
          <h1>Tell us about it...</h1>
          <p
            v-html="
              'Please describe your technical problem to your best ability. A member of our Tech Support team will be in touch with you as soon as possible.'
            "
          />
        </div>

        <img :src="require(`@/assets/images/icons/report_problem.svg`)" />
      </div>
      <FormGroup>
        <FormLabel>
          <p>
            What are you trying to do?
            <span
              v-if="contextValid === false"
              :class="$style.validation"
              data-cy="context_not_valid"
              >{{ contextValidationMessage }}</span
            >
          </p>

          <FormTextarea
            v-model="context"
            rows="5"
            data-ref="context"
            :class="$style.context"
            data-cy="report_problem_context"
            placeholder="Please explain the steps you took before you encountered the issue. The more information you provide the better."
          ></FormTextarea>
        </FormLabel>
      </FormGroup>
      <FormGroup>
        <FormLabel>
          <p>
            What is stopping you from completing the intended task?
            <span
              v-if="issueValid === false"
              :class="$style.validation"
              data-cy="issue_not_valid"
              >{{ issueValidationMessage }}</span
            >
          </p>

          <FormTextarea
            v-model="issue"
            rows="5"
            :class="$style.issue"
            data-cy="report_problem_issue"
            placeholder="Please describe what your expectations were regarding this functionality."
          ></FormTextarea>
        </FormLabel>
      </FormGroup>

      <Notice
        type="error"
        v-if="errorMessage"
        :class="$style.errorMessage"
        data-cy="report_problem_errors"
        contentStyle="padding-right: 28px"
        :dismissable="true"
        @dismiss="handleErrorDismiss"
      >
        {{ errorMessage }}
      </Notice>

      <FormButton
        @click="handleSubmit"
        :standard="true"
        :class="$style.submit"
        :disabled="isAtWork"
        data-cy="report_problem_submit"
        >Submit Report</FormButton
      >
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { GET_USER_INFO, REPORT_PROBLEM } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { getResponse } from '@/helpers/errors';

import Notice from '@/components/notices/Notice/Notice';

export default {
  name: 'ReportProblem',
  components: { Notice },
  props: {
    displaySuccess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      context: '',
      contextValid: undefined,
      contextValidationMessage: 'Please fill this area.',
      issue: '',
      issueValid: undefined,
      issueValidationMessage: 'Please fill this area.',
      userInfo: null,
      errorMessage: '',
      isAtWork: false
    };
  },
  watch: {
    context(newValue) {
      this.contextValid = newValue.length ? true : false;
    },
    issue(newValue) {
      this.issueValid = newValue.length ? true : false;
    },
    canSubmit(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        // Some of the inputs was not valid but it got corrected
        this.isAtWork = false;
      }
    }
  },
  computed: {
    canSubmit() {
      return this.issueValid === true && this.contextValid === true;
    }
  },
  methods: {
    async handleSubmit() {
      this.isAtWork = true;
      this.contextValid = this.context.length ? true : false;
      this.issueValid = this.issue.length ? true : false;

      if (this.contextValid && this.issueValid) {
        let debugInfo = {
          context: this.context,
          issue: this.issue,
          location: document.URL,
          current_user: this.userInfo,
          browser_time: moment().toISOString(),
          user_agent: navigator.userAgent
        };

        try {
          await this.$store.dispatch(REPORT_PROBLEM, debugInfo);
          this.$emit('success');
          this.contextValid = undefined;
          this.issueValid = undefined;
          this.context = '';
          this.issue = '';
          this.isAtWork = false;
        } catch (error) {
          this.isAtWork = false;
          this.handleError(error);
        }
      }
    },
    handleErrorDismiss() {
      this.errorMessage = '';
    },
    handleError(error) {
      const response = getResponse(error);

      if (response) {
        // Errors from HTTP Response
        const responseData = response.data;
        const responseMessage = `${response.status} ${response.statusText}: ${responseData.message}`;

        switch (response.status) {
          // case 412:
          //   this.errorMessage = responseMessage;
          //   break;
          default:
            this.errorMessage = responseMessage;
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      } else {
        // Other errors like CORS when no request made and thus no response
        this.errorMessage = error;
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  },
  async created() {
    try {
      this.userInfo = await this.$store.dispatch(GET_USER_INFO);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  },
  mounted() {
    const foundRef = this.$el.querySelector('[data-ref=context]');

    if (foundRef) {
      foundRef.focus();
    }
  }
};
</script>

<style lang="scss" module>
@import './ReportProblem.scss';
</style>
