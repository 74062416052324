/* auth */
export const CHECK_AUTH = 'CHECK_AUTH';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';

/* conversations */
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const POLL_MESSAGES = 'POLL_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const GET_UNREAD_COUNT = 'GET_UNREAD_COUNT';
export const SCHEDULE_MARK_AS_READ = 'SCHEDULE_MARK_AS_READ';
export const OPEN_CONVERSATION = 'OPEN_CONVERSATION';
export const OPEN_PLACEHOLDER_CONVERSATION = 'OPEN_PLACEHOLDER_CONVERSATION';

/* users */
export const SEARCH_USER_PROFILES = 'SEARCH_USER_PROFILES';
export const SEARCH_COHORTS = 'SEARCH_COHORTS';
export const GET_USER_PROFILES = 'GET_USER_PROFILES';
export const GET_BULK_USER_PROFILES = 'GET_BULK_USER_PROFILES';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const MAYBE_GET_USER_PROFILE = 'MAYBE_GET_USER_PROFILE';
export const MAYBE_GET_USER_PROFILES = 'MAYBE_GET_USER_PROFILES';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_CURRENT_USER_PROFILE_DATA = 'GET_CURRENT_USER_PROFILE_DATA';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const STORE_CURRENT_USER_CONSENTS = 'STORE_CURRENT_USER_CONSENTS';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const GET_CURRENT_USER_PROFILE = 'GET_CURRENT_USER_PROFILE';
export const GET_CURRENT_USER_COHORTS = 'GET_CURRENT_USER_COHORTS';
export const SELECT_CURRENT_USER_COHORT = 'SELECT_CURRENT_USER_COHORT';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const POLL_AVATAR = 'POLL_AVATAR';
export const DELETE_AVATAR = 'DELETE_AVATAR';
export const SEND_USER_WELCOME_EMAIL = 'SEND_USER_WELCOME_EMAIL';

/* teams */
export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAM_PROFILE = 'GET_TEAM_PROFILE';
export const UPDATE_TEAM_PROFILE = 'UPDATE_TEAM_PROFILE';
export const GET_TEAM_STREAM_EVENTS = 'GET_TEAM_STREAM_EVENTS';
export const GET_TEAM_ROOM = 'GET_TEAM_ROOM';
export const GET_NEWROW_AUTH_TOKEN = 'GET_NEWROW_AUTH_TOKEN';
export const GET_INTEGRATION_TOKENS = 'GET_INTEGRATION_TOKENS';
export const GET_COLLABORATION_SERVICES_TOKEN =
  'GET_COLLABORATION_SERVICES_TOKEN';

/* domain */
export const GET_DOMAIN_COUNTRIES = 'GET_DOMAIN_COUNTRIES';
export const GET_DOMAIN = 'GET_DOMAIN';

/* schedule */
export const GET_MODULE_PHASES = 'GET_MODULE_PHASES';
export const GET_MODULES_PHASES = 'GET_MODULES_PHASES';
export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_TEMP = 'GET_MODULES_TEMP';
export const GET_SESSION_ASSIGNMENTS = 'GET_SESSION_ASSIGNMENTS';
export const GET_SESSION_GRADES = 'GET_SESSION_GRADES';
export const GET_GROUP_GRADES = 'GET_GRADES_GRADES';
export const GET_SCHEDULE_UPCOMING = 'GET_SCHEDULE_UPCOMING';
export const GET_SCHEDULE_WEEK_CURRENT = 'GET_SCHEDULE_WEEK_CURRENT';
export const GET_SCHEDULE_WEEK = 'GET_SCHEDULE_WEEK';
export const GET_SCHEDULED_ASSIGNMENT = 'GET_SCHEDULED_ASSIGNMENT';
export const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS';
export const GET_ASSIGNMENT_QUIZ_STATS = 'GET_ASSIGNMENT_QUIZ_STATS';
export const GET_CURRENT_PHASE_ASSIGNMENT = 'GET_CURRENT_PHASE_ASSIGNMENT';
export const SET_VIEW_AS_ACTION = 'SET_VIEW_AS_ACTION';
export const POST_ASSIGNMENT_NOTE_RESOURCES = 'POST_ASSIGNMENT_NOTE_RESOURCES';
export const POST_ASSIGNMENT_NOTE_TEXT = 'POST_ASSIGNMENT_NOTE_TEXT';
export const POST_ASSIGNMENT_NOTE_PUBLISH = 'POST_ASSIGNMENT_NOTE_PUBLISH';
export const DELETE_ASSIGNMENT_NOTE_RESOURCES =
  'DELETE_ASSIGNMENT_NOTE_RESOURCES';

/* common */
export const GET_USERS_STREAM_EVENTS = 'GET_USERS_STREAM_EVENTS';
export const GET_CALENDAR_URL = 'GET_CALENDAR_URL';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const HIDE_ANNOUNCEMENTS = 'HIDE_ANNOUNCEMENTS';

/* blog */
export const GET_BLOG_POSTS = 'GET_BLOG_POSTS';
export const GET_BLOG_POST = 'GET_BLOG_POST';
export const GET_BLOG_POST_TAGS = 'GET_BLOG_POST_TAGS';

/* meta */
export const GET_LOCALE = 'GET_LOCALE';

/* discussions */
export const GET_DISCUSSION_CATEGORIES = 'GET_DISCUSSION_CATEGORIES';
export const GET_DISCUSSION_CATEGORY_FORUMS = 'GET_DISCUSSION_CATEGORY_FORUMS';
export const GET_DISCUSSION_FORUM = 'GET_DISCUSSION_FORUM';
export const GET_DISCUSSION_FORUM_TOPICS = 'GET_DISCUSSION_FORUM_TOPICS';
export const CREATE_NEW_DISCUSSION_FORUM_TOPIC =
  'CREATE_NEW_DISCUSSION_FORUM_TOPIC';
export const GET_DISCUSSION_TOPIC = 'GET_DISCUSSION_TOPIC';
export const SUBSCRIBE_DISCUSSION_TOPIC = 'SUBSCRIBE_DISCUSSION_TOPIC';
export const UNSUBSCRIBE_DISCUSSION_TOPIC = 'UNSUBSCRIBE_DISCUSSION_TOPIC';
export const GET_DISCUSSION_TOPIC_POSTS = 'GET_DISCUSSION_TOPIC_POSTS';
export const SEND_DISCUSSION_TOPIC_POST = 'SEND_DISCUSSION_TOPIC_POST';
export const GET_DISCUSSION_RECENT_POSTS = 'GET_DISCUSSION_RECENT_POSTS';

export const GET_NEW_DISCUSSIONS_TOPIC_ID = 'GET_NEW_DISCUSSIONS_TOPIC_ID';
export const GET_NEW_DISCUSSIONS_TOPIC_POSTS =
  'GET_NEW_DISCUSSIONS_TOPIC_POSTS';
export const DELETE_NEW_DISCUSSIONS_TOPIC_POST =
  'DELETE_NEW_DISCUSSIONS_TOPIC_POST';
export const POST_NEW_DISCUSSIONS_CONTENT = 'POST_NEW_DISCUSSIONS_CONTENT';
export const GET_NEW_DISCUSSIONS_REACTIONS = 'GET_NEW_DISCUSSIONS_REACTIONS';
export const POST_NEW_DISCUSSIONS_REACTION = 'POST_NEW_DISCUSSIONS_REACTION';

/* faq */
export const GET_FAQ_TOPIC_QUESTIONS = 'GET_FAQ_TOPIC_QUESTIONS';
export const GET_FAQ_TOPICS = 'GET_FAQ_TOPICS';

/* resources */
export const GET_RESOURCE = 'GET_RESOURCE';
export const POLL_FILE_RESOURCE = 'POLL_FILE_RESOURCE';
export const CREATE_FILE_RESOURCE = 'CREATE_FILE_RESOURCE';
export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCES_COUNT = 'GET_RESOURCES_COUNT';
export const GET_RESOURCE_GROUPS = 'GET_RESOURCE_GROUPS';
export const GET_RESOURCE_GROUP = 'GET_RESOURCE_GROUP';
export const GET_RESOURCES_LOGICAL_TYPES = 'GET_RESOURCES_LOGICAL_TYPES';
export const DELETE_RESOURCE_FEEDBACK = 'DELETE_RESOURCE_FEEDBACK';
export const GET_RESOURCE_FEEDBACK = 'GET_RESOURCE_FEEDBACK';

export const GET_RESOURCE_BOOKMARKS = 'GET_RESOURCE_BOOKMARKS';
export const GET_RESOURCE_PROVIDERS = 'GET_RESOURCE_PROVIDERS';
export const DELETE_RESOURCE_BOOKMARK = 'DELETE_RESOURCE_BOOKMARK';
export const POST_RESOURCE_BOOKMARK = 'POST_RESOURCE_BOOKMARK';

export const GET_LEARNING_UNITS = 'GET_LEARNING_UNITS';
export const GET_LEARNING_UNIT = 'GET_LEARNING_UNIT';
export const GET_LEARNING_OBJECTIVES = 'GET_LEARNING_OBJECTIVES';
export const GET_LEARNING_CATEGORIES = 'GET_LEARNING_CATEGORIES';

/* assessments */
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_SUBMISSION = 'GET_SUBMISSION';
export const SAVE_GRADING = 'SAVE_GRADING';
export const REMOVE_GRADING = 'REMOVE_GRADING';
export const PUBLISH_GRADING = 'PUBLISH_GRADING';
export const CHECK_MISSING_MANDATORY_VALUES = 'CHECK_MISSING_MANDATORY_VALUES';
export const RESET_MANDATORY_VALUES_CHECK = 'RESET_MANDATORY_VALUES_CHECK';

/* settings */
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_SETTING = 'GET_USER_SETTING';
export const CHANGE_USER_SETTING = 'CHANGE_USER_SETTING';

/* kvstore */
export const GET_USER_KVSTORE = 'GET_USER_KVSTORE';
export const PATCH_USER_KVSTORE = 'PATCH_USER_KVSTORE';

/* reports */
export const REPORT_ROUTE = 'REPORT_ROUTE';
export const REPORT_PROBLEM = 'REPORT_PROBLEM';

/* gradebook */
export const GET_USER_GRADES = 'GET_USER_GRADES';
export const GET_CURRENT_USER_GRADES = 'GET_CURRENT_USER_GRADES';
export const GET_BULK_ASSIGNMENTS = 'GET_BULK_ASSIGNMENTS';

/* evaluation item */
export const SAVE_EVALUATION_ITEM = 'SAVE_EVALUATION_ITEM';
export const SUBMIT_GROUPWORK = 'SUBMIT_GROUPWORK';
export const GET_CURRENT_GROUP_WORK_ASSIGNMENT_ID =
  'GET_CURRENT_GROUP_WORK_ASSIGNMENT_ID';
export const GET_MODULE_GROUP_WORKS = 'GET_MODULE_GROUP_WORKS';

/* admin */
export const GET_ADMIN_STUDENTS = 'GET_ADMIN_STUDENTS';
export const GET_ADMIN_STUDENT_DETAIL = 'GET_ADMIN_STUDENT_DETAIL';
export const GET_ADMIN_COHORTS = 'GET_ADMIN_COHORTS';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const GET_ADMIN_USER_DETAIL = 'GET_ADMIN_USER_DETAIL';
export const GET_ADMIN_COHORT_DETAIL = 'GET_ADMIN_COHORT_DETAIL';
export const GET_ADMIN_FEEDBACK_STATUS = 'GET_ADMIN_FEEDBACK_STATUS';
export const GET_ADMIN_OPERATION_STATUS = 'GET_ADMIN_OPERATION_STATUS';
export const REOPEN_ADMIN_OPERATION = 'REOPEN_ADMIN_OPERATION';
export const GET_PENDING_OPERATIONS = 'GET_PENDING_OPERATIONS';
export const APPROVE_OPERATION = 'APPROVE_OPERATION';
export const REJECT_OPERATION = 'REJECT_OPERATION';
export const GET_APPROVED_OPERATIONS = 'GET_APPROVED_OPERATIONS';
export const GET_REJECTED_OPERATIONS = 'GET_REJECTED_OPERATIONS';
export const GET_ADMIN_GROUP_DETAIL = 'GET_ADMIN_GROUP_DETAIL';
export const ADMIN_GROUP_DETAIL_REMOVE_USER = 'ADMIN_GROUP_DETAIL_REMOVE_USER';
export const ADMIN_GROUP_DETAIL_REMOVE_GROUP =
  'ADMIN_GROUP_DETAIL_REMOVE_GROUP';
export const GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS =
  'GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS';
export const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
export const GET_ADMINISTRATIVE_USER_GROUPS = 'GET_ADMINISTRATIVE_USER_GROUPS';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_NEW_COHORT = 'CREATE_NEW_COHORT';
export const GET_ADMIN_USER_ROLES = 'GET_ADMIN_USER_ROLES';
export const ADMIN_GROUP_DETAIL_CREATE_GROUP =
  'ADMIN_GROUP_DETAIL_CREATE_GROUP';
export const ADMIN_RESET_PASSWORD = 'ADMIN_RESET_PASSWORD';
export const UPDATE_ADMIN_USER_DETAIL = 'UPDATE_ADMIN_USER_DETAIL';
export const GET_ADMIN_STATISTICS_USERS = 'GET_ADMIN_STATISTICS_USERS';
export const GET_ADMIN_STATISTICS_STUDENTS = 'GET_ADMIN_STATISTICS_STUDENTS';
export const GET_ADMIN_STATISTICS_COHORTS = 'GET_ADMIN_STATISTICS_COHORTS';
export const GET_GRADING_EVALUATION_STATUS = 'GET_GRADING_EVALUATION_STATUS';
export const GET_ADMIN_USER_EMAILS = 'GET_ADMIN_USER_EMAILS';
export const ADMIN_FAQ_TOPICS = 'ADMIN_FAQ_TOPICS';
export const ADMIN_FAQ_QUESTIONS = 'ADMIN_FAQ_QUESTIONS';
export const ADMIN_CREATE_FAQ_QUESTIONS = 'ADMIN_CREATE_FAQ_QUESTIONS';
export const ADMIN_UPDATE_FAQ_QUESTION = 'ADMIN_UPDATE_FAQ_QUESTION';
export const GET_ADMIN_BLOG_DRAFTS = 'GET_ADMIN_BLOG_DRAFTS';
export const GET_ADMIN_BLOG_PUBLISHED = 'GET_ADMIN_BLOG_PUBLISHED';
export const GET_ADMIN_BLOG_DETAIL = 'GET_ADMIN_BLOG_DETAIL';
export const CREATE_ADMIN_BLOG = 'CREATE_ADMIN_BLOG';
export const PUBLISH_ADMIN_BLOG = 'PUBLISH_ADMIN_BLOG';
export const UNPUBLISH_ADMIN_BLOG = 'UNPUBLISH_ADMIN_BLOG';
export const UPDATE_ADMIN_BLOG_DETAIL = 'UPDATE_ADMIN_BLOG_DETAIL';
export const ASSIGN_COHORTS_ADMIN_BLOG_DETAIL =
  'ASSIGN_COHORTS_ADMIN_BLOG_DETAIL';
export const DELETE_COHORTS_ADMIN_BLOG_DETAIL =
  'DELETE_COHORTS_ADMIN_BLOG_DETAIL';
export const DELETE_ADMIN_BLOG = 'DELETE_ADMIN_BLOG';
export const UPDATE_TAG_ADMIN_BLOG_DETAIL = 'UPDATE_TAG_ADMIN_BLOG_DETAIL';
export const GET_ADMIN_MODULES = 'GET_ADMIN_MODULES';
export const GET_ADMIN_CATEGORIES = 'GET_ADMIN_CATEGORIES';
export const GET_ADMIN_MODULE_DETAIL = 'GET_ADMIN_MODULE_DETAIL';
export const GET_ADMIN_SPECS = 'GET_ADMIN_SPECS';
export const GET_ADMIN_OVERVIEW = 'GET_ADMIN_OVERVIEW';
export const UPDATE_ADMIN_USER_ROLE = 'UPDATE_ADMIN_USER_ROLE';
export const UPLOAD_ADMIN_AVATAR = 'UPLOAD_ADMIN_AVATAR';
export const DELETE_ADMIN_AVATAR = 'DELETE_ADMIN_AVATAR';
export const GET_ADMIN_ASSIGNMENT_NOTES = 'GET_ADMIN_ASSIGNMENT_NOTES';
export const GET_ADMIN_ASSIGNMENT_NOTE_DETAIL =
  'GET_ADMIN_ASSIGNMENT_NOTE_DETAIL';
export const GET_ADMIN_RESOURCES_PENDING = 'GET_ADMIN_RESOURCES_PENDING';
export const GET_ADMIN_RESOURCES_APPROVED = 'GET_ADMIN_RESOURCES_APPROVED';
export const GET_ADMIN_RESOURCES_REJECTED = 'GET_ADMIN_RESOURCES_REJECTED';
export const GET_ADMIN_RECOURCE_PENDING_DETAIL =
  'GET_ADMIN_RECOURCE_PENDING_DETAIL';
export const SET_ADMIN_RECOURCE_PENDING_DETAIL =
  'SET_ADMIN_RECOURCE_PENDING_DETAIL';
export const UPDATE_ADMIN_MODULE_DETAIL_SETTINGS =
  'UPDATE_ADMIN_MODULE_DETAIL_SETTINGS';
export const CREATE_ADMIN_MODULE = 'CREATE_ADMIN_MODULE';
export const GET_ADMIN_POSTS = 'GET_ADMIN_POSTS';
