<template>
  <div :class="$style.wrapper">
    <FormButtonGroup :class="$style.sortButtons">
      <FormButton
        :ghostFilter="true"
        :class="{ [$style.selected]: students, [$style.button]: true }"
        class="theme-color"
        @click="changeRoute('students')"
      >
        Students</FormButton
      >
      <FormButton
        :ghostFilter="true"
        :class="{ [$style.selected]: !students, [$style.button]: true }"
        class="theme-color"
        @click="changeRoute('staff')"
      >
        EDU Staff</FormButton
      >
    </FormButtonGroup>

    <div :class="$style.search" v-if="isCurrentUserTeachingStaff">
      <div data-ref="text" :class="$style.text">
        <i class="icon icon-search" :class="$style.iconSearch" />
        <FormInput
          data-ref="search"
          v-model="searchTerm"
          :large="true"
          type="text"
          :withBorder="false"
          placeholder="Search by name..."
        />
        <AvatarSelect
          :class="$style.avatarSelect"
          v-click-outside="hideDropdown"
          v-if="showDropdown"
          :users="filterUsers"
          :searchRegExp="searchRegExp"
          size="40px"
          @select="goToProfile"
        />
        <div :class="{ [$style.loader]: true, [$style.on]: isSearching }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const DEBOUNCE_WAIT_MS = 400;
import debounce from 'lodash.debounce';
import { SEARCH_USER_PROFILES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import AvatarSelect from '@/components/common/AvatarSelect/AvatarSelect';
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside
  },
  components: {
    AvatarSelect
  },
  data() {
    return {
      students: true,
      searchTerm: '',
      isSearching: false,
      searchRegExp: undefined,
      showAvatarSelect: false,
      users: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff']),
    showDropdown() {
      return (
        !this.isSearching && this.showAvatarSelect && this.searchTerm !== ''
      );
    },
    filterUsers() {
      if (!Array.isArray(this.users)) return [];

      const usersExclCurrent = this.users.filter(
        user => user.user_id !== this.currentUserId
      );

      return usersExclCurrent;
    }
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm) {
        this.searchRegExp = undefined;
        this.users = [];
      }

      this.searchRegExp = new RegExp(newSearchTerm, 'ig');
      this.searchUsers(newSearchTerm);
    }
  },
  methods: {
    changeRoute(status) {
      if (status == 'students') {
        this.students = true;
        return this.$router.push({ name: 'students' }).catch(() => {});
      } else {
        this.students = false;
        return this.$router.push({ name: 'staff' }).catch(() => {});
      }
    },
    goToProfile(userId) {
      return this.$router
        .push({
          name: 'user-profile',
          params: {
            userId: userId
          }
        })
        .catch(() => {});
    },
    searchUsers: debounce(function(searchTerm) {
      this.isSearching = true;
      this.showAvatarSelect = true;
      this.$store
        .dispatch(SEARCH_USER_PROFILES, { inactive: true, name: searchTerm })
        .then(users => {
          this.users = users;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.isSearching = false;
        });
    }, DEBOUNCE_WAIT_MS),
    hideDropdown() {
      this.showAvatarSelect = false;
    }
  },
  mounted() {
    this.changeRoute(this.$route.name);

    this.$router.currentRoute.name == 'staff'
      ? (this.students = false)
      : (this.students = true);
  }
};
</script>

<style lang="scss" module>
@import './UsersListBy.scss';
</style>
