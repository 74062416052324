<template>
  <DocumentCard v-if="!resource" :isEmpty="true" />
  <DocumentCard v-else :class="$style.card">
    <header>
      <img
        v-svg-inline
        :src="require(`@/assets/images/icons/document.svg`)"
        :class="$style.icon"
      />

      <span>
        <IconButton
          v-if="currentMode === MODES.EDIT"
          @click="$emit('remove_upload')"
        >
          <i class="icon icon-remove"></i>
        </IconButton>
      </span>
    </header>

    <main>
      <section :class="$style.primary">
        <p>
          <strong>{{ resourceTitleOrFileName }}</strong>
        </p>
        <!-- <p :class="$style.small">TODO: file_size</p> -->
      </section>

      <section
        :class="{
          [$style.secondary]: true,
          [$style.withAttempts]: submission
        }"
      >
        <template v-if="submission">
          <p :class="$style.small">
            <UserName :userId="submission.resource_user_id" />
          </p>
          <p :class="$style.small" data-ignore="snapshot">
            {{ submission && submission.created_at | format }}
          </p>
          <p :class="$style.small">#&nbsp;{{ submission.atttempt_no }}</p>
        </template>
        <template v-else>
          <p :class="$style.small">
            <UserName
              :userId="resource && resource.owner_ids && resource.owner_ids[0]"
            />
          </p>
          <p :class="$style.small" data-ignore="snapshot">
            {{ uploadFeedbackFile && uploadFeedbackFile.created_at | format }}
          </p>
        </template>
      </section>
    </main>
    <footer>
      <router-link
        :to="{
          name: 'pdf-view',
          params: {
            resourceSlug: resourceSlug
          }
        }"
        target="_blank"
      >
        <img
          v-svg-inline
          :src="require(`@/assets/images/icons/view.svg`)"
        />&nbsp;View
      </router-link>

      <a :href="resourceFilepath" download>
        <img
          v-svg-inline
          :src="require(`@/assets/images/icons/download.svg`)"
        />&nbsp;Download
      </a>
    </footer>
  </DocumentCard>
</template>

<script>
import moment from 'moment';
import DocumentCard from './DocumentCard';
import IconButton from './IconButton';
import { MODES } from '@/config/grading';

export default {
  name: 'GradingCard',
  components: {
    DocumentCard,
    IconButton
  },
  props: {
    currentMode: {
      type: String,
      default: MODES.VIEW
    },
    resource: {
      type: Object
    },
    submission: {
      type: Object
    },
    uploadFeedbackFile: {
      type: Object
    }
  },
  filters: {
    format(dateISOString) {
      if (dateISOString) {
        const m_date = moment(dateISOString);
        if (m_date.isValid()) {
          return m_date.format('DD.MM.YYYY, HH:mm');
        }
      }

      return '';
    }
  },
  computed: {
    resourceTitleOrFileName() {
      if (!this.resource) return '';

      const resourceTitle = this.resource.title;

      const resourceFileName =
        this.resource.file && this.resource.file.name
          ? this.resource.file.name
          : undefined;

      return resourceTitle ? resourceTitle : resourceFileName;
    },
    resourceFilepath() {
      return ['development', 'production'].includes(process.env.NODE_ENV)
        ? this.resource.file.path
        : 'dummy-resource-file-path';
    },
    resourceSlug() {
      return ['development', 'production'].includes(process.env.NODE_ENV)
        ? this.resource.slug
        : 'dummy-resource-slug';
    }
  },
  created() {
    this.MODES = MODES;
  }
};
</script>

<style lang="scss" module>
@import '~@/assets/css/generated-variables.scss';

.icon {
  width: 40px;
  height: 50px;
  margin-bottom: 10px;
}

.icon svg:focus {
  outline: none;
}

.card {
  display: inline-grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;

  header,
  main,
  footer {
    width: 170px;
  }

  header,
  footer {
    display: flex;
    justify-content: space-between;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  footer {
    border-top: 1px solid $primaryColor;
    padding-top: 10px;
  }

  .small {
    font-size: 12px;
  }
}

.primary,
.secondary {
  overflow-x: hidden;
}

.with-attempts {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-row: auto auto;

  p:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
}
</style>
