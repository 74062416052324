<template>
  <div :class="$style.assessments">
    <Card
      v-if="unitQuizzes.length === 1"
      :data-cy="`card-flexible-${unitQuizzes[0].slug}`"
      @card_click="onAssessmentCardClick(unitQuizzes[0])"
    >
      <template #content>
        <p
          class="text-semibold paragraph-large"
          v-line-clamp:3="1.2"
          :title="unitQuizzes[0].name"
        >
          {{ unitQuizzes[0].name }}
        </p>
        <p class="paragraph-small">
          Multiple choice
        </p>
      </template>
    </Card>

    <splide :options="options" v-else-if="unitQuizzes.length > 1">
      <splide-slide v-for="(item, index) in unitQuizzes" :key="index">
        <Card
          :data-cy="`card-flexible-${item.slug}`"
          @card_click="onAssessmentCardClick(item)"
        >
          <template #content>
            <p
              class="text-semibold paragraph-large"
              v-line-clamp:3="1.2"
              :title="item.name"
            >
              {{ item.name }}
            </p>
            <p class="paragraph-small">
              Multiple choice
            </p>
          </template>
        </Card>
      </splide-slide>
    </splide>

    <Card v-else :data-cy="`card-flexible-empty`" :isEmpty="true" />

    <ModalNew
      :showModal="showModal"
      v-transfer-dom
      @close="onModalClose"
      @before_destroy="onModalClose"
    >
      <template v-if="quizId">
        <Quiz :quizId="quizId" :currentTime="null" @close="onModalClose" />
      </template>
      <template v-else>
        <Notice :type="'error'" :dismissable="false">
          <template #icon>
            <i class="icon icon-exclamation-triangle" />
          </template>
          <p class="text-semibold">Assessment {{ quizId }} not found</p>
        </Notice>
      </template>
    </ModalNew>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { SET_GLOBAL_ERROR, RESET_STATE } from '@/store/mutations.type';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

import Notice from '@/components/notices/Notice/Notice';
import Quiz from '@/views/Assessments/RepeatableMultiChoiceQuiz/Quiz';
import Card from '@/components/common/Card/Card';

export default {
  name: 'RelatedAssessmentsDesktop',
  components: {
    Notice,
    Quiz,
    Card,
    Splide,
    SplideSlide
  },
  props: {
    unitQuizzes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false,
      quizId: undefined,
      options: {
        padding: 50,
        gap: 100,
        pagination: false,
        perPage: 1
      }
    };
  },
  computed: {
    ...mapGetters(['currentUserProfile'])
  },
  methods: {
    ...mapMutations('quiz', [RESET_STATE]),
    async onAssessmentCardClick(item) {
      if (!item) return;

      this.quizId = item.quiz_id;
      this.showModal = true;
    },
    onModalClose() {
      this.showModal = false;
      this.RESET_STATE();
      this.quizId = undefined;
    }
  },
  created() {
    this.systemLanguage =
      this.currentUserProfile && this.currentUserProfile.system_language
        ? this.currentUserProfile.system_language
        : 'en';
  }
};
</script>

<style lang="scss" module>
@import './RelatedAssessments.scss';
</style>
