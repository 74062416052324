<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <router-link
            :to="backLink"
            class="btn standard"
            :class="$style.backButton"
            ><i class="icon icon-chevron-left"
          /></router-link>
          <h6>{{ groupDetail.object_name }}</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <!-- Generate table with cohorts for students OR without cohorts for administrative users -->
        <vue-good-table
          v-if="groupDetail.cohort"
          :columns="columnsStudents"
          :rows="rows"
          :class="$style.table"
          :sort-options="{
            enabled: false
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'userGroupId'">
              <p class="adminPrimaryId">{{ props.row.userGroupId }}</p>
            </span>
            <span v-else-if="props.column.field === 'teamId'">
              <p class="adminPrimaryId">{{ props.row.teamId }}</p>
            </span>
            <span v-else-if="props.column.field === 'cohortName'">
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.cohortId }
                }"
              >
                {{ props.row.cohortName }}
                <p class="adminSecondaryId">{{ props.row.cohortId }}</p>
              </router-link>
            </span>
          </template>
        </vue-good-table>
        <vue-good-table
          v-else
          :columns="columnsAdministrative"
          :rows="rows"
          :class="$style.table"
          :sort-options="{
            enabled: false
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'userGroupId'">
              <p class="adminPrimaryId">{{ props.row.userGroupId }}</p>
            </span>
            <span v-else-if="props.column.field === 'teamId'">
              <p class="adminPrimaryId">{{ props.row.teamId }}</p>
            </span>
          </template>
        </vue-good-table>
        <AdminGroupDetailUsers
          :class="$style.students"
          :groupType="groupDetail.group_type"
          :userGroupId="groupDetail.user_group_id"
          :groupMembers="groupDetail.members"
          @reloadMembers="fetchGroupDetail($route.params.userGroupId)"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import { VueGoodTable } from 'vue-good-table';

import { GET_ADMIN_GROUP_DETAIL } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import AdminGroupDetailUsers from '@/views/Admin/AdminGroupDetail/AdminGroupDetailUsers';

export default {
  components: {
    Layout,
    VueGoodTable,
    AdminGroupDetailUsers
  },
  data() {
    return {
      display404: false,
      groupDetail: [],
      columnsStudents: [
        {
          label: 'User Group Id',
          field: 'userGroupId'
        },
        {
          label: 'Team ID',
          field: 'teamId'
        },
        {
          label: 'Group name',
          field: 'name'
        },
        {
          label: 'Group type',
          field: 'type'
        },
        {
          label: 'Cohort name',
          field: 'cohortName'
        },
        {
          label: 'External ID',
          field: 'externalID'
        }
      ],
      columnsAdministrative: [
        {
          label: 'User Group Id',
          field: 'userGroupId'
        },
        {
          label: 'Group ID',
          field: 'teamId'
        },
        {
          label: 'Group name',
          field: 'name'
        },
        {
          label: 'Group type',
          field: 'type'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff']),
    backLink() {
      if (
        this.groupDetail &&
        this.groupDetail.cohort &&
        this.groupDetail.cohort.cohort_id
      ) {
        return '/admin/cohorts/' + this.groupDetail.cohort.cohort_id;
      } else {
        return '/admin/user_groups';
      }
    }
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchGroupDetail(this.$route.params.userGroupId);
    }
  },
  methods: {
    fetchGroupDetail(userGroupId) {
      this.$store
        .dispatch(GET_ADMIN_GROUP_DETAIL, userGroupId)
        .then(groupDetail => {
          this.groupDetail = groupDetail;
        })
        .then(() => {
          this.createGroupRow();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createGroupRow() {
      this.rows = [];

      this.rows.push({
        teamId: this.groupDetail.object_id || '',
        name: this.groupDetail.object_name || '',
        type: this.groupDetail.group_type || '',
        cohortId:
          (this.groupDetail.cohort && this.groupDetail.cohort.cohort_id) || '',
        cohortName:
          (this.groupDetail.cohort && this.groupDetail.cohort.name) || '',
        userGroupId: this.groupDetail.user_group_id || '',
        externalID: this.groupDetail.external_id || ''
      });
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminGroupDetail.scss';
</style>
