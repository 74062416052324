<template>
  <div :class="$style.wrapper" v-if="nowISO && containers && containers.length">
    <h6 class="main-title" :class="{ [$style.marginBottom]: currentPhaseName }">
      My Schedule
      <span :class="$style.week" v-if="isCurrentUserStudent">{{
        currentPhaseName
      }}</span>
    </h6>

    <div
      :class="$style.containers"
      :style="{
        gridTemplateColumns: `repeat(${containersTotalLength}, 1fr)`
      }"
      data-cy="minischedule-containers"
    >
      <MiniScheduleContainer
        v-for="(container, containerIndex) in containers"
        :class="$style.container"
        :key="containerIndex"
        :container="container"
        :slots="getSlotsNo(container)"
        :days="getDaysNo(container)"
        :isEmpty="isEmpty(container)"
        class="overflow-visible"
      >
        <template
          v-if="getSlotsNo(container) === 1 && getDaysNo(container) === 1"
        >
          <template v-if="!isEmpty(container)">
            <MiniScheduleDays
              class="box-shadow-white overflow-visible"
              :container="container"
              :nowISO="nowISO"
              :isLoading="isLoading"
            />
          </template>
          <template v-else>
            <MiniScheduleDaysEmpty
              class="box-shadow-white overflow-visible"
              :container="container"
              :isLoading="isLoading"
            />
          </template>
        </template>

        <template
          v-else-if="getSlotsNo(container) > 1 && getDaysNo(container) === 1"
        >
          <template v-if="!isEmpty(container)">
            <MiniScheduleDays
              class="box-shadow-white overflow-visible"
              :container="container"
              :nowISO="nowISO"
              :isLoading="isLoading"
            />
          </template>
          <template v-else>
            <!-- Debug 1 -->
          </template>
        </template>

        <template
          v-else-if="getSlotsNo(container) > 0 && getDaysNo(container) > 1"
        >
          <template v-if="!isEmpty(container)">
            <!-- Debug 2 -->
          </template>
          <template v-else>
            <MiniScheduleDaysSpanEmpty
              :container="container"
              :isLoading="isLoading"
            />
          </template>
        </template>

        <template v-else>
          <!-- Debug 3 -->
        </template>
      </MiniScheduleContainer>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import {
  GET_SCHEDULE_UPCOMING,
  GET_SCHEDULE_WEEK_CURRENT
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { FALLBACK_LANGUAGE } from '@/config/locale';
import { CYCLE_TYPES } from '@/config/schedule';
import { getWeekType, getDayType } from '@/helpers/schedule';

import MiniScheduleContainer from '@/views/MiniSchedule/MiniScheduleContainer';
import MiniScheduleDays from '@/views/MiniSchedule/MiniScheduleDays';
import MiniScheduleDaysEmpty from '@/views/MiniSchedule/MiniScheduleDaysEmpty';
import MiniScheduleDaysSpanEmpty from '@/views/MiniSchedule/MiniScheduleDaysSpanEmpty';

export default {
  name: 'MiniSchedule',
  components: {
    MiniScheduleContainer,
    MiniScheduleDays,
    MiniScheduleDaysEmpty,
    MiniScheduleDaysSpanEmpty
  },
  props: {
    nowISO: {
      type: String,
      required: true
    }
  },
  watch: {
    nowISO(newNow) {
      const m_now = moment(newNow);
      const m_firstLessonEnd = moment(this.firstLessonEnd);

      if (!m_now.isValid() || !m_firstLessonEnd.isValid()) return;

      if (m_now.isSameOrAfter(m_firstLessonEnd)) {
        this.fetchData();
      }
    }
  },
  data() {
    return {
      upcomingEvents: [],
      weekData: {
        calendar_week_id: undefined,
        weeks: []
      },
      isLoading: true,
      hasError: false
    };
  },
  computed: {
    ...mapGetters([
      'currentPhaseSlug',
      'phaseName',
      'isPhoneLayout',
      'isTabletLayout',
      'isCurrentUserStudent',
      'isCurrentUserTeachingStaff'
    ]),
    maxRecords() {
      return this.isTabletLayout ? 3 : 4;
    },
    maxDayRecords() {
      return this.isTabletLayout ? 3 : 4;
    },
    currentPhaseName() {
      return this.phaseName(this.currentPhaseSlug(this.nowISO));
    },
    containersTotalLength() {
      if (this.isPhoneLayout) return 1;

      return Object.values(this.containers).reduce((memo, container) => {
        return memo + this.getSlotsNo(container);
      }, 0);
    },
    upcomingDays() {
      return Array(this.maxDayRecords)
        .fill(0)
        .reduce((memo, value, index) => {
          void value;
          index === 0
            ? memo.push(this.toWorkDay(this.nowISO))
            : memo.push(
                this.toWorkDay(
                  moment(memo[index - 1])
                    .add(1, 'days')
                    .toISOString(true)
                )
              );

          return memo;
        }, []);
    },
    upcommingLessons() {
      return this.upcomingDays.reduce((memo, dayISO) => {
        memo[dayISO] = this.getLessonsForDay(dayISO);

        return memo;
      }, {});
    },
    firstLessonEnd() {
      if (!this.upcomingEvents) return undefined;

      if (!this.upcomingEvents.length) return undefined;

      const firstLesson = this.upcomingEvents[0];

      return firstLesson.end_at;
    },
    weeks() {
      return (this.weekData && this.weekData.weeks) || [];
    },
    currentWeekId() {
      return (this.weekData && this.weekData.calendar_week_id) || undefined;
    },
    nextWeekId() {
      const foundIndex = this.weeks.findIndex(
        week => week.calendar_week_id === this.currentWeekId
      );

      if (foundIndex === -1) return undefined;

      const lastIndex = this.weeks.length - 1;

      const nextIndex = foundIndex === lastIndex ? lastIndex : foundIndex + 1;
      const found = this.weeks[nextIndex];

      return found && found.calendar_week_id
        ? found.calendar_week_id
        : undefined;
    },
    currentWeekType() {
      return getWeekType(this.weeks, this.currentWeekId);
    },
    nextWeekType() {
      return getWeekType(this.weeks, this.nextWeekId);
    },
    miniSchedule() {
      let totalItems = 0;
      let dayItems = 0;

      return Object.entries(this.upcommingLessons).reduce((memo, day) => {
        const dayISO = day[0];
        const dayLessons = day[1];

        // If we're already maxed-out, just return whatever we collected so far
        if (totalItems >= this.maxRecords) return memo;

        // If there's no key for current dayISO, let's create one
        if (!memo[dayISO]) {
          memo[dayISO] = [];
        }

        // If the daiISO has no lessons increment the total
        if (dayLessons.length === 0) {
          totalItems++;
        } else {
          dayLessons.map(lesson => {
            // If we're already maxed out, skip the lesson
            if (totalItems >= this.maxRecords) return;
            // If max for a day is reached, skip the lesson
            if (dayItems >= this.maxDayRecords) return;
            memo[dayISO].push(lesson);
            totalItems++;
            dayItems++;
          });
        }

        // Reset day lessons counter
        dayItems = 0;

        return memo;
      }, {});
    },
    containers() {
      if (!Object.keys(this.miniSchedule).length) return [];
      // if (!this.currentWeekType) return [];
      // if (!this.nextWeekType) return [];

      return Object.entries(this.miniSchedule).reduce(
        (memo, item, index) => {
          const dayISO = item[0];
          const data = item[1];
          const nextDayISO = Object.keys(this.miniSchedule)[index + 1];
          const nextDayType = this.getDayType(nextDayISO);

          if (index === 0) {
            // First item
            memo[memo.length - 1].type = this.getDayType(dayISO);
            memo[memo.length - 1].days[dayISO] = data;

            if (
              !this.isStackable(dayISO, nextDayType, nextDayISO) ||
              this.isPhoneLayout
            ) {
              memo.push({
                type: nextDayType,
                days: {}
              });
            }
          } else if (index === Object.keys(this.miniSchedule).length - 1) {
            // Last item
            memo[memo.length - 1].days[dayISO] = data;
          } else {
            // Items in between
            memo[memo.length - 1].days[dayISO] = data;

            if (
              !this.isStackable(dayISO, nextDayType, nextDayISO) ||
              this.isPhoneLayout
            ) {
              memo.push({
                type: nextDayType,
                days: {}
              });
            }
          }

          return memo;
        },
        [
          {
            type: undefined,
            days: {}
          }
        ]
      );
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
    this.fetchData();
  },
  methods: {
    toWorkDay(dayISO) {
      if (!dayISO) return undefined;

      const day = moment(dayISO);

      if (!day.isValid()) return undefined;

      if (day.isoWeekday() === 6) {
        return day.add(2, 'days').toISOString(true);
      }

      if (day.isoWeekday() === 7) {
        return day.add(1, 'days').toISOString(true);
      }

      return day.toISOString(true);
    },
    isStackable(dayISO, nextDayType, nextDayISO) {
      const dayType = this.getDayType(dayISO);

      if (!nextDayType) return false;
      if (!dayType) return false;

      const isStackable =
        [CYCLE_TYPES.flex, CYCLE_TYPES.cr, CYCLE_TYPES.holiday].includes(
          dayType
        ) &&
        dayType === nextDayType &&
        this.miniSchedule[dayISO].length === 0 &&
        this.miniSchedule[nextDayISO].length === 0;

      return isStackable;
    },
    isEmpty(container) {
      if (!container) return undefined;

      return Object.keys(container.days).reduce((memo, dayISO) => {
        if (memo === false) return memo;

        return !container.days[dayISO].length > 0;
      }, undefined);
    },
    async fetchData() {
      try {
        this.isLoading = true;

        this.weekData = await this.$store.dispatch(GET_SCHEDULE_WEEK_CURRENT, {
          personal: this.isCurrentUserTeachingStaff
        });
        this.upcomingEvents = await this.$store.dispatch(GET_SCHEDULE_UPCOMING);

        this.isLoading = false;
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        this.hasError = true;
        this.isLoading = false;
        return;
      }
    },
    getLessonsForDay(dayISO) {
      const m_dayStart = moment(dayISO).startOf('day');
      const m_dayEnd = moment(dayISO).endOf('day');

      if (!Array.isArray(this.upcomingEvents)) return [];

      const lessons = this.upcomingEvents.filter(event => {
        const m_event_start = moment(event.start_at);
        const m_event_end = moment(event.end_at);

        if (!m_event_start.isValid() || !m_event_end.isValid()) return false;

        // Filter out assignments of group_work kind
        if (event.kind === 'group_work') return false;

        // Check if an event takes place during dayISO, maybe even part of it
        const isEventWithinDay =
          m_event_start.isBetween(m_dayStart, m_dayEnd, undefined, '[]') ||
          m_event_end.isBetween(m_dayStart, m_dayEnd, undefined, '[]');

        return isEventWithinDay;
      });

      return lessons;
    },
    getWeekType(weekId) {
      return getWeekType(this.weekData.weeks, weekId);
    },
    getDayType(dayISO) {
      return getDayType(dayISO, this.currentWeekType, this.nextWeekType);
    },
    getDaysNo(container) {
      if (!container) return 0;

      return Object.keys(container.days).length;
    },
    getSlotsNo(container) {
      if (!container) return 0;

      if (this.isPhoneLayout) return 1;

      return Object.keys(container.days).reduce((memo, dayISO) => {
        const dayLength = container.days[dayISO].length
          ? container.days[dayISO].length
          : 1;
        return memo + dayLength;
      }, 0);
    }
  }
};
</script>

<style lang="scss" module>
@import './MiniSchedule.scss';
@import './WeekTypeFlex.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>
