<template>
  <div :class="$style.wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<style lang="scss" module>
@import './FormButtonGroup.scss';
</style>
