<template>
  <div :class="$style.wrapper">
    <FormLabel
      v-for="option in options"
      :key="option[optionIdAttrName(option)]"
      :class="{
        [$style.option]: true,
        [$style.disabled]: disabled,
        correct: isCorrect(option),
        incorrect: isIncorrect(option)
      }"
    >
      <FormCheckbox
        :class="{
          [$style.input]: true,
          [$style.gold]: useFallbackResultsColor
        }"
        v-model="selectedValues"
        :value="option[optionIdAttrName(option)]"
        :disabled="disableOptions(option) ? true : null"
      />
      <p>
        {{ option[optionContentAttrName(option)] }}
      </p>
    </FormLabel>
  </div>
</template>

<script>
import { getSelectedOptionsIds } from '@/helpers/common';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    maxChoices: {
      type: Number,
      default: -1 // unlimited
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styleAsResults: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.selectedValues = getSelectedOptionsIds(this.options);
  },
  data() {
    return {
      selectedValues: []
    };
  },
  watch: {
    selectedValues: {
      handler() {
        this.$emit('input', this.selectedValues);
      },
      deep: true
    },
    value() {
      this.selectedValues = this.value;
    },
    options: {
      handler(newOptions) {
        this.selectedValues = getSelectedOptionsIds(newOptions);
      },
      deep: true
    }
  },
  computed: {
    areCorrectAnswersIndicated() {
      if (!this.options || !this.options.length) return undefined;

      const found = this.options.find(option => option.is_correct === true);

      return found ? true : false;
    },
    useFallbackResultsColor() {
      return this.styleAsResults && this.areCorrectAnswersIndicated
        ? false
        : true;
    }
  },
  methods: {
    optionIdAttrName(option) {
      return option.hasOwnProperty('option_id') ? 'option_id' : 'id';
    },
    optionContentAttrName(option) {
      return option.hasOwnProperty('answer') ? 'answer' : 'content';
    },
    disableOptions(option) {
      if (this.disabled) return true;

      if (this.maxChoices !== -1) {
        return (
          this.selectedValues.length + 1 > this.maxChoices &&
          this.selectedValues.indexOf(option.id) === -1
        );
      }
    },
    isCorrect(option) {
      if (this.areCorrectAnswersIndicated === false) return undefined;

      if (this.styleAsResults) {
        if (!option) return undefined;

        if (!Object.keys(option).includes('is_correct')) return undefined;

        if (!this.styleAsResults) return undefined;

        if (typeof option.is_correct === 'boolean') {
          return option.is_correct;
        }

        return undefined;
      }
    },
    isIncorrect(option) {
      if (!this.styleAsResults) return undefined;

      if (this.areCorrectAnswersIndicated === false) return undefined;

      if (option && option.selected && this.isCorrect(option) === true) {
        return false;
      }

      if (option && option.selected && this.isCorrect(option) === false) {
        return true;
      }

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import '../ExamAnswerSingleChoice/ExamAnswerSingleChoice.scss';
</style>
