import { state } from './state';
import { removeDuplicateObjByKey } from '@/helpers/common';
import { CONVERSATION_TYPES, PLACEHOLDER_ID } from '@/config/conversations';

export const getters = {
  unreadCount(state) {
    return state.unreadCount;
  },
  conversations(state, getters) {
    if (getters.currentConversationId === PLACEHOLDER_ID) {
      const placeholderConversation = {
        conversation_id: PLACEHOLDER_ID,
        type: state.placeholder_conversation.type,
        unread: 0,
        participant_ids: state.placeholder_conversation.participantIds,
        participants: state.placeholder_conversation.participants,
        object_id: state.placeholder_conversation.object_id
      };

      return [placeholderConversation, ...state.conversations];
    }

    return state.conversations;
  },
  currentConversationId(state) {
    return state.current_conversation.id;
  },
  currentConversationMessages(state) {
    return removeDuplicateObjByKey(state.current_conversation.messages, 'id');
  },
  currentConversation(state, getters) {
    let currentConversation = getters.conversations.find(conversation => {
      return conversation.conversation_id === getters.currentConversationId;
    });

    if (currentConversation === undefined) {
      return null;
    }

    return currentConversation;
  },
  conversationsWithParticipants: state => (
    participant_ids = [],
    type = CONVERSATION_TYPES.ADHOC
  ) => {
    if (participant_ids.length > 1) {
      return state.conversations
        .filter(conversation => conversation.type === type)
        .filter(conversation => {
          if (!conversation || !conversation.participants) {
            return false;
          }

          const participantIds = conversation.participants.map(
            participant => participant.user_id
          );

          // are participant_ids and participantIds equal?
          return (
            participantIds.length === participant_ids.length &&
            participantIds
              .sort()
              .every((value, index) => value === participant_ids.sort()[index])
          );
        });
    }

    return [];
  },

  conversationsWithTeam: state => (teamId = null) => {
    if (teamId) {
      return state.conversations.filter(
        conversation =>
          conversation.type === CONVERSATION_TYPES.TEAM &&
          conversation.object_id === teamId
      );
    }

    return [];
  },

  canStartTeamConversation: (state, getters) => teamSlug => {
    if (getters.currentUserProfile && getters.currentUserProfile.teaching_staff)
      return true;

    if (
      getters.currentUserTeamSlugs &&
      getters.currentUserTeamSlugs.includes(teamSlug)
    )
      return true;

    return false;
  },

  isReadyToStart(state) {
    return state.is_ready_to_start;
  },

  usersSearchResults(state) {
    return state.users_search_results;
  },

  selectedParticipantIds(state) {
    return state.selected_participant_ids;
  }
};
