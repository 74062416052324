import axios from 'axios';
import ApiService from '@/api/api.service';

import {
  GET_NEW_DISCUSSIONS_TOPIC_ID,
  GET_NEW_DISCUSSIONS_TOPIC_POSTS,
  POST_NEW_DISCUSSIONS_CONTENT,
  GET_NEW_DISCUSSIONS_REACTIONS,
  POST_NEW_DISCUSSIONS_REACTION,
  DELETE_NEW_DISCUSSIONS_TOPIC_POST
} from '@/store/actions.type';

export const actions = {
  [GET_NEW_DISCUSSIONS_REACTIONS](context, { objectType, objectId }) {
    if (!objectType) return Promise.reject('No objectType. Action aborted.');
    if (!objectId) return Promise.reject('No objectId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.getReactions(objectType, objectId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_NEW_DISCUSSIONS_REACTION](context, { objectType, objectId, payload }) {
    if (!objectType) return Promise.reject('No objectType. Action aborted.');
    if (!objectId) return Promise.reject('No objectId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.postReaction(objectType, objectId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_NEW_DISCUSSIONS_TOPIC_ID](context, { objectType, objectId }) {
    if (!objectType) return Promise.reject('No objectType. Action aborted.');
    if (!objectId) return Promise.reject('No objectId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.getNewDiscussionsTopicId(objectType, objectId)
        .then(({ data }) => {
          const keys = Object.keys(data);

          if (keys.length === 1 && keys[0] === 'topic_id') {
            resolve(data.topic_id);
          } else {
            resolve(data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_NEW_DISCUSSIONS_TOPIC_POSTS](context, { topicId, since }) {
    if (!topicId) return Promise.reject('No topicId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.getNewDiscussionsTopicPosts(topicId, since)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_NEW_DISCUSSIONS_TOPIC_POST](context, { topicId, postId }) {
    if (!topicId) return Promise.reject('No topicId. Action aborted.');
    if (!postId) return Promise.reject('No postId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.deleteNewDiscussionsTopicPost(topicId, postId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_NEW_DISCUSSIONS_CONTENT](
    context,
    { topicId, content, parentPostId = null }
  ) {
    if (!topicId) return Promise.reject('No topicId. Action aborted.');
    if (!content) return Promise.reject('No content. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.submitNewDiscussionsPost(topicId, content, parentPostId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
