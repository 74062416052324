<template>
  <div class="Library" :class="$style.wrapper">
    <Layout variant="7">
      <template #pageHero>
        <span id="scroll"></span>
        <ResourcesFilter
          :modules="modulesAndPhases"
          :types="types"
          :logicalTypes="resourcesLogicalTypes"
          :units="definedLearningUnits"
          :objectives="definedLearningObjectives"
          :filters="filters"
          @update="onUpdateFilters"
          @clear="onClearFilters"
        />
      </template>

      <template
        v-if="stateContainsFilters && resources && resources.length"
        #pageContent
      >
        <h6 :class="$style.title" class=" main-title">
          MATCHING CONTENT {{ foundResources }}
          <inlineLoader :class="$style.loader" v-if="isLoading" />
        </h6>
        <div :class="$style.cardsWrapper">
          <template v-for="(resource, index) in resources">
            <div :key="index">
              <IntersectionTrigger
                v-if="index % resourcesPerLoad === 0"
                @trigerredEnter="onOffsetMarkSeen(index)"
                :key="`offset-${index}`"
                :class="$style.trigger"
              />
              <ResourceCard
                :class="$style.card"
                :resource="resource"
                :key="index"
              />
            </div>
          </template>
        </div>
        <div style="clear: both;">
          <CircleLoader v-if="isLoadingMore" />
        </div>
        <div
          v-if="isEndOfScroll"
          :class="$style.endOfScroll"
          class="placeholder"
        >
          <p>All results displayed.</p>
          <p>
            Haven't found what you're looking for? Try adjusting your search
            criteria!
          </p>

          <FormButton :ghost="true" @click="scrollTop">Go to Top</FormButton>
        </div>
        <IntersectionTrigger @trigerredEnter="loadMore" />
      </template>

      <template v-else #pageContent>
        <h6 :class="$style.title" class=" main-title" v-if="isLoading">
          MATCHING CONTENT {{ foundResources }}
          <inlineLoader :class="$style.loader" v-if="isLoading" />
        </h6>
        <div
          v-if="!stateContainsFilters && nonFeaturedResourceGroups.length"
          :class="$style.content"
        >
          <LibraryProviders />
          <ResourcesSlider
            v-show="resourceBookmarks && resourceBookmarks.length"
            :resourceGroup="{
              name: 'My List',
              resources: resourceBookmarks
            }"
          />
          <!-- Resource Group -->
          <ResourcesSlider
            v-for="resourceGroup in nonFeaturedResourceGroups"
            :key="resourceGroup.code"
            :resourceGroup="resourceGroup"
          />
        </div>
        <div v-else>
          <div
            v-if="!stateContainsFilters && !isLoading"
            :class="$style.placeholder"
            class="placeholder"
          >
            <p>
              No resources to show for the current week. Please use the filters
              to search for resources.
            </p>
            <div :class="$style.placeholderBox">
              <div v-for="index in 4" :key="index" />
            </div>
          </div>
          <div
            v-if="stateContainsFilters && !isLoading"
            :class="$style.placeholder"
          >
            <div :class="$style.placeholderBox">
              <div v-for="index in 8" :key="index" />
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import { arraysEqual } from '@/helpers/common';

import {
  GET_LEARNING_UNITS,
  GET_LEARNING_OBJECTIVES,
  GET_MODULES_PHASES,
  GET_RESOURCE_GROUPS,
  GET_RESOURCES,
  GET_RESOURCES_COUNT,
  GET_RESOURCE_BOOKMARKS,
  GET_RESOURCES_LOGICAL_TYPES
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import IntersectionTrigger from '@/components/common/IntersectionTrigger/IntersectionTrigger';
import CircleLoader from '@/components/common/CircleLoader/CircleLoader';
import ResourcesFilter from '@/components/resources/ResourcesFilter/ResourcesFilter';
import ResourcesSlider from '@/components/resources/ResourcesSlider/ResourcesSlider';
import ResourceCard from '@/components/resources/ResourceCard/ResourceCard';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import LibraryProviders from '@/components/common/LibraryProviders/LibraryProviders';

export default {
  components: {
    Layout,
    ResourceCard,
    ResourcesFilter,
    ResourcesSlider,
    IntersectionTrigger,
    CircleLoader,
    InlineLoader,
    LibraryProviders
  },
  data() {
    return {
      offsetFromQuery: undefined,
      showFilterDropdown: false,
      isLoading: false,
      isLoadingMore: false
    };
  },
  computed: {
    ...mapGetters([
      'nonFeaturedResourceGroups',
      'resourceBookmarks',
      'modulesPhases',
      'resourcesLogicalTypes'
    ]),
    ...mapGetters('library', [
      'resources',
      'resourcesCount',
      'resourcesPerLoad',
      'filters',
      'loadParams',
      'loadMoreParams',
      'stateContainsFilters',
      'offset',
      'since',
      'definedLearningUnits',
      'definedLearningObjectives'
    ]),
    canLoadMore() {
      if (this.resourcesCount && this.resources.length < this.resourcesCount) {
        return true;
      }

      return false;
    },
    foundResources() {
      if (!this.isLoading && Number.isInteger(this.resourcesCount)) {
        return ` - FOUND ${this.resourcesCount}`;
      }

      return '';
    },
    types() {
      return [
        { slug: 'link', name: 'Link' },
        { slug: 'document', name: 'Document' }
      ];
    },
    modulesAndPhases() {
      if (!Array.isArray(this.modulesPhases)) return [];

      const phasesPerModule = this.modulesPhases.reduce(
        (accumulator, modulePhase) => {
          if (!accumulator[modulePhase.module_name]) {
            accumulator[modulePhase.module_name] = [];
          }

          accumulator[modulePhase.module_name].push(modulePhase);
          return accumulator;
        },
        {}
      );

      return Object.entries(phasesPerModule).map(moduleEntry => {
        const moduleName = moduleEntry[0];
        const modulePhases = moduleEntry[1];

        return {
          module_name: moduleName,
          phases: modulePhases.map(modulePhase => {
            return {
              slug: modulePhase.slug,
              name: modulePhase.phase_name
            };
          })
        };
      });
    },
    isEndOfScroll() {
      return this.resources.length > 0 && !this.canLoadMore;
    }
  },
  watch: {
    offset(newOffset) {
      this.updateQueryOffset(newOffset);
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch(GET_RESOURCES_LOGICAL_TYPES);
      if (!this.definedLearningUnits.length) {
        await this.GET_LEARNING_UNITS();
      }
      if (!this.definedLearningObjectives.length) {
        await this.GET_LEARNING_OBJECTIVES();
      }
      this.isLoading = false;
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      this.isLoading = false;
      return;
    }

    const query = this.$route.query;
    const queryFilters = this.queryToFiltersObject(query);
    const queryHasFilters = this.queryContainsFilters(queryFilters);

    this.isLoading = true;
    if (queryHasFilters === true) {
      return this.$store
        .dispatch(GET_MODULES_PHASES)
        .then(() => this.createSearchResults(queryHasFilters, queryFilters))
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.isLoading = false;
        });
    }

    if (queryHasFilters === false) {
      return this.$store
        .dispatch(GET_RESOURCE_BOOKMARKS)
        .then(() => this.$store.dispatch(GET_RESOURCE_GROUPS))
        .then(() => this.$store.dispatch(GET_MODULES_PHASES))
        .then(() => this.createSearchResults(queryHasFilters, queryFilters))
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.setSince(moment().toISOString(true));
  },
  methods: {
    ...mapActions('library', [GET_LEARNING_UNITS, GET_LEARNING_OBJECTIVES]),
    async getResources(append = false, showLoadingMore = false) {
      let params = !append ? this.loadParams : this.loadMoreParams;

      if (showLoadingMore) {
        this.isLoadingMore = true;
      } else {
        this.isLoading = true;
      }

      if (!append) {
        await this.getResourcesCount();
      }

      this.$store
        .dispatch(GET_RESOURCES, params)
        .then(data => {
          if (append) {
            this.setResources([...this.resources, ...data]);
          } else {
            this.setResources(data);
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          if (showLoadingMore) {
            this.isLoadingMore = false;
          } else {
            this.isLoading = false;
          }
        });
    },
    getResourcesCount() {
      this.$store
        .dispatch(GET_RESOURCES_COUNT, this.loadParams)
        .then(data => {
          if (Number.isInteger(data)) {
            this.setResourcesCount(data);
          } else {
            this.setResourcesCount(undefined);
          }
        })
        .catch(error => {
          this.setResourcesCount(undefined);
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    loadMore() {
      if (this.isLoadingMore || !this.canLoadMore) return;
      const showLoadingMore = true;

      this.getResources(true, showLoadingMore);
    },
    scrollTop() {
      let top = document.querySelector('#scroll').offsetTop;
      window.scrollTo(0, top);
    },
    queryContainsFilters(queryFiltersObject) {
      if (!queryFiltersObject) {
        return undefined;
      }

      if (Object.keys(queryFiltersObject) === ['offset']) {
        return false;
      }

      const {
        phase,
        type,
        logicalType,
        unit,
        objective,
        text
      } = queryFiltersObject;

      return (
        (phase && Object.entries(phase).length > 0) ||
        (type && Object.entries(type).length > 0) ||
        (logicalType && Object.entries(logicalType).length > 0) ||
        (unit && Object.entries(unit).length > 0) ||
        (objective && Object.entries(objective).length > 0) ||
        (text && Object.entries(text).length > 0)
      );
    },
    updateQueryFilters(newFilters) {
      this.$router
        .replace({
          query: {
            phase: newFilters.phase || [],
            type: newFilters.type || [],
            logicalType: newFilters.logicalType || [],
            unit: newFilters.unit || [],
            objective: newFilters.objective || [],
            text: newFilters.text || []
          }
        })
        .catch(() => {});
    },
    updateQueryOffset(newOffset) {
      const newQuery = { ...this.$route.query, ...{ offset: newOffset } };
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    ...mapMutations('library', [
      'setResources',
      'setResourcesCount',
      'setStateInitialFilters',
      'setStateFilters',
      'setStateOffset',
      'setSince'
    ]),
    onOffsetMarkSeen(offset) {
      this.setStateOffset({ offset });
    },
    onUpdateFilters(selectedFilters) {
      this.setStateFilters(selectedFilters);
      this.updateQueryFilters(selectedFilters);
      this.updateSearchResults();
    },
    onClearFilters() {
      this.setStateInitialFilters();
      this.updateQueryFilters({});
      this.setResources([]);
      this.setResourcesCount(undefined);
      this.updateSearchResults();
    },
    createSearchResults(hasQueryFilters, queryFilters) {
      if (!hasQueryFilters) {
        this.setStateFilters(queryFilters);
      } else {
        if (
          this.sameFiltersInQueryAndState(queryFilters, this.filters) === false
        ) {
          this.setStateFilters(queryFilters);
          this.getResources();
        }
      }
    },
    updateSearchResults() {
      if (!this.stateContainsFilters) {
        return;
      }

      this.setStateFilters(this.queryToFiltersObject(this.$route.query));
      this.getResources();
    },
    sameFiltersInQueryAndState(query, filters) {
      if (!query || !filters) return undefined;

      return (
        arraysEqual(query.phase, filters.phase) &&
        arraysEqual(query.type, filters.type) &&
        arraysEqual(query.logicalType, filters.logicalType) &&
        arraysEqual(query.unit, filters.unit) &&
        arraysEqual(query.objective, filters.objective) &&
        arraysEqual(query.text, filters.text)
      );
    },
    queryToFiltersObject(query) {
      let filters = {
        phase: [],
        type: [],
        logicalType: [],
        unit: [],
        objective: [],
        text: []
      };

      return Object.entries(query).reduce((memo, item) => {
        const attribute = item[0];
        const value = item[1];

        if (!Object.keys(filters).includes(attribute)) {
          return memo;
        }

        memo[attribute] = Array.isArray(value) ? value : [value];

        return memo;
      }, filters);
    }
  }
};
</script>

<style lang="scss" module>
@import 'Library.scss';
</style>
