<template>
  <div :class="$style.wrapper">
    <div class="p-2">
      <div :class="$style.title">
        <h6>Chat Notifications</h6>
      </div>
      <span
        :class="$style.centerSetting"
        v-if="userSettingValue(SETTING_CODES.MSG_NOTIFICATION) !== undefined"
      >
        <FormGroup :class="$style.formGroup">
          <FormLabel :class="$style.email">
            <FormCheckbox
              :value="userSettingValue(SETTING_CODES.MSG_NOTIFICATION)"
              :modelValue="userSettingValue(SETTING_CODES.MSG_NOTIFICATION)"
              :disabled="
                isLoading(SETTING_CODES.MSG_NOTIFICATION) ? true : null
              "
              @change="changePreference($event, SETTING_CODES.MSG_NOTIFICATION)"
            />
            E-mail notifications
            <InlineLoader
              :key="SETTING_CODES.MSG_NOTIFICATION"
              v-if="isLoading(SETTING_CODES.MSG_NOTIFICATION)"
            />
          </FormLabel>
        </FormGroup>
      </span>
      <span
        :class="$style.centerSetting"
        v-if="userSettingValue(SETTING_CODES.FIXED_NAV) !== undefined"
      >
        <div :class="$style.title">
          <h6>Preferences</h6>
        </div>
        <FormGroup :class="$style.formGroup">
          <FormLabel>
            <FormCheckbox
              :value="userSettingValue(SETTING_CODES.FIXED_NAV)"
              :modelValue="userSettingValue(SETTING_CODES.FIXED_NAV)"
              :disabled="isLoading(SETTING_CODES.FIXED_NAV) ? true : null"
              @change="changePreference($event, SETTING_CODES.FIXED_NAV)"
            />
            <span
              :title="userSettingValue(SETTING_CODES.FIXED_NAV, 'description')"
              >{{ userSettingValue(SETTING_CODES.FIXED_NAV, 'name') }}</span
            >
            <InlineLoader
              :key="SETTING_CODES.FIXED_NAV"
              v-if="isLoading(SETTING_CODES.FIXED_NAV)"
            />
          </FormLabel>
        </FormGroup>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

import { CHANGE_USER_SETTING, GET_USER_SETTINGS } from '@/store/actions.type';
import { SETTING_CODES } from '@/config/userSettings';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    InlineLoader
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      loading: []
    };
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;
  },
  computed: {
    ...mapGetters(['clientUserProfileErrors', 'userProfileSettings']),
    ...mapGetters(['userSetting', 'userSettingValue', 'userSettings']),
    errorsFor() {
      return path => {
        if (Object.prototype.toString.call(path) === '[object Array]') {
          return this.clientUserProfileErrors.filter(e =>
            path.includes(e.path)
          );
        }

        return this.clientUserProfileErrors.filter(e => e.path === path);
      };
    }
  },
  methods: {
    fetchUserSettingsPromise(params) {
      return this.$store.dispatch(GET_USER_SETTINGS, params).catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    },
    isLoading(sectionKey) {
      return this.loading.includes(sectionKey);
    },
    startLoading(sectionKey) {
      this.loading = [...this.loading, sectionKey];
    },
    endLoading(sectionKey) {
      const found = this.loading.indexOf(sectionKey);

      if (found !== -1) {
        this.loading.splice(found, 1);
      }
    },
    changePreference(newValue, settingCode) {
      this.startLoading(settingCode);
      var newStringValue;

      if (typeof newValue !== 'string') {
        newStringValue = JSON.stringify(newValue);
      } else {
        newStringValue = newValue;
      }

      this.$store
        .dispatch(CHANGE_USER_SETTING, {
          apiCode: settingCode,
          payload: {
            value: newStringValue
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.fetchUserSettingsPromise();

          this.endLoading(settingCode);
        });
    }
  },
  mounted() {
    this.fetchUserSettingsPromise();
  }
};
</script>

<style module lang="scss">
@import './UserSettings.scss';
</style>
