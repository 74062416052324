<script>
const KEYCODE_ARROW_UP = 38;
const KEYCODE_ARROW_DOWN = 40;
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;

export default {
  props: {
    listLength: Number,
    selectedEvent: String,
    focusedEvent: String
  },
  data() {
    return {
      // Index of the list item to display
      // -1 means that no item is selected
      selectedIndex: -1
    };
  },
  created() {
    this.addKeyHandler();
  },
  destroyed() {
    this.removeKeyHandler();
  },
  methods: {
    keyHandler(e) {
      const key = e.which || e.keyCode;

      if (key === KEYCODE_ARROW_UP || (e.shiftKey && key === KEYCODE_TAB)) {
        this.handleKeyUp(e);
      } else if (key === KEYCODE_ARROW_DOWN || key === KEYCODE_TAB) {
        this.handleKeyDown(e);
      } else if (key === KEYCODE_ENTER) {
        this.handleEnter(e);
      }
    },
    addKeyHandler() {
      window.addEventListener('keyup', this.keyHandler);
    },
    removeKeyHandler() {
      window.removeEventListener('keyup', this.keyHandler);
    },
    handleEnter(e) {
      e.preventDefault();
      this.$emit(this.selectedEvent, this.selectedIndex);
    },
    handleKeyUp(e) {
      e.preventDefault();
      const lastIndex = this.listLength - 1;

      // If index is less than or equal to zero
      // This means that we did not start yet
      if (this.selectedIndex <= 0) {
        // Set the index to the last item
        this.selectedIndex = lastIndex;
      } else if (this.selectedIndex > 0 && this.selectedIndex <= lastIndex) {
        // If index is larger than zero and smaller or equal to last index then decrement
        this.selectedIndex--;
      }

      this.$emit(this.focusedEvent, this.selectedIndex);
    },
    handleKeyDown(e) {
      e.preventDefault();
      const lastIndex = this.listLength - 1;

      // Check if index is below 0
      // This means that we did not start yet
      if (this.selectedIndex < 0 || this.selectedIndex === lastIndex) {
        // Set the index to the first item
        this.selectedIndex = 0;
      } else if (this.selectedIndex >= 0 && this.selectedIndex < lastIndex) {
        // If index is larger than or equal to zero and smaller than last index then increment
        this.selectedIndex++;
      }

      this.$emit(this.focusedEvent, this.selectedIndex);
    }
  },
  render(h) {
    // Create a div and render content in the scoped slot as well as pass the selectedIndex
    return h(
      'div',
      this.$scopedSlots.default({ selectedIndex: this.selectedIndex })
    );
  }
};
</script>
