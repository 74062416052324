<template>
  <div :class="$style.wrapper">
    <h6>
      Manage students in this group:
    </h6>
    <p :class="$style.notice" class="placeholder">
      Carefully! Removing or adding a new student is not an operation that
      requires approval!
    </p>
    <vue-good-table
      ref="tableData"
      :columns="columns"
      :rows="rows"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      compactMode
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom'
      }"
    >
      <div slot="table-actions">
        <AdminSearchNewUser
          :addUserGroupId="userGroupId"
          :eligibleUsersGroupId="userGroupId"
          @reloadMembers="reloadMembers"
        />
      </div>
      <template slot="column-filter" slot-scope="props">
        <span
          v-if="props.column.field === 'studentEmail'"
          :class="{ [$style.allEmails]: true, [$style.email]: true }"
          @click="copyAllEmails()"
        >
          <img src="~@/assets/images/icons/two-envelopes.svg" />
          <i
            id="allEmailsCopied"
            :class="$style.isCopied"
            class="icon icon-check"
          />
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'studentId'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.studentId }
            }"
          >
            {{ props.row.studentId }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'studentFirstName'">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.studentId }
            }"
          >
            {{ props.row.studentFirstName }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'studentLastName'">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.studentId }
            }"
          >
            {{ props.row.studentLastName }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'studentGroups'">
          <p v-if="props.row.studentGroups.length">
            <span
              v-for="(group, index) in props.row.studentGroups"
              :key="index"
            >
              <template v-if="index > 0">, </template>
              <router-link
                v-if="group.slug"
                :to="{
                  name: 'admin-group-detail',
                  params: { userGroupId: group.user_group_id }
                }"
              >
                {{ group.object_name }}
                <p class="adminSecondaryId">{{ group.object_id }}</p>
              </router-link>
              <span v-else
                >{{ group.object_name }}
                <p class="adminSecondaryId">{{ group.object_id }}</p>
              </span>
            </span>
          </p>
          <p v-else>-</p>
        </span>
        <span v-else-if="props.column.field === 'studentEmail'">
          <div @click="copyEmail(props.row)" :class="$style.email">
            <i class="icon icon-envelope" />
            <i
              :id="`id-${props.row.studentId}`"
              :class="$style.isCopied"
              class="icon icon-check"
            />
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';

import { VueGoodTable } from 'vue-good-table';
import copy from 'clipboard-copy';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import AdminSearchNewUser from '@/components/admin/AdminSearchNewUser/AdminSearchNewUser';

export default {
  components: {
    VueGoodTable,
    AdminSearchNewUser
  },
  props: {
    cohortMembers: {
      type: Array,
      default: () => []
    },
    userGroupId: {
      type: Number
    },
    cohortId: {
      type: Number
    }
  },
  data() {
    return {
      allEmails: [],
      allGroups: [],
      searchParams: {},
      columns: [
        {
          label: 'User ID',
          field: 'studentId',
          type: 'number',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('studentId')
          }
        },
        {
          label: 'First name',
          field: 'studentFirstName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('studentFirstName')
          }
        },
        {
          label: 'Last name',
          field: 'studentLastName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('studentLastName')
          }
        },
        {
          label: 'Groups',
          field: 'studentGroups',
          sortable: false,
          filterOptions: {
            enabled: true,
            customFilter: false,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('studentGroups')
          }
        },
        {
          label: 'Email',
          field: 'studentEmail',
          width: '60px',
          sortable: false,
          filterOptions: {
            customFilter: true
          }
        }
      ],
      rows: []
    };
  },
  watch: {
    cohortMembers() {
      this.createStudentsRows();
    }
  },
  methods: {
    copyEmail(data) {
      let inputEl = this.$el.querySelector('#id-' + data.studentId);

      copy(data.studentEmail)
        .then(() => {
          this.copied = true;
        })
        .catch(error => {
          logError(error);
          this.copied = false;
        });

      if (document.execCommand('copy') == true) {
        inputEl.style.opacity = '100';
        setTimeout(() => {
          inputEl.style.opacity = '0';
        }, 800);
      }
    },
    copyAllEmails() {
      var dummy = document.createElement('input');
      var allEmails = [];

      // map trough filtered results
      this.$refs.tableData.filteredRows[0].children.map(member => {
        allEmails.push(member.studentEmail);
      });
      var el = this.$el.querySelector('#allEmailsCopied');

      this.$el.appendChild(dummy);
      dummy.value = allEmails.join('; ');
      dummy.select();
      document.execCommand('copy');
      this.$el.removeChild(dummy);

      if (document.execCommand('copy') == true) {
        el.style.opacity = '100';
        setTimeout(() => {
          el.style.opacity = '0';
        }, 800);
      }
    },
    createStudentsRows() {
      this.rows = [];

      if (!this.cohortMembers) return [];

      this.cohortMembers.filter(student => {
        this.rows.push({
          studentId: student.user_id || '',
          studentFirstName: student.first_name || '',
          studentLastName: student.last_name || '',
          studentGroups: student.user_groups || '',
          studentEmail: student.email || ''
        });
      });
    },
    // Check if string matches any group name in the array
    filterGroups(data, filterString) {
      if (data) {
        return data.find(
          group =>
            group.object_name
              .toLowerCase()
              .includes(filterString.toLowerCase()) ||
            group.object_id.toString().includes(filterString)
        );
      }
    },
    onSortChange(sort) {
      var sorting = {
        studentsSort: sort[0].field || [],
        studentsOrder: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({
            ...this.$route.query,
            ...this.searchParams,
            ...sorting
          });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.studentsOrder) {
        return {
          field: this.$route.query.studentsSort,
          type: this.$route.query.studentsOrder
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'studentId':
          return this.$route.query.studentId;
          break;
        case 'studentFirstName':
          return this.$route.query.studentFirstName;
          break;
        case 'studentLastName':
          return this.$route.query.studentLastName;
          break;
        case 'studentGroups':
          return this.$route.query.studentGroups;
          break;
        case 'studentEmail':
          return this.$route.query.studentEmail;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        studentId: column.studentId || [],
        studentFirstName: column.studentFirstName || [],
        studentLastName: column.studentLastName || [],
        studentGroups: column.studentGroups || [],
        studentEmail: column.studentEmail || []
      };

      this.updateQueryFilters({ ...this.$route.query, ...this.searchParams });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    reloadMembers() {
      this.$emit('reloadMembers');
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminCohortDetail.scss';
</style>
