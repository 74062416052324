<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :class="$style.table"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        infoFn: () => {}
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="updateFilters"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'draftTitle'">
          <router-link
            :to="{
              name: 'admin-blog-detail',
              params: { postId: props.row.draftId }
            }"
            >{{ props.row.draftTitle }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'draftId'">
          <p class="adminPrimaryId">{{ props.row.draftId }}</p>
        </span>
        <span v-else-if="props.column.field === 'draftAuthor'">
          <router-link
            v-if="props.row.draftAuthor"
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.draftAuthorId }
            }"
            >{{ props.row.draftAuthor }}
            <span class="adminSecondaryId">{{ props.row.draftAuthorId }}</span>
          </router-link>
          <span v-else>-</span>
        </span>

        <span
          v-else-if="props.column.field === 'draftCohorts'"
          v-line-clamp:1="1"
        >
          <span v-if="props.row.draftCohorts == 'All'">All</span>
          <span v-else v-for="(cohort, i) in props.row.draftCohorts" :key="i">
            <router-link
              :to="{
                name: 'admin-cohort-detail',
                params: { cohortId: cohort[1] }
              }"
              >{{ cohort[0] }}
              <span class="adminSecondaryId">{{ cohort[1] }}</span></router-link
            >
            <span v-if="i != props.row.draftCohorts.length - 1" class="bold"
              >,
            </span></span
          >
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';

import { GET_ADMIN_BLOG_DRAFTS, GET_ADMIN_COHORTS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      columns: [
        {
          label: 'Draft ID',
          field: 'draftId',
          type: 'number',
          width: '95px',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('draftId')
          }
        },
        {
          label: 'Title',
          field: 'draftTitle',
          thClass: 'postTitle',
          tdClass: 'postTitle',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('draftTitle')
          }
        },
        {
          label: 'Tag',
          field: 'draftTag',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('draftTag')
          }
        },
        {
          label: 'Cohorts',
          field: 'draftCohorts',
          tdClass: 'draftCohorts',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('draftCohorts')
          }
        },
        {
          label: 'Created at',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('createdAt')
          }
        },
        {
          label: 'Author',
          field: 'draftAuthor',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('draftAuthor')
          }
        }
      ],
      rows: [],
      drafts: [],
      allCohorts: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.$store
        .dispatch(GET_ADMIN_COHORTS)
        .then(cohorts => {
          this.allCohorts = cohorts;
        })
        .then(() => {
          this.fetchDrafts();
        });
    }
  },
  methods: {
    fetchDrafts() {
      this.$store
        .dispatch(GET_ADMIN_BLOG_DRAFTS)
        .then(drafts => {
          this.drafts = drafts;
        })
        .then(drafts => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {
        field: 'createdAt',
        type: 'desc'
      };
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'draftId':
          return this.$route.query.draftId;
          break;
        case 'draftTitle':
          return this.$route.query.draftTitle;
          break;
        case 'draftTag':
          return this.$route.query.draftTag;
          break;
        case 'draftCohorts':
          return this.$route.query.draftCohorts;
          break;
        case 'createdAt':
          return this.$route.query.createdAt;
          break;
        case 'draftAuthor':
          return this.$route.query.draftAuthor;
          break;
        case 'draftAuthorId':
          return this.$route.query.draftAuthorId;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        draftId: column.draftId || [],
        draftTitle: column.draftTitle || [],
        draftTag: column.draftTag || [],
        draftCohorts: column.draftCohorts || [],
        createdAt: column.createdAt || [],
        draftAuthor: column.draftAuthor || [],
        draftAuthorId: column.draftAuthorId || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    createRows() {
      this.rows = [];

      if (!this.drafts || !this.drafts.length) return [];

      this.drafts.filter(draft => {
        this.rows.push({
          draftId: draft.post_id || '',
          draftTitle: draft.title || '',
          draftTag: (draft.tag_data && draft.tag_data.code) || '-',
          draftCohorts: this.filterCohorts(draft.cohorts) || '',
          createdAt: moment(draft.created_at).format('DD.MM.yyyy HH:mm') || '-',
          draftAuthor: this.authorName(draft),
          draftAuthorId: this.authorId(draft)
        });
      });
    },
    filterCohorts(cohorts) {
      if (!this.allCohorts) return [];
      if (!cohorts) return 'All';

      var res = [];
      this.allCohorts.filter(cohort => {
        if (cohorts && cohorts.includes(cohort.cohort_id)) {
          res.push([cohort.name, cohort.cohort_id]);
        }
      });

      return res;
    },
    authorName(draft) {
      if (draft && draft.author) {
        return draft.author.first_name + ' ' + draft.author.last_name;
      }

      return;
    },
    authorId(draft) {
      if (draft && draft.author) {
        return draft.author.user_id;
      }

      return;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
