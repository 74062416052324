var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Students ("+_vm._s(_vm.totalRecords)+")")]),_c('div',{class:_vm.$style.homeButton},[_c('router-link',{class:_vm.$style.button,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
          enabled: true,
          initialSortBy: _vm.loadUrlWithSorting()
        },"pagination-options":{
          enabled: true,
          infoFn: function (params) { return _vm.setTotalRecords(params.totalRecords); }
        },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.id)+" ")])],1):(props.column.field === 'module')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-module-detail',
                params: { moduleId: props.row.moduleId }
              }}},[_vm._v(" "+_vm._s(props.row.module)+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.moduleId))])])],1):(props.column.field === 'firstName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.firstName)+" ")])],1):(props.column.field === 'lastName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.lastName)+" ")])],1):(props.column.field === 'cohort')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-cohort-detail',
                params: { cohortId: props.row.cohort[0] }
              }}},[_vm._v(" "+_vm._s(props.row.cohort[1])+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.cohort[0]))])])],1):(props.column.field === 'groups')?_c('span',[(props.row.groups.length)?_c('p',_vm._l((props.row.groups),function(group,index){return _c('span',{key:index},[(index > 0)?[_vm._v(", ")]:_vm._e(),(group.object_id)?_c('router-link',{attrs:{"to":{
                    name: 'admin-group-detail',
                    params: { userGroupId: group.user_group_id }
                  }}},[_vm._v(" "+_vm._s(group.object_name)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(group.object_id))])]):_c('span',[_vm._v(_vm._s(group.object_name))])],2)}),0):_c('p',[_vm._v("-")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }