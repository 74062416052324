<template>
  <div data-ref="messagesWrapper" :class="$style.wrapper">
    <div data-ref="messages">
      <template v-for="group in messagesGroups">
        <div
          v-for="(message, messageGroupIndex) in group"
          data-ref="message"
          :key="message.id"
          :class="{
            [$style.message]: true,
            [$style.myMessage]: amIAuthor(message),
            [$style.firstGroupMessage]: messageGroupIndex === 0,
            [$style.groupMessage]:
              messageGroupIndex !== 0 && messageGroupIndex !== group.length - 1,
            [$style.lastGroupMessage]: messageGroupIndex === group.length - 1,
            [$style.mobileNoAvatar]: isMobileLayout
          }"
        >
          <div
            :class="$style.date"
            class="paragraph-small"
            v-if="displayDate(message)"
          >
            <span>{{ getDate(message) }}</span>
          </div>

          <Avatar
            v-if="!isMobileLayout || !amIAuthor(message)"
            :class="$style.avatar"
            :user="message.user_profile"
            size="35px"
          />

          <div :class="$style.messageText">
            <div :class="$style.messageTextWrapper">
              <div
                v-if="
                  conversation &&
                    conversation.participants != null &&
                    conversation.participants.length > 2
                "
                :class="$style.infoGroup"
              >
                <div :class="$style.messageAuthor" class="paragraph-small">
                  <template v-if="amIAuthor(message)">
                    You
                  </template>
                  <template v-else>
                    <UserName :preTitles="true" :user="message.user_profile" />
                  </template>
                </div>
              </div>
              <DoNotTrack>
                <div
                  :class="{
                    [$style.bubble]: true,
                    'theme-bg': amIAuthor(message),
                    [$style.bubbleGray]: !amIAuthor(message)
                  }"
                  v-html="message.message"
                />
              </DoNotTrack>
              <time
                :datetime="message.created_at"
                class="paragraph-small"
                :class="$style.messageTime"
              >
                {{ getTime(message) }}
              </time>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div data-ref="bottom" style="height: 1px;" />
    <div
      v-if="newMessagesAvailable"
      :class="$style.newMessagesAvailable"
      class="paragraph-small"
      @click="scrollDown"
    >
      <i class="icon icon-chevron-down"></i>
      new message(s)
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import Avatar from '@/components/common/User/Avatar';

import { logError } from '@/helpers/errors';

export default {
  components: {
    Avatar
  },
  props: {
    conversation: {
      type: Object
    },
    currentUserId: {},
    messages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      intersectionObserver: undefined,
      shouldScrollToBottom: true,
      mutationsObserver: undefined,
      newMessagesAvailable: false
    };
  },
  watch: {
    shouldScrollToBottom(newValue, oldValue) {
      // When jump-link displayed but user scrolled to bottom without using it
      if (
        this.newMessagesAvailable &&
        oldValue === false &&
        newValue === true
      ) {
        this.newMessagesAvailable = false;
      }
    }
  },
  computed: {
    ...mapGetters(['isMobileLayout']),
    messagesGroups() {
      if (!this.messages) return [];

      const messagesGroups = this.messages.reduce(
        (memo, currentMessage, currentMessageIndex, allMessages) => {
          const lastGroupIndex = memo.length - 1;

          const currentMessageUserId = currentMessage.user_profile.user_id;
          const currentMessageCreatedAt = moment(currentMessage.created_at);

          // First message goes straight to first group and memo is returned
          if (currentMessageIndex === 0) {
            memo[lastGroupIndex].push(currentMessage);

            return memo;
          }

          const previousMessageIndex = currentMessageIndex - 1;
          const previousMessage = allMessages[previousMessageIndex];
          const previousMessageUserId = previousMessage.user_profile.user_id;
          const previousMessageCreatedAt = moment(previousMessage.created_at);

          if (
            currentMessageUserId === previousMessageUserId &&
            currentMessageCreatedAt.diff(previousMessageCreatedAt, 'minutes') <=
              5
          ) {
            // If previous message within a timeframe is by the same author
            // then add current message to its group
            memo[lastGroupIndex].push(currentMessage);
          } else {
            // Otherwise create a new group with current message
            let newGroup = [];
            newGroup.push(currentMessage);

            memo.push(newGroup);
          }

          return memo;
        },
        [[]] // Initial contents of memo is an array with one empty group of messages
      );

      return messagesGroups;
    }
  },
  created() {
    this.mutationsObserver = new MutationObserver(this.handleMutation);
  },
  mounted() {
    const messagesWrapper = this.$el.querySelector(
      '[data-ref=messagesWrapper]'
    );
    const messagesList = this.$el.querySelector('[data-ref=messages]');

    if (messagesWrapper && messagesList) {
      this.mutationsObserver.observe(messagesList, {
        attributes: true,
        childList: true,
        subtree: true
      });

      this.intersectionObserver = new IntersectionObserver(
        this.handleIntersection
      );

      this.intersectionObserver.observe(
        this.$el.querySelector('[data-ref=bottom]')
      );
    }

    this.scrollDown();
  },
  beforeDestroy() {
    if (this.mutationsObserver) {
      this.mutationsObserver.disconnect();
    }

    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  },
  methods: {
    displayDate(message) {
      const index = this.messages.findIndex(m => m == message);

      if (
        index == 0 ||
        !moment(message.created_at).isSame(
          moment(this.messages[index - 1].created_at),
          'day'
        )
      ) {
        return true;
      }
    },
    handleMutation(mutationsList, observer) {
      if (this.shouldScrollToBottom) {
        this.scrollDown();
      } else {
        this.newMessagesAvailable = true;
      }
    },
    handleIntersection(entries, observer) {
      if (entries && entries[0]) {
        // If messages bottom marker is visible within wrapper
        // we should keep scrolling to it with new messages
        this.shouldScrollToBottom = entries[0].isIntersecting;
      }
    },
    amIAuthor(message) {
      return this.currentUserId === message.user_profile.user_id;
    },
    getDate(message) {
      if (moment(message.created_at).isSame(moment(), 'day')) {
        return 'Today';
      }

      return moment(message.created_at).format('l');
    },
    getTime(message) {
      return moment(message.created_at).format('HH:mm');
    },
    scrollDown() {
      this.newMessagesAvailable = false;
      this.$el.querySelector('[data-ref=bottom]').scrollIntoView(false); // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    }
  }
};
</script>

<style lang="scss" module>
@import './Messages.scss';
</style>
