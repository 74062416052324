<template>
  <SideWidget :class="$style.wrapper">
    <template #name
      >Key Dates
      <span
        :class="$style.module"
        class="paragraph-normal"
        v-if="isCurrentUserStudent"
        >{{ currentModuleName }}</span
      ></template
    >

    <template #content>
      <div class="box-shadow-white" :class="$style.content">
        Check your Module Outline and Academic Calendar in the
        <a href="https://info.edu.edu.mt/my-studies/" target="_blank">
          My Studies section of the Info Centre
        </a>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import { mapGetters } from 'vuex';

import SideWidget from '@/components/common/SideWidget/SideWidget';

import { GET_MODULE_PHASES, GET_MODULES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget
  },
  computed: {
    ...mapGetters(['modules', 'modulePhases', 'isCurrentUserStudent']),
    currentModuleName() {
      const found =
        this.modulePhases && this.modulePhases.length
          ? this.modulePhases[0].module_name
          : undefined;

      if (found) return found;

      if (!Array.isArray(this.modules)) return '';

      if (this.modules.length === 0) return '';

      const modulesCopy = [...this.modules];

      const fallback = modulesCopy.sort((a, b) =>
        a.module_version_id > b.module_version_id ? 1 : -1
      )[this.modules.length - 1];

      return fallback && fallback.name ? fallback.name : '';
    }
  },
  async created() {
    try {
      await this.$store.dispatch(GET_MODULE_PHASES);
      await this.$store.dispatch(GET_MODULES);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetKeyDates.scss';
</style>
