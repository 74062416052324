<template>
  <DoNotTrack placeholder="👤">
    <span :style="getStyle" class="avatar">
      <img
        src="~@/assets/images/icons/EDU_staff_PP.jpg"
        role="presentation"
        alt="EDU staff"
      />
    </span>
  </DoNotTrack>
</template>

<script>
export default {
  name: 'AvatarEDU',
  props: {
    size: {
      type: String,
      required: false
    }
  },
  computed: {
    getStyle() {
      return `width: ${this.size}; height: ${this.size};`;
    }
  }
};
</script>

<style lang="scss">
@import './Avatar.scss';
</style>
