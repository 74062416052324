<template>
  <div data-cy="day-header">
    <h4 class="uppercase bold">{{ toWeekDay(dayISO) }}</h4>
    <span>{{ toHeaderFormat(dayISO) }}</span>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { FALLBACK_LANGUAGE } from '@/config/locale';

export default {
  name: 'DayHeader',
  props: {
    dayISO: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentUserProfile'])
  },
  methods: {
    toWeekDay(dayISO) {
      const m_dayStartISO = moment(dayISO).startOf('day');
      const m_todayStartISO = moment().startOf('day');
      const m_tomorrowStartISO = moment()
        .startOf('day')
        .add(1, 'day');

      // is dayISO today?
      if (m_dayStartISO.isSame(m_todayStartISO, 'day')) {
        return 'Today';
      }

      // is dayISO tomorrow?
      if (m_dayStartISO.isSame(m_tomorrowStartISO, 'day')) {
        return 'Tomorrow';
      }

      return moment(dayISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('dddd');
    },
    toHeaderFormat(periodStartISO, periodEndISO = undefined) {
      if (periodEndISO) {
        // TODO: Format clinical rotation period
      }

      const m_dayStartISO = moment(periodStartISO).startOf('day');
      const m_todayStartISO = moment().startOf('day');
      const m_tomorrowStartISO = moment()
        .startOf('day')
        .add(1, 'day');

      // is periodStartISO today or tomorrow?
      if (
        m_dayStartISO.isSame(m_todayStartISO, 'day') ||
        m_dayStartISO.isSame(m_tomorrowStartISO, 'day')
      ) {
        return moment(periodStartISO)
          .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
          .format('DD.MM.YYYY, ddd');
      }

      return moment(periodStartISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('DD.MM.YYYY');
    }
  }
};
</script>
