export const MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT'
};

export const GRADING_TYPES = {
  TYPENULL: 'no_grading',
  TYPE1: 'active_participation',
  TYPE2: 'group_work',
  TYPE3: 'attendance',
  TYPE4: 'legacy_attendance',
  TYPE5: 'legacy_group_work'
};

export const SCOPES = {
  user: 'user_id',
  group: 'user_group_id'
};

export const TYPES = {
  attendance: 'attendance',
  score: 'score',
  feedback: 'feedback',
  upload: 'upload'
};

export const SESSION_PROGRESS = {
  DISTANT: 'DISTANT',
  IMMINENT: 'IMMINENT',
  RUNNING: 'RUNNING',
  ENDED: 'ENDED'
};

export const COLUMNS = {
  attendance: 'attendance',
  student: 'student',
  score: 'score',
  comment: 'comment'
};

export const GRADING_PROGRESS = {
  PENDING: 'pending',
  EVALUATED: 'evaluated',
  ALL: 'all'
};

export const EVALUATION_STATUS_DONE = 'done'; // It would be great if API endpoint `/assignments/evaluated` would match `evaluation_status` value of /assignments/arr:id_A,id_B
