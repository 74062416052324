<template>
  <div
    :class="{ [$style[type]]: true, [$style.wrapper]: true }"
    v-if="showNotice"
  >
    <div
      v-if="dismissable"
      data-ref="closeButton"
      :class="$style.closeIcon"
      @click="dismiss"
    >
      <i class="icon" />
    </div>

    <div :class="$style.content" :style="contentStyle">
      <slot name="icon" />
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'none',
      validator: function(value) {
        // The value must match one of these strings
        return ['success', 'warning', 'error', 'info'].indexOf(value) !== -1;
      }
    },
    dismissable: {
      type: Boolean,
      default: true
    },
    contentStyle: {
      type: String,
      default: 'max-width: 532px'
    }
  },
  data() {
    return {
      showNotice: true
    };
  },
  methods: {
    dismiss() {
      this.showNotice = false;
      this.$emit('dismiss');
    }
  }
};
</script>

<style lang="scss" module>
@import './Notice.scss';
</style>
