<template>
  <div :class="$style.wrapper">
    <SessionView>
      <p class="text-uppercase text-align-left paragraph-large text-bold">
        Log in to your account
      </p>

      <p class="pb-2 text-align-left">
        Sign in to unlock the full potential of the platform - start learning
        with your peers today!
      </p>

      <form
        aria-label="Login by e-mail and password"
        @submit.prevent="onSubmit"
      >
        <FormLabel>
          Your EDU e-mail

          <FormInput
            data-cy="email"
            name="email"
            data-ref="email"
            v-model="email"
            :large="true"
            type="text"
          />
        </FormLabel>

        <FormLabel>
          Your password

          <FormInputPassword
            data-cy="password"
            name="password"
            data-ref="password"
            v-model="password"
            :large="true"
          />
        </FormLabel>

        <p class="form-text-additional">
          <router-link :to="{ name: 'initiate-password-reset' }">
            Forgot your password?
          </router-link>
        </p>

        <FormErrors data-cy="errors" :errors="errors" />

        <div :class="$style.button">
          <FormButton :disabled="disableButton ? true : null" :large="true">
            <i class="icon icon-sign-in" />
            Log in
          </FormButton>
        </div>
      </form>
    </SessionView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { logError, hasErrorStatus } from '@/helpers/errors';

import { LOGIN, GET_CURRENT_USER_PROFILE } from '@/store/actions.type';

import focusMixin from '@/mixins/focusMixin';

import SessionView from '@/components/common/SessionView/SessionView';

export default {
  components: {
    SessionView
  },
  mixins: [focusMixin],
  data() {
    return {
      email: '',
      password: '',
      errors: [],
      disableButton: false
    };
  },
  computed: {
    ...mapGetters(['session', 'currentUserAcceptedTerms'])
  },
  methods: {
    async onSubmit() {
      this.errors = [];

      if (this.email === '' || this.password === '') {
        this.errors = ['Please fill out all fields'];
        return;
      }

      this.disableButton = true;

      try {
        await this.$store.dispatch(LOGIN, {
          email: this.email,
          password: this.password
        });

        await this.$store.dispatch(GET_CURRENT_USER_PROFILE);
        if (!this.currentUserAcceptedTerms) {
          this.$router.push({ name: 'terms-and-conditions' }).catch(() => {});
          return;
        }

        if (this.$route.query && this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect).catch(() => {});
        } else if (this.$route.query && this.$route.query.external) {
          const origin = window.location.origin;
          const path = window.location.pathname;
          const external = this.$route.query.external;

          window.location.replace(`${origin}${path}${external}`);
        } else {
          this.$router.push({ name: 'dashboard' }).catch(() => {});
        }
      } catch (error) {
        if (hasErrorStatus(401, error)) {
          this.errors = ['Invalid credentials'];
        } else {
          logError(error);
          this.errors = ['An unexpected error occured'];
        }

        this.disableButton = false;
      }
    }
  }
};
</script>

<style module lang="scss">
@import './Login.scss';
</style>
