<template>
  <div
    class="box-shadow"
    :class="{
      [$style.wrapper]: true,
      ['type-onboarding']: weekType === CYCLE_TYPES.onboarding,
      ['type-study']: weekType === CYCLE_TYPES.study,
      ['type-clinical-rotation']: weekType === CYCLE_TYPES.cr,
      ['type-holiday']: weekType === CYCLE_TYPES.holiday,
      ['type-flex']: weekType === CYCLE_TYPES.flex,
      ['type-undefined']: weekType === undefined
    }"
  >
    <div
      v-for="(day, dayIndex) in Object.keys(weekDetail)"
      :key="dayIndex"
      :class="{
        ['current']: isCurrent(day)
      }"
    >
      <div class="day-header">
        <h4 class="uppercase bold">{{ toWeekDay(day) }}</h4>
        <h4 class="normal">{{ toHeaderFormat(day) }}</h4>
      </div>
    </div>
    <div :class="$style.weekSpanned">
      <slot v-if="!weekType"> </slot>

      <slot
        :name="CYCLE_TYPES.onboarding"
        v-else-if="weekType === CYCLE_TYPES.onboarding"
      >
        <div class="lesson">
          <!-- empty? -->
        </div>
      </slot>

      <slot
        :name="CYCLE_TYPES.study"
        v-else-if="weekType === CYCLE_TYPES.study"
      >
        <div class="lesson">
          <div class="note">
            <div>
              <img
                width="150"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_flex.svg')"
              />
            </div>
            <div>
              <h5>No sessions for this week</h5>
              <div
                v-html="
                  '<p>Self-study time allows you to work on any open tasks that you may have.</p><p>Feel free to schedule this time as you see appropriate.</p>'
                "
              />
            </div>
          </div>
        </div>
      </slot>

      <slot :name="CYCLE_TYPES.flex" v-else-if="weekType === CYCLE_TYPES.flex">
        <div class="lesson">
          <div class="note">
            <div>
              <img
                width="150"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_flex.svg')"
              />
            </div>
            <div>
              <h5>Flex Week</h5>
              <div
                v-html="
                  'As you travel back from your teaching hospital this week, make sure to schedule time to review the module and finish any open tasks that you may have.'
                "
              />
            </div>
          </div>
        </div>
      </slot>

      <slot
        :name="CYCLE_TYPES.holiday"
        v-else-if="weekType === CYCLE_TYPES.holiday"
      >
        <div class="lesson">
          <div class="note">
            <div>
              <img
                width="150"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_holiday.svg')"
              />
            </div>
            <div>
              <h5>It's Holiday!</h5>
              <div>
                <p>
                  More time to study, yay!
                </p>
              </div>
            </div>
          </div>
        </div>
      </slot>

      <slot :name="CYCLE_TYPES.cr" v-else-if="weekType === CYCLE_TYPES.cr">
        <div class="lesson">
          <div class="note">
            <div>
              <img
                width="150"
                v-svg-inline
                :src="require('@/assets/images/icons/empty_cr.svg')"
              />
            </div>
            <div>
              <h5>Clinical Rotation</h5>
              <div>
                <p>
                  This is your clinical rotation phase. Class may be out, but
                  the learning doesn’t stop! Head to the library to find videos
                  and readings that will enhance your clinical experience.
                </p>
                <p>
                  <router-link
                    :to="{
                      name: 'learning-units-current'
                    }"
                  >
                    Check the Units...
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { FALLBACK_LANGUAGE } from '@/config/locale';
import { CYCLE_TYPES } from '@/config/schedule';

export default {
  name: 'WeekDetailEmpty',
  props: {
    weekDetail: {
      type: Object,
      default: () => {}
    },
    weekType: {
      type: String,
      default: undefined
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  },
  computed: {
    ...mapGetters(['currentUserProfile'])
  },
  methods: {
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    },
    toWeekDay(dayISO) {
      const m_dayStartISO = moment(dayISO).startOf('day');

      return moment(dayISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('ddd');
    },
    toHeaderFormat(periodStartISO) {
      const m_dayStartISO = moment(periodStartISO).startOf('day');

      return moment(periodStartISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('DD.MM.YYYY');
    }
  }
};
</script>

<!-- Watch out! Following styles are NOT modules, but globals! -->
<style lang="scss">
@import './WeekDetailGlobals.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
@import './WeekTypeUndefined.scss';
</style>

<style lang="scss" module>
@import './WeekDetailEmpty.scss';
</style>
