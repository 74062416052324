export const ROLES = {
  admin: 'admin',
  mentor: 'mentor',
  expert: 'expert',
  student: 'student',
  moduleLeader: 'module-leader',
  tutor: 'tutor',
  staff: 'staff'
};

export const STUDENT_ROLES = [ROLES.student];
export const TEACHING_STAFF_ROLES = [
  ROLES.admin,
  ROLES.mentor,
  ROLES.expert,
  ROLES.moduleLeader,
  ROLES.tutor,
  ROLES.staff
];

export const GENDERS = {
  male: 'male',
  female: 'female',
  other: 'other'
};

export const NEWROW_ROLES = {
  student: 'Student',
  instructor: 'Instructor',
  admin: 'Administrator'
};
