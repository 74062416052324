<template>
  <div>
    <NotFound v-if="display404" />
    <Layout variant="4" v-else>
      <template #pageHero>
        <UserProfileImageSettings :user="userProfile(userId)" />
      </template>
      <template #pageContent>
        <TopWidgetUserSettings :user="userProfileSettings(userId)" />
        <h2 class="pl-2">Account Settings</h2>
        <div class="box-shadow-white">
          <PasswordChange
            @change_password="changePassword"
            :user="userProfileSettings(userId)"
            :newPasswordIncorrect="newPasswordIncorrect"
          />
          <UserSettings :user="userProfileSettings(userId)" />
        </div>
      </template>
      <template #pageSideA>
        <SideWidgetUserProfileTeam :user="userProfile(userId)" :locked="true" />
        <SideWidgetUserProfileCalendar
          :user="userProfile(userId)"
          style="margin-top: 14px;"
          v-if="isCurrentUserStudent && userId === currentUserId"
          :locked="true"
        />
      </template>
      <template #pageSideB>
        <SideWidgetUserProfileSocialSettings :user="userProfile(userId)" />
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_USER_PROFILE,
  CHANGE_USER_PASSWORD,
  GET_TEAM_PROFILE
} from '@/store/actions.type';
import {
  PURGE_USER_PROFILE_SETTINGS,
  SET_GLOBAL_ERROR
} from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import TopWidgetUserSettings from '@/components/user-profile/TopWidgetUserSettings/TopWidgetUserSettings';
import UserProfileImageSettings from '@/components/user-profile/UserProfileImageSettings/UserProfileImageSettings';
import UserSettings from '@/components/user-profile/UserSettings/UserSettings';
import PasswordChange from '@/components/user-profile/PasswordChange/PasswordChange';
import SideWidgetUserProfileTeam from '@/components/user-profile/SideWidgetUserProfileTeam/SideWidgetUserProfileTeam';
import SideWidgetUserProfileSocialSettings from '@/components/user-profile/SideWidgetUserProfileSocialSettings/SideWidgetUserProfileSocialSettings';
import SideWidgetUserProfileCalendar from '@/components/user-profile/SideWidgetUserProfileCalendar/SideWidgetUserProfileCalendar';

export default {
  components: {
    Layout,
    TopWidgetUserSettings,
    UserSettings,
    NotFound,
    PasswordChange,
    UserProfileImageSettings,
    SideWidgetUserProfileTeam,
    SideWidgetUserProfileCalendar,
    SideWidgetUserProfileSocialSettings
  },
  data() {
    return {
      submitDisabled: false,
      newPasswordIncorrect: false
    };
  },
  computed: {
    ...mapGetters([
      'domainCountries',
      'userProfileSettings',
      'clientUserProfileErrors',
      'currentUserId',
      'teamAvatarUrl',
      'userTeamSlug',
      'userProfile',
      'isCurrentUserStudent'
    ]),
    isDisabled() {
      return this.clientUserProfileErrors.length > 0 || this.submitDisabled;
    },
    userId() {
      return this.$route.params.userId;
    },
    display404() {
      return this.userId.toString() !== this.currentUserId.toString();
    }
  },
  watch: {
    $route() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
    changePassword(oldPassword, newPassword) {
      this.$store
        .dispatch(CHANGE_USER_PASSWORD, {
          userId: this.userId,
          passwordData: {
            oldPassword: oldPassword,
            password: newPassword
          }
        })
        .then(() => {
          this.newPasswordIncorrect = false;
          this.$store.commit(PURGE_USER_PROFILE_SETTINGS);
          this.$router.push({
            name: 'login'
          });
        })
        .catch(error => {
          if (error.response.data.errors[0].code == 'validation') {
            this.newPasswordIncorrect = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    },
    async fetchData() {
      try {
        await this.$store.dispatch(GET_USER_PROFILE, this.userId);

        const userTeamSlug = this.userTeamSlug(this.userProfile(this.userId));
        if (userTeamSlug) {
          await this.$store.dispatch(GET_TEAM_PROFILE, userTeamSlug);
        }
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>
