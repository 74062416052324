<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Medical Subjects</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
          :pagination-options="{
            enabled: true
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <span class="adminPrimaryId">{{ props.row.id }}</span>
            </span>
          </template>
        </vue-good-table>
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import { VueGoodTable } from 'vue-good-table';

import { GET_ADMIN_CATEGORIES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    VueGoodTable
  },
  data() {
    return {
      categories: [],
      display404: false,
      columns: [
        {
          label: 'Subject ID',
          field: 'id',
          type: 'number',
          width: '110px',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('name')
          }
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchCategories();
    }
  },
  methods: {
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'name':
          return this.$route.query.name;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        name: column.name || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    fetchCategories() {
      this.$store
        .dispatch(GET_ADMIN_CATEGORIES)
        .then(categories => {
          this.categories = categories;
        })
        .then(categories => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.rows = [];

      this.categories.filter(category => {
        this.rows.push({
          id: category.jsonb_build_object.category_id || '',
          name: category.jsonb_build_object.label || ''
        });
      });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminTables.scss';
</style>
