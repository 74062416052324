<template>
  <NotFound v-if="display404" />
  <Layout variant="8" v-else>
    <template #pageSideA>
      <AdminFaqTopics v-if="faqTopics.length" :faqTopics="faqTopics" />
    </template>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Faq Questions</h6>
          <div :class="$style.buttons">
            <FormButton
              @click="handleOpened"
              :class="$style.greenButton"
              class="btn standard"
            >
              New Question
            </FormButton>
            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <AdminFaqList
          :faqQuestions="faqQuestions"
          :faqTopics="faqTopics"
          @fetchQuestions="fetchFaqQuestions"
        />

        <ModalNew
          :class="{ [$style.adminModal]: true, [$style.wideModal]: true }"
          :showModal="showModal"
          v-transfer-dom
          @close="handleClosed"
        >
          <AdminFaqForm
            v-if="faqTopics"
            :faqTopics="faqTopics"
            @createOrUpdateFaq="createNewQuestion"
          />
        </ModalNew>
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  ADMIN_FAQ_QUESTIONS,
  ADMIN_FAQ_TOPICS,
  ADMIN_CREATE_FAQ_QUESTIONS
} from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import AdminFaqList from '@/views/Admin/AdminFaq/AdminFaqList';
import AdminFaqTopics from '@/views/Admin/AdminFaq/AdminFaqTopics';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminFaqForm from '@/components/admin/AdminFaqForm/AdminFaqForm';

export default {
  components: {
    Layout,
    NotFound,
    AdminFaqList,
    AdminFaqTopics,
    AdminFaqForm
  },
  data() {
    return {
      display404: false,
      faqQuestions: [],
      faqTopics: [],
      showModal: false
    };
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchFaqTopics();
      this.fetchFaqQuestions();
    }
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  methods: {
    ...mapActions([ADMIN_CREATE_FAQ_QUESTIONS]),
    fetchFaqTopics() {
      this.$store
        .dispatch(ADMIN_FAQ_TOPICS)
        .then(topics => {
          this.faqTopics = topics;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchFaqQuestions() {
      this.$store
        .dispatch(ADMIN_FAQ_QUESTIONS)
        .then(questions => {
          this.faqQuestions = questions;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createNewQuestion(faqData) {
      this.ADMIN_CREATE_FAQ_QUESTIONS(faqData)
        .then(() => {
          this.fetchFaqQuestions();
          this.handleClosed();
        })
        .then(() => {
          if (faqData.faqTopicId != this.$route.params.topicId) {
            this.$router.push({
              name: 'admin-faq-topic',
              params: {
                topicId: faqData.faqTopicId
              }
            });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleOpened() {
      this.showModal = true;
    },
    handleClosed() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminFaq.scss';
@import '../AdminTables.scss';
</style>
