<template>
  <FileUploadBase
    :step="forceStep || step"
    :allowedMimeTypes="allowedMimeTypes"
    :errors="errors"
    :class="$style.wrapper"
  >
    <template slot="waiting">
      <div :class="$style.uploadCard">
        <FileUploadArea
          :allowedMimeTypes="allowedMimeTypes"
          @inserted="onInserted"
          @validation="onValidation"
          :maxSizeInMb="maxSizeInMb"
        >
          <p :class="$style.uploadCardIcon">
            <a><i class="icon icon-plus"></i></a>
          </p>
          <p>
            <a>Click to upload</a>
          </p>
          <p>
            <small>* File size max. {{ maxSizeInMb }} MB. </small>
          </p>
        </FileUploadArea>
      </div>
    </template>

    <template slot="uploading">
      <div :class="$style.uploadCard">
        <CircleLoader
          :radius="60"
          :strokeWidth="5"
          backgroundColor="#fff"
          :color="primaryColor"
          :class="$style.uploadCardLoader"
        >
          Uploading...
        </CircleLoader>
      </div>
    </template>

    <template slot="success">
      <GradingCard
        :resource="resource"
        :submission="submission"
        @remove_upload="relayRemoveUpload"
      />

      <FileUploadArea
        v-if="canReupload"
        :allowedMimeTypes="allowedMimeTypes"
        @inserted="onInserted"
        @validation="onValidation"
        :maxSizeInMb="maxSizeInMb"
        :class="$style.reUpload"
      >
        <a class="btn ghost link">
          <img
            v-svg-inline
            :src="require(`@/assets/images/icons/retry.svg`)"
          />&nbsp;Re-upload
        </a>
      </FileUploadArea>
    </template>

    <template slot="error">
      <div :class="{ [$style.uploadCard]: true, [$style.error]: true }">
        <FileUploadArea
          :allowedMimeTypes="allowedMimeTypes"
          @inserted="onInserted"
          @validation="onValidation"
          :maxSizeInMb="maxSizeInMb"
        >
          <p :class="$style.uploadCardImage">
            <a><i class="icon icon-remove"></i></a>
          </p>
          <h6>{{ errors[0] }}</h6>
        </FileUploadArea>
      </div>

      <FileUploadArea
        :allowedMimeTypes="allowedMimeTypes"
        @inserted="onInserted"
        @validation="onValidation"
        :maxSizeInMb="1"
        :class="$style.reUpload"
      >
        <a class="btn ghost link">
          <img
            v-svg-inline
            :src="require(`@/assets/images/icons/retry.svg`)"
          />&nbsp;Try again
        </a>
      </FileUploadArea>
    </template>
  </FileUploadBase>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { CREATE_FILE_RESOURCE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import {
  MIME_TYPES_BY_FILE_EXTENSION,
  UPLOAD_STEP,
  FILENAME_MAX_LENGTH
} from '@/config/files';
import {
  FileTooLargeError,
  FileNameTooLongError,
  InvalidFileTypeError,
  mimeTypeToUploadType
} from '@/helpers/files';
import { hasErrorStatus, getServerErrorMessages } from '@/helpers/errors';
import { THEMES, DEFAULT_THEME } from '@/config/themes';
import { MODES } from '@/config/grading';

import FileUploadBase from '@/components/common/FileUploadBase/FileUploadBase';
import FileUploadArea from '@/components/common/FileUploadArea/FileUploadArea';
import CircleLoader from '@/components/common/CircleLoader/CircleLoader';
import GradingCard from '../GradingCard';

export default {
  name: 'GroupWorkUpload',
  components: {
    FileUploadBase,
    FileUploadArea,
    CircleLoader,
    GradingCard
  },
  props: {
    forceStep: {
      type: String
    },
    canRemoveUpload: {
      type: Boolean,
      default: true
    },
    canReupload: {
      type: Boolean,
      type: true
    },
    uploadingStepDuration: {
      type: Number,
      default: 3 * 1000 // three seconds
    },
    resource: {
      type: Object,
      default: () => {}
    },
    submission: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      step: UPLOAD_STEP.waiting,
      errors: [],
      allowedMimeTypes: [MIME_TYPES_BY_FILE_EXTENSION.pdf],
      maxSizeInMb: 50
    };
  },
  filters: {
    format(dateISOString) {
      if (dateISOString) {
        const m_date = moment(dateISOString);
        if (m_date.isValid()) {
          return m_date.format('DD.MM.YYYY, HH:mm');
        }
      }

      return 'TODO: created_at';
    }
  },
  computed: {
    ...mapGetters(['currentUserId'])
  },
  created() {
    this.UPLOAD_STEP = UPLOAD_STEP;
    this.MODES = MODES;
    this.primaryColor = THEMES[process.env.THEME || DEFAULT_THEME].primaryColor;
    this.target = ['test', 'e2e'].includes(process.env.NODE_ENV)
      ? '_self'
      : '_blank';
  },
  methods: {
    onValidation(payload) {
      this.step = UPLOAD_STEP.error;

      if (payload && payload.errors && payload.errors.length > 0) {
        const error = payload.errors[0];

        if (error instanceof FileTooLargeError) {
          this.errors.push('File size is too large!');
        } else if (error instanceof InvalidFileTypeError) {
          this.errors.push('File type is wrong!');
        } else if (error instanceof FileNameTooLongError) {
          this.errors.push(
            `File name is too long! Maximum ${FILENAME_MAX_LENGTH} chars allowed.`
          );
        }

        this.$emit('error', error);
      }
    },
    relayRemoveUpload() {
      this.$emit('remove_upload');
    },
    handleUploadSuccess(resource) {
      this.errors = [];
      this.step = UPLOAD_STEP.success;
      this.$emit('success', {
        resource
      });
    },
    async onInserted(payload) {
      if (!payload) return;

      const file = payload && payload.file;
      this.errors = [];
      this.step = UPLOAD_STEP.uploading;
      this.$emit('uploading');

      try {
        const resource = await this.$store.dispatch(CREATE_FILE_RESOURCE, {
          file,
          type: mimeTypeToUploadType(file.type),
          title: file.name,
          ownerIds: [this.currentUserId]
        });

        if (['test', 'e2e'].includes(process.env.NODE_ENV)) {
          this.handleUploadSuccess(resource);
        } else {
          setTimeout(() => {
            this.handleUploadSuccess(resource);
          }, this.uploadingStepDuration);
        }
      } catch (error) {
        if (hasErrorStatus(422, error)) {
          this.errors.push(getServerErrorMessages(error).join(', '));
        } else {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          this.errors.push(
            'An unexpected error has occurred. Please try again.'
          );
        }

        this.step = UPLOAD_STEP.error;
        this.$emit('error', error);
      }
    }
  }
};
</script>

<style module lang="scss">
@import './GradingGroupWorkUpload.scss';
</style>
