<template>
  <div class="lesson">
    <template v-if="isViewingAsStudent || isViewingAsCohort">
      <p :class="$style.duration">{{ duration(lesson) }}</p>
      <p :class="$style.name" v-line-clamp:2="1.35">
        <router-link
          :to="{
            name: 'assignment-detail',
            params: {
              assignmentId: lesson.parent_assignment_id || lesson.assignment_id,
              scheduledAssignmentId: lesson.scheduled_assignment_id
            }
          }"
        >
          {{ lesson.name }}
        </router-link>
      </p>
      <p :class="$style.type">{{ label(sessionType(lesson)) }}</p>
      <ListUnits
        v-if="lesson && lesson.learning_units && lesson.learning_units.length"
        :learningUnits="lesson.learning_units"
        :class="$style.learningUnit"
      />
      <ul :class="$style.facilitators" data-cy="facilitators">
        <li v-for="(facilitator, index) in facilitators" :key="index">
          <router-link
            v-if="facilitator && facilitator.user_id"
            :to="{
              name: 'user-profile',
              params: {
                userId: facilitator.user_id
              }
            }"
            class="semibold"
          >
            <UserName :user="facilitator" />
          </router-link>
        </li>
      </ul>

      <NewRoomLauncherBeforeStart
        :user="currentUserProfile"
        :session="lesson"
        :nowISO="nowISO"
        class="launcher"
      >
        <template #not-joinable>
          <a
            :href="lesson.session_feedback_url"
            target="_blank"
            :class="$style.feedback"
            class="btn ghost"
            v-if="showFeedbackLink"
            @click="buttonClickHandler(true)"
          >
            Give Feedback
          </a>

          <router-link
            v-else
            data-cy="assignment-detail-link"
            @click.native="buttonClickHandler(false)"
            :class="{
              [$style.assignmentDetail]: true,
              ['btn']: true
            }"
            :to="{
              name: 'assignment-detail',
              params: {
                assignmentId:
                  lesson.parent_assignment_id || lesson.assignment_id,
                scheduledAssignmentId: lesson.scheduled_assignment_id
              }
            }"
          >
            View Details
          </router-link>
        </template>
        <template #default>
          <!-- Should use whatever is default template inside NewRoomLauncherBeforeStart component -->
        </template>
      </NewRoomLauncherBeforeStart>
    </template>

    <template v-if="isViewingAsTeachingStaff">
      <p :class="$style.duration">{{ duration(lesson) }}</p>
      <p :class="$style.name" v-line-clamp:2="1.35">
        <router-link
          :to="{
            name: 'assignment-detail',
            params: {
              assignmentId: lesson.parent_assignment_id || lesson.assignment_id,
              scheduledAssignmentId: lesson.scheduled_assignment_id
            }
          }"
        >
          {{ lesson.name }}
        </router-link>
      </p>

      <div :class="$style.groups">
        {{ getModuleName() ? `${getModuleName()}, ` : '' }}
        <template v-for="(groupType, index) in Object.values(userGroupsByType)">
          <span :key="`group-type-${index}`">
            <span
              v-for="(userGroup, index) in groupType"
              :key="`group-${index}`"
              :class="$style.group"
            >
              {{ userGroup.object_name }}
            </span>
          </span>
        </template>
      </div>

      <NewRoomLauncherBeforeStart
        :user="currentUserProfile"
        :session="lesson"
        :nowISO="nowISO"
        class="launcher"
        :minutesBeforeSessionStart="60"
      >
        <template #not-joinable="{ room }">
          <div
            :class="{ [$style.classRoom]: true, [$style.joinable]: false }"
            v-if="room && room.newrow_room_name"
          >
            NewRow {{ room.newrow_room_name }}
          </div>
        </template>
        <template #default="{ room }">
          <div :class="{ [$style.classRoom]: true, [$style.joinable]: true }">
            NewRow {{ room.newrow_room_name }}
            <button :class="$style.inlineBtn">Open</button>
          </div>
        </template>
      </NewRoomLauncherBeforeStart>

      <router-link
        data-cy="assignment-detail-link"
        :class="{
          [$style.assignmentDetail]: true,
          ['btn']: true,
          ['standard']: hasLessonEnded ? true : false
        }"
        :style="{
          color: hasLessonEnded ? 'white' : ''
        }"
        :to="{
          name: 'assignment-detail',
          params: {
            assignmentId: lesson.parent_assignment_id || lesson.assignment_id,
            scheduledAssignmentId: lesson.scheduled_assignment_id
          }
        }"
      >
        {{ hasLessonEnded ? 'Enter Grading' : 'View Details' }}
      </router-link>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { VISITED_FEEDBACK_URLS } from '@/config/localStorage';
import { USER_GROUP_TYPES } from '@/config/userGroups';

import NewRoomLauncherBeforeStart from '@/components/common/NewRoomLauncherBeforeStart/NewRoomLauncherBeforeStart';
import ListUnits from '@/views/Grading/ListUnits';
import { renderLabel } from '@/helpers/common';
import { getAssignmentType } from '@/helpers/schedule';

export default {
  name: 'Lesson',
  components: {
    NewRoomLauncherBeforeStart,
    ListUnits
  },
  props: {
    nowISO: {
      type: String,
      required: true
    },
    lesson: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
    minutesBeforeSessionStart: {
      type: Number,
      default: 5
    },
    selectedModuleVersionId: {
      type: Number
    }
  },
  data() {
    return {
      visitedSessionFeedbacks: []
    };
  },
  computed: {
    ...mapGetters([
      'currentUserProfile',
      'isViewingAsTeachingStaff',
      'isViewingAsStudent',
      'isViewingAsCohort',
      'getFromModulesByVersionId',
      'modules'
    ]),
    hasLessonEnded() {
      if (!this.lesson) return undefined;
      if (!this.lesson.end_at) return undefined;

      const m_end_at = moment(this.lesson.end_at);
      const m_now = moment(this.nowISO);

      return (
        m_end_at.isValid() && m_now.isValid() && m_now.isSameOrAfter(m_end_at)
      );
    },
    userGroupsByType() {
      if (!this.userGroups) return {};

      return this.userGroups.reduce((memo, userGroup) => {
        const userGroupType = userGroup.group_type;

        if (!memo[userGroupType]) {
          memo[userGroupType] = [];
          memo[userGroupType].push(userGroup);
        } else {
          memo[userGroupType].push(userGroup);
        }

        return memo;
      }, {});
    },
    userGroups() {
      if (!this.lesson) return [];

      if (!this.lesson.user_groups) return [];

      const allUserGroups = this.lesson.user_groups.map(ug => {
        if (!ug.object_name) {
          // If no group name is provided, concat type and id
          ug.object_name = `${ug.group_type} ${ug.user_group_id}`;
        }

        return ug;
      });

      return allUserGroups.filter(ug => {
        if (ug.group_type === this.USER_GROUP_TYPES.cohort) {
          // Skip over cohort group types?
          return true;
        }

        if (!Object.values(this.USER_GROUP_TYPES).includes(ug.group_type)) {
          return false;
        }

        return true;
      });
    },
    facilitators() {
      if (!this.lesson) return [];

      return this.lesson.facilitators;
    },
    showFeedbackLink() {
      if (!this.lesson) return false;
      if (!this.lesson.session_feedback_url) return false;

      const m_now = moment(this.nowISO);
      const m_end = moment(this.lesson.end_at);
      const m_until = moment(this.lesson.end_at).add(1, 'weeks');

      if (
        m_now.isValid() &&
        m_end.isValid() &&
        m_until.isValid() &&
        m_now.isSameOrAfter(m_end) &&
        m_now.isSameOrBefore(m_until)
      ) {
        return this.isVisited(this.lesson) ? false : true;
      }

      return false;
    }
  },
  methods: {
    duration(lesson) {
      const m_start = moment(lesson.start_at);
      const m_end = moment(lesson.end_at);

      if (m_start.isValid() && m_end.isValid()) {
        return `${m_start.format('HH:mm')} - ${m_end.format('HH:mm')}`;
      }

      return '';
    },
    label(value) {
      return renderLabel(value);
    },
    sessionType(lesson) {
      return getAssignmentType(lesson);
    },
    getModuleName() {
      const found = this.getFromModulesByVersionId(
        this.modules,
        this.selectedModuleVersionId
      );
      return found && found.name ? found.name : '';
    },
    buttonClickHandler(shouldEmit) {
      if (!shouldEmit) return;

      const lessonId = parseInt(this.lesson.scheduled_assignment_id);
      const found = this.visitedSessionFeedbacks.includes(lessonId);

      if (!found) {
        this.addToLocalStorageIds(lessonId);
        this.saveToLocalStorage();
      } else {
        this.removeFromLocalStorageIds(lessonId);
        this.saveToLocalStorage();
      }
    },
    saveToLocalStorage() {
      window.localStorage.setItem(
        VISITED_FEEDBACK_URLS,
        JSON.stringify(this.visitedSessionFeedbacks)
      );
    },
    addToLocalStorageIds(lessonId) {
      if (!lessonId) return;

      this.visitedSessionFeedbacks.push(lessonId);
    },
    removeFromLocalStorageIds(lessonId) {
      if (!lessonId) return;

      const indexToRemove = this.visitedSessionFeedbacks.indexOf(lessonId);

      if (indexToRemove !== -1) {
        const filteredArray = this.visitedSessionFeedbacks.filter(
          (val, index) => index !== indexToRemove
        );

        this.visitedSessionFeedbacks = filteredArray;
      }
    },
    isVisited(lesson) {
      const lessonId = parseInt(this.lesson.scheduled_assignment_id);
      return this.visitedSessionFeedbacks.includes(lessonId);
    }
  },
  created() {
    this.USER_GROUP_TYPES = USER_GROUP_TYPES;

    // Create localStorage key if it doesn't exist
    if (window.localStorage.getItem(VISITED_FEEDBACK_URLS) === null) {
      window.localStorage.setItem(VISITED_FEEDBACK_URLS, JSON.stringify([]));
    }
  },
  mounted() {
    this.visitedSessionFeedbacks = JSON.parse(
      window.localStorage.getItem(VISITED_FEEDBACK_URLS)
    );
  }
};
</script>

<style lang="scss" module>
@import './Lesson.scss';
</style>
