<template>
  <SideWidget
    :class="{
      'item-locked': locked,
      [$style.wrapper]: true,
      [$style.locked]: locked
    }"
    :title="false"
    class="box-shadow-white p-2"
  >
    <template #content>
      <div :class="$style.content">
        <i class="icon" v-if="locked" />

        <div :class="$style.mobile">
          <h3>My Schedule</h3>
          <CalendarLink />
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import SideWidget from '@/components/common/SideWidget/SideWidget';
import CalendarLink from '@/components/common/CalendarLink/CalendarLink';

export default {
  components: {
    SideWidget,
    CalendarLink
  },
  props: {
    locked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module lang="scss">
@import '../SideWidgetUserProfileTeam/SideWidgetUserProfileTeam.scss';
</style>
