<template>
  <div :class="$style.wrapper">
    <h6 class="main-title">
      Browse Partner Collections
    </h6>
    <div :class="$style.providers" class="box-shadow-white">
      <div
        v-for="provider in legitResourceProviders"
        :key="provider.provider_code"
        :class="$style.provider"
      >
        <i @click="showProviderModal(provider)" class="icon icon-help-circle" />
        <a :href="provider.url" target="_blank">
          <img
            :src="require(`@/assets/images/logos/${providerLogo(provider)}`)"
          />
        </a>
      </div>
    </div>

    <ModalNew
      :class="$style.providersModalWrapper"
      :showModal="showModal"
      v-transfer-dom
      @close="showModal = false"
    >
      <div :class="$style.description">
        <img
          v-if="providerLogo(displayProvider)"
          :src="
            require(`@/assets/images/logos/${providerLogo(displayProvider)}`)
          "
        />
        <div v-html="displayProvider.description" />
      </div>
      <div :class="$style.link" v-if="displayProvider.url">
        <a class="btn ghost url" target="_blank" :href="displayProvider.url"
          >Visit page</a
        >
      </div>
    </ModalNew>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showModal: false,
      displayProvider: {}
    };
  },
  computed: {
    ...mapGetters(['resourceProviders']),
    legitResourceProviders() {
      return this.resourceProviders.filter(
        provider =>
          provider.provider_code === 'ditki' ||
          provider.provider_code === 'oxford' ||
          provider.provider_code === 'amboss' ||
          provider.provider_code === 'thieme' ||
          provider.provider_code === 'biodigital' ||
          provider.provider_code === 'proquest'
      );
    }
  },
  methods: {
    showProviderModal(provider) {
      this.showModal = true;
      this.displayProvider = provider;
    },
    providerLogo(provider) {
      switch (provider.provider_code) {
        case 'oxford':
          return 'oup.png';
          break;
        case 'ditki':
          return 'ditki.png';
          break;
        case 'amboss':
          return 'amboss.png';
          break;
        case 'biodigital':
          return 'biodigital.jpg';
          break;
        case 'thieme':
          return 'thieme.png';
          break;
        case 'proquest':
          return 'proQuest.png';
          break;
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './LibraryProviders.scss';
</style>
