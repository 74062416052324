<template>
  <div id="app" :class="themeClassName">
    <router-view />
    <TickTack
      :useScopedSlot="false"
      :tickEverySeconds="60"
      :emitAfterTicks="1"
      emit="global_tick"
      @global_tick="onGlobalTick"
    ></TickTack>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { REPORT_ROUTE, GET_ANNOUNCEMENTS } from '@/store/actions.type';
import {
  SET_DESKTOP_LAYOUT,
  SET_TABLET_LAYOUT,
  SET_PHONE_LAYOUT
} from '@/store/mutations.type';
import {
  ROUTE_CHANGED_EVENT,
  AUTH_CHANGED_EVENT,
  NEWER_VERSION_EVENT,
  SITES
} from '@/config/tracking';
import { DEFAULT_THEME } from '@/config/themes';
import { FORM_FACTORS } from '@/config/common';

import TickTack from '@/components/common/TickTack/TickTack';
import { logWarning, logError } from '@/helpers/errors';

const VERSION_CHECK_INTERVAL_MINUTES = 30;

// These vars need to be global in order to have a reference when destroying listeners
let phoneMediaQueryList;
let tabletMediaQueryList;
let desktopMediaQueryList;

export default {
  components: {
    TickTack
  },
  data() {
    return {
      appIsVisible: undefined,
      visibilityAttributeName: undefined,
      visibilityChangeEventName: undefined,
      visibilityChangeSupported: undefined,
      minutesToNextVersionCheck: 0
    };
  },
  watch: {
    isAuthenticated(newState) {
      this.$bus.$emit(AUTH_CHANGED_EVENT, newState);
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    themeClassName() {
      return `theme-${process.env.THEME || DEFAULT_THEME}`;
    },
    shouldReport() {
      // Do not send report when app is not visible
      if (!this.appIsVisible) {
        return false;
      }

      // Do not send report when user is not authenticated
      if (!this.isAuthenticated) {
        return false;
      }

      if (process.env.NODE_ENV !== 'production') {
        return false;
      }

      return true;
    },
    shouldCheckAnnouncements() {
      // NOTE: Whatever condition is in here, you might need to also watch for it's changes!

      if (!this.isAuthenticated) {
        // Do not check announcements when user is not authenticated
        return false;
      }

      return true;
    },
    shouldCheckLatestVersion() {
      if (!this.isVersionCheckingEnabled()) return false;

      return this.minutesToNextVersionCheck === 0;
    }
  },
  methods: {
    onGlobalTick() {
      this.reportRoute(this.$route.fullPath);
      this.checkAnnouncements();
      this.checkLatestVersion();
    },
    reportRoute(routeToReport) {
      if (this.shouldReport) {
        this.$store
          .dispatch(REPORT_ROUTE, this.$route.fullPath)
          .catch(error => {
            void error;
            // Swallow reporting errors on purpose
          });
      }
    },
    getTrackingSiteIndex() {
      return SITES.findIndex(
        site => site.hostname === window.location.hostname
      );
    },
    isVersionCheckingEnabled() {
      const siteIndex = this.getTrackingSiteIndex();

      if (siteIndex === -1) return false;

      return SITES[siteIndex].version_checking === true;
    },
    async checkLatestVersion() {
      if (!this.shouldCheckLatestVersion) {
        this.minutesToNextVersionCheck = this.minutesToNextVersionCheck - 1;

        return;
      }

      let runningVersion = process.env.BUILD_HASH;
      let availableVersion = undefined;

      let customHeaders = new Headers();
      customHeaders.append('pragma', 'no-cache');
      customHeaders.append('cache-control', 'no-cache');

      try {
        availableVersion = await fetch(
          `${window.location.origin}/version.json?t=${moment().format('X')}`,
          { method: 'GET', headers: customHeaders }
        )
          .then(response => response.json())
          .then(data => {
            return data && data.hash ? data.hash : undefined;
          });
      } catch (error) {
        logError(error);
      }

      // No matter if the available version fetch succeeded or not, reset the countdown to next check
      this.minutesToNextVersionCheck = VERSION_CHECK_INTERVAL_MINUTES;

      // Now, if we even have version hashes to compare...
      // and they don't match, emit an event
      if (
        runningVersion &&
        availableVersion &&
        runningVersion !== availableVersion
      ) {
        this.$bus.$emit(NEWER_VERSION_EVENT, {
          runningVersion,
          availableVersion
        });
      }
    },
    checkAnnouncements() {
      if (this.shouldCheckAnnouncements) {
        this.$store.dispatch(GET_ANNOUNCEMENTS).catch(() => {});
      }
    },
    handleVisibilityChange() {
      if (this.visibilityChangeSupported !== true) return;

      if (document[this.visibilityAttributeName]) {
        this.appIsVisible = false;
      } else {
        this.appIsVisible = true;
      }
    },
    setupVisibilityAPI() {
      if (typeof document.addEventListener === 'undefined') {
        this.visibilityChangeSupported = false;
        logWarning(`Browser doesn't support addEventListener`);
        return;
      }

      // Set the name of the hidden property and the change event for visibility
      if (typeof document.hidden !== 'undefined') {
        this.visibilityAttributeName = 'hidden';
        this.visibilityChangeEventName = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        this.visibilityAttributeName = 'msHidden';
        this.visibilityChangeEventName = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.visibilityAttributeName = 'webkitHidden';
        this.visibilityChangeEventName = 'webkitvisibilitychange';
      }

      if (this.visibilityAttributeName === undefined) {
        this.visibilityChangeSupported = false;
        logWarning(`Browser doesn't support Page Visibility API`);
      }

      // At this point we know browser supports visibility tracking
      this.visibilityChangeSupported = true;
    },
    setupEventBusListeners() {
      // Listen for route changes
      this.$bus.$on(ROUTE_CHANGED_EVENT, changedToFullPath => {
        this.reportRoute(changedToFullPath);
      });

      this.$bus.$on(AUTH_CHANGED_EVENT, () => {
        this.checkAnnouncements();
      });
    },
    destroyEventBusListeners() {
      // Stop listening for all events
      this.$bus.$off();
    },
    handlePhoneLayoutChange(event) {
      this.$store.commit(SET_PHONE_LAYOUT, event.matches);
    },
    handleTabletLayoutChange(event) {
      this.$store.commit(SET_TABLET_LAYOUT, event.matches);
    },
    handleDesktopLayoutChange(event) {
      this.$store.commit(SET_DESKTOP_LAYOUT, event.matches);
    },
    setupLayoutChangeListeners() {
      phoneMediaQueryList = window.matchMedia(FORM_FACTORS.phone);
      tabletMediaQueryList = window.matchMedia(FORM_FACTORS.tablet);
      desktopMediaQueryList = window.matchMedia(FORM_FACTORS.desktop);

      // Set initial values
      this.$store.commit(SET_PHONE_LAYOUT, phoneMediaQueryList.matches);
      this.$store.commit(SET_TABLET_LAYOUT, tabletMediaQueryList.matches);
      this.$store.commit(SET_DESKTOP_LAYOUT, desktopMediaQueryList.matches);

      // And watch for their changes
      phoneMediaQueryList.addListener(this.handlePhoneLayoutChange);
      tabletMediaQueryList.addListener(this.handleTabletLayoutChange);
      desktopMediaQueryList.addListener(this.handleDesktopLayoutChange);
    },
    destroyLayoutChangeListeners() {
      phoneMediaQueryList.removeListener(this.handlePhoneLayoutChange);
      tabletMediaQueryList.removeListener(this.handleTabletLayoutChange);
      desktopMediaQueryList.removeListener(this.handleDesktopLayoutChange);
    }
  },
  beforeDestroy() {
    document.removeEventListener(
      this.visibilityChangeEventName,
      this.handleVisibilityChange
    );

    this.destroyLayoutChangeListeners();
    this.destroyEventBusListeners();
  },
  mounted() {
    if (this.visibilityChangeSupported) {
      document.addEventListener(
        this.visibilityChangeEventName,
        this.handleVisibilityChange,
        false
      );
    }
  },
  created() {
    this.setupLayoutChangeListeners();
    this.setupVisibilityAPI();
    this.handleVisibilityChange();
    this.setupEventBusListeners();
    this.checkAnnouncements();
    this.checkLatestVersion();
  }
};
</script>
