<template>
  <div>
    <template v-if="teachingStaff.length">
      <UsersGroup class="box-shadow-white" :users="teachingStaff" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ROLES } from '@/config/users';

import { GET_USER_PROFILES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import UsersGroup from '@/components/user-profile/UsersGroup/UsersGroup';

export default {
  components: {
    UsersGroup
  },
  created() {
    this.$store.dispatch(GET_USER_PROFILES, { inactive: true }).catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  },
  computed: {
    ...mapGetters(['userProfiles']),
    teachingStaff() {
      if (!this.userProfiles) return [];

      return this.userProfiles.filter(user =>
        [ROLES.tutor, ROLES.mentor, ROLES.staff].includes(user.role.code)
      );
    }
  }
};
</script>
