var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){
var _obj, _obj$1, _obj$2;
return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[(_vm.currentUserCohorts && _vm.currentUserCohorts.length > 1)?_c('div',{class:_vm.$style.viewAs},[_c('strong',[_vm._v("View as:")]),_c('CohortSelect',{attrs:{"selectedCohortId":_vm.selectedCohortId},on:{"cohort_selected":_vm.onSelectedCohort}})],1):_vm._e()]),(_vm.modulesByTime && _vm.modulesByTime.all.length)?_c('div',{class:_vm.$style.modulesNavigation},[_c('ModulesNavigation',{attrs:{"modules":_vm.modules,"runningModuleVersionId":_vm.presentModuleVersionId,"onlyRunning":false,"onlyRunningOrFuture":false,"checkForDisabledUnits":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var moduleObject = ref.moduleObject;
var isActive = ref.isActive;
return [(isActive)?[_c('router-link',{attrs:{"to":{
                name: 'learning-units',
                params: {
                  moduleVersionId: moduleObject.module_version_id
                },
                query: Object.assign({}, _vm.$route.query)
              }}},[_vm._v(" "+_vm._s(moduleObject.name)+" ")])]:[_vm._v(" "+_vm._s(moduleObject.name)+" ")]]}}],null,false,3788069688)})],1):_vm._e(),(_vm.isLoading)?_c('InlineLoader'):_vm._e(),(!_vm.isLoading)?_c('div',{class:_vm.$style.completed},[_c('div',{class:( _obj = {}, _obj[_vm.$style.header] = true, _obj[_vm.$style.inactive] = _vm.completedUnits.length < 1, _obj ),on:{"click":_vm.flipCompleted}},[_c('h5',[_vm._v(" Completed Units ")]),(!_vm.showCompleted)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.showCompleted)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()]),_c('TransitionExpand',[(_vm.showCompleted)?_c('div',{class:_vm.$style.units},[(_vm.completedUnits.length > 0)?_vm._l((_vm.completedUnits),function(unit,index){
          var _obj;
return _c('LearningUnit',{key:index,staticClass:"box-shadow-white",class:( _obj = {}, _obj[_vm.$style.unit] = true, _obj[_vm.$style.study] = _vm.CYCLE_TYPES.STUDY === unit.phase_type, _obj[_vm.$style.other] = _vm.CYCLE_TYPES.STUDY !== unit.phase_type, _obj ),attrs:{"unit":unit,"moduleVersionId":_vm.selectedModuleVersionId}})}):void 0],2):_vm._e()])],1):_vm._e(),(!_vm.isLoading)?_c('div',{class:_vm.$style.current},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.header] = true, _obj$1[_vm.$style.inactive] = _vm.currentUnits.length < 1, _obj$1 ),on:{"click":_vm.flipCurrent}},[_c('h5',[_vm._v(" Current Units ")]),(!_vm.showCurrent)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.showCurrent)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()]),_c('TransitionExpand',[(_vm.showCurrent)?_c('div',{class:_vm.$style.units},[(_vm.currentUnits.length > 0)?_vm._l((_vm.currentUnits),function(unit,index){
          var _obj;
return _c('LearningUnit',{key:index,staticClass:"box-shadow",class:( _obj = {}, _obj[_vm.$style.unit] = true, _obj[_vm.$style.study] = _vm.CYCLE_TYPES.STUDY === unit.phase_type, _obj[_vm.$style.other] = _vm.CYCLE_TYPES.STUDY !== unit.phase_type, _obj ),attrs:{"unit":unit,"moduleVersionId":_vm.selectedModuleVersionId}})}):void 0],2):_vm._e()])],1):_vm._e(),(!_vm.isLoading)?_c('div',{class:_vm.$style.upcoming},[_c('div',{class:( _obj$2 = {}, _obj$2[_vm.$style.header] = true, _obj$2[_vm.$style.inactive] = _vm.upcomingUnits.length < 1, _obj$2 ),on:{"click":_vm.flipUpcoming}},[_c('h5',[_vm._v(" Upcoming Units ")]),(!_vm.showUpcoming)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.showUpcoming)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()]),_c('TransitionExpand',[(_vm.showUpcoming)?_c('div',{class:_vm.$style.units},[(_vm.upcomingUnits.length > 0)?_vm._l((_vm.upcomingUnits),function(unit,index){
          var _obj;
return _c('LearningUnit',{key:index,staticClass:"box-shadow-white",class:( _obj = {}, _obj[_vm.$style.unit] = true, _obj[_vm.$style.study] = _vm.CYCLE_TYPES.STUDY === unit.phase_type, _obj[_vm.$style.other] = _vm.CYCLE_TYPES.STUDY !== unit.phase_type, _obj ),attrs:{"unit":unit,"moduleVersionId":_vm.selectedModuleVersionId}})}):void 0],2):_vm._e()])],1):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }