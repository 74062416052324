<template>
  <Layout variant="8">
    <template #pageHero v-if="isCurrentUserTeachingStaff">
      <div :class="$style.wrapper">
        <router-link to="/admin/faq" class="btn standard"
          >Manage FAQs</router-link
        >
      </div>
    </template>
    <template #pageContent>
      <router-view />
    </template>
    <template #pageSideA>
      <SideWidgetFaqTopics :faqTopics="faqTopics" />
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_FAQ_TOPICS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import SideWidgetFaqTopics from '@/components/common/SideWidgetFaqTopics/SideWidgetFaqTopics';

export default {
  components: {
    Layout,
    SideWidgetFaqTopics
  },
  computed: {
    ...mapGetters(['faqTopics', 'isCurrentUserTeachingStaff'])
  },
  watch: {
    $route() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    redirectToFirstTopic() {
      if (this.faqTopics && this.faqTopics.length) {
        const firstTopic = this.faqTopics[0];

        this.$router
          .replace({
            name: 'faq-topic',
            params: {
              topicSlug: firstTopic.slug
            }
          })
          .catch(() => {});
      }
    },
    init() {
      this.$store
        .dispatch(GET_FAQ_TOPICS)
        .then(() => {
          // do not redirect to first topic in case we're on topic detail
          // in child router-view
          if (!this.$route.params || !this.$route.params.topicSlug) {
            this.redirectToFirstTopic();
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './Faq.scss';
</style>
