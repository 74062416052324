<template>
  <div :class="$style.wrapper" v-if="users && users.length">
    <div>
      <div v-if="group" :class="$style.line" id="UsersGroupCohortTitle">
        <span>Cohort {{ sectionTitle }}</span>
        <p id="yourCohort">YOUR COHORT</p>
      </div>
      <div :class="$style.users">
        <div v-for="user in users" :key="user.user_id" :class="$style.user">
          <router-link
            :to="{ name: 'user-profile', params: { userId: user.user_id } }"
          >
            <span :class="$style.grayScale">
              <span class="with-badge">
                <Avatar :class="$style.avatar" :user="user" />
                <Badge :user="user" />
              </span>
              <h4>
                <UserName :preTitles="true" :user="user" />
              </h4>
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Avatar from '@/components/common/User/Avatar';
import Badge from '@/components/common/User/Badge';

export default {
  components: {
    Avatar,
    Badge
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    sectionTitle: {
      type: String,
      default: 'Contacts'
    },
    group: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['currentUserCohorts'])
  }
};
</script>

<style module lang="scss">
@import './UsersGroup.scss';
</style>
