<template>
  <div :class="$style.wrapper">
    <div :class="$style.imageLayer" role="presentation">
      <div />
    </div>

    <div :class="$style.content">
      <router-link :to="{ name: 'dashboard' }" :class="$style.logo">
        <img
          :src="require(`@/assets/images/logos/theme-${THEME}_logo.png`)"
          role="banner"
          alt="candena"
        />
      </router-link>

      <div :class="$style.container" role="main" class="paragraph-large">
        <slot />
      </div>

      <footer :class="$style.footer" role="contentinfo">
        <nav>
          <a
            href="https://info.edu.edu.mt/privacy-policy/"
            target="_blank"
            rel="noopener"
            >Privacy Policy</a
          >
          <a href="https://info.edu.edu.mt/terms-of-service/"
            >Terms of Service</a
          >
        </nav>

        <nav>
          <a href="https://info.edu.edu.mt/imprint/">Imprint</a>
          <a href="https://info.edu.edu.mt/">About</a>
          <a href="https://info.edu.edu.mt/confidentiality-obligation/"
            >Confidentiality Obligation</a
          >
        </nav>

        <div class="copyright">&copy; EDU {{ new Date().getFullYear() }}</div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.THEME = process.env.THEME;
  }
};
</script>

<style lang="scss" module>
@import './SessionView.scss';
</style>
