import ApiService from '@/api/api.service';
import { REGISTER_CONFIG } from '@/config/registration';

import { SET_DOMAIN, SET_DOMAIN_COUNTRIES } from './mutations.type';
import { GET_DOMAIN, GET_DOMAIN_COUNTRIES } from './actions.type';

export const state = {
  domain: {},
  countries: []
};

export const getters = {
  domain(state) {
    return state.domain;
  },
  domainCountries(state) {
    return state.countries;
  },
  emailRegex(state) {
    if (
      !state.domain ||
      !state.domain.user_account_policies ||
      !state.domain.user_account_policies.email_regex
    ) {
      return REGISTER_CONFIG.defaultEmailRegex;
    }

    return state.domain.user_account_policies.email_regex;
  },
  passwordRegex(state) {
    if (
      !state.domain ||
      !state.domain.user_account_policies ||
      !state.domain.user_account_policies.password_regex
    ) {
      return REGISTER_CONFIG.defaultPasswordRegex;
    }

    return state.domain.user_account_policies.password_regex;
  }
};

export const actions = {
  [GET_DOMAIN_COUNTRIES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getDomainCountries()
        .then(({ data }) => {
          context.commit(SET_DOMAIN_COUNTRIES, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DOMAIN](context) {
    return new Promise((resolve, reject) => {
      ApiService.getDomainMetadata()
        .then(({ data }) => {
          context.commit(SET_DOMAIN, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [SET_DOMAIN](state, domain) {
    state.domain = domain;
  },
  [SET_DOMAIN_COUNTRIES](state, countries) {
    state.countries = countries;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
