<template>
  <article
    :class="{
      [$style.wrapper]: true,
      [$style.deleted]: comment.deleted_at ? true : false
    }"
  >
    <Avatar :user="{}" size="36px" :class="$style.avatar" />

    <strong :class="$style.author">
      <UserName
        :user="{ user_id: undefined }"
        :preTitles="false"
        :postTitles="false"
        class="user-name"
      />
    </strong>

    <span>
      <!-- Delete button won't appear on an already deleted message -->
    </span>

    <span :class="$style.content">This comment was deleted.</span>
  </article>
</template>

<script>
import moment from 'moment';
import Avatar from '@/components/common/User/Avatar';
import { mapGetters, mapActions } from 'vuex';
import { emojisDefault } from '@/components/common/EmojiPickerCustom/emojis.js';

import { MAYBE_GET_USER_PROFILE } from '@/store/actions.type';

export default {
  name: 'CommentDeleted',
  components: {
    Avatar
  },
  data() {
    return {
      shouldDisplayPicker: false
    };
  },
  props: {
    comment: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'currentUserId'])
  },
  created() {
    this.MAYBE_GET_USER_PROFILE(this.comment.user_id);
  },
  methods: {
    ...mapActions([MAYBE_GET_USER_PROFILE]),
    replyTo(comment) {
      this.$emit('reply_to', comment);
    }
  }
};
</script>

<style lang="scss" module>
@import './Comment.scss';
</style>
