<template>
  <div :class="$style.wrapper">
    <div :class="$style.voting">
      <div :class="$style.icons">
        <div :class="$style.icon">
          <div v-if="!isLoading && !isBookmarked" @click="onAddBookmark">
            <i class="icon icon-bookmark-o" />
          </div>

          <div v-else-if="!isLoading && isBookmarked" @click="onRemoveBookmark">
            <i class="icon icon-bookmark" />
          </div>

          <div v-else-if="isLoading">
            <i class="icon icon-hourglass-2" />
          </div>
        </div>

        <div :class="$style.thumbs">
          <div :class="$style.icon">
            <i
              class="icon icon-thumbs-o-up1"
              @click="vote('thumbsUp')"
              v-if="!currentUserVotedUp"
            />
            <i
              class="icon icon-thumbs-up"
              @click="vote('thumbsUp')"
              v-if="currentUserVotedUp"
            />
            <span>{{ upvotes }}</span>
          </div>
          <div :class="$style.icon">
            <i
              class="icon icon-thumbs-o-down"
              @click="vote('thumbsDown')"
              v-if="!currentUserVotedDown"
            />
            <i
              class="icon icon-thumbs-down"
              @click="vote('thumbsDown')"
              v-if="currentUserVotedDown"
            />
            <span>{{ downvotes }}</span>
          </div>
        </div>

        <div :class="$style.share" @click="copyURL()" title="Copy url">
          <i class="icon icon-share-square-o" />
          <i id="copy" :class="$style.isCopied" class="icon icon-check" />
        </div>
      </div>
    </div>

    <div
      :class="{
        [$style.feedbackButtons]: true,
        [$style.showFeedbackOptions]: showFeedbackOptions
      }"
    >
      <div class="text-dark-grey" :class="$style.feedbackText">
        <p :class="$style.thankMessage">
          Thank you for your feedback!
        </p>
        <p>Why did you not like this resource?</p>
      </div>
      <!-- will be generated when we recieve choices from BE-->
      <div :class="$style.buttons">
        <FormButton
          v-for="reason in reasons"
          :key="reason"
          @click="currentUserVotedDownReason(reason)"
          :ghost="true"
          >{{ reason }}</FormButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/helpers/common';
import { mapGetters } from 'vuex';

import {
  GET_RESOURCE_FEEDBACK,
  DELETE_RESOURCE_FEEDBACK,
  POST_RESOURCE_BOOKMARK,
  DELETE_RESOURCE_BOOKMARK,
  GET_RESOURCE
} from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentUserVotedUp: false,
      currentUserVotedDown: false,
      showFeedbackOptions: false,
      reasons: [
        'Medically irrelevant',
        'Poorly structured',
        'Too difficult',
        'Too easy',
        'Too general',
        'Unstimulating',
        'User unfriendly',
        'Inaccessible'
      ],
      isLoading: false,
      upvotes: null,
      downvotes: null
    };
  },
  mounted() {
    this.fetchResource();

    if (this.resource.feedback.is_upvote == null) return;

    return this.resource.feedback.is_upvote == true
      ? (this.currentUserVotedUp = true)
      : (this.currentUserVotedDown = true);
  },
  computed: {
    ...mapGetters(['hasResourceBookmark']),
    isBookmarked() {
      return this.hasResourceBookmark(this.resource.slug);
    }
  },
  methods: {
    onAddBookmark() {
      this.isLoading = true;
      this.$store
        .dispatch(POST_RESOURCE_BOOKMARK, this.resource.slug)
        .then(() => {
          setTimeout(() => (this.isLoading = false), 150);
        })
        .catch(error => {
          this.isLoading = false;
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    onRemoveBookmark() {
      this.isLoading = true;
      this.$store
        .dispatch(DELETE_RESOURCE_BOOKMARK, this.resource.slug)
        .then(() => {
          setTimeout(() => (this.isLoading = false), 150);
        })
        .catch(error => {
          this.isLoading = false;
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchResource() {
      this.$store.dispatch(GET_RESOURCE, this.resource.slug).then(resource => {
        this.upvotes = resource.up_votes;
        this.downvotes = resource.down_votes;
      });
    },
    capitalizeType(value) {
      return capitalize(value);
    },
    copyURL() {
      var dummy = document.createElement('input');
      var url = window.location.href;
      var el = this.$el.querySelector('#copy');

      this.$el.appendChild(dummy);
      dummy.value = url;
      dummy.select();
      document.execCommand('copy');
      this.$el.removeChild(dummy);

      if (document.execCommand('copy') == true) {
        el.style.opacity = '100';
        setTimeout(() => {
          el.style.opacity = '0';
        }, 800);
      }
    },
    currentUserVotedDownReason(reason) {
      this.$store.dispatch(GET_RESOURCE_FEEDBACK, {
        resourceSlug: this.resource.slug,
        is_upvote: false,
        note: reason
      });

      this.showFeedbackOptions = false;
    },
    vote(vote) {
      if (vote === 'thumbsUp') {
        if (this.currentUserVotedUp === true) {
          this.currentUserVotedUp = false;
          return this.$store
            .dispatch(DELETE_RESOURCE_FEEDBACK, this.resource.slug)
            .then(() => {
              this.fetchResource();
            });
        }

        this.currentUserVotedDown = false;
        this.currentUserVotedUp = true;
        this.showFeedbackOptions = false;

        this.$store
          .dispatch(GET_RESOURCE_FEEDBACK, {
            resourceSlug: this.resource.slug,
            is_upvote: true,
            note: 'upvote'
          })
          .then(() => {
            this.fetchResource();
          });
      }

      if (vote === 'thumbsDown') {
        if (this.currentUserVotedDown === false) {
          this.currentUserVotedUp = false;
          this.showFeedbackOptions = true;

          this.$store
            .dispatch(GET_RESOURCE_FEEDBACK, {
              resourceSlug: this.resource.slug,
              is_upvote: false,
              note: 'downvote'
            })
            .then(() => {
              this.fetchResource();
            });
        } else {
          this.showFeedbackOptions = false;

          this.$store
            .dispatch(DELETE_RESOURCE_FEEDBACK, this.resource.slug)
            .then(() => {
              this.fetchResource();
            });
        }

        this.currentUserVotedDown = !this.currentUserVotedDown;
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './ResourceFeedback.scss';
</style>
