<template>
  <div :class="$style.messageForm">
    <form
      :class="{ [$style.formMobile]: !message.length }"
      @submit="onSendMessage"
      @keydown.shift.enter="onSendMessage"
      @keydown.enter.exact="onSendMessage"
    >
      <DoNotTrack style="width: 100%">
        <div :class="$style.textareaWrapper">
          <div :class="$style.expander" data-ref="expander">
            <textarea
              v-model="message"
              placeholder="Write your message..."
              @input="updateTextAreaHeight"
              :maxlength="maxCharLength"
              rows="1"
            />
          </div>
        </div>
      </DoNotTrack>
      <FormButton
        type="submit"
        :standard="true"
        :disabled="currentConversation ? null : true"
        :class="$style.submit"
      >
        <i class="icon icon-paper-plane" />
      </FormButton>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { logError } from '@/helpers/errors';
import { charactersCounter } from '@/helpers/conversations';

import { SEND_MESSAGE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    // Promise, accepts message through parameter
    onMessageSend: {
      type: Function
    }
  },
  data() {
    return {
      message: '',
      maxCount: 4000
    };
  },
  computed: {
    ...mapGetters('chats', ['currentConversation']),
    maxCharLength() {
      if (this.countdown === '') return 0;

      return this.countdown > 0 ? this.maxCount : 0;
    },
    countdown() {
      return charactersCounter(this.message, this.maxCount);
    },
    trimmedMessage() {
      if (this.message === null) {
        return '';
      }

      return this.message.trim();
    }
  },
  methods: {
    ...mapActions('chats', [SEND_MESSAGE]),
    updateTextAreaHeight(event) {
      // https://css-tricks.com/auto-growing-inputs-textareas/
      this.$el.querySelector('[data-ref=expander]').dataset.value =
        event.target.value;
    },
    onSendMessage(evt) {
      if (!this.currentConversation) return;

      if (evt.shiftKey && evt.keyCode === 13) {
        return;
      }

      evt.preventDefault();

      this.$emit('sendMessage', this.message);

      if (this.trimmedMessage) {
        this.onMessageSend(this.trimmedMessage)
          .then(() => {
            this.message = '';
            this.updateTextAreaHeight({ target: { value: '' } });
          })
          .catch(error => {
            logError(error);
          });
      }

      this.$el.querySelector('textarea').style.height = 'auto';
    }
  }
};
</script>

<style lang="scss" module>
@import './MessageForm.scss';
</style>
