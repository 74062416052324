<template>
  <span
    :data-mf-replace="placeholder"
    :class="{
      [$style.debug]: debug
    }"
  >
    <span v-if="debug" :class="$style.placeholder">{{ placeholder }}</span>
    <slot v-else />
  </span>
</template>

<script>
export default {
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '***REDACTED***'
    }
  }
};
</script>

<style lang="scss" module>
@import './DoNotTrack.scss';
</style>
