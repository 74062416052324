import moment from 'moment';
import ApiService from '@/api/api.service';
import { logError } from '@/helpers/errors';

import { QUESTION_KIND } from '@/config/assessments';
import { RESET_STATE } from '@/store/mutations.type';
import { playerStates } from '@/config/quizPlayer';

export const state = {
  attempt: undefined,
  reverseAllowed: false,
  reverseActive: false,
  resultsView: false,
  continueActive: false
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

const getLastAttempt = attempts => {
  if (!Array.isArray(attempts)) return undefined;

  const sortedAttempts = attempts.sort((a, b) => {
    const m_updated_a = moment(a.updated_at);
    const m_updated_b = moment(b.updated_at);

    return m_updated_a.isSame(m_updated_b)
      ? 0
      : m_updated_a.isAfter(m_updated_b)
      ? 1
      : -1;
  });

  const lastAttempt = sortedAttempts[sortedAttempts.length - 1];

  return lastAttempt ? lastAttempt : undefined;
};

export const getters = {
  attempt() {
    return state.attempt;
  },
  attemptId(state, getters) {
    return getters.attempt ? parseInt(getters.attempt.attempt_id) : NaN;
  },
  quizId(state, getters) {
    if (Number.isNaN(getters.attemptId)) return NaN;

    return getters.attempt ? parseInt(getters.attempt.quiz.quiz_id) : NaN;
  },
  quizName(state, getters) {
    if (Number.isNaN(getters.attemptId)) return undefined;

    return getters.attempt && getters.attempt.quiz && getters.attempt.quiz.name;
  },
  questions(state) {
    return (state.attempt && state.attempt.questions) || [];
  },
  answeredList(state, getters) {
    return getters.questions.map((question, questionIndex) => {
      // For single choice questions
      if (question.max_choices && parseInt(question.max_choices) === 1) {
        if (question.options.find(option => option.selected === true))
          return questionIndex;
      }

      // For multiple choices questions
      if (!question.maxChoices || parseInt(question.max_choices) !== 1) {
        if (question.options.some(option => option.selected === true))
          return questionIndex;
      }

      return null;
    });
  },
  hasAllQuestionsAnswered(state, getters) {
    const total = getters.questions.length;

    return (
      getters.answeredList.filter(answer => answer !== null).length === total
    );
  },
  firstNotAnsweredQuestionIndex(state, getters) {
    // Return first question for ended assessments
    if (getters.hasEnded) return 0;

    // Find already saved answers in questions
    const answeredListClone = [...getters.answeredList];

    // Find first null = find first not answered question index
    const notAnsweredIndex = answeredListClone.findIndex(item => item === null);

    // All answered, but assessment not ended -> return the last question
    if (notAnsweredIndex === -1 && !getters.hasEnded)
      return answeredListClone.pop();

    // Return found index or first question as a fallback
    return notAnsweredIndex ? notAnsweredIndex : 0;
  },
  reverseAllowed(state) {
    return state.reverseAllowed;
  },
  reverseActive(state) {
    return state.reverseActive;
  },
  hasEnded(state) {
    const hasEndAt = !!state.attempt && state.attempt.finished_at;
    const hasEnded = hasEndAt && moment(state.attempt.finished_at).isValid();

    if (hasEnded) {
      return true;
    }

    return undefined;
  },
  hasStarted(state) {
    return state.attempt && moment(state.attempt.created_at).isValid();
  },
  lifecycleState(state, getters) {
    const reverseAllowed = state.reverseAllowed;
    const reverseActive = state.reverseActive;
    const notAnsweredIndex = getters.firstNotAnsweredQuestionIndex;
    const resultsView = state.resultsView;

    if (!getters.hasStarted && !getters.hasEnded) {
      // Meaning that the test was never taken before
      return playerStates.new;
    } else if (
      getters.hasStarted &&
      !getters.hasEnded &&
      notAnsweredIndex === 0
    ) {
      // Meaning that none of the questions has an answer yet
      return playerStates.running;
    } else if (
      getters.hasStarted &&
      !getters.hasEnded &&
      notAnsweredIndex > 0 &&
      state.continueActive
    ) {
      // Meaning that some questions have an answer, and user chose to continue the test
      return playerStates.running;
    } else if (
      getters.hasStarted &&
      !getters.hasEnded &&
      notAnsweredIndex > 0 &&
      !state.continueActive
    ) {
      // Meaning that some questions were answered & we're waiting for user to decide if he/she wants to continue
      return playerStates.resume;
    } else if (
      getters.hasStarted &&
      getters.hasEnded &&
      reverseAllowed &&
      !reverseActive &&
      !resultsView
    ) {
      // Meaning that test was submitted & we're waiting for user to decide what's going to be next action
      return playerStates.completed;
    } else if (
      getters.hasStarted &&
      getters.hasEnded &&
      reverseAllowed &&
      reverseActive &&
      !resultsView
    ) {
      // Meaning that test was submitted and user chose to go back to see the answers (can't change them tho)
      return playerStates.retrospect;
    } else if (
      getters.hasStarted &&
      getters.hasEnded &&
      reverseAllowed &&
      !reverseActive &&
      resultsView
    ) {
      // Meaning that test was submitted and user chose to go to see results
      return playerStates.results;
    } else if (
      getters.hasStarted &&
      getters.hasEnded &&
      !reverseAllowed &&
      !resultsView
    ) {
      // Meaning that test was submitted and there's no going back?
      return playerStates.ended;
    } else if (
      getters.hasStarted &&
      getters.hasEnded &&
      !reverseAllowed &&
      resultsView
    ) {
      // Meaning that test was submitted and user should see results
      return playerStates.results;
    } else {
      return undefined;
    }
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      } else {
        state[prop] = undefined;
      }
    }
  },
  setAttempt(state, attempt) {
    state.attempt = attempt;
  },
  setResultsView(state, newValue) {
    state.resultsView = newValue;
  },
  setReverseAllowed(state, newValue) {
    state.reverseAllowed = newValue;
  },
  setReverseActive(state, newValue) {
    state.reverseActive = newValue;
  },
  setContinueActive(state, newValue) {
    state.continueActive = newValue;
  },
  setAttemptAnswer(state, answerPayload) {
    const questions = state.attempt.questions;
    const questionId = answerPayload.answerContent.question_id;

    const foundQuestion = questions.find(
      question => question.question_id === questionId
    );

    if (!foundQuestion) {
      logError(
        `Answer save success, but question ${questionId} not found in store for submissionId ${answerPayload.submissionId}`
      );

      return;
    }

    const optionsToCheck = answerPayload.answerContent.checked_option_ids;

    foundQuestion.options.map(option => {
      if (optionsToCheck.includes(option.option_id)) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    });
  }
};

export const actions = {
  quizzesCurrent() {
    return new Promise((resolve, reject) => {
      ApiService.quizzesCurrent()
        .then(({ data }) => {
          if (Array.isArray(data)) {
            resolve(data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  quizAttempts(context, quizId) {
    if (!quizId) {
      return Promise.reject('No quizId, quizAttempts rejected!');
    }

    return new Promise((resolve, reject) => {
      ApiService.quizAttempts(quizId)
        .then(({ data }) => {
          const lastAttempt = getLastAttempt(data);

          if (lastAttempt) {
            context.commit('setAttempt', lastAttempt);
          }

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAttempt(context, { quizId, attemptId }) {
    if (!quizId || !attemptId) {
      return Promise.reject('No quizId or attemptId, fetchAttempt rejected!');
    }

    return new Promise((resolve, reject) => {
      ApiService.quizAttempt(quizId, attemptId)
        .then(({ data }) => {
          context.commit('setAttempt', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  quizStart(context, quizId) {
    if (!quizId) {
      return Promise.reject('No quizId, quizStart rejected!');
    }

    return new Promise((resolve, reject) => {
      ApiService.quizStart(quizId)
        .then(({ data }) => {
          context.commit('setAttempt', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  quizAttemptEnd(context, { quizId, quizAttemptId }) {
    if (!quizId) {
      return Promise.reject('No quizId, quizAttemptEnd rejected!');
    }

    return new Promise((resolve, reject) => {
      ApiService.quizAttemptEnd(quizId, quizAttemptId)
        .then(({ data }) => {
          context.commit('setAttempt', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  quizAttemptRemove(context, { quizId, quizAttemptId }) {
    if (!quizId) {
      return Promise.reject('No quizId, quizAttemptRemove rejected!');
    }

    return new Promise((resolve, reject) => {
      ApiService.quizAttemptRemove(quizId, quizAttemptId)
        .then(({ data }) => {
          context.commit('setAttempt', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  saveAnswer(context, { quizId, quizAttemptId, answerContent }) {
    return new Promise((resolve, reject) => {
      ApiService.quizAttemptAnswer(quizId, quizAttemptId, answerContent)
        .then(({ data }) => {
          context.commit('setAttemptAnswer', {
            quizId,
            quizAttemptId,
            answerContent
          });

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
