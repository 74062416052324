<template>
  <div :class="$style.wrapper">
    <div :class="$style.inputWrapper">
      <div :class="$style.visibleInputWrapper">
        <ul :class="$style.selectedItems">
          <li @click.stop="flipDropdownVisibility">
            {{ displayDate }}
          </li>
        </ul>
      </div>

      <div
        v-if="dropdownVisible"
        :class="$style.dropdown"
        class="box-shadow-medium"
        v-click-outside="hideDropdown"
      >
        <CalendarWeekSelect
          :selectedWeekStartAt="selectedWeekStartAt"
          @calendar_week_selected="relaySelectEvent"
          :weeks="weeks"
        />
      </div>
      <i
        v-if="dropdownVisible === false"
        :class="$style.inputIcon"
        class="icon icon-chevron-down"
        @click.stop="flipDropdownVisibility"
      />
      <i
        v-if="dropdownVisible === true"
        :class="$style.inputIcon"
        class="icon icon-chevron-up"
        @click.stop="flipDropdownVisibility"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import CalendarWeekSelect from './CalendarWeekSelect';

export default {
  name: 'CalendarWeekInput',
  components: { CalendarWeekSelect },
  directives: { ClickOutside },
  props: {
    selectedWeekStartAt: {
      type: String
    },
    weeks: {
      type: Array
    }
  },
  data() {
    return {
      dropdownVisible: false
    };
  },
  computed: {
    displayDate() {
      const m_weekStart = moment(this.selectedWeekStartAt).startOf('isoWeek');
      const m_weekEnd = moment(this.selectedWeekStartAt).endOf('isoWeek');

      return `${m_weekStart.format('DD.MM')} - ${m_weekEnd.format(
        'DD.MM.YYYY'
      )}`;
    }
  },
  methods: {
    relaySelectEvent(messageToRelay) {
      this.$emit('calendar_week_selected', messageToRelay);
      this.setDropdownVisibility(false);
    },
    setDropdownVisibility(newValue) {
      this.dropdownVisible = newValue;
    },
    flipDropdownVisibility() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    hideDropdown() {
      this.setDropdownVisibility(false);
    }
  }
};
</script>

<style lang="scss" module>
@import './CalendarWeekInput.scss';
</style>
