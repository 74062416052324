<template>
  <span :class="$style.wrapper">
    <FormInput
      v-model="password"
      v-bind="$attrs"
      :type="passwordHidden ? 'password' : 'text'"
      :large="large"
      :shaded="shaded"
    />

    <button
      :class="passwordHidden ? 'icon-eye-slash' : 'icon-eye'"
      type="button"
      class="icon"
      @click="passwordHidden = !passwordHidden"
    />
  </span>
</template>

<script>
import FormInput from '@/components/forms/FormInput/FormInput';

export default {
  components: {
    FormInput
  },
  inheritAttrs: false,
  props: {
    value: {},
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    shaded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      passwordHidden: true
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './FormInputPassword.scss';
</style>
