<template>
  <div>
    <Notice
      :type="level"
      v-if="shouldDisplay"
      :dismissable="false"
      :contentStyle="'max-width: 100%; padding: 0 7px;'"
    >
      <template #icon>
        <i class="icon icon-exclamation-triangle" style="font-size: 21px;" />
      </template>

      <p>
        <slot>{{ firstError.message || firstError }}</slot>
      </p>
    </Notice>
  </div>
</template>

<script>
import Notice from '@/components/notices/Notice/Notice';

export default {
  components: { Notice },
  props: {
    errors: {
      type: Array,
      required: false
    },
    forName: {
      type: String,
      required: true
    },
    level: {
      type: String,
      required: false,
      default: 'warning'
    }
  },
  computed: {
    errorsForName() {
      if (!Array.isArray(this.errors)) return [];
      if (this.errors.length < 1) return [];

      return this.errors.filter(error => error.inputName === this.forName);
    },
    shouldDisplay() {
      return Array.isArray(this.errorsForName) && this.errorsForName.length > 0;
    },
    firstError() {
      if (this.shouldDisplay) {
        return this.errorsForName[0];
      }

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import './FormError.scss';
</style>
