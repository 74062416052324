const attachStyleToDocument = css => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  style.type = 'text/css';

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
};

import { THEMES } from '@/config/themes';

export const setThemeStyles = theme => {
  const { primaryColor } = THEMES[theme];

  const themeStyles = `
    a,
    .ql-editor a {
      color: ${primaryColor};
    }

    .btn-primary {
      border-color: ${primaryColor};
      background: ${primaryColor};
    }

    .btn-primary:hover {
      border-color: ${primaryColor}aa;
      background: ${primaryColor}aa;
    }

    .theme-bg {
      background-color: ${primaryColor};
      color: white;
    }

    .theme-color {
      color: ${primaryColor};
    }

    .theme-border-bottom {
      border-bottom: 2px solid ${primaryColor};
    }

    .theme-border {
      border: 2px solid ${primaryColor};
    }

    .theme-border-color {
      border-color: ${primaryColor};
    }

    .theme-box {
      padding: 14px;
      border-radius: 7px;
      border: 1px solid ${primaryColor};
      background-color: #fdf8ed;
    }
  `;

  attachStyleToDocument(themeStyles);
};
