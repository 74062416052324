import axios from 'axios';
import ApiService from '@/api/api.service';

import {
  SAVE_GRADING,
  CHECK_MISSING_MANDATORY_VALUES,
  RESET_MANDATORY_VALUES_CHECK
} from '@/store/actions.type';

export default {
  namespaced: true,
  state() {
    return {
      attendanceGradeId: undefined,
      attendanceGrade: undefined,
      attendanceGradeComment: undefined,
      attendanceGradeMandatory: undefined,
      attendanceGradeIsMissing: undefined,
      scoreGradeId: undefined,
      scoreGrade: undefined,
      scoreGradeMin: undefined,
      scoreGradeMax: undefined,
      scoreGradeMandatory: undefined,
      scoreGradeIsMissing: undefined
    };
  },
  getters: {
    attendanceGradeId(state) {
      return state.attendanceGradeId;
    },
    attendanceGrade(state) {
      return state.attendanceGrade;
    },
    attendanceGradeComment(state) {
      return state.attendanceGradeComment;
    },
    attendanceGradeIsMissing(state) {
      return state.attendanceGradeIsMissing;
    },
    scoreGradeId(state) {
      return state.scoreGradeId;
    },
    scoreGrade(state) {
      return state.scoreGrade;
    },
    scoreGradeMin(state) {
      return state.scoreGradeMin;
    },
    scoreGradeMax(state) {
      return state.scoreGradeMax;
    },
    scoreGradeIsMissing(state) {
      return state.scoreGradeIsMissing;
    },
    hasEdits(state) {
      if ([true, false].includes(state.attendanceGrade)) return true;
      if (state.scoreGrade) return true;
      if (state.attendanceGradeComment) return true;

      return false;
    }
  },
  actions: {
    RESET_MANDATORY_VALUES_CHECK: {
      root: false,
      handler({ commit }) {
        return new Promise((resolve, reject) => {
          commit('attendanceGradeIsMissingSet', undefined);
          commit('scoreGradeIsMissingSet', undefined);
          resolve();
        });
      }
    },
    CHECK_MISSING_MANDATORY_VALUES: {
      root: false,
      handler({ state, commit }, payload) {
        // console.warn('payload', payload);

        return new Promise((resolve, reject) => {
          let anythingMissing = [];

          commit('attendanceGradeIsMissingSet', undefined);
          if (
            state.attendanceGradeMandatory === true &&
            ![true, false].includes(state.attendanceGrade)
          ) {
            commit('attendanceGradeIsMissingSet', true);
            anythingMissing.push(true);
          } else {
            commit('attendanceGradeIsMissingSet', false);
          }

          commit('scoreGradeIsMissingSet', undefined);
          if (
            state.scoreGradeMandatory === true &&
            Number.isNaN(parseFloat(state.scoreGrade))
          ) {
            commit('scoreGradeIsMissingSet', true);
            anythingMissing.push(true);
          } else {
            commit('scoreGradeIsMissingSet', false);
          }

          resolve(anythingMissing.some(result => result));
        });
      }
    },
    [SAVE_GRADING](
      context,
      {
        assignmentId = undefined,
        scheduledAssignmentId = undefined,
        payload = {}
      }
    ) {
      if (!assignmentId)
        return Promise.reject('No assignmentId. Action aborted.');
      if (!scheduledAssignmentId)
        return Promise.reject('No scheduledAssignmentId. Action aborted.');
      if (!payload || !Object.values(payload).length) {
        return Promise.reject('No payload. Action aborted.');
      }

      return new Promise((resolve, reject) => {
        ApiService.saveGrading(assignmentId, scheduledAssignmentId, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    attendanceGradeIdSet(state, value) {
      state.attendanceGradeId = value;
    },
    attendanceGradeSet(state, value) {
      state.attendanceGrade = value;
    },
    attendanceGradeCommentSet(state, value) {
      state.attendanceGradeComment = value;
    },
    attendanceGradeMandatorySet(state, value) {
      state.attendanceGradeMandatory = value;
    },
    attendanceGradeIsMissingSet(state, value) {
      state.attendanceGradeIsMissing = value;
    },
    scoreGradeIdSet(state, value) {
      state.scoreGradeId = value;
    },
    scoreGradeSet(state, value) {
      state.scoreGrade = value;
    },
    scoreGradeMinSet(state, value) {
      state.scoreGradeMin = value;
    },
    scoreGradeMaxSet(state, value) {
      state.scoreGradeMax = value;
    },
    scoreGradeMandatorySet(state, value) {
      state.scoreGradeMandatory = value;
    },
    scoreGradeIsMissingSet(state, value) {
      state.scoreGradeIsMissing = value;
    }
  }
};
