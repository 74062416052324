<template>
  <div v-if="post" :class="$style.wrapper">
    <TopicPostAuthor :user="post.author" />

    <div :class="$style.content">
      <div :class="$style.stats">
        <time v-if="post" :datetime="post.created_at">{{ createdAt }}</time>
      </div>

      <div :class="$style.contentWrapper"><p v-html="post.content" /></div>
      <div :class="$style.separator" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

// import TopicPostQuote from '@/components/discussions/TopicPostQuote/TopicPostQuote';

import TopicPostAuthor from '@/components/discussions/TopicPostAuthor/TopicPostAuthor';

export default {
  components: {
    TopicPostAuthor
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    createdAt() {
      return moment(this.post.created_at).format('DD MMM, YYYY | HH:mm');
    }
  }
};
</script>

<style lang="scss" module>
@import './TopicPost.scss';
</style>
