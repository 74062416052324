var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper,style:(("border-color: " + (_vm.getProviderColor(
      _vm.resource
    )) + "; box-shadow: 2px 2px 0px 2px " + (_vm.getProviderColor(_vm.resource))))},[_c('router-link',{attrs:{"to":{
      name: 'library-resource',
      params: { resourceSlug: _vm.resource.slug }
    },"target":"_blank"}},[_c('header',{attrs:{"title":_vm.resource.title}},[(_vm.getLibraryIcon(_vm.resource))?_c('img',{attrs:{"src":require(("@/assets/images/icons/library-icons/" + (_vm.getLibraryIcon(
            _vm.resource
          ))))}}):_vm._e(),(_vm.getProviderLogo(_vm.resource))?_c('img',{attrs:{"src":require(("@/assets/images/logos/" + (_vm.getProviderLogo(_vm.resource))))}}):_vm._e()])]),_c('router-link',{attrs:{"to":{
      name: 'library-resource',
      params: { resourceSlug: _vm.resource.slug }
    },"target":"_blank"}},[_c('main',{attrs:{"title":_vm.resource.title}},[_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1),expression:"1",arg:"3"}]},[_vm._v(_vm._s(_vm.resource.title))])])]),_c('footer',[_c('div',[_vm._t("default")],2),_c('ResourceBookmark',{attrs:{"resourceSlug":_vm.resource.slug,"wrapperStyle":"width: 32px; height: 24px; font-size: 20px;"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }