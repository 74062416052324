import ApiService from '@/api/api.service';

import {
  CHANGE_USER_SETTING,
  GET_USER_SETTINGS,
  GET_USER_SETTING
} from './actions.type';

import {
  ADD_USER_SETTINGS,
  PURGE_USER_SETTINGS,
  RESET_STATE
} from './mutations.type';

import { addObjToArray } from '@/helpers/common';

export const state = {
  userSettings: []
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

const convertTypeValue = settingObject => {
  if (!settingObject) return undefined;

  if (settingObject.type === 'boolean') {
    return ['true', 'True'].includes(settingObject.value);
  }

  if (settingObject.type === 'integer') {
    return parseInt(settingObject.value, 10);
  }

  if (settingObject.type === 'string') {
    return settingObject.value;
  }

  return new TypeError('SettingObject conversion failed');
};

export const getters = {
  userSettings(state) {
    return state.userSettings;
  },

  userSetting: state => settingCode => {
    return state.userSettings.find(
      settingObject => settingObject.api_code === settingCode
    );
  },

  userSettingValue: state => (settingCode, attribute = 'value') => {
    const found = state.userSettings.find(
      settingObject => settingObject.api_code === settingCode
    );

    return found ? found[attribute] : undefined;
  }
};

export const actions = {
  [GET_USER_SETTINGS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getUserSettings(params)
        .then(({ data }) => {
          const convertedData = data.map(settingObject => {
            return {
              ...settingObject,
              ...{ value: convertTypeValue(settingObject) }
            };
          });

          context.commit(ADD_USER_SETTINGS, convertedData);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_USER_SETTING](context, apiCode) {
    return new Promise((resolve, reject) => {
      ApiService.getUserSetting(apiCode)
        .then(({ data }) => {
          const convertedData = [data].map(settingObject => {
            return {
              ...settingObject,
              ...{ value: convertTypeValue(settingObject) }
            };
          });

          context.commit(ADD_USER_SETTINGS, convertedData);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CHANGE_USER_SETTING](context, { apiCode, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.putUserSetting(apiCode, payload)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [ADD_USER_SETTINGS](state, userSettings) {
    userSettings.map(setting => {
      state.userSettings = addObjToArray(
        state.userSettings,
        setting,
        'api_code'
      );
    });
  },
  [PURGE_USER_SETTINGS](state) {
    state.userSettings = initialStateCopy.userSettings;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
