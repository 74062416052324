<template>
  <div :class="$style.wrapper" class="box-shadow-white p-2">
    <div :class="$style.header">
      <h6>Create a new discussion</h6>
      <i class="icon icon-close" @click="onClose" />
    </div>

    <div data-ref="subject">
      <p>Subject</p>
      <span :class="$style.subject">
        <span :class="$style.subjectWrapper">
          <FormInput v-model="subject" type="text" />
          <FormFieldErrors :errors="newTopicSubjectErrors" />
        </span>
      </span>
    </div>

    <div data-ref="content">
      <p>Description</p>
      <Editor
        v-model="content"
        :config="{
          toolbar: {
            viewportTopOffset:
              userSettingValue(SETTING_CODES.FIXED_NAV) === true ? 0 : 80,
            items: [
              'heading',
              '|',
              'Bold',
              'Italic',
              'bulletedList',
              'blockQuote',
              'link',
              '|',
              'Undo',
              'Redo'
            ]
          }
        }"
      ></Editor>
      <FormFieldErrors :errors="newTopicContentErrors" />
    </div>

    <FormButton :ghost="true" @click.prevent="onSubmit">
      Send
    </FormButton>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Editor from '@/components/common/Editor/Editor';
import { SETTING_CODES } from '@/config/userSettings';

export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters('forumTopics', [
      'newTopicSubject',
      'newTopicContent',
      'newTopicSubjectErrors',
      'newTopicContentErrors'
    ]),
    ...mapGetters(['userSettingValue']),
    subject: {
      get() {
        return this.newTopicSubject;
      },
      set(value) {
        this.setNewTopicSubject(value);
      }
    },
    content: {
      get() {
        return this.newTopicContent;
      },
      set(value) {
        this.setNewTopicContent(value);
      }
    }
  },
  methods: {
    ...mapMutations('forumTopics', [
      'setNewTopicSubject',
      'setNewTopicContent'
    ]),
    onClose() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('submit');
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;
  }
};
</script>

<style lang="scss" module>
@import './TopicNewForm.scss';
</style>
