<template>
  <div :class="$style.wrapper">
    <ul v-if="slicedUsers.length">
      <li
        v-for="(user, i) in slicedUsers"
        :key="i"
        :class="{ [$style.activeItem]: activeUserId === user.user_id }"
      >
        <a @click="onClick(user)">
          <Avatar :user="user" :size="size" :class="$style.avatar" />
          <div :class="$style.details">
            <DoNotTrack>
              <div
                v-html="highlightMatches(userFullName(user), searchRegExp)"
              />
            </DoNotTrack>
          </div>
        </a>
      </li>
    </ul>
    <template v-else>
      <slot name="empty">
        The list is empty
      </slot>
    </template>
  </div>
</template>

<script>
import Avatar from '@/components/common/User/Avatar';
import { wrapAllMatches } from '@/helpers/common';
import { getUserFullName } from '@/helpers/users';

export default {
  components: {
    Avatar
  },
  props: {
    users: {
      type: Array
    },
    // -1 unlimited
    maxLength: {
      type: Number,
      default: -1
    },
    size: {
      type: String
    },
    searchRegExp: {
      type: RegExp
    },
    activeUserId: {
      type: Number
    }
  },
  computed: {
    slicedUsers() {
      if (!this.users) {
        return [];
      }

      if (this.maxLength === -1) {
        return this.users;
      }

      return this.users.slice(0, this.maxLength);
    }
  },
  methods: {
    onClick(user) {
      if (!user) return;

      this.$emit('select', user.user_id);
    },
    userRole(user) {
      if (!user) return '';

      return (user.role && user.role.label) || '';
    },
    userFullName(user) {
      return getUserFullName(user);
    },
    highlightMatches(userName, searchRegExp) {
      return wrapAllMatches(userName, searchRegExp);
    }
  }
};
</script>

<style module lang="scss">
@import './AvatarList.scss';
</style>
