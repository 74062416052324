<template>
  <SideWidget class="box-shadow-white p-2" :title="false">
    <template #content>
      <h6>Social</h6>
      <div
        :class="{
          [$style.link]: true,
          [$style.invalid]: isHomepageURLValid === false ? true : false
        }"
      >
        <img :class="$style.socialIcon" src="~@/assets/images/icons/WEB.svg" />

        <FormInputAutoSave
          data-ref="homepageUrl"
          v-model="homepageUrl"
          placeholder="Homepage"
          :action="updateProfile({ homepage_url: homepageUrl })"
          :validation="validateURL()"
          @validation_result="onHomepageValidationResult"
        />

        <span v-if="isHomepageURLValid === false">
          Please enter a valid address.
        </span>
      </div>

      <div
        :class="{
          [$style.link]: true,
          [$style.invalid]: isLinkedinURLValid === false ? true : false
        }"
      >
        <img :class="$style.socialIcon" src="~@/assets/images/icons/LIN.svg" />

        <FormInputAutoSave
          data-ref="linkedinUrl"
          v-model="linkedinUrl"
          placeholder="LinkedIN"
          :action="updateProfile({ linkedin_url: linkedinUrl })"
          :validation="validateURL()"
          @validation_result="onLinkedinValidationResult"
        />

        <span v-if="isLinkedinURLValid === false">
          Please enter a valid address.
        </span>
      </div>

      <div
        :class="{
          [$style.link]: true,
          [$style.invalid]: isTwitterURLValid === false ? true : false
        }"
      >
        <img :class="$style.socialIcon" src="~@/assets/images/icons/TW.svg" />

        <FormInputAutoSave
          data-ref="twitterUrl"
          v-model="twitterUrl"
          placeholder="Twitter"
          :action="updateProfile({ twitter_url: twitterUrl })"
          :validation="validateURL()"
          @validation_result="onTwitterValidationResult"
        />

        <span v-if="isTwitterURLValid === false">
          Please enter a valid address.
        </span>
      </div>

      <div
        :class="{
          [$style.link]: true,
          [$style.invalid]: isFacebookURLValid === false ? true : false
        }"
      >
        <img :class="$style.socialIcon" src="~@/assets/images/icons/FB.svg" />

        <FormInputAutoSave
          data-ref="facebookUrl"
          v-model="facebookUrl"
          placeholder="Facebook"
          :action="updateProfile({ facebook_url: facebookUrl })"
          :validation="validateURL()"
          @validation_result="onFacebookValidationResult"
        />

        <span v-if="isFacebookURLValid === false">
          Please enter a valid address.
        </span>
      </div>

      <div
        :class="{
          [$style.link]: true,
          [$style.invalid]: isInstagramURLValid === false ? true : false
        }"
      >
        <img :class="$style.socialIcon" src="~@/assets/images/icons/INS.svg" />

        <FormInputAutoSave
          data-ref="instagramUrl"
          v-model="instagramUrl"
          placeholder="Instagram"
          :action="updateProfile({ instagram_url: instagramUrl })"
          :validation="validateURL()"
          @validation_result="onInstagramValidationResult"
        />

        <span v-if="isInstagramURLValid === false">
          Please enter a valid address.
        </span>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import { mapGetters } from 'vuex';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import { UPDATE_USER_PROFILE } from '@/store/actions.type';
import { ADD_USER_PROFILE_SETTINGS } from '@/store/mutations.type';
import { SOCIAL_LINKS_SKELETON } from '@/store/users.constants';

export default {
  components: {
    SideWidget
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isHomepageURLValid: undefined,
      isLinkedinURLValid: undefined,
      isTwitterURLValid: undefined,
      isFacebookURLValid: undefined,
      isInstagramURLValid: undefined
    };
  },
  computed: {
    ...mapGetters(['userProfileSettings']),
    userProfileSettingsLinks() {
      const userProfileSettings = this.userProfileSettings(this.user.user_id);
      return userProfileSettings && userProfileSettings.links
        ? userProfileSettings.links
        : SOCIAL_LINKS_SKELETON;
    },
    homepageUrl: {
      get() {
        return this.userProfileSettingsLinks.homepage_url || '';
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          links: {
            ...this.userProfileSettingsLinks,
            ...{
              homepage_url: value
            }
          }
        });
      }
    },
    linkedinUrl: {
      get() {
        return this.userProfileSettingsLinks.linkedin_url || '';
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          links: {
            ...this.userProfileSettingsLinks,
            ...{
              linkedin_url: value
            }
          }
        });
      }
    },
    twitterUrl: {
      get() {
        return this.userProfileSettingsLinks.twitter_url || '';
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          links: {
            ...this.userProfileSettingsLinks,
            ...{
              twitter_url: value
            }
          }
        });
      }
    },
    facebookUrl: {
      get() {
        return this.userProfileSettingsLinks.facebook_url || '';
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          links: {
            ...this.userProfileSettingsLinks,
            ...{
              facebook_url: value
            }
          }
        });
      }
    },
    instagramUrl: {
      get() {
        return this.userProfileSettingsLinks.instagram_url || '';
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          links: {
            ...this.userProfileSettingsLinks,
            ...{
              instagram_url: value
            }
          }
        });
      }
    }
  },
  methods: {
    updateProfile() {
      return newValue => {
        const userProfileSettings = this.userProfileSettings(this.user.user_id);

        return this.$store
          .dispatch(UPDATE_USER_PROFILE, {
            userId: this.user.user_id,
            profileData: {
              links: this.userProfileSettingsLinks,
              city_of_residence: userProfileSettings.city_of_residence // required by API
            }
          })
          .catch(error => {
            void error;
          });
      };
    },
    onHomepageValidationResult(isValid) {
      this.isHomepageURLValid = isValid;
    },
    onLinkedinValidationResult(isValid) {
      this.isLinkedinURLValid = isValid;
    },
    onTwitterValidationResult(isValid) {
      this.isTwitterURLValid = isValid;
    },
    onFacebookValidationResult(isValid) {
      this.isFacebookURLValid = isValid;
    },
    onInstagramValidationResult(isValid) {
      this.isInstagramURLValid = isValid;
    },
    validateURL() {
      return newValue => {
        // Allow empty string in order to allow removing of URL from user profile
        if (newValue === '') return true;

        return this.isValidHttpUrl(newValue);
      };
    },
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    }
  }
};
</script>

<style module lang="scss">
@import './SideWidgetUserProfileSocialSettings.scss';
</style>
