var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
            name: 'admin-recommended-resources-detail',
            params: { resourceId: props.row.resourceId }
          }}},[_vm._v(" "+_vm._s(props.row.id)+" ")])],1):(props.column.field === 'name')?_c('span',[_c('router-link',{class:_vm.$style.resourceName,attrs:{"to":{
            name: 'admin-recommended-resources-detail',
            params: { resourceId: props.row.resourceId }
          }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1):(props.column.field === 'resourceId')?_c('span',[_c('span',{ref:props.row.resourceId,staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.resourceId))])]):(props.column.field === 'author')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.author.user_id }
          }}},[_vm._v(" "+_vm._s(props.row.author.first_name + ' ' + props.row.author.last_name)+" ")]),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.author.user_id))])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }