<template>
  <div :class="$style.wrapper" class="pb-1">
    <vue-good-table :columns="columns" :rows="rows" compactMode>
      <div slot="table-actions">
        <h6 :class="$style.tableHeader">
          Cohort history
        </h6>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'id'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'admin-cohort-detail',
              params: { cohortId: props.row.id }
            }"
          >
            {{ props.row.id }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'name'">
          <router-link
            :to="{
              name: 'admin-cohort-detail',
              params: { cohortId: props.row.id }
            }"
          >
            {{ props.row.name }}
          </router-link>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';

import { GET_ADMIN_STUDENT_DETAIL } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      cohortHistory: [],
      columns: [
        {
          label: 'Cohort ID',
          type: 'number',
          field: 'id'
        },
        {
          label: 'Cohort Name',
          field: 'name'
        },
        {
          label: 'Active from',
          field: 'activeFrom',
          dateInputFormat: 'dd.MM.yyyy',
          dateOutputFormat: 'dd.MM.yyyy'
        },
        {
          label: 'Active to',
          field: 'activeTo',
          dateInputFormat: 'dd.MM.yyyy',
          dateOutputFormat: 'dd.MM.yyyy'
        }
      ],
      rows: []
    };
  },
  created() {
    this.$store
      .dispatch(GET_ADMIN_STUDENT_DETAIL, this.$route.params.userId)
      .then(cohort => {
        this.cohortHistory = cohort.cohort_history;
      })
      .then(() => {
        this.createCohortRows();
      })
      .catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
  },
  methods: {
    formatTime(time) {
      if (!time) {
        return null;
      }

      return moment(time).format('DD.MM.yyyy');
    },
    createCohortRows() {
      if (this.cohortHistory) {
        this.cohortHistory.filter(cohort => {
          this.rows.push({
            id: cohort.cohort_id || '',
            name: cohort.cohort_name || '',
            activeFrom: this.formatTime(cohort.active_from) || '',
            activeTo: this.formatTime(cohort.active_to) || 'Ongoing'
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
