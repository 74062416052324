var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('h6',[_vm._v(" Manage students in this group: ")]),_c('p',{staticClass:"placeholder",class:_vm.$style.notice},[_vm._v(" Carefully! Removing or adding a new student is not an operation that requires approval! ")]),_c('vue-good-table',{ref:"tableData",attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 10,
      position: 'bottom'
    }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"column-filter",fn:function(props){
    var _obj;
return [(props.column.field === 'studentEmail')?_c('span',{class:( _obj = {}, _obj[_vm.$style.allEmails] = true, _obj[_vm.$style.email] = true, _obj ),on:{"click":function($event){return _vm.copyAllEmails()}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/two-envelopes.svg")}}),_c('i',{staticClass:"icon icon-check",class:_vm.$style.isCopied,attrs:{"id":"allEmailsCopied"}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'studentId')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.studentId }
          }}},[_vm._v(" "+_vm._s(props.row.studentId)+" ")])],1):(props.column.field === 'studentFirstName')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.studentId }
          }}},[_vm._v(" "+_vm._s(props.row.studentFirstName)+" ")])],1):(props.column.field === 'studentLastName')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.studentId }
          }}},[_vm._v(" "+_vm._s(props.row.studentLastName)+" ")])],1):(props.column.field === 'studentGroups')?_c('span',[(props.row.studentGroups.length)?_c('p',_vm._l((props.row.studentGroups),function(group,index){return _c('span',{key:index},[(index > 0)?[_vm._v(", ")]:_vm._e(),(group.slug)?_c('router-link',{attrs:{"to":{
                name: 'admin-group-detail',
                params: { userGroupId: group.user_group_id }
              }}},[_vm._v(" "+_vm._s(group.object_name)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(group.object_id))])]):_c('span',[_vm._v(_vm._s(group.object_name)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(group.object_id))])])],2)}),0):_c('p',[_vm._v("-")])]):(props.column.field === 'studentEmail')?_c('span',[_c('div',{class:_vm.$style.email,on:{"click":function($event){return _vm.copyEmail(props.row)}}},[_c('i',{staticClass:"icon icon-envelope"}),_c('i',{staticClass:"icon icon-check",class:_vm.$style.isCopied,attrs:{"id":("id-" + (props.row.studentId))}})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('AdminSearchNewUser',{attrs:{"addUserGroupId":_vm.userGroupId,"eligibleUsersGroupId":_vm.userGroupId},on:{"reloadMembers":_vm.reloadMembers}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }