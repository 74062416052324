<template>
  <div>
    <LayoutConversationsMobile
      v-if="isMobileLayout"
      :class="$style.mobileLayout"
    >
      <template #mobileHeader>
        <router-view
          name="mobileHeader"
          @navigate_up="onNavigateUp"
          @init_existing="openExistingConversation"
          @init_new="openPlaceholderConversation"
        />
      </template>

      <template #mobileContent>
        <router-view name="mobileContent" />
      </template>

      <template #mobileFooter>
        <router-view name="mobileFooter" :onMessageSend="sendMessage" />
      </template>
    </LayoutConversationsMobile>

    <LayoutConversations v-else :class="$style.desktopLayout">
      <template #sideHeader>
        <router-view name="sideHeader" />
      </template>

      <template #sideContent>
        <router-view name="sideContent" />
      </template>

      <template #contentSubHeader>
        <router-view name="contentSubHeader" />
        <router-view name="contentHeader" />
      </template>

      <template #content>
        <router-view name="content" />
      </template>

      <template #contentFooter>
        <router-view name="contentFooter" :onMessageSend="sendMessage" />
      </template>
    </LayoutConversations>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import {
  GET_CONVERSATIONS,
  OPEN_CONVERSATION,
  OPEN_PLACEHOLDER_CONVERSATION,
  SEND_MESSAGE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR, PURGE_CONVERSATION } from '@/store/mutations.type';

import {
  PLACEHOLDER_ID,
  CONVERSATIONS_POLLING_MS
} from '@/config/conversations';
import { RECENT_CONVERSATION_KEY } from '@/config/localStorage';

import LayoutConversations from '@/views/Layout/LayoutConversations';
import LayoutConversationsMobile from '@/views/Layout/LayoutConversationsMobile';

export default {
  name: 'Conversations',
  components: {
    LayoutConversations,
    LayoutConversationsMobile
  },
  data() {
    return {
      intervalID: null
    };
  },
  watch: {
    $route(newValue) {
      // if (newValue && newValue.name && newValue.name === 'conversation-new') {
      //   this.navigateToConversation(PLACEHOLDER_ID);
      //   return;
      // }
      if (newValue && newValue.name && newValue.name === 'conversations') {
        const conversationId = this.getRecentConversationId();
        this.navigateToConversation(conversationId);
        return;
      }
    }
  },
  computed: {
    ...mapGetters(['isMobileLayout']),
    ...mapGetters('chats', ['conversations']),
    routeParamId() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.conversationId
      )
        return this.$route.params.conversationId;

      return undefined;
    }
  },
  async created() {
    try {
      await this.fetchConversations();

      if (this.routeParamId) {
        this.navigateToConversation(this.routeParamId);
      } else {
        const recentConversationId = this.getRecentConversationId();
        this.navigateToConversation(recentConversationId);
      }

      this.intervalID = setInterval(
        this.fetchConversations,
        CONVERSATIONS_POLLING_MS
      );
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, {
        error,
        log: true,
        clientMessage: error
      });
    }
  },
  destroyed() {
    clearInterval(this.intervalID);
    this.PURGE_CONVERSATION();
  },
  methods: {
    ...mapActions('chats', [
      GET_CONVERSATIONS,
      OPEN_CONVERSATION,
      OPEN_PLACEHOLDER_CONVERSATION,
      SEND_MESSAGE
    ]),
    ...mapMutations('chats', [PURGE_CONVERSATION]),
    fetchConversations() {
      return this.GET_CONVERSATIONS().catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      });
    },
    getRecentConversationId() {
      // Get the most recent conversation from localstorage
      const foundRecent = window.localStorage.getItem(RECENT_CONVERSATION_KEY);
      if (foundRecent) return foundRecent;

      // If not found in localstorage try finding id of the first conversation in the list
      const foundFirst =
        this.conversations[0] && this.conversations[0].conversation_id;
      if (foundFirst) return foundFirst;

      return undefined;
    },
    navigateToConversation(conversationId) {
      const foundConversation =
        this.conversations.findIndex(
          c => c.conversation_id === conversationId
        ) >= 0;

      if (this.isMobileLayout) {
        this.$router.push({
          name: 'conversations-list'
        });
      } else if (foundConversation) {
        this.openExistingConversation(conversationId);
      } else {
        this.openNewConversation();
      }
    },
    async openExistingConversation(conversationId) {
      if (!conversationId) return;

      try {
        await this.OPEN_CONVERSATION(conversationId);
        this.$router
          .replace({
            name: 'conversation-existing',
            params: {
              conversationId
            }
          })
          .catch(() => {});
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    openPlaceholderConversation(payload) {
      if (!payload) return;

      this.OPEN_PLACEHOLDER_CONVERSATION(payload)
        .then(() => {
          this.$router.push({
            name: 'conversation-existing',
            params: {
              conversationId: PLACEHOLDER_ID
            }
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        });
    },
    openNewConversation() {
      this.$router
        .replace({
          name: 'conversation-new'
        })
        .catch(() => {});
    },
    async sendMessage(message) {
      if (!message) return;

      try {
        await this.SEND_MESSAGE(message);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: `${error}`
        });
      }
    },
    onNavigateUp() {
      this.$router
        .replace({
          name: 'conversations-list'
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
.desktop-layout {
  height: 100vh;
}

.mobile-layout {
  height: 100vh;

  // Since there's a bug in mobile Webkit
  // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  // and the proposed solution doesn't work in our case
  // let's try and overwrite height for Webkit to 90% of viewport instead of 100%
  @media only screen and (-webkit-min-device-pixel-ratio: 0) {
    height: 90vh;
  }
}
</style>
