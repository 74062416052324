<template>
  <div>
    <NotFound v-if="!currentUserTeamSlug" />
    <Layout variant="5" v-else>
      <template #pageContent>
        <TopWidgetTeamSettings
          :team="teamProfileSettings(currentUserTeamSlug)"
        />
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  GET_TEAM_PROFILE,
  UPDATE_TEAM_PROFILE,
  GET_CURRENT_USER_PROFILE
} from '@/store/actions.type';
import {
  SET_GLOBAL_ERROR,
  PURGE_TEAM_PROFILE_SETTINGS
} from '@/store/mutations.type';

import { pick } from '@/helpers/common';

import Layout from '@/views/Layout/Layout';
import TopWidgetTeamSettings from '@/components/team-profile/TopWidgetTeamSettings/TopWidgetTeamSettings';
import NotFound from '@/components/common/NotFound/NotFound';

export default {
  components: {
    Layout,
    TopWidgetTeamSettings,
    NotFound
  },
  data() {
    return {
      submitDisabled: false
    };
  },
  computed: {
    ...mapGetters([
      'clientTeamProfileErrors',
      'teamProfile',
      'teamProfileSettings',
      'currentUserTeamSlug'
    ]),
    teamName() {
      if (!this.currentUserTeamSlug) {
        return '';
      }

      const team = this.teamProfile(this.currentUserTeamSlug);
      if (team && team.name) {
        return team.name;
      }

      return '';
    }
  },
  async created() {
    try {
      await this.$store.dispatch(GET_CURRENT_USER_PROFILE);

      if (this.currentUserTeamSlug) {
        await this.$store.dispatch(GET_TEAM_PROFILE, this.currentUserTeamSlug);
      } else {
        this.submitDisabled = true;
      }
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  },
  methods: {
    submitProfile() {
      this.submitDisabled = true;

      const profileData = pick(
        this.teamProfileSettings(this.currentUserTeamSlug),
        'name',
        'description'
      );

      this.$store
        .dispatch(UPDATE_TEAM_PROFILE, {
          teamSlug: this.currentUserTeamSlug,
          profileData
        })
        .then(() => {
          this.$store.commit(PURGE_TEAM_PROFILE_SETTINGS);
          this.$router.push({
            name: 'team-profile',
            params: { teamSlug: this.currentUserTeamSlug }
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        })
        .then(() => {
          this.submitDisabled = false; // no matter what, enable saving after API call finished
        });
    }
  }
};
</script>
