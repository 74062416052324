<template>
  <a :href="resourceFilePath" :download="resourceTitle" :title="resourceTitle">
    <slot>{{ resourceFilePath }}</slot>
  </a>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_RESOURCE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  name: 'ResourceDownloadLink',
  props: {
    resource: {
      type: Object,
      validator: value => {
        return value && value.hasOwnProperty('resource_id');
      }
    }
  },
  computed: {
    resourceSlug() {
      if (!this.resource) return undefined;

      return this.resource.slug;
    },
    resourceFilePath() {
      if (!this.resourceFile) return undefined;

      return this.resourceFile.path;
    },
    resourceTitle() {
      if (!this.resource) return undefined;

      return this.resource.title;
    }
  },
  data() {
    return {
      resourceFile: undefined
    };
  },
  methods: {
    ...mapActions([GET_RESOURCE])
  },
  async beforeMount() {
    try {
      const { file } = await this.GET_RESOURCE(this.resourceSlug);
      this.resourceFile = file;
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  }
};
</script>
