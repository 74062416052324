<template>
  <section :class="$style.wrapper">
    <CommentReply
      :class="$style.replyArea"
      :name="`new-comment-${messages.length + 1}`"
      placeholder="Start writing here..."
      @submit="postMessageTo($event, null)"
      @cancel="postMessageTo($event, null)"
    />

    <Notice
      type="warning"
      v-if="hasNewMessagesSinceLastFetch"
      :dismissable="false"
      :class="$style.banner"
      :contentStyle="'max-width:unset;justify-content: center;'"
    >
      <button @click="mergeNewMessages" class="textonly">
        View new comments
      </button>
    </Notice>

    <template v-for="(comment, index_c) in comments">
      <div
        :key="index_c"
        class="thread"
        :class="{
          [$style.thread]: true,
          [$style.withReplies]: hasReplies(comment)
        }"
      >
        <Comment
          v-if="!comment.deleted_at"
          :comment="comment"
          :id="comment.post_id"
          :rel="comment.parent_post_id"
          @reply_to="replyTo"
          @delete_comment="deleteComment"
          @new_reaction="onNewReaction"
          @add_reaction="onIncreaseReactionCount"
          @remove_reaction="onDecreaseReactionCount"
          class="root"
          :isNew="newPostsIds.includes(comment.post_id)"
          :data-cy="`root-comment-${index_c}`"
        />

        <CommentDeleted
          v-else
          :comment="comment"
          :id="comment.post_id"
          :rel="comment.parent_post_id"
          class="root"
          :data-cy="`root-comment-${index_c}`"
        />

        <section
          :class="$style.replies"
          :key="index_c"
          v-if="hasReplies(comment)"
        >
          <template v-if="replies.includes(comment.post_id)">
            <CommentReply
              class="root"
              :class="$style.replyArea"
              :name="`reply-to-${comment.post_id}`"
              placeholder="Type your reply here..."
              @submit="postMessageTo($event, comment.post_id)"
              @cancel="postMessageTo($event, comment.post_id)"
              :modelValue="`${getUserFullNameWithTitles(comment.user)}: `"
              :focused="true"
              :data-cy="`reply-to-${comment.post_id}`"
            />
          </template>

          <template
            v-for="reply in sortingByDate(
              repliesByCommentId[comment.post_id],
              'created_at',
              false
            )"
          >
            <Comment
              :comment="reply"
              :id="reply.post_id"
              :key="reply.post_id"
              :rel="reply.parent_post_id"
              @reply_to="replyTo"
              @delete_comment="deleteComment"
              @new_reaction="onNewReaction"
              @add_reaction="onIncreaseReactionCount"
              @remove_reaction="onDecreaseReactionCount"
              :isNew="newPostsIds.includes(reply.post_id)"
              :data-cy="`reply-${reply.post_id}`"
            />
          </template>

          <template
            v-for="(reply, index_r) in repliesByCommentId[comment.post_id]"
          >
            <template v-if="replies.includes(reply.post_id)">
              <CommentReply
                :class="$style.replyArea"
                :key="index_r"
                :name="`reply-to-${reply.post_id}`"
                placeholder="Type your reply here..."
                @submit="postMessageTo($event, reply.post_id)"
                @cancel="postMessageTo($event, reply.post_id)"
                :modelValue="`${getUserFullNameWithTitles(reply.user)}: `"
                :focused="true"
              >
              </CommentReply>
            </template>
          </template>
        </section>
      </div>
      <hr v-if="index_c < comments.length - 1" :key="`hr-${index_c}`" />
    </template>

    <template v-if="allComments.length > maxThreads">
      <hr />

      <button
        class="textonly"
        @click="honorMaxThreads = !honorMaxThreads"
        :class="$style.loadMore"
      >
        <template v-if="honorMaxThreads">
          See more ({{ allComments.length - maxThreads }})
        </template>
        <template v-else>
          See less
        </template>
      </button>
    </template>

    <p v-if="comments.length === 0" :class="$style.empty">
      No questions yet.
    </p>
  </section>
</template>

<script>
import moment from 'moment';
import Comment from './Comment';
import CommentDeleted from './CommentDeleted';
import CommentReply from './CommentReply';
import Notice from '@/components/notices/Notice/Notice';
import { sortingByDate } from '@/helpers/common';
import { getUserFullNameWithTitles } from '@/helpers/users';
import { mapGetters, mapActions } from 'vuex';

import {
  GET_NEW_DISCUSSIONS_TOPIC_ID,
  GET_NEW_DISCUSSIONS_TOPIC_POSTS,
  DELETE_NEW_DISCUSSIONS_TOPIC_POST,
  POST_NEW_DISCUSSIONS_CONTENT,
  GET_NEW_DISCUSSIONS_REACTIONS,
  POST_NEW_DISCUSSIONS_REACTION
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  name: 'Comments',
  components: {
    Comment,
    CommentDeleted,
    CommentReply,
    Notice
  },
  data() {
    return {
      newTopLevelComment: undefined,
      replies: [],
      showEmojiPicker: false,
      topicId: undefined,
      lastFetchISO: null,
      messages: [],
      messagesSinceLastFetch: [],
      intervalID: null,
      newPostsIds: [],
      ignoreMessagesChange: true,
      honorMaxThreads: true
    };
  },
  props: {
    objectType: {
      type: String,
      required: true
    },
    objectId: {
      type: Number,
      required: true
    },
    maxThreads: {
      type: Number,
      default: 3
    },
    pollingIntervalMs: {
      type: Number,
      default: 1 * 60 * 1000
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
    sortedMessages() {
      return sortingByDate(this.messages, 'created_at', true);
    },
    allComments() {
      if (!Array.isArray(this.sortedMessages)) return [];

      return this.sortedMessages.filter(
        message => message.parent_post_id === null
      );
    },
    comments() {
      return this.honorMaxThreads
        ? this.allComments.slice(0, this.maxThreads)
        : this.allComments;
    },
    commentsIds() {
      return this.comments.map(comment => comment.post_id);
    },
    messagesRoots() {
      return this.sortedMessages.reduce((memo, message) => {
        memo[message.post_id] = this.findTopCommentId(message);

        return memo;
      }, {});
    },
    repliesByCommentId() {
      return this.comments.reduce((memo, comment) => {
        const commentId = comment.post_id;

        const repliesIds = Object.entries(this.messagesRoots)
          .filter(entry => entry[1] === commentId)
          .filter(entry => !this.commentsIds.includes(parseInt(entry[0])))
          .map(entry => parseInt(entry[0]));

        if (!Object.keys(memo).includes(commentId)) {
          memo[commentId] = this.sortedMessages.filter(message =>
            repliesIds.includes(message.post_id)
          );
        }

        return memo;
      }, {});
    },
    newMessagesExceptMine() {
      return this.messagesSinceLastFetch.filter(
        message => message.user.user_id !== this.currentUserId
      );
    },
    hasNewMessagesSinceLastFetch() {
      if (this.newMessagesExceptMine.length > 0) return true;

      return false;
    }
  },
  async created() {
    this.sortingByDate = sortingByDate;
    this.getUserFullNameWithTitles = getUserFullNameWithTitles;

    try {
      this.topicId = await this.GET_NEW_DISCUSSIONS_TOPIC_ID({
        objectType: this.objectType,
        objectId: this.objectId
      });

      await this.fetchInitialMessages();

      this.ignoreMessagesChange = false;
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, {
        error,
        log: true,
        clientMessage: error
      });

      this.ignoreMessagesChange = false;
    }

    this.intervalID = setInterval(this.fetchMessages, this.pollingIntervalMs);
  },
  destroyed() {
    clearInterval(this.intervalID);
  },
  watch: {
    messages(allMessages, oldMessages) {
      if (this.ignoreMessagesChange) return;

      const oldMessagesIds = oldMessages.map(m => m.post_id);
      const allMessagesIds = allMessages.map(m => m.post_id);
      const newMessagesIds = allMessagesIds.filter(
        messageId => !oldMessagesIds.includes(messageId)
      );

      this.newPostsIds = newMessagesIds;
    }
  },
  methods: {
    ...mapActions('discussions', [
      GET_NEW_DISCUSSIONS_TOPIC_ID,
      GET_NEW_DISCUSSIONS_TOPIC_POSTS,
      DELETE_NEW_DISCUSSIONS_TOPIC_POST,
      POST_NEW_DISCUSSIONS_CONTENT,
      GET_NEW_DISCUSSIONS_REACTIONS,
      POST_NEW_DISCUSSIONS_REACTION
    ]),
    fetchInitialMessages() {
      return new Promise(async (resolve, reject) => {
        try {
          this.lastFetchISO = null;
          const received = await this.GET_NEW_DISCUSSIONS_TOPIC_POSTS({
            topicId: this.topicId
          });

          if (Array.isArray(received)) {
            this.messages = received;
            this.lastFetchISO = moment().toISOString();
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchMessages() {
      return new Promise(async (resolve, reject) => {
        try {
          const received = await this.GET_NEW_DISCUSSIONS_TOPIC_POSTS({
            topicId: this.topicId,
            since: this.lastFetchISO
          });

          if (Array.isArray(received)) {
            this.messagesSinceLastFetch = received;
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    async mergeNewMessages() {
      try {
        await this.fetchInitialMessages();
        this.messagesSinceLastFetch = [];
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    hasReplies(message) {
      if (this.replies.includes(message.post_id)) return true;

      if (
        sortingByDate(
          this.repliesByCommentId[message.post_id],
          'created_at',
          false
        ).length
      )
        return true;

      return false;
    },
    findTopCommentId(message) {
      let isRelated = !this.commentsIds.includes(message.post_id);

      if (!isRelated) return message.post_id;

      let parentId = message.parent_post_id;

      while (isRelated) {
        const parentMessage = this.sortedMessages.find(
          m => m.post_id === parentId
        );

        if (!parentMessage || parentMessage.parent_post_id === null) {
          isRelated = false;
        } else {
          parentId = parentMessage.parent_post_id;
        }
      }

      return parentId;
    },
    flipEmojiPicker(maybeShowPicker) {
      this.showEmojiPicker = maybeShowPicker;
    },
    formatCreatedAt(createdAt) {
      return moment(createdAt).fromNow();
    },
    replyTo(message) {
      if (this.replies.length === 1 && this.replies.includes(message.post_id)) {
        this.replies = [];
      } else {
        this.replies = [];
        this.replies.push(message.post_id);
      }
    },
    async addToMessageReactions(originalMessage, reactionObj) {
      await this.fetchInitialMessages();
    },
    async decreaseReactionCount(originalMessage, reactionObj) {
      await this.fetchInitialMessages();
    },
    async increaseReactionCount(originalMessage, reactionObj) {
      await this.fetchInitialMessages();
    },
    async deleteComment(comment) {
      try {
        await this.DELETE_NEW_DISCUSSIONS_TOPIC_POST({
          topicId: this.topicId,
          postId: comment.post_id
        });

        await this.fetchInitialMessages();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async onIncreaseReactionCount({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'discussion_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction
          }
        });

        await this.fetchInitialMessages();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async onDecreaseReactionCount({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'discussion_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction,
            delete: true
          }
        });

        await this.fetchInitialMessages();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async onNewReaction({ reactionObj, comment }) {
      try {
        await this.POST_NEW_DISCUSSIONS_REACTION({
          objectType: 'discussion_post',
          objectId: comment.post_id,
          payload: {
            reaction: reactionObj.reaction
          }
        });

        await this.fetchInitialMessages();
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    },
    async postMessageTo(content, messageId) {
      if (messageId !== null) {
        this.replies = this.replies.filter(r => r.post_id === messageId);
      }

      if (!content) return;

      try {
        await this.POST_NEW_DISCUSSIONS_CONTENT({
          topicId: this.topicId,
          content,
          parentPostId: messageId
        });

        this.ignoreMessagesChange = true;
        await this.fetchInitialMessages();
        this.ignoreMessagesChange = false;
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, {
          error,
          log: true,
          clientMessage: error
        });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './Comments.scss';
</style>
