/* any */
export const RESET_STATE = 'RESET_STATE';

/* auth */
export const PURGE_AUTH = 'PURGE_AUTH';
export const SET_AUTH = 'SET_AUTH';
export const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN';
export const PURGE_PASSWORD_RESET_TOKEN = 'PURGE_PASSWORD_RESET_TOKEN';

/* conversations */
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const SET_CONVERSATION_ID = 'SET_CONVERSATION_ID';
export const MERGE_MESSAGES = 'MERGE_MESSAGES';
export const SET_POLLING_CANCEL_TOKEN = 'SET_POLLING_CANCEL_TOKEN';
export const PURGE_CONVERSATION = 'PURGE_CONVERSATION';
export const SET_READ_TIMEOUT = 'SET_READ_TIMEOUT';
export const SET_PLACEHOLDER_CONVERSATION = 'SET_PLACEHOLDER_CONVERSATION';
export const PURGE_PLACEHOLDER_CONVERSATION = 'PURGE_PLACEHOLDER_CONVERSATION';
export const SET_READY_TO_START = 'SET_READY_TO_START';
export const SET_USERS_SEARCH_RESULTS = 'SET_USERS_SEARCH_RESULTS';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';

/* users */
export const SET_USER_PROFILES = 'SET_USER_PROFILES';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const SET_CURRENT_USER_COHORTS = 'SET_CURRENT_USER_COHORTS';
export const ADD_USER_PROFILE = 'ADD_USER_PROFILE';
export const ADD_USER_PROFILE_SETTINGS = 'ADD_USER_PROFILE_SETTINGS';
export const SET_AVATARS = 'SET_AVATARS';
export const SET_CURRENT_USER_AVATARS = 'SET_CURRENT_USER_AVATARS';
export const PURGE_AVATARS = 'PURGE_AVATARS';
export const SET_AVATAR_UPLOAD_STEP = 'SET_AVATAR_UPLOAD_STEP';
export const SET_AVATAR_UPLOAD_PERCENT = 'SET_AVATAR_UPLOAD_PERCENT';
export const PURGE_AVATAR_UPLOAD = 'PURGE_AVATAR_UPLOAD';
export const PURGE_USER_PROFILE_SETTINGS = 'PURGE_USER_PROFILE_SETTINGS';
export const PURGE_USER_KVSTORE = 'PURGE_USER_KVSTORE';
export const SET_USER_KVSTORE = 'SET_USER_KVSTORE';

/* teams */
export const SET_TEAMS = 'SET_TEAMS';
export const ADD_TEAM_PROFILE_SETTINGS = 'ADD_TEAM_PROFILE_SETTINGS';
export const ADD_TEAM_PROFILE = 'ADD_TEAM_PROFILE';
export const PURGE_TEAM_PROFILE_SETTINGS = 'PURGE_TEAM_PROFILE_SETTINGS';

/* meta */
export const SET_DOMAIN_COUNTRIES = 'SET_DOMAIN_COUNTRIES';
export const SET_DOMAIN = 'SET_DOMAIN';

/* schedule */
export const SET_MODULE_PHASES = 'SET_MODULE_PHASES';
export const SET_MODULES_PHASES = 'SET_MODULES_PHASES';
export const SET_MODULES = 'SET_MODULES';
export const ADD_SESSION_ASSIGNMENTS = 'ADD_SESSION_ASSIGNMENTS';
export const SET_AS_USER_ID = 'SET_AS_USER_ID';
export const SET_AS_COHORT_ID = 'SET_AS_COHORT_ID';
export const SET_AS_USER_PROFILE = 'SET_AS_USER_PROFILE';

/* common */
export const SET_DESKTOP_LAYOUT = 'SET_DESKTOP_LAYOUT';
export const SET_TABLET_LAYOUT = 'SET_TABLET_LAYOUT';
export const SET_PHONE_LAYOUT = 'SET_PHONE_LAYOUT';
export const SET_USERS_STREAM_EVENTS = 'SET_USERS_STREAM_EVENTS';
export const SET_CALENDAR_URL = 'SET_CALENDAR_URL';
export const ADD_ANNOUNCEMENTS = 'ADD_ANNOUNCEMENTS';
export const REMOVE_ANNOUNCEMENTS = 'REMOVE_ANNOUNCEMENTS';

/* meta */
export const SET_LOCALE = 'SET_LOCALE';

/* errors */
export const ADD_CLIENT_ERRORS = 'ADD_CLIENT_ERRORS';
export const REMOVE_CLIENT_ERRORS = 'REMOVE_CLIENT_ERRORS';
export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';
export const PURGE_GLOBAL_ERROR = 'PURGE_GLOBAL_ERROR';

/* faqs */
export const SET_FAQ_TOPICS = 'SET_FAQ_TOPICS';

/* resources */
export const ADD_RESOURCE_GROUP = 'ADD_RESOURCE_GROUP';
export const SET_RESOURCE_GROUPS = 'SET_RESOURCE_GROUPS';
export const SET_RESOURCE_BOOKMARKS = 'SET_RESOURCE_BOOKMARKS';
export const SET_RESOURCE_PROVIDERS = 'SET_RESOURCE_PROVIDERS';
export const SET_RESOURCES_LOGICAL_TYPES = 'SET_RESOURCES_LOGICAL_TYPES';
export const SET_RESOURCE_FEEDBACK = 'SET_RESOURCE_FEEDBACK';
export const SET_RESOURCE_UPLOAD_IN_PROGRESS =
  'SET_RESOURCE_UPLOAD_IN_PROGRESS';

export const ADD_USER_SETTINGS = 'ADD_USER_SETTINGS';
export const PURGE_USER_SETTINGS = 'PURGE_USER_SETTINGS';

/* admin */
export const SET_ADMIN_USERS_SEARCH_RESULTS = 'SET_ADMIN_USERS_SEARCH_RESULTS';
