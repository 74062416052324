import {
  MEDIA_TYPES,
  MIME_TYPES_BY_FILE_EXTENSION,
  FILENAME_MAX_LENGTH
} from '@/config/files';
import { logError } from './errors';

export class FileTooLargeError extends Error {}
export class FileNameTooLongError extends Error {}
export class InvalidFileTypeError extends Error {}

export const mimeTypeToUploadType = mimeType => {
  const found = MEDIA_TYPES.find(mediaType => mediaType.mime === mimeType);

  return found ? found.uploadType : undefined;
};

export const mimeTypesToExtensions = mimeTypesArray => {
  if (!Array.isArray(mimeTypesArray)) return [];

  if (mimeTypesArray.length === 0) {
    return Object.keys(MIME_TYPES_BY_FILE_EXTENSION);
  }

  return Object.entries(MIME_TYPES_BY_FILE_EXTENSION)
    .filter(entry => mimeTypesArray.includes(entry[1]))
    .map(item => item[0]);
};

/**
 * Convert MIME types array to a string of comma separated extensions.
 * Ex. ['application/pdf', 'application/msword'] => ".pdf, .doc"
 * @param {*} mimeTypesArr An array of MIME types (only MIME types specified
 * in config are supported)
 */
export const humanizeMimeTypes = mimeTypesArray => {
  return mimeTypesToExtensions(mimeTypesArray)
    .map(ext => `.${ext}`)
    .join(', ');
};

export const megabytesToBytes = megabytes => megabytes * 1000000;

export const isValidFileName = (
  fileName,
  maxFileNameLength = FILENAME_MAX_LENGTH
) => {
  return fileName.length <= maxFileNameLength;
};

export const isValidSize = (actualSize, maxSizeInMb = undefined) => {
  // Only accept numbers as actualSize
  if (Number.isNaN(parseInt(actualSize))) {
    return false;
  }

  // Max size needs to be defined and be a number
  if (Number.isNaN(parseInt(maxSizeInMb))) {
    return false;
  }

  // Max value of -1 is valid and won't even check the actual size
  if (parseInt(maxSizeInMb) === -1) {
    return true;
  }

  return actualSize <= megabytesToBytes(maxSizeInMb);
};

export const isValidMimeType = (
  mimeTypeToValidate,
  allowedMimeTypes = undefined
) => {
  // When allowedMimeTypes is empty array, it means there is no restriction on type
  if (Array.isArray(allowedMimeTypes) && allowedMimeTypes.length === 0) {
    return true;
  }

  // Falsey values in allowedMimeTypes are not valid
  if (!allowedMimeTypes) {
    return false;
  }

  // Only defined MIME types are valid
  if (
    !Object.values(MIME_TYPES_BY_FILE_EXTENSION).includes(mimeTypeToValidate)
  ) {
    return false;
  }

  // Only allowed type is valid
  if (allowedMimeTypes.includes(mimeTypeToValidate)) {
    return true;
  }

  // Any other cases are invalid
  return false;
};
