<template>
  <div>
    <h6 class="main-title">My Tasks</h6>
    <ul
      v-if="!tasks.length"
      :class="{ [$style.taskList]: true, [$style.taskEmptyList]: true }"
    >
      <li
        class="box-shadow"
        :class="{
          [$style.taskComplete]: true,
          [$style.taskIncomplete]: false
        }"
      >
        <a :class="$style.taskIcon"
          ><span class="icon icon-check-square-o"></span
        ></a>
        <span :class="$style.taskText">No tasks to complete!</span>
      </li>
    </ul>

    <ul v-else :class="$style.taskList">
      <template v-for="(task, index) in tasksToShow">
        <li
          class="box-shadow-white"
          :key="index"
          :class="{
            [$style.taskComplete]: task && task.completed ? true : false,
            [$style.taskIncomplete]: task && !task.completed ? true : false
          }"
        >
          <template v-if="!task.completed">
            <a
              :class="$style.taskIcon"
              @click="onTaskTick(true, task.user_task_id)"
            >
              <span class="icon icon-square-o"></span>
            </a>

            <span
              :class="$style.taskText"
              @click="onTaskTick(true, task.user_task_id)"
            >
              <TemplateCompiler :template="renderBlocks(task)" />
            </span>
          </template>

          <template v-else>
            <a
              :class="$style.taskIcon"
              @click="onTaskTick(false, task.user_task_id)"
            >
              <span class="icon icon-check-square-o"></span>
            </a>

            <span
              :class="$style.taskText"
              @click="onTaskTick(false, task.user_task_id)"
            >
              <TemplateCompiler :template="renderBlocks(task)" />
            </span>

            <a
              :class="{ [$style.taskIcon]: true, [$style.tail]: true }"
              @click="onTaskArchive(task.user_task_id)"
            >
              <span class="icon icon-close"></span>
            </a>
          </template>
        </li>
      </template>
    </ul>
    <div v-if="tasks.length > tasksToShow.length" :class="$style.more">
      <a @click="showMore">
        See more…
      </a>
    </div>
  </div>
</template>

<script>
import TemplateCompiler from '@/components/common/TemplateCompiler/TemplateCompiler';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    TemplateCompiler
  },
  computed: {
    ...mapGetters('tasks', ['tasks', 'pageBy', 'showPages']),
    tasksToShow() {
      return this.tasks.slice(0, this.showPages * this.pageBy);
    }
  },
  methods: {
    ...mapActions('tasks', ['fetchTasks', 'updateTask', 'archiveTask']),
    ...mapMutations('tasks', ['setShowPages']),
    showMore() {
      this.setShowPages(this.showPages + 1);
    },
    onTaskTick(newValue, taskId) {
      if (event.target.tagName != 'A') {
        this.updateTask({ taskId, payload: { completed: newValue } }).catch(
          error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        );
      }
    },
    onTaskArchive(taskId) {
      this.archiveTask(taskId).catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    },
    renderBlocks(task) {
      const blocks =
        !task.blocks || (!Array.isArray(task.blocks) && task.name)
          ? [{ type: 'section', text: task.name ? task.name : '' }]
          : [];

      const templateString = blocks.reduce((memo, block) => {
        if (block.type === 'section') {
          return `${memo}${block.text}`;
        }

        if (block.type === 'route') {
          let filteredParams = {};

          Object.keys(block.params).map(key => {
            if (key !== 'name') {
              filteredParams[key] = block.params[key];
            }
          });

          const linkObject = {
            name: block.params.name,
            params: filteredParams
          };

          return `${memo}<router-link :to='${JSON.stringify(linkObject)}'>${
            block.text
          }</router-link>`;
        }

        return memo;
      }, '');

      // Concatenated string must be wrapped in span because
      // when passed to TemplateCompiler there can only be one root
      // element of the whole template - otherwise it would be ignored
      // and compilation errors would be in JS console
      return `<span>${templateString}</span>`;
    }
  },
  created() {
    this.fetchTasks().catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  }
};
</script>

<style lang="scss" module>
@import './Tasks.scss';
</style>
