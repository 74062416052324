<template>
  <div :class="$style.wrapper" class="pt-1">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        infoFn: params => setTotalRecords(params.totalRecords)
      }"
      compactMode
    >
      <div slot="table-actions">
        <h6 :class="$style.tableHeader">
          Email history
          <FormButton
            :class="$style.iconBtn"
            :standard="true"
            @click="fetchEmailHistory()"
            ><i class="icon icon-retweet" />
          </FormButton>
        </h6>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';
import { GET_ADMIN_USER_EMAILS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      totalRecords: 0,
      emailHistory: [],
      columns: [
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Created at',
          field: 'createdAt',
          dateInputFormat: 'dd.MM.yyyy',
          dateOutputFormat: 'dd.MM.yyyy'
        }
      ],
      rows: []
    };
  },
  props: {
    userDetail: {
      type: Object,
      required: false
    }
  },
  watch: {
    userDetail() {
      this.fetchEmailHistory();
    }
  },
  methods: {
    fetchEmailHistory() {
      this.$store
        .dispatch(GET_ADMIN_USER_EMAILS, this.$route.params.userId)
        .then(emails => {
          if (emails.length) {
            this.emailHistory = emails;
          }
        })
        .then(() => {
          this.createEmailRows();
        });
    },
    setTotalRecords(totalRecords) {
      this.totalRecords = totalRecords;
    },
    formatTime(time) {
      if (!time) {
        return null;
      }
      return moment(time).format('DD.MM.yyyy - HH:mm');
    },
    createEmailRows() {
      this.rows = [];
      if (!this.emailHistory.length) return [];
      this.emailHistory.filter(email => {
        this.rows.push({
          type: email.type || '',
          createdAt: this.formatTime(email.created_at) || ''
        });
      });
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminUserDetailEmails.scss';
@import '../AdminTables.scss';
</style>
