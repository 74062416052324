var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.posts)?_c('div',{staticClass:"box-shadow-white",class:_vm.$style.wrapper},[_c('h6',{class:_vm.$style.header},[_vm._v(" Most recent posts ")]),_vm._l((_vm.posts),function(post,index){return _c('div',{key:index,class:_vm.$style.post},[_c('div',{class:_vm.$style.avatar},[_c('Avatar',{attrs:{"user":post.author,"size":"45px"}})],1),_c('div',{class:_vm.$style.text},[_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp:1",value:(1.2),expression:"1.2",arg:"1"}],class:_vm.$style.title},[_c('router-link',{attrs:{"to":{
            name: 'user-profile',
            params: { userId: post.author.user_id }
          }}},[_c('UserName',{attrs:{"preTitles":true,"user":post.author}})],1),_vm._v(" posted in "),_c('router-link',{attrs:{"to":{
            name: 'topic-posts',
            params: {
              forumSlug: post.topic.forum_slug,
              topicSlug: post.topic.slug,
              postId: post.post_id
            }
          }}},[_c('span',{domProps:{"innerHTML":_vm._s(post.topic.subject)}})]),_vm._v(" on "),_c('time',{attrs:{"datetime":post.created_at}},[_vm._v(" "+_vm._s(_vm.createdAt(post)))])],1),_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp:2",value:(1.2),expression:"1.2",arg:"2"}],staticClass:"paragraph-normal",class:_vm.$style.content,domProps:{"innerHTML":_vm._s(post.content)}})])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }