<template>
  <div :class="$style.wrapper" role="region">
    <div :class="$style.background" class="border">
      <img v-if="teamAvatarUrl" :src="teamAvatarUrl" alt="Team avatar" />
      <img v-else src="~@/assets/images/bg/TeamHeaderDefault.jpg" />
    </div>

    <template v-if="user">
      <div :class="$style.quote">
        <span
          v-if="user && user.profile_data && user.profile_data.personal_quote"
          data-cy="viewPersonalQuote"
          v-html="user.profile_data.personal_quote"
        />
        <span v-else />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    teamAvatarUrl: {
      type: String,
      default: ''
    }
  }
};
</script>

<style module lang="scss">
@import './UserProfileImage.scss';
</style>
