<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      compactMode
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom'
      }"
    >
      <div slot="table-actions" :class="$style.form">
        <FormInput placeholder="Group name ..." v-model="groupName" />
        <FilterSelect
          :class="$style.select"
          :submenuVisible="groupSubmenuVisible"
          @submenu_visibility="handleGroupTypeSubmenuChange"
        >
          <template #title>
            {{ groupType || 'Group type' }}
          </template>
          <template #items>
            <template>
              <span
                v-for="type in groupTypes"
                :key="type"
                @click="handleGroupTypeChange(type)"
              >
                {{ type }}
              </span>
            </template>
          </template>
        </FilterSelect>
        <FormButton @click="handleOpened()" :standard="true"
          >Create group
        </FormButton>
      </div>

      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'removeGroup'"
          :class="$style.closeCell"
        >
          <i
            v-if="!props.row.groupMembers"
            @click="handleModalOpened(props.row.groupId)"
            :class="$style.closeIcon"
            class="icon icon-close"
          />
          <i v-else :class="$style.disabledIcon" class="icon icon-close" />
        </span>
        <span v-else-if="props.column.field === 'groupTeamId'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'admin-group-detail',
              params: {
                userGroupId: props.row.groupId,
                groupTeamSlug: props.row.groupTeamSlug
              }
            }"
          >
            {{ props.row.groupTeamId }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'groupName'">
          <router-link
            :to="{
              name: 'admin-group-detail',
              params: {
                userGroupId: props.row.groupId,
                groupTeamSlug: props.row.groupTeamSlug
              }
            }"
          >
            {{ props.row.groupName }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'groupId'">
          <p class="adminSecondaryId">{{ props.row.groupId }}</p>
        </span>
      </template>
    </vue-good-table>

    <ModalNew
      :class="$style.modalOperationConfirmation"
      :showModal="showCreateGroupModal"
      v-transfer-dom
      @close="handleCreateGroupModalClosed"
    >
      Do you want to create new group?
      <div :class="$style.buttonsOperationConfirmation">
        <FormButton @click="createNewUserGroup()" :standard="true"
          >Yes</FormButton
        >
        <FormButton @click="handleCreateGroupModalClosed" :ghost="true"
          >No</FormButton
        >
      </div>
    </ModalNew>
    <ModalNew
      :showModal="showRemoveGroupModal"
      v-transfer-dom
      :class="$style.modalOperationConfirmation"
      @close="handleRemoveGroupModalClosed"
    >
      Are you sure you want to remove this group?
      <div :class="$style.buttonsOperationConfirmation">
        <FormButton @click="removeGroup()" :ghost="true">Yes</FormButton>
        <FormButton @click="handleRemoveGroupModalClosed" :ghost="true"
          >No</FormButton
        >
      </div>
    </ModalNew>
  </div>
</template>

<script>
import moment from 'moment';

import { VueGoodTable } from 'vue-good-table';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  ADMIN_GROUP_DETAIL_CREATE_GROUP,
  ADMIN_GROUP_DETAIL_REMOVE_GROUP
} from '@/store/actions.type';

import { GROUP_TYPES } from '@/config/admin';

export default {
  components: {
    VueGoodTable,
    FilterSelect
  },
  props: {
    userGroups: {
      type: Array,
      default: () => []
    },
    cohortId: {
      type: Number
    }
  },
  data() {
    return {
      groupTypes: [GROUP_TYPES.team, GROUP_TYPES.vcGroup, GROUP_TYPES.crGroup],
      groupType: null,
      groupName: null,
      showCreateGroupModal: false,
      groupSubmenuVisible: false,
      showRemoveGroupModal: false,
      approveID: null,
      searchParams: {},
      columns: [
        {
          label: '',
          field: 'removeGroup',
          width: '50px',
          tdClass: 'center-icon',
          thClass: 'removeMemberHeader',
          sortable: false,
          filterOptions: {
            styleClass: 'removeMemberHeader'
          }
        },
        {
          label: 'Team ID',
          type: 'number',
          field: 'groupTeamId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('groupTeamId')
          }
        },
        {
          label: 'Team name',
          field: 'groupName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('groupName')
          }
        },
        {
          label: '# Members',
          type: 'number',
          field: 'groupMembers',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('groupMembers')
          }
        },
        {
          label: 'Group type',
          field: 'groupType',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('groupType')
          }
        },
        {
          label: 'Group ID',
          type: 'number',
          field: 'groupId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('groupId')
          }
        },
        {
          label: 'External ID',
          field: 'externalId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('externalId')
          }
        }
      ],
      rows: []
    };
  },
  watch: {
    userGroups() {
      this.createPhaseRows();
    }
  },
  methods: {
    createNewUserGroup() {
      this.$store
        .dispatch(ADMIN_GROUP_DETAIL_CREATE_GROUP, {
          cohortId: this.cohortId,
          groupType: this.groupType,
          groupName: this.groupName
        })
        .then(() => {
          this.$emit('reloadMembers');
          this.showCreateGroupModal = false;
          this.groupType = null;
          this.groupName = null;
        })
        .then(() => {
          this.createPhaseRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleModalOpened(approveID) {
      this.approveID = approveID;
      this.showRemoveGroupModal = true;
    },
    handleRemoveGroupModalClosed() {
      this.approveID = null;
      this.showRemoveGroupModal = false;
    },
    removeGroup() {
      this.$store
        .dispatch(ADMIN_GROUP_DETAIL_REMOVE_GROUP, this.approveID)
        .then(() => {
          this.$emit('reloadMembers');
          this.showRemoveGroupModal = false;
          this.approveID = null;
        })
        .then(() => {
          this.createPhaseRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleGroupTypeSubmenuChange(newSubmenuState) {
      this.groupSubmenuVisible = newSubmenuState;
    },
    handleGroupTypeChange(type) {
      this.groupType = type;
      this.groupSubmenuVisible = false;
    },
    handleOpened() {
      if (this.cohortId && this.groupType && this.groupName) {
        this.showCreateGroupModal = true;
      }
    },
    handleCreateGroupModalClosed() {
      this.showCreateGroupModal = false;
    },
    createPhaseRows() {
      this.rows = [];

      if (!this.userGroups) return [];

      this.userGroups.filter(group => {
        this.rows.push({
          groupTeamId: group.object_id || '',
          groupName: group.object_name || '',
          groupMembers: group.members || '',
          groupType: group.group_type || '',
          groupId: group.user_group_id || '',
          groupTeamSlug: group.slug || '',
          externalId: group.external_id || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        groupSort: sort[0].field || [],
        groupOrder: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({
            ...this.$route.query,
            ...this.searchParams,
            ...sorting
          });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.groupOrder) {
        return {
          field: this.$route.query.groupSort,
          type: this.$route.query.groupOrder
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'groupTeamId':
          return this.$route.query.groupTeamId;
          break;
        case 'groupName':
          return this.$route.query.groupName;
          break;
        case 'groupMembers':
          return this.$route.query.groupMembers;
          break;
        case 'groupType':
          return this.$route.query.groupType;
          break;
        case 'groupId':
          return this.$route.query.groupId;
          break;
        case 'externalId':
          return this.$route.query.externalId;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        groupTeamId: column.groupTeamId || [],
        groupName: column.groupName || [],
        groupMembers: column.groupMembers || [],
        groupType: column.groupType || [],
        groupId: column.groupId || [],
        externalId: column.externalId || []
      };

      this.updateQueryFilters({ ...this.$route.query, ...this.searchParams });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminCohortDetail.scss';
</style>
