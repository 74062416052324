<template>
  <Layout variant="5">
    <template #pageContent>
      <RecentPosts :posts="posts" />
      <DiscussionCategory
        v-for="category in categories"
        :key="category.slug"
        :category="category"
        :forums="categoryForums(category.slug)"
      />
    </template>
  </Layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  GET_DISCUSSION_CATEGORIES,
  GET_DISCUSSION_CATEGORY_FORUMS,
  GET_DISCUSSION_RECENT_POSTS
} from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import DiscussionCategory from '@/components/discussions/DiscussionCategory/DiscussionCategory';
import RecentPosts from '@/components/discussions/RecentPosts/RecentPosts';

export default {
  components: {
    DiscussionCategory,
    Layout,
    RecentPosts
  },
  data() {
    return {
      posts: []
    };
  },
  computed: {
    ...mapGetters('discussionCategories', ['categories', 'categoryForums'])
  },
  created() {
    this.fetchData();
    this.fetchPosts();
  },
  methods: {
    ...mapMutations('discussionCategories', ['setCategories', 'setForums']),
    async fetchData() {
      await this.getCategories();
      await this.getForums();
    },
    async getCategories() {
      try {
        const categories = await this.$store.dispatch(
          GET_DISCUSSION_CATEGORIES
        );
        this.setCategories(categories);
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async getForums() {
      let forums = [];

      if (!this.categories) {
        return;
      }

      for (let i = 0; i < this.categories.length; i++) {
        let category = this.categories[i];

        let categoryForums;
        try {
          categoryForums = await this.$store.dispatch(
            GET_DISCUSSION_CATEGORY_FORUMS,
            category.slug
          );
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }

        forums = [...forums, ...categoryForums];

        this.setForums(forums);
      }
    },
    fetchPosts() {
      this.$store
        .dispatch(GET_DISCUSSION_RECENT_POSTS, 3)
        .then(posts => {
          this.posts = posts;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>
