<template>
  <button :class="classes" v-bind="$attrs" v-on="eventListeners">
    <slot />
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    standard: {
      type: Boolean,
      required: false,
      default: false
    },
    special: {
      type: Boolean,
      required: false,
      default: false
    },
    ghost: {
      type: Boolean,
      required: false,
      default: false
    },
    ghostFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    ghostClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    round: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    classes() {
      const classes = ['btn'];

      if (this.standard) {
        classes.push('standard');
      }

      if (this.special) {
        classes.push('special');
      }

      if (this.ghost) {
        classes.push('ghost');
      }

      if (this.ghostFilter) {
        classes.push('ghostFilter');
      }

      if (this.ghostClickable) {
        classes.push('ghostClickable');
      }

      if (this.round) {
        classes.push('round');
      }

      if (this.large) {
        classes.push('special');
        classes.push('large');
      }

      return classes;
    },
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        click: event => {
          this.$emit('click', event);
        }
      });
    }
  }
};
</script>
