<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Administrative groups</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <vue-good-table :columns="columns" :rows="rows" compactMode>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'name'">
              <router-link
                :to="{
                  name: 'admin-group-detail',
                  params: { userGroupId: props.row.id }
                }"
              >
                {{ props.row.name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'id'">
              <router-link
                class="adminPrimaryId"
                :to="{
                  name: 'admin-group-detail',
                  params: { userGroupId: props.row.id }
                }"
              >
                {{ props.row.id }}
              </router-link>
            </span>
          </template>
        </vue-good-table>
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_ADMINISTRATIVE_USER_GROUPS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      groups: [],
      columns: [
        {
          label: 'Group Name',
          field: 'name'
        },
        {
          label: 'User Group Id',
          field: 'id',
          type: 'number'
        },
        {
          label: 'Group Type',
          field: 'type'
        },
        {
          label: 'Number of users',
          field: 'number'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchGroups();
    }
  },
  methods: {
    fetchGroups() {
      this.$store
        .dispatch(GET_ADMINISTRATIVE_USER_GROUPS)
        .then(groups => {
          this.groups = groups;
        })
        .then(groups => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.groups.filter(group => {
        this.rows.push({
          id: group.user_group_id || '',
          name: group.object_name || '',
          type: group.group_type || '',
          number: group.user_count || ''
        });
      });
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
