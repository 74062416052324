<template>
  <span :class="$style.wrapper" class="form-textarea">
    <textarea
      v-bind="$attrs"
      :value="value"
      :maxlength="maxCount"
      v-on="eventListeners"
    />
    <span :class="$style.info" v-if="showCounter" class="paragraph-small"
      >{{ characterCount }}/{{ maxCount }}</span
    >
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    showCounter: Boolean,
    maxCount: Number
  },
  computed: {
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', event.target.value);
        }
      });
    },
    characterCount() {
      return typeof this.value === 'string' ? this.value.length : 0;
    }
  }
};
</script>

<style lang="scss" module>
@import './FormTextarea.scss';
</style>
