<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Modules</h6>
          <div :class="$style.buttons">
            <FormButton
              :standard="true"
              :class="$style.greenButton"
              @click="handleOpened()"
              >Create Module</FormButton
            >
            <div :class="$style.homeButton">
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <router-link
                class="adminPrimaryId"
                :to="{
                  name: 'admin-module-detail',
                  params: { moduleId: props.row.id }
                }"
                >{{ props.row.id }}</router-link
              >
            </span>
            <span v-else-if="props.column.field === 'name'">
              <router-link
                :to="{
                  name: 'admin-module-detail',
                  params: { moduleId: props.row.id }
                }"
                >{{ props.row.name }}</router-link
              >
            </span>
            <span v-else-if="props.column.field === 'cohort'">
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.cohort[1] }
                }"
                >{{ props.row.cohort[0] }}
                <span class="adminSecondaryId">{{
                  props.row.cohort[1]
                }}</span></router-link
              >
            </span>
            <span v-else-if="props.column.field === 'units'">
              <span
                class="text-semibold"
                :class="{
                  [$style.enabled]: props.row.units === 'Enabled',
                  [$style.disabled]: props.row.units === 'Disabled'
                }"
                >{{ props.row.units }}</span
              >
            </span>
            <span v-else-if="props.column.field === 'scheduleGroup'">
              <span
                class="text-semibold"
                :class="{
                  [$style.enabled]: props.row.scheduleGroup === 'Enabled',
                  [$style.disabled]: props.row.scheduleGroup === 'Disabled'
                }"
                >{{ props.row.scheduleGroup }}</span
              >
            </span>
            <span v-else-if="props.column.field === 'scheduleDuration'">
              <p>{{ props.row.scheduleDuration[0] }} -</p>
              <p>{{ props.row.scheduleDuration[1] }}</p>
            </span>
          </template>
        </vue-good-table>

        <ModalNew
          :class="{
            [$style.adminModal]: true,
            [$style.AdminModulesModal]: true
          }"
          :showModal="showModal"
          v-transfer-dom
          @close="handleClosed"
        >
          <h6>Create New Module</h6>
          <div :class="$style.createModuleForm">
            <FormLabel
              >Module Name
              <FormInput
                v-model="newModule.moduleName"
                :placeholder="'Module Name'"
            /></FormLabel>
            <FormLabel>
              Select Cohort
              <FilterSelect
                :submenuVisible="cohortSubmenuVisible"
                @submenu_visibility="selectCohort"
              >
                <template #title>
                  <span v-if="!newModule.selectedCohort.name"
                    >Select Cohort</span
                  >
                  <span v-else>{{ newModule.selectedCohort.name }} </span>
                </template>
                <template #items>
                  <fieldset
                    v-for="cohort in cohorts"
                    :value="cohort.cohort_id"
                    :key="cohort.cohort_id"
                    @click="handleCohortChange(cohort)"
                  >
                    <FormLabel>
                      {{ cohort.name }}
                    </FormLabel>
                  </fieldset>
                </template></FilterSelect
              ></FormLabel
            >
            <FormLabel
              >External Id
              <FormInput
                v-model="newModule.externalId"
                :placeholder="'External Id'"
            /></FormLabel>
            <FormLabel
              >Start Date<FormInputDate v-model="newModule.startDate"
            /></FormLabel>
            <FormLabel
              >End Date<FormInputDate v-model="newModule.endDate"
            /></FormLabel>
            <FormLabel
              ><FormCheckbox v-model="newModule.disableGroups" /> Disable
              Groups</FormLabel
            >
          </div>

          <FormFieldErrors :errors="[{ message: errorMessages }]" />

          <FormButton
            @click="createNewModule()"
            :standard="true"
            :class="$style.createModule"
            >Create Module</FormButton
          >
        </ModalNew>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  GET_ADMIN_MODULES,
  CREATE_ADMIN_MODULE,
  GET_ADMIN_COHORTS
} from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import { VueGoodTable } from 'vue-good-table';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable,
    FilterSelect
  },
  data() {
    return {
      display404: false,
      showModal: false,
      cohortSubmenuVisible: false,
      modules: [],
      cohorts: [],
      errorMessages: '',
      newModule: {
        selectedCohort: [],
        moduleName: '',
        externalId: '',
        startDate: '',
        endDate: '',
        disableGroups: true
      },
      columns: [
        {
          label: 'Module ID',
          field: 'id',
          width: '110px',
          type: 'number',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'Module name',
          field: 'name',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('name')
          }
        },
        {
          label: 'Published at',
          field: 'published',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('published')
          }
        },
        {
          label: 'Cohort',
          field: 'cohort',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('cohort')
          }
        },
        {
          label: 'Schedule duration',
          field: 'scheduleDuration',
          width: '120px'
        },
        {
          label: 'Units',
          field: 'units',
          width: '110px'
        },
        {
          label: 'Schedule group',
          field: 'scheduleGroup',
          width: '110px'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchModules();

      this.$store.dispatch(GET_ADMIN_COHORTS).then(cohorts => {
        this.cohorts = cohorts;
      });
    }
  },
  methods: {
    ...mapActions([CREATE_ADMIN_MODULE]),
    handleCohortChange(cohort) {
      this.newModule.selectedCohort = cohort;
      this.cohortSubmenuVisible = false;
    },
    createNewModule() {
      if (
        this.newModule.moduleName &&
        this.newModule.moduleName.trim() != '' &&
        this.newModule.startDate &&
        this.newModule.startDate.trim() != '' &&
        this.newModule.endDate &&
        this.newModule.endDate.trim() != '' &&
        this.newModule.selectedCohort != null
      ) {
        this.$store
          .dispatch(CREATE_ADMIN_MODULE, {
            name: this.newModule.moduleName,
            externalId: this.newModule.externalId,
            startDate: this.newModule.startDate,
            endDate: this.newModule.endDate,
            cohortId: this.newModule.selectedCohort.cohort_id,
            disableGroups: this.newModule.disableGroups
          })
          .then(response => {
            this.$router.push({
              name: 'admin-module-detail',
              params: {
                moduleId: response.module_id
              }
            });
          })
          .catch(error => {
            this.errorMessages = error.response.data.message;
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      } else {
        this.errorMessages = 'Please fill out all fields.';
      }
    },
    selectCohort(newSubmenuState) {
      this.cohortSubmenuVisible = newSubmenuState;
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'name':
          return this.$route.query.name;
          break;
        case 'published':
          return this.$route.query.published;
          break;
        case 'cohort':
          return this.$route.query.cohort;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        name: column.name || [],
        published: column.published || [],
        cohort: column.cohort || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    fetchModules() {
      this.$store
        .dispatch(GET_ADMIN_MODULES)
        .then(modules => {
          this.modules = modules;
        })
        .then(modules => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.modules.filter(module => {
        this.rows.push({
          id: module.modules.module_id || '',
          name: module.modules.module_name || '',
          units: this.unitsStatus(module.modules) || '',
          scheduleDuration: this.scheduleDurationCheck(module.modules) || '',
          scheduleGroup: this.scheduleGroupStatus(module.modules) || '',
          published:
            moment(module.modules.published_at).format('DD.MM.yyyy HH:mm') ||
            '',
          cohort: this.cohortColumn(module.modules.cohort) || ''
        });
      });
    },
    scheduleDurationCheck(module) {
      if (module && module.schedule) {
        return [module.schedule.start_date, module.schedule.end_date];
      }

      return '-';
    },
    cohortColumn(cohort) {
      if (cohort) {
        return [cohort.name, cohort.cohort_id];
      }
    },
    unitsStatus(module) {
      if (module.options && module.options.disable_units) {
        return 'Disabled';
      }

      return '-';
    },
    scheduleGroupStatus(module) {
      if (module.options && module.options.disable_schedule_groups) {
        return 'Disabled';
      }

      return '-';
    },
    handleOpened() {
      this.showModal = true;
    },
    handleClosed() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import 'AdminModules.scss';
</style>
