<template>
  <div :class="$style.wrapper">
    <div
      :class="$style.progress"
      class="theme-bg"
      :style="{ width: progressPercent + '%' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    progressPercent: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>

<style lang="scss" module>
@import './ProgressBar.scss';
</style>
