import isEqual from 'lodash.isequal';
import moment from 'moment';
import { FROM_NEWEST } from '@/config/dates';

/**
 * Remove all items with `null` value from an object.
 */
export const removeNullItemsFromObj = obj => {
  return Object.keys(obj)
    .filter(key => obj[key] !== null)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});
};

/**
 * Remove all items with falsey values from an object.
 */
export const removeFalseyItemsFromObj = obj => {
  return Object.keys(obj)
    .filter(key => obj[key])
    .reduce((newObj, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});
};

/**
 * Return last n elements of the array.
 * Return the whole array if n === -1.
 */
export const last = (arr, n) => {
  return n !== -1 ? arr.slice(-n) : arr;
};

/**
 * Remove 'duplicate' objects from an array which
 * contain a same value for a given key.
 */
export const removeDuplicateObjByKey = (arr, key) => {
  if (!Array.isArray(arr)) return;

  return arr.reduce((accumulator, item) => {
    if (
      item[key] === undefined ||
      !accumulator.find(duplicate => duplicate[key] === item[key])
    ) {
      accumulator.push(item);
    }
    return accumulator;
  }, []);
};

export const isArray = maybeArray => {
  return Object.prototype.toString.call(maybeArray) === '[object Array]';
};

export const isObject = maybeObject => {
  return typeof maybeObject === 'object';
};

/**
 * Returns string like 'test=dummy&test=funny' when
 * given array ['dummy', 'funny'] and string 'test' as a key.
 * Omits any undefined or null values.
 * If any params missing or array empty returns undefined.
 */
export const serializeArr = (values, key) => {
  if (!isArray(values) || values.length < 1 || !key) return undefined;

  return values
    .filter(value => ![undefined, null, ''].includes(value))
    .map(value => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

export const serializeObj = obj => {
  if (!isObject(obj) || Object.keys(obj).length < 1) return undefined;

  return Object.keys(obj)
    .filter(key => ![undefined, null, ''].includes(obj[key]))
    .map(key =>
      isArray(obj[key])
        ? serializeArr(obj[key], key)
        : serializeArr([obj[key]], key)
    )
    .join('&');
};

/**
 * Constructs new Object with only selected keys of another Object
 * Given const data = { a: 1, b: 2, c: 3 }
 * To get Object with only a & b keys call pick(data, 'a', 'b')
 * https://stackoverflow.com/questions/34658867/slice-specific-keys-in-javascript-object
 */

export const pick = (obj, ...args) => ({
  ...args.reduce(
    (accumulator, key) => ({ ...accumulator, [key]: obj[key] }),
    {}
  )
});

/**
 * Add object to an array. If object is there already, update it. Otherwise push
 * a new object to the end of an array. Equality of two objects is determined according
 * to equality of specified property values.
 * Return updated array.
 * Examples:
 *
 * 1.
 * const arr = [{slug: 'test-topic', name: 'Test topic'}]
 * const obj = {slug: 'test-topic', name: 'Another name'}
 * const propName = 'slug'
 *
 * addObjToArray(arr, obj, propName)
 * // returns [{slug: 'test-topic', name: 'Another name'}]
 *
 * 2.
 * const arr = [{slug: 'test-topic', name: 'Test topic'}]
 * const obj =  {slug: 'support', name: 'Support'}
 * const propName = 'slug'
 *
 * addObjToArray(arr, obj, propName)
 * // returns [{slug: 'test-topic', name: 'Test topic'}, {slug: 'support', name: 'Support'}]
 *
 * Specified property value is expected to be a simple value.
 */
export const addObjToArray = (arr, obj, propName) => {
  let newArr = [];

  if (!arr) {
    return [];
  }

  newArr = [...arr];

  // eslint-disable-next-line no-prototype-builtins
  if (!obj || !propName || !obj.hasOwnProperty(propName)) {
    return newArr;
  }

  const objIdx = arr.findIndex(
    item => item[propName].toString() === obj[propName].toString()
  );

  if (objIdx === -1) {
    newArr.push(obj);
  } else {
    newArr[objIdx] = obj;
  }

  return newArr;
};

// Wraps all found matches in a span or custom markup
// Uses https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
export const wrapAllMatches = (
  string,
  regexp,
  wrapMarkup = { before: '<span class="highlight">', after: '</span>' }
) => {
  if (!string || !regexp) return string;

  let matches;
  let matchPrefix = '';
  let matchWrapped = '';
  let matchSuffix = '';
  let result = '';
  let lastMatch = 0;

  // Clone needed here because calling .test() will mutate regexp's lastIndex property
  const regexpClone = new RegExp(regexp.source, regexp.flags);
  // Check for global flag - prevents infinite loop when calling .exec() later
  const isGlobalRegExp = regexp.flags.split('').includes('g');
  // When no matches found or global flag not found, just return the string
  if (!regexpClone.test(string) || !isGlobalRegExp) return string;

  while ((matches = regexp.exec(string))) {
    // Gets portion of a string from the last match to the current one
    matchPrefix = string.slice(lastMatch, matches.index);
    // Wraps current match in HTML
    matchWrapped = `${wrapMarkup.before}${matches[0]}${wrapMarkup.after}`;
    // Gets the rest of the string after current match
    matchSuffix = string.slice(matches.index + matches[0].length);
    // Remembers position after current match for next run
    lastMatch = regexp.lastIndex;

    // Appends section from last match to current one to result
    result += `${matchPrefix}${matchWrapped}`;
  }

  // Slaps the last remaining suffix to the result
  result += matchSuffix;

  return result;
};

export const getSelectedOptionsIds = options => {
  if (options && Array.isArray(options)) {
    return options.reduce((accumulator, option) => {
      const propetyName = option.hasOwnProperty('option_id')
        ? 'option_id'
        : 'id';

      if (option.selected === true && option[propetyName])
        accumulator.push(option[propetyName]);

      return accumulator;
    }, []);
  }

  return [];
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const cloneA = a.slice(0);
  const cloneB = b.slice(0);

  cloneA.sort();
  cloneB.sort();

  for (var i = 0; i < cloneA.length; ++i) {
    if (!isEqual(cloneA[i], cloneB[i])) return false;
  }

  return true;
};

export const sortingByDate = (arr, attr, newest) => {
  if (!Array.isArray(arr)) return arr;
  const arrCopy = [...arr];

  return arrCopy.sort((itemA, itemB) => {
    const m_itemA = moment(itemA[attr]);
    const m_itemB = moment(itemB[attr]);
    if (!m_itemA.isValid() || !m_itemB.isValid()) return undefined;

    if (newest == FROM_NEWEST) {
      if (m_itemA.isAfter(m_itemB)) return -1;

      if (m_itemA.isBefore(m_itemB)) return 1;
    } else {
      if (m_itemA.isAfter(m_itemB)) return 1;

      if (m_itemA.isBefore(m_itemB)) return -1;
    }

    if (m_itemA.isSame(m_itemB)) return 0;
  });
};

export const capitalize = value => {
  if (!value) return '';

  return value
    .toString()
    .split(' ')
    .map(letter => {
      return letter.charAt(0).toUpperCase() + letter.slice(1);
    })
    .join(' ');
};

export const formatTag = tag => {
  return '#' + tag.replace(/ |-/g, '').toLowerCase();
};

export const renderLabel = value => {
  if (!value) return '';

  if (isObject(value) && value.label) return value.label.trim();

  return value.trim();
};

// https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
export const romanize = num => {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let roman = '';

  Object.entries(lookup).map((pair, index) => {
    const romanLetter = pair[0];
    const romanLetterValue = pair[1];

    while (num >= romanLetterValue) {
      roman = `${roman}${romanLetter}`;
      num = num - romanLetterValue;
    }
  });

  return roman;
};
