// everything that doesn't deserve its own module yet

import { last } from '@/helpers/common';
import ApiService from '@/api/api.service';

import {
  RESET_STATE,
  SET_DESKTOP_LAYOUT,
  SET_TABLET_LAYOUT,
  SET_PHONE_LAYOUT,
  SET_CALENDAR_URL,
  SET_USERS_STREAM_EVENTS,
  // SET_TEAM_STREAM_EVENTS,
  ADD_ANNOUNCEMENTS,
  REMOVE_ANNOUNCEMENTS
} from './mutations.type';
import {
  GET_USERS_STREAM_EVENTS,
  // GET_TEAM_STREAM_EVENTS,
  GET_CALENDAR_URL,
  GET_ANNOUNCEMENTS,
  HIDE_ANNOUNCEMENTS
} from './actions.type';

export const state = {
  tasks: [],
  calendarUrl: undefined,
  stream: [],
  streamTeam: [],
  announcements: [],
  phone: null,
  tablet: null,
  desktop: null
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  isMobileLayout: (state, getters) => {
    return getters.isPhoneLayout;
  },
  isPhoneLayout: state => {
    if (state.phone === true) return true;

    return state.desktop === true ? false : undefined;
  },
  isTabletLayout: state => {
    if (state.tablet === true) return true;

    return state.desktop === true ? false : undefined;
  },
  tasks: state => (
    limit = 5 // limit = -1 - return all events
  ) => {
    if (state.tasks === null) {
      return null;
    }

    return last(state.tasks, limit);
  },
  calendarUrl: state => {
    return state.calendarUrl;
  },
  newsfeed(state) {
    return state.newsfeed;
  },
  announcements(state) {
    return state.announcements;
  },
  stream(state) {
    return state.stream;
  },
  streamTeam(state) {
    return state.streamTeam;
  }
};

export const actions = {
  [GET_USERS_STREAM_EVENTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getUsersStreamEvents(params)
        .then(({ data }) => {
          context.commit(SET_USERS_STREAM_EVENTS, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_CALENDAR_URL](context) {
    return new Promise((resolve, reject) => {
      ApiService.getCalendarUrl()
        .then(({ data }) => {
          if (data && data.calendar_url) {
            context.commit(SET_CALENDAR_URL, data.calendar_url);
            resolve(data.calendar_url);
          } else {
            resolve();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ANNOUNCEMENTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAnnouncement()
        .then(({ data }) => {
          context.commit(ADD_ANNOUNCEMENTS, [data]); // passing in array with data instead of just data because API sends only one announcement now, but we'll be ready to receive a list
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [HIDE_ANNOUNCEMENTS](context, announcementSlug) {
    return new Promise((resolve, reject) => {
      ApiService.hideAnnouncement(announcementSlug)
        .then(({ data }) => {
          context.commit(REMOVE_ANNOUNCEMENTS, [{ slug: announcementSlug }]); // passing in a list of objects with slug that should be removed
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    var reducedState = Object.assign({}, state);

    // Let's not reset state for layout since null value won't reset after created is called once.
    delete reducedState.desktop;
    delete reducedState.tablet;
    delete reducedState.phone;

    for (const prop in reducedState) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [SET_DESKTOP_LAYOUT](state, isMatchingMediaQuery) {
    state.desktop = isMatchingMediaQuery;
  },
  [SET_TABLET_LAYOUT](state, isMatchingMediaQuery) {
    state.tablet = isMatchingMediaQuery;
  },
  [SET_PHONE_LAYOUT](state, isMatchingMediaQuery) {
    state.phone = isMatchingMediaQuery;
  },
  [SET_USERS_STREAM_EVENTS](state, stream) {
    state.stream = stream;
  },
  [SET_CALENDAR_URL](state, calendarUrl) {
    state.calendarUrl = calendarUrl;
  },
  [ADD_ANNOUNCEMENTS](state, announcementsArray) {
    const addAnnouncement = announcementToAdd => {
      const found = state.announcements.find(
        announcement => announcement.slug === announcementToAdd.slug
      );

      // do not add same items more times, based on item.slug field
      if (!found) {
        state.announcements = [...state.announcements, ...[announcementToAdd]];
      }
    };

    announcementsArray.forEach(announcement => addAnnouncement(announcement));
  },
  [REMOVE_ANNOUNCEMENTS](state, announcementsArray) {
    const removeAnnouncement = announcementToRemove => {
      const found = state.announcements.find(
        announcement => announcement.slug === announcementToRemove.slug
      );

      // remove announcement based on slug field
      if (found) {
        const position = state.announcements
          .map(e => e.slug)
          .indexOf(announcementToRemove.slug);
        if (position > -1) {
          state.announcements.splice(position, 1);
        }
      }
    };

    announcementsArray.forEach(announcement =>
      removeAnnouncement(announcement)
    );
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
