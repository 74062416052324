<template>
  <span
    :class="{
      [$style.wrapper]: true,
      [$style.noActionIndicator]: withActionIndicator === false,
      [$style.undefined]: modelValue === undefined,
      [$style.on]: modelValue === true,
      [$style.off]: modelValue === false
    }"
    data-cy="threeway-switch"
  >
    <label
      :style="{
        backgroundColor: colors[`${modelValue}`]
      }"
    >
      <InputToggle
        v-bind="$attrs"
        :action="action"
        :value="value"
        :modelValue="modelValue"
        :validation="validation"
        @state="updateState"
        @change="relayChange"
        @success="relaySuccess"
        @error="relayError"
        @validation_result="relayValidationResult"
      />
    </label>

    <span :class="$style.info" v-if="withActionIndicator">
      <img
        :class="{
          [$style.state]: true,
          [$style.blinking]: isBlinking
        }"
        v-if="getStateIcon(state)"
        :title="stateTitle"
        :src="require('@/assets/images/icons/' + getStateIcon(state))"
      />
      <span v-else :class="$style.state">&nbsp;</span>
    </span>
  </span>
</template>

<script>
import { STATES } from '@/config/forms';
import { THEMES, DEFAULT_THEME } from '@/config/themes';
import InputToggle from '@/components/forms/InputToggle/InputToggle';

const THEME_COLORS = THEMES[process.env.THEME || DEFAULT_THEME];

export default {
  name: 'FormThreeWaySwitchAutoSave',
  inheritAttrs: false, // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  components: {
    InputToggle
  },
  props: {
    value: {},
    modelValue: {},
    action: {
      type: Function, // Parent should pass Function that returns a Function that returns a Promise
      required: false
    },
    validation: {
      type: Function, // Parent should pass Function that returns a Boolean
      required: false
    },
    inActionTitle: {
      type: String,
      default: 'Saving...'
    },
    onSuccessTitle: {
      type: String,
      default: 'Saved'
    },
    onErrorTitle: {
      type: String
    },
    withActionIndicator: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Object,
      default: () => {
        return {
          true: '#4bb543',
          false: '#ff3e3e',
          undefined: THEME_COLORS['light-grey']
        };
      }
    }
  },
  data() {
    return {
      state: STATES.idle,
      errorMessage: ''
    };
  },
  computed: {
    isBlinking() {
      return this.state === STATES.in_action;
    },
    classes() {
      const classes = [this.$style.wrapper];

      if (this.large) {
        classes.push(this.$style.large);
      }

      if (this.shaded) {
        classes.push(this.$style.shaded);
      }

      return classes;
    },
    stateTitle() {
      let titleText = '';

      switch (this.state) {
        case STATES.in_action:
          titleText = this.inActionTitle;
          break;
        case STATES.success:
          titleText = this.onSuccessTitle;
          break;
        case STATES.error:
          if (this.onErrorTitle && this.errorMessage) {
            titleText = this.onErrorTitle;
            titleText += '\n';
            titleText += this.errorMessage;
          }

          if (!this.onErrorTitle && this.errorMessage) {
            titleText += this.errorMessage;
          }

          break;
        default:
          titleText = '';
      }

      return titleText;
    }
  },
  methods: {
    updateState(newState) {
      this.state = newState;
      this.$emit('state', newState);
    },
    relayChange(newValue) {
      this.$emit('change', newValue);
    },
    relaySuccess(newValue) {
      this.$emit('success', newValue);
    },
    relayError(newValue) {
      this.$emit('error', newValue);
    },
    relayValidationResult(validationResult) {
      this.$emit('validation_result', validationResult);
    },
    getStateIcon(state) {
      if (state === STATES.in_action) return 'saving.svg';
      if (state === STATES.success) return 'saved.svg';
      if (state === STATES.error) return 'not_saved.svg';

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import './FormThreeWaySwitchAutoSave.scss';
</style>
