<template>
  <div :class="$style.wrapper" class="box-shadow-medium">
    <KeyboardControlled
      :listLength="users.length"
      selectedEvent="selected"
      focusedEvent="focused"
      @selected="onSelected"
      @focused="onFocused"
    >
      <!-- eslint-disable -->
      <template v-slot="{ selectedIndex }">
        <AvatarList
          :users="users"
          :size="size"
          :searchRegExp="searchRegExp"
          :activeUserId="selectedUserId"
          @select="selectRelay"
        >
          <template #empty>
            <div :class="$style.placeholder">
              No users matching search criteria
            </div>
          </template>
        </AvatarList>
      </template>
    </KeyboardControlled>
  </div>
</template>

<script>
import AvatarList from '@/components/common/AvatarList/AvatarList';
import KeyboardControlled from '@/components/common/KeyboardControlled/KeyboardControlled';

export default {
  components: {
    AvatarList,
    KeyboardControlled
  },
  props: {
    users: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: String
    },
    searchRegExp: {
      type: RegExp
    }
  },
  data() {
    return {
      selectedUserIndex: undefined
    };
  },
  computed: {
    selectedUserId() {
      const selectedUser = this.users[this.selectedUserIndex];
      return selectedUser && selectedUser.user_id
        ? selectedUser.user_id
        : undefined;
    }
  },
  methods: {
    onFocused(selectedIndex) {
      this.selectedUserIndex = selectedIndex;
    },
    onSelected(selectedIndex) {
      this.selectedUserIndex = selectedIndex;

      if (this.selectedUserId) this.selectRelay(this.selectedUserId);
    },
    selectRelay(userId) {
      if (!userId) return;

      this.$emit('select', userId);
    }
  }
};
</script>

<style module lang="scss">
@import './AvatarSelect.scss';
</style>
