<template>
  <FileUploadBase
    :step="forceStep || step"
    :allowedMimeTypes="allowedMimeTypes"
    :errors="errors"
    :class="$style.wrapper"
  >
    <template #waiting>
      <div :class="$style.content">
        <div :class="$style.uploadCard">
          <FileUploadArea
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @validation="onValidation"
            :maxSizeInMb="maxSizeInMb"
          >
            <div class="with-icon">
              <i class="icon icon-plus"></i>

              <div class="paragraph-large">
                <p class="text-semibold">
                  <em>
                    Drag &amp; drop or click to upload a file
                  </em>
                </p>
                <p>
                  <small>
                    * Max file size is {{ maxSizeInMb }} MB and currently only
                    {{ humanizeMimeTypes(allowedMimeTypes) }} is allowed.
                  </small>
                </p>
              </div>
            </div>
          </FileUploadArea>
        </div>
      </div>
    </template>

    <template #uploading>
      <div :class="$style.content">
        <div :class="$style.uploadCard">
          <p>Uploading <InlineLoader /></p>
        </div>
      </div>
    </template>

    <template #success>
      <div :class="$style.content">
        <div :class="$style.uploadCard">
          <FileUploadArea
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @validation="onValidation"
            :maxSizeInMb="maxSizeInMb"
          >
            <a>
              <i class="icon icon-plus"></i> Drag &amp; drop or click to upload
              a file
            </a>
          </FileUploadArea>
        </div>
      </div>
    </template>

    <template #error>
      <div :class="$style.content">
        <div :class="$style.uploadCard">
          <FileUploadArea
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @validation="onValidation"
            :maxSizeInMb="maxSizeInMb"
          >
            <div class="with-icon">
              <i class="icon icon-remove error"></i>

              <div class="paragraph-large">
                <p class="text-semibold">
                  <em>
                    Oops... the file upload failed. Click here to try again.
                  </em>
                </p>
                <p>
                  <small>
                    * Max file size is {{ maxSizeInMb }} MB and currently only
                    {{ humanizeMimeTypes(allowedMimeTypes) }} is allowed.
                  </small>
                </p>
              </div>
            </div>
          </FileUploadArea>
        </div>
      </div>
    </template>
  </FileUploadBase>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { CREATE_FILE_RESOURCE } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import {
  UPLOAD_STEP,
  FILENAME_MAX_LENGTH,
  MIME_TYPES_BY_FILE_EXTENSION
} from '@/config/files';
import {
  humanizeMimeTypes,
  mimeTypeToUploadType,
  FileTooLargeError,
  FileNameTooLongError,
  InvalidFileTypeError
} from '@/helpers/files';
import { hasErrorStatus, getServerErrorMessages } from '@/helpers/errors';
import { THEMES, DEFAULT_THEME } from '@/config/themes';

import FileUploadBase from '@/components/common/FileUploadBase/FileUploadBase';
import FileUploadArea from '@/components/common/FileUploadArea/FileUploadArea';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

export default {
  name: 'ResourceUpload',
  components: {
    FileUploadBase,
    FileUploadArea,
    InlineLoader
  },
  props: {
    forceStep: {
      type: String
    },
    nowISO: {
      type: String
    },
    uploadingStepDuration: {
      type: Number,
      default: 3 * 1000 // three seconds
    }
  },
  data() {
    return {
      step: UPLOAD_STEP.waiting,
      errors: [],
      allowedMimeTypes: [MIME_TYPES_BY_FILE_EXTENSION.pdf],
      maxSizeInMb: 25
    };
  },
  computed: {
    ...mapGetters(['isPhoneLayout', 'currentUserId'])
  },
  created() {
    this.humanizeMimeTypes = humanizeMimeTypes;
    this.UPLOAD_STEP = UPLOAD_STEP;
    this.primaryColor = THEMES[process.env.THEME || DEFAULT_THEME].primaryColor;
  },
  methods: {
    format(dateISOString) {
      if (dateISOString) {
        const m_date = moment(dateISOString);
        if (m_date.isValid()) {
          return m_date.format('LL, k:mm');
        }
      }

      return '';
    },
    onValidation(payload) {
      this.step = UPLOAD_STEP.error;

      if (payload && payload.errors && payload.errors.length > 0) {
        const error = payload.errors[0];

        if (error instanceof FileTooLargeError) {
          this.errors.push('File size is too large!');
        } else if (error instanceof InvalidFileTypeError) {
          this.errors.push('File type is wrong!');
        } else if (error instanceof FileNameTooLongError) {
          this.errors.push(
            `File name is too long! Maximum ${FILENAME_MAX_LENGTH} chars allowed.`
          );
        }

        this.$emit('error', error);
      }
    },
    handleUploadSuccess(resource) {
      this.errors = [];
      this.step = UPLOAD_STEP.success;
      this.$emit('success', {
        resource
      });
    },
    async onInserted(payload) {
      if (!payload) return;

      const file = payload && payload.file;
      this.errors = [];
      this.step = UPLOAD_STEP.uploading;
      this.$emit('uploading');

      try {
        const resource = await this.$store.dispatch(CREATE_FILE_RESOURCE, {
          file,
          type: mimeTypeToUploadType(file.type), // file.type is actually a browser MIME type :facepalm:
          title: file.name,
          ownerIds: [this.currentUserId]
        });

        if (['test', 'e2e'].includes(process.env.NODE_ENV)) {
          this.handleUploadSuccess(resource);
        } else {
          setTimeout(() => {
            this.handleUploadSuccess(resource);
          }, this.uploadingStepDuration);
        }
      } catch (error) {
        if (hasErrorStatus(422, error)) {
          this.errors.push(getServerErrorMessages(error).join(', '));
        } else {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          this.errors.push(
            'An unexpected error has occurred. Please try again.'
          );
        }

        this.step = UPLOAD_STEP.error;
        this.$emit('error', error);
      }
    }
  }
};
</script>

<style module lang="scss">
@import './ResourceUpload.scss';
</style>
