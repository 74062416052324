<template>
  <DoNotTrack>{{ firstTeamName }}</DoNotTrack>
</template>

<script>
export default {
  name: 'UserTeamName',
  props: ['user'],
  computed: {
    firstTeamName() {
      if (
        this.user &&
        this.user.teams &&
        this.user.teams[0] &&
        this.user.teams[0].name
      ) {
        return this.user.teams[0].name;
      }

      return '';
    }
  }
};
</script>
