import ApiService from '@/api/api.service';

import { GET_FAQ_TOPIC_QUESTIONS, GET_FAQ_TOPICS } from './actions.type';
import { SET_FAQ_TOPICS } from './mutations.type';

export const state = {
  topics: []
};

export const getters = {
  faqTopics(state) {
    return state.topics;
  },
  faqTopic: state => topicSlug => {
    if (!state.topics || !state.topics.length) {
      return {};
    }

    return state.topics.find(
      topic => topic.slug.toString() === topicSlug.toString()
    );
  }
};

export const actions = {
  [GET_FAQ_TOPIC_QUESTIONS](context, topicSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getFaqTopicQuestions(topicSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_FAQ_TOPICS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getFaqTopics()
        .then(({ data }) => {
          context.commit(SET_FAQ_TOPICS, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [SET_FAQ_TOPICS](state, topics) {
    state.topics = topics;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
