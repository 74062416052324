<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Assignment Notes</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          compactMode
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'postId'">
              <template v-if="props.row.objectInfo[0] === 'blog_post'">
                <router-link
                  class="adminPrimaryId"
                  :to="{
                    name: 'blog-post',
                    params: {
                      postId: props.row.objectInfo[1]
                    }
                  }"
                >
                  {{ props.row.postId }}
                </router-link>
              </template>
              <template v-else>
                <router-link
                  class="adminPrimaryId"
                  :to="{
                    name: 'assignment-detail',
                    params: {
                      assignmentId: props.row.objectInfo[1]
                    }
                  }"
                >
                  {{ props.row.postId }}
                </router-link>
              </template>
            </span>
            <span
              v-else-if="props.column.field === 'content'"
              :class="$style.content"
            >
              <template v-if="props.row.objectInfo[0] === 'blog_post'">
                <router-link
                  :to="{
                    name: 'blog-post',
                    params: {
                      postId: props.row.objectInfo[1]
                    }
                  }"
                  v-html="props.row.content"
                />
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: 'assignment-detail',
                    params: {
                      assignmentId: props.row.objectInfo[1]
                    }
                  }"
                  v-html="props.row.content"
                />
              </template>
            </span>
            <span v-else-if="props.column.field === 'objectInfo'">
              {{ props.row.objectInfo[0] }}
              <span class="adminSecondaryId">
                {{ props.row.objectInfo[1] }}
              </span>
            </span>
            <span v-else-if="props.column.field === 'user'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.user[2] }
                }"
              >
                {{ props.row.user[0] + ' ' + props.row.user[1] }}
                <span class="adminSecondaryId">{{ props.row.user[2] }}</span>
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { GET_ADMIN_POSTS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      posts: [],
      columns: [
        {
          label: 'post ID',
          field: 'postId',
          width: '90px',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postID')
          }
        },
        {
          label: 'content',
          field: 'content',

          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('content')
          }
        },
        {
          label: 'object',
          field: 'objectInfo',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('objectInfo')
          }
        },
        {
          label: 'user',
          field: 'user',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('user')
          }
        },
        {
          label: 'created at',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('createdAt')
          }
        }
      ],
      rows: [],
      scrollToId: null
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchPosts();
    }
  },
  methods: {
    fetchPosts() {
      this.$store
        .dispatch(GET_ADMIN_POSTS)
        .then(posts => {
          this.posts = posts;
        })
        .then(() => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.rows = [];

      if (!this.posts || !this.posts.length) return [];

      this.posts.filter(post => {
        this.rows.push({
          postId: post.post_id || [],
          content: post.content || [],
          objectInfo: [post.object_type, post.object_id] || [],
          user:
            [
              post.user.name.first_name,
              post.user.name.last_name,
              post.user.user_id
            ] || [],
          createdAt: moment(post.created_at).format('DD.MM.yyyy HH:mm') || []
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'postId':
          return this.$route.query.postId;
          break;
        case 'content':
          return this.$route.query.content;
          break;
        case 'objectInfo':
          return this.$route.query.objectInfo;
          break;
        case 'user':
          return this.$route.query.user;
          break;
        case 'createdAt':
          return this.$route.query.createdAt;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        postId: column.postId || [],
        content: column.content || [],
        objectInfo: column.objectInfo || [],
        user: column.user || [],
        createdAt: column.createdAt || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminPosts.scss';
@import '../AdminTables.scss';
</style>
