<template>
  <header
    :class="{ [$style.wrapper]: true, [$style.collapsed]: collapsed === true }"
  >
    <ListFacilitators :facilitators="session.facilitators" />

    <span>Group Assignment Submission</span>
    <strong :class="$style.sessionDuration"></strong>

    <div>
      <!-- newrow join -->
    </div>
    <span
      v-if="
        leadSessionGradingComponent !== GRADING_TYPES.TYPENULL &&
          hasEnded === true &&
          isPublished === true
      "
      class="grading-state-evaluated"
      data-cy="grading-state"
    >
      &#x25CF; EVALUATED
    </span>
    <span
      v-if="
        leadSessionGradingComponent !== GRADING_TYPES.TYPENULL &&
          hasEnded === true &&
          isPublished === false
      "
      class="grading-state-pending"
      data-cy="grading-state"
    >
      &#x25CF; PENDING
    </span>
    <span v-if="hasEnded === false"></span>

    <a @click="$emit('flip', !collapsed)" :class="$style.flipLink" class="link">
      <i class="icon icon-chevron-down" v-if="collapsed === true" />
      <i class="icon icon-chevron-up" v-if="collapsed === false" />
    </a>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { GRADING_TYPES } from '@/config/grading';
import ListFacilitators from './ListFacilitators';

export default {
  name: 'ContainerGroupWorkHeader',
  components: { ListFacilitators },
  props: {
    session: {
      type: Object,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: undefined
    },
    nowISO: {
      type: String,
      required: true
    },
    isPublished: {
      type: Boolean,
      default: undefined
    },
    hasEnded: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    ...mapGetters('grading', ['leadSessionGradingComponent'])
  },
  created() {
    this.GRADING_TYPES = GRADING_TYPES;
  }
};
</script>

<style lang="scss" module>
@import './styles/ContainerHeader.scss';
</style>
