// contains data for currently active assessment (only one can be active)

import { QUESTION_KIND } from '@/config/assessments';

import ApiService from '@/api/api.service';

import { logError } from '@/helpers/errors';

export const state = {
  // general assessment information available all the time
  assessment: null,
  // actual asssessment of the user, available after assessment start only
  submission: null
};

export const getters = {
  assessment(state) {
    return state.assessment;
  },
  submission(state) {
    return state.submission;
  }
};

export const mutations = {
  setAssessment(state, assessment) {
    state.assessment = assessment;
  },
  setSubmission(state, submission) {
    state.submission = submission;
  },
  setQuestionAnswer(state, answerPayload) {
    const questions = state.submission.questions;
    const questionId = answerPayload.answerContent.question_id;

    const foundQuestion = questions.find(
      question => question.id === questionId
    );

    if (!foundQuestion) {
      logError(
        `Answer save success, but question ${questionId} not found in store for submissionId ${answerPayload.submissionId}`
      );

      return;
    }

    const questionType = foundQuestion.type;

    if (questionType === QUESTION_KIND['free-text']) {
      foundQuestion.text_answer = answerPayload.answerContent.text_entry;

      return;
    }

    if (questionType === QUESTION_KIND['options']) {
      const optionsToCheck = answerPayload.answerContent.checked_option_ids;

      foundQuestion.options.map(option => {
        if (optionsToCheck.includes(option.id)) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });

      return;
    }

    // These two types should prabably never be inside test-player, but adding them here for the sake of completeness
    if (
      [
        QUESTION_KIND['collaborative-editor'],
        QUESTION_KIND['file-upload']
      ].includes(questionType)
    ) {
      logError(
        `Unsupported question kind "${questionType}" found in question ${questionId} of submissionId ${answerPayload.submissionId}!`
      );
      return;
    }

    logError(
      `Unknown question kind "${questionType}" found in question ${questionId} of submissionId ${answerPayload.submissionId}!`
    );
  }
};

export const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
