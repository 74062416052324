<template v-if="attempt && attempt.attempt_id">
  <div>
    <div :class="$style.content">
      <div :class="$style.name">
        <template v-if="questions.length">
          <div
            v-for="(question, index) in questions"
            :key="index"
            :class="$style.answer"
            data-cy="exam_answer"
          >
            <strong>Question {{ index + 1 }}</strong>
            <p
              :class="$style.questionDescription"
              v-html="question.description || question.question"
            />
            <ExamAnswer
              :questionId="question.id"
              :kind="question.type"
              :maxChoices="question.max_choices"
              :options="question.options"
              :textAnswer="question.text_answer"
              :resourceSlug="question.resource_slug"
              :disabled="true"
              :styleAsResults="true"
            />
            <div
              v-if="question.correct_text_answer"
              :class="$style.correctTextAnswer"
              class="theme-box"
              title="Suggested correct answer"
            >
              {{ question.correct_text_answer }}
            </div>
          </div>
        </template>

        <div v-if="attempt" :class="$style.results">
          <hr />

          <AssessmentFeedback
            class="theme-box"
            :scorePoints="scorePoints"
            :scoreMaxPoints="scoreMaxPoints"
          />

          <div :class="$style.navigation">
            <FormButton
              :class="$style.action"
              :standard="true"
              @click="relayRetake"
              data-cy="exam_start"
              >Retake the Quiz</FormButton
            >

            <FormButton
              :class="$style.action"
              :ghost="true"
              @click="relayClose"
              data-cy="exam_close"
              >Close</FormButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeDuplicateObjByKey } from '@/helpers/common';

import ExamAnswer from '@/components/exams/ExamAnswer/ExamAnswer';
import AssessmentFeedback from '@/components/gradebook/AssessmentFeedback/AssessmentFeedback';

export default {
  components: {
    ExamAnswer,
    AssessmentFeedback
  },
  props: {
    attempt: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    scorePoints() {
      return this.getReachedScorePoints(this.questions);
    },
    scoreMaxPoints() {
      return this.getMaxScorePoints(this.questions);
    },
    questions() {
      if (
        this.attempt &&
        this.attempt.questions &&
        Array.isArray(this.attempt.questions)
      ) {
        return removeDuplicateObjByKey(this.attempt.questions, 'question_id');
      }

      return [];
    }
  },
  methods: {
    getReachedScorePoints(questions) {
      if (!questions || !questions.length) return NaN;

      return questions.reduce((memo, question) => {
        const maxChoices = parseInt(question.max_choices);
        const numberOfCorrectAnswers = question.options.filter(
          option => option.selected === true && option.is_correct === true
        ).length;

        if (maxChoices === 1) {
          // Single correct option case
          return numberOfCorrectAnswers === 1 ? ++memo : memo;
        } else {
          // Multiple correct options case
          return maxChoices === numberOfCorrectAnswers ? ++memo : memo;
        }
      }, 0);
    },
    getMaxScorePoints(questions) {
      if (!questions || !questions.length) return NaN;

      return questions.reduce((memo, question) => {
        return memo + 1;
      }, 0);
    },
    relayRetake() {
      this.$emit('retake');
    },
    relayClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" module>
@import './QuizResults.scss';
</style>
