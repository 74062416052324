<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Cohorts ({{ totalRecords }})</h6>
          <div :class="$style.buttons">
            <FormButton @click="handleOpened" :class="$style.greenButton"
              ><i class="icon icon-user-plus" />
              Create cohort
            </FormButton>
            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
          :pagination-options="{
            enabled: true,
            infoFn: params => setTotalRecords(params.totalRecords)
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <router-link
                class="adminPrimaryId"
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.id }
                }"
              >
                {{ props.row.id }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'name'">
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.id }
                }"
              >
                {{ props.row.name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'currentModule'">
              <router-link
                v-if="props.row.currentModule"
                :to="{
                  name: 'admin-module-detail',
                  params: { moduleId: props.row.moduleId }
                }"
              >
                {{ props.row.currentModule }}
                <span class="adminSecondaryId">{{ props.row.moduleId }}</span>
              </router-link>
              <span v-else class="text-bold">-</span>
            </span>
            <span v-else-if="props.column.field === 'students'">
              <router-link
                :to="{
                  name: 'admin-students',
                  params: { cohort_name: props.row.name }
                }"
              >
                {{ props.row.students }}
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>

        <ModalNew
          :class="$style.adminModal"
          :showModal="showModal"
          v-transfer-dom
          @close="handleClosed"
        >
          <AdminCreateCohort @reloadPage="fetchCohorts()" />
        </ModalNew>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { GET_ADMIN_COHORTS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminCreateCohort from '@/components/admin/AdminCreateCohort/AdminCreateCohort';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable,
    AdminCreateCohort
  },
  data() {
    return {
      showModal: false,
      totalRecords: 0,
      searchParams: {},
      display404: false,
      cohorts: [],
      columns: [
        {
          label: 'Cohort ID',
          field: 'id',
          type: 'number',
          filterOptions: {
            enabled: true,
            styleClass: 'columnId',
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'Cohort name',
          field: 'name',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('name')
          }
        },
        {
          label: '# Students',
          field: 'students',
          type: 'number',
          filterOptions: {
            enabled: true,
            styleClass: 'students',
            filterValue: this.loadUrlWithFilters('students')
          }
        },
        {
          label: 'Module name',
          field: 'currentModule',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('currentModule')
          }
        },
        {
          label: 'Current phase',
          field: 'currentPhase',
          tdClass: 'currentPhase',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('currentPhase')
          }
        },
        {
          label: 'Duration',
          field: 'currentPhaseDuration',
          sortable: false,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('currentPhaseDuration')
          }
        },
        {
          label: 'Next phase',
          field: 'nextPhase',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('nextPhase')
          }
        },
        {
          label: 'Duration',
          field: 'nextPhaseDuration',
          sortable: false,
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('nextPhaseDuration')
          }
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchCohorts();
    }
  },
  methods: {
    setTotalRecords(totalRecords) {
      this.totalRecords = totalRecords;
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'name':
          return this.$route.query.name;
          break;
        case 'students':
          return this.$route.query.students;
          break;
        case 'currentModule':
          return this.$route.query.currentModule;
          break;
        case 'currentPhase':
          return this.$route.query.currentPhase;
          break;
        case 'currentPhaseDuration':
          return this.$route.query.currentPhaseDuration;
          break;
        case 'nextPhase':
          return this.$route.query.nextPhase;
          break;
        case 'nextPhaseDuration':
          return this.$route.query.nextPhaseDuration;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        name: column.name || [],
        students: column.students || [],
        currentModule: column.currentModule || [],
        currentPhase: column.currentPhase || [],
        currentPhaseDuration: column.currentPhaseDuration || [],
        nextPhase: column.nextPhase || [],
        nextPhaseDuration: column.nextPhaseDuration || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    fetchCohorts() {
      this.$store
        .dispatch(GET_ADMIN_COHORTS)
        .then(cohorts => {
          this.cohorts = cohorts;
        })
        .then(cohorts => {
          this.showModal = false;
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.rows = [];

      this.cohorts.filter(cohort => {
        this.rows.push({
          id: cohort.cohort_id || '',
          name: cohort.name || '',
          students: cohort.student_count || 0,
          currentModule:
            (cohort.current_cohort_phase &&
              cohort.current_cohort_phase.module.name) ||
            '',
          moduleId:
            (cohort.current_cohort_phase &&
              cohort.current_cohort_phase.module.module_id) ||
            '',
          currentPhase:
            (cohort.current_cohort_phase &&
              cohort.current_cohort_phase.label) ||
            '',
          currentPhaseDuration:
            this.currentPhaseDuration(cohort.current_cohort_phase) || '',
          nextPhase:
            (cohort.next_cohort_phase && cohort.next_cohort_phase.label) || '',
          nextPhaseDuration:
            this.nextPhaseDuration(cohort.next_cohort_phase) || ''
        });
      });
    },
    currentPhaseDuration(phase) {
      if (!phase) {
        return null;
      }
      return (
        moment(phase.start_date).format('DD.MM.yyyy') +
        ' - ' +
        moment(phase.end_date).format('DD.MM.yyyy')
      );
    },
    nextPhaseDuration(phase) {
      if (!phase) {
        return null;
      }

      return (
        moment(phase.start_date).format('DD.MM.yyyy') +
        ' - ' +
        moment(phase.end_date).format('DD.MM.yyyy')
      );
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    handleOpened() {
      this.showModal = true;
    },
    handleClosed() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminTables.scss';
</style>
