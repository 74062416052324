<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Students ({{ totalRecords }})</h6>
          <div :class="$style.homeButton">
            <router-link to="/admin" :class="$style.button">
              <i class="icon icon-home"
            /></router-link>
          </div>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
          :pagination-options="{
            enabled: true,
            infoFn: params => setTotalRecords(params.totalRecords)
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
                class="adminPrimaryId"
              >
                {{ props.row.id }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'module'">
              <router-link
                :to="{
                  name: 'admin-module-detail',
                  params: { moduleId: props.row.moduleId }
                }"
              >
                {{ props.row.module }}
                <span class="adminSecondaryId">{{ props.row.moduleId }}</span>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'firstName'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
              >
                {{ props.row.firstName }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'lastName'">
              <router-link
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.id }
                }"
              >
                {{ props.row.lastName }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'cohort'">
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: props.row.cohort[0] }
                }"
              >
                {{ props.row.cohort[1] }}
                <p class="adminSecondaryId">{{ props.row.cohort[0] }}</p>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'groups'">
              <p v-if="props.row.groups.length">
                <span v-for="(group, index) in props.row.groups" :key="index">
                  <template v-if="index > 0">, </template>
                  <router-link
                    v-if="group.object_id"
                    :to="{
                      name: 'admin-group-detail',
                      params: { userGroupId: group.user_group_id }
                    }"
                  >
                    {{ group.object_name }}
                    <p class="adminSecondaryId">{{ group.object_id }}</p>
                  </router-link>
                  <span v-else>{{ group.object_name }}</span>
                </span>
              </p>
              <p v-else>-</p>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_ADMIN_STUDENTS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable
  },
  data() {
    return {
      totalRecords: 0,
      searchParams: {},
      display404: false,
      students: [],
      columns: [
        {
          label: 'User ID',
          field: 'id',
          type: 'number',
          filterOptions: {
            enabled: true,
            styleClass: 'columnId',
            filterValue: this.loadUrlWithFilters('id')
          }
        },
        {
          label: 'First name',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('firstName')
          }
        },
        {
          label: 'Last name',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('lastName')
          }
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('status')
          }
        },
        {
          label: 'Cohort name',
          field: 'cohort',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('cohort')
          }
        },
        {
          label: 'Module name',
          field: 'module',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('module')
          }
        },
        {
          label: 'User groups',
          field: 'groups',
          sortable: false,
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('groups')
          }
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchStudents();
    }
  },
  methods: {
    setTotalRecords(totalRecords) {
      this.totalRecords = totalRecords;
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    // Check if string matches any group name in the array
    filterGroups(data, filterString) {
      if (data) {
        return data.find(
          group =>
            group.object_name
              .toLowerCase()
              .includes(filterString.toLowerCase()) ||
            group.object_id.toString().includes(filterString)
        );
      }
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'id':
          return this.$route.query.id;
          break;
        case 'firstName':
          return this.$route.query.firstName;
          break;
        case 'lastName':
          return this.$route.query.lastName;
          break;
        case 'status':
          return this.$route.query.status;
          break;
        case 'cohort':
          // Check if user wants to see all the students in cohort - param from /cohort view.
          return this.$route.params.cohort_name || this.$route.query.cohort;
          break;
        case 'module':
          return this.$route.query.module;
          break;
        case 'groups':
          return this.$route.query.groups;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        id: column.id || [],
        firstName: column.firstName || [],
        lastName: column.lastName || [],
        status: column.status || [],
        cohort: column.cohort || [],
        module: column.module || [],
        module: column.user_groups || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    fetchStudents() {
      this.$store
        .dispatch(GET_ADMIN_STUDENTS)
        .then(students => {
          this.students = students;
        })
        .then(students => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.students.filter(student => {
        this.rows.push({
          id: student.user_id || '',
          firstName: student.name.first_name || '',
          lastName: student.name.last_name || '',
          status: this.studentStatus(student.active) || '',
          cohort: this.cohortColumn(student.cohort) || '',
          module: (student.module_version && student.module_version.name) || '',
          moduleId:
            (student.module_version &&
              student.module_version.module_version_id) ||
            '',
          groups: student.user_groups || ''
        });
      });
    },
    cohortColumn(cohort) {
      if (cohort) {
        return [cohort.cohort_id, cohort.name];
      }
    },
    studentStatus(status) {
      return status == true ? 'Active' : 'On Hold';
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminTables.scss';
</style>
