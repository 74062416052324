import { API_URL, ENDPOINTS } from './config';
import {
  MESSAGES_POLLING_MS,
  CONVERSATION_TYPES
} from '@/config/conversations';
import { QUESTION_KIND } from '@/config/assessments';

import {
  removeNullItemsFromObj,
  removeFalseyItemsFromObj,
  serializeObj
} from '@/helpers/common';
import { logError, logWarning } from '@/helpers/errors';
import { getAuthConfig } from '@/helpers/auth';

/**
 * @param {Object} Search parameters
 *  {Array} phases An array of phases's slugs
 *  {Array} types An arry of types
 *  {Array} logicalTypes An array of logical types
 *  {String} text Full-text search filter
 *  {Number} limit Number of records in response
 *  {Number} offset Number of records to skip from start
 *  {String} timestamp ISO string formatted
 */
function extractParams(
  params = {
    phase: [],
    type: [],
    logicalType: [],
    unit: [],
    objective: [],
    text: '',
    limit: 0,
    offset: 0,
    timestamp: ''
  }
) {
  let filters = {};

  if (!params) return filters;

  if (params.phase && params.phase.length) {
    filters.phase = params.phase;
  }

  if (params.type && params.type.length) {
    filters.type = params.type;
  }

  if (params.logicalType && params.logicalType.length) {
    filters.logical_type_code = params.logicalType;
  }

  if (params.unit && params.unit.length) {
    filters.unit = params.unit;
  }

  if (params.objective && params.objective.length) {
    filters.objective = params.objective;
  }

  if (params.text && params.text.length) {
    filters.text = params.text;
  }

  if (params.limit) {
    filters.limit = params.limit;
  }

  if (params.offset) {
    filters.offset = params.offset;
  }

  if (params.timestamp && params.timestamp.length) {
    filters.timestamp = params.timestamp;
  }

  return filters;
}

/**
 * @param {Object} Search parameters
 *  {Number} user User id
 *  {String} module Module slug
 *  {String} module_phase Phase slug
 */
function extractSubmissionParams(
  params = {
    user: undefined,
    module: undefined,
    module_phase: undefined
  }
) {
  let filters = {};

  if (!params) return filters;

  if (params.user) {
    filters.user = params.user;
  }

  if (params.module) {
    filters.module = params.module;
  }

  if (params.module_phase) {
    filters.module_phase = params.module_phase;
  }

  return filters;
}

function deprecated(call) {
  logWarning('Deprecated call to:', call);
  return call;
}

export default class ApiClient {
  constructor(axiosInstance, apiUrl = API_URL) {
    this._axios = axiosInstance;
    this._axios.defaults.baseURL = apiUrl;
  }

  login(email, password) {
    const payload = {
      email,
      password
    };

    return this._axios.post(ENDPOINTS.sessions(), payload);
  }

  initiatePasswordReset(email) {
    const payload = {
      email
    };

    return this._axios.post(ENDPOINTS.initiatePasswordReset(), payload);
  }

  changePassword(userId, { oldPassword = null, password = null }) {
    const config = {
      auth: getAuthConfig()
    };

    let payload = {
      old_password: oldPassword,
      password
    };

    payload = removeNullItemsFromObj(payload);

    return this._axios.put(ENDPOINTS.changePassword(userId), payload, config);
  }

  changeUserStatus(status = null) {
    const config = {
      auth: getAuthConfig()
    };

    let payload = {
      status
    };

    payload = removeNullItemsFromObj(payload);

    return this._axios.put(ENDPOINTS.userStatus(), payload, config);
  }

  getDomainMetadata() {
    return this._axios.get(ENDPOINTS.domainMetadata());
  }

  /* Authenticated */

  getDomainCountries() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.domainCountries(), config);
  }

  validateSession() {
    const config = {
      auth: getAuthConfig()
    };

    if (config.auth.username === 'undefined') {
      // TODO: find out how it gets set to undefined in the first place!
      return Promise.reject();
    }

    return this._axios.get(ENDPOINTS.sessions(), config);
  }

  searchUserProfiles({ inactive = null, name = null } = {}) {
    if (!name) return Promise.resolve([]);

    let params = {
      name,
      inactive
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.users(), config);
  }

  searchCohorts({ inactive = null, name = null } = {}) {
    if (!name) return Promise.resolve([]);

    let params = {
      name,
      inactive
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.userCohorts(), config);
  }

  getBulkUserProfiles({ ids = [], inactive = null } = {}) {
    let params = {
      inactive
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.bulkUsers(ids.join(',')), config);
  }

  getUserProfiles({ inactive = null } = {}) {
    let params = {
      inactive
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.users(), config);
  }

  getUserCohorts({ inactive = null } = {}) {
    let params = {
      inactive
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.userCohorts(), config);
  }

  selectUserCohort(cohortSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.put(
      ENDPOINTS.userCohortSelect(cohortSlug),
      null,
      config
    );
  }

  getUserProfile(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.user(userId), config);
  }

  getUserInfo(userId = 'current') {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.userInfo(userId), config);
  }

  updateUserProfile(userId, profileData) {
    // remove all `null` values so we won't patch
    // some profile data to `null`s
    profileData = removeNullItemsFromObj(profileData);

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(ENDPOINTS.user(userId), profileData, config);
  }

  storeCurrentUserConsents(payload = ['tos', 'pp']) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(ENDPOINTS.currentUserConsents(), payload, config);
  }

  getTeams({ inactive = null, unassigned = null } = {}) {
    let params = {
      inactive,
      unassigned
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.teams(), config);
  }

  getTeamProfile(teamSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.team(teamSlug), config);
  }

  updateTeamProfile(teamSlug, { name = null, description = null }) {
    let payload = {
      name,
      description
    };

    // remove all `null` values so we won't patch
    // some team profile data to `null`s
    payload = removeNullItemsFromObj(payload);

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(ENDPOINTS.team(teamSlug), payload, config);
  }

  uploadAvatar(file, onUploadProgress) {
    const config = {
      auth: getAuthConfig(),
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    };

    const payload = new FormData();
    payload.append('avatar', file, file.name);

    return this._axios.post(ENDPOINTS.avatarsPostDelete(), payload, config);
  }

  getAvatars(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.avatars(userId), config);
  }

  downloadAvatar(avatarUrl, onDownloadProgress) {
    const config = {
      auth: getAuthConfig(),
      baseURL: '/',
      responseType: 'arraybuffer',
      onDownloadProgress
    };

    return this._axios.get(avatarUrl, config);
  }

  deleteAvatar() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.avatarsPostDelete(), config);
  }

  getTasks({ limit = null, offset = null } = {}) {
    let params = {
      limit,
      offset
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.tasks(), config);
  }

  getTask(taskId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.task(taskId), config);
  }

  updateTask(taskId, { blocks = null, completed = null }) {
    let payload = {
      blocks,
      completed
    };

    payload = removeNullItemsFromObj(payload);

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(ENDPOINTS.task(taskId), payload, config);
  }

  archiveTask(taskId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.task(taskId), config);
  }

  archiveTasks() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.tasks(), config);
  }

  getUsersStreamEvents({ limit = null, timestamp = null, after = null } = {}) {
    let params = {
      limit,
      timestamp,
      after
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.usersStreamEvents(), config);
  }

  getCalendarUrl() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.userCalendar(), config);
  }

  getTotalUnreadMessagesCount() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.unreadCount(), config);
  }

  getConversations() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.conversations(), config);
  }

  /**
   * @param {Number, String} conversationId
   * @param {String} after ISO8601 date, retrieve only messages after this date
   * @param {String} before ISO8601 date, retrieve only messages before this date
   * @param {Boolean} poll If not new messages are available wait for them up until timeout
   * @param {Number} timeout Timeout for polling in seconds
   * @param {Function} cancelToken Axios cancel token
   */
  getConversationMessages(
    conversationId,
    after,
    before,
    poll,
    timeout,
    cancelToken
  ) {
    let params = {};
    let config = {};

    if (after) {
      params.after = after;
    }

    if (before) {
      params.before = before;
    }

    if (poll !== undefined && poll !== null) {
      params.poll = poll;
    }

    if (timeout) {
      params.timeout = timeout;
      config.timeout = timeout * 1000; // axios accepts timeout in milliseconds
    } else {
      config.timeout = MESSAGES_POLLING_MS;
    }

    if (cancelToken) {
      config.cancelToken = cancelToken;
    }

    config.auth = getAuthConfig();
    config.params = params;

    return this._axios.get(ENDPOINTS.conversation(conversationId), config);
  }

  sendConversationMessage(conversationId, message) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      message: message
    };

    return this._axios.post(
      ENDPOINTS.conversation(conversationId),
      payload,
      config
    );
  }

  subscribeNotifications(token) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      token: token
    };

    return this._axios.post(ENDPOINTS.notifications(), payload, config);
  }

  unsubscribeNotifications() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.notifications(), config);
  }

  readConversation(conversationId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(
      ENDPOINTS.conversation(conversationId),
      null,
      config
    );
  }

  createConversation(
    payload = {
      type: CONVERSATION_TYPES.ADHOC,
      object_id: null,
      participant_ids: null
    }
  ) {
    const config = {
      auth: getAuthConfig()
    };

    payload = removeNullItemsFromObj(payload);

    return this._axios.post(ENDPOINTS.conversations(), payload, config);
  }

  getModules(params) {
    let mergedParams = {
      ...{
        inactive: null
      },
      ...params
    };

    mergedParams = removeNullItemsFromObj(mergedParams);

    const config = {
      auth: getAuthConfig(),
      params: mergedParams
    };

    return this._axios.get(ENDPOINTS.modules(), config);
  }

  getModulesPhases() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.modulesPhases(), config);
  }

  getWebinarAuthToken() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.webinarAuthToken(), config);
  }

  getModulePhases() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.modulePhases(), config);
  }

  getScheduleUpcoming() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.scheduleUpcoming(), config);
  }

  getScheduleWeekCurrent({
    asUserId = NaN,
    asCohortId = NaN,
    moduleVersionId = NaN,
    personal = false
  } = {}) {
    let params = {
      as_user_id: asUserId,
      as_cohort_id: asCohortId,
      module_version_id: moduleVersionId
    };

    params = removeFalseyItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(
      personal
        ? ENDPOINTS.personalScheduleWeekCurrent()
        : ENDPOINTS.scheduleWeekCurrent(),
      config
    );
  }

  getScheduleWeek({
    asUserId = NaN,
    asCohortId = NaN,
    calendarWeekId = NaN,
    moduleVersionId = NaN,
    personal = false
  } = {}) {
    let params = {
      as_user_id: asUserId,
      as_cohort_id: asCohortId,
      module_version_id: moduleVersionId
    };

    params = removeFalseyItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(
      personal
        ? ENDPOINTS.personalScheduleWeek(calendarWeekId)
        : ENDPOINTS.scheduleWeek(calendarWeekId),
      config
    );
  }

  getSessionAssignments(phaseSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.sessionAssignments(phaseSlug), config);
  }

  getScheduledAssignment(scheduledAssignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.scheduledAssignment(scheduledAssignmentId),
      config
    );
  }

  getAssignment(assignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.assignment(assignmentId), config);
  }

  getAssignmentQuizStats(assignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.assignmentQuizStats(assignmentId), config);
  }

  getAssignments(options) {
    let params = removeNullItemsFromObj({
      ...options,
      ...{ experiment: true }
    });

    const endpoint = ENDPOINTS.assignmentsByStatus(params.status);

    delete params.limit;
    delete params.offset;
    delete params.status;

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(endpoint, config);
  }

  getBulkAssignments({ ids = [] } = {}) {
    let params = {};

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.bulkAssignments(ids.join(',')), config);
  }

  postAssignmentNoteResources(assignmentId, payload) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.assignmentNoteResources(assignmentId),
      payload,
      config
    );
  }

  deleteAssignmentNoteResources(assignmentId, resourceSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(
      ENDPOINTS.assignmentNoteResource(assignmentId, resourceSlug),
      null,
      config
    );
  }

  postAssignmentNoteText(assignmentId, payload) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.assignmentNoteText(assignmentId),
      payload,
      config
    );
  }

  postAssignmentNotePublish(assignmentId, payload) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.assignmentNotePublish(assignmentId),
      payload,
      config
    );
  }

  getScheduledAssignments(assignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.scheduledAssignments(assignmentId),
      config
    );
  }

  getCurrentPhaseGroupWork() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.currentPhaseGroupWork(), config);
  }

  getModuleGroupWorks(moduleVersionId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.moduleGroupWorks(moduleVersionId), config);
  }

  getAnnouncement() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.announcement(), config);
  }

  hideAnnouncement(announcementSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.announcementHide(announcementSlug),
      config
    );
  }

  getBlogPosts({
    limit = null,
    offset = null,
    timestamp = null,
    tag = null
  } = {}) {
    let params = {
      limit,
      offset,
      timestamp,
      tag
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.blogPosts(), config);
  }

  getBlogPost(blogPostSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.blogPost(blogPostSlug), config);
  }

  getTags({ category = null } = {}) {
    let params = {
      category
    };

    params = removeNullItemsFromObj(params);

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.tags(), config);
  }

  updateBlogPostTag(postId, tagId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.blogPostTags(postId, tagId),
      null,
      config
    );
  }

  untagBlogPost(blogPostSlug, tagSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.blogPostUntag(blogPostSlug, tagSlug),
      config
    );
  }

  getNewDiscussionsTopicId(objectType, objectId) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      object_type: objectType,
      object_id: objectId
    };

    return this._axios.post(ENDPOINTS.newDiscussionsTopics(), payload, config);
  }

  getNewDiscussionsTopicPosts(topicId, since) {
    let params = {};

    if (since) {
      params.since = since;
    }

    const config = {
      auth: getAuthConfig(),
      params: removeNullItemsFromObj(params)
    };

    return this._axios.get(ENDPOINTS.newDiscussionsTopicPosts(topicId), config);
  }

  deleteNewDiscussionsTopicPost(topicId, postId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(
      ENDPOINTS.newDiscussionsTopicPost(topicId, postId),
      config
    );
  }

  getReactions(objectType, objectId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.reactions(objectType, objectId), config);
  }

  postReaction(objectType, objectId, payload) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.reactions(objectType, objectId),
      payload,
      config
    );
  }

  submitNewDiscussionsPost(topicId, content, parentPostId) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      parent_post_id: parentPostId,
      content: content
    };

    return this._axios.post(
      ENDPOINTS.newDiscussionsTopicPosts(topicId),
      payload,
      config
    );
  }

  getDiscussionCategories() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.discussionCategories(), config);
  }

  getDiscussionCategoryForums(categorySlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.discussionCategoryForums(categorySlug),
      config
    );
  }

  getDiscussionForum(forumSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.discussionForum(forumSlug), config);
  }

  getDiscussionForumTopics(forumSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.discussionForumTopics(forumSlug), config);
  }

  createDiscussionForumTopic(forumSlug, topicSubject, topicContent) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      subject: topicSubject,
      content: topicContent
    };

    return this._axios.post(
      ENDPOINTS.discussionForumTopics(forumSlug),
      payload,
      config
    );
  }

  getDiscussionTopic(topicSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.discussionTopic(topicSlug), config);
  }

  subscribeDiscussionTopic(topicSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.discussionTopicSubscribe(topicSlug),
      config
    );
  }

  unsubscribeDiscussionTopic(topicSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.discussionTopicUnsubscribe(topicSlug),
      config
    );
  }

  getFaqTopicQuestions(topicSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.faqTopicQuestions(topicSlug), config);
  }

  getFaqTopics() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.faqTopics(), config);
  }

  getUserGrades(userId, moduleVersion) {
    let params = {};

    if (moduleVersion) {
      params.module_version = moduleVersion;
    }

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.userGrades(userId), config);
  }

  getCurrentUserGrades(moduleVersion) {
    let params = {};

    if (moduleVersion) {
      params.module_version = moduleVersion;
    }

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.currentUserGrades(), config);
  }

  getAdminStudents() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminStudents(), config);
  }

  getAdminStudentDetail(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminStudentDetail(userId), config);
  }

  getAdminCohorts() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminCohorts(), config);
  }

  getAdminUsers() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminUsers(), config);
  }

  getAdminUserDetail(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminUserDetail(userId), config);
  }

  getAdminUserEmails(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminUserEmails(userId), config);
  }

  setAdminUserRole(userId, roleCode) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      role_code: roleCode
    };

    return this._axios.post(ENDPOINTS.adminUserRole(userId), payload, config);
  }

  getAdminOverview() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminOverview(), config);
  }

  getAdminAssignmentNotes() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminAssignmentNotes(), config);
  }

  getAdminResourcesPending() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminResourcesPending(), config);
  }

  postAdminResourcesPendingDetail(
    resourceId,
    status,
    title,
    comment,
    selectedLearningObjectives
  ) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      status: status,
      title: title,
      comment: comment,
      selected_learning_objectives: selectedLearningObjectives
    };

    return this._axios.post(
      ENDPOINTS.adminResourcesPendingDetail(resourceId),
      payload,
      config
    );
  }

  getAdminResourcesApproved() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminResourcesApproved(), config);
  }

  getAdminResourcesRejected() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminResourcesRejected(), config);
  }

  getAdminPosts() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminPosts(), config);
  }

  updateAdminUserDetail(
    userId,
    { job_title = null, pre_nominal_title = null, post_nominal_title = null }
  ) {
    let payload = {
      job_title,
      pre_nominal_title,
      post_nominal_title
    };

    payload = removeNullItemsFromObj(payload);

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(
      ENDPOINTS.adminUserDetail(userId),
      payload,
      config
    );
  }

  createNewUser(newUserProperties) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = newUserProperties;

    return this._axios.post(ENDPOINTS.adminUsers(), payload, config);
  }

  sendUserWelcomeEmail(userId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    const payload = {};

    return this._axios.post(
      ENDPOINTS.userWelcomeEmail(userId),
      payload,
      config
    );
  }

  createNewCohort(newCohortProperties) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = newCohortProperties;

    return this._axios.post(ENDPOINTS.adminCohorts(), payload, config);
  }

  getAdminCohortDetail(cohortId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminCohortDetail(cohortId), config);
  }

  createAdminCohortDetailGroup(cohortId, groupType, groupName) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      group_type: groupType,
      name: groupName
    };

    return this._axios.post(
      ENDPOINTS.adminCohortDetailGroups(cohortId),
      payload,
      config
    );
  }

  getAdminFeedbackStatus(submissionId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminFeedbackStatus(submissionId), config);
  }

  getAdminOperationStatus(userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminOperationStatus(userId), config);
  }

  adminOperations(func, objectType, objectId, reference) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      func,
      object_type: objectType,
      object_id: objectId,
      reference
    };

    return this._axios.post(ENDPOINTS.adminOperations(), payload, config);
  }

  getPendingOperations() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminPendingOperations(), config);
  }

  adminApproveOperation(operationId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    const payload = {};

    return this._axios.post(
      ENDPOINTS.adminApproveOperation(operationId),
      payload,
      config
    );
  }

  adminResetPassword(userId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    const payload = {};

    return this._axios.post(
      ENDPOINTS.adminResetPassword(userId),
      payload,
      config
    );
  }

  adminRejectOperation(operationId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.adminOperation(operationId), config);
  }

  getAdminGradingEvaluation(evaluationId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.adminGradingEvaluation(evaluationId),
      config
    );
  }

  getAdminApprovedOperations() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminApprovedOperations(), config);
  }

  getAdminRejectedOperations() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminRejectedOperations(), config);
  }

  getAdminGroupDetail(userGroupId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminGroupDetail(userGroupId), config);
  }

  adminGroupDetailRemoveGroup(userGroupId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.adminGroupDetail(userGroupId), config);
  }

  adminGroupDetailRemoveUser(userGroupId, userId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(
      ENDPOINTS.adminGroupDetailUser(userGroupId, userId),
      config
    );
  }

  getAdminGroupDetailEligibleUsers(userGroupId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.adminGroupDetailEligibleUsers(userGroupId),
      config
    );
  }

  addUserToGroup(userGroupId, user_id) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      user_id: user_id
    };

    return this._axios.post(
      ENDPOINTS.adminGroupDetailUsers(userGroupId),
      payload,
      config
    );
  }

  getAdministrativeUserGroups() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminGroups(), config);
  }

  getAdminUserRoles() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminUserRoles(), config);
  }

  getAdminStatisticsUsers() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminStatisticsUsers(), config);
  }

  getAdminStatisticsStudents() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminStatisticsStudents(), config);
  }

  getAdminStatisticsCohorts() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminStatisticsCohorts(), config);
  }

  getAdminFaqTopics() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminFaqTopics(), config);
  }

  getAdminFaqQuestions() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminFaqQuestions(), config);
  }

  createAdminFaqQuestion(faqTopicId, question, answer) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      faq_topic_id: faqTopicId,
      question,
      answer
    };

    return this._axios.post(ENDPOINTS.adminFaqQuestions(), payload, config);
  }

  updateAdminFaqQuestion(questionId, { faqTopicId, question, answer }) {
    let payload = {
      faq_topic_id: faqTopicId,
      question,
      answer
    };

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(
      ENDPOINTS.adminFaqQuestion(questionId),
      payload,
      config
    );
  }

  getAdminBlogDrafts() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminBlogDrafts(), config);
  }

  getAdminBlogPublished() {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminBlogPublished(), config);
  }

  getAdminBlogDetail(postId) {
    // Admin

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminBlogDetail(postId), config);
  }

  updateAdminBlogDetail(postId, { title, excerpt, content, feedback_enabled }) {
    // Admin
    let payload = {
      title,
      excerpt,
      content,
      feedback_enabled
    };

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(
      ENDPOINTS.adminBlogDetail(postId),
      payload,
      config
    );
  }

  publishAdminBlog(postId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.adminBlogDetailAction(postId),
      null,
      config
    );
  }

  getAdminModuleDetail(moduleId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminModuleDetail(moduleId), config);
  }

  setAdminModuleDetailSetting(moduleId, optionName) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(
      ENDPOINTS.adminModuleDetailSetting(moduleId, optionName),
      null,
      config
    );
  }

  getAdminAssignmentNoteDetail(assignmentNoteId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.adminAssignmentNoteDetail(assignmentNoteId),
      config
    );
  }

  getAdminResourcesPendingDetail(resourceId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.adminResourcesPendingDetail(resourceId),
      config
    );
  }

  uploadAdminAvatar(userId, file, onUploadProgress) {
    // Admin
    const config = {
      auth: getAuthConfig(),
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    };

    const payload = new FormData();
    payload.append('avatar', file, file.name);

    return this._axios.post(ENDPOINTS.adminEditAvatar(userId), payload, config);
  }

  deleteAdminAvatar(userId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.adminEditAvatar(userId), config);
  }

  unpublishAdminBlog(postId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.adminBlogDetailAction(postId), config);
  }

  deleteAdminBlog(postId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.adminBlogDetail(postId), config);
  }

  createAdminBlog(title, excerpt, content, tag_id, feedback_enabled) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      title,
      excerpt,
      content,
      tag_id,
      feedback_enabled
    };

    return this._axios.post(ENDPOINTS.adminBlogPost(), payload, config);
  }

  getAdminModules() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminModules(), config);
  }

  createAdminModule(
    name,
    externalId,
    startDate,
    endDate,
    cohortId,
    disableGroups
  ) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      name,
      external_id: externalId,
      start_date: startDate,
      end_date: endDate,
      cohort_id: cohortId,
      disable_groups: disableGroups
    };

    return this._axios.post(ENDPOINTS.adminModules(), payload, config);
  }

  getAdminCategories() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminCategories(), config);
  }

  getAdminSpecs() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.adminSpecs(), config);
  }

  assignCohortsAdminBlogDetail(postId, cohortId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.adminBlogDetailCohorts(postId, cohortId),
      null,
      config
    );
  }

  deleteCohortsAdminBlogDetail(postId, cohortId) {
    // Admin
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(
      ENDPOINTS.adminBlogDetailCohorts(postId, cohortId),
      config
    );
  }

  /**
   * @param {String} topicSlug
   * @param {String} after ISO8601 date, retrieve only posts after this date
   * @param {String} limit How many posts to retrieve
   */
  getDiscussionTopicPosts(topicSlug, after, limit) {
    let params = {};

    if (after) {
      params.after = after;
    }

    if (limit) {
      params.limit = limit;
    }

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.discussionTopicPosts(topicSlug), config);
  }

  getDiscussionRecentPosts(limit) {
    let params = {};

    if (limit) {
      params.limit = limit;
    }

    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.discussionRecentPosts(), config);
  }

  sendDiscussionTopicPost(topicSlug, postContent) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      content: postContent
    };

    return this._axios.post(
      ENDPOINTS.discussionTopicPosts(topicSlug),
      payload,
      config
    );
  }

  getResource(resourceSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resource(resourceSlug), config);
  }

  getLearningUnits(moduleVersionId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.learningUnits(moduleVersionId), config);
  }

  getLearningUnit(learningUnitId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.learningUnit(learningUnitId), config);
  }

  getLearningObjectives() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.learningObjectives(), config);
  }

  getLearningCategories() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.learningCategories(), config);
  }

  resetPassword(token, newPassword) {
    const payload = {
      token,
      password: newPassword
    };

    return this._axios.put(ENDPOINTS.resetPassword(), payload);
  }

  setResourceFeedback(resourceSlug, is_upvote, note) {
    const payload = {
      is_upvote,
      note
    };

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.put(
      ENDPOINTS.resourceFeedback(resourceSlug),
      payload,
      config
    );
  }

  removeResourceFeedback(resourceSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.resourceFeedback(resourceSlug), config);
  }

  getResourcesLogicalTypes() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resourcesLogicalTypes(), config);
  }

  createFileResource(file, type, title, ownerIds) {
    const config = {
      auth: getAuthConfig(),
      headers: { 'Content-Type': 'multipart/form-data' }
    };

    const payload = new FormData();
    payload.append('file', file, file.name);
    payload.append(
      'resource',
      JSON.stringify({ type, title, owner_ids: ownerIds })
    );

    return this._axios.post(ENDPOINTS.resources(), payload, config);
  }

  getResources(searchFilters) {
    const config = {
      auth: getAuthConfig(),
      params: extractParams(searchFilters),
      paramsSerializer: params => serializeObj(params)
    };

    return this._axios.get(ENDPOINTS.resources(), config);
  }

  getResourcesCount(searchFilters) {
    const config = {
      auth: getAuthConfig(),
      params: extractParams(searchFilters),
      paramsSerializer: params => serializeObj(params)
    };

    return this._axios.get(ENDPOINTS.resourcesCount(), config);
  }

  getResourceGroups() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resourceGroups(), config);
  }

  getResourceGroup(resourceGroupCode) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resourceGroup(resourceGroupCode), config);
  }

  getResourceBookmarks() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resourceBookmarks(), config);
  }

  getResourceProviders() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.resourceProviders(), config);
  }

  getTeamRoom(newrowContextId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.teamRoom(newrowContextId), config);
  }

  getIntegrationTokens(integrationName) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.integrationTokens(integrationName),
      config
    );
  }

  getCollaborationServicesToken() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.collaborationServicesToken(), config);
  }

  addResourceBookmark(resourceSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.resourceBookmark(resourceSlug),
      null,
      config
    );
  }

  removeResourceBookmark(resourceSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.resourceBookmark(resourceSlug), config);
  }

  getAssessments(params = { kind: 'session' }) {
    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.assessments(), config);
  }

  getAssessment(assessmentSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.assessment(assessmentSlug), config);
  }

  quizzesCurrent() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.quizzesCurrent(), config);
  }

  quizStart(quizId) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {};

    return this._axios.post(ENDPOINTS.quizStart(quizId), payload, config);
  }

  quizAttempts(quizId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.quizAttempts(quizId), config);
  }

  quizAttempt(quizId, attemptId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.quizAttempt(quizId, attemptId), config);
  }

  quizAttemptRemove(quizId, attemptId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.quizAttempt(quizId, attemptId), config);
  }

  quizAttemptAnswer(quizId, quizAttemptId, answerContent) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = answerContent;

    return this._axios.post(
      ENDPOINTS.quizAttemptAnswer(quizId, quizAttemptId, answerContent),
      payload,
      config
    );
  }

  quizAttemptEnd(quizId, quizAttemptId) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {};

    return this._axios.post(
      ENDPOINTS.quizAttemptEnd(quizId, quizAttemptId),
      payload,
      config
    );
  }

  startAssessment(assessmentSlug) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.assessmentStart(assessmentSlug), config);
  }

  startAssessmentFromAssignment(scheduledAssignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(
      ENDPOINTS.assessmentStartFromAssignment(scheduledAssignmentId),
      config
    );
  }

  getSubmissionFeedback(submissionId) {
    const config = {
      auth: getAuthConfig(),
      params: {
        display_correct_answers: true
      }
    };

    return this._axios.get(ENDPOINTS.submission(submissionId), config);
  }

  saveGrading(assignmentId, scheduledAssignmentId, gradingPayload) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = gradingPayload;

    return this._axios.post(
      ENDPOINTS.grading(assignmentId, scheduledAssignmentId),
      payload,
      config
    );
  }

  removeGrading(assignmentId, scheduledAssignmentId, gradeId, userGroupId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(
      ENDPOINTS.groupGrade(
        assignmentId,
        scheduledAssignmentId,
        gradeId,
        userGroupId
      ),
      config
    );
  }

  publishGrading(assignmentId, scheduledAssignmentId) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.post(
      ENDPOINTS.publishing(assignmentId, scheduledAssignmentId),
      null,
      config
    );
  }

  submitGroupwork(assignmentId, scheduledAssignmentId, submittingPayload) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = submittingPayload;

    return this._axios.post(
      ENDPOINTS.submitting(assignmentId, scheduledAssignmentId),
      payload,
      config
    );
  }

  getUserSettings(params = {}) {
    const config = {
      auth: getAuthConfig(),
      params
    };

    return this._axios.get(ENDPOINTS.userSettings(), config);
  }

  getUserSetting(apiCode) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.userSetting(apiCode), config);
  }

  putUserSetting(apiCode, payload) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.put(ENDPOINTS.userSetting(apiCode), payload, config);
  }

  deleteUserSetting(apiCode) {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.delete(ENDPOINTS.userSetting(apiCode), config);
  }

  getUserKVStore() {
    const config = {
      auth: getAuthConfig()
    };

    return this._axios.get(ENDPOINTS.userKVStore(), config);
  }

  patchUserKVStore(data) {
    // remove all `null` values so we won't patch
    // some profile data to `null`s
    // data = removeNullItemsFromObj(data);

    const config = {
      auth: getAuthConfig()
    };

    return this._axios.patch(ENDPOINTS.userKVStore(), data, config);
  }

  reportRoute(route) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = {
      route
    };

    removeNullItemsFromObj(payload);

    return this._axios.post(ENDPOINTS.reportRoute(), payload, config);
  }

  reportProblem(debugInfo) {
    const config = {
      auth: getAuthConfig()
    };

    const payload = debugInfo;

    removeNullItemsFromObj(payload);

    return this._axios.post(ENDPOINTS.reportProblem(), payload, config);
  }
}
