<template>
  <div>
    <NotFound v-if="display404">
      <p>This profile doesn't exist.</p>
      <p>
        Go to
        <router-link
          :to="{
            name: 'dashboard'
          }"
        >
          home page.
        </router-link>
      </p>
    </NotFound>
    <Layout variant="4">
      <template #pageHero>
        <UserProfileImage
          :user="userProfile(userId)"
          :teamAvatarUrl="teamAvatarUrl(userTeamSlug(userProfile(userId)))"
        />
      </template>
      <template #pageContent>
        <UserProfileInfo
          :user="userProfile(userId)"
          :displayMessageIcon="displayMessageIcon"
          :displaySettingsLink="isOwner"
          :countriesList="domainCountries"
          @open_conversation="onOpenConversation"
        />
      </template>
      <template #pageSideA>
        <SideWidgetUserProfileTeam :user="userProfile(userId)" />
        <SideWidgetUserProfileCalendar
          :user="userProfile(userId)"
          style="margin-top: 14px;"
          v-if="isCurrentUserStudent && userId === currentUserId"
        />
      </template>
      <template #pageSideB>
        <SideWidgetUserProfileSocial :user="userProfile(userId)" />
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { addObjToArray } from '@/helpers/common';
import { hasErrorStatus } from '@/helpers/errors';
import { PLACEHOLDER_ID, CONVERSATION_TYPES } from '@/config/conversations';

import {
  GET_USER_PROFILE,
  GET_CONVERSATIONS,
  OPEN_PLACEHOLDER_CONVERSATION,
  GET_TEAM_PROFILE,
  OPEN_CONVERSATION,
  GET_USER_SETTINGS
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import UserProfileImage from '@/components/user-profile/UserProfileImage/UserProfileImage';
import UserProfileInfo from '@/components/user-profile/UserProfileInfo/UserProfileInfo';
import SideWidgetUserProfileSocial from '@/components/user-profile/SideWidgetUserProfileSocial/SideWidgetUserProfileSocial';
import SideWidgetUserProfileTeam from '@/components/user-profile/SideWidgetUserProfileTeam/SideWidgetUserProfileTeam';
import SideWidgetUserProfileCalendar from '@/components/user-profile/SideWidgetUserProfileCalendar/SideWidgetUserProfileCalendar';

export default {
  components: {
    NotFound,
    Layout,
    UserProfileImage,
    UserProfileInfo,
    SideWidgetUserProfileSocial,
    SideWidgetUserProfileTeam,
    SideWidgetUserProfileCalendar
  },
  data() {
    return {
      display404: false,
      since: null
    };
  },
  computed: {
    ...mapGetters('chats', ['conversationsWithParticipants']),
    ...mapGetters([
      'userProfile',
      'domainCountries',
      'currentUserId',
      'currentUserProfile',
      'teamAvatarUrl',
      'userTeamSlug',
      'isCurrentUserTeachingStaff',
      'isCurrentUserStudent'
    ]),
    userId() {
      return parseInt(this.$route.params.userId);
    },
    displayMessageIcon() {
      if (this.isCurrentUserTeachingStaff && !this.isOwner) {
        return true;
      }

      // TODO: start conversation only with teammates
      return !this.isOwner;
    },
    isOwner() {
      return this.currentUserId === this.userId;
    }
  },
  watch: {
    $route() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.since = moment().toISOString(true);

    this.$store.dispatch(GET_USER_SETTINGS).catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  },
  methods: {
    ...mapActions('chats', [
      GET_CONVERSATIONS,
      OPEN_CONVERSATION,
      OPEN_PLACEHOLDER_CONVERSATION
    ]),
    async fetchData() {
      try {
        await this.$store.dispatch(GET_USER_PROFILE, this.userId);
      } catch (error) {
        if (hasErrorStatus(404, error)) {
          this.display404 = true;
          return;
        } else {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          return;
        }
      }

      const userTeamSlug = this.userTeamSlug(this.userProfile(this.userId));
      if (userTeamSlug) {
        this.$store.dispatch(GET_TEAM_PROFILE, userTeamSlug).catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
      }

      this.GET_CONVERSATIONS().catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    },
    onOpenConversation(participantProfile) {
      const found = this.conversationsWithParticipants([
        this.currentUserId,
        participantProfile.user_id
      ]);

      if (found.length === 0) {
        this.OPEN_PLACEHOLDER_CONVERSATION({
          type: CONVERSATION_TYPES.ADHOC,
          participant_ids: [this.currentUserId, participantProfile.user_id],
          participants: [this.currentUserProfile, participantProfile]
        })
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId: PLACEHOLDER_ID
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      } else {
        const conversationId = found[0].conversation_id;

        if (!conversationId) return;

        this.OPEN_CONVERSATION(conversationId)
          .then(() => {
            this.$router.push({
              name: 'conversation-existing',
              params: {
                conversationId
              }
            });
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>
