<template>
  <SideWidget :class="$style.wrapper" :title="false" v-if="detail">
    <template #content>
      <!-- Top side widget ↓ -->
      <div :class="$style.section" class="box-shadow-white">
        <p class="bold" :class="$style.sectionTitle">Resource title:</p>
        <FormInput v-if="editingMode" v-model="title" />
        <p v-else>{{ detail.title }}</p>
        <p class="bold" :class="$style.sectionTitle">Resource type:</p>
        <p>{{ detail.type }}</p>
        <p class="bold" :class="$style.sectionTitle">Source:</p>
        <p>{{ resourceType }}</p>
        <p class="bold" :class="$style.sectionTitle">Author:</p>
        <p v-if="detail.user && detail.user.name">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: detail.user.user_id }
            }"
            >{{ detail.user.name.first_name }}
            {{ detail.user.name.last_name }}</router-link
          >
        </p>
        <p class="bold" :class="$style.sectionTitle">Unit:</p>
        <router-link
          v-if="detail.learning_unit && detail.learning_unit.name"
          :to="{
            name: 'learning-unit-detail',
            params: {
              learningUnitId: detail.learning_unit.unit_id
            }
          }"
          >{{ detail.learning_unit.name }}</router-link
        >

        <p v-else class="placeholder">No unit</p>
      </div>

      <!-- Bottom side widget ↓ -->
      <div :class="$style.section" class="box-shadow-white" v-if="detail">
        <p class="bold" :class="$style.objectivesTitle">Learning objectives</p>

        <!-- EDIT LO ↓ -->
        <div v-if="editingMode" :class="$style.editSection">
          <div v-if="filteredObjectives">
            <div v-for="(objective, i) in filteredObjectives" :key="i">
              <FormLabel :class="$style.objective"
                ><FormCheckbox
                  v-model="selectedObjectives"
                  :value="objective.learning_objective_id"
                /><span>{{ i + 1 }}. {{ objective.name }}</span></FormLabel
              >
            </div>
            <p
              v-if="
                detail.learning_objectives &&
                  detail.learning_objectives.length > 5
              "
              class="button-text"
              @click="showMoreObjectives = !showMoreObjectives"
            >
              Show {{ showMoreObjectives ? 'less' : 'more' }}
            </p>
          </div>
          <div v-else class="placeholder">
            No learning objectives to select from
          </div>
        </div>

        <!-- PREVIEW LO-->
        <div v-else>
          <div v-if="detail.selected_learning_objectives">
            <div
              :class="$style.objective"
              v-for="(objective, i) in filteredSelectedObjectives"
              :key="i"
            >
              <span>{{ objective.number }}. {{ objective.name }}</span>
            </div>
          </div>
          <div v-else :class="$style.objective" class="placeholder">
            No learning objective selected
          </div>

          <p
            v-if="
              detail.selected_learning_objectives &&
                detail.selected_learning_objectives.length > 5
            "
            class="button-text"
            @click="showMoreObjectives = !showMoreObjectives"
          >
            Show {{ showMoreObjectives ? 'less' : 'more' }}
          </p>
        </div>

        <!-- Comment -->
        <p class="bold">Comment:</p>
        <FormTextarea
          v-if="editingMode"
          v-model="comment"
          placeholder="comment"
        ></FormTextarea>
        <div v-else>
          <p v-if="detail.comment">{{ detail.comment }}</p>
          <p v-else class="placeholder">None</p>
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import moment from 'moment';

import SideWidget from '@/components/common/SideWidget/SideWidget';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMoreObjectives: false,
      editingMode: false,
      team: '',
      copyright: '',
      selectedObjectives: [],
      title: '',
      comment: ''
    };
  },
  async created() {
    this.enumerateById;
  },
  watch: {
    title(newVal) {
      if (this.editingMode) {
        this.$emit('titleChange', newVal);
      }
    },
    comment(newVal) {
      if (this.editingMode) {
        this.$emit('commentChange', newVal);
      }
    },
    selectedObjectives(newVal) {
      if (this.editingMode) {
        this.$emit('selectedObjectivesChange', newVal);
      }
    },
    editing(newVal) {
      this.editingMode = newVal;

      if (newVal && this.detail) {
        this.title = this.detail.title;
        this.comment = this.detail.comment;
      }

      if (newVal && this.detail && this.detail.selected_learning_objectives) {
        this.selectedObjectives = this.detail.selected_learning_objectives.map(
          objective => objective.learning_objective_id
        );
      } else {
        this.selectedObjectives = [];
      }
    }
  },
  computed: {
    // add number to each LO, so after we select LOs in editing mode and save it,
    // they stay the same ↓
    enumerateById() {
      if (this.detail && this.detail.learning_objectives) {
        return this.detail.learning_objectives.map((objective, idx) => {
          return { ...objective, number: idx + 1 };
        });
      }

      return null;
    },
    // Available LOs in EDIT mode ↓
    filteredObjectives() {
      if (!this.detail.learning_objectives) return null;

      return this.showMoreObjectives
        ? this.detail.learning_objectives
        : this.detail.learning_objectives.slice(0, 5);
    },
    // Already selected LOs in preview mode ↓
    // assign number to each selected LO - comparing its id and id of LOs in enumerateById.
    filteredSelectedObjectives() {
      if (!this.detail.selected_learning_objectives) return null;

      var enumeratedById = [];
      this.enumerateById.map(objective => {
        this.detail.selected_learning_objectives.find(ob => {
          if (ob.learning_objective_id == objective.learning_objective_id) {
            enumeratedById.push({ ...ob, number: objective.number });
          }
        });
      });

      return this.showMoreObjectives
        ? enumeratedById
        : enumeratedById.slice(0, 5);
    },
    resourceType() {
      return this.detail.assignment_note_id ? 'Session' : 'Unit Page';
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminRecommendedResources.scss';
</style>
