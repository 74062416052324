import ApiService from '@/api/api.service';

import {
  GET_ADMIN_STUDENTS,
  GET_ADMIN_STUDENT_DETAIL,
  GET_ADMIN_COHORTS,
  GET_ADMIN_USERS,
  GET_ADMIN_USER_DETAIL,
  GET_ADMIN_USER_EMAILS,
  GET_ADMIN_COHORT_DETAIL,
  GET_ADMIN_FEEDBACK_STATUS,
  GET_ADMIN_OPERATION_STATUS,
  REOPEN_ADMIN_OPERATION,
  GET_PENDING_OPERATIONS,
  APPROVE_OPERATION,
  REJECT_OPERATION,
  GET_APPROVED_OPERATIONS,
  GET_REJECTED_OPERATIONS,
  GET_ADMIN_GROUP_DETAIL,
  ADMIN_GROUP_DETAIL_REMOVE_GROUP,
  ADMIN_GROUP_DETAIL_REMOVE_USER,
  GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS,
  ADD_USER_TO_GROUP,
  GET_ADMINISTRATIVE_USER_GROUPS,
  CREATE_NEW_USER,
  CREATE_NEW_COHORT,
  GET_ADMIN_USER_ROLES,
  GET_ADMIN_STATISTICS_USERS,
  GET_ADMIN_STATISTICS_STUDENTS,
  GET_ADMIN_STATISTICS_COHORTS,
  ADMIN_GROUP_DETAIL_CREATE_GROUP,
  ADMIN_RESET_PASSWORD,
  UPDATE_ADMIN_USER_DETAIL,
  ADMIN_FAQ_TOPICS,
  ADMIN_FAQ_QUESTIONS,
  ADMIN_CREATE_FAQ_QUESTIONS,
  ADMIN_UPDATE_FAQ_QUESTION,
  GET_ADMIN_BLOG_DRAFTS,
  GET_ADMIN_BLOG_PUBLISHED,
  GET_ADMIN_BLOG_DETAIL,
  UPDATE_ADMIN_BLOG_DETAIL,
  CREATE_ADMIN_BLOG,
  PUBLISH_ADMIN_BLOG,
  UNPUBLISH_ADMIN_BLOG,
  DELETE_ADMIN_BLOG,
  ASSIGN_COHORTS_ADMIN_BLOG_DETAIL,
  DELETE_COHORTS_ADMIN_BLOG_DETAIL,
  UPDATE_TAG_ADMIN_BLOG_DETAIL,
  GET_ADMIN_MODULES,
  CREATE_ADMIN_MODULE,
  GET_ADMIN_CATEGORIES,
  GET_ADMIN_MODULE_DETAIL,
  GET_ADMIN_SPECS,
  GET_ADMIN_OVERVIEW,
  UPDATE_ADMIN_USER_ROLE,
  UPLOAD_ADMIN_AVATAR,
  DELETE_ADMIN_AVATAR,
  GET_ADMIN_ASSIGNMENT_NOTES,
  GET_ADMIN_ASSIGNMENT_NOTE_DETAIL,
  GET_ADMIN_RESOURCES_PENDING,
  GET_ADMIN_RESOURCES_APPROVED,
  GET_ADMIN_RESOURCES_REJECTED,
  GET_ADMIN_RECOURCE_PENDING_DETAIL,
  SET_ADMIN_RECOURCE_PENDING_DETAIL,
  UPDATE_ADMIN_MODULE_DETAIL_SETTINGS,
  GET_ADMIN_POSTS
} from './actions.type';

import { SET_ADMIN_USERS_SEARCH_RESULTS } from '@/store/mutations.type';

export const state = {
  users_search_results: []
};

export const getters = {
  usersSearchResults: state => {
    return state.users_search_results;
  }
};

export const actions = {
  [GET_ADMIN_STUDENTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminStudents()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_STUDENT_DETAIL](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminStudentDetail(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_COHORTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminCohorts()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_USERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminUsers()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_USER_DETAIL](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminUserDetail(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_USER_EMAILS](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminUserEmails(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_COHORT_DETAIL](context, cohortId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminCohortDetail(cohortId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_FEEDBACK_STATUS](context, submissionId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminFeedbackStatus(submissionId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_OPERATION_STATUS](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminOperationStatus(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [REOPEN_ADMIN_OPERATION](context, { func, objectType, objectId, reference }) {
    return new Promise((resolve, reject) => {
      ApiService.adminOperations(func, objectType, objectId, reference)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_PENDING_OPERATIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getPendingOperations()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [APPROVE_OPERATION](context, operationId) {
    return new Promise((resolve, reject) => {
      ApiService.adminApproveOperation(operationId)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [REJECT_OPERATION](context, operationId) {
    return new Promise((resolve, reject) => {
      ApiService.adminRejectOperation(operationId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_RESET_PASSWORD](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.adminResetPassword(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_APPROVED_OPERATIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminApprovedOperations()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_REJECTED_OPERATIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminRejectedOperations()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_GROUP_DETAIL](context, userGroupId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminGroupDetail(userGroupId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_GROUP_DETAIL_REMOVE_GROUP](context, userGroupId) {
    return new Promise((resolve, reject) => {
      ApiService.adminGroupDetailRemoveGroup(userGroupId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_GROUP_DETAIL_REMOVE_USER](context, { userGroupId, userId }) {
    return new Promise((resolve, reject) => {
      ApiService.adminGroupDetailRemoveUser(userGroupId, userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_GROUP_DETAIL_ELIGIBLE_USERS](context, userGroupId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminGroupDetailEligibleUsers(userGroupId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADD_USER_TO_GROUP](context, { userGroupId, user_id }) {
    return new Promise((resolve, reject) => {
      ApiService.addUserToGroup(userGroupId, user_id)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_OVERVIEW](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminOverview()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMINISTRATIVE_USER_GROUPS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdministrativeUserGroups()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_USER_ROLES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminUserRoles()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_STATISTICS_USERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminStatisticsUsers()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_STATISTICS_STUDENTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminStatisticsStudents()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_STATISTICS_COHORTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminStatisticsCohorts()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CREATE_NEW_USER](context, newUserProperties) {
    return new Promise((resolve, reject) => {
      ApiService.createNewUser(newUserProperties)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CREATE_NEW_COHORT](context, newCohortProperties) {
    return new Promise((resolve, reject) => {
      ApiService.createNewCohort(newCohortProperties)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_GROUP_DETAIL_CREATE_GROUP](
    context,
    { cohortId, groupType, groupName }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.createAdminCohortDetailGroup(cohortId, groupType, groupName)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_ADMIN_USER_DETAIL](context, { userId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.updateAdminUserDetail(userId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_ADMIN_MODULE_DETAIL_SETTINGS](context, { moduleId, optionName }) {
    return new Promise((resolve, reject) => {
      ApiService.setAdminModuleDetailSetting(moduleId, optionName)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_FAQ_TOPICS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminFaqTopics()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_FAQ_QUESTIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminFaqQuestions()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_CREATE_FAQ_QUESTIONS](context, { faqTopicId, question, answer }) {
    return new Promise((resolve, reject) => {
      ApiService.createAdminFaqQuestion(faqTopicId, question, answer)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ADMIN_UPDATE_FAQ_QUESTION](context, { questionId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.updateAdminFaqQuestion(questionId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_BLOG_DRAFTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminBlogDrafts()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_BLOG_PUBLISHED](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminBlogPublished()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_BLOG_DETAIL](context, postId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminBlogDetail(postId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_ADMIN_BLOG_DETAIL](context, { postId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.updateAdminBlogDetail(postId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_ADMIN_USER_ROLE](context, { userId, roleCode }) {
    return new Promise((resolve, reject) => {
      ApiService.setAdminUserRole(userId, roleCode)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CREATE_ADMIN_BLOG](
    context,
    { title, excerpt, content, tag_id, feedback_enabled }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.createAdminBlog(
        title,
        excerpt,
        content,
        tag_id,
        feedback_enabled
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CREATE_ADMIN_MODULE](
    context,
    { name, externalId, startDate, endDate, cohortId, disableGroups }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.createAdminModule(
        name,
        externalId,
        startDate,
        endDate,
        cohortId,
        disableGroups
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [PUBLISH_ADMIN_BLOG](context, postId) {
    return new Promise((resolve, reject) => {
      ApiService.publishAdminBlog(postId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UNPUBLISH_ADMIN_BLOG](context, postId) {
    return new Promise((resolve, reject) => {
      ApiService.unpublishAdminBlog(postId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_ADMIN_BLOG](context, postId) {
    return new Promise((resolve, reject) => {
      ApiService.deleteAdminBlog(postId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ASSIGN_COHORTS_ADMIN_BLOG_DETAIL](context, { postId, cohortId }) {
    return new Promise((resolve, reject) => {
      ApiService.assignCohortsAdminBlogDetail(postId, cohortId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_TAG_ADMIN_BLOG_DETAIL](context, { postId, tagId }) {
    return new Promise((resolve, reject) => {
      ApiService.updateBlogPostTag(postId, tagId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_COHORTS_ADMIN_BLOG_DETAIL](context, { postId, cohortId }) {
    return new Promise((resolve, reject) => {
      ApiService.deleteCohortsAdminBlogDetail(postId, cohortId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_MODULES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminModules()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_CATEGORIES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminCategories()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_SPECS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminSpecs()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_MODULE_DETAIL](context, moduleId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminModuleDetail(moduleId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPLOAD_ADMIN_AVATAR](context, { userId, file }) {
    const onUploadProgress = progressEvent => {
      let percent = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      context.commit(SET_AVATAR_UPLOAD_PERCENT, percent);
    };

    return new Promise((resolve, reject) => {
      ApiService.uploadAdminAvatar(userId, file, onUploadProgress)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_ADMIN_AVATAR](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.deleteAdminAvatar(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_ASSIGNMENT_NOTES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminAssignmentNotes()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_ASSIGNMENT_NOTE_DETAIL](context, assignmentNoteId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminAssignmentNoteDetail(assignmentNoteId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_RESOURCES_PENDING](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminResourcesPending()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_RESOURCES_APPROVED](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminResourcesApproved()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_RESOURCES_REJECTED](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminResourcesRejected()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_RECOURCE_PENDING_DETAIL](context, resourceId) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminResourcesPendingDetail(resourceId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ADMIN_POSTS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminPosts()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SET_ADMIN_RECOURCE_PENDING_DETAIL](
    context,
    { resourceId, status, title, comment, selectedLearningObjectives }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.postAdminResourcesPendingDetail(
        resourceId,
        status,
        title,
        comment,
        selectedLearningObjectives
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [SET_ADMIN_USERS_SEARCH_RESULTS](state, { eligibleUsers, searchTerm }) {
    let filteredEligibleUsers = [];

    if (Array.isArray(eligibleUsers) && searchTerm) {
      eligibleUsers.filter(user => {
        const searching = searchTerm.trim().toLowerCase();
        const name =
          user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase();

        // Possible to search by name OR by ID
        if (
          name.includes(searching) ||
          String(user.user_id).includes(searching)
        ) {
          filteredEligibleUsers.push(user);
        }
      });
    }

    state.users_search_results = filteredEligibleUsers;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
