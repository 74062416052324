<template>
  <TickTack
    :tickEverySeconds="1"
    :emitAfterTicks="1"
    emit="update"
    @update="updateNow"
  >
    <Layout variant="5">
      <template slot="pageContent">
        <template v-if="isLoading">
          <InlineLoader />
        </template>
        <template v-else>
          <Type1
            v-if="leadSessionGradingComponent === GRADING_TYPES.TYPE1"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />

          <Type2
            v-else-if="leadSessionGradingComponent === GRADING_TYPES.TYPE2"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />

          <Type3
            v-else-if="leadSessionGradingComponent === GRADING_TYPES.TYPE3"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />

          <Type4
            v-else-if="leadSessionGradingComponent === GRADING_TYPES.TYPE4"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />
          <Type5
            v-else-if="leadSessionGradingComponent === GRADING_TYPES.TYPE5"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />

          <TypeNull
            v-else-if="leadSessionGradingComponent === GRADING_TYPES.TYPENULL"
            :focusAtId="scheduledAssignmentId"
            :nowISO="nowISO"
          />

          <div v-else>
            Unknown assessment criteria
          </div>
        </template>
      </template>
    </Layout>
  </TickTack>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import Layout from '@/views/Layout/Layout';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import TickTack from '@/components/common/TickTack/TickTack';

import TypeNull from './TypeNull/TypeNull';
import Type1 from './Type1/Type1';
import Type2 from './Type2/Type2';
import Type3 from './Type3/Type3';
import Type4 from './Type4/Type4';
import Type5 from './Type5/Type5';

import { GRADING_TYPES } from '@/config/grading';

import { GET_ASSIGNMENT } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  name: 'AssignmentDetail',
  components: {
    TickTack,
    Layout,
    InlineLoader,
    TypeNull,
    Type1,
    Type2,
    Type3,
    Type4,
    Type5
  },
  data() {
    return {
      nowISO: moment().toISOString(true),
      isLoading: true
    };
  },
  watch: {
    assignmentId(assignmentId) {
      this.getAssignment(assignmentId);
    }
  },
  computed: {
    ...mapGetters('grading', ['assignment', 'leadSessionGradingComponent']),
    assignmentId() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.assignmentId
      ) {
        return parseInt(this.$route.params.assignmentId);
      }

      return undefined;
    },
    scheduledAssignmentId() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.scheduledAssignmentId
      ) {
        return parseInt(this.$route.params.scheduledAssignmentId);
      }

      return undefined;
    }
  },
  methods: {
    ...mapActions('grading', [GET_ASSIGNMENT]),
    updateNow(dateISO) {
      this.nowISO = moment(dateISO).toISOString(true);
    },
    async getAssignment(assignmentId) {
      if (!assignmentId) return;

      try {
        this.isLoading = true;
        await this.GET_ASSIGNMENT(assignmentId);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        this.isLoading = false;
      }
    }
  },
  created() {
    this.GRADING_TYPES = GRADING_TYPES;
    this.getAssignment(this.assignmentId);
  }
};
</script>
