<template>
  <div :class="$style.wrapper" class="box-shadow-white">
    <div :class="$style.header" class="theme-bg">
      <h1 :class="$style.categoryName">
        {{ category.name }}
      </h1>
      <div :class="$style.statisticTitles">
        <p class="bold"><i class="icon icon-comments-o" />Posts</p>
        <p class="bold"><i class="icon icon-list-ul" />Discussions</p>
      </div>
    </div>

    <div :class="$style.forums">
      <div v-for="forum in forums" :key="forum.slug" :class="$style.forum">
        <div :class="$style.forumName">
          <h5>
            <router-link
              :to="{ name: 'forum-topics', params: { forumSlug: forum.slug } }"
            >
              {{ forum.name }}
            </router-link>
          </h5>
          <p>{{ forum.description }}</p>
        </div>
        <div :class="$style.statistic">
          <strong>{{ forum.post_count }}</strong>
          <strong>{{ forum.topic_count }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['category', 'forums']
};
</script>

<style lang="scss" module>
@import './DiscussionCategory.scss';
</style>
