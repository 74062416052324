var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: true,
      initialSortBy: { field: 'createdAt', type: 'desc' }
    },"pagination-options":{
      enabled: true,
      infoFn: function () {}
    },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'operationId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.operationId))])]):(props.column.field === 'reference')?_c('span',[_c('a',{attrs:{"href":props.row.reference.startsWith('#')
              ? props.row.reference
              : ("#" + (props.row.reference)),"target":"_blank"}},[_vm._v("Visit Page")])]):(props.column.field === 'requestedBy')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'user-profile',
            params: { userId: props.row.userId }
          }}},[_vm._v(" "+_vm._s(props.row.requestedBy)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.userId))])])],1):(props.column.field === 'action')?_c('span',[_c('div',{class:_vm.$style.operations},[_c('FormButton',{class:_vm.$style.approve,attrs:{"ghost":true},on:{"click":function($event){return _vm.handleApproveOpened(props.row.operationId)}}},[_vm._v("Approve")]),_c('FormButton',{class:_vm.$style.deny,attrs:{"ghost":true},on:{"click":function($event){return _vm.handleDenyOpened(props.row.operationId)}}},[_vm._v("Deny")])],1)]):_vm._e()]}}])}),_c('ModalNew',{directives:[{name:"transfer-dom",rawName:"v-transfer-dom"}],class:_vm.$style.modalOperationConfirmation,attrs:{"showModal":_vm.showApproveModal},on:{"close":_vm.handleApproveClosed}},[_vm._v(" Are you sure you want to approve this operation? "),_c('div',{class:_vm.$style.buttonsOperationConfirmation},[_c('FormButton',{attrs:{"ghost":true},on:{"click":function($event){return _vm.approveOperation(_vm.approveID)}}},[_vm._v("Yes")]),_c('FormButton',{attrs:{"ghost":true},on:{"click":_vm.handleApproveClosed}},[_vm._v("No")])],1)]),_c('ModalNew',{directives:[{name:"transfer-dom",rawName:"v-transfer-dom"}],class:_vm.$style.modalOperationConfirmation,attrs:{"showModal":_vm.showDenyModal},on:{"close":_vm.handleDenyClosed}},[_c('p',[_vm._v("Are you sure you want to deny this operation?")]),_c('div',{class:_vm.$style.buttonsOperationConfirmation},[_c('FormButton',{attrs:{"ghost":true},on:{"click":function($event){return _vm.denyOperation(_vm.denyID)}}},[_vm._v("Yes")]),_c('FormButton',{attrs:{"ghost":true},on:{"click":_vm.handleDenyClosed}},[_vm._v("No")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }