import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

// Custom plugins
import SimpleUploadAdapter from '@/helpers/CKEditor/simpleuploadadapter';

import { MIME_TYPES_BY_FILE_EXTENSION } from '@/config/files';

function saveData(contentToSave) {
  void contentToSave;

  return Promise.reject(
    'Not implemented! https://ckeditor.com/docs/ckeditor5/latest/api/module_autosave_autosave-AutosaveConfig.html#function-save'
  );
}

export const DEFAULT_CONFIG = {
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    HeadingPlugin,
    BlockQuotePlugin,
    ListPlugin,
    WordCountPlugin,
    Image,
    ImageUpload,
    ImageResize,
    SimpleUploadAdapter
  ],
  toolbar: {
    viewportTopOffset: 80,
    items: [
      'heading',
      '|',
      'Bold',
      'Italic',
      'bulletedList',
      'blockQuote',
      'link',
      'imageUpload',
      'resizeImage',
      '|',
      'Undo',
      'Redo'
    ]
  },
  link: {
    addTargetToExternalLinks: true
  },
  simpleUpload: {
    uploadUrl: {}
  },
  image: {
    types: [MIME_TYPES_BY_FILE_EXTENSION.png, MIME_TYPES_BY_FILE_EXTENSION.jpeg]
  },
  wordCount: {
    displayCharacters: false
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h4',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h6',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      }
    ]
  },
  sidebar: {
    container: null
  },
  presenceList: {
    container: null
  },
  collaboration: {
    channelId: null
  },
  autosave: {
    waitingTime: 5000, // https://ckeditor.com/docs/ckeditor5/latest/api/module_autosave_autosave-AutosaveConfig.html#member-waitingTime
    save(editor) {
      // The saveData() function must return a promise
      // which should be resolved when the data is successfully saved.
      return saveData(editor.getData());
    }
  }
};
