// third-party
import Vue from 'vue';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// stylesheets
import 'normalize.css';

import '@/assets/css/index.scss';
import '@/assets/icons/style.scss';
import 'vue-good-table/dist/vue-good-table.css';

// JS
import common from '@/components/common';
import forms from '@/components/forms';
import CKEditor from '@/plugins/ckeditor';
import EventBus from '@/plugins/eventBus';
import MouseFlow from '@/plugins/mouseFlow';
import VueSvgInlinePlugin from '@/plugins/vue-svg-inline-plugin';

import router from '@/router';
import store from '@/store';
import {
  CHECK_AUTH,
  GET_UNREAD_COUNT,
  GET_DOMAIN_COUNTRIES,
  SUBSCRIBE_NOTIFICATIONS,
  GET_CURRENT_USER_PROFILE,
  GET_USER_SETTINGS,
  GET_USER_SETTING,
  GET_RESOURCE_PROVIDERS
} from '@/store/actions.type';
import {
  SET_UNREAD_COUNT,
  SET_GLOBAL_ERROR,
  PURGE_GLOBAL_ERROR,
  SET_TOKEN_ON_SERVER
} from '@/store/mutations.type';

import { setThemeStyles } from '@/helpers/theme';
import { DEFAULT_THEME } from '@/config/themes';
import { logError } from '@/helpers/errors';
import { AUTH_NONE } from '@/config/common';
import { ROUTE_CHANGED_EVENT } from '@/config/tracking';
import { beforeSendSentry } from '@/sentry.ignore';

import lineClamp from '@/directives/lineClamp';
import transferDOM from '@/directives/transferDOM';

import App from './App';
import 'intersection-observer';

Vue.use(common);
Vue.use(forms);
Vue.use(CKEditor);
Vue.use(EventBus);
Vue.use(MouseFlow);

// Clear clients that already have the key and data stored under it
window.localStorage.removeItem('vue-svg-inline-plugin:2.1.3');
// Prevent caching in localStorage
VueSvgInlinePlugin.install(Vue, {
  cache: {
    persistent: false
  }
});

Vue.directive('line-clamp', lineClamp);
Vue.directive('transfer-dom', transferDOM);

Vue.config.productionTip = false;

moment.locale(window.navigator.userLanguage || window.navigator.language);

const fetchUnreadMessagesCount = () => {
  store.dispatch(`chats/${GET_UNREAD_COUNT}`).catch(error => {
    // in this case, no need to display there is an error to the user
    // since it doesn't cause any problem with data, etc., the only
    // impact is that total unread messages counter won't be displayed
    // => log error only, hopefully we will fix it before anyone notices :)
    logError(error);
  });
};

const isUnauthenticatedRoute = route => {
  return route.meta && route.meta.auth && route.meta.auth === AUTH_NONE;
};

let firstVisit = true;

router.beforeEach(async (to, from, next) => {
  // -----
  // Actions to be triggered as soon as possible

  store.commit(PURGE_GLOBAL_ERROR);

  // -----
  // Try to login user with "remember me"
  // (at the same time make sure we have a current user ID available all the time)

  if (isUnauthenticatedRoute(to)) {
    return next();
  }

  // Proceed immediately in case there's a token and a user ID available
  // If first visit, continue to check token validity. Otherwise end here
  // and perform token validity check in afterEach hook to speed up navigation
  // throughout the platform
  if (
    !firstVisit &&
    store.getters.isAuthenticated &&
    store.getters.currentUserId
  ) {
    return next();
  }

  try {
    await store.dispatch(CHECK_AUTH);
  } catch (error) {
    firstVisit = false;
    return next({
      name: 'login',
      query: { redirect: to.fullPath }
    });
  }

  Sentry.configureScope(scope => {
    scope.setUser({ id: store.getters.currentUserId });
  });

  if (firstVisit && from.name !== 'terms-and-conditions') {
    try {
      await store.dispatch(GET_CURRENT_USER_PROFILE);
      const systemLanguage = store.getters.currentUserProfile
        ? store.getters.currentUserProfile['system_language']
        : undefined;

      if (systemLanguage) {
        moment.locale(systemLanguage);
      } else {
        moment.locale('en');
      }
    } catch (error) {
      store.commit(SET_GLOBAL_ERROR, { error, log: true });

      firstVisit = false;
      return next({
        name: 'login',
        query: { redirect: to.fullPath }
      });
    }

    if (!store.getters.currentUserAcceptedTerms) {
      firstVisit = false;
      return next({ name: 'terms-and-conditions' });
    }
  }

  firstVisit = false;
  return next();
});

router.afterEach(async to => {
  // -----
  // Auth-check
  // Ensure we checked auth when a page requiring auth visited
  // The reason we do it after hook and not before hook is to speed
  // up the navigation through platform. In case there will be a problem
  // with auth before user is logged out, they won't see any
  // new info anyway because all request to the server will fail.
  if (isUnauthenticatedRoute(to)) {
    return;
  }

  try {
    await store.dispatch(CHECK_AUTH);
  } catch (error) {
    router.push({
      name: 'login',
      query: { redirect: to.fullPath }
    });
  }

  // -----
  // Actions that can be finished any time

  if (to.meta && to.meta.tracking === false) {
    // Routes with meta attribute tracking: false won't be reported
  } else {
    router.app.$bus.$emit(ROUTE_CHANGED_EVENT, to.fullPath);
  }

  fetchUnreadMessagesCount();

  // should be sufficient to fetch countries only once on app start
  if (
    !(store.getters.domainCountries && store.getters.domainCountries.length)
  ) {
    store.dispatch(GET_DOMAIN_COUNTRIES).catch(error => {
      store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  }

  MouseFlow.push(['newPageView', to.fullPath]);
});

if (!['development', 'e2e', 'test'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    dsn: 'https://e2bd08cce1314f84b63e65b21cc7b768@sentry.io/1336622',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: process.env.BUILD_HASH,
    environment: process.env.SENTRY_ENV,
    beforeSend: beforeSendSentry
  });
}

new Vue({
  router,
  store,
  data() {
    return {
      pageHasVisibility: undefined
    };
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated;
    },
    isReadyForTracking() {
      return this.isAuthenticated;
    }
  },
  watch: {
    isAuthenticated(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.getUserSettings();
        this.getLibraryProviders();
      }
    },
    isReadyForTracking(newValue, oldValue) {
      if (
        oldValue === false &&
        newValue === true &&
        MouseFlow.isActivated() === false
      ) {
        const tagUserRole =
          store.getters.isCurrentUserStudent === true
            ? 'student'
            : 'teaching_staff';

        MouseFlow.activate({
          tags: [tagUserRole]
        });
      }
    },
    pageHasVisibility(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        if (this.$route && !isUnauthenticatedRoute(this.$route)) {
          fetchUnreadMessagesCount();
        }
      }
    }
  },
  beforeCreate: function() {
    setThemeStyles(process.env.THEME || DEFAULT_THEME);
  },
  created() {
    if (this.isAuthenticated) {
      this.getUserSettings();
      this.getLibraryProviders();
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('visibilitychange', this.checkVisibility);
    });
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.checkVisibility);
  },
  methods: {
    checkVisibility() {
      this.pageHasVisibility =
        document.visibilityState === 'visible' ? true : false;
    },
    getUserSettings() {
      this.$store.dispatch(GET_USER_SETTINGS).catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    },
    getLibraryProviders() {
      this.$store.dispatch(GET_RESOURCE_PROVIDERS).catch(error => {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      });
    }
  },
  render: h => h(App)
}).$mount('#app');
