var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    },"pagination-options":{
      enabled: true,
      infoFn: function () {}
    },"compactMode":""},on:{"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.updateFilters},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'draftTitle')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-blog-detail',
            params: { postId: props.row.draftId }
          }}},[_vm._v(_vm._s(props.row.draftTitle)+" ")])],1):(props.column.field === 'draftId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.draftId))])]):(props.column.field === 'draftAuthor')?_c('span',[(props.row.draftAuthor)?_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.draftAuthorId }
          }}},[_vm._v(_vm._s(props.row.draftAuthor)+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.draftAuthorId))])]):_c('span',[_vm._v("-")])],1):(props.column.field === 'draftCohorts')?_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp:1",value:(1),expression:"1",arg:"1"}]},[(props.row.draftCohorts == 'All')?_c('span',[_vm._v("All")]):_vm._l((props.row.draftCohorts),function(cohort,i){return _c('span',{key:i},[_c('router-link',{attrs:{"to":{
              name: 'admin-cohort-detail',
              params: { cohortId: cohort[1] }
            }}},[_vm._v(_vm._s(cohort[0])+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(cohort[1]))])]),(i != props.row.draftCohorts.length - 1)?_c('span',{staticClass:"bold"},[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }