<template>
  <SideWidget
    :class="$style.wrapper"
    class="box-shadow-white p-2"
    :title="false"
  >
    <template #content>
      <h6>Social</h6>
      <div :class="$style.icons">
        <a
          v-if="hasUrl('homepage_url')"
          :href="user.links.homepage_url"
          target="_blank"
        >
          <img src="~@/assets/images/icons/WEB.svg" />
        </a>
        <span v-else :class="$style.grayIcon">
          <img src="~@/assets/images/icons/WEB.svg" />
        </span>

        <a
          v-if="hasUrl('linkedin_url')"
          :href="user.links.linkedin_url"
          target="_blank"
        >
          <img src="~@/assets/images/icons/LIN.svg" />
        </a>
        <span v-else :class="$style.grayIcon">
          <img src="~@/assets/images/icons/LIN.svg" />
        </span>

        <a
          v-if="hasUrl('twitter_url')"
          :href="user.links.twitter_url"
          target="_blank"
        >
          <img src="~@/assets/images/icons/TW.svg" />
        </a>
        <span v-else :class="$style.grayIcon">
          <img src="~@/assets/images/icons/TW.svg" />
        </span>

        <a
          v-if="hasUrl('facebook_url')"
          :href="user.links.facebook_url"
          target="_blank"
        >
          <img src="~@/assets/images/icons/FB.svg" />
        </a>
        <span v-else :class="$style.grayIcon">
          <img src="~@/assets/images/icons/FB.svg" />
        </span>

        <a
          v-if="hasUrl('instagram_url')"
          :href="user.links.instagram_url"
          target="_blank"
        >
          <img src="~@/assets/images/icons/INS.svg" />
        </a>
        <span v-else :class="$style.grayIcon">
          <img src="~@/assets/images/icons/INS.svg" />
        </span>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import SideWidget from '@/components/common/SideWidget/SideWidget';

export default {
  components: {
    SideWidget
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    hasUrl(urlAttribute) {
      if (!urlAttribute) return undefined;

      if (!this.user) return undefined;

      if (!this.user.links) return undefined;

      return this.user.links[urlAttribute] ? true : false;
    }
  }
};
</script>

<style module lang="scss">
@import './SideWidgetUserProfileSocial.scss';
</style>
