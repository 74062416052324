var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[_vm.$style.wrapper] = true, _obj['active'] = _vm.isActive, _obj )},[_c('div',{class:_vm.$style.details},[_c('p',{staticClass:"duration"},[_vm._v(" "+_vm._s(_vm.duration(_vm.lesson))+" ")]),_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:3",value:(1.35),expression:"1.35",arg:"3"}],class:_vm.$style.name},[_c('router-link',{attrs:{"to":{
          name: 'assignment-detail',
          params: {
            assignmentId: _vm.lesson.parent_assignment_id || _vm.lesson.assignment_id,
            scheduledAssignmentId: _vm.lesson.scheduled_assignment_id
          }
        }}},[_vm._v(" "+_vm._s(_vm.lesson.name)+" ")])],1),_c('p',{class:_vm.$style.type},[_vm._v(_vm._s(_vm.label(_vm.sessionType(_vm.lesson))))])]),_c('NewRoomLauncherBeforeStart',{staticClass:"launcher",attrs:{"user":_vm.currentUserProfile,"session":_vm.lesson,"nowISO":_vm.nowISO}},[(_vm.isPhoneLayout)?_c('h5',{class:_vm.$style.joinActionMobile},[_vm._v(" Use a computer or tablet to JOIN the session. ")]):_c('h5',{staticClass:"join-button uppercase",class:_vm.$style.joinAction},[_vm._v(" Join! ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }