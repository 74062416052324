<template>
  <NotFound v-if="display404" />
  <Layout variant="2" v-else>
    <template #pageSideA>
      <AdminUserInfo
        v-if="detail && detail.published_by"
        :title="'Author'"
        :user="detail.published_by"
      />
    </template>
    <template #pageContent>
      <div v-if="detail && detail.published_by" :class="$style.wrapper">
        <div :class="$style.header">
          <router-link
            to="/admin/assignments"
            class="btn standard"
            :class="$style.backButton"
            ><i class="icon icon-chevron-left"
          /></router-link>
          <h6>Assignment Note</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <div class="box-shadow-white p-2">
          <div :class="$style.detailHeader">
            <p class="semibold">
              {{ detail.unit_name }}
              <span v-if="detail.unit_id" class="adminSecondaryId">{{
                detail.unit_id
              }}</span>
            </p>
            <div :class="$style.detailTitle">
              <h1>
                <a :href="detail.assignment_url" target="_blank">{{
                  detail.assignment_name
                }}</a>
              </h1>
              <p class="adminPrimaryId">{{ detail.assignment_id }}</p>
            </div>
            <div :class="$style.moduleDate">
              <p class="semibold">{{ detail.module_name }}</p>
              <p class="placeholder">| {{ publishedAt }}</p>
            </div>
          </div>
          <div v-html="detail.note" />
        </div>
      </div>
      <h6 class="pt-2">Resources</h6>
      <vue-good-table
        :class="$style.wrapper"
        :columns="columns"
        :rows="rows"
        compactMode
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'title'">
            <router-link
              :to="{
                name: 'library-resource',
                params: { resourceSlug: props.row.slug }
              }"
            >
              {{ props.row.title }}
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'id'">
            <span class="adminSecondaryId">{{ props.row.id }}</span>
          </span>
        </template>
      </vue-good-table>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { GET_ADMIN_ASSIGNMENT_NOTE_DETAIL } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminUserInfo from '@/components/admin/AdminUserInfo/AdminUserInfo';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    AdminUserInfo,
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      detail: [],
      publishedAt: null,
      columns: [
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Resource ID',
          field: 'id'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchNoteDetail();
    }
  },
  methods: {
    fetchNoteDetail() {
      this.$store
        .dispatch(
          GET_ADMIN_ASSIGNMENT_NOTE_DETAIL,
          this.$route.params.assignmentNoteId
        )
        .then(detail => {
          this.detail = detail;
        })
        .then(() => {
          this.createResourceRows();
          this.publishedAt = moment(this.detail.published_at).format(
            'DD MMM, YYYY'
          );
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createResourceRows() {
      this.rows = [];

      if (this.detail.resources) {
        this.detail.resources.filter(resource => {
          this.rows.push({
            title: resource.title || [],
            id: resource.resource_id || [],
            type: resource.type || [],
            slug: resource.slug || []
          });
        });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './AdminAssignmentNoteDetail.scss';
@import '../AdminTables.scss';
</style>
