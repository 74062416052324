<template>
  <div :class="$style.wrapper" v-html="content" />
</template>

<script>
export default {
  props: {
    content: {
      type: String
    }
  }
};
</script>

<style module lang="scss">
@import './BlogPostContent.scss';
</style>
