<template>
  <div :class="$style.wrapper">
    <div class="box-shadow-white" :class="$style.contentEdit">
      <FormLabel>
        <span>Title</span>
        <FormInput :placeholder="'Blog post title ...'" v-model="newTitle" />
      </FormLabel>
      <FormLabel>
        <span>Short Description</span>
        <FormTextarea
          :placeholder="'Blog post description ...'"
          v-model="newExcerpt"
        />
      </FormLabel>
      <span :class="$style.label">Content</span>
      <Editor v-model="newContent" />

      <FormLabel :class="$style.labelNextToInput" class="pt-1">
        <span class="pr-1">Reactions and discussion allowed</span>
        <FormCheckbox v-model="feedbackEnabled" />
      </FormLabel>

      <FormFieldErrors
        :class="$style.error"
        :errors="[{ message: errorMessages }]"
      />
      <div :class="$style.confirmButtons">
        <FormButton
          :standard="true"
          @click="updatePost"
          :class="$style.greenButton"
          >Save changes</FormButton
        >
        <FormButton :ghost="true" @click="closeEditMode"
          >Discard changes</FormButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Editor from '@/components/common/Editor/Editor';

import {
  UPDATE_ADMIN_BLOG_DETAIL,
  GET_ADMIN_BLOG_DETAIL
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Editor
  },
  data() {
    return {
      display404: false,
      newTitle: null,
      newExcerpt: null,
      newContent: null,
      feedbackEnabled: true,
      errorMessages: '',
      postDetail: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    ...mapActions([UPDATE_ADMIN_BLOG_DETAIL]),
    fetchDetail() {
      this.$store
        .dispatch(GET_ADMIN_BLOG_DETAIL, this.$route.params.postId)
        .then(post => {
          if (post) {
            this.newTitle = post.title;
            this.newExcerpt = post.excerpt;
            this.newContent = post.content;
            this.feedbackEnabled = post.feedback_enabled;
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    checkInputs() {
      this.errorMessages = '';

      if (
        !this.newTitle ||
        !this.newTitle.trim() ||
        !this.newExcerpt ||
        !this.newExcerpt.trim() ||
        !this.newContent ||
        !this.newContent.trim()
      ) {
        this.errorMessages = 'Please fill out all the inputs.';
      } else {
        return true;
      }

      return false;
    },
    updatePost() {
      if (this.checkInputs()) {
        this.UPDATE_ADMIN_BLOG_DETAIL({
          postId: this.$route.params.postId,
          payload: {
            title: this.newTitle,
            excerpt: this.newExcerpt,
            content: this.newContent,
            feedback_enabled: this.feedbackEnabled
          }
        })
          .then(response => {
            this.$emit('updateBlog');
            this.closeEditMode();
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    closeEditMode() {
      this.$emit('closeEditMode');
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminCreateBlog.scss';
@import '../AdminTables.scss';
</style>
