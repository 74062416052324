import moment from 'moment';

export const getAssignmentDeadline = assignment => {
  if (!assignment) {
    return null;
  }

  if (assignment.end_at) {
    return assignment.end_at;
  }

  return null;
};

export const getAssignmentStart = assignment => {
  if (!assignment) {
    return null;
  }

  if (assignment.start_at) {
    return assignment.start_at;
  }

  return null;
};

export const isAssignmentAfterDeadline = (assignment, nowISO) => {
  const deadline = getAssignmentDeadline(assignment);

  if (!deadline) {
    return false;
  }

  return moment(nowISO).isAfter(deadline);
};

export const isAssignmentAfterStart = (assignment, nowISO) => {
  const start = getAssignmentStart(assignment);

  if (!start) {
    return false;
  }

  return moment(nowISO).isAfter(start);
};
