// This module contains only mock functionality we use
// for demo purposes only. Meanwhile, a new store
// module - domain.module - has been created. This
// module should be merged with it so we will have
// only meta.module or domain.module.
// I've decided not to move it from here right now because
// we can do it when implementing production-ready
// for theming and locale.
import ApiService from '@/api/api.service';

import { GET_LOCALE } from './actions.type';
import { SET_LOCALE } from './mutations.type';

export const state = {
  locale: 'en'
};

export const getters = {
  locale(state) {
    return state.locale;
  }
};

export const actions = {
  [GET_LOCALE](context) {
    return new Promise((resolve, reject) => {
      ApiService.getDomainMetadata()
        .then(({ data }) => {
          // TODO: replace with data from API when it's ready
          // context.commit(SET_LOCALE, data.locale);
          context.commit(SET_LOCALE, 'en');
          resolve(data);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  }
};

export const mutations = {
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
