export const REGISTER_CONFIG = {
  // this regex is used for e-mail validation in case
  // we couldn't contact API and obtain a domain-specific regex
  // eslint-disable-next-line
  defaultEmailRegex: '.+\\@.+\\..+',
  // this regex is used for password validation in case
  // we couldn't contact API and obtain a domain-specific regex
  // eslint-disable-next-line
  defaultPasswordRegex: '.{6,}',
  minPasswordLength: 5,
  passwordStrength: null, // null or predescribed password strength
  errors: {
    // obsolete but leaving it here for some time if we find out we need it
    /*
    short_pwd: {
      path: 'password',
      message: 'Your password is too short'
    },
    */
    weak_pwd: {
      path: 'password',
      message: 'Choose stronger password'
    },
    invalid_pwd: {
      path: 'password',
      message: 'Valid password required'
    },
    email: {
      path: 'email',
      message: 'Valid email required'
    },
    terms_of_service: {
      path: 'terms',
      message: 'You need to agree to the Terms of Service'
    },
    privacy_policy: {
      path: 'terms',
      message: 'You need to agree to the Privacy Policy'
    },
    gender: {
      path: 'gender',
      message: 'You need to select your gender'
    },
    birthdate: {
      path: 'birthdate',
      message: 'You need to provide a valid birthdate'
    },
    wrong_name: {
      path: 'name',
      message: 'Please fill in your full name'
    },
    wrong_country: {
      path: 'country',
      message: 'Please select a country'
    },
    wrong_address: {
      path: 'address',
      message: 'Please fill in your full address'
    }
  }
};
