<template>
  <header :class="$style.wrapper">
    <div :class="$style.content">
      <div :class="$style.logo">
        <span v-if="hideNavigationItems">
          <img
            :src="require(`@/assets/images/logos/theme-${THEME}_logo.png`)"
            role="banner"
            alt="candena"
          />
        </span>

        <router-link v-else :to="{ name: 'dashboard' }">
          <img
            :src="require(`@/assets/images/logos/theme-${THEME}_logo.png`)"
            role="banner"
            alt="candena"
          />
        </router-link>
      </div>

      <template v-if="!hideNavigationItems">
        <div :class="$style.menu">
          <MobileNav
            v-if="isPhoneLayout || isTabletLayout"
            :class="{ [$style.mobileNav]: !isActive }"
            @close="isActive = !isActive"
          />
          <MainNav v-else role="navigation" :class="$style.nav" />
        </div>
        <div :class="$style.icons">
          <ActionBar />
        </div>
        <svg
          :class="$style.toggleIcon"
          @click="isActive = !isActive"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 100 100"
          xmlns:v="https://vecta.io/nano"
        >
          <use xlink:href="#B" />
          <use xlink:href="#B" x="0.2" y="31.5" />
          <use xlink:href="#B" y="62.7" />
          <defs>
            <path
              id="B"
              d="M8.652 8.852h82.5c4.8 0 8.6 3.9 8.6 8.6h0c0 4.8-3.9 8.6-8.6 8.6h-82.5c-4.8 0-8.6-3.9-8.6-8.6h0c-.1-4.7 3.8-8.6 8.6-8.6z"
            />
          </defs>
        </svg>
      </template>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import { DEFAULT_THEME } from '@/config/themes';

import MainNav from '@/components/common/MainNav/MainNav';
import MobileNav from '@/components/common/MobileNav/MobileNav';
import ActionBar from '@/components/common/ActionBar/ActionBar';

export default {
  components: {
    MainNav,
    MobileNav,
    ActionBar
  },
  props: {
    hideNavigationItems: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  watch: {
    isPhoneLayout(value) {
      if (!value) {
        this.isActive = false;
      }
    },
    isTabletLayout(value) {
      if (!value) {
        this.isActive = false;
      }
    },
    isActive() {
      document.querySelector('body, html').style.overflow = this.isActive
        ? 'hidden'
        : 'visible';
    }
  },
  computed: {
    ...mapGetters(['isPhoneLayout', 'isTabletLayout'])
  },
  created() {
    document.querySelector('body, html').style.overflow = 'visible';

    this.THEME = process.env.THEME || DEFAULT_THEME;
  },
  methods: {
    onLogoutClick() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './PageHeader.scss';
</style>
