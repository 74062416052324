import { mapGetters } from 'vuex';

import { checkPassStrength } from '@/helpers/password';
import { REGISTER_CONFIG } from '@/config/registration';

export default {
  data() {
    return {
      password: '',
      errors: []
    };
  },
  computed: {
    ...mapGetters(['passwordRegex']),
    passwordErrors() {
      return this.errors.filter(e => e.path === 'password');
    },
    passwordStrength() {
      return checkPassStrength(this.password).name;
    },
    validatePasswordStrength() {
      return (
        REGISTER_CONFIG.passwordStrength === null ||
        checkPassStrength(this.password).score >=
          REGISTER_CONFIG.passwordStrength.score
      );
    },
    validatePassword() {
      const re = new RegExp(this.passwordRegex);
      return re.test(this.password);
    }
  }
};
