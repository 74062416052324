<template>
  <div
    class="tooltip"
    :class="{ show: visible }"
    role="tooltip"
    @mouseenter="onShow"
    @mouseleave="onHide"
  >
    <slot></slot>
    <div class="tooltip-inner">
      <slot name="inner">This is a tooltip</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      visible: false
    };
  },
  methods: {
    onShow() {
      this.$emit('show');
      this.visible = true;
    },
    onHide() {
      this.$emit('hide');
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip.show .tooltip-inner {
  opacity: 1;
}

.tooltip-inner {
  opacity: 0;
  width: 200px;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: calc(100% + 5px);
  right: calc(-100px + 50%);
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;

  &:before {
    position: absolute;
    display: inline-block;
    top: 100%;
    right: 50%;
    margin: 0 0 0 5px;
    border-width: 5px 5px 0;
    border-color: transparent;
    border-top-color: black;
    border-style: solid;
    background-color: transparent;
    content: '';
    width: 1px;
    height: 15px;
  }
}
</style>
