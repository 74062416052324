import ApiService from '@/api/api.service';

import { GET_USER_KVSTORE, PATCH_USER_KVSTORE } from './actions.type';

import {
  SET_USER_KVSTORE,
  PURGE_USER_KVSTORE,
  RESET_STATE
} from './mutations.type';

export const state = {
  userKVStore: {}
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  getUserKVStore(state) {
    return state.userKVStore;
  },

  getUserKVStoreValue: state => key => {
    const found = Object.keys(state.userKVStore).find(
      userKVStoreKey => userKVStoreKey === key
    );

    return found ? state.userKVStore[key] : undefined;
  }
};

export const actions = {
  [GET_USER_KVSTORE](context) {
    return new Promise((resolve, reject) => {
      ApiService.getUserKVStore()
        .then(({ data }) => {
          context.commit(SET_USER_KVSTORE, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [PATCH_USER_KVSTORE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patchUserKVStore(payload)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [SET_USER_KVSTORE](state, payload) {
    state.userKVStore = { ...state.userKVStore, ...payload };
  },
  [PURGE_USER_KVSTORE](state) {
    state.userKVStore = initialStateCopy.userKVStore;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
