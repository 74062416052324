import { logError } from '@/helpers/errors';

import {
  ADD_CLIENT_ERRORS,
  REMOVE_CLIENT_ERRORS,
  SET_GLOBAL_ERROR,
  PURGE_GLOBAL_ERROR
} from './mutations.type';
import { clientErrorTypes } from './errors.client';

export const state = {
  globalError: {
    error: null,
    clientMessage: ''
  },
  clientErrors: []
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  globalErrorClientMessage(state) {
    return state.globalError.clientMessage;
  },
  clientErrors(state) {
    return state.clientErrors;
  },
  clientUserProfileErrors(state) {
    return state.clientErrors.filter(
      error =>
        clientErrorTypes.map(e => e.path).includes(error.path) &&
        error.path.startsWith('user.')
    );
  },
  clientTeamProfileErrors(state) {
    return state.clientErrors.filter(
      error =>
        clientErrorTypes.map(e => e.path).includes(error.path) &&
        error.path.startsWith('team.')
    );
  }
};

export const mutations = {
  [SET_GLOBAL_ERROR](
    state,
    { error, clientMessage = 'An unexpected error occurred.', log = false }
  ) {
    state.globalError = initialStateCopy.globalError;

    if (error) {
      state.globalError.error = error;
    }

    if (clientMessage) {
      state.globalError.clientMessage = clientMessage;
    }

    if (log && error) {
      logError(error);
    }
  },
  [PURGE_GLOBAL_ERROR](state) {
    state.globalError = { error: null, clientMessage: '' };
  },
  [ADD_CLIENT_ERRORS](state, clientErrorsArray) {
    const addClientError = clientError => {
      // do not add same errors more times, based on clientError.path field
      if (!state.clientErrors.find(item => item.path === clientError.path)) {
        const found = clientErrorTypes.find(e => e.path === clientError.path);
        if (found) {
          state.clientErrors.push(found);
        }
      }
    };

    clientErrorsArray.forEach(error => addClientError(error));
  },

  [REMOVE_CLIENT_ERRORS](state, clientErrorsArray) {
    const removeClientError = clientError => {
      // remove instance of error based on clientError.path field
      if (state.clientErrors.find(item => item.path === clientError.path)) {
        const position = state.clientErrors
          .map(e => e.path)
          .indexOf(clientError.path);
        if (position > -1) {
          state.clientErrors.splice(position, 1);
        }
      }
    };

    clientErrorsArray.forEach(error => removeClientError(error));
  }
};

export default {
  state,
  getters,
  mutations
};
