export const QUESTION_KIND = {
  options: 'options',
  'free-text': 'free-text',
  'collaborative-editor': 'collaborative-editor',
  'file-upload': 'file-upload'
};

export const QUESTION_TYPE = {
  radio: 'radio',
  checkbox: 'checkbox',
  text: 'text',
  editor: 'editor',
  viewer: 'viewer'
};

export const ASSESSMENT_ORIGIN_ATTR = 'scheduled_assignment_kind';
export const ASSESSMENT_ORIGINS = {
  collaborative: 'group_work',
  individual: 'session'
};

export const ASSESSMENT_STATES = {
  missed: 'missed',
  new: 'new',
  in_progress: 'in_progress',
  submitted: 'submitted',
  pending: 'pending',
  evaluated: 'evaluated',
  published: 'published'
};

export const ASSESSMENT_TYPES = {
  general: 'general',
  multi_choice: 'multi_choice',
  verbal: 'verbal',
  individual: 'individual'
};
