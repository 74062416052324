<template>
  <div>
    <NotFound v-if="display404">
      <p>This topic doesn't exist</p>
      <p>
        Go to
        <router-link
          :to="{
            name: 'forum-topics',
            params: {
              forumSlug: $route.params.forumSlug
            }
          }"
        >
          list of topics
        </router-link>
      </p>
    </NotFound>
    <Layout variant="5" v-else>
      <template #pageContent>
        <div class="box-shadow-white overflow-visible">
          <template v-if="posts && posts.length <= 1">
            <TopicIntroductionPost
              :topic="topic"
              :post="posts[0]"
              @subscribe="subscribeTopic"
              @unsubscribe="unsubscribeTopic"
              :class="$style.roundBorder"
            />
          </template>

          <template v-else-if="posts && posts.length">
            <TopicIntroductionPost
              :topic="topic"
              :post="posts[0]"
              @subscribe="subscribeTopic"
              @unsubscribe="unsubscribeTopic"
            />

            <TopicPost
              v-for="post in posts.slice(1)"
              :class="$style.post"
              :key="post.post_id"
              :post="post"
              :id="`id-${post.post_id}`"
            />
          </template>
        </div>
        <div :class="$style.replyForm" class="box-shadow-white">
          <h6>your reply</h6>
          <Editor
            v-model="newPostContent"
            :config="{
              toolbar: {
                viewportTopOffset:
                  userSettingValue(SETTING_CODES.FIXED_NAV) === true ? 0 : 80,
                items: [
                  'heading',
                  '|',
                  'Bold',
                  'Italic',
                  'bulletedList',
                  'blockQuote',
                  'link',
                  '|',
                  'Undo',
                  'Redo'
                ]
              }
            }"
          ></Editor>
          <div :class="$style.replyBtn">
            <FormButton :standard="true" @click.prevent="sendPost">
              <i class="icon icon-mail-reply" />
              Post new reply
            </FormButton>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Editor from '@/components/common/Editor/Editor';

import {
  GET_DISCUSSION_FORUM,
  GET_DISCUSSION_TOPIC,
  SUBSCRIBE_DISCUSSION_TOPIC,
  UNSUBSCRIBE_DISCUSSION_TOPIC,
  GET_DISCUSSION_TOPIC_POSTS,
  SEND_DISCUSSION_TOPIC_POST
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR, PURGE_GLOBAL_ERROR } from '@/store/mutations.type';

import { DISCUSSIONS_POSTS_POLLING_INTERVAL } from '@/config/discussions';
import { SETTING_CODES } from '@/config/userSettings';
import { hasErrorStatus } from '@/helpers/errors';

import NotFound from '@/components/common/NotFound/NotFound';

import Layout from '@/views/Layout/Layout';
import TopicPost from '@/components/discussions/TopicPost/TopicPost';
import TopicIntroductionPost from '@/components/discussions/TopicIntroductionPost/TopicIntroductionPost';

export default {
  components: {
    NotFound,
    Layout,
    TopicPost,
    TopicIntroductionPost,
    Editor
  },
  data() {
    return {
      display404: false,
      pollInterval: null
    };
  },
  computed: {
    ...mapGetters('topicPosts', ['forum', 'topic', 'posts', 'newPost']),
    ...mapGetters(['userSettingValue']),
    topicSlug() {
      return this.$route.params.topicSlug;
    },
    forumSlug() {
      return this.$route.params.forumSlug;
    },
    newPostContent: {
      get() {
        return this.newPost.content === null ? '' : this.newPost.content;
      },
      set(value) {
        this.setNewPostContent(value);
      }
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;
    this.$store
      .dispatch(GET_DISCUSSION_FORUM, this.forumSlug)
      .then(forum => {
        this.setForum(forum);
      })
      .then(() => {
        this.scrollToPost();
      })
      .catch(error => {
        if (hasErrorStatus(404, error)) {
          this.display404 = true;
        } else {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        }
      });

    this.fetchTopic();
  },
  destroyed() {
    clearInterval(this.pollInterval);
    this.purge();
  },
  methods: {
    ...mapMutations('topicPosts', [
      'setForum',
      'setTopic',
      'setTopicSubscription',
      'setPosts',
      'setNewPostContent',
      'purgeNewPost',
      'purge'
    ]),
    sendPost() {
      if (this.newPostContent) {
        this.$store
          .dispatch(SEND_DISCUSSION_TOPIC_POST, {
            topicSlug: this.topicSlug,
            postContent: this.newPostContent
          })
          .then(() => {
            this.$store.commit(PURGE_GLOBAL_ERROR);

            // user is implicitly subscribed to the topic after
            // sending a new post => needs to be indicated on FE
            this.setTopicSubscription(true);

            this.fetchPosts();
            this.purgeNewPost();
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    fetchTopic() {
      this.$store
        .dispatch(GET_DISCUSSION_TOPIC, this.topicSlug)
        .then(topic => {
          this.setTopic(topic);

          this.fetchPosts();
          this.pollInterval = setInterval(
            this.fetchPosts,
            DISCUSSIONS_POSTS_POLLING_INTERVAL * 1000
          );
        })
        .catch(error => {
          if (hasErrorStatus(404, error)) {
            this.display404 = true;
          } else {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          }
        });
    },
    fetchPosts() {
      // currently, forum is not working for more than 100 posts
      // BE cannot provide us with more posts and we
      // were told we don't want to solve it right now
      this.$store
        .dispatch(GET_DISCUSSION_TOPIC_POSTS, {
          topicSlug: this.topicSlug,
          limit: 100
        })
        .then(posts => {
          this.setPosts(posts);
          this.$store.commit(PURGE_GLOBAL_ERROR);
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    scrollToPost() {
      this.$store
        .dispatch(GET_DISCUSSION_TOPIC_POSTS, {
          topicSlug: this.topicSlug,
          limit: 100
        })
        .then(posts => {
          this.setPosts(posts);
          this.$store.commit(PURGE_GLOBAL_ERROR);
        })
        .then(() => {
          if (this.$route && this.$route.params.postId) {
            this.$nextTick(() => {
              if (!this.$el) return;

              const elToScrollTo = this.$el.querySelector(
                `#id-${this.$route.params.postId}`
              );

              if (elToScrollTo) {
                elToScrollTo.scrollIntoView(true);
                window.scrollBy(0, -120); // move a bit down because of header
              }
            });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    async subscribeTopic() {
      try {
        await this.$store.dispatch(SUBSCRIBE_DISCUSSION_TOPIC, this.topicSlug);

        this.setTopicSubscription(true);
      } catch (error) {
        // subscription already exists - can be ignored
        if (hasErrorStatus(412, error)) {
          this.setTopicSubscription(true);

          return;
        }

        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    },
    async unsubscribeTopic() {
      try {
        await this.$store.dispatch(
          UNSUBSCRIBE_DISCUSSION_TOPIC,
          this.topicSlug
        );

        this.setTopicSubscription(false);
      } catch (error) {
        // subscription doesn't exists - can be ignored
        if (hasErrorStatus(412, error)) {
          this.setTopicSubscription(false);

          return;
        }

        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './TopicPosts.scss';
</style>
