<template>
  <div :class="$style.wrapper">
    <h6 class="main-title">Overview</h6>
    <div :class="$style.overviewCards">
      <AdminOverviewBox
        v-if="students && students.data && students.data.length"
        :overviewData="students"
      />
      <AdminOverviewBox
        v-if="users && users.data && users.data.length"
        :overviewData="users"
      />
      <AdminOverviewBox
        v-if="cohorts && cohorts.data && cohorts.data.length"
        :overviewData="cohorts"
      />
    </div>
  </div>
</template>

<script>
import {
  GET_ADMIN_STATISTICS_STUDENTS,
  GET_ADMIN_STATISTICS_USERS,
  GET_ADMIN_STATISTICS_COHORTS
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import AdminOverviewBox from '@/components/admin/AdminOverviewBox/AdminOverviewBox';

export default {
  components: {
    AdminOverviewBox
  },
  data() {
    return {
      students: {},
      users: {},
      cohorts: {}
    };
  },
  created() {
    this.fetchStatisticsStudents();
    this.fetchStatisticsUsers();
    this.fetchStatisticsCohorts();
  },
  methods: {
    fetchStatisticsStudents() {
      this.$store
        .dispatch(GET_ADMIN_STATISTICS_STUDENTS)
        .then(students => {
          this.students = students;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchStatisticsUsers() {
      this.$store
        .dispatch(GET_ADMIN_STATISTICS_USERS)
        .then(users => {
          this.users = users;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchStatisticsCohorts() {
      this.$store
        .dispatch(GET_ADMIN_STATISTICS_COHORTS)
        .then(cohorts => {
          this.cohorts = cohorts;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminDashboard.scss';
</style>
