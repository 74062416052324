<template>
  <div
    :class="{
      [$style.wrapper]: true,
      ['type-onboarding']: weekType === CYCLE_TYPES.onboarding,
      ['type-study']: weekType === CYCLE_TYPES.study,
      ['type-clinical-rotation']: weekType === CYCLE_TYPES.cr,
      ['type-holiday']: weekType === CYCLE_TYPES.holiday,
      ['type-flex']: weekType === CYCLE_TYPES.flex,
      ['type-undefined']: weekType === undefined
    }"
  >
    <div :class="$style.containers" data-cy="week_days">
      <div
        v-for="(day, dayIndex) in Object.keys(weekDetail)"
        :key="dayIndex"
        :id="`id-${dayIndex}`"
        :class="{
          [$style.dayContent]: !openDays.includes(dayIndex),
          ['current']: isCurrent(day),
          ['empty']: weekDetail[day].length === 0 ? true : false
        }"
        data-cy="week_day"
      >
        <div
          class="day-header"
          :class="{ [$style.openedDay]: openDays.includes(dayIndex) }"
          @click="toggleQuestion(dayIndex)"
        >
          <h4 class="uppercase bold">{{ toWeekDay(day) }}</h4>
          <h4 class="normal">{{ toHeaderFormat(day) }}</h4>
          <i v-if="openDays.includes(dayIndex)" class="icon icon-chevron-up" />
          <i
            v-if="!openDays.includes(dayIndex)"
            class="icon icon-chevron-down"
          />
        </div>
        <div v-show="openDays.includes(dayIndex)">
          <Lesson
            v-for="(lesson, lessonIndex) in weekDetail[day]"
            :key="lessonIndex"
            :lesson="lesson"
            :nowISO="nowISO"
            :isActive="isActive(lesson)"
            :class="{
              ['lesson']: true,
              ['active']: isActive(lesson)
            }"
            :showDetails="isExpanded(lesson)"
            @toggle_details="toggleShowDetails"
          />

          <div v-if="weekDetail[day].length === 0" class="lesson">
            <p>No sessions today.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import Lesson from '@/views/Schedule/Lesson';

import { FALLBACK_LANGUAGE } from '@/config/locale';
import { CYCLE_TYPES } from '@/config/schedule';
import { EXPANDED_DETAILS_LESSONS } from '@/config/localStorage';

export default {
  name: 'WeekDetail',
  components: {
    Lesson
  },
  props: {
    nowISO: {
      type: String,
      required: true
    },
    weekDetail: {
      type: Object,
      default: () => {}
    },
    weekType: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      purgeBeforeMoment: moment().subtract(2, 'weeks'),
      expandedDetailsLessons: [],
      openDays: []
    };
  },
  watch: {
    $route() {
      this.openDays = [];
    }
  },
  computed: {
    ...mapGetters(['currentUserProfile']),
    nowDayIndex() {
      return moment(this.nowISO).isoWeekday();
    },
    nowDayArrayIndex() {
      return this.nowDayIndex - 1;
    }
  },
  methods: {
    scrollToCurrentDay() {
      // On page load keep current day open and scroll to it
      var index = 0;
      Object.keys(this.weekDetail).map(i => {
        if (this.isCurrent(i) == true) {
          this.openDays.push(index);
          const elToScrollTo = this.$el.querySelector(`#id-${index}`);

          if (elToScrollTo) {
            elToScrollTo.scrollIntoView(true);
            window.scrollBy(0, -80);
          }
        }

        index += 1;
      });
    },
    toggleQuestion(dayIndex) {
      let arrayIndex = this.openDays.indexOf(dayIndex);

      if (this.openDays.includes(dayIndex)) {
        this.openDays.splice(arrayIndex, 1);
      } else {
        this.openDays.push(dayIndex);
      }
    },
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    },
    isActive(lesson) {
      const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

      const m_event_start = moment(lesson.start_at);
      const m_event_end = moment(lesson.end_at);

      if (!m_event_start.isValid()) return false;
      if (!m_event_end.isValid()) return false;

      return moment(this.nowISO).isBetween(
        m_event_start,
        m_event_end,
        null,
        INCLUSIVITY
      );
    },
    toWeekDay(dayISO) {
      const m_dayStartISO = moment(dayISO).startOf('day');

      return moment(dayISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('ddd');
    },
    toHeaderFormat(periodStartISO) {
      const m_dayStartISO = moment(periodStartISO).startOf('day');

      return moment(periodStartISO)
        .locale(this.currentUserProfile.system_language || FALLBACK_LANGUAGE)
        .format('DD.MM.YYYY');
    },
    saveToLocalStorage() {
      window.localStorage.setItem(
        EXPANDED_DETAILS_LESSONS,
        JSON.stringify(this.expandedDetailsLessons)
      );
    },
    addToExpandedIds(lessonId) {
      if (!lessonId) return;

      this.expandedDetailsLessons.push(lessonId);
    },
    removeFromExpandedIds(lessonId) {
      if (!lessonId) return;

      const indexToRemove = this.expandedDetailsLessons.indexOf(lessonId);

      if (indexToRemove !== -1) {
        const filteredArray = this.expandedDetailsLessons.filter(
          (val, index) => index !== indexToRemove
        );

        this.expandedDetailsLessons = filteredArray;
      }
    },
    isExpanded(lesson) {
      const lessonId = parseInt(lesson.scheduled_assignment_id);
      return this.expandedDetailsLessons.includes(lessonId);
    },
    toggleShowDetails(lesson) {
      const lessonId = parseInt(lesson.scheduled_assignment_id);
      const found = this.expandedDetailsLessons.includes(lessonId);

      if (!found) {
        this.addToExpandedIds(lessonId);
        this.saveToLocalStorage();
      } else {
        this.removeFromExpandedIds(lessonId);
        this.saveToLocalStorage();
      }
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
    // Create localStorage key if it doesn't exist
    if (window.localStorage.getItem(EXPANDED_DETAILS_LESSONS) === null) {
      window.localStorage.setItem(EXPANDED_DETAILS_LESSONS, JSON.stringify([]));
    }
  },
  mounted() {
    this.scrollToCurrentDay();

    this.expandedDetailsLessons = JSON.parse(
      window.localStorage.getItem(EXPANDED_DETAILS_LESSONS)
    );
  }
};
</script>

<!-- Watch out! Following styles are NOT modules, but globals! -->
<style lang="scss">
@import './WeekDetailGlobals.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>

<style lang="scss" module>
@import './WeekDetailMobile.scss';
</style>
