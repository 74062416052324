<template>
  <div :class="{ [$style.wrapper]: true, ['active']: isActive }">
    <div :class="$style.details">
      <p class="duration">
        {{ duration(lesson) }}
      </p>
      <p :class="$style.name" v-line-clamp:3="1.35">
        <router-link
          :to="{
            name: 'assignment-detail',
            params: {
              assignmentId: lesson.parent_assignment_id || lesson.assignment_id,
              scheduledAssignmentId: lesson.scheduled_assignment_id
            }
          }"
        >
          {{ lesson.name }}
        </router-link>
      </p>
      <p :class="$style.type">{{ label(sessionType(lesson)) }}</p>
    </div>
    <NewRoomLauncherBeforeStart
      :user="currentUserProfile"
      :session="lesson"
      :nowISO="nowISO"
      class="launcher"
    >
      <h5 v-if="isPhoneLayout" :class="$style.joinActionMobile">
        Use a computer or tablet to JOIN the session.
      </h5>

      <h5 v-else :class="$style.joinAction" class="join-button uppercase">
        Join!
      </h5>
    </NewRoomLauncherBeforeStart>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { renderLabel } from '@/helpers/common';
import { getAssignmentType } from '@/helpers/schedule';

import NewRoomLauncherBeforeStart from '@/components/common/NewRoomLauncherBeforeStart/NewRoomLauncherBeforeStart';

export default {
  components: {
    NewRoomLauncherBeforeStart
  },
  props: {
    nowISO: {
      type: String,
      required: true
    },
    lesson: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentUserProfile', 'isPhoneLayout'])
  },
  methods: {
    duration(lesson) {
      const m_start = moment(lesson.start_at);
      const m_end = moment(lesson.end_at);

      if (m_start.isValid() && m_end.isValid()) {
        return `${m_start.format('HH:mm')} - ${m_end.format('HH:mm')}`;
      }

      return '';
    },
    sessionType(lesson) {
      return getAssignmentType(lesson);
    },
    label(value) {
      return renderLabel(value);
    }
  }
};
</script>

<style lang="scss" module>
@import './MiniLesson.scss';
</style>
