// Under this key there's the most recent conversation id that user visited
export const RECENT_CONVERSATION_KEY = 'recentConversationId';

// Under this key there's a list of submission ids that user visited
export const VISITED_SUBMISSIONS_KEY = 'visitedSubmissions';

// Under this key there's a list of scheduled assignment ids that user expanded details of
export const EXPANDED_DETAILS_LESSONS = 'expandedDetailsLessons';

// Under this key there's a list of visited feedback URLs of ended scheduled assignment ids
export const VISITED_FEEDBACK_URLS = 'visitedFeedbackUrls';

// Under this key there's a preference of how many items per page should be used when paging
export const ITEMS_PER_PAGE_GRADING_INDEX = 'itemsPerPageGradingIndex';

// Enable filtering of gradebook assignments to only the ones where current user is a facilitator
export const MINE_ASSIGNMENTS_FILTER = 'mineAssignmentsFilter';
