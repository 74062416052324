import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import { AUTH_NONE } from '@/config/common';
import { VIEW_TYPES } from '@/config/gradebook';

import NotFound from '@/components/common/NotFound/NotFound';

import AdminDashboard from '@/views/Admin/AdminDashboard/AdminDashboard';
import AdminStudents from '@/views/Admin/AdminStudents';
import AdminCohorts from '@/views/Admin/AdminCohorts';
import AdminUsers from '@/views/Admin/AdminUsers';
import AdminCohortDetail from '@/views/Admin/AdminCohortDetail/AdminCohortDetail';
import AdminOperations from '@/views/Admin/AdminOperations/AdminOperations';
import AdminPendingOperations from '@/views/Admin/AdminOperations/AdminPendingOperations';
import AdminApprovedOperations from '@/views/Admin/AdminOperations/AdminApprovedOperations';
import AdminRejectedOperations from '@/views/Admin/AdminOperations/AdminRejectedOperations';
import AdminGroupDetail from '@/views/Admin/AdminGroupDetail/AdminGroupDetail';
import AdminAdministrativeGroups from '@/views/Admin/AdminGroupDetail/AdminAdministrativeGroups';
import AdminFaq from '@/views/Admin/AdminFaq/AdminFaq';
import AdminFaqList from '@/views/Admin/AdminFaq/AdminFaqList';
import AdminUserDetail from '@/views/Admin/AdminUserDetail/AdminUserDetail';
import AdminBlog from '@/views/Admin/AdminBlog/AdminBlog';
import AdminBlogDrafts from '@/views/Admin/AdminBlog/AdminBlogDrafts';
import AdminBlogPublished from '@/views/Admin/AdminBlog/AdminBlogPublished';
import AdminBlogDetail from '@/views/Admin/AdminBlog/AdminBlogDetail';
import AdminCreateBlog from '@/views/Admin/AdminBlog/AdminCreateBlog';
import AdminEditBlog from '@/views/Admin/AdminBlog/AdminEditBlog';
import AdminModules from '@/views/Admin/AdminModules/AdminModules';
import AdminCategories from '@/views/Admin/AdminCategories';
import AdminModuleDetail from '@/views/Admin/AdminModules/AdminModuleDetail';
import AdminSpecs from '@/views/Admin/AdminSpecs/AdminSpecs';
import AdminAssignments from '@/views/Admin/AdminAssignments/AdminAssignments';
import AdminAssignmentNoteDetail from '@/views/Admin/AdminAssignments/AdminAssignmentNoteDetail';
import AdminRecommendedResources from '@/views/Admin/AdminResources/AdminRecommendedResources';
import AdminPendingResources from '@/views/Admin/AdminResources/AdminPendingResources';
import AdminApprovedResources from '@/views/Admin/AdminResources/AdminApprovedResources';
import AdminRejectedResources from '@/views/Admin/AdminResources/AdminRejectedResources';
import AdminRecommendedResourceDetail from '@/views/Admin/AdminResources/AdminRecommendedResourceDetail';
import AdminPosts from '@/views/Admin/AdminPosts/AdminPosts';

import Dashboard from '@/views/Dashboard/Dashboard';
import Init from '@/views/Init/Init';
import DiscussionCategories from '@/views/DiscussionCategories/DiscussionCategories';
import ForumTopics from '@/views/ForumTopics/ForumTopics';
import TopicPosts from '@/views/TopicPosts/TopicPosts';
import Login from '@/views/Login/Login';
import Logout from '@/views/Logout/Logout';
import PasswordReset from '@/views/PasswordReset/PasswordReset';
import SetNewPassword from '@/views/SetNewPassword/SetNewPassword';
import TeamProfile from '@/views/TeamProfile/TeamProfile';
import UserProfile from '@/views/UserProfile/UserProfile';
import Conversations from '@/views/Conversations/Conversations';
import ConversationsHeader from '@/views/Conversations/ConversationsHeader';
import ConversationsHeaderMobile from '@/views/Conversations/ConversationsHeaderMobile';
import ConversationsList from '@/views/Conversations/ConversationsList';
import ConversationHeader from '@/views/Conversations/ConversationHeader';
import ConversationHeaderMobile from '@/views/Conversations/ConversationHeaderMobile';
import ConversationNewHeaderMobile from '@/views/Conversations/ConversationNewHeaderMobile';
import MessageForm from '@/views/Conversations/MessageForm';
import MessageFormMobile from '@/views/Conversations/MessageFormMobile';
import ConversationNew from '@/views/Conversations/ConversationNew';
import ConversationExisting from '@/views/Conversations/ConversationExisting';
import BlogDetail from '@/views/Blog/BlogDetail';
import BlogList from '@/views/Blog/BlogList';
import UserProfileSettings from '@/views/UserProfileSettings/UserProfileSettings';
import Library from '@/views/Library/Library';
import LibraryResource from '@/views/LibraryResource/LibraryResource';
import PdfResourceViewer from '@/components/common/PdfResourceViewer/PdfResourceViewer';
import Faq from '@/views/Faq/Faq';
import TeamProfileSettings from '@/views/TeamProfileSettings/TeamProfileSettings';
import Users from '@/views/Users/Users';
import FaqTopic from '@/components/faq/FaqTopic/FaqTopic';
import GroupWorkNextGrading from '@/views/GroupWork/GroupWorkNextGrading';
import AppInternalStatus from '@/views/AppInternalStatus/AppInternalStatus';
import ScheduleRedirect from '@/views/Schedule/Redirect.js';
import RedirectToCurrentUnits from '@/views/Learning/Redirect.js';
import Schedule from '@/views/Schedule/Schedule';
import ScheduleUndefined from '@/views/Schedule/ScheduleUndefined';
import TermsAndConditions from '@/views/TermsAndConditions/TermsAndConditions';
import UsersStaff from '@/components/user-profile/UsersStaff/UsersStaff';
import UsersStudents from '@/components/user-profile/UsersStudents/UsersStudents';
import LearningUnitDetail from '@/views/Learning/LearningUnitDetail';
import LearningUnitList from '@/views/Learning/LearningUnitList';
import LearningUnitIcons from '@/views/Learning/LearningUnitIcons';
import AssignmentDetail from '@/views/Grading/AssignmentDetail';
import AssignmentsIndex from '@/views/Grading/AssignmentsIndex';

import UpToDateIntegration from '@/views/ProofOfConcept/UpToDateIntegration';

Vue.use(Router);

export default new Router({
  routes: [
    {
      name: 'app-internal-status',
      path: '/app/internal/status',
      component: AppInternalStatus,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'proof-of-concept',
      path: '/app/internal/proof-of-concept',
      component: UpToDateIntegration
    },
    {
      name: 'integrations-uptodate',
      path: '/integrations/uptodate',
      component: UpToDateIntegration
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'logout',
      path: '/logout',
      component: Logout,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'initiate-password-reset',
      path: '/password-reset',
      component: PasswordReset,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'password-reset',
      path: '/password-reset/:token',
      component: PasswordReset,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'set-new-password',
      path: '/set-new-password',
      component: SetNewPassword,
      meta: { auth: AUTH_NONE }
    },
    {
      name: 'dashboard',
      path: '/',
      component: Dashboard
    },
    {
      name: 'init',
      path: '/init',
      component: Init
    },
    {
      name: 'discussions',
      path: '/discussions',
      component: DiscussionCategories
    },
    {
      name: 'forum-topics',
      path: '/discussions/forums/:forumSlug/topics',
      component: ForumTopics
    },
    {
      name: 'topic-posts',
      path: '/discussions/forums/:forumSlug/topics/:topicSlug',
      component: TopicPosts
    },
    {
      name: 'team-profile',
      path: '/teams/:teamSlug',
      component: TeamProfile
    },
    {
      name: 'user-profile',
      path: '/users/:userId',
      component: UserProfile
    },
    {
      name: 'current-user',
      path: '/current-user',
      redirect: () => {
        return {
          name: 'user-profile',
          params: { userId: store.getters.currentUserId }
        };
      }
    },
    {
      name: 'user-profile-settings',
      path: '/users/:userId/settings',
      component: UserProfileSettings
    },
    {
      name: 'conversations',
      path: '/conversations',
      component: Conversations,
      children: [
        {
          name: 'conversations-list',
          path: 'list',
          components: {
            sideHeader: ConversationsHeader,
            sideContent: ConversationsList,
            contentHeader: ConversationHeader,
            content: ConversationExisting,
            contentFooter: MessageForm,
            mobileHeader: ConversationsHeaderMobile,
            mobileContent: ConversationsList
          }
        },
        {
          name: 'conversation-new',
          path: 'new',
          components: {
            sideHeader: ConversationsHeader,
            sideContent: ConversationsList,
            contentHeader: ConversationHeader,
            contentSubHeader: ConversationNew,
            content: ConversationExisting,
            contentFooter: MessageForm,
            mobileHeader: ConversationNewHeaderMobile,
            mobileContent: ConversationNew
          }
        },
        {
          name: 'conversation-existing',
          path: ':conversationId',
          components: {
            sideHeader: ConversationsHeader,
            sideContent: ConversationsList,
            contentHeader: ConversationHeader,
            content: ConversationExisting,
            contentFooter: MessageForm,
            mobileHeader: ConversationHeaderMobile,
            mobileContent: ConversationExisting,
            mobileFooter: MessageFormMobile
          }
        }
      ]
    },
    {
      name: 'blog',
      path: '/blog',
      component: BlogList,
      children: [
        {
          name: 'blog-filter',
          path: '/blog/tag/:tag',
          component: BlogList
        }
      ]
    },
    {
      name: 'blog-post',
      path: '/blog/posts/:postId',
      component: BlogDetail
    },
    {
      name: 'library',
      path: '/library',
      component: Library
    },
    {
      name: 'pdf-view',
      path: '/pdf-view/:resourceSlug',
      component: PdfResourceViewer
    },
    {
      name: 'library-resource',
      path: '/library/:resourceSlug',
      component: LibraryResource
    },
    {
      name: 'faq',
      path: '/faq',
      component: Faq,
      children: [
        {
          name: 'faq-topic',
          path: ':topicSlug',
          component: FaqTopic
        }
      ]
    },
    {
      name: 'learning-units-current',
      path: '/learning',
      component: RedirectToCurrentUnits
    },
    {
      name: 'learning-units',
      path: '/learning/units/:moduleVersionId',
      component: LearningUnitList
    },
    {
      name: 'learning-unit-detail',
      path: '/learning/unit/:learningUnitId',
      component: LearningUnitDetail,
      props: true
    },
    {
      name: 'learning-unit-categories',
      path: '/learning/categories',
      component: LearningUnitIcons
    },
    {
      name: 'schedule',
      path: '/schedule',
      component: ScheduleRedirect,
      meta: { tracking: false }
    },
    {
      name: 'schedule-module-version-detail',
      path: '/schedule/module/:moduleVersionId',
      component: ScheduleRedirect
    },
    {
      name: 'schedule-module-current-week-detail',
      path: '/schedule/week/:weekId?',
      component: ScheduleRedirect
    },
    {
      name: 'schedule-week-detail',
      path: '/schedule/module/none/week/:weekId?',
      component: Schedule
    },
    {
      name: 'schedule-module-version-week-detail',
      path: '/schedule/module/:moduleVersionId/week/:weekId?',
      component: Schedule
    },
    {
      name: 'schedule-undefined',
      path: '/schedule/undefined',
      component: ScheduleUndefined,
      props: true
    },
    {
      name: 'team-profile-settings',
      path: '/team/settings',
      component: TeamProfileSettings
    },
    {
      path: '/contacts',
      component: Users,
      children: [
        {
          name: 'students',
          path: 'students',
          component: UsersStudents
        },
        {
          name: 'staff',
          path: 'staff',
          component: UsersStaff
        }
      ]
    },
    {
      name: 'group-work',
      path: '/group-work',
      component: GroupWorkNextGrading
    },
    {
      name: 'assignment-detail',
      path: '/assignment/:assignmentId/:scheduledAssignmentId?',
      canReuse: false,
      component: AssignmentDetail,
      meta: { tracking: false }
    },
    {
      name: 'gradebook',
      path: '/gradebook',
      canReuse: false,
      component: AssignmentsIndex,
      meta: { tracking: false }
    },
    {
      name: 'terms-and-conditions',
      path: '/terms-and-conditions',
      component: TermsAndConditions
    },
    {
      name: 'admin',
      path: '/admin',
      component: AdminDashboard
    },
    {
      name: 'admin-students',
      path: '/admin/students',
      component: AdminStudents
    },
    {
      name: 'admin-cohorts',
      path: '/admin/cohorts',
      component: AdminCohorts
    },
    {
      name: 'admin-users',
      path: '/admin/users',
      component: AdminUsers
    },
    {
      name: 'admin-cohort-detail',
      path: '/admin/cohorts/:cohortId',
      component: AdminCohortDetail
    },
    {
      name: 'admin-operations',
      path: '/admin/operations/',
      redirect: '/admin/operations/pending',
      component: AdminOperations,
      children: [
        {
          name: 'admin-pending-operations',
          path: 'pending',
          component: AdminPendingOperations
        },
        {
          name: 'admin-approved-operations',
          path: 'approved',
          component: AdminApprovedOperations
        },
        {
          name: 'admin-rejected-operations',
          path: 'rejected',
          component: AdminRejectedOperations
        }
      ]
    },
    {
      name: 'admin-group-detail',
      path: '/admin/user_groups/:userGroupId',
      component: AdminGroupDetail
    },
    {
      name: 'admin-administrative-groups',
      path: '/admin/user_groups',
      component: AdminAdministrativeGroups
    },
    {
      name: 'admin-user-detail',
      path: '/admin/users/:userId',
      component: AdminUserDetail
    },
    {
      name: 'admin-faq',
      path: '/admin/faq',
      component: AdminFaq,
      children: [
        {
          name: 'admin-faq-topic',
          path: ':topicId',
          component: AdminFaqList
        }
      ]
    },
    {
      name: 'admin-blog',
      path: '/admin/blog',
      redirect: 'admin/blog/published',
      component: AdminBlog,
      children: [
        {
          name: 'admin-blog-drafts',
          path: 'drafts',
          component: AdminBlogDrafts
        },
        {
          name: 'admin-blog-published',
          path: 'published',
          component: AdminBlogPublished
        }
      ]
    },
    {
      name: 'admin-blog-detail',
      path: '/admin/blog/detail/:postId',
      component: AdminBlogDetail
    },
    {
      name: 'admin-create-blog',
      path: '/admin/blog/new',
      component: AdminCreateBlog
    },
    {
      name: 'admin-edit-blog',
      path: '/admin/blog/edit/:postId',
      component: AdminEditBlog
    },
    {
      name: 'admin-modules',
      path: '/admin/modules',
      component: AdminModules
    },
    {
      name: 'admin-categories',
      path: '/admin/categories',
      component: AdminCategories
    },
    {
      name: 'admin-module-detail',
      path: '/admin/modules/:moduleId',
      component: AdminModuleDetail
    },
    {
      name: 'admin-specs',
      path: '/admin/specs',
      component: AdminSpecs
    },
    {
      name: 'admin-assignments',
      path: '/admin/assignments',
      component: AdminAssignments
    },
    {
      name: 'admin-assignment-node-detail',
      path: '/admin/assignment/:assignmentNoteId',
      component: AdminAssignmentNoteDetail
    },
    {
      name: 'admin-recommended-resources',
      path: '/admin/resources',
      component: AdminRecommendedResources,
      children: [
        {
          name: 'admin-pending-resources',
          path: 'pending',
          component: AdminPendingResources
        },
        {
          name: 'admin-approved-resources',
          path: 'approved',
          component: AdminApprovedResources
        },
        {
          name: 'admin-rejected-resources',
          path: 'rejected',
          component: AdminRejectedResources
        }
      ]
    },
    {
      name: 'admin-recommended-resources-detail',
      path: '/admin/resources/:resourceId',
      component: AdminRecommendedResourceDetail
    },
    {
      name: 'admin-posts',
      path: '/admin/posts',
      component: AdminPosts
    },
    { path: '*', component: NotFound }
  ],
  scrollBehavior(to, from, savedPosition) {
    const top = { x: 0, y: 0 };

    // Disable scrollBehavior when navigating to library
    // As user scrolls through and more resources get loaded
    // an offset param in URL changes to mark the users place on the page
    // causing jump to the top of the page
    if (['library'].includes(to.name)) {
      return savedPosition;
    }

    if (to.name === 'gradebook' && from.name === 'gradebook') {
      return savedPosition;
    }

    if (
      to.name === 'learning-unit-detail' &&
      from.name === 'learning-unit-detail'
    ) {
      return savedPosition;
    }

    if (
      to.name === 'learning-unit-detail' &&
      from.name !== 'learning-unit-detail'
    ) {
      return top;
    }

    if (
      to.name === 'admin-cohort-detail' &&
      from.name === 'admin-cohort-detail'
    ) {
      return savedPosition;
    }

    if (
      to.name === 'admin-module-detail' &&
      from.name === 'admin-module-detail'
    ) {
      return savedPosition;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return top;
    }
  }
});
