var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 10,
      position: 'bottom'
    }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'phaseId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.phaseId))])]):(props.column.field === 'phaseModule')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-module-detail',
            params: { moduleId: props.row.phaseModule[1] }
          }}},[_vm._v(" "+_vm._s(props.row.phaseModule[0])+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.phaseModule[1]))])])],1):(props.column.field === 'gaAssignment')?_c('span',[(props.row.assignmentId && props.row.scheduledAssignmentId)?_c('router-link',{attrs:{"to":{
            name: 'assignment-detail',
            params: {
              assignmentId: props.row.assignmentId,
              scheduledAssignmentId: props.row.scheduledAssignmentId
            }
          }},domProps:{"innerHTML":_vm._s(props.row.gaAssignment)}}):_c('p',[_vm._v("-")])],1):_vm._e()]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('h6',{class:_vm.$style.tableHeader},[_vm._v(" Cohort Phases ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }