<template>
  <div :class="$style.wrapper">
    <div
      :class="$style.topInfo"
      class="box-shadow-white overflow-visible pr-1 pl-1"
    >
      <div :class="$style.photo" class="with-badge">
        <Avatar :class="$style.avatar" :user="user" />
        <Badge class="small" :user="user" />
      </div>
      <template v-if="displaySettingsLink">
        <router-link
          :to="{
            name: 'user-profile-settings',
            params: { userId: user.user_id }
          }"
          :class="$style.settings"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            xmlns:v="https://vecta.io/nano"
          >
            <path
              d="M93.5 60.8l-5.7-3.3.2-1.3c.3-2 .5-4.1.5-6.2s-.2-4.2-.5-6.2l-.2-1.3 5.6-3.3c1.5-.8 2.7-2.1 3.4-3.7.8-2 .6-4.2-.4-6.1l-5.1-8.8c-1.9-3.3-6.2-4.5-9.6-2.6l-6 3.5-1-.8c-3.2-2.7-6.8-4.7-10.8-6.2l-1.2-.4V7.5c0-4-3.3-7.3-7.3-7.3h-9.6c-4 0-7.3 3.3-7.3 7.3v6.6l-1.2.4c-3.9 1.5-7.5 3.6-10.8 6.2l-1 .8-6-3.5a6.99 6.99 0 0 0-9.5 2.6l-5 8.9c-1.1 1.9-1.3 4.1-.5 6.1.6 1.6 1.8 2.9 3.2 3.6l5.8 3.3-.2 1.3c-.3 2-.5 4.1-.5 6.2s.2 4.2.5 6.2l.2 1.3-5.7 3.3c-1.5.8-2.7 2.1-3.4 3.7-.8 2-.6 4.2.4 6.1l5.1 8.9c1 1.6 2.5 2.7 4.2 3.1 1.8.5 3.7.3 5.4-.6l6-3.5 1 .8c3.2 2.6 6.8 4.7 10.8 6.2l1.2.4v6.6c0 4 3.3 7.3 7.3 7.3h9.6c4 0 7.3-3.3 7.3-7.3v-6.6l1.2-.4c3.9-1.5 7.5-3.6 10.7-6.2l1-.8 6.1 3.5c1.6.9 3.5 1.1 5.2.7 1.8-.5 3.4-1.7 4.3-3.3l5.1-8.8c1.1-1.9 1.3-4.1.4-6.1-.6-1.6-1.7-2.9-3.2-3.7zM50.7 67a16.99 16.99 0 0 1-17-17c0-9.4 7.7-17 17-17a16.99 16.99 0 1 1 0 34z"
            />
          </svg>
        </router-link>
      </template>

      <div data-ref="about" :class="$style.about">
        <h2 class="header-lowercase">
          <UserName
            :preTitles="true"
            :postTitles="true"
            :suffixSeparator="''"
            :user="user"
          />
          <a v-if="displayMessageIcon" :class="$style.message"
            ><span @click="openConversation(user)"
              ><img src="~@/assets/images/icons/msg.svg"/></span
          ></a>
        </h2>

        <div
          :class="$style.location"
          v-if="userLocation"
          data-cy="viewLocation"
        >
          <i class="icon icon-map-marker" />
          <p>{{ userLocation }}</p>
        </div>

        <p
          data-cy="bio"
          v-if="user && user.bio"
          :class="$style.bio"
          v-html="bio"
        />
      </div>
    </div>

    <h5 v-if="user && user.first_name">
      <DoNotTrack>{{ user.first_name }}</DoNotTrack
      >'s Activity
    </h5>

    <div class="box-shadow-white overflow-visible" :class="$style.bottomInfo">
      <div class="placeholder" :class="$style.bottomInfoPlaceholder">
        Currently there is no activity for
        <DoNotTrack>{{
          user && user.first_name ? user.first_name : 'user'
        }}</DoNotTrack>
        to display.
      </div>
    </div>
  </div>
</template>

<script>
import { getUserLocation } from '@/helpers/users';

import Avatar from '@/components/common/User/Avatar';
import Badge from '@/components/common/User/Badge';

export default {
  components: {
    Avatar,
    Badge
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    countriesList: {
      type: Array,
      default: () => []
    },
    displayMessageIcon: {
      type: Boolean,
      default: false
    },
    displaySettingsLink: {
      type: Boolean,
      default: false
    },
    teamAvatarUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    bio() {
      return this.user.bio.replace(/(\n)+/g, '<br />');
    },
    userLocation() {
      return getUserLocation(this.user, this.countriesList);
    }
  },
  methods: {
    openConversation(userProfile) {
      this.$emit('open_conversation', userProfile);
    }
  }
};
</script>

<style module lang="scss">
@import './UserProfileInfo.scss';
</style>
