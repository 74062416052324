import { GRADING_PROGRESS } from '../config/grading';

export default {};

/* never ever change this, if you need to change url
   for dev purposes, go to dev/README.md */
export const API_URL = '/api/v1';

export const ENDPOINTS = {
  sessions: () => `sessions`,
  users: () => `users`,
  bulkUsers: csvIds => `users/arr:${csvIds}`,
  initiatePasswordReset: () => `users/reset_password`,
  resetPassword: () => `users/set_password`,
  changePassword: () => `users/password`,
  userStatus: () => `users/status`,
  user: userId => `users/${userId}`,
  currentUserConsents: () => `users/consents`,
  userInfo: userId => `users/${userId}/info`,
  userCohorts: () => `cohorts`,
  userCohortSelect: cohortSlug => `cohorts/${cohortSlug}/select`,
  teams: () => `teams`,
  team: teamSlug => `teams/${teamSlug}`,
  avatars: userId => `users/${userId}/avatars`,
  avatarsPostDelete: () => `users/avatars`,
  domainMetadata: () => `domains/current`,
  domainCountries: () => `domains/current/countries`,
  tasks: () => `tasks`,
  task: taskId => `tasks/${taskId}`,
  usersStreamEvents: () => `users/stream_events`,
  userCalendar: () => `users/current/calendar`,
  unreadCount: () => `conversations/unread_count`,
  conversations: () => `conversations`,
  conversation: conversationId => `conversations/${conversationId}`,
  notifications: () => `notifications`,
  modules: () => `module_versions`,
  // yes, it's plural on our side (modulesPhases) and singular on BE (module_phases)
  // because we have modulePhases too that maps to /module_versions/current/module_phases
  // it's a mess :(
  modulesPhases: () => `module_phases`,
  webinarAuthToken: () => `webinars/get_auth_token`,
  modulePhases: () => `module_versions/current/module_phases`,
  sessionAssignments: phaseSlug => `phases/${phaseSlug}/session_assignments`,
  currentPhaseGroupWork: () =>
    'phases/current/scheduled_assignments/current/group_work',
  moduleGroupWorks: moduleVersionId =>
    `assignments/group_work/${moduleVersionId}`,

  scheduleWeekCurrent: () => `schedule/weeks/current`,
  scheduleWeek: calendarWeekId => `schedule/weeks/${calendarWeekId}`,
  personalScheduleWeekCurrent: () => `personal/schedule/weeks/current`,
  personalScheduleWeek: calendarWeekId =>
    `personal/schedule/weeks/${calendarWeekId}`,
  scheduleUpcoming: () => `schedule/upcoming`,
  scheduledAssignment: scheduledAssignmentId =>
    `scheduled_assignments/${scheduledAssignmentId}`,
  bulkAssignments: csvIds => `assignments/arr:${csvIds}`,
  assignmentsByStatus: status => {
    let endpoint;
    switch (status) {
      case GRADING_PROGRESS.PENDING:
        endpoint = `assignments/${GRADING_PROGRESS.PENDING}`;
        break;
      case GRADING_PROGRESS.EVALUATED:
        endpoint = `assignments/${GRADING_PROGRESS.EVALUATED}`;
        break;
      case GRADING_PROGRESS.ALL:
        endpoint = `assignments`;
        break;
      default:
        endpoint = `assignments`;
    }

    return endpoint;
  },
  assignment: assignmentId => `assignments/${assignmentId}`,
  assignmentQuizStats: assignmentId =>
    `assignments/${assignmentId}/quizz_stats`,
  grading: (assignmentId, scheduledAssignmentId) =>
    `assignments/${assignmentId}/scheduled_assignments/${scheduledAssignmentId}/grades`,
  publishing: (assignmentId, scheduledAssignmentId) =>
    `assignments/${assignmentId}/scheduled_assignments/${scheduledAssignmentId}/evaluations`,
  submitting: (assignmentId, scheduledAssignmentId) =>
    `assignments/${assignmentId}/scheduled_assignments/${scheduledAssignmentId}/submissions`,
  groupGrade: (assignmentId, scheduledAssignmentId, gradeId, userGroupId) =>
    `assignments/${assignmentId}/scheduled_assignments/${scheduledAssignmentId}/grades/${gradeId}/user_groups/${userGroupId}`,
  assignmentNoteResources: assignmentId =>
    `assignments/${assignmentId}/note_resources`,
  assignmentNoteResource: (assignmentId, resourceSlug) =>
    `assignments/${assignmentId}/note_resources/${resourceSlug}`,
  assignmentNoteText: assignmentId => `assignments/${assignmentId}/notes`,
  assignmentNotePublish: assignmentId =>
    `assignments/${assignmentId}/notes/publish`,
  blogPosts: () => `blog_posts`,
  // BE uses two different URLS so we need to differentiate
  // use blogPost URL for GET or POST
  // use blogPostPatch URL for PATCH
  blogPost: blogPostSlug => `blog_posts/${blogPostSlug}`,
  blogPostPatch: blogPostSlug => `blog_posts/${blogPostSlug}`,
  tags: () => `tags`,
  blogPostTags: (postId, tagId) => `admin/blog_posts/${postId}/tags/${tagId}`,
  blogPostTag: (blogPostSlug, tag) => `blog_posts/${blogPostSlug}/tag/${tag}`,
  blogPostUntag: (blogPostSlug, tag) =>
    `blog_posts/${blogPostSlug}/untag/${tag}`,
  newDiscussionsTopics: () => `discussions/topics`,
  newDiscussionsTopicPosts: topicId => `discussions/topics/${topicId}/posts`,
  newDiscussionsTopicPost: (topicId, postId) =>
    `discussions/topics/${topicId}/posts/${postId}`,
  reactions: (objectType, objectId) =>
    `reactions/${objectType}/${objectId}/reactions`,
  discussionCategories: () => `discussions/categories`,
  discussionCategoryForums: categorySlug =>
    `discussions/categories/${categorySlug}/forums`,
  discussionForum: forumSlug => `discussions/forums/${forumSlug}`,
  discussionForumTopics: forumSlug => `discussions/forums/${forumSlug}/topics`,
  discussionTopic: topicSlug => `discussions/topics/${topicSlug}`,
  discussionTopicSubscribe: topicSlug =>
    `discussions/topics/${topicSlug}/subscribe`,
  discussionTopicUnsubscribe: topicSlug =>
    `discussions/topics/${topicSlug}/unsubscribe`,
  discussionTopicPosts: topicSlug => `discussions/topics/${topicSlug}/posts`,
  discussionRecentPosts: () => 'discussions/recent_posts',
  faqTopicQuestions: topicSlug => `faq/${topicSlug}`,
  faqTopics: () => `faq`,
  resources: () => `resources`,
  resourcesCount: () => `resources/count`,
  resourcesLogicalTypes: () => `resources/logical_types`,
  resource: resourceSlug => `resources/${resourceSlug}`,
  resourceFeedback: resourceSlug => `resources/${resourceSlug}/feedback`,
  resourceGroups: () => `resource_groups`,
  resourceGroup: resourceGroupCode => `resource_groups/${resourceGroupCode}`,
  resourceBookmark: resourceSlug => `resources/${resourceSlug}/read_list`,
  resourceBookmarks: () => 'read_list',
  resourceProviders: () => `resource_providers`,
  learningUnits: moduleVersionId => `modules/${moduleVersionId}/learning_units`,
  learningUnit: learningUnitId => `learning_units/${learningUnitId}`,
  learningObjectives: () => 'learning_objectives',
  learningCategories: () => 'learning_categories',
  teamRoom: newrowContextId => `integrations/team_rooms/${newrowContextId}`,
  integrationTokens: integrationName =>
    `integrations/${integrationName}/tokens`,
  collaborationServicesToken: () => `integrations/ck_editor/token`,
  quizzesCurrent: () => `quizzes/current`,
  quizStart: quizId => `quizzes/${quizId}/start`,
  quizAttempts: quizId => `quizzes/${quizId}/attempts`,
  quizAttempt: (quizId, quizAttemptId) =>
    `quizzes/${quizId}/attempts/${quizAttemptId}`,
  quizAttemptAnswer: (quizId, quizAttemptId) =>
    `quizzes/${quizId}/attempts/${quizAttemptId}/answer`,
  quizAttemptEnd: (quizId, quizAttemptId) =>
    `quizzes/${quizId}/attempts/${quizAttemptId}/end`,
  assessments: () => `assessments`,
  assessment: assessmentSlug => `assessments/${assessmentSlug}`,
  assessmentStart: assessmentSlug => `assessments/${assessmentSlug}/start`,
  assessmentStartFromAssignment: scheduledAssignmentId =>
    `scheduled_assignments/${scheduledAssignmentId}/start_assessment`,
  announcement: () => `announcements`,
  announcementHide: announcementSlug =>
    `announcements/${announcementSlug}/hide`,
  userSettings: () => `users/settings`,
  userSetting: apiCode => `users/settings/${apiCode}`,
  userKVStore: () => `users/current/settings`, // about to replace userSetting endpoint
  reportRoute: () => `route_report`,
  reportProblem: () => `problem_reports`,
  currentUserGrades: () => `users/campus_grades`,
  userGrades: userId => `users/${userId}/campus_grades`,

  // ADMIN
  adminStudents: () => `admin/students`,
  adminStudentDetail: userId => `admin/students/${userId}`,
  adminCohorts: () => `admin/cohorts`,
  adminUsers: () => `admin/users`,
  userWelcomeEmail: userId => `admin/users/${userId}/emails/welcome_email`,
  adminUserDetail: userId => `admin/users/${userId}`,
  adminUserEmails: userId => `admin/users/${userId}/emails`,
  adminUserRole: userId => `admin/users/${userId}/role`,
  adminCohortDetail: cohortId => `admin/cohorts/${cohortId}`,
  adminCohortDetailGroups: cohortId => `admin/cohorts/${cohortId}/user_groups`,
  adminFeedbackStatus: submissionId =>
    `admin/operations/objects/feedback/${submissionId}`,
  adminOperationStatus: userId => `admin/operations/objects/user/${userId}`,
  adminOperations: () => `admin/operations`,
  adminPendingOperations: () => `admin/operations/pending`,
  adminApproveOperation: operationId =>
    `admin/operations/${operationId}/approve`,
  adminOperation: operationId => `admin/operations/${operationId}`,
  adminApprovedOperations: () => `admin/operations/approved`,
  adminRejectedOperations: () => `admin/operations/rejected`,
  adminGroupDetail: userGroupId => `admin/user_groups/${userGroupId}`,
  adminGroupDetailUser: (userGroupId, userId) =>
    `admin/user_groups/${userGroupId}/users/${userId}`,
  adminGroupDetailEligibleUsers: userGroupId =>
    `admin/user_groups/${userGroupId}/users/eligible`,
  adminGroupDetailUsers: userGroupId =>
    `admin/user_groups/${userGroupId}/users`,
  adminGroups: () => `admin/user_groups`,
  adminUserRoles: () => `admin/user_roles`,
  adminResetPassword: userId => `admin/users/${userId}/reset_password`,
  adminStatisticsUsers: () => `analytics/data_sources/overview_users`,
  adminStatisticsStudents: () => `analytics/data_sources/overview_students`,
  adminStatisticsCohorts: () => `analytics/data_sources/overview_cohorts`,
  adminGradingEvaluation: evaluationId =>
    `admin/operations/objects/grading_evaluation/${evaluationId}`,
  adminFaqTopics: () => `admin/faq/topics`,
  adminFaqQuestions: () => `admin/faq/questions`,
  adminFaqQuestion: questionId => `admin/faq/questions/${questionId}`,
  adminBlogDrafts: () => `admin/blog_posts/drafts`,
  adminBlogPublished: () => `admin/blog_posts/posts`,
  adminBlogDetail: postId => `admin/blog_posts/${postId}`,
  adminBlogDetailAction: postId => `admin/blog_posts/${postId}/publish`,
  adminBlogPost: () => `admin/blog_posts`,
  adminBlogDetailCohorts: (postId, cohortId) =>
    `admin/blog_posts/${postId}/cohorts/${cohortId}`,
  adminModules: () => `admin/modules`,
  adminCategories: () => `admin/lo_categories`,
  adminModuleDetail: moduleId => `admin/modules/${moduleId}`,
  adminModuleDetailSetting: (moduleId, optionName) =>
    `admin/modules/${moduleId}/options/${optionName}`,
  adminSpecs: () => `admin/specs`,
  adminOverview: () => `admin/modules/overview`,
  adminEditAvatar: userId => `admin/users/${userId}/avatars`,
  adminAssignmentNotes: () => `admin/assignment_notes`,
  adminAssignmentNoteDetail: assignmentNoteId =>
    `admin/assignment_notes/${assignmentNoteId}`,
  adminResourcesPending: () => `admin/resources/approvals/pending`,
  adminResourcesApproved: () => `admin/resources/approvals/approved`,
  adminResourcesRejected: () => `admin/resources/approvals/rejected`,
  adminResourcesPendingDetail: resourceId =>
    `admin/resources/approvals/resources/${resourceId}`,
  adminPosts: () => `admin/posts`
};

export const ID_TOKEN_KEY = 'X-New-Auth-Token';
export const ID_DROP_TOKEN_KEY = 'X-Drop-Auth-Token';
export const CURRENT_USER_ID = 'current_user';
