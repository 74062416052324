<template>
  <div :class="$style.wrapper">
    <div :class="$style.cohort">
      <select
        @change="onSelectedCohort"
        :class="$style.select"
        data-ref="cohort"
      >
        <option :value="NaN" v-if="hasFoundSelectedCohort === false">
          Select a cohort
        </option>
        <option
          v-for="(cohort, index) in currentUserCohorts"
          :key="index"
          :value="cohort.cohort_id"
          :selected="cohort.cohort_id === cohortId"
        >
          {{ cohortName(cohort) || 'No name cohort'
          }}{{ cohort.module_name ? ` (module: ${cohort.module_name})` : '' }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { SELECT_CURRENT_USER_COHORT } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    selectedCohortId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(['currentUserCohorts']),
    cohortId() {
      return parseInt(this.selectedCohortId);
    },
    hasFoundSelectedCohort() {
      const foundSelectedCohort = this.currentUserCohorts.find(
        cohort => cohort.cohort_id === this.cohortId
      );

      return this.currentUserCohorts &&
        foundSelectedCohort &&
        foundSelectedCohort.cohort_id
        ? true
        : false;
    }
  },
  methods: {
    cohortName(cohort) {
      if (!cohort) return undefined;

      if (cohort.cohort_name) return cohort.cohort_name;

      if (cohort.name) return cohort.name;

      if (cohort.slug) return cohort.slug;

      return undefined;
    },
    onSelectedCohort(event) {
      const cohortId = event.target.value;

      this.$emit('cohort_selected', cohortId);
    }
  }
};
</script>

<style lang="scss" module>
@import './CohortSelect.scss';
</style>
