import {
  QUESTION_KIND,
  ASSESSMENT_STATES,
  ASSESSMENT_TYPES
} from '@/config/assessments';

const GRADES = [
  { grade: 'F', lowerBoundary: 0, upperBoundary: 59 },
  { grade: 'D', lowerBoundary: 60, upperBoundary: 65 },
  { grade: 'D+', lowerBoundary: 66, upperBoundary: 69 },
  { grade: 'C', lowerBoundary: 70, upperBoundary: 75 },
  { grade: 'C+', lowerBoundary: 76, upperBoundary: 79 },
  { grade: 'B', lowerBoundary: 80, upperBoundary: 85 },
  { grade: 'B+', lowerBoundary: 86, upperBoundary: 89 },
  { grade: 'A', lowerBoundary: 90, upperBoundary: 95 },
  { grade: 'A+', lowerBoundary: 96, upperBoundary: 100 }
];

export const hasOnlyOptionsTypeQuestions = questions => {
  let allTypes = questions.reduce((memo, question) => {
    if (Object.values(QUESTION_KIND).includes(question.type)) {
      memo.push(question.type);
    }

    return memo;
  }, []);

  allTypes = [...new Set(allTypes)]; // removes duplicates

  if (allTypes.length === 1 && allTypes[0] === QUESTION_KIND.options)
    return true;

  return false;
};

export const hasSingleFileUploadQuestion = questions => {
  if (
    questions.length === 1 &&
    questions[0].type === QUESTION_KIND['file-upload']
  )
    return true;

  return false;
};

export const hasSingleCollaborativeEditorQuestion = questions => {
  if (
    questions.length === 1 &&
    questions[0].type === QUESTION_KIND['collaborative-editor']
  )
    return true;

  return false;
};

export const percentToGrade = scorePercentage => {
  let parsedPercentage = parseFloat(scorePercentage);

  if (Number.isNaN(parsedPercentage)) return undefined;

  if (parsedPercentage < 0) return undefined;

  parsedPercentage = Math.abs(parsedPercentage); // converts -0 to 0

  if (parsedPercentage > 100) return undefined;

  const roundedPercentage = Math.round(parsedPercentage);

  const foundGrade = GRADES.sort(
    (a, b) => a.lowerBoundary < b.lowerBoundary
  ).find(gradeRange => {
    return (
      roundedPercentage >= gradeRange.lowerBoundary &&
      roundedPercentage <= gradeRange.upperBoundary
    );
  });

  return foundGrade ? foundGrade.grade : undefined;
};

export const getSubmissionStatus = submission => {
  if (!submission) return undefined;

  if (submission.missed === true) return ASSESSMENT_STATES.missed;

  if (Object.values(ASSESSMENT_STATES).includes(submission.status))
    return submission.status;

  return undefined;
};

export const getReachedScorePoints = (submission, assessmentType) => {
  if (!submission) return NaN;

  // Individual and verbal assessments have no questions
  if (
    !submission.questions &&
    submission.evaluation &&
    submission.evaluation.score_points
  )
    return parseFloat(submission.evaluation.score_points);

  const evaluation = submission.evaluation;

  if (!evaluation) return NaN;

  const evaluationKeys = Object.keys(evaluation);

  if (!evaluationKeys.length) return NaN;

  let reachedScorePointsAttrName = undefined;

  if (evaluationKeys.includes('score_points_sum')) {
    reachedScorePointsAttrName = 'score_points_sum';
  }

  if (
    [ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.verbal].includes(
      assessmentType
    )
  ) {
    reachedScorePointsAttrName = 'score_points';
  }

  if (
    assessmentType === ASSESSMENT_TYPES.general &&
    submission.questions &&
    submission.questions.length === 1 &&
    submission.questions[0].type === QUESTION_KIND['file-upload'] &&
    submission.questions[0].score_points
  ) {
    return submission.questions[0].score_points;
  }

  if (
    reachedScorePointsAttrName &&
    !Number.isNaN(parseFloat(evaluation[reachedScorePointsAttrName]))
  ) {
    return parseFloat(evaluation[reachedScorePointsAttrName]);
  } else {
    return NaN;
  }
};

export const getMaxScorePoints = submission => {
  if (!submission) return NaN;

  // Special condition for file upload groupwork either with
  // defined type or without type, but with only question of
  // file-upload kind (what a mess :facepalm:)
  if (
    ((submission.assessment_type &&
      submission.assessment_type === ASSESSMENT_TYPES.general) ||
      !submission.assessment_type) &&
    submission.questions &&
    submission.questions.length === 1 &&
    submission.questions[0].type === QUESTION_KIND['file-upload'] &&
    submission.questions[0].max_points
  ) {
    return submission.questions[0].max_points;
  }

  if (
    submission &&
    submission.evaluation &&
    submission.evaluation.score_max_points &&
    !Number.isNaN(parseFloat(submission.evaluation.score_max_points))
  ) {
    return parseFloat(submission.evaluation.score_max_points);
  }

  return NaN;
};

export const getQuestionsScorePointsSum = (
  submission,
  feedbackQuestionsDrafts
) => {
  if (!submission || !submission.submission_id) return NaN;

  // Individual and verbal assessments have no questions
  if (!submission.questions && submission.evaluation.score_points)
    return parseFloat(submission.evaluation.score_points);
  if (!submission.questions && submission.evaluation.score_points === null)
    return NaN;

  const submissionId = submission.submission_id;

  const assessmentQuestionsIds = submission.questions.map(
    question => question.id
  );

  if (!assessmentQuestionsIds.length) return NaN;

  return feedbackQuestionsDrafts
    .filter(
      feedbackQuestionDraft =>
        feedbackQuestionDraft.submission_id === submissionId &&
        assessmentQuestionsIds.includes(feedbackQuestionDraft.question_id)
    )
    .reduce((memo, feedbackQuestionDraft) => {
      if (
        Number.isNaN(parseFloat(feedbackQuestionDraft.score_points)) ||
        Number.isNaN(parseFloat(memo))
      ) {
        return NaN;
      }

      return memo + parseFloat(feedbackQuestionDraft.score_points);
    }, 0);
};
