<template>
  <div :class="$style.wrapper">
    <FormLabel>
      <h5 class="text-primary">{{ headerText }}</h5>
      <p>Select Topic</p>
      <FilterSelect
        :value="selectedTopicId"
        @submenu_visibility="handleSubmenuChange"
        :submenuVisible="submenuVisible"
      >
        <template #title>
          <span>{{ selectedTopicTitle || 'Select Topic' }} </span>
        </template>
        <template #items>
          <fieldset
            v-for="(topic, index) in faqTopics"
            @click="handleTopicChange(topic)"
            :key="index"
            :value="topic"
          >
            {{ topic.title }}
          </fieldset>
        </template>
      </FilterSelect>
    </FormLabel>

    <FormLabel>
      <p>
        Question:
      </p>
      <FormTextarea
        v-model="newFaqQuestion"
        rows="2"
        placeholder="type a question here ..."
      ></FormTextarea>
    </FormLabel>

    <p>
      Answer:
    </p>
    <Editor v-model="newFaqAnswer" />

    <FormFieldErrors :errors="[{ message: errorMessages }]" />
    <FormButton :standard="true" @click="createOrUpdateFaq()">{{
      buttonText
    }}</FormButton>
  </div>
</template>

<script>
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';
import Editor from '@/components/common/Editor/Editor';

export default {
  components: {
    FilterSelect,
    Editor
  },
  data() {
    return {
      submenuVisible: false,
      selectedTopicId: '',
      selectedTopicTitle: '',
      newFaqQuestion: '',
      newFaqAnswer: '',
      errorMessages: ''
    };
  },
  props: {
    faqTopics: {
      type: Array,
      required: true
    },
    editedQuestion: {
      type: Object
    }
  },
  created() {
    if (this.editedQuestion) {
      this.newFaqAnswer = this.editedQuestion.answer;
      this.newFaqQuestion = this.editedQuestion.question;

      this.faqTopics.filter(topic => {
        if (topic.faq_topic_id === this.editedQuestion.faq_topic_id) {
          this.handleTopicChange(topic);
        }
      });
    }
  },
  computed: {
    buttonText() {
      return this.editedQuestion ? 'Update Question' : 'Create Question';
    },
    headerText() {
      return this.editedQuestion ? 'Update Question' : 'Add New Question';
    }
  },
  methods: {
    createOrUpdateFaq() {
      if (this.checkForm() && this.editedQuestion) {
        this.$emit('createOrUpdateFaq', {
          questionId: this.editedQuestion.faq_question_id,
          payload: {
            faqTopicId: parseInt(this.selectedTopicId),
            question: this.newFaqQuestion,
            answer: this.newFaqAnswer
          }
        });
      } else if (this.checkForm()) {
        this.$emit('createOrUpdateFaq', {
          faqTopicId: parseInt(this.selectedTopicId),
          question: this.newFaqQuestion,
          answer: this.newFaqAnswer
        });
      }
    },
    checkForm() {
      this.errorMessages = '';

      if (
        this.selectedTopicId === '' ||
        !this.newFaqQuestion.trim() ||
        !this.newFaqAnswer.trim()
      ) {
        this.errorMessages = 'Please fill out all fields';
      }

      if (this.errorMessages === '') {
        return true;
      }

      return false;
    },
    handleSubmenuChange(value) {
      this.submenuVisible = value;
    },
    handleClosed() {
      this.showModal = false;
      (this.selectedTopicTitle = ''),
        (this.newFaqQuestion = ''),
        (this.newFaqAnswer = '');
    },
    handleTopicChange(topic) {
      this.selectedTopicId = topic.faq_topic_id;
      this.selectedTopicTitle = topic.title;
      this.submenuVisible = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminFaqForm.scss';
</style>
