<template>
  <div :class="$style.wrapper">
    <Avatar
      v-for="(user, i) in slicedUsers"
      :key="i"
      :user="user"
      :size="size"
      :class="$style.avatar"
    />
    <h4
      v-if="showCounter"
      :style="counterStyle"
      :class="$style.counter"
      class="theme-bg bold"
    >
      +{{ counterValue }}
    </h4>
  </div>
</template>

<script>
import Avatar from '@/components/common/User/Avatar';

const DEFAULT_SIZE = '70px';

export default {
  components: {
    Avatar
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    // -1 unlimited
    maxLength: {
      type: Number,
      required: false,
      default: 3
    },
    counter: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: DEFAULT_SIZE
    }
  },
  computed: {
    showCounter() {
      return this.counter && this.users && this.users.length > this.maxLength;
    },
    counterStyle() {
      let size = this.size;

      if (!size) {
        size = DEFAULT_SIZE;
      }

      return `width: ${size}; height: ${size}`;
    },
    counterValue() {
      if (!this.users) {
        return '';
      }

      if (this.maxLength === -1) {
        return '';
      }

      return this.users.length - this.maxLength;
    },
    slicedUsers() {
      if (!this.users) {
        return [];
      }

      if (this.maxLength === -1) {
        return this.users;
      }

      return this.users.slice(0, this.maxLength);
    }
  }
};
</script>

<style module lang="scss">
@import './AvatarGroup.scss';
</style>
