<template>
  <!-- eslint-disable -->
  <Layout variant="5">
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <div
            :class="$style.viewAs"
            v-if="currentUserCohorts && currentUserCohorts.length > 1"
          >
            <strong>View&nbsp;as:</strong>
            <CohortSelect
              :selectedCohortId="selectedCohortId"
              @cohort_selected="onSelectedCohort"
            />
          </div>
        </div>

        <div
          v-if="modulesByTime && modulesByTime.all.length"
          :class="$style.modulesNavigation"
        >
          <ModulesNavigation
            :modules="modules"
            :runningModuleVersionId="presentModuleVersionId"
            :onlyRunning="false"
            :onlyRunningOrFuture="false"
            :checkForDisabledUnits="true"
            v-slot="{ moduleObject, isActive }"
          >
            <template v-if="isActive">
              <router-link
                :to="{
                  name: 'learning-units',
                  params: {
                    moduleVersionId: moduleObject.module_version_id
                  },
                  query: {
                    ...$route.query
                  }
                }"
              >
                {{ moduleObject.name }}
              </router-link>
            </template>

            <template v-else>
              {{ moduleObject.name }}
            </template>
          </ModulesNavigation>
        </div>

        <InlineLoader v-if="isLoading" />

        <div :class="$style.completed" v-if="!isLoading">
          <div
            :class="{
              [$style.header]: true,
              [$style.inactive]: completedUnits.length < 1
            }"
            @click="flipCompleted"
          >
            <h5>
              Completed Units
            </h5>
            <i class="icon icon-chevron-down" v-if="!showCompleted" />
            <i class="icon icon-chevron-up" v-if="showCompleted" />
          </div>
          <TransitionExpand>
            <div :class="$style.units" v-if="showCompleted">
              <template v-if="completedUnits.length > 0">
                <LearningUnit
                  v-for="(unit, index) in completedUnits"
                  class="box-shadow-white"
                  :key="index"
                  :class="{
                    [$style.unit]: true,
                    [$style.study]: CYCLE_TYPES.STUDY === unit.phase_type,
                    [$style.other]: CYCLE_TYPES.STUDY !== unit.phase_type
                  }"
                  :unit="unit"
                  :moduleVersionId="selectedModuleVersionId"
                />
              </template>
              <template v-else>
                <!-- <p>No units</p> -->
              </template>
            </div>
          </TransitionExpand>
        </div>

        <div :class="$style.current" v-if="!isLoading">
          <div
            :class="{
              [$style.header]: true,
              [$style.inactive]: currentUnits.length < 1
            }"
            @click="flipCurrent"
          >
            <h5>
              Current Units
            </h5>
            <i class="icon icon-chevron-down" v-if="!showCurrent" />
            <i class="icon icon-chevron-up" v-if="showCurrent" />
          </div>
          <TransitionExpand>
            <div :class="$style.units" v-if="showCurrent">
              <template v-if="currentUnits.length > 0">
                <LearningUnit
                  v-for="(unit, index) in currentUnits"
                  :key="index"
                  class="box-shadow"
                  :class="{
                    [$style.unit]: true,
                    [$style.study]: CYCLE_TYPES.STUDY === unit.phase_type,
                    [$style.other]: CYCLE_TYPES.STUDY !== unit.phase_type
                  }"
                  :unit="unit"
                  :moduleVersionId="selectedModuleVersionId"
                />
              </template>
              <template v-else>
                <!-- <p>No units</p> -->
              </template>
            </div>
          </TransitionExpand>
        </div>

        <div :class="$style.upcoming" v-if="!isLoading">
          <div
            :class="{
              [$style.header]: true,
              [$style.inactive]: upcomingUnits.length < 1
            }"
            @click="flipUpcoming"
          >
            <h5>
              Upcoming Units
            </h5>
            <i class="icon icon-chevron-down" v-if="!showUpcoming" />
            <i class="icon icon-chevron-up" v-if="showUpcoming" />
          </div>
          <TransitionExpand>
            <div :class="$style.units" v-if="showUpcoming">
              <template v-if="upcomingUnits.length > 0">
                <LearningUnit
                  v-for="(unit, index) in upcomingUnits"
                  :key="index"
                  class="box-shadow-white"
                  :class="{
                    [$style.unit]: true,
                    [$style.study]: CYCLE_TYPES.STUDY === unit.phase_type,
                    [$style.other]: CYCLE_TYPES.STUDY !== unit.phase_type
                  }"
                  :unit="unit"
                  :moduleVersionId="selectedModuleVersionId"
                />
              </template>
              <template v-else>
                <!-- <p>No units</p> -->
              </template>
            </div>
          </TransitionExpand>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { GET_LEARNING_UNITS, GET_MODULES_TEMP } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { FROM_OLDEST } from '@/config/dates';
import { CYCLE_TYPES } from '@/config/learning';
import { sortingByDate } from '@/helpers/common';
import TransitionExpand from '@/components/common/TransitionExpand/TransitionExpand';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import ModulesNavigation from '@/views/Schedule/ModulesNavigation';
import CohortSelect from '@/views/Learning/CohortSelect';
import LearningUnit from './LearningUnit';
import { SETTING_CODES } from '@/config/userSettings';

import Layout from '@/views/Layout/Layout';

export default {
  name: 'LearningUnitList',
  components: {
    Layout,
    TransitionExpand,
    LearningUnit,
    InlineLoader,
    ModulesNavigation,
    CohortSelect
  },
  data() {
    return {
      isLoading: true,
      unitList: [],
      modules: []
    };
  },
  watch: {
    $route() {
      this.resetSections();

      this.fetchData(this.selectedModuleVersionId, this.selectedCohortId);
    }
  },
  computed: {
    ...mapGetters(['userSettingValue', 'isMobileLayout', 'currentUserCohorts']),
    ...mapGetters('learning', ['showCompleted', 'showCurrent', 'showUpcoming']),
    selectedModuleVersionId() {
      return this.$route.params && this.$route.params.moduleVersionId
        ? parseInt(this.$route.params.moduleVersionId)
        : 'current';
    },
    selectedCohortId() {
      return this.$route.query && this.$route.query.asCohort
        ? parseInt(this.$route.query.asCohort)
        : null;
    },
    units() {
      if (!this.unitList) return [];

      if (!Array.isArray(this.unitList.units)) return [];

      return this.unitList.units;
    },
    unitsByTime() {
      return this.periodsByTime(
        this.units,
        moment().toISOString(),
        false,
        'start_date',
        'end_date',
        'unit_id'
      );
    },
    modulesByTime() {
      return this.periodsByTime(
        this.modules,
        moment().toISOString(),
        false,
        'start_date',
        'end_date',
        'unit_id'
      );
    },
    presentModuleVersionId() {
      if (!this.modulesByTime) return undefined;
      if (!this.modulesByTime.present) return undefined;
      if (!this.modulesByTime.present.length) return undefined;

      const firstPresentModule = this.modulesByTime.present[0];

      if (firstPresentModule && firstPresentModule.module_version_id) {
        return firstPresentModule.module_version_id;
      }

      if (!this.modulesByTime.past) return undefined;
      if (!this.modulesByTime.past.length) return undefined;

      const lastPastModule = this.modulesByTime.past[
        modulesByTime.past.length - 1
      ];

      if (lastPastModule && lastPastModule.module_version_id) {
        return lastPastModule.module_version_id;
      }

      return undefined;
    },
    completedUnits() {
      if (!this.unitsByTime) return [];

      return this.sortByUnitStartAndSessions(this.unitsByTime.past);
    },
    currentUnits() {
      if (!this.unitsByTime) return [];

      return this.sortByUnitStartAndSessions(this.unitsByTime.present);
    },
    upcomingUnits() {
      if (!this.unitsByTime) return [];

      return this.sortByUnitStartAndSessions(this.unitsByTime.future);
    }
  },
  methods: {
    ...mapActions([GET_MODULES_TEMP]),
    ...mapActions('learning', [GET_LEARNING_UNITS]),
    ...mapMutations('learning', [
      'setShowCompleted',
      'setShowCurrent',
      'setShowUpcoming'
    ]),
    async onSelectedCohort(cohortId) {
      await this.$router.push({
        name: 'learning-units-current',
        query: {
          ...this.$route.query,
          ...{
            asCohort: parseInt(cohortId)
          }
        }
      });
    },
    sortByUnitStartAndSessions(arr) {
      const MOVE_B_BEFORE_A = 1;
      const MOVE_A_BEFORE_B = -1;
      const DONT_MOVE = 0;

      return arr.sort((unitA, unitB) => {
        const m_unitA = moment(unitA.start_date);
        const hasSessionsUnitA =
          Array.isArray(unitA.sessions) && unitA.sessions.length;

        const m_unitB = moment(unitB.start_date);
        const hasSessionsUnitB =
          Array.isArray(unitB.sessions) && unitB.sessions.length;

        // Incomparable units should be left untouched
        if (!m_unitA.isValid() || !m_unitB.isValid()) return DONT_MOVE;

        // Units with different start dates re-arrange accordingly
        if (m_unitA.isAfter(m_unitB)) return MOVE_B_BEFORE_A;
        if (m_unitA.isBefore(m_unitB)) return MOVE_A_BEFORE_B;

        // Units with same start date sort further by first session start moment
        if (m_unitA.isSame(m_unitB, 'day')) {
          const m_sessionUnitA = hasSessionsUnitA
            ? moment(
                sortingByDate(
                  Array.isArray(unitA.sessions) ? unitA.sessions : [],
                  'start_at',
                  FROM_OLDEST
                )[0].start_at
              )
            : moment.invalid({});

          const m_sessionUnitB = hasSessionsUnitB
            ? moment(
                sortingByDate(
                  Array.isArray(unitB.sessions) ? unitB.sessions : [],
                  'start_at',
                  FROM_OLDEST
                )[0].start_at
              )
            : moment.invalid({});

          // If both units have at least one session
          if (m_sessionUnitA.isValid() && m_sessionUnitB.isValid()) {
            // Compare them and re-arrange accordingly
            if (m_sessionUnitA.isAfter(m_sessionUnitB)) return MOVE_B_BEFORE_A;
            if (m_sessionUnitA.isBefore(m_sessionUnitB)) return MOVE_A_BEFORE_B;
          } else if (m_sessionUnitA.isValid() && !m_sessionUnitB.isValid()) {
            // If second unit has no session it gets queued after the one with session
            return MOVE_A_BEFORE_B;
          } else if (!m_sessionUnitA.isValid() && m_sessionUnitB.isValid()) {
            // If first unit has no session it gets queued after the one with session
            return MOVE_B_BEFORE_A;
          }

          // Any other case will leave the order untouched
          return DONT_MOVE;
        }
      });
    },
    flipCompleted() {
      if (!this.completedUnits.length) return;

      this.setShowCompleted(!this.showCompleted);
    },
    flipCurrent() {
      if (!this.currentUnits.length) return;

      this.setShowCurrent(!this.showCurrent);
    },
    flipUpcoming() {
      if (!this.upcomingUnits.length) return;

      this.setShowUpcoming(!this.showUpcoming);
    },
    async fetchData(moduleVersionId, cohortId) {
      try {
        this.isLoading = true;
        this.modules = await this.GET_MODULES_TEMP({
          as_cohort_id: cohortId ? cohortId : undefined
        });
        this.unitList = await this.GET_LEARNING_UNITS(moduleVersionId);
        this.initSections();

        this.isLoading = false;
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        this.isLoading = false;
      }
    },
    periodsByTime(periods, dateISOString, strictOverlaps) {
      const result = {
        past: [],
        present: [],
        future: [],
        all: periods
      };

      const pivot = moment(dateISOString);

      if (
        Object.prototype.toString.call(periods) !== '[object Array]' ||
        !dateISOString ||
        !pivot.isValid()
      ) {
        return undefined;
      }

      if (periods.length === 0) {
        return result;
      }

      const validPeriods = periods.filter(
        period =>
          period.start_date &&
          moment(period.start_date).isValid() &&
          period.end_date &&
          moment(period.end_date).isValid()
      );

      if (validPeriods.length === 0) {
        return result;
      }

      sortingByDate(validPeriods, 'start_date', FROM_OLDEST);

      validPeriods.map(period => {
        const INCLUSIVITY = '[]'; // https://momentjs.com/docs/#/query/is-between/

        const m_periodStart = moment(period.start_date);
        const m_periodEnd = moment(period.end_date);

        if (pivot.isBetween(m_periodStart, m_periodEnd, null, INCLUSIVITY)) {
          result.present.push(period);
          return;
        }

        if (pivot.isBefore(m_periodStart)) {
          result.future.push(period);
          return;
        }

        if (pivot.isAfter(m_periodEnd)) {
          result.past.push(period);
        }
      });

      return result;
    },
    resetSections() {
      this.setShowCurrent(undefined);
      this.setShowUpcoming(undefined);
      this.setShowCompleted(undefined);
    },
    initSections() {
      const totalUnits = this.unitsByTime.all.length;

      if (this.showCompleted === undefined) {
        if (this.completedUnits.length === totalUnits) {
          this.setShowCompleted(true);
        }
      }

      if (this.showCurrent === undefined) {
        if (this.currentUnits.length) {
          this.setShowCurrent(true);
        }
      }

      if (this.showUpcoming === undefined) {
        if (this.upcomingUnits.length) {
          this.setShowUpcoming(true);
        }
      }
    }
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
    this.SETTING_CODES = SETTING_CODES;
    this.resetSections();
    this.fetchData(this.selectedModuleVersionId, this.selectedCohortId);
  }
};
</script>

<style lang="scss" module>
@import './LearningUnitList.scss';
</style>
