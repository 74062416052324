<template>
  <content @click="handleRowClick(item)">
    <div>
      <p>
        Deadline:
        <strong>
          {{ formatDate(item.end_at) }} {{ formatTime(item.end_at) }}
        </strong>
      </p>
    </div>

    <div>
      <p :class="$style.schedule">
        <span>{{ cohortName }}</span>
        &nbsp;
        <span>{{ moduleName }}</span>
      </p>
      <p>
        {{
          userGroups
            .filter(ug => ug.group_type !== 'cohort')
            .map(ug => (ug.object_name ? ug.object_name : ''))
            .join(', ') || '&mdash;'
        }}
      </p>
    </div>

    <div>
      <span
        v-if="item.evaluation_status === GRADING_PROGRESS.EVALUATED"
        class="grading-state-evaluated"
        data-cy="grading-state"
      >
        EVALUATED
      </span>
      <span
        v-if="item.evaluation_status === GRADING_PROGRESS.PENDING"
        class="grading-state-pending"
        data-cy="grading-state"
      >
        PENDING
      </span>
    </div>

    <div>
      <i class="icon icon-chevron-right" />
    </div>
  </content>
</template>

<script>
import moment from 'moment';
import { GRADING_PROGRESS } from '@/config/grading';
const moduleShortRegexp = /Module\s*(?<moduleNumber>.+)*/i;

export default {
  name: 'GroupWorkItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    cohortName() {
      if (!this.item) return '';
      if (!this.item.cohort) return '';
      if (!this.item.cohort.name) return '';

      return this.item.cohort.name;
    },
    moduleName() {
      if (!this.item) return '';
      if (!this.item.module) return '';
      if (!this.item.module.name) return '';

      const found = this.item.module.name.match(moduleShortRegexp);

      if (found) {
        return `M${found.groups.moduleNumber}`;
      }

      return this.item.module.name;
    },
    userGroups() {
      if (!this.item) return [];
      if (!this.item.user_groups) return [];

      if (Array.isArray(this.item.user_groups)) return this.item.user_groups;

      return [this.item.user_groups];
    },
    gradingType() {
      if (!this.item) return null;
      if (!this.item.grading) return null;
      if (!this.item.grading.assessment_criterias) return null;
      if (!this.item.grading.assessment_criterias.label) return null;

      return this.item.grading.assessment_criterias.label;
    }
  },
  methods: {
    formatDate(dateISOString) {
      return moment(dateISOString).format('ddd DD.MM.YYYY');
    },
    formatTime(dateISOString) {
      return moment(dateISOString).format('H:mm');
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'assignment-detail',
        params: {
          assignmentId: row.parent_assignment_id || row.assignment_id,
          scheduledAssignmentId: row.scheduled_assignment_id
        }
      });

      return;
    }
  },
  created() {
    this.GRADING_PROGRESS = GRADING_PROGRESS;
  }
};
</script>

<style lang="scss" module>
@import '../Grading/styles/ContainerHeader.scss';
</style>
