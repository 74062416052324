<template>
  <div>
    <div v-if="conversations.length" :class="$style.conversations">
      <div
        v-for="(conversation, i) in conversations"
        :key="i"
        :class="{
          [$style.conversation]: true,
          [$style.activeConversation]:
            conversation.conversation_id === currentConversationId
        }"
        @click="selectConversation(conversation.conversation_id)"
      >
        <div :class="$style.avatarsGroup">
          <div
            v-for="avatar in avatarsWithoutMe(conversation)"
            :key="avatar.user_id"
          >
            <Avatar
              v-if="avatarsWithoutMe(conversation).length == 1"
              :user="userProfile(avatar.user_id)"
              size="45px"
            />
            <Avatar
              v-else
              :class="$style.limit"
              :user="userProfile(avatar.user_id)"
              size="25px"
            />
          </div>
          <div
            v-if="avatarsWithoutMe(conversation).length > 3"
            :class="$style.counter"
            class="paragraph-small text-bold"
          >
            +{{ avatarsWithoutMe(conversation).length - 3 }}
          </div>
        </div>

        <div
          :class="{
            [$style.conversationWrapper]: true,
            [$style.unreadConversation]: hasUnreadMessages(conversation)
          }"
        >
          <p v-line-clamp:1="1.6">
            <span v-if="conversation && conversation.object_name">{{
              conversation.object_name
            }}</span>
            <span
              v-else
              v-for="(participant, index) in fullConversationNameWithIds(
                conversation
              )"
              :key="index"
              :class="$style.conversationName"
              :style="[
                hasUnreadMessages(conversation)
                  ? { 'font-weight': 'bold' }
                  : { 'font-weight': 'normal' }
              ]"
            >
              <DoNotTrack
                ><span :class="$style.comma">{{
                  participant.name
                }}</span></DoNotTrack
              >
            </span>
          </p>
          <time :datetime="conversation.updated_at" class="paragraph-small">
            {{ getDate(conversation) }}, {{ getTime(conversation) }}
          </time>
        </div>

        <div
          v-if="hasUnreadMessages(conversation)"
          :class="$style.conversationCounter"
          class="paragraph-small text-bold"
        >
          {{ conversation.unread }}
        </div>
      </div>
    </div>
    <p v-else :class="$style.noConversations">
      No conversations
    </p>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { getConversationNameWithIds } from '@/helpers/conversations';
import { PLACEHOLDER_ID } from '@/config/conversations';
import Avatar from '@/components/common/User/Avatar';

export default {
  name: 'ConversationsListDesktop',
  components: {
    Avatar
  },
  props: {
    conversations: {
      type: Array
    },
    currentUserId: {},
    currentConversationId: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  methods: {
    selectConversation(conversationId) {
      if (conversationId === PLACEHOLDER_ID) return;

      this.$emit('select_conversation', conversationId);
    },
    createConversation() {
      this.$emit('create_conversation');
    },
    avatarsWithoutMe(conversation) {
      if (!conversation.participants) return {};

      return conversation.participants.filter(participant => {
        if (participant.user_id != this.currentUserId) {
          return participant;
        }
      });
    },
    hasUnreadMessages(conversation) {
      return conversation.unread > 0;
    },
    getDate(conversation) {
      return moment(conversation.updated_at).format('DD.MM.yyyy');
    },
    getTime(conversation) {
      return moment(conversation.updated_at).format('HH:mm');
    },
    fullConversationNameWithIds(conversation) {
      return getConversationNameWithIds(conversation, this.currentUserId);
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationsList.scss';
</style>
