<template>
  <div :class="$style.wrapper">
    <h6 id="scroll" class="main-title">
      Latest News
    </h6>
    <div v-if="news && news.length" :class="$style.contentWrapper">
      <div
        v-for="(item, index) in news"
        :key="index"
        :class="{
          [$style.item]: true,
          [$style.official]: item.tag.code == 'official',
          [$style.saveTheDate]: item.tag.code == 'save-the-date',
          [$style.platformUpdates]: item.tag.code == 'platform-news'
        }"
        class="box-shadow-white"
      >
        <div v-if="item && item.tag" :class="$style.tag">
          <div :class="$style.line" />
          <p class="paragraph-small">#{{ item.tag.code }}</p>
        </div>
        <div :class="$style.content">
          <div :class="$style.header">
            <div :class="$style.icon">
              <svg
                v-if="item.tag"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                fill="#cba04d"
                xmlns:v="https://vecta.io/nano"
              >
                <path
                  d="M442.8 278.1c-5.6-5.6-14.5-5.6-20 0s-5.6 14.5 0 20l28.5 28.5c2.7 2.7 6.3 4.1 10 4.1 3.6 0 7.2-1.4 10-4.1 5.6-5.6 5.6-14.5 0-20l-28.5-28.5zm-9.9-116.8c3.6 0 7.2-1.4 10-4.1l28.5-28.5c5.6-5.6 5.6-14.5 0-20-5.6-5.6-14.5-5.6-20 0l-28.5 28.5c-5.6 5.6-5.6 14.5 0 20 2.7 2.6 6.3 4.1 10 4.1h0zm53.1 42.2h-40.2c-7.7 0-14.1 6.3-14.1 14.1 0 7.7 6.3 14.1 14.1 14.1H486c7.7 0 14.1-6.3 14.1-14.1.1-7.8-6.3-14.1-14.1-14.1zM360.3 49.1C342.9 39 321.9 39 304.5 49l-93.8 53.9c-7.7 4.5-16.6 6.8-25.4 6.8h-42.6c-26.7 0-49.6 16.4-59.3 39.6H46.9C21 149.3 0 170.3 0 196.2v43.1c0 25.9 21.1 46.9 46.9 46.9h36.3c5.5 13 14.9 23.8 26.9 30.8l-26.6 62c-1.4 3.4-2.4 6.9-2.7 10.4-1.2 12.1 3.5 23.8 12.3 30.5l43.7 33c4.8 3.6 10.4 5.6 16.1 5.6 11.2 0 21.2-7 25.9-18.1l43.7-102.2 83.1 44c8.3 4.4 17.3 6.7 26.3 6.7 9.9 0 19.9-2.7 28.8-8.1 16.7-10 26.7-27.6 26.7-47V96.6c.3-19.8-9.9-37.5-27.1-47.5zM47.4 255.8c-11.9.1-21.6-9.6-21.6-21.5l-.1-33.2c-.1-11.9 9.6-21.6 21.5-21.6l24.1-.1.2 76.3-24.1.1zm111.4 149.8c-2.1 4.4-6.3 7.1-11.1 7.1-2.4 0-4.8-.8-6.9-2.2l-18.7-12.9c-3.8-2.6-5.8-7.2-5.3-11.9.2-1.4.6-2.7 1.2-4.1l23.9-54.4c1.1-2.3 49.2 5.3 49.3 5.4l-32.4 73zm192.5-91.2c0 22.9-26.5 37.6-48.3 26.7l-85.2-42.4c-9-4.5-19-6.8-29.1-6.8h-39.1c-22.4 0-40.5-17.1-40.5-38.1v-73.3c0-21 18.1-38.1 40.5-38.1h37.9c10.9 0 21.5-2.6 31-7.7L302 89.5c21.8-11.8 49 2.8 49.3 26.1v198.8z"
                />
              </svg>
              <img
                v-else
                :src="require(`@/assets/images/icons/course_update.svg`)"
              />
            </div>

            <time :datetime="item.published_at" class="paragraph-small">
              {{ getDate(item) }}
            </time>
          </div>
          <div :class="$style.author">
            <router-link
              v-if="item.author"
              :to="{
                name: 'user-profile',
                params: { userId: item.author.user_id }
              }"
            >
              <DoNotTrack>{{ fullUserName(item) }}</DoNotTrack>
            </router-link>

            <span v-else>EDU staff</span>

            published an article:
          </div>
          <div :class="$style.link" v-line-clamp:1="1">
            <router-link
              :to="{ name: 'blog-post', params: { postId: item.post_id } }"
            >
              "<span v-html="item.title" />"
            </router-link>
          </div>
          <p v-line-clamp:1="1" v-if="item.excerpt" v-html="item.excerpt" />
        </div>
      </div>

      <router-link
        :to="{ name: 'blog' }"
        class="button-text"
        :class="$style.button"
      >
        See all News
        <i class="icon icon-chevron-right" />
      </router-link>
    </div>

    <NotificationsPlaceholder v-else />
  </div>
</template>

<script>
import moment from 'moment';
import { getUserFullName } from '@/helpers/users';

import NotificationsPlaceholder from '@/components/common/NotificationsPlaceholder/NotificationsPlaceholder';

export default {
  props: {
    news: {
      type: Array
    }
  },
  components: {
    NotificationsPlaceholder
  },
  methods: {
    getDate(item) {
      return moment(item.published_at).format('D MMM, HH:mm');
    },
    fullUserName(item) {
      return getUserFullName(item.author);
    }
  }
};
</script>

<style lang="scss" module>
@import './NewsfeedBlog.scss';
</style>
