var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.conversations.length)?_c('div',{class:_vm.$style.conversations},_vm._l((_vm.conversations),function(conversation,i){
var _obj, _obj$1;
return _c('div',{key:i,class:( _obj = {}, _obj[_vm.$style.conversation] = true, _obj[_vm.$style.activeConversation] = conversation.conversation_id === _vm.currentConversationId, _obj ),on:{"click":function($event){return _vm.selectConversation(conversation.conversation_id)}}},[_c('div',{class:_vm.$style.avatarsGroup},[_vm._l((_vm.avatarsWithoutMe(conversation)),function(avatar){return _c('div',{key:avatar.user_id},[(_vm.avatarsWithoutMe(conversation).length == 1)?_c('Avatar',{attrs:{"user":_vm.userProfile(avatar.user_id),"size":"45px"}}):_c('Avatar',{class:_vm.$style.limit,attrs:{"user":_vm.userProfile(avatar.user_id),"size":"25px"}})],1)}),(_vm.avatarsWithoutMe(conversation).length > 3)?_c('div',{staticClass:"paragraph-small text-bold",class:_vm.$style.counter},[_vm._v(" +"+_vm._s(_vm.avatarsWithoutMe(conversation).length - 3)+" ")]):_vm._e()],2),_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.conversationWrapper] = true, _obj$1[_vm.$style.unreadConversation] = _vm.hasUnreadMessages(conversation), _obj$1 )},[_c('p',{directives:[{name:"line-clamp",rawName:"v-line-clamp:1",value:(1.6),expression:"1.6",arg:"1"}]},[(conversation && conversation.object_name)?_c('span',[_vm._v(_vm._s(conversation.object_name))]):_vm._l((_vm.fullConversationNameWithIds(
              conversation
            )),function(participant,index){return _c('span',{key:index,class:_vm.$style.conversationName,style:([
              _vm.hasUnreadMessages(conversation)
                ? { 'font-weight': 'bold' }
                : { 'font-weight': 'normal' }
            ])},[_c('DoNotTrack',[_c('span',{class:_vm.$style.comma},[_vm._v(_vm._s(participant.name))])])],1)})],2),_c('time',{staticClass:"paragraph-small",attrs:{"datetime":conversation.updated_at}},[_vm._v(" "+_vm._s(_vm.getDate(conversation))+", "+_vm._s(_vm.getTime(conversation))+" ")])]),(_vm.hasUnreadMessages(conversation))?_c('div',{staticClass:"paragraph-small text-bold",class:_vm.$style.conversationCounter},[_vm._v(" "+_vm._s(conversation.unread)+" ")]):_vm._e()])}),0):_c('p',{class:_vm.$style.noConversations},[_vm._v(" No conversations ")])])}
var staticRenderFns = []

export { render, staticRenderFns }