<template>
  <div :class="$style.title">
    <p :class="$style.heading">
      Conversations
      <i
        class="icon icon-plus"
        title="Start a conversation"
        @click.stop="createConversation"
      />
    </p>
  </div>
</template>

<script>
export default {
  name: 'ConversationsHeader',
  methods: {
    createConversation() {
      this.$router
        .replace({
          name: 'conversation-new'
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationsHeader.scss';
</style>
