<script>
import Vue from 'vue';

export default {
  functional: true,
  render(h, { props }) {
    return h('div', [h(props.template && Vue.compile(props.template))]);
  }
};
</script>
