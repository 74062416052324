/**
 * Directive for clamping (shortening) of long multi-line texts.
 * It converts the image to base64 and appends the string to img element.
 * Use:
 *   Vue.directive('line-clamp', lineClamp)
 *   <p v-line-clamp:2="1.1">...</p> means: show a paragraph with maximum 2 lines of height 1.1rem'
 */

import debounce from 'lodash.debounce';

const needsFallback = () => {
  return !('webkitLineClamp' in document.body.style);
};

const truncateText = (el, bindings, fallbackFunc) => {
  const currentValueProp = 'vLineClampValue';
  let desiredNumberOfLines = parseInt(bindings.arg);

  if (desiredNumberOfLines !== el[currentValueProp]) {
    el[currentValueProp] = desiredNumberOfLines; // stores value into element's HTML5 data- attribute

    if (needsFallback()) {
      fallbackFunc(el, bindings, desiredNumberOfLines);
    } else {
      el.style.webkitLineClamp = desiredNumberOfLines || '';
      el.style.display = '-webkit-box';
      el.style.webkitBoxOrient = 'vertical';
      el.style.overflow = 'hidden';
      el.style.textOverflow = 'ellipsis';
    }
  }
};

const splitElementTextToLines = (el, linesToShow) => {
  let lineText, line;
  let lineStart = 0;
  let lineCount = 1;
  let wordStart = 0;
  let wasNewLine = false;
  let lineWidth = el.clientWidth;
  let ce = document.createElement.bind(document);
  let ctn = document.createTextNode.bind(document);

  // get all the text, remove any line changes
  let text = (el.textContent || el.innerText).replace(/\n/g, ' ');
  // remove all content from clamped element
  while (el.firstChild !== null) el.removeChild(el.firstChild);

  // measurement element is made a child of the clamped element to get it's style
  let measure = el.appendChild(ce('span'));
  measure.style.position = 'absolute'; // prevent page reflow
  measure.style.whiteSpace = 'pre'; // cross-browser width results
  measure.style.visibility = 'hidden'; // prevent drawing

  // http://ejohn.org/blog/search-and-dont-replace/
  text.replace(/ /g, (match, position) => {
    if (lineCount === linesToShow) return; // ignore any further processing if we have total lines

    measure.appendChild(ctn(text.substr(lineStart, position - lineStart))); // create a text node and place it in the measurement element
    // have we exceeded allowed line width?
    if (lineWidth < measure.clientWidth) {
      if (wasNewLine) {
        lineText = text.substr(lineStart, position + 1 - lineStart); // we have a long word so it gets a line of it's own
        lineStart = position + 1; // next line start position
      } else {
        lineText = text.substr(lineStart, wordStart - lineStart); // grab the text until this word
        lineStart = wordStart; // next line start position
      }

      line = ce('span'); // create a line element
      line.appendChild(ctn(lineText)); // add text to the line element
      el.appendChild(line); // add the line element to the container
      wasNewLine = true; // yes, we created a new line
      lineCount++;
    } else {
      wasNewLine = false; // did not create a new line
    }

    wordStart = position + 1; // remember last word start position
    measure.removeChild(measure.firstChild); // clear measurement element
  });

  el.removeChild(measure); // remove the measurement element from the container
  line = ce('span'); // create the last line element
  line.style.display = 'inline-block'; // give styles required for text-overflow to kick in
  line.style.overflow = 'hidden';
  line.style.textOverflow = 'ellipsis';
  line.style.whiteSpace = 'nowrap';
  line.style.width = '100%';
  line.appendChild(ctn(text.substr(lineStart))); // add all remaining text to the line element
  el.appendChild(line); // add the last line element to the container
};

const fallbackSupport = (el, bindings, linesToShow) => {
  if (!linesToShow) {
    el.style.maxHeight = el.style.overflowX = '';
    return;
  }

  // figure out how high the lines should be
  let lineHeight = parseFloat(bindings.value);
  if (isNaN(lineHeight)) {
    throw new Error(
      'line-height argument for vue-line-clamp must be a number (in rem units), falling back to 14px'
    );
    lineHeight = 1;
  }
  let maxHeight = lineHeight * linesToShow;

  // make sure that max-height is set and overflowing text will be hidden
  el.style.maxHeight = maxHeight ? `${maxHeight}rem` : '';
  el.style.lineHeight = `${lineHeight}rem`; // to ensure consistency
  el.style.overflow = 'hidden';

  // split the text to desired number of lines and white-space: no-wrap the last one with ellipsis
  splitElementTextToLines(el, linesToShow);
};

export default {
  bind: (el, bindings) => {
    // Only fallback browsers need resize handler
    if (!needsFallback()) return;

    const debouncedHandler = debounce(
      () => truncateText(el, bindings, fallbackSupport),
      200
    );

    window.addEventListener('resize', debouncedHandler);

    el.cleanup = () => window.removeEventListener('resize', debouncedHandler);
  },
  unbind(el) {
    // Only fallback browsers need resize handler
    if (!needsFallback()) return;

    if (el.cleanup && typeof el.cleanup === 'function') {
      el.cleanup();
    }
  },
  inserted: (el, bindings) => truncateText(el, bindings, fallbackSupport),
  updated: (el, bindings) => truncateText(el, bindings, fallbackSupport),
  componentUpdated: (el, bindings) =>
    truncateText(el, bindings, fallbackSupport)
};
