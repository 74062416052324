<template>
  <Layout variant="5">
    <template #pageContent>
      <div :class="$style.header">
        <div v-if="shouldSeeViewAs === true" :class="$style.viewAs">
          <strong>View as:</strong>
          <SingleItemSuggest
            :userId="asUserId"
            :cohortId="asCohortId"
            :allowedSuggestionTypes="allowedSuggestionTypes"
            @cleared="handleSelectionCleared"
            :readOnly="true"
          />
        </div>
      </div>
      <Debug :print="composedDebugObject" />
    </template>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { SET_VIEW_AS_ACTION } from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import SingleItemSuggest from '@/views/Schedule/SingleItemSuggest';
import Debug from '@/components/common/Debug/Debug';

export default {
  name: 'ScheduleUndefined',
  components: {
    Layout,
    SingleItemSuggest,
    Debug
  },
  props: {
    debugObject: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'isViewingAsUser',
      'isViewingAsCohort',
      'getUserKVStoreValue',
      'shouldUsePersonalEndpoint',
      'isCurrentUserStudent',
      'isCurrentUserAdmin',
      'isCurrentUserTeachingStaff',
      'asUserId',
      'asCohortId',
      'allowedSuggestionTypes'
    ]),
    shouldSeeViewAs() {
      if (this.isCurrentUserStudent) return false;

      if (this.isViewingAsUser || this.isViewingAsCohort) {
        if (this.isCurrentUserAdmin) return true;
        if (this.isCurrentUserTeachingStaff) return true;
      }

      return undefined;
    },
    composedDebugObject() {
      return {
        ...this.debugObject,
        ...{
          isViewingAsUser: this.isViewingAsUser,
          isViewingAsCohort: this.isViewingAsCohort,
          shouldUsePersonalEndpoint: this.shouldUsePersonalEndpoint
        }
      };
    }
  },
  methods: {
    ...mapActions([SET_VIEW_AS_ACTION]),

    async handleSelectionCleared() {
      try {
        await this.SET_VIEW_AS_ACTION();

        if (this.isCurrentUserAdmin || this.isCurrentUserTeachingStaff) {
          this.$router
            .push({
              name: 'schedule'
            })
            .catch(() => {});
          return;
        } else {
          // TODO: Figure out when it is called? For students only?
          // Should there be a different method? Maybe a SET_VIEW_AS_ACTION call instead?
          this.handleViewAsUserIdRoute(NaN);
        }
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './Schedule.scss';
</style>
