<template>
  <SideWidget :class="$style.wrapper" :title="true">
    <template #content>
      <AdminUserInfo
        :class="$style.info"
        :user="userDetail"
        :studentId="userDetail.student_id"
      />
      <div :class="$style.basic">
        <div
          v-if="userDetail.role && userDetail.role.code === 'student'"
          :class="$style.activity"
        >
          <p :class="$style.status" class="text-bold">
            <span
              v-if="
                studentDetail &&
                  studentDetail.cohort &&
                  studentDetail.cohort.active
              "
              :class="$style.statusActive"
              >ACTIVE</span
            >
            <span v-else :class="$style.statusHold">ON HOLD</span>
          </p>
          <p
            v-if="
              studentDetail &&
                studentDetail.cohort &&
                studentDetail.cohort.active
            "
            :class="$style.highlightSpan"
          >
            in cohort
            <span>
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: studentDetail.cohort.cohort_id }
                }"
              >
                {{ studentDetail.cohort.cohort_name }}
              </router-link>
            </span>
          </p>
        </div>

        <div :class="$style.email">
          <a :href="`mailto:${userDetail.email}`"
            ><i class="icon icon-envelope-o" /> {{ userDetail.email }}</a
          >
        </div>
      </div>

      <ul :class="$style.listDates">
        <li>
          <h6>
            Created
          </h6>
          <p class="paragraph-large">
            {{ formatDays(userDetail.created_at) }}
          </p>
          <p>{{ formatTime(userDetail.created_at) }}</p>
        </li>
        <li v-if="userDetail && userDetail.last_activity">
          <h6>
            Last login
          </h6>
          <p class="paragraph-large">
            {{ formatDays(userDetail.last_activity) }}
          </p>
          <p>{{ formatTime(userDetail.last_activity) }}</p>
        </li>
        <li v-else>
          <h6>
            Last login
          </h6>
          <p :class="$style.highlight">
            Never
          </p>
        </li>
        <li>
          <h6>
            Password changed
          </h6>
          <p class="paragraph-large">
            {{ formatDays(userDetail.password_changed_at) }}
          </p>
          <p>{{ formatTime(userDetail.password_changed_at) }}</p>
        </li>
      </ul>
    </template>
  </SideWidget>
</template>

<script>
import moment from 'moment';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import AdminUserInfo from '@/components/admin/AdminUserInfo/AdminUserInfo';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget,
    AdminUserInfo
  },
  props: {
    userDetail: {
      type: Object,
      required: true
    },
    studentDetail: {
      type: Object,
      required: false
    }
  },
  methods: {
    formatDays(date) {
      return moment(date).format('DD.MM.yyyy');
    },
    formatTime(time) {
      return moment(time).format('hh:mm A');
    }
  }
};
</script>

<style module lang="scss">
@import './AdminSideWidgetUserDetail.scss';
</style>
