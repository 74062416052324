var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user"},[(_vm.withAvatarLink)?[_c('router-link',{staticClass:"user-avatar",attrs:{"to":{
        name: 'user-profile',
        params: {
          userId: _vm.foundUserProfileId
        }
      }}},[_c('Avatar',{attrs:{"user":_vm.foundUserProfile,"size":_vm.size}})],1)]:[_c('Avatar',{staticClass:"user-avatar",attrs:{"user":_vm.foundUserProfile,"size":_vm.size}})],(_vm.withNameLink)?[_c('router-link',{staticClass:"user-name",attrs:{"to":{
        name: 'user-profile',
        params: {
          userId: _vm.foundUserProfileId
        }
      }}},[_c('UserName',{attrs:{"user":_vm.foundUserProfile}})],1)]:[_c('UserName',{staticClass:"user-name",attrs:{"user":_vm.foundUserProfile}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }