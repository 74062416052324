import moment from 'moment';
import { ROLES } from '@/config/users';
import { hasUserRole, getUserFullName } from '@/helpers/users';

export const LEVELS = {
  maintenance: 'maintenance',
  warning: 'warning',
  info: 'info'
};

export const AUTH_NONE = 'auth-none';

// Any changes here should be in sync with src/assets/css/media.scss
export const FORM_FACTORS = {
  phone: 'only screen and (min-width: 1px) and (max-width: 767px)',
  tablet: 'only screen and (min-width: 768px) and (max-width: 1024px)',
  desktop: 'only screen and (min-width: 1025px)',
  tabletUp: 'only screen and (min-width: 768px)'
};

// Number of phases/weeks ahead of current time
// that should be accessible to browse in schedule
export const PHASES_AHEAD = 200;

export const CLOSE_TO_DEADLINE_THRESHOLD = moment
  .duration(1, 'hours')
  .asMilliseconds();

// Formats are searched from index 0 and first one that satisfies the condition is used
// bear that in mind when overriding the default formats
export const TIMER_FORMATS = [
  {
    id: 'more-than-24-hours',
    condition: remainingDurationMs => {
      return (
        remainingDurationMs >= moment.duration(24, 'hours').asMilliseconds()
      );
    },
    formatFunction: (remainingDurationMs, deadlineISO, currentTimeISO) => {
      const fullDays = moment(deadlineISO).diff(moment(currentTimeISO), 'days');

      const remainingHours = moment(deadlineISO).diff(
        moment(currentTimeISO).add(fullDays, 'days'),
        'hours'
      );

      if (remainingHours === 0) {
        return `${fullDays} days`;
      }

      if (remainingHours === 1) {
        return `${fullDays}d ${remainingHours}h`;
      }

      if (remainingHours > 1) {
        return `${fullDays}d ${remainingHours}h`;
      }
    }
  },
  {
    id: 'more-than-1-hour-less-than-24-hours',
    condition: remainingDurationMs => {
      return (
        remainingDurationMs < moment.duration(24, 'hours').asMilliseconds() &&
        remainingDurationMs >= CLOSE_TO_DEADLINE_THRESHOLD
      );
    },
    formatFunction: (
      remainingDurationMs,
      deadline,
      currentTime,
      showSeconds
    ) => {
      // moment.utc() needed here because creating a moment
      // using duration value (miliseconds)
      // gets automatically off-set to local time
      // so 5 minutes is 1h 5 minutes in Prague suddenly
      // which we really don't want
      return moment
        .utc(remainingDurationMs)
        .format(showSeconds ? 'HH:mm:ss' : 'HH:mm');
    }
  },
  {
    id: 'less-than-1-hour',
    condition: remainingDurationMs => {
      return remainingDurationMs < CLOSE_TO_DEADLINE_THRESHOLD;
    },
    formatFunction: (
      remainingDurationMs,
      deadline,
      currentTime,
      showSeconds
    ) => {
      // moment.utc() needed here because creating a moment
      // using duration value (miliseconds)
      // gets automatically off-set to local time
      return moment
        .utc(remainingDurationMs)
        .format(showSeconds ? 'mm:ss' : 'mm');
    }
  },
  {
    id: 'fallback',
    condition: () => true,
    formatFunction: showSeconds => (showSeconds ? '--:--:--' : '--:--')
  }
];

export const SUGGESTION_TYPES = {
  user: 'user',
  cohort: 'cohort'
};

export const SUGGESTION_GROUPS = [
  {
    title: 'Cohorts',
    code: SUGGESTION_TYPES.cohort,
    filterFn: i => i.item_type === SUGGESTION_TYPES.cohort,
    titleFn: i => (i && i.name ? i.name : '')
  },
  {
    title: 'Students',
    code: SUGGESTION_TYPES.user,
    filterFn: i =>
      i.item_type === SUGGESTION_TYPES.user && hasUserRole(i, ROLES.student),
    titleFn: i => getUserFullName(i)
  },
  {
    title: 'Teaching Staff',
    code: SUGGESTION_TYPES.user,
    filterFn: i =>
      i.item_type === SUGGESTION_TYPES.user &&
      (hasUserRole(i, ROLES.mentor) ||
        hasUserRole(i, ROLES.tutor) ||
        hasUserRole(i, ROLES.moduleLeader) ||
        hasUserRole(i, ROLES.expert)),
    titleFn: i => getUserFullName(i)
  },
  {
    title: 'Non-Teaching Staff',
    code: SUGGESTION_TYPES.user,
    filterFn: i =>
      i.item_type === SUGGESTION_TYPES.user && hasUserRole(i, ROLES.admin),
    titleFn: i => getUserFullName(i)
  }
];
