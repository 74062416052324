<template>
  <div>
    <template v-if="fetchingStats">
      <InlineLoader />
    </template>
    <template v-else-if="isMobileLayout">
      <div :class="$style.wrapper">
        <div class="box-shadow-white pt-1 pb-1 pl-2 pr-2">
          <span :class="$style.links">
            <ul>
              <li>{{ cohortName }}</li>
              <li>{{ moduleName }}</li>
            </ul>
          </span>
          <h2 :title="assignment.slug">{{ assignment.name }}</h2>
          <p v-html="assignment.description" />
        </div>
        <div :class="$style.pageSection" v-if="hasQuizStats">
          <h2>QUIZ</h2>

          <ul :class="$style.expandable">
            <li
              v-for="(quiz, index) in quizStats"
              :key="index"
              :title="`${quiz.quiz_name}`"
              class="box-shadow-white pt-1 pb-1 pl-2 pr-2"
              :class="{
                [$style.collapsed]: !isExpanded(quiz.quiz_id),
                [$style.expanded]: isExpanded(quiz.quiz_id)
              }"
            >
              <TransitionExpand>
                <div v-if="!isExpanded(quiz.quiz_id)">
                  <span
                    :class="$style.title"
                    v-line-clamp:2="1.1"
                    :title="`${quiz.quiz_name}`"
                  >
                    {{ quiz.quiz_name }}
                  </span>
                  <span :class="$style.score">
                    Average<br /><strong>{{ quiz.score }}%</strong>
                  </span>
                  <span :class="$style.flip">
                    <a
                      @click="expandQuizStat(quiz.quiz_id)"
                      :class="$style.flipLink"
                      class="link"
                    >
                      <i class="icon icon-chevron-down" />
                    </a>
                  </span>
                </div>

                <div v-else>
                  <span
                    :class="$style.title"
                    v-line-clamp:2="1.1"
                    :title="`${quiz.quiz_name}`"
                  >
                    {{ quiz.quiz_name }}
                  </span>
                  <span :class="$style.flip">
                    <a
                      @click="expandQuizStat(quiz.quiz_id)"
                      :class="$style.flipLink"
                      class="link"
                    >
                      <i class="icon icon-chevron-up" />
                    </a>
                  </span>

                  <p :class="$style.description">Overview</p>

                  <div :class="$style.score">
                    <img
                      v-svg-inline
                      :src="require(`@/assets/images/icons/avg_score.svg`)"
                      :class="$style.icon"
                    />
                    <strong :class="$style.value">{{ quiz.score }}</strong>
                    <span :class="$style.info">Average score</span>
                  </div>
                  <div :class="$style.attempts">
                    <img
                      v-svg-inline
                      :src="require(`@/assets/images/icons/completed.svg`)"
                      :class="$style.icon"
                    />
                    <strong :class="$style.value">{{
                      quiz.attempts_count
                    }}</strong>
                    <span :class="$style.info">Attempts completed</span>
                  </div>
                  <div :class="$style.participants">
                    <img
                      v-svg-inline
                      :src="require(`@/assets/images/icons/student.svg`)"
                      :class="$style.icon"
                    />
                    <strong :class="$style.value">{{ quiz.user_count }}</strong>
                    <span :class="$style.info">Number of Students</span>
                  </div>
                </div>
              </TransitionExpand>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        :class="{
          [$style.wrapper]: true,
          [$style.hasQuizStats]: hasQuizStats,
          [$style.gradingTitles]: true
        }"
      >
        <div class="box-shadow-white pt-1 pb-1 pl-2 pr-2">
          <span :class="$style.links">
            <ul>
              <li>{{ cohortName }}</li>
              <li>{{ moduleName }}</li>
            </ul>
          </span>
          <h2 :title="assignment.slug">{{ assignment.name }}</h2>
          <p v-html="assignment.description" />
        </div>
        <div
          v-if="hasQuizStats"
          class="box-shadow-white pt-0 pb-1 pl-1 pr-1"
          :class="$style.quizOverview"
        >
          <span :class="$style.title">
            <h3>QUIZ</h3>
            Overview
          </span>

          <ul :class="$style.selector" v-if="quizStats.length > 1">
            <li
              v-for="(quiz, index) in quizStats"
              :key="index"
              :title="`${quiz.quiz_name}`"
              @click="selectQuizStat(quiz.quiz_id)"
              :class="{ [$style.active]: selectedQuizId === quiz.quiz_id }"
            >
              {{ index + 1 }}
            </li>
          </ul>

          <p :class="$style.description">
            {{ selectedQuiz.quiz_name }}
          </p>

          <div :class="$style.score">
            <img
              v-svg-inline
              :src="require(`@/assets/images/icons/avg_score.svg`)"
              :class="$style.icon"
            />
            <strong :class="$style.value">{{ selectedQuiz.score }}</strong>
            <span :class="$style.info">Average score</span>
          </div>
          <div :class="$style.attempts">
            <img
              v-svg-inline
              :src="require(`@/assets/images/icons/completed.svg`)"
              :class="$style.icon"
            />
            <strong :class="$style.value">{{
              selectedQuiz.attempts_count
            }}</strong>
            <span :class="$style.info">Attempts completed</span>
          </div>
          <div :class="$style.participants">
            <img
              v-svg-inline
              :src="require(`@/assets/images/icons/student.svg`)"
              :class="$style.icon"
            />
            <strong :class="$style.value">{{ selectedQuiz.user_count }}</strong>
            <span :class="$style.info">Number of Students</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_ASSIGNMENT_QUIZ_STATS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import TransitionExpand from '@/components/common/TransitionExpand/TransitionExpand';

const moduleShortRegexp = /Module\s*(?<moduleNumber>\d)*/i;

export default {
  name: 'Type1Header',
  components: {
    InlineLoader,
    TransitionExpand
  },
  props: {
    assignment: {
      type: Object,
      default: () => {}
    },
    nowISO: {
      type: String,
      required: true
    },
    leadSession: {
      type: Object
    }
  },
  data() {
    return {
      quizStats: {},
      fetchingStats: undefined,
      selectedQuizId: undefined,
      expandedQuizId: undefined
    };
  },
  computed: {
    ...mapGetters(['isMobileLayout', 'isCurrentUserStudent']),
    ...mapGetters('grading', ['assignmentId']),
    selectedQuiz() {
      if (!this.hasQuizStats) return {};
      if (!Array.isArray(this.quizStats)) return {};

      const found = this.quizStats.find(
        quiz => quiz.quiz_id === this.selectedQuizId
      );

      const quiz = found ? found : this.quizStats[0];

      return quiz;
    },
    cohortName() {
      if (!this.leadSession) return '';
      if (!this.leadSession.cohort) return '';
      if (!this.leadSession.cohort.name) return '';

      return this.leadSession.cohort.name;
    },
    moduleName() {
      if (!this.leadSession) return '';
      if (!this.leadSession.module) return '';
      if (!this.leadSession.module.name) return '';

      // const found = this.leadSession.module.name.match(moduleShortRegexp);

      // if (found) {
      //   return `M${found.groups.moduleNumber}`;
      // }

      return this.leadSession.module.name;
    },
    hasQuizStats() {
      if (this.isCurrentUserStudent) return false;

      return Object.keys(this.quizStats).length > 0;
    }
  },
  methods: {
    ...mapActions('grading', [GET_ASSIGNMENT_QUIZ_STATS]),
    selectQuizStat(quizId) {
      this.selectedQuizId = quizId;
    },
    expandQuizStat(quizId) {
      if (this.expandedQuizId === quizId) {
        this.expandedQuizId = undefined;
      } else {
        this.expandedQuizId = quizId;
      }
    },
    initQuizSelection() {
      if (this.hasQuizStats && this.quizStats.length >= 1) {
        const foundQuizId = this.quizStats[0] && this.quizStats[0].quiz_id;
        if (foundQuizId) {
          this.selectedQuizId = foundQuizId;
        }
      }
    },
    isExpanded(quizId) {
      return this.expandedQuizId === quizId;
    }
  },
  async created() {
    try {
      this.fetchingStats = true;
      this.quizStats = await this.GET_ASSIGNMENT_QUIZ_STATS(this.assignmentId);

      this.initQuizSelection();
      this.fetchingStats = false;
    } catch (error) {
      this.fetchingStats = false;
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  }
};
</script>

<style lang="scss" module>
@import '../styles/Grading.scss';
@import '../styles/Header.scss';
</style>
