<template>
  <div :class="$style.wrapper" class="box-shadow-white">
    <div :class="$style.wrapperScroll">
      <CircleLoader
        v-if="isLoading"
        :radius="60"
        :strokeWidth="6"
        :class="$style.loading"
      >
        <span>Loading ...</span>
      </CircleLoader>
      <div v-else :class="$style.wrapperCalendar">
        <div :class="$style.columns">
          <div
            v-for="(week, idx) in splitToWeeks"
            :key="idx"
            :class="$style.column"
            :id="'id-week-' + week"
          >
            <div
              :class="{
                [$style.dateHeader]: true,
                [$style.currentWeekHeader]: checkForCurrentWeek(week)
              }"
            >
              <h6
                v-visible="
                  idx == 0 ||
                    returnYear(week) != returnYear(splitToWeeks[idx - 1])
                "
                :class="$style.year"
              >
                {{ returnYear(week) }}
              </h6>
              <p
                v-visible="
                  idx == 0 ||
                    returnMonth(week) != returnMonth(splitToWeeks[idx - 1])
                "
                class="bold"
                :class="$style.month"
              >
                {{ returnMonth(week) }}
              </p>
              <div :class="$style.week">{{ returnDay(week) }}</div>
            </div>
          </div>
        </div>
        <div :class="$style.calendar">
          <!-- main calendar -->
          <div :class="$style.cohorts">
            <div
              v-for="(cohort, i) in overviews"
              :key="i"
              :class="$style.cohort"
            >
              <router-link
                :to="{
                  name: 'schedule-module-version-detail',
                  params: {
                    moduleVersionId: cohort.module.module_version_id
                  },
                  query: { asCohort: cohort.cohort.cohort_id }
                }"
                ><i class="icon icon-calendar"
              /></router-link>
              <router-link
                :to="{
                  name: 'admin-cohort-detail',
                  params: { cohortId: cohort.cohort.cohort_id }
                }"
                >{{ cohort.cohort.name }}</router-link
              >

              <p :class="$style.moduleName">{{ cohort.module.name }}</p>
            </div>
          </div>
          <div :class="$style.scrollWrapper">
            <div :class="$style.modules">
              <div v-for="(week, weekIdx) in splitToWeeks" :key="weekIdx">
                <div
                  v-for="(cohort, cohortIdx) in overviews"
                  :key="cohortIdx"
                  :class="{
                    [$style.cell]: true,
                    [$style.currentWeek]: checkForCurrentWeek(week)
                  }"
                >
                  <div
                    v-if="
                      returnOverview(week, cohort, weekIdx) &&
                        returnOverview(week, cohort, weekIdx)
                    "
                    :class="{
                      [$style.module]: true,
                      [$style.study]:
                        returnOverview(week, cohort, weekIdx).type ===
                        CYCLE_TYPES.study,
                      [$style.onboarding]:
                        returnOverview(week, cohort, weekIdx).type ===
                        CYCLE_TYPES.onboarding,
                      [$style.clinicalRotation]:
                        returnOverview(week, cohort, weekIdx).type ===
                        CYCLE_TYPES.cr,
                      [$style.tail]: returnOverview(week, cohort, weekIdx).tail
                    }"
                  ></div>
                  <div v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.map">
      <div><span :class="$style.studySquare" /> Study</div>
      <div><span :class="$style.crSquare" /> Clinical rotation</div>
      <div><span :class="$style.onboardingSquare" /> Onboarding</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { sortingByDate } from '@/helpers/common';
import { FROM_OLDEST } from '@/config/dates';

import { CYCLE_TYPES } from '@/config/schedule';
import { GET_ADMIN_OVERVIEW } from '@/store/actions.type';

import CircleLoader from '@/components/common/CircleLoader/CircleLoader';

export default {
  components: {
    CircleLoader
  },
  data() {
    return {
      overviews: [],
      filteredOverviews: [],
      isLoading: true
    };
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
    this.fetchData();
  },
  directives: {
    visible: {
      inserted: function(el, binding) {
        el.style.visibility = !!binding.value ? 'visible' : 'hidden';
      }
    }
  },
  computed: {
    splitToWeeks() {
      if (!this.filteredOverviews || !this.filteredOverviews.length) return [];

      var interval_start = moment(this.filteredOverviews[0].start).startOf(
        'week'
      );
      var interval_end = moment(
        this.filteredOverviews[this.filteredOverviews.length - 1].end
      );

      let tmp = interval_start.clone().day(1);

      var weekStarts = [];

      if (tmp.isAfter(interval_start, 'd')) {
        weekStarts.push(tmp.format('YYYY-MM-DD'));
      }

      while (tmp.isBefore(interval_end)) {
        tmp.add(7, 'days');
        weekStarts.push(tmp.format('YYYY-MM-DD'));
      }

      return weekStarts;
    }
  },
  methods: {
    checkForCurrentWeek(week) {
      if (
        moment().isBetween(
          moment(week),
          moment(week).add(1, 'weeks'),
          'days',
          '[)'
        )
      ) {
        this.scrollIntoView(week);
        return true;
      }
    },
    scrollIntoView(week) {
      this.$nextTick(() => {
        document.querySelector('#id-week-' + week).scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      });
    },
    returnYear(date) {
      return moment(date).format('YYYY');
    },
    returnMonth(date) {
      return moment(date).format('MM');
    },
    returnDay(date) {
      return moment(date).format('DD');
    },
    fetchData() {
      this.isLoading = true;

      this.$store
        .dispatch(GET_ADMIN_OVERVIEW)
        .then(overviews => {
          this.overviews = overviews;
        })
        .then(() => {
          this.assignEventsToDays();
          this.splitToWeeks;
        })
        .then(() => {
          this.isLoading = false;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    assignEventsToDays() {
      this.overviews.forEach((cohort, idx) => {
        sortingByDate(
          Array.isArray(cohort.details) ? cohort.details : [],
          'start_date',
          FROM_OLDEST
        );

        cohort.details.forEach((week, i) => {
          this.filteredOverviews.push({
            cohort: cohort.cohort.name,
            module: cohort.module.name,
            type: week.phase_type,
            start: moment(week.start_date),
            end: moment(week.end_date),
            tail: i > 0 && week.phase_type === cohort.details[i - 1].phase_type
          });

          this.filteredOverviews = sortingByDate(
            Array.isArray(this.filteredOverviews) ? this.filteredOverviews : [],
            'start',
            FROM_OLDEST
          );
        });
      });
    },
    returnOverview(week, cohort, weekIdx) {
      return this.filteredOverviews.find((overview, i) => {
        if (
          overview.module === cohort.module.name &&
          overview.cohort === cohort.cohort.name &&
          overview.start.isSameOrAfter(week) &&
          overview.start.isBefore(this.splitToWeeks[weekIdx + 1])
        ) {
          return overview;
        }
      });
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminOverviewTable.scss';
</style>
