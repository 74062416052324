<template>
  <div :class="$style.heading">
    <a v-if="!isAtTop" :class="$style.backArrow" @click="$emit('navigate_up')"
      ><img v-svg-inline :src="require(`@/assets/images/icons/nav_arrow.svg`)"
    /></a>
    <span v-else></span>
    <p>
      Contacts
    </p>
    <FormButton
      :class="$style.startConversation"
      @click="onConversationInit"
      :standard="true"
      :disabled="isReadyToStart ? null : true"
      >Start</FormButton
    >
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  SET_PARTICIPANTS,
  SET_USERS_SEARCH_RESULTS
} from '@/store/mutations.type';
import { CONVERSATION_TYPES } from '@/config/conversations';

export default {
  name: 'ConversationNewHeaderMobile',
  computed: {
    ...mapGetters(['currentUserId', 'userProfiles', 'currentUserProfile']),
    ...mapGetters('chats', [
      'conversationsWithParticipants',
      'isReadyToStart',
      'selectedParticipantIds'
    ]),
    isAtTop() {
      return this.$route.name === 'conversations-list';
    },
    participantProfiles() {
      if (!this.userProfiles) return [];

      return this.selectedParticipantIds.map(id =>
        this.userProfiles.find(user => id == user.user_id)
      );
    }
  },
  methods: {
    ...mapMutations('chats', [SET_PARTICIPANTS, SET_USERS_SEARCH_RESULTS]),
    onConversationInit() {
      if (this.selectedParticipantIds.length === 0) return;

      const foundConversations = this.conversationsWithParticipants([
        this.currentUserId,
        ...this.selectedParticipantIds
      ]);

      if (foundConversations.length === 0) {
        this.$emit('init_new', {
          type: CONVERSATION_TYPES.ADHOC,
          participant_ids: [...this.selectedParticipantIds, this.currentUserId],
          participants: [...this.participantProfiles, this.currentUserProfile]
        });

        this.SET_PARTICIPANTS([]);
        this.SET_USERS_SEARCH_RESULTS([]);
      } else {
        this.$emit('init_existing', foundConversations[0].conversation_id);
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationsHeaderMobile.scss';
</style>
