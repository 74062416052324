var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('h6',[_vm._v(" Manage users in this group: ")]),_c('p',{staticClass:"placeholder",class:_vm.$style.notice},[_vm._v(" Carefully! Removing or adding a new user is not an operation that requires approval! ")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
      enabled: true,
      initialSortBy: _vm.loadUrlWithSorting()
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 10,
      position: 'bottom'
    }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'removeMember')?_c('span',{on:{"click":function($event){return _vm.handleModalOpened(props.row.memberId)}}},[_c('i',{staticClass:"icon icon-close",class:_vm.$style.closeIcon})]):(props.column.field === 'memberId')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.memberId }
          }}},[_vm._v(" "+_vm._s(props.row.memberId)+" ")])],1):(props.column.field === 'memberFirstName')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.memberId }
          }}},[_vm._v(" "+_vm._s(props.row.memberFirstName)+" ")])],1):(props.column.field === 'memberLastName')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'admin-user-detail',
            params: { userId: props.row.memberId }
          }}},[_vm._v(" "+_vm._s(props.row.memberLastName)+" ")])],1):_vm._e()]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('AdminSearchNewUser',{attrs:{"addUserGroupId":_vm.userGroupId,"eligibleUsersGroupId":_vm.userGroupId},on:{"reloadMembers":_vm.reloadMembers}})],1)]),_c('ConfirmationModal',{attrs:{"question":_vm.removeMemberQuestion,"showModal":_vm.showModal,"optionOne":'Remove',"optionOneStyle":'red'},on:{"handleClosed":_vm.handleModalClosed,"agreedAction":function($event){return _vm.removeMember(_vm.approveID)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }