<template>
  <article
    :class="{
      [$style.wrapper]: true,
      [$style.deleted]: comment.deleted_at ? true : false,
      [$style.isNew]: isNew ? true : false
    }"
    class="message"
  >
    <router-link
      v-if="comment && comment.user && comment.user.user_id"
      :to="{
        name: 'user-profile',
        params: {
          userId: comment.user.user_id
        }
      }"
      class="user-name"
    >
      <Avatar :user="comment.user" size="36px" :class="$style.avatar" />
    </router-link>

    <Avatar v-else :user="comment.user" size="36px" :class="$style.avatar" />

    <strong :class="$style.author">
      <router-link
        v-if="comment && comment.user && comment.user.user_id"
        :to="{
          name: 'user-profile',
          params: {
            userId: comment.user.user_id
          }
        }"
        class="user-name"
      >
        <UserName
          :user="comment.user"
          :preTitles="true"
          :postTitles="true"
          class="user-name"
        />
      </router-link>

      <UserName
        v-else
        :user="comment.user"
        :preTitles="true"
        :postTitles="true"
        class="user-name"
      />
    </strong>

    <small :class="$style.since">
      {{ formatCreatedAt(comment.created_at) }}
    </small>

    <span></span>

    <div
      :class="$style.actionsMenuContainer"
      v-if="comment.user.user_id === currentUserId"
    >
      <button
        class="textonly"
        @click="flipActionsMenu"
        :class="$style.actionsMenuSwitch"
        v-click-outside="closeActionsMenu"
        data-cy="menu-switch"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          width="16"
          height="16"
        >
          <path
            d="M9.5 8a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm0 6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm0-13a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"
          />
        </svg>
      </button>

      <div
        v-if="shouldDisplayActionsMenu"
        :class="$style.actionsMenu"
        class="box-shadow-medium"
      >
        <button
          @click="confirmDelete(comment)"
          v-if="comment.user.user_id === currentUserId"
          class="textonly"
          data-cy="action-delete"
        >
          <i class="icon icon-close2"></i> Delete
        </button>
      </div>
    </div>

    <span
      v-html="comment.content.replaceAll('\n', '<br />')"
      :class="$style.content"
    />
    <footer :class="$style.actions">
      <button
        class="textonly"
        :class="$style.replyLink"
        @click="replyTo(comment)"
        :data-cy="`reply-to-${comment.post_id}`"
      >
        Reply
      </button>

      <Reactions
        :comment="comment"
        @new_reaction="$emit('new_reaction', $event)"
        @add_reaction="$emit('add_reaction', $event)"
        @remove_reaction="$emit('remove_reaction', $event)"
      />
    </footer>

    <ModalNew
      :showModal="shouldDisplayDeleteModal"
      v-transfer-dom
      :closeWithClickOutside="true"
      @close="closeDeleteModal"
      :class="$style.booleanModal"
    >
      <strong>Delete comment?</strong>
      <p>Are you sure you want to delete this comment?</p>
      <div :class="$style.confirmation">
        <button
          class="btn ghost"
          @click="closeDeleteModal"
          data-cy="modal-cancel"
        >
          Cancel
        </button>
        <button
          class="btn standard"
          @click="deleteComment(comment)"
          data-cy="modal-confirm"
        >
          Delete
        </button>
      </div>
    </ModalNew>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import User from '@/components/common/User/User';
import Avatar from '@/components/common/User/Avatar';
import Reactions from './Reactions';
import { emojisDefault } from '@/components/common/EmojiPickerCustom/emojis.js';

import ClickOutside from 'vue-click-outside';

export default {
  name: 'Comment',
  directives: {
    ClickOutside
  },
  components: {
    Avatar,
    Reactions
  },
  data() {
    return {
      shouldDisplayPicker: false,
      shouldDisplayActionsMenu: false,
      shouldDisplayDeleteModal: false
    };
  },
  props: {
    comment: {
      type: Object
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentUserId'])
  },
  methods: {
    formatCreatedAt(createdAt) {
      return moment(createdAt).fromNow();
    },
    replyTo(comment) {
      this.$emit('reply_to', comment);
    },
    getEmojiTitle(emojiData) {
      if (!emojisDefault) return '';

      const found = emojisDefault.find(emoji => emoji.data === emojiData);

      return found
        ? found.aliases.map(alias => alias.replace('_', ' ')).join(', ')
        : '';
    },
    deleteComment(comment) {
      this.closeDeleteModal();
      this.$emit('delete_comment', comment);
    },
    flipActionsMenu() {
      this.shouldDisplayActionsMenu = !this.shouldDisplayActionsMenu;
    },
    closeActionsMenu() {
      this.shouldDisplayActionsMenu = false;
    },
    confirmDelete(comment) {
      this.shouldDisplayDeleteModal = true;
    },
    closeDeleteModal() {
      this.shouldDisplayDeleteModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './Comment.scss';
</style>
