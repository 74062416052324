<template>
  <span
    :class="{ [$style.wrapper]: true, [$style.active]: hasFocusOrContent }"
    class="reply"
  >
    <textarea
      cols="30"
      :rows="hasFocusOrContent ? 3 : 1"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onInput($event.target.value)"
      :class="$style.content"
      @focus="onFocus"
      data-cy="comment-input"
    >
    </textarea>

    <button
      v-if="hasFocusOrContent"
      @click="onCancel"
      :class="$style.cancel"
      class="textonly"
      data-cy="comment-cancel"
    >
      Cancel
    </button>

    <FormButton
      :standard="true"
      @click="onSubmit"
      :class="$style.submit"
      :disabled="!hasFocusOrContent"
      data-cy="comment-submit"
    >
      Post
    </FormButton>
  </span>
</template>

<script>
export default {
  name: 'CommentReply',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    focused: {
      type: Boolean,
      default: false
    },
    modelValue: String
  },
  data() {
    return {
      textareaHasFocus: this.focused
    };
  },
  computed: {
    hasFocusOrContent() {
      return this.textareaHasFocus ? true : false;
    }
  },
  mounted() {
    if (this.focused) {
      const commentContent = this.$el.querySelector('textarea');
      commentContent.focus();
    }
  },
  methods: {
    onSubmit() {
      const commentContent = this.$el.querySelector('textarea');

      if (!commentContent.value) return;

      this.$emit('submit', commentContent.value);

      this.textareaHasFocus = false;
      commentContent.value = '';
    },
    onInput(value) {
      this.$emit('update:modelValue', value);
    },
    onCancel() {
      const commentContent = this.$el.querySelector('textarea');

      this.textareaHasFocus = false;
      commentContent.value = '';

      this.$emit('cancel');
    },
    onFocus() {
      this.textareaHasFocus = true;
    }
  }
};
</script>

<style lang="scss" module>
@import './CommentReply.scss';
</style>
