export const STATES = {
  idle: 'idle',
  typing: 'typing',
  in_action: 'in_action',
  success: 'success',
  error: 'error'
};

// How long should we wait for further input from user before executing the action
export const DEBOUNCE_INPUT_DELAY = 2000; // ms
export const DEBOUNCE_CHANGE_DELAY = 0; // ms

// Display loader if action takes longer than this to complete
export const ACTION_COMPLETE_TIMEOUT = 200; // ms

// How long should we wait before hiding success indicator?
// -1 = never
export const CLEAR_SUCCESS_AFTER = 5000; // ms

// How long should we wait before hiding error indicator?
// -1 = never
export const CLEAR_ERROR_AFTER = 60000; // ms
