<template>
  <div
    :class="{
      [$style.gridContainer]: true,
      [$style.layoutConversations]: true
    }"
  >
    <header
      :class="{
        [$style.pageHeader]: true,
        [$style.unstick]: userSettingValue(SETTING_CODES.FIXED_NAV) === true
      }"
    >
      <slot name="pageHeader">
        <PageHeader :hideNavigationItems="hideNavigationItems" />
      </slot>
    </header>
    <section :class="$style.pageServiceArea">
      <slot name="pageServiceArea">
        <AnnouncementNotice
          v-if="announcement && announcement.content"
          data-ref="globalAnnouncement"
          :message="announcement.content"
          :slug="announcement.slug"
        />

        <ErrorNotice
          v-if="globalErrorClientMessage"
          data-ref="globalError"
          :message="globalErrorClientMessage"
        />

        <Notice
          v-if="hasNewerVersion"
          data-ref="globalNotice"
          type="info"
          :dismissable="false"
        >
          <template #icon>
            <i class="icon icon-info" @click="showVersions = !showVersions" />
          </template>
          <template #default>
            New version of the platform is available. Please reload this page.
            <p v-if="showVersions">
              {{ versions }}
            </p>
          </template>
        </Notice>
      </slot>
    </section>
    <header :class="$style.mobileHeader">
      <slot name="mobileHeader"></slot>
    </header>
    <content :class="$style.mobileContent" class="box-shadow-white">
      <slot name="mobileContent"></slot>
    </content>
    <footer :class="$style.mobileFooter">
      <slot name="mobileFooter"></slot>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SETTING_CODES } from '@/config/userSettings';
import { NEWER_VERSION_EVENT } from '@/config/tracking';

import Notice from '@/components/notices/Notice/Notice';
import ErrorNotice from '@/components/notices/ErrorNotice/ErrorNotice';
import AnnouncementNotice from '@/components/notices/AnnouncementNotice/AnnouncementNotice';

import PageHeader from '@/components/common/PageHeader/PageHeader';

export default {
  name: 'LayoutConversationsMobile',
  components: {
    PageHeader,
    AnnouncementNotice,
    ErrorNotice,
    Notice
  },
  props: {
    hideNavigationItems: {
      type: Boolean
    }
  },
  data() {
    return {
      hasNewerVersion: false,
      showVersions: false,
      versions: {
        runningVersion: undefined,
        availableVersion: undefined
      }
    };
  },
  computed: {
    ...mapGetters([
      'globalErrorClientMessage',
      'userSettingValue',
      'announcements'
    ]),
    announcement() {
      return this.announcements && this.announcements.length
        ? this.announcements[0]
        : undefined;
    }
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;

    this.$bus.$on(NEWER_VERSION_EVENT, versions => {
      this.hasNewerVersion = true;
      this.versions = versions;
    });
  }
};
</script>

<style lang="scss" module>
@import '~@/assets/css/generated-variables.scss';
@import '~@/assets/css/media.scss';

// Import shared grid defaults
@import './Layout.scss';

.gridContainer {
  grid-row-gap: 0;
  min-height: unset;
}

.mobileHeader {
  grid-area: mobileHeader;
  margin: 0 14px;
}

.contentSubHeader {
  grid-area: contentSubHeader;
  margin: 0 14px;
}

.mobileContent {
  grid-area: mobileContent;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 14px;
  padding-bottom: 20px;
}

.mobileFooter {
  grid-area: mobileFooter;
  margin: 14px;
}

.gridContainer.layout-conversations {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas:
    'pageHeader'
    'pageServiceArea'
    'mobileHeader'
    'mobileContent'
    'mobileFooter';
}
</style>
