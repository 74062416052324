import moment from 'moment';
import { API_SERVER_DATE_FORMAT } from '@/config/dates';

// Tests should not see deprecation warnigs
moment.suppressDeprecationWarnings = ['test', 'e2e'].includes(
  process.env.NODE_ENV
)
  ? true
  : false;

/**
 * Format any date in any locale for use in API calls.
 * @param {Object} moment object
 */
export const toServerFormat = maybeMoment => {
  const momentClone = moment(maybeMoment);

  if (!(momentClone instanceof moment) || !momentClone.isValid()) {
    return '';
  }

  return momentClone.locale('en').format(API_SERVER_DATE_FORMAT);
};

/**
 * Pretty date range format.
 * Ex.: 12.10.2019 - 13.10.2019.
 * @param {String} startDate ISO8601 start date
 * @param {String} endDate ISO8601 end date
 */
export const formatDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return '';
  }

  const start = moment(startDate);
  const end = moment(endDate);

  if (!start.isValid() || !end.isValid()) {
    return '';
  }

  return `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`;
};

/**
 * Pretty time range format.
 * Ex.: 9:30 - 11:00
 * @param {String} startTime ISO8601
 * @param {String} endTime ISO8601
 */
export const formatTimeRange = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return '';
  }

  const start = moment(startTime);
  const end = moment(endTime);

  if (!start.isValid() || !end.isValid()) {
    return '';
  }

  return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
};

/**
 * Decides if the first dateISOString
 * is before the second dateISOString
 * Returns boolean
 */
export const isBefore = function(dateISOString, currentTime) {
  const periodStart = moment(dateISOString);
  const now = moment(currentTime);

  return (
    periodStart.isValid() && now.isValid() && periodStart.isSameOrBefore(now)
  );
};
