<template>
  <div :class="$style.wrapper">
    <div :class="$style.inputWrapper">
      <div :class="$style.visibleInputWrapper">
        <ul v-if="selectedUserProfile" :class="$style.selectedItems">
          <li @click.stop="removeItem">
            <Avatar size="25px" :user="selectedUserProfile" />
            <UserName :preTitles="true" :user="selectedUserProfile" />
          </li>
        </ul>
        <ul v-else-if="selectedCohort" :class="$style.selectedItems">
          <li @click.stop="removeItem">
            {{ selectedCohort.name }}
          </li>
        </ul>
        <FormInput
          v-else
          data-ref="search"
          :large="true"
          v-model="searchTerm"
          type="text"
          :placeholder="placeholder"
          :disabled="readOnly"
        />
      </div>

      <div
        v-if="showDropdown"
        :class="$style.dropdown"
        class="box-shadow-medium"
        v-click-outside="hideDropdown"
      >
        <ItemSelect
          :class="$style.ItemSelect"
          :items="items"
          :groups="groups"
          :searchRegExp="searchRegExp"
          :searchTerm="searchTerm"
          :isSearching="isSearching"
          :isTyping="isTyping"
          @select="onItemSelect"
        />
      </div>
      <div :class="{ [$style.loader]: true, [$style.on]: isSearching }"></div>
      <i
        v-if="selectedCohort || selectedUserProfile"
        :class="$style.inputIcon"
        class="icon icon-trash-o cursor-pointer"
        @click.stop="removeItem"
      />
      <i v-else :class="$style.inputIcon" class="icon icon-search" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import {
  SEARCH_USER_PROFILES,
  SEARCH_COHORTS,
  GET_USER_PROFILE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import focusMixin from '@/mixins/focusMixin';
import { SUGGESTION_GROUPS, SUGGESTION_TYPES } from '@/config/common';

import ClickOutside from 'vue-click-outside';
import ItemSelect from './ItemSelect/ItemSelect';
import Avatar from '@/components/common/User/Avatar';

const DEBOUNCE_WAIT_MS = 500;
const MIN_SEARCH_TERM_LENGTH = 2;

export default {
  name: 'SingleItemSuggest',
  directives: {
    ClickOutside
  },
  components: {
    ItemSelect,
    Avatar
  },
  mixins: [focusMixin],
  props: {
    userId: {
      type: Number,
      default: NaN
    },
    cohortId: {
      type: Number,
      default: NaN
    },
    allowedSuggestionTypes: {
      type: Array,
      default: () => [SUGGESTION_TYPES.user]
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: '',
      users_search_results: [],
      cohorts_search_results: [],
      isSearching: false,
      isTyping: false,
      searchRegExp: undefined,
      showItemSelect: false
    };
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'userProfiles',
      'currentUserId',
      'currentUserProfile',
      'currentUserCohorts'
    ]),
    showDropdown() {
      if (this.readOnly) return false;

      // We're supposed to show what is the API searching for in a dropdown before results come back
      if (this.isSearching) return true;

      if (this.showItemSelect) return true;

      return false;
    },
    users() {
      if (!Array.isArray(this.users_search_results)) return [];

      const usersExclCurrent = this.users_search_results.filter(
        user => user.user_id !== this.currentUserId
      );

      return usersExclCurrent;
    },
    cohorts() {
      if (!Array.isArray(this.cohorts_search_results)) return [];

      return this.cohorts_search_results;
    },
    items() {
      return [...this.cohorts, ...this.users];
    },
    groups() {
      return SUGGESTION_GROUPS;
    },
    selectedUserProfile() {
      if (!this.userProfiles) return undefined;

      return this.userProfiles.find(user => user.user_id == this.userId);
    },
    selectedCohort() {
      if (!this.currentUserCohorts) return undefined;

      return this.currentUserCohorts.find(
        cohort => cohort.cohort_id == this.cohortId
      );
    },
    placeholder() {
      if (
        this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.user) &&
        this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.cohort)
      ) {
        return 'Search for a cohort or a user';
      }

      if (
        this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.user) &&
        !this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.cohort)
      ) {
        return 'Search for a user';
      }

      if (
        !this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.user) &&
        this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.cohort)
      ) {
        return 'Search for a cohort';
      }

      return 'Search for a user';
    }
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (!newSearchTerm || newSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
        this.searchRegExp = undefined;
        this.users_search_results = [];
        this.cohorts_search_results = [];
        this.setDropdownVisibility(false);
        this.isTyping = false;
      }

      if (newSearchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
        this.isTyping = true;
        this.searchRegExp = new RegExp(newSearchTerm, 'ig');
        this.searchItems(newSearchTerm);
        this.setDropdownVisibility(true);
      }
    }
  },
  async created() {
    try {
      await this.maybeFetchUserProfile(this.userId);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, {
        error,
        log: true,
        clientMessage: error
      });
    }
  },
  methods: {
    maybeFetchUserProfile(userId) {
      // If no userId provided do nothing
      if (!userId) return Promise.resolve();

      const found = this.userProfiles.find(user => user.user_id === userId);

      if (found) {
        return Promise.resolve(found);
      } else {
        return this.$store.dispatch(GET_USER_PROFILE, userId);
      }
    },
    async onItemSelect(itemObject) {
      this.searchTerm = '';

      if (itemObject.code === SUGGESTION_TYPES.user) {
        try {
          await this.maybeFetchUserProfile(itemObject.id);
        } catch (error) {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        }

        this.$emit('selected_user_id', itemObject.id);
      }

      if (itemObject.code === SUGGESTION_TYPES.cohort) {
        this.$emit('selected_cohort_id', itemObject.id);
      }
    },
    removeItem() {
      this.$emit('cleared');
    },

    searchItems: debounce(function(searchTerm) {
      this.isTyping = false;

      // Abort when searchTerm is empty
      if (!searchTerm.length) return;

      this.isSearching = true;

      let promises = {};

      if (this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.user)) {
        promises[SUGGESTION_TYPES.user] = this.$store.dispatch(
          SEARCH_USER_PROFILES,
          {
            inactive: true,
            name: searchTerm
          }
        );
      }

      if (this.allowedSuggestionTypes.includes(SUGGESTION_TYPES.cohort)) {
        promises[SUGGESTION_TYPES.cohort] = this.$store.dispatch(
          SEARCH_COHORTS,
          {
            inactive: false,
            name: searchTerm
          }
        );
      }

      // Abort when no promises found
      if (!Object.keys(promises).length) return;

      Promise.all(Object.values(promises))
        .then(results => {
          const promiseKeys = Object.keys(promises);

          let usersResults = [];
          let cohortsResults = [];

          const userResultsIndex = promiseKeys.findIndex(
            k => k === SUGGESTION_TYPES.user
          );

          if (userResultsIndex >= 0) {
            usersResults = results[userResultsIndex].map(u => {
              return { ...u, ...{ item_type: SUGGESTION_TYPES.user } };
            });
          }

          const cohortResultsIndex = promiseKeys.findIndex(
            k => k === SUGGESTION_TYPES.cohort
          );

          if (cohortResultsIndex >= 0) {
            cohortsResults = results[cohortResultsIndex].map(c => {
              return { ...c, ...{ item_type: SUGGESTION_TYPES.cohort } };
            });
          }

          if (usersResults.length === 0 && cohortsResults.length === 0) {
            this.$emit('has_results', false);
          } else {
            this.users_search_results = usersResults;
            this.cohorts_search_results = cohortsResults;
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, {
            error,
            log: true,
            clientMessage: error
          });
        })
        .then(() => {
          this.isSearching = false;
        });
    }, DEBOUNCE_WAIT_MS),
    setDropdownVisibility(newValue) {
      this.showItemSelect = newValue;
    },
    hideDropdown() {
      this.setDropdownVisibility(false);
    }
  }
};
</script>

<style lang="scss" module>
@import './SingleItemSuggest.scss';
</style>
