// This plugin adds another instance of Vue
// as an event bus and makes it available under this.$bus in every component
import mitt from 'mitt';

export const Emitter = mitt();

export default {
  install(Vue) {
    Vue.prototype.$bus = {
      $all: Emitter.all,
      $on: Emitter.on,
      $off: Emitter.off,
      $emit: Emitter.emit
    };
  }
};
