<template>
  <span :class="$style.wrapper" :title="categoryName">
    <img
      v-svg-inline
      v-if="isValidMedicalSubjectCode(category)"
      :src="
        require(`@/assets/images/icons/medical-subjects/${
          ICON_CODES[category.category_id]
        }.svg`)
      "
    />
    <img
      v-svg-inline
      v-else
      :src="require(`@/assets/images/icons/medical-subjects/none.svg`)"
    />
  </span>
</template>

<script>
const ICON_CODES = {
  6: 'anatomy',
  11: 'biology',
  73: 'clinical_rotation',
  134: 'clinical_medicine',
  69: 'collaborator',
  68: 'communicator',
  14: 'dermatology',
  7: 'ebm',
  84: 'ebm',
  75: 'embryology',
  4: 'emergency_medicine_anesthesia',
  8: 'emergency_medicine_anesthesia',
  93: 'emergency_medicine_anesthesia',
  24: 'ent',
  135: 'elective',
  22: 'general_practice',
  76: 'general_practice',
  132: 'general_practice',
  25: 'gynaecology',
  78: 'gynaecology',
  94: 'health_advocate',
  57: 'human_genetics',
  54: 'hygiene',
  82: 'hygiene',
  87: 'hygiene',
  91: 'hygiene',
  127: 'hygiene',
  129: 'hygiene',
  15: 'internal_medicine',
  62: 'laboratory',
  122: 'laboratory',
  43: 'laboratory',
  92: 'leader',
  83: 'medical_english',
  53: 'medical_ethics',
  59: 'medical_ethics',
  81: 'medical_ethics',
  90: 'medical_expert',
  35: 'medical_sociology',
  42: 'medical_sociology',
  80: 'medical_sociology',
  71: 'mentoring',
  13: 'microbiology',
  72: 'microbiology',
  124: 'microbiology',
  18: 'neurology',
  89: 'onboarding',
  133: 'oncology_palliative_care',
  105: 'ophthalmology',
  38: 'pathology',
  26: 'pediatrics',
  27: 'pharmacology',
  47: 'pharmacology',
  77: 'pharmacology',
  125: 'pharmacology',
  2: 'physics_chemistry_biochemistry',
  33: 'physics_chemistry_biochemistry',
  34: 'physics_chemistry_biochemistry',
  70: 'physics_chemistry_biochemistry',
  79: 'physics_chemistry_biochemistry',
  123: 'physics_chemistry_biochemistry',
  10: 'physiology',
  21: 'physiology',
  9: 'practical_procedures_skills',
  95: 'professional',
  39: 'psychiatry',
  74: 'psychiatry',
  55: 'research_track',
  67: 'scholar',
  63: 'srl',
  19: 'surgery',
  17: 'traumatology',
  104: 'traumatology',
  20: 'urology',
  121: 'nursing_skills',
  115: 'professionalism',
  128: 'professionalism',
  16: 'aging',
  109: 'laboratory',
  110: 'pharmacology',
  111: 'physics_chemistry_biochemistry',
  112: 'aging',
  126: 'aging',
  113: 'hygiene',
  114: 'psychiatry',
  116: 'general_practice',
  117: 'research_track',
  118: 'traumatology',
  119: 'physics_chemistry_biochemistry',
  120: 'medical_ethics'
};

export default {
  name: 'LearningUnitCategoryIcon',
  props: ['category'],
  computed: {
    categoryName() {
      if (!this.category) return '';

      if (!this.category.name) return '';

      return this.category.name;
    }
  },
  methods: {
    isValidMedicalSubjectCode(category) {
      if (!category) return false;

      if (!category.category_id) return false;

      return Object.keys(this.ICON_CODES).includes(`${category.category_id}`);
    }
  },
  created() {
    this.ICON_CODES = ICON_CODES;
  }
};
</script>

<style lang="scss" module>
@import './LearningUnitCategoryIcon.scss';
</style>
