// common actions that can be re-used from different places,
// this module is part of our new store schema (in progress)
// will be probably renamed to "common" but this name is reserved now
// for data that will be moved to different places in the future

import {
  GET_DISCUSSION_CATEGORIES,
  GET_DISCUSSION_CATEGORY_FORUMS,
  GET_DISCUSSION_FORUM,
  GET_DISCUSSION_FORUM_TOPICS,
  CREATE_NEW_DISCUSSION_FORUM_TOPIC,
  GET_DISCUSSION_TOPIC,
  SUBSCRIBE_DISCUSSION_TOPIC,
  UNSUBSCRIBE_DISCUSSION_TOPIC,
  GET_DISCUSSION_TOPIC_POSTS,
  SEND_DISCUSSION_TOPIC_POST,
  GET_DISCUSSION_RECENT_POSTS,
  GET_TEAM_ROOM,
  GET_NEWROW_AUTH_TOKEN,
  GET_INTEGRATION_TOKENS,
  GET_COLLABORATION_SERVICES_TOKEN,
  GET_BLOG_POSTS,
  GET_BLOG_POST,
  GET_BLOG_POST_TAGS,
  GET_ASSESSMENT,
  GET_ASSESSMENTS,
  STORE_CURRENT_USER_CONSENTS,
  REPORT_ROUTE,
  REPORT_PROBLEM
} from './actions.type';

import ApiService from '@/api/api.service';

export const actions = {
  [GET_NEWROW_AUTH_TOKEN]() {
    return new Promise((resolve, reject) => {
      ApiService.getWebinarAuthToken()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_INTEGRATION_TOKENS](context, integrationName) {
    return new Promise((resolve, reject) => {
      ApiService.getIntegrationTokens(integrationName)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_COLLABORATION_SERVICES_TOKEN]() {
    return new Promise((resolve, reject) => {
      ApiService.getCollaborationServicesToken()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_TEAM_ROOM](context, newrow_context_id) {
    return new Promise((resolve, reject) => {
      ApiService.getTeamRoom(newrow_context_id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_CATEGORIES]() {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionCategories()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_CATEGORY_FORUMS](context, categorySlug) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionCategoryForums(categorySlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_FORUM](context, forumSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionForum(forumSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_FORUM_TOPICS](context, forumSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionForumTopics(forumSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CREATE_NEW_DISCUSSION_FORUM_TOPIC](
    context,
    { forumSlug, topicSubject, topicContent }
  ) {
    return new Promise((resolve, reject) => {
      ApiService.createDiscussionForumTopic(
        forumSlug,
        topicSubject,
        topicContent
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_TOPIC](context, topicSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionTopic(topicSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SUBSCRIBE_DISCUSSION_TOPIC](context, topicSlug) {
    return new Promise((resolve, reject) => {
      ApiService.subscribeDiscussionTopic(topicSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UNSUBSCRIBE_DISCUSSION_TOPIC](context, topicSlug) {
    return new Promise((resolve, reject) => {
      ApiService.unsubscribeDiscussionTopic(topicSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_TOPIC_POSTS](context, { topicSlug, after, limit }) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionTopicPosts(topicSlug, after, limit)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_DISCUSSION_RECENT_POSTS](context, limit) {
    return new Promise((resolve, reject) => {
      ApiService.getDiscussionRecentPosts(limit)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SEND_DISCUSSION_TOPIC_POST](context, { topicSlug, postContent }) {
    return new Promise((resolve, reject) => {
      ApiService.sendDiscussionTopicPost(topicSlug, postContent)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_BLOG_POSTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getBlogPosts(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_BLOG_POST](context, blogPostSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getBlogPost(blogPostSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_BLOG_POST_TAGS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getTags(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ASSESSMENTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getAssessments(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_ASSESSMENT](context, assessmentSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getAssessment(assessmentSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [STORE_CURRENT_USER_CONSENTS]() {
    return new Promise((resolve, reject) => {
      ApiService.storeCurrentUserConsents()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [REPORT_ROUTE](context, route) {
    return new Promise((resolve, reject) => {
      ApiService.reportRoute(route)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [REPORT_PROBLEM](context, debugInfo) {
    return new Promise((resolve, reject) => {
      ApiService.reportProblem(debugInfo)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  actions
};
