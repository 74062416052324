<template>
  <div style="display: contents;">
    <tr
      :class="{
        [$style.wrapper]: true,
        ['user-scope']: scope === SCOPES.user,
        ['group-scope']: scope === SCOPES.group,
        [$style.notAttended]: attendanceModel === false
      }"
    >
      <template v-if="inViewMode">
        <td
          v-if="columns.includes(COLUMNS.attendance)"
          :class="{
            [$style.columnAttendance]: true,
            [$style.missing]: attendanceGradeIsMissing
          }"
        >
          <FormThreeWaySwitchReadOnly
            :value="attendanceModel"
            :modelValue="attendanceModel"
            data-cy="attendance"
          />
        </td>
        <td
          v-if="columns.includes(COLUMNS.student)"
          :class="$style.columnStudent"
        >
          <User :user="user" size="25px" />
        </td>
        <td
          v-if="columns.includes(COLUMNS.score)"
          data-cy="score"
          :class="{
            [$style.columnScore]: true,
            [$style.missing]: scoreGradeIsMissing
          }"
        >
          {{ Number.isNaN(parseFloat(scoreModel)) ? '-' : scoreModel }}
        </td>
        <td
          v-if="columns.includes(COLUMNS.score)"
          data-cy="score-max"
          :class="$style.columnScoreMax"
        >
          {{
            Number.isNaN(parseFloat(scoreGradeMax)) ? '-' : `/${scoreGradeMax}`
          }}
        </td>
        <td
          v-if="
            columns.includes(COLUMNS.comment) &&
              scope === SCOPES.user &&
              isCurrentUserTeachingStaff
          "
          data-cy="comment"
          :class="{
            [$style.columnComment]: true,
            [$style.empty]: attendanceGradeComment ? false : true
          }"
        >
          <small>Visible to teaching staff only</small>
          <p v-html="attendanceGradeComment"></p>
        </td>
        <td
          v-if="
            isCurrentUserTeachingStaff &&
              (columns.includes(COLUMNS.attendance) ||
                columns.includes(COLUMNS.score) ||
                columns.includes(COLUMNS.comment))
          "
          :class="$style.columnIndicator"
        >
          <!-- action indicator -->
        </td>
      </template>

      <template v-else-if="inEditMode">
        <td
          v-if="columns.includes(COLUMNS.attendance)"
          :class="$style.columnAttendance"
        >
          <FormThreeWaySwitchAutoSave
            name="attendanceModel"
            data-cy="attendance"
            :value="attendanceModel"
            :modelValue="attendanceModel"
            :disabled="attendanceIsDisabled"
            :action="attendanceSave"
            :validation="attendanceValidate"
            @validation_result="onValidationResult"
            @success="attendanceSaveSuccessHandler"
            @error="attendanceSaveErrorHandler"
            @state="actionStateChangeHandler"
            :withActionIndicator="false"
          />
        </td>
        <td
          v-if="columns.includes(COLUMNS.student)"
          :class="$style.columnStudent"
        >
          <div :class="$style.user">
            <Avatar :user="user" size="20px" />&nbsp;<UserName :user="user" />
          </div>
        </td>
        <td v-if="columns.includes(COLUMNS.score)" :class="$style.columnScore">
          <div>
            <FormInputNumberAutoSave
              ref="scoreModel"
              data-cy="score"
              v-model="scoreModel"
              :min="scoreGradeMin"
              :max="scoreGradeMax"
              :action="scoreSave"
              :validation="scoreValidate"
              @validation_result="scoreValidationResult"
              :disabled="scoreIsDisabled"
              @success="scoreSaveSuccessHandler"
              @error="scoreSaveErrorHandler"
              @state="actionStateChangeHandler"
              :withActionIndicator="false"
            />
          </div>
        </td>
        <td
          v-if="columns.includes(COLUMNS.score)"
          :class="$style.columnScoreMax"
        >
          <span data-cy="score-max">{{
            Number.isNaN(scoreGradeMax) ? '' : `/${scoreGradeMax}`
          }}</span>
        </td>
        <td
          v-if="columns.includes(COLUMNS.comment) && scope === SCOPES.user"
          :class="$style.columnComment"
        >
          <small>Visible to teaching staff only</small>
          <FormTextareaAutoSave
            ref="commentModel"
            data-cy="comment"
            v-model="commentModel"
            :action="commentSave"
            :validation="commentValidate"
            @validation_result="commentValidationResult"
            :disabled="commentIsDisabled"
            @success="commentSaveSuccessHandler"
            @error="commentSaveErrorHandler"
            @state="actionStateChangeHandler"
            :withActionIndicator="false"
          />
        </td>
        <td :class="$style.columnIndicator">
          <span :class="$style.info">
            <img
              :class="{
                [$style.state]: true,
                [$style.blinking]: isBlinking
              }"
              v-if="getStateIcon(state)"
              :title="stateTitle"
              :src="require('@/assets/images/icons/' + getStateIcon(state))"
            />
            <span v-else :class="$style.state">&nbsp;</span>
          </span>
        </td>
      </template>
    </tr>
    <tr
      v-if="inEditMode && hasErrors"
      :class="{
        [$style.wrapper]: true,
        ['errors']: true
      }"
    >
      <td :colspan="columnsToSpan">
        <span v-if="scoreIsValid === false">
          {{ errors.join(', ') }}
        </span>
        <span v-if="commentIsValid === false">
          Valid attendance value must be provided in order to save the comment
        </span>
        <span
          v-if="scoreMaxIsValid === false && columns.includes(COLUMNS.score)"
        >
          Valid maximum score was not provided, please report this issue
        </span>
      </td>
    </tr>
    <tr v-if="!isLastRow" :class="{ [$style.divider]: true }">
      <td v-if="columns.includes(COLUMNS.attendance)"></td>
      <td v-if="columns.includes(COLUMNS.student)"></td>
      <td v-if="columns.includes(COLUMNS.score)"></td>
      <td v-if="columns.includes(COLUMNS.score)"></td>
      <td v-if="columns.includes(COLUMNS.comment)"></td>
      <td
        v-if="
          isCurrentUserTeachingStaff &&
            (columns.includes(COLUMNS.attendance) ||
              columns.includes(COLUMNS.score) ||
              columns.includes(COLUMNS.comment))
        "
        :class="$style.columnIndicator"
      >
        <!-- action indicator -->
      </td>
    </tr>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  mapInstanceActions,
  mapInstanceGetters,
  mapInstanceMutations
} from '@urbn/vuex-helpers';
import gradingUser from '@/store/modules/gradingUser/gradingUser';

import intervalParse from 'math-interval-parser';

import User from '@/components/common/User/User';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

import { SAVE_GRADING } from '@/store/actions.type';
import { MODES, TYPES, COLUMNS, SCOPES } from '@/config/grading';
import { STATES } from '@/config/forms';

import {
  getGradingErrors,
  isEvaluationPublished,
  getSessionColumns
} from '@/helpers/grading';

import { removeDuplicateObjByKey } from '@/helpers/common';
import { logError } from '@/helpers/errors';

const name = 'gradingUser';

const getNamespace = cmp => {
  const userId = cmp.user.user_id;
  const assignmentId = cmp.session.assignment_id;
  const scheduledAssignmentId = cmp.session.scheduled_assignment_id;

  return `${name}-${assignmentId}-${scheduledAssignmentId}-${userId}`;
};

export default {
  name,
  beforeCreate() {
    this.SCOPES = SCOPES;
    this.COLUMNS = COLUMNS;
  },
  created() {
    const namespace = getNamespace(this);
    this.NS = namespace;

    if (this.NS && !this.$store.hasModule(this.NS)) {
      this.$store.registerModule(this.NS, gradingUser);
    } else {
      // console.info(`Existing namespace ${this.NS}`);
    }

    // When registering modules dynamically
    // watchers of module getters need to be declared manually as well
    this.$watch(
      'hasEdits',
      function(newValue) {
        this.reportEdits(newValue);
      },
      { immediate: true }
    );

    this.setInitialEvaluations();
  },
  components: { User },
  props: {
    currentMode: {
      type: String,
      default: MODES.VIEW,
      validator: function(value) {
        return Object.values(MODES).includes(value);
      }
    },
    scope: {
      type: String,
      default: SCOPES.user,
      required: true,
      validator: function(value) {
        return Object.values(SCOPES).includes(value);
      }
    },
    user: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    isLastRow: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return {
      errors: [],
      scoreIsValid: undefined,
      scoreMaxIsValid: undefined,
      commentIsValid: undefined,
      state: STATES.idle
    };
  },
  watch: {
    hasErrors(newValue) {
      this.reportErrors(newValue);
    },
    currentMode(newValue, oldValue) {
      // Reset the state to idle when comming from VIEW to EDIT mode
      if (oldValue === MODES.VIEW && newValue === MODES.EDIT) {
        this.state = STATES.idle;
      }
    },
    session() {
      // If session data is re-fetched in parent view
      // we need to use freshly fetched data from API
      // to set correct values in form
      this.setInitialEvaluations();
    }
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff', 'isCurrentUserStudent']),
    ...mapInstanceGetters(getNamespace, [
      'attendanceGrade',
      'attendanceGradeId',
      'attendanceGradeComment',
      'attendanceGradeIsMissing',
      'scoreGrade',
      'scoreGradeId',
      'scoreGradeMin',
      'scoreGradeMax',
      'scoreGradeIsMissing',
      'hasEdits'
    ]),
    columns() {
      return getSessionColumns(this.session);
    },
    attendanceModel: {
      get() {
        return this.attendanceGrade;
      },
      set(newValue) {
        this.attendanceGradeSet(newValue);
      }
    },
    scoreModel: {
      get() {
        return this.scoreGrade;
      },
      set(newValue) {
        this.scoreGradeSet(newValue);
      }
    },
    commentModel: {
      get() {
        return this.attendanceGradeComment;
      },
      set(newValue) {
        this.attendanceGradeCommentSet(newValue);
      }
    },
    userId() {
      if (!this.user) return undefined;
      if (!this.user.user_id) return undefined;

      return this.user.user_id;
    },
    assignmentId() {
      if (!this.session) return undefined;
      if (!this.session.assignment_id) return undefined;

      return this.session.assignment_id;
    },
    scheduledAssignmentId() {
      if (!this.session) return undefined;
      if (!this.session.scheduled_assignment_id) return undefined;

      return this.session.scheduled_assignment_id;
    },
    grades() {
      if (!this.session) return [];
      if (!this.session.grading) return [];
      if (!this.session.grading.grades) return [];

      return removeDuplicateObjByKey(this.session.grading.grades, 'grade_id');
    },
    columnsToSpan() {
      let columns = 0;

      if (this.columns.includes(COLUMNS.attendance)) {
        columns = columns + 1;
      }

      if (this.columns.includes(COLUMNS.student)) {
        columns = columns + 1;
      }

      if (this.columns.includes(COLUMNS.score)) {
        columns = columns + 2;
      }

      if (this.columns.includes(COLUMNS.comment)) {
        columns = columns + 1;
      }

      return columns;
    },
    hasErrors() {
      if (this.columns.includes(COLUMNS.score)) {
        if (this.errors.length) return true;
        if (this.scoreMaxIsValid === false) return true;
      }

      if (this.columns.includes(COLUMNS.attendance)) {
        if (this.commentIsValid === false) return true;
      }

      // TODO: store attendance saving errors here as well?

      return false;
    },
    inViewMode() {
      return this.currentMode === MODES.VIEW;
    },
    inEditMode() {
      return this.currentMode === MODES.EDIT;
    },
    attendanceIsDisabled() {
      return false;
    },
    scoreIsDisabled() {
      return false;
    },
    commentIsDisabled() {
      return false;
    },
    isBlinking() {
      return this.state === STATES.typing;
    },
    stateTitle() {
      let titleText = '';

      switch (this.state) {
        case STATES.in_action:
          titleText = this.inActionTitle;
          break;
        case STATES.success:
          titleText = this.onSuccessTitle;
          break;
        case STATES.error:
          if (this.onErrorTitle && this.errorMessage) {
            titleText = this.onErrorTitle;
            titleText += '\n';
            titleText += this.errorMessage;
          }

          if (!this.onErrorTitle && this.errorMessage) {
            titleText += this.errorMessage;
          }

          break;
        default:
          titleText = '';
      }

      return titleText;
    }
  },
  methods: {
    ...mapInstanceActions(getNamespace, [SAVE_GRADING]),
    ...mapInstanceMutations(getNamespace, [
      'attendanceGradeIdSet',
      'attendanceGradeSet',
      'attendanceGradeCommentSet',
      'attendanceGradeMandatorySet',
      'scoreGradeIdSet',
      'scoreGradeSet',
      'scoreGradeMinSet',
      'scoreGradeMaxSet',
      'scoreGradeMandatorySet'
    ]),
    isPublished(session) {
      return isEvaluationPublished(session);
    },
    getStateIcon(state) {
      if (state === STATES.typing) return 'saving.svg';
      if (state === STATES.in_action) return 'saving.svg';
      if (state === STATES.success) return 'saved.svg';
      if (state === STATES.error) return 'not_saved.svg';

      return undefined;
    },
    setInitialEvaluations() {
      const {
        attendanceGradeId,
        attendanceGrade,
        attendanceGradeComment,
        attendanceGradeMandatory
      } = this.getInitialAttendance(this.session, this.userId, this.NS);

      // Students are not supposed to see grading until published
      if (
        this.isCurrentUserStudent &&
        this.isPublished(this.session) === false
      ) {
        this.attendanceGradeIdSet(attendanceGradeId);
        this.attendanceGradeSet(undefined);
        this.attendanceGradeCommentSet(undefined);
        this.attendanceGradeMandatorySet(attendanceGradeMandatory);
      } else {
        this.attendanceGradeIdSet(attendanceGradeId);
        this.attendanceGradeSet(attendanceGrade);
        this.attendanceGradeCommentSet(attendanceGradeComment);
        this.attendanceGradeMandatorySet(attendanceGradeMandatory);
      }

      const {
        scoreGradeId,
        scoreGrade,
        scoreGradeMin,
        scoreGradeMax,
        scoreGradeMandatory
      } = this.getInitialScore(this.session, this.userId, this.NS);

      // Students are not supposed to see grading until published
      if (
        this.isCurrentUserStudent &&
        this.isPublished(this.session) === false
      ) {
        this.scoreGradeIdSet(scoreGradeId);
        this.scoreGradeSet(undefined);
        this.scoreGradeMinSet(scoreGradeMin);
        this.scoreGradeMaxSet(scoreGradeMax);
        this.scoreGradeMandatorySet(scoreGradeMandatory);
      } else {
        this.scoreGradeIdSet(scoreGradeId);
        this.scoreGradeSet(scoreGrade);
        this.scoreGradeMinSet(scoreGradeMin);
        this.scoreGradeMaxSet(scoreGradeMax);
        this.scoreGradeMandatorySet(scoreGradeMandatory);
      }
    },
    // ----- Attendance -----
    attendanceValidate(value) {
      const isValid = typeof value === 'boolean';

      if (isValid) {
        this.commentIsValid = undefined;
      }

      return isValid;
    },
    attendanceSave(newValue) {
      return this.SAVE_GRADING({
        assignmentId: this.assignmentId,
        scheduledAssignmentId: this.scheduledAssignmentId,
        payload: {
          user_id: this.userId,
          grade_id: this.attendanceGradeId,
          grade: newValue,
          internal_comment: this.attendanceGradeComment
        }
      });
    },
    attendanceSaveSuccessHandler(newValue) {
      this.attendanceModel = newValue;
      this.$emit('update-succes', {});
    },
    attendanceSaveErrorHandler(error) {
      this.$emit('update-error', error);
    },
    // ----- Score -----
    scoreValidate(value) {
      this.errors = this.getErrors(
        value,
        this.scoreGradeMax,
        this.scoreGradeMin
      );

      return this.errors.length ? false : true;
    },
    scoreValidationResult(maybeValid) {
      this.scoreIsValid = maybeValid;
    },
    scoreSave(newValue) {
      return this.SAVE_GRADING({
        assignmentId: this.assignmentId,
        scheduledAssignmentId: this.scheduledAssignmentId,
        payload: {
          user_id: this.userId,
          grade_id: this.scoreGradeId,
          grade: newValue
        }
      });
    },
    scoreSaveSuccessHandler() {
      this.$emit('update-succes', {});
    },
    scoreSaveErrorHandler(error) {
      this.$emit('update-error', error);
    },
    // ----- Comment -----
    // We don't care about the value at all
    commentValidate() {
      // Just don't allow comment save without having a valid attendance value
      if (this.attendanceValidate(this.attendanceGrade)) {
        return true;
      }

      return false;
    },
    commentValidationResult(maybeValid) {
      this.commentIsValid = maybeValid;
    },
    commentSave(newValue) {
      // Comment is tied to attendance atm: https://candena.slack.com/archives/G9Q8CFP36/ p1643374702167359
      return this.SAVE_GRADING({
        assignmentId: this.assignmentId,
        scheduledAssignmentId: this.scheduledAssignmentId,
        payload: {
          user_id: this.userId,
          grade_id: this.attendanceGradeId,
          grade: this.attendanceGrade,
          internal_comment: newValue
        }
      });
    },
    commentSaveSuccessHandler() {
      this.$emit('update-succes', {});
    },
    commentSaveErrorHandler(error) {
      this.$emit('update-error', error);
    },
    actionStateChangeHandler(newState) {
      this.state = newState;

      const maybeInAction = [STATES.in_action, STATES.typing].includes(
        newState
      );

      this.$emit('in_action', maybeInAction);
    },
    getErrors(scorePoints, scoreMaxPoints, scoreMinPoints) {
      return getGradingErrors(scorePoints, scoreMaxPoints, scoreMinPoints);
    },
    reportErrors(maybeHasErrors) {
      this.$emit('has_errors', {
        origin: `${this.scheduledAssignmentId}_${this.userId}`,
        has_errors: maybeHasErrors
      });
    },
    reportEdits(maybeHasEdits) {
      this.$emit('has_edits', {
        origin: `${this.scheduledAssignmentId}_${this.userId}`,
        has_edits: maybeHasEdits
      });
    },
    onValidationResult(value) {},
    getInitialAttendance(session, userId, namespace) {
      const noEvaluation = {
        attendanceGradeId: undefined,
        attendanceGrade: undefined,
        attendanceGradeComment: undefined,
        attendanceGradeMandatory: undefined
      };

      const foundCriteria = this.grades
        .filter(g => g.scope === SCOPES.user)
        .filter(g => g.grade_type === TYPES.attendance);

      if (!foundCriteria.length) {
        // logError(`${namespace}: no criteria`);
        return noEvaluation;
      }

      if (foundCriteria.length !== 1) {
        logError(
          `${namespace}: ambiguous criteria ${JSON.stringify(foundCriteria)}`
        );
        return noEvaluation;
      }

      const criteria = foundCriteria[0];

      if (!session.evaluation || !session.evaluation.items) {
        return {
          ...noEvaluation,
          ...{
            attendanceGradeId: criteria.grade_id,
            attendanceGradeMandatory: criteria.mandatory
          }
        };
      }

      const foundEvaluations = session.evaluation.items.filter(
        i => i.user_id === userId && criteria.grade_id === i.grade_id
      );

      if (foundEvaluations.length > 1) {
        logError(
          `${namespace}: ambiguous evaluations ${JSON.stringify(
            foundEvaluations
          )}`
        );
        return noEvaluation;
      }

      if (!foundEvaluations.length) {
        return {
          ...noEvaluation,
          ...{
            attendanceGradeId: criteria.grade_id,
            attendanceGradeMandatory: criteria.mandatory
          }
        };
      }

      const evaluation = foundEvaluations[0];

      return {
        attendanceGradeId: evaluation.grade_id,
        attendanceGrade: evaluation.grade_data,
        attendanceGradeComment: evaluation.comment,
        attendanceGradeMandatory: criteria.mandatory
      };
    },
    getInitialScore(session, userId, namespace) {
      const noEvaluation = {
        scoreGradeId: undefined,
        scoreGrade: NaN,
        scoreGradeMin: NaN,
        scoreGradeMax: NaN,
        scoreGradeMandatory: undefined
      };

      const foundCriteria = this.grades
        .filter(g => g.scope === SCOPES.user)
        .filter(g => g.grade_type === TYPES.score);

      if (!foundCriteria.length) {
        // logError(`${namespace}: no criteria`);
        return noEvaluation;
      }

      if (foundCriteria.length !== 1) {
        logError(
          `${namespace}: ambiguous criteria ${JSON.stringify(foundCriteria)}`
        );
        return noEvaluation;
      }

      const criteria = foundCriteria[0];

      const score_range = intervalParse(criteria.score_range);
      let score_min,
        score_max = NaN;

      if (score_range) {
        score_min = score_range.from.included
          ? score_range.from.value
          : score_range.from.value + 1;
        score_max = score_range.to.included
          ? score_range.to.value
          : score_range.to.value - 1;
      }

      if (!session.evaluation || !session.evaluation.items) {
        return {
          ...noEvaluation,
          ...{
            scoreGradeId: criteria.grade_id,
            scoreGradeMin: score_min,
            scoreGradeMax: score_max,
            scoreGradeMandatory: criteria.mandatory
          }
        };
      }

      const foundEvaluations = session.evaluation.items.filter(
        i => i.user_id === userId && criteria.grade_id === i.grade_id
      );

      if (foundEvaluations.length > 1) {
        logError(
          `${namespace}: ambiguous evaluations ${JSON.stringify(
            foundEvaluations
          )}`
        );
        return noEvaluation;
      }

      if (!foundEvaluations.length) {
        return {
          ...noEvaluation,
          ...{
            scoreGradeId: criteria.grade_id,
            scoreGradeMin: score_min,
            scoreGradeMax: score_max,
            scoreGradeMandatory: criteria.mandatory
          }
        };
      }

      const evaluation = foundEvaluations[0];

      return {
        scoreGradeId: evaluation.grade_id,
        scoreGrade: evaluation.grade_data,
        scoreGradeMin: score_min,
        scoreGradeMax: score_max,
        scoreGradeMandatory: criteria.mandatory
      };
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/Columns.scss';
@import './styles/GradingUser.scss';
</style>
