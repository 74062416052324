var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-shadow-white p-2",class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Create a new discussion")]),_c('i',{staticClass:"icon icon-close",on:{"click":_vm.onClose}})]),_c('div',{attrs:{"data-ref":"subject"}},[_c('p',[_vm._v("Subject")]),_c('span',{class:_vm.$style.subject},[_c('span',{class:_vm.$style.subjectWrapper},[_c('FormInput',{attrs:{"type":"text"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('FormFieldErrors',{attrs:{"errors":_vm.newTopicSubjectErrors}})],1)])]),_c('div',{attrs:{"data-ref":"content"}},[_c('p',[_vm._v("Description")]),_c('Editor',{attrs:{"config":{
        toolbar: {
          viewportTopOffset:
            _vm.userSettingValue(_vm.SETTING_CODES.FIXED_NAV) === true ? 0 : 80,
          items: [
            'heading',
            '|',
            'Bold',
            'Italic',
            'bulletedList',
            'blockQuote',
            'link',
            '|',
            'Undo',
            'Redo'
          ]
        }
      }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('FormFieldErrors',{attrs:{"errors":_vm.newTopicContentErrors}})],1),_c('FormButton',{attrs:{"ghost":true},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Send ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }