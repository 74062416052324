export const state = {
  forum: {},
  topic: {},
  posts: [],
  newPost: {
    content: null
  }
};

export const getters = {
  forum(state) {
    return state.forum;
  },
  topic(state) {
    return state.topic;
  },
  posts(state) {
    return state.posts;
  },
  newPost(state) {
    return state.newPost;
  }
};

export const mutations = {
  setForum(state, forum) {
    state.forum = forum;
  },
  setTopic(state, topic) {
    state.topic = topic;
  },
  setTopicSubscription(state, isSubscribed) {
    state.topic.is_subscribed = isSubscribed;
  },
  setPosts(state, posts) {
    state.posts = posts;
  },
  setNewPostContent(state, content) {
    let trimmedContent = '';
    if (content) {
      trimmedContent = content.trim();
    }

    state.newPost.content = trimmedContent;
  },
  purgeNewPost(state) {
    state.newPost = {
      content: null
    };
  },
  purge(state) {
    state.forum = {};
    state.topic = {};
    state.posts = [];
    state.newPost = {
      content: null
    };
  }
};

export default {
  state,
  getters,
  mutations
};
