<template>
  <SideWidget :title="false" :gray="true" class="box-shadow-white">
    <template #content>
      <div
        :class="{ [$style.wrapper]: true, [$style.wrapperGray]: !rooms.length }"
      >
        <p class="text-bold paragraph-large" :class="$style.title">Team Room</p>
        <div v-if="!rooms.length" :class="$style.room">
          <img src="~@/assets/images/icons/enter_VC_icon.svg" />
        </div>
        <NewRoomLauncher
          v-else
          v-for="(room, index) in rooms"
          :key="index"
          :user="user"
          :room="getRoom(room)"
          @launching="handleProgress"
        >
          <div :class="$style.room">
            <img src="~@/assets/images/icons/enter_VC_icon.svg" />
          </div>
          <img
            v-if="rooms.length"
            :class="$style.logo"
            src="~@/assets/images/icons/newrow_logo.svg"
          />
        </NewRoomLauncher>
        <p :class="$style.placeholder" class="placeholder" v-if="!rooms.length">
          This is where you can see the Team Room.
        </p>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import SideWidget from '@/components/common/SideWidget/SideWidget';
import { NEWROW_ROLES } from '@/config/users';
import NewRoomLauncher from '@/components/common/NewRoomLauncher/NewRoomLauncher';

export default {
  components: {
    NewRoomLauncher,
    SideWidget
  },
  props: {
    rooms: {
      type: Array
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      isWorking: false
    };
  },
  methods: {
    handleProgress(working) {
      this.isWorking = working;
    },
    getRoom(room) {
      // Forcing an Instructor role here on purpose
      // See details at https://candena.slack.com/archives/CEE275SA0/p1669817654591329

      return { ...room, role: NEWROW_ROLES.instructor };
    }
  }
};
</script>

<style lang="scss" module>
@import './SideWidgetTeamRooms.scss';
</style>
