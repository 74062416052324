<template>
  <div role="main">
    <SessionView>
      <template v-if="!passwordSet">
        <p class="text-uppercase text-align-left paragraph-large text-bold">
          Choose a new password
        </p>

        <p class="pb-2 text-align-left">
          Please choose a new password you'll use to sign in to our services
          from now on:
        </p>

        <form aria-label="Enter your password" @submit.prevent="onSubmit">
          <FormLabel>
            Choose a password
            <FormInputPassword
              data-ref="password"
              v-model="password"
              :large="true"
            />
            <FormFieldErrors :errors="passwordErrors" />
            <FormInputPasswordStrength
              :password="password"
              :isValid="validatePassword"
              :class="$style.pwdStrength"
            />
          </FormLabel>

          <formButton
            :large="true"
            :disabled="disableButton ? true : null"
            :class="$style.btn"
          >
            Set new password
          </formButton>

          <p
            v-if="passwordSetAttempted"
            :class="$style.initiateReset"
            class="text-center"
          >
            <router-link :to="{ name: 'initiate-password-reset' }">
              Request a new password reset
            </router-link>
          </p>

          <p v-if="serverErrors.length" class="color-error text-center">
            Something went wrong. Click the link above to make a new request.
          </p>
        </form>
      </template>

      <template v-else>
        <p class="paragraph-large text-uppercase" :class="$style.pwdUpdated">
          PASSWORD UPDATED
        </p>
        <div :class="$style.circleGreen">
          <span class="icon icon-check" />
        </div>
        <p :class="$style.doneText">
          You can now login with your new credentials.
        </p>
        <router-link :to="{ name: 'login' }">
          <FormButton :class="$style.loginButton" :large="true"
            >LOGIN
          </FormButton>
        </router-link>
      </template>
    </SessionView>
  </div>
</template>

<script>
import { REGISTER_CONFIG } from '@/config/registration';

import { RESET_PASSWORD } from '@/store/actions.type';
import { PURGE_PASSWORD_RESET_TOKEN } from '@/store/mutations.type';

import { logError } from '@/helpers/errors';

import focusMixin from '@/mixins/focusMixin';
import validatedPasswordMixin from '@/mixins/validatedPasswordMixin';

import SessionView from '@/components/common/SessionView/SessionView';

export default {
  components: {
    SessionView
  },
  mixins: [focusMixin, validatedPasswordMixin],
  data() {
    return {
      passwordSetAttempted: false,
      passwordSet: false,
      disableButton: false,
      serverErrors: []
    };
  },
  destroyed() {
    this.$store.commit(PURGE_PASSWORD_RESET_TOKEN);
  },
  methods: {
    checkForm() {
      this.errors = [];

      if (!this.validatePassword) {
        this.focusInput(this.$el.querySelector('[data-ref=password]'));
        this.errors.push(REGISTER_CONFIG.errors.invalid_pwd);
      }

      if (!this.validatePasswordStrength) {
        this.focusInput(this.$el.querySelector('[data-ref=password]'));
        this.errors.push(REGISTER_CONFIG.errors.weak_pwd);
      }

      if (!this.errors.length) return true;
    },
    onSubmit() {
      if (this.checkForm()) {
        this.disableButton = true;
        this.passwordSetAttempted = false;
        this.serverErrors = [];

        this.$store
          .dispatch(RESET_PASSWORD, this.password)
          .then(() => {
            this.passwordSet = true;
            this.passwordSetAttempted = true;
          })
          .catch(error => {
            this.disableButton = true;
            this.serverErrors = [
              {
                message:
                  'Something went wrong. Click the link above to make a new request.'
              }
            ];
            logError(error);
          })
          .then(() => {
            this.passwordSetAttempted = true;
          });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './SetNewPassword.scss';
</style>
