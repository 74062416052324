export const SOCIAL_LINKS_SKELETON = {
  homepage_url: null,
  linkedin_url: null,
  twitter_url: null,
  facebook_url: null,
  instagram_url: null
};

export const ROLE_SKELETON = {
  code: null,
  label: null,
  color: null,
  primary: null
};

export const AVATARS_SKELETON = {
  normal: null,
  small: null
};

export const PROFILE_DATA_SKELETON = {
  city_of_residence: null,
  personal_quote: null
};

export const USER_PROFILE_SKELETON = {
  user_id: null,
  email: null,
  created_at: null,
  password_changed_at: null,
  role: ROLE_SKELETON,
  student_id: null,
  birth_date: null,
  system_language: null,
  first_name: null,
  last_name: null,
  pre_nominal_title: null,
  post_nominal_title: null,
  job_title: null,
  country_id: null,
  created_at: null,
  bio: null,
  profile_data: PROFILE_DATA_SKELETON,
  slug: null,
  profile_created_at: null,
  avatars: AVATARS_SKELETON,
  pp_accepted_at: null,
  tos_accepted_at: null,
  status: null,
  cohorts: [],
  teams: [],
  all_user_roles: [],
  teaching_staff: null,
  links: SOCIAL_LINKS_SKELETON
};
