var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:( _obj = {}, _obj[_vm.$style.wrapper] = true, _obj[_vm.$style.collapsed] = _vm.collapsed === true, _obj )},[_c('ListFacilitators',{attrs:{"facilitators":_vm.session.facilitators}}),_c('span',[_vm._v("Group Assignment Submission")]),_c('strong',{class:_vm.$style.sessionDuration}),_c('div'),(
      _vm.leadSessionGradingComponent !== _vm.GRADING_TYPES.TYPENULL &&
        _vm.hasEnded === true &&
        _vm.isPublished === true
    )?_c('span',{staticClass:"grading-state-evaluated",attrs:{"data-cy":"grading-state"}},[_vm._v(" ● EVALUATED ")]):_vm._e(),(
      _vm.leadSessionGradingComponent !== _vm.GRADING_TYPES.TYPENULL &&
        _vm.hasEnded === true &&
        _vm.isPublished === false
    )?_c('span',{staticClass:"grading-state-pending",attrs:{"data-cy":"grading-state"}},[_vm._v(" ● PENDING ")]):_vm._e(),(_vm.hasEnded === false)?_c('span'):_vm._e(),_c('a',{staticClass:"link",class:_vm.$style.flipLink,on:{"click":function($event){return _vm.$emit('flip', !_vm.collapsed)}}},[(_vm.collapsed === true)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.collapsed === false)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }