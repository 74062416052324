<template>
  <div :class="{ [$style.wrapper]: true }">
    <template v-if="inViewMode">
      <ContainerSessionHeader
        :session="session"
        :collapsed="isCollapsed"
        :nowISO="nowISO"
        :isPublished="isPublished"
        :hasEnded="this.sessionProgress === SESSION_PROGRESS.ENDED"
        @flip="handleFlip"
        :class="{
          [$style.dim]: this.sessionProgress === SESSION_PROGRESS.DISTANT
        }"
        data-cy="container-session-header"
      />
      <TransitionExpand>
        <div v-if="isCollapsed === false">
          <main
            :class="{
              [$style.dim]: [
                SESSION_PROGRESS.DISTANT,
                SESSION_PROGRESS.IMMINENT
              ].includes(this.sessionProgress)
            }"
            data-cy="container-session-main"
          >
            <slot :currentMode="currentMode"></slot>
          </main>
          <footer
            :class="{
              [$style.dim]: [
                SESSION_PROGRESS.DISTANT,
                SESSION_PROGRESS.IMMINENT
              ].includes(this.sessionProgress)
            }"
            data-cy="container-session-footer"
          >
            <div :class="$style.actions">
              <template
                v-if="
                  !isPublished &&
                    isCurrentUserTeachingStaff &&
                    sessionGradingComponent !== GRADING_TYPES.TYPENULL &&
                    sessionGradingComponent !== GRADING_TYPES.TYPE5
                "
              >
                <template v-if="hasEdits">
                  <FormButton
                    :standard="false"
                    :ghost="true"
                    data-cy="edit"
                    @click="handleEdit"
                    :disabled="canEdit === false || isInAction"
                    >Edit</FormButton
                  >
                  <FormButton
                    :standard="true"
                    :ghost="false"
                    data-cy="publish"
                    @click="handlePublish"
                    :disabled="canPublish === false || isInAction"
                    >Publish</FormButton
                  >
                </template>
                <template v-else>
                  <FormButton
                    :standard="true"
                    :ghost="false"
                    data-cy="edit"
                    @click="handleEdit"
                    :disabled="canEdit === false || isInAction"
                    >Edit</FormButton
                  >
                </template>
              </template>

              <template v-if="isPublished">
                <p :class="$style.published" data-cy="published">
                  <i class="icon icon-check" />

                  <strong>Published</strong> at
                  <span data-ignore="snapshot">{{
                    formatPublishedDate(session.evaluation.published_at)
                  }}</span>

                  <template v-if="operationPending">
                    <FormButton
                      :ghost="true"
                      data-cy="reopen"
                      :disabled="true"
                      v-if="isCurrentUserTeachingStaff"
                    >
                      Re-open Feedback (Pending)
                    </FormButton>
                  </template>
                  <template v-else>
                    <FormButton
                      :ghost="true"
                      data-cy="reopen"
                      @click="handleModalOpened"
                      :disabled="null"
                      v-if="isCurrentUserTeachingStaff"
                    >
                      Re-open Feedback
                    </FormButton>
                  </template>

                  <ModalNew
                    :showModal="showModal"
                    v-transfer-dom
                    :class="$style.modalConfirmation"
                    @close="handleModalClosed"
                  >
                    <b>You are about to re-open the feedback</b>
                    <p class="text-dark-grey">
                      It is subject to approval and might take a while
                    </p>
                    <div :class="$style.buttonsConfirmation">
                      <FormButton @click="handleReopen" :ghost="true"
                        >Send request</FormButton
                      >
                      <p @click="handleModalClosed">
                        Cancel
                      </p>
                    </div>
                  </ModalNew>
                </p>
              </template>
            </div>

            <div>
              <Notice
                type="error"
                v-if="publishActionError"
                :class="$style.errorMessage"
                :contentStyle="
                  'max-width: 100%; padding: 7px; justify-content: center;'
                "
                data-cy="publish_errors"
                :dismissable="false"
              >
                <template #icon>
                  <i
                    class="icon icon-exclamation-triangle"
                    style="font-size: 21px;"
                  />
                </template>
                <p>{{ publishActionError }}</p>
              </Notice>

              <Notice
                type="error"
                v-if="previewActionError"
                :class="$style.errorMessage"
                :contentStyle="
                  'max-width: 100%; padding: 7px; justify-content: center;'
                "
                data-cy="evaluation_errors"
                :dismissable="false"
              >
                <template #icon>
                  <i
                    class="icon icon-exclamation-triangle"
                    style="font-size: 21px;"
                  />
                </template>
                <p>{{ previewActionError }}</p>
              </Notice>
            </div>
          </footer>
        </div>
      </TransitionExpand>
    </template>

    <template v-if="inEditMode">
      <ContainerSessionHeader
        :session="session"
        :collapsed="isCollapsed"
        :nowISO="nowISO"
        @flip="handleFlip"
        data-cy="container-session-header"
        :isPublished="isPublished"
        :hasEnded="this.sessionProgress === SESSION_PROGRESS.ENDED"
      />
      <TransitionExpand>
        <div v-if="isCollapsed === false">
          <main data-cy="container-session-main">
            <slot :currentMode="currentMode"></slot>
          </main>
          <footer data-cy="container-session-footer">
            <div :class="$style.actions">
              <FormButton
                :standard="true"
                data-cy="preview"
                @click="handlePreview"
                :disabled="canPreview === false || isInAction"
                >Preview</FormButton
              >
            </div>
            <div>
              <Notice
                type="error"
                v-if="publishActionError"
                :class="$style.errorMessage"
                :contentStyle="'max-width: 100%; padding: 7px;'"
                data-cy="publish_errors"
                :dismissable="false"
              >
                <template #icon>
                  <i
                    class="icon icon-exclamation-triangle"
                    style="font-size: 21px;"
                  />
                </template>
                <p>{{ publishActionError }}</p>
              </Notice>

              <Notice
                type="error"
                v-if="previewActionError"
                :class="$style.errorMessage"
                :contentStyle="'max-width: 100%; padding: 7px;'"
                data-cy="evaluation_errors"
                :dismissable="false"
              >
                <template #icon>
                  <i
                    class="icon icon-exclamation-triangle"
                    style="font-size: 21px;"
                  />
                </template>
                <p>{{ previewActionError }}</p>
              </Notice>
            </div>
          </footer>
        </div>
      </TransitionExpand>
    </template>
  </div>
</template>

<script>
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

import TransitionExpand from '@/components/common/TransitionExpand/TransitionExpand';
import ContainerSessionHeader from './ContainerSessionHeader';
import Notice from '@/components/notices/Notice/Notice';

import { GRADING_TYPES, MODES, SESSION_PROGRESS } from '@/config/grading';
import {
  PUBLISH_GRADING,
  GET_ASSIGNMENT,
  GET_GRADING_EVALUATION_STATUS,
  REOPEN_ADMIN_OPERATION,
  CHECK_MISSING_MANDATORY_VALUES,
  RESET_MANDATORY_VALUES_CHECK
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  getSessionProgress,
  getSessionGradingComponent
} from '@/helpers/grading';

export default {
  name: 'ContainerSession',
  components: {
    ContainerSessionHeader,
    TransitionExpand,
    Notice
  },
  props: {
    session: {
      type: Object,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: undefined
    },
    nowISO: {
      type: String,
      required: true
    },
    canPreview: {
      type: Boolean,
      default: false
    },
    canPublish: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isInAction: {
      type: Boolean,
      default: false
    },
    hasEdits: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentMode: MODES.VIEW,
      collapsedOverride: undefined,
      previewActionError: undefined,
      publishActionError: undefined,
      reopenActionError: undefined,
      operationPending: false,
      showModal: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff', 'isCurrentUserStudent']),
    ...mapGetters('grading', ['leadSessionAssignmentId']),
    sessionGradingComponent() {
      return getSessionGradingComponent(this.session);
    },
    evaluationId() {
      if (!this.session) return undefined;
      if (!this.session.evaluation) return undefined;
      if (!this.session.evaluation.evaluation_id) return undefined;

      return this.session.evaluation.evaluation_id;
    },
    assignmentId() {
      if (!this.session) return undefined;
      if (!this.session.assignment_id) return undefined;

      return this.session.assignment_id;
    },
    scheduledAssignmentId() {
      if (!this.session) return undefined;
      if (!this.session.scheduled_assignment_id) return undefined;

      return this.session.scheduled_assignment_id;
    },
    isCollapsed() {
      return this.collapsedOverride !== undefined
        ? this.collapsedOverride
        : this.collapsed;
    },
    inViewMode() {
      return this.currentMode === MODES.VIEW;
    },
    inEditMode() {
      return this.currentMode === MODES.EDIT;
    },
    sessionProgress() {
      return getSessionProgress(this.session, this.nowISO);
    }
  },
  created() {
    this.MODES = MODES;
    this.SESSION_PROGRESS = SESSION_PROGRESS;
    this.GRADING_TYPES = GRADING_TYPES;

    this.getGradingEvaluationStatus();
  },
  methods: {
    ...mapActions('grading', [
      PUBLISH_GRADING,
      GET_ASSIGNMENT,
      GET_GRADING_EVALUATION_STATUS,
      REOPEN_ADMIN_OPERATION
    ]),
    getNamespacedModules(assignmentId, scheduledAssignmentId) {
      const re = new RegExp(
        `grading[User|Group]+\-${assignmentId}\-${scheduledAssignmentId}.*`,
        'g'
      );

      const namespacedModules = Object.keys(
        this.$store._modules.root._children
      ).filter(moduleName => moduleName.match(re));

      return namespacedModules;
    },
    formatPublishedDate(dateISO) {
      return moment(dateISO).format('DD.MM.YYYY, HH:mm');
    },
    handleError(error) {
      const errorBody =
        error && error.response && error.response.data
          ? error.response.data
          : undefined;

      return errorBody && errorBody.message ? errorBody.message : errorBody;
    },
    handleFlip(newValue) {
      this.collapsedOverride = newValue;
    },
    handleModalOpened() {
      this.showModal = true;
    },
    handleModalClosed() {
      this.showModal = false;
    },
    handleEdit() {
      if (this.isCurrentUserStudent) return;

      this.publishActionError = undefined;
      this.resetChecks();
      this.currentMode = MODES.EDIT;
    },
    getGradingEvaluationStatus() {
      if (this.isCurrentUserStudent) return;
      if (!this.evaluationId) return;

      this.GET_GRADING_EVALUATION_STATUS(this.evaluationId)
        .then(status => {
          this.operationPending =
            Array.isArray(status) && status.length ? true : false;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleReopen() {
      if (this.isCurrentUserStudent) return;
      if (!this.evaluationId) return;

      this.REOPEN_ADMIN_OPERATION({
        func: 'open_grading_evaluation',
        objectType: 'grading_evaluation',
        objectId: this.evaluationId,
        reference: `/#/assignment/${this.assignmentId}/${this.scheduledAssignmentId}`
      })
        .then(() => {
          this.operationPending = true;
          this.showModal = false;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    resetChecks() {
      let promises = [];

      this.getNamespacedModules(
        this.assignmentId,
        this.scheduledAssignmentId
      ).map(mod => {
        promises.push(
          this.$store.dispatch(`${mod}/${RESET_MANDATORY_VALUES_CHECK}`)
        );
      });

      Promise.all(promises).catch(error => {
        void error;
      });

      this.publishActionError = undefined;
    },
    handlePublish() {
      if (this.isCurrentUserStudent) return;

      let promises = [];

      this.getNamespacedModules(
        this.assignmentId,
        this.scheduledAssignmentId
      ).map(mod => {
        promises.push(
          this.$store.dispatch(`${mod}/${CHECK_MISSING_MANDATORY_VALUES}`, mod)
        );
      });

      this.publishActionError = undefined;
      Promise.all(promises)
        .then(result => {
          if (result.some(res => res)) {
            this.publishActionError =
              'One or more mandatory grading values are missing.';
          } else {
            this.PUBLISH_GRADING({
              assignmentId: this.assignmentId,
              scheduledAssignmentId: this.scheduledAssignmentId
            })
              .then(() => {
                this.GET_ASSIGNMENT(this.leadSessionAssignmentId)
                  .then(() => {
                    this.currentMode = MODES.VIEW;
                  })
                  .catch(error => {
                    this.previewActionError = this.handleError(error);
                  });
              })
              .catch(error => {
                this.publishActionError = this.handleError(error);
              });
          }
        })
        .catch(error => {
          this.publishActionError = this.handleError(error);
        });
    },
    handlePreview() {
      if (this.isCurrentUserStudent) return;

      this.previewActionError = undefined;

      this.GET_ASSIGNMENT(this.leadSessionAssignmentId)
        .then(() => {
          this.currentMode = MODES.VIEW;
        })
        .catch(error => {
          this.previewActionError = this.handleError(error);
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/Container.scss';
</style>
