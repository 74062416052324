<template>
  <div v-if="userDetail" :class="$style.wrapper">
    <div :class="$style.title">
      <h6>User detail</h6>
      <div :class="$style.buttons">
        <FormButton
          v-if="!editMode"
          @click="editMode = true"
          :class="$style.iconBtn"
          :standard="true"
          ><i class="icon icon-pencil" />
        </FormButton>
        <FormButton
          v-if="editMode"
          @click="saveEdit()"
          :class="$style.iconBtn"
          :standard="true"
          ><i class="icon icon-check" />
        </FormButton>
        <FormButton
          v-if="editMode"
          @click="cancelEdit()"
          :class="$style.iconBtnRed"
          :standard="true"
          ><i class="icon icon-close" />
        </FormButton>
      </div>
    </div>

    <div :class="$style.info">
      <div :class="$style.infoBox">
        <p :class="$style.infoLabel">
          Role:
        </p>
        <p v-if="!editMode">
          <span v-if="role">{{ role.label }}</span
          ><span v-else class="placeholder">none</span>
        </p>
        <FormLabel v-else :class="$style.roleLabel">
          <FilterSelect
            v-if="!checkIfStudent"
            :submenuVisible="roleSubmenuVisible"
            @submenu_visibility="selectRole"
          >
            <template #title>
              <span>{{ selectedRole.label || role.label }}</span>
            </template>
            <template #items>
              <fieldset
                v-for="role in rolesWithoutStudent"
                :value="role.label"
                :key="role.label"
                @click="handleRoleChange(role)"
              >
                <FormLabel>
                  {{ role.label }}
                </FormLabel>
              </fieldset>
            </template>
          </FilterSelect>
          <div v-else :class="$style.studentRoleLocked">
            {{ role.label }}<i class="icon icon-lock" />
          </div>
        </FormLabel>
      </div>

      <div :class="$style.infoBox">
        <p :class="$style.infoLabel">
          Job title:
        </p>
        <p v-if="!editMode">
          <span v-if="jobTitle">{{ jobTitle }}</span
          ><span v-else class="placeholder">none</span>
        </p>
        <FormInput :class="$style.infoInput" v-model="jobTitle" v-else />
      </div>

      <div :class="$style.infoBox">
        <p :class="$style.infoLabel">
          Pre nominal title:
        </p>
        <p v-if="!editMode">
          <span v-if="preTitle">{{ preTitle }}</span
          ><span v-else class="placeholder">none</span>
        </p>
        <FormInput :class="$style.infoInput" v-model="preTitle" v-else />
      </div>
      <div :class="$style.infoBox">
        <p :class="$style.infoLabel">
          Post nominal title:
        </p>
        <p v-if="!editMode">
          <span v-if="postTitle">{{ postTitle }}</span
          ><span v-else class="placeholder">none</span>
        </p>
        <FormInput :class="$style.infoInput" v-model="postTitle" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import {
  UPDATE_ADMIN_USER_DETAIL,
  GET_ADMIN_USER_ROLES,
  UPDATE_ADMIN_USER_ROLE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import { ROLES } from '@/config/users';

import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

export default {
  components: {
    FilterSelect
  },
  data() {
    return {
      editMode: false,
      jobTitle: null,
      preTitle: null,
      postTitle: null,
      role: null,
      roles: [],
      selectedRole: {},
      roleSubmenuVisible: false
    };
  },
  props: {
    userDetail: {
      type: Object,
      required: false
    }
  },
  created() {
    this.fetchRoles();
  },
  watch: {
    userDetail() {
      this.fetchUserDetail();
    }
  },
  computed: {
    rolesWithoutStudent() {
      return this.roles.filter(role => role.role_code != ROLES.student);
    },
    checkIfStudent() {
      if (this.userDetail) {
        return this.userDetail.role.code == ROLES.student;
      }

      return '';
    }
  },
  methods: {
    ...mapActions([UPDATE_ADMIN_USER_DETAIL, UPDATE_ADMIN_USER_ROLE]),
    fetchUserDetail() {
      if (this.userDetail) {
        this.jobTitle = this.userDetail.job_title;
        this.preTitle = this.userDetail.pre_nominal_title;
        this.postTitle = this.userDetail.post_nominal_title;
        this.role = this.userDetail.role;
      }
    },
    fetchRoles() {
      this.$store
        .dispatch(GET_ADMIN_USER_ROLES)
        .then(roles => {
          this.roles = roles;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleroleSubmenuChange(newSubmenuState) {
      this.roleSubmenuVisible = newSubmenuState;
    },
    handleRoleChange(role) {
      this.selectedRole = role;
      this.roleSubmenuVisible = false;
    },
    selectRole(newSubmenuState) {
      this.roleSubmenuVisible = newSubmenuState;
    },
    cancelEdit() {
      this.fetchUserDetail();
      this.selectedRole = {};
      this.editMode = false;
    },
    saveEdit() {
      this.updateUserInfo();
      this.editMode = false;
    },
    updateUserInfo() {
      if (this.userDetail && this.userDetail.user_id) {
        if (this.selectedRole) {
          this.$store.dispatch(UPDATE_ADMIN_USER_ROLE, {
            userId: this.userDetail.user_id,
            roleCode: this.selectedRole.role_code
          });
        }

        this.$store
          .dispatch(UPDATE_ADMIN_USER_DETAIL, {
            userId: this.userDetail.user_id,
            payload: {
              job_title: this.jobTitle,
              pre_nominal_title: this.preTitle,
              post_nominal_title: this.postTitle
            }
          })
          .then(() => {
            this.$emit('reloadUserDetail');
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminUserDetailEdit.scss';
</style>
