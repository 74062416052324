const VERY_WEAK_PASSWORD_STRENGTH = { score: 0, name: 'Very Weak' };
const WEAK_PASSWORD_STRENGTH = { score: 1, name: 'Weak' };
const GOOD_PASSWORD_STRENGTH = { score: 2, name: 'Average' };
const STRONG_PASSWORD_STRENGTH = { score: 3, name: 'Strong' };

const scorePassword = pass => {
  let score = 0;
  let variationCount = 0;
  let letters = {};

  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  };

  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  for (let check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }

  score += (variationCount - 1) * 10;

  return parseInt(score);
};

export const checkPassStrength = pass => {
  const score = scorePassword(pass);

  if (score > 80) {
    return STRONG_PASSWORD_STRENGTH;
  } else if (score > 60) {
    return GOOD_PASSWORD_STRENGTH;
  } else if (score >= 30) {
    return WEAK_PASSWORD_STRENGTH;
  } else {
    return VERY_WEAK_PASSWORD_STRENGTH;
  }
};
