<template>
  <div :class="$style.wrapper">
    <h5>Create new cohort</h5>
    <form @submit.prevent="createCohort">
      <FormLabel>
        <span>Cohort name</span>
        <FormInput
          name="cohortName"
          v-model="cohortName"
          data-cy="cohortName"
        />
      </FormLabel>
      <FormLabel>
        <span>Start at</span>
        <FormInputDate
          name="startDate"
          v-model="startDate"
          :stepping="true"
          data-cy="startDate"
        />
      </FormLabel>
      <FormLabel>
        <span>End at</span>
        <FormInputDate
          :withBorder="true"
          name="endDate"
          v-model="endDate"
          data-cy="endDate"
        />
      </FormLabel>
      <FormFieldErrors :errors="[{ message: errorMessages }]" />
      <FormButton :standard="true">Create cohort</FormButton>
    </form>
  </div>
</template>

<script>
import moment from 'moment';

import { REGISTER_CONFIG } from '@/config/registration';

import { mapActions } from 'vuex';
import { hasErrorStatus } from '@/helpers/errors';

import { CREATE_NEW_COHORT } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  data() {
    return {
      cohortCreated: false,
      errorMessages: '',
      cohortName: '',
      startDate: '',
      endDate: ''
    };
  },
  methods: {
    ...mapActions([CREATE_NEW_COHORT]),
    checkForm() {
      this.errorMessages = '';

      if (moment(this.endDate).diff(moment(this.startDate), 'days') < 365) {
        this.errorMessages =
          'Minimum time period between the cohort start and its end is one calendar year.';
      }

      if (
        !this.cohortName.trim() ||
        !this.startDate.trim() ||
        !this.endDate.trim()
      ) {
        this.errorMessages = 'Please fill out all inputs';
      }

      if (this.errorMessages === '') {
        return true;
      }

      return false;
    },
    createCohort() {
      if (this.checkForm()) {
        this.CREATE_NEW_COHORT({
          name: this.cohortName,
          start_date: moment(this.startDate).format('DD.MM.YYYY'),
          end_date: moment(this.endDate).format('DD.MM.YYYY')
        })
          .then(() => {
            this.cohortCreated = true;

            // when cohort is created, order them in desc order to see newly created user
            if (
              this.$route.query.sort != 'id' ||
              this.$route.query.order != 'desc'
            ) {
              this.$router.replace({
                query: { sort: 'id', order: 'desc' }
              });
            }
          })
          .then(() => {
            this.$emit('reloadPage');
          })
          .catch(error => {
            if (hasErrorStatus(409, error)) {
              this.errorMessages = error.response.data.message;
            } else {
              this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminCreateCohort.scss';
</style>
