import ApiService from '@/api/api.service';
import moment from 'moment';
// Tests should not see deprecation warnigs
moment.suppressDeprecationWarnings = ['test', 'e2e'].includes(
  process.env.NODE_ENV
)
  ? true
  : false;
// import mergeWith from 'lodash.mergewith';
import { removeDuplicateObjByKey } from '@/helpers/common';
import { logWarning } from '@/helpers/errors';
import {
  periodsByTime,
  weekdaysBetween,
  nextPeriodEnd
} from '@/helpers/schedule';
import { sortingByDate } from '@/helpers/common';
import { FROM_NEWEST } from '@/config/dates';
import { PHASES_AHEAD, SUGGESTION_TYPES } from '@/config/common';
import { STUDENT_ROLES, TEACHING_STAFF_ROLES } from '@/config/users';

import {
  RESET_STATE,
  SET_MODULE_PHASES,
  SET_MODULES_PHASES,
  SET_MODULES,
  ADD_SESSION_ASSIGNMENTS,
  SET_AS_USER_ID,
  SET_AS_USER_PROFILE,
  SET_AS_COHORT_ID
} from './mutations.type';
import {
  GET_MODULE_PHASES,
  GET_MODULES_PHASES,
  GET_MODULES,
  GET_MODULES_TEMP,
  GET_SCHEDULE_UPCOMING,
  GET_SCHEDULE_WEEK_CURRENT,
  GET_SCHEDULE_WEEK,
  GET_SESSION_ASSIGNMENTS,
  GET_SCHEDULED_ASSIGNMENT,
  SET_VIEW_AS_ACTION,
  POST_ASSIGNMENT_NOTE_RESOURCES,
  POST_ASSIGNMENT_NOTE_TEXT,
  POST_ASSIGNMENT_NOTE_PUBLISH,
  DELETE_ASSIGNMENT_NOTE_RESOURCES
} from './actions.type';

const CURRENT_PHASE_SLUG = 'current';

const getNextPhaseEnd = (phases, nowISO, phasesAhead) => {
  if (!phases || !phases.length) {
    return null;
  }

  if (!nowISO) {
    return null;
  }

  const phasesByNow = periodsByTime(phases, nowISO);

  return nextPeriodEnd(phasesByNow, nowISO, phasesAhead);
};

const getCurrentPhaseSlug = (phases, nowISO) => {
  if (!nowISO) {
    return null;
  }

  const phasesByNow = periodsByTime(phases, nowISO);

  if (!phasesByNow || !phasesByNow.present || !phasesByNow.present.length) {
    return null;
  }

  const currentPhase = phasesByNow.present[0];

  if (!currentPhase.slug) {
    return null;
  }

  return currentPhase.slug;
};

const getPastPhaseSlug = (phases, nowISO) => {
  if (!nowISO) {
    return null;
  }

  const phasesByNow = periodsByTime(phases, nowISO);

  if (!phasesByNow) {
    return null;
  }

  if (!phasesByNow.past.length) {
    return null;
  }

  const pastPhase = phasesByNow.past[phasesByNow.past.length - 1];

  if (!pastPhase.slug) {
    return null;
  }

  return pastPhase.slug;
};

const getMatchingRule = (resultsArray, rules) => {
  const found = rules.find(rule => {
    return rule.r.every(
      (result, resultIndex) => result === resultsArray[resultIndex]
    );
  });

  return found ? found : undefined;
};

export const state = {
  modulePhases: [],
  modulesPhases: [],
  modules: [],
  sessionAssignments: {},
  asUserId: NaN,
  asUserProfile: {},
  asCohortId: NaN
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  asUserId: state => {
    return state.asUserId;
  },
  asUserProfile: state => {
    return state.asUserProfile;
  },
  asUserRole: (state, getters) => {
    if (
      getters.asUserProfile &&
      getters.asUserProfile.role &&
      getters.asUserProfile.role.code
    ) {
      return getters.asUserProfile.role.code;
    }

    return undefined;
  },
  // This is almost the same as isCurrentUserStudent/isCurrentUserTeachingStaff getters,
  // but takes into account that current user (most likely admin) might be viewing data as another user with different role
  // The result is true if the view-as user has a student role
  isViewingAsStudent: (state, getters) => {
    if (getters.isViewingAsUser === true) {
      return STUDENT_ROLES.includes(getters.asUserRole);
    } else {
      return getters.isCurrentUserStudent;
    }
  },
  // This is almost the same as isCurrentUserStudent/isCurrentUserTeachingStaff getters,
  // but takes into account that current user (most likely admin) might be viewing data as another user with different role
  // The result is true if the view-as user has a teaching staff role
  isViewingAsTeachingStaff: (state, getters) => {
    if (getters.isViewingAsUser === true) {
      return TEACHING_STAFF_ROLES.includes(getters.asUserRole);
    } else if (getters.isViewingAsCohort === true) {
      return false;
    } else {
      return getters.isCurrentUserTeachingStaff;
    }
  },
  asCohortId: state => {
    return state.asCohortId;
  },
  modulePhases(state) {
    return state.modulePhases;
  },
  modulesPhases(state) {
    return state.modulesPhases;
  },
  modules(state) {
    return state.modules;
  },
  phaseWeekDays: state => phaseSlug => {
    if (!phaseSlug) {
      return [];
    }

    const phase = state.modulePhases.find(phase => phase.slug === phaseSlug);

    if (!phase) {
      return [];
    }

    const phaseStartISO = phase.start_at;
    const phaseEndISO = phase.end_at;

    if (!phaseStartISO || !phaseEndISO) {
      return [];
    }

    return weekdaysBetween(phaseStartISO, phaseEndISO);
  },
  phaseDetail: state => phaseSlug => {
    const foundPhase = state.modulePhases.filter(
      phase => phase.slug === phaseSlug
    );

    if (foundPhase.length === 1) {
      return foundPhase[0];
    }

    return undefined;
  },
  phaseName: state => phaseSlug => {
    const phase = state.modulePhases.find(phase => phase.slug === phaseSlug);

    if (!phase || !phase.phase_name) {
      return '';
    }

    return phase.phase_name;
  },
  sessionAssignment: state => (phaseSlug, scheduledAssignmentId) => {
    if (
      !phaseSlug ||
      !state.sessionAssignments[phaseSlug] ||
      !state.sessionAssignments[phaseSlug].length
    ) {
      return null;
    }

    const sessionAssignment = state.sessionAssignments[phaseSlug].find(
      assignment => {
        return assignment.scheduled_assignment_id === scheduledAssignmentId;
      }
    );

    if (!sessionAssignment) {
      return null;
    }

    return sessionAssignment;
  },
  sessionAssignmentsBetween: state => (
    phaseSlug,
    periodStartISO,
    periodEndISO
  ) => {
    if (
      !phaseSlug ||
      !state.sessionAssignments[phaseSlug] ||
      !state.sessionAssignments[phaseSlug].length
    ) {
      return [];
    }

    const firstAssignmentIndex = 0;
    const lastAssignmentIndex = state.sessionAssignments[phaseSlug].length - 1;

    if (!periodStartISO) {
      periodStartISO =
        state.sessionAssignments[phaseSlug][firstAssignmentIndex].start_at;
    }

    if (!periodEndISO) {
      periodEndISO =
        state.sessionAssignments[phaseSlug][lastAssignmentIndex].end_at;
    }

    const m_start_at = moment(periodStartISO);
    const m_end_at = moment(periodEndISO);

    if (
      !m_start_at.isValid() ||
      !m_end_at.isValid() ||
      m_start_at.isAfter(m_end_at)
    )
      return [];

    return state.sessionAssignments[phaseSlug].filter(assignment => {
      const isStartWithinPeriod = moment(assignment.start_at).isBetween(
        m_start_at,
        m_end_at,
        null,
        '[]'
      );
      const isEndWithinPeriod = moment(assignment.end_at).isBetween(
        m_start_at,
        m_end_at,
        null,
        '[]'
      );

      if (
        (isStartWithinPeriod && !isEndWithinPeriod) ||
        (!isStartWithinPeriod && isEndWithinPeriod)
      ) {
        logWarning(
          `assignment ${assignment.slug} scheduled ${assignment.start_at} - ${assignment.end_at} leaks outside period ${periodStartISO} - ${periodEndISO}`
        );
      }

      return (
        isStartWithinPeriod &&
        isEndWithinPeriod &&
        assignment.kind === 'session'
      );
    });
  },
  nextPhaseEnd: state => nowISO => {
    return getNextPhaseEnd(state.modulePhases, nowISO, PHASES_AHEAD);
  },
  // Only past phases, current phase and first upcoming phase is visible
  isPhaseVisible: state => (phaseSlug, nowISO) => {
    const phase = state.modulePhases.find(phase => phase.slug === phaseSlug);

    if (!phase || !phase.end_at) {
      return false;
    }

    const phaseEnd = phase.end_at;
    const nextPhaseEnd = getNextPhaseEnd(
      state.modulePhases,
      nowISO,
      PHASES_AHEAD
    );

    return moment(phaseEnd).isSameOrBefore(moment(nextPhaseEnd));
  },
  dayHasSessionAssignments: state => (phaseSlug, dayISO) => {
    if (
      !state.sessionAssignments ||
      !state.sessionAssignments[phaseSlug] ||
      !state.sessionAssignments[phaseSlug].length ||
      !dayISO
    ) {
      return false;
    }

    return !!state.sessionAssignments[phaseSlug].find(assignment => {
      if (!assignment || !assignment.start_at) {
        return false;
      }

      const m_assignment_start = moment(assignment.start_at);

      if (!m_assignment_start.isValid()) return false;

      return moment(dayISO).isSame(m_assignment_start, 'day');
    });
  },
  currentPhaseSlug: state => nowISO => {
    return getCurrentPhaseSlug(state.modulePhases, nowISO);
  },
  currentOrPastPhaseSlug: state => nowISO => {
    // console.warn('currentOrPastPhaseSlug getter is deprecated!');

    const currentPhaseSlug = getCurrentPhaseSlug(state.modulePhases, nowISO);
    const pastPhaseSlug = getPastPhaseSlug(state.modulePhases, nowISO);

    if (currentPhaseSlug) {
      return currentPhaseSlug;
    } else if (pastPhaseSlug) {
      return pastPhaseSlug;
    } else {
      return null;
    }
  },
  currentScheduledAssignmentId: state => nowISO => {
    const currentPhaseSlug = getCurrentPhaseSlug(state.modulePhases, nowISO);

    if (
      !currentPhaseSlug ||
      !state.sessionAssignments ||
      !state.sessionAssignments[currentPhaseSlug]
    ) {
      return null;
    }

    const currentPhaseAssignments = state.sessionAssignments[currentPhaseSlug];
    const currentPhaseAssignmentsByNow = periodsByTime(
      currentPhaseAssignments,
      nowISO
    );

    if (
      !currentPhaseAssignmentsByNow ||
      !currentPhaseAssignmentsByNow.present ||
      !currentPhaseAssignmentsByNow.present.length
    ) {
      return null;
    }

    const currentAssignment = currentPhaseAssignmentsByNow.present[0];

    if (!currentAssignment.scheduled_assignment_id) {
      return null;
    }

    return currentAssignment.scheduled_assignment_id;
  },
  isViewingAsUser: state => {
    return !Number.isNaN(parseInt(state.asUserId));
  },
  isViewingAsCohort: state => {
    return !Number.isNaN(parseInt(state.asCohortId));
  },
  shouldSeeFacilitatorSwitch: (state, getters) => {
    if (getters.isCurrentUserStudent) return false;
    if (getters.isCurrentUserAdmin) return false;

    if (getters.isCurrentUserTeachingStaff) {
      if (getters.isViewingAsCohort) return true;
    }

    return false;
  },
  getModuleByVersionId: state => moduleVersionId => {
    if (!state.modules) return undefined;
    if (Array.isArray(!state.modules)) return undefined;
    if (!state.modules.length) return undefined;

    const found = state.modules.find(
      m => m.module_version_id === moduleVersionId
    );

    return found ? found : undefined;
  },
  isCurrentUserFacilitating: (state, getters) => lesson => {
    if (!lesson) return false;
    if (!Array.isArray(lesson.facilitators)) return false;
    if (!getters.shouldSeeFacilitatorSwitch) return false;

    if (getters.getUserKVStoreValue('schedule_facilitated') === true) {
      const found = lesson.facilitators.find(
        u => u.user_id === getters.currentUserId
      );

      return found ? true : false;
    }

    return false;
  },
  shouldUsePersonalEndpoint: (state, getters) => {
    const resultsArray = [
      getters.isCurrentUserTeachingStaff ? 1 : 0,
      getters.isViewingAsCohort ? 1 : 0,
      getters.isViewingAsUser ? 1 : 0
    ];

    const rules = [
      {
        r: [0, 0, 0],
        n: 'Student not viewing as',
        h: () => false
      },
      {
        r: [0, 1, 0],
        n: 'Student viewing as cohort',
        h: () => false
      },
      {
        r: [0, 0, 1],
        n: 'Student viewing as user',
        h: () => false
      },
      {
        r: [0, 1, 1],
        n: 'Student viewing as cohort & user',
        h: () => false
      },
      {
        r: [1, 0, 0],
        n: 'Teaching staff not viewing as',
        h: () => true
      },
      {
        r: [1, 1, 0],
        n: 'Teaching staff viewing as cohort',
        h: () => false
      },
      {
        r: [1, 0, 1],
        n: 'Teaching staff viewing as user (role dependent)',
        h: () => {
          return getters.isViewingAsStudent ? false : true;
        }
      },
      {
        r: [1, 1, 1],
        n: 'Teaching staff viewing as cohort & user',
        h: () => false
      }
    ];

    const found = getMatchingRule(resultsArray, rules);

    if (!found) {
      return false;
    }

    // console.warn('scenario:', JSON.stringify(found.r), found.n);
    return found.h();
  },
  allowedSuggestionTypes: (state, getters) => {
    const allowedSuggestionTypesForAdmin = [
      SUGGESTION_TYPES.user,
      SUGGESTION_TYPES.cohort
    ];

    const allowedSuggestionTypesForTeachingStaff = [SUGGESTION_TYPES.cohort];

    if (getters.isCurrentUserStudent) return [];
    if (getters.isCurrentUserAdmin) return allowedSuggestionTypesForAdmin;
    if (getters.isCurrentUserTeachingStaff)
      return allowedSuggestionTypesForTeachingStaff;

    return [];
  },
  getFromModulesByVersionId: () => (modules, moduleVersionId) => {
    if (!modules) return undefined;

    const found = modules.find(m => m.module_version_id === moduleVersionId);
    return found ? found : undefined;
  }
};

export const actions = {
  [GET_MODULES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getModules(params)
        .then(({ data }) => {
          context.commit(
            SET_MODULES,
            removeDuplicateObjByKey(data, 'module_version_id')
          );
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_MODULES_TEMP](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getModules(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_MODULES_PHASES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getModulesPhases()
        .then(({ data }) => {
          context.commit(
            SET_MODULES_PHASES,
            removeDuplicateObjByKey(data, 'slug')
          );
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_MODULE_PHASES](context) {
    return new Promise((resolve, reject) => {
      ApiService.getModulePhases()
        .then(({ data }) => {
          data = Array.isArray(data) ? data : [];

          context.commit(
            SET_MODULE_PHASES,
            removeDuplicateObjByKey(data, 'slug')
          );
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_SCHEDULE_UPCOMING](context) {
    return new Promise((resolve, reject) => {
      ApiService.getScheduleUpcoming()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_SCHEDULE_WEEK_CURRENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getScheduleWeekCurrent(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_SCHEDULE_WEEK](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getScheduleWeek(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_SESSION_ASSIGNMENTS](context, phaseSlug) {
    return new Promise((resolve, reject) => {
      ApiService.getSessionAssignments(phaseSlug)
        .then(({ data }) => {
          context.commit(ADD_SESSION_ASSIGNMENTS, {
            [phaseSlug]: data
          });

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_SCHEDULED_ASSIGNMENT](context, scheduledAssignmentId) {
    return new Promise((resolve, reject) => {
      ApiService.getScheduledAssignment(scheduledAssignmentId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_ASSIGNMENT_NOTE_RESOURCES](context, { assignmentId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.postAssignmentNoteResources(assignmentId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_ASSIGNMENT_NOTE_RESOURCES](context, { assignmentId, resourceSlug }) {
    return new Promise((resolve, reject) => {
      ApiService.deleteAssignmentNoteResources(assignmentId, resourceSlug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_ASSIGNMENT_NOTE_TEXT](context, { assignmentId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.postAssignmentNoteText(assignmentId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [POST_ASSIGNMENT_NOTE_PUBLISH](context, { assignmentId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.postAssignmentNotePublish(assignmentId, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async [SET_VIEW_AS_ACTION](context, payload) {
    const shouldPatchKVStore =
      (context && context.getters && context.getters.isCurrentUserAdmin) ||
      false;

    // If no payload, treat as request to clear
    if (!payload || !payload.view_as) {
      try {
        // Clearing should be done no matter what value shouldPatchKVStore variable has
        await ApiService.patchUserKVStore({
          view_as: { object_type: NaN, object_id: NaN }
        });

        context.commit(SET_AS_USER_ID, NaN);
        context.commit(SET_AS_USER_PROFILE, {});
        context.commit(SET_AS_COHORT_ID, NaN);
        return Promise.resolve();
      } catch (error) {
        context.commit(SET_AS_USER_ID, NaN);
        context.commit(SET_AS_USER_PROFILE, {});
        context.commit(SET_AS_COHORT_ID, NaN);
        return Promise.reject(error);
      }
    }

    if (payload.view_as.object_type === 'user') {
      try {
        if (shouldPatchKVStore) {
          await ApiService.patchUserKVStore(payload);
        }
        context.commit(SET_AS_USER_ID, payload.view_as.object_id);
        context.commit(SET_AS_COHORT_ID, NaN);

        const { data } = await ApiService.getBulkUserProfiles({
          ids: [payload.view_as.object_id]
        });

        const userProfile = Array.isArray(data) && data.length ? data[0] : {};
        context.commit(SET_AS_USER_PROFILE, userProfile);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    if (payload.view_as.object_type === 'cohort') {
      try {
        if (shouldPatchKVStore) {
          await ApiService.patchUserKVStore(payload);
        }
        context.commit(SET_AS_USER_ID, NaN);
        context.commit(SET_AS_COHORT_ID, payload.view_as.object_id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.reject();
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [SET_MODULES](state, modules) {
    state.modules = modules;
  },

  [SET_AS_USER_ID](state, userId) {
    state.asUserId = parseInt(userId);
  },

  [SET_AS_USER_PROFILE](state, userProfile) {
    state.asUserProfile = userProfile;
  },

  [SET_AS_COHORT_ID](state, cohortId) {
    state.asCohortId = parseInt(cohortId);
  },

  [SET_MODULES_PHASES](state, modulesPhases) {
    state.modulesPhases = modulesPhases;
  },

  [SET_MODULE_PHASES](state, modulePhases) {
    state.modulePhases = modulePhases;
  },

  [ADD_SESSION_ASSIGNMENTS](state, sessionAssignments) {
    state.sessionAssignments = {
      ...state.sessionAssignments,
      ...sessionAssignments
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
