<template>
  <span @click="showSeconds = !showSeconds">
    <slot
      name="before-deadline"
      v-if="!isAfterDeadline"
      v-bind:checkTime="checkTime"
      v-bind:isCounting="isCounting"
    >
      <time>{{ checkTime }}</time>
    </slot>

    <slot
      name="after-deadline"
      v-if="isAfterDeadline"
      v-bind:isCounting="isCounting"
    >
      <time>0:00:00</time>
    </slot>
  </span>
</template>

<script>
import moment from 'moment';

// Formats are searched from index 0 and first one that satisfies the condition is used
// bear that in mind when overriding the default formats
import { TIMER_FORMATS, CLOSE_TO_DEADLINE_THRESHOLD } from '@/config/common';

export default {
  props: {
    timerStart: {
      type: String,
      required: false,
      default: '2018-11-06 16:00:00'
    },
    deadline: {
      type: String,
      required: false,
      default: '2018-11-06 16:00:00'
    },
    currentTime: {
      type: String,
      required: false,
      default: ''
    },
    formats: {
      type: Array,
      default: () => TIMER_FORMATS
    }
  },
  data() {
    return {
      interval: null,
      showSeconds: true
    };
  },
  computed: {
    taskDuration() {
      const deadlineMoment = moment(this.deadline);
      const timerStartMoment = moment(this.timerStart);
      return moment
        .duration(deadlineMoment.diff(timerStartMoment))
        .asMilliseconds();
    },
    remainingDuration() {
      return moment
        .duration(moment(this.deadline).diff(moment(this.currentTime)))
        .asMilliseconds();
    },
    checkTime() {
      if (this.isBeforeTimerStart) {
        return moment.utc(this.taskDuration).format('HH:mm:ss');
      }

      if (!Array.isArray(this.formats)) {
        return this.showSeconds ? '--:--:--' : '--:--';
      }

      const foundFormat = this.formats.find(
        format => format.condition(this.remainingDuration) === true
      );

      return foundFormat.formatFunction(
        this.remainingDuration,
        this.deadline,
        this.currentTime,
        this.showSeconds
      );
    },
    isCounting() {
      return !this.isBeforeTimerStart;
    },
    isBeforeTimerStart() {
      return moment(this.currentTime).isBefore(moment(this.timerStart));
    },
    isAfterDeadline() {
      return moment(this.currentTime).isAfter(moment(this.deadline));
    }
  }
};
</script>
