<template>
  <span
    :class="{
      [$style.wrapper]: true,
      [$style.undefined]: modelValue === undefined,
      [$style.on]: modelValue === true,
      [$style.off]: modelValue === false
    }"
    data-cy="threeway-switch"
  >
    <label>
      <img
        v-if="getStateIcon(modelValue)"
        :src="require('@/assets/images/icons/' + getStateIcon(modelValue))"
      />
      <span v-else>-</span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'FormThreeWaySwitchReadOnly',
  inheritAttrs: false, // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  props: {
    value: {},
    modelValue: {}
  },
  methods: {
    getStateIcon(state) {
      if (state === true) return 'saved.svg';
      if (state === false) return 'not_saved.svg';
      if (state === undefined) return 'undefined.svg';

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import './FormThreeWaySwitchReadOnly.scss';
</style>
