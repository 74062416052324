<template>
  <div :class="$style.wrapper">
    <div
      :class="{
        [$style.frame]: true,
        [$style.withAvatar]: hasAvatars(userId),
        [$style.withNoAvatar]: !hasAvatars(userId)
      }"
      :style="{ borderColor: borderColor }"
    >
      <FileUploadBase :step="avatarUploadStep" :errors="errors">
        <template #waiting>
          <FileUploadArea
            :maxSizeInMb="maxSizeInMb"
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @removed="onRemoved"
            @validation="onValidation"
          >
            <Avatar
              v-if="hasAvatars(userId)"
              :user="user"
              size="160px"
              :class="$style.avatar"
            />
            <span v-else>
              <img
                v-svg-inline
                width="150"
                src="~@/assets/images/icons/avatar-upload-icon.svg"
              />
            </span>

            <div :class="$style.buttonsOverlay">
              <span :class="$style.editButton">
                <img
                  v-svg-inline
                  width="24"
                  src="~@/assets/images/icons/edit.svg"
                />
              </span>
              <span
                :class="$style.removeButton"
                @click="onRemoved"
                data-ref="remove"
                v-if="hasAvatars(userId)"
              >
                <img
                  v-svg-inline
                  width="24"
                  src="~@/assets/images/icons/remove.svg"
                />
              </span>
            </div>
          </FileUploadArea>
        </template>
        <template #uploading>
          <CircleProgress
            data-ref="uploadProgress"
            :percent="avatarUploadPercent"
            :radius="72"
          >
            Uploading...
          </CircleProgress>
        </template>
        <template #processing>
          <CircleLoader data-ref="loader" :radius="78" :strokeWidth="8">
            Processing...
          </CircleLoader>
        </template>
        <template #success>
          <FileUploadArea
            :maxSizeInMb="maxSizeInMb"
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @removed="onRemoved"
            @validation="onValidation"
          >
            <Avatar :user="user" size="160px" :class="$style.avatar" />

            <div :class="$style.buttonsOverlay">
              <span :class="$style.editButton">
                <img
                  v-svg-inline
                  width="24"
                  src="~@/assets/images/icons/edit.svg"
                />
              </span>
              <span
                :class="$style.removeButton"
                @click="onRemoved"
                data-ref="remove"
              >
                <img
                  v-svg-inline
                  width="24"
                  src="~@/assets/images/icons/remove.svg"
                  v-if="hasAvatars(userId)"
                />
              </span>
            </div>
          </FileUploadArea>
        </template>
        <template #error>
          <FileUploadArea
            :maxSizeInMb="maxSizeInMb"
            :allowedMimeTypes="allowedMimeTypes"
            @inserted="onInserted"
            @removed="onRemoved"
            @validation="onValidation"
          >
            <img
              v-svg-inline
              width="24"
              src="~@/assets/images/icons/not_saved.svg"
              alt="File upload failed!"
              :class="$style.errorIcon"
            />
            <div v-if="errors.length">
              <p class="paragraph-small" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </p>
            </div>
          </FileUploadArea>
        </template>
      </FileUploadBase>
    </div>

    <Badge :class="$style.badge" :user="user" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { THEMES, DEFAULT_THEME } from '@/config/themes';

import {
  UPLOAD_AVATAR,
  POLL_AVATAR,
  DELETE_AVATAR
} from '@/store/actions.type';
import {
  SET_AVATAR_UPLOAD_STEP,
  PURGE_AVATARS,
  PURGE_AVATAR_UPLOAD
} from '@/store/mutations.type';
import { MIME_TYPES_BY_FILE_EXTENSION, UPLOAD_STEP } from '@/config/files';

import CircleLoader from '@/components/common/CircleLoader/CircleLoader';
import CircleProgress from '@/components/common/CircleProgress/CircleProgress';
import FileUploadBase from '@/components/common/FileUploadBase/FileUploadBase';
import FileUploadArea from '@/components/common/FileUploadArea/FileUploadArea';
import Avatar from '@/components/common/User/Avatar';
import Badge from '@/components/common/User/Badge';

export default {
  components: {
    CircleLoader,
    CircleProgress,
    FileUploadBase,
    FileUploadArea,
    Avatar,
    Badge
  },
  props: {
    userId: {
      type: Number,
      required: false
    },
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      maxSizeInMb: 5,
      allowedMimeTypes: [
        MIME_TYPES_BY_FILE_EXTENSION.png,
        MIME_TYPES_BY_FILE_EXTENSION.jpeg,
        MIME_TYPES_BY_FILE_EXTENSION.gif
      ],
      errors: []
    };
  },
  computed: {
    ...mapGetters(['avatarUploadPercent', 'avatarUploadStep', 'hasAvatars']),
    userRoleColor() {
      const userRoleColor = this.user && this.user.role && this.user.role.color;

      return userRoleColor ? userRoleColor : this.defaultColor;
    },
    borderColor() {
      if (this.avatarUploadStep === UPLOAD_STEP.uploading) return 'transparent';

      return this.userRoleColor;
    }
  },
  watch: {
    avatarUploadPercent() {
      if (this.avatarUploadPercent === 100) {
        // a little delay so 100% upload is visible
        // even when a request is really fast
        setTimeout(() => {
          // this check is required because setTimeout asynchronity
          // upload percent can be 100 and after that server can return
          // error response - we don't want to show processing
          // in these cases
          if (this.avatarUploadStep === UPLOAD_STEP.uploading) {
            this.showProcessing();
          }
        }, 700);
      }
    }
  },
  methods: {
    async onInserted(payload) {
      this.reset();

      this.showUploading();

      try {
        await this.$store.dispatch(UPLOAD_AVATAR, {
          userId: this.userId,
          file: payload.file
        });
      } catch (error) {
        this.errors = [...this.errors, error];
        this.$store.commit(PURGE_AVATAR_UPLOAD);
        this.showError();

        return;
      }

      try {
        await this.$store.dispatch(POLL_AVATAR, { userId: this.userId });
      } catch (error) {
        this.errors = [...this.errors, error];
        this.$store.commit(PURGE_AVATAR_UPLOAD);
        this.showError();
      }
    },
    onValidation(payload) {
      if (payload.errors.length > 0) {
        this.errors = payload.errors.map(error => error.message);
        this.$store.commit(PURGE_AVATAR_UPLOAD);
        this.$store.dispatch(DELETE_AVATAR); // not waiting for the result
        this.$store.commit(PURGE_AVATARS, this.userId);
        this.showError();
      }
    },
    onRemoved() {
      this.reset();
    },
    reset() {
      this.errors = [];
      this.$store.commit(PURGE_AVATAR_UPLOAD);
      this.$store.dispatch(DELETE_AVATAR); // not waiting for the result
      this.$store.commit(PURGE_AVATARS, this.userId);
    },
    showWaiting() {
      this.$store.commit(SET_AVATAR_UPLOAD_STEP, UPLOAD_STEP.waiting);
    },
    showUploading() {
      this.$store.commit(SET_AVATAR_UPLOAD_STEP, UPLOAD_STEP.uploading);
    },
    showProcessing() {
      this.$store.commit(SET_AVATAR_UPLOAD_STEP, UPLOAD_STEP.processing);
    },
    showError() {
      this.$store.commit(SET_AVATAR_UPLOAD_STEP, UPLOAD_STEP.error);
    }
  },
  beforeCreate() {
    this.defaultColor = THEMES[process.env.THEME || DEFAULT_THEME].primaryColor;
    this.UPLOAD_STEP = UPLOAD_STEP;
  }
};
</script>

<style lang="scss" module>
@import './AvatarUpload.scss';
</style>
