<template>
  <div :class="$style.wrapper">
    <router-link :to="{ name: 'conversations' }">
      <div :class="$style.itemWrapper">
        <div
          :class="{
            [$style.item]: true,
            [$style.iconItem]: true,
            [$style.active]: isOnConversationsRoute,
            [$style.unread]: isOnConversationsRoute || unreadCount > 0
          }"
        >
          <span
            v-if="unreadCount > 0"
            data-ref="unreadCounter"
            :class="$style.counter"
            class="theme-color paragraph-small"
            >{{ unreadCount }}
          </span>
          <img v-svg-inline :src="require('@/assets/images/icons/chat.svg')" />
        </div>
      </div>
    </router-link>

    <div
      v-if="!isMobileLayout && !isTabletLayout"
      :class="$style.itemWrapper"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
    >
      <!-- This may be temporary solution for mobiles, we don't want link to be working on smaller devices. Touch event will not work correctly - http://www.stucox.com/blog/you-cant-detect-a-touchscreen/-->
      <router-link
        tag="button"
        :class="$style.user"
        :disabled="isMobileLayout || isTabletLayout ? true : null"
        :to="{ name: 'user-profile', params: { userId: currentUserId } }"
      >
        <div
          :class="{
            [$style.item]: true,
            [$style.active]: isOnProfileRoute,
            'theme-bg': isOnProfileRoute
          }"
        >
          <Avatar :user="currentUserProfile" size="35px" />
        </div>
      </router-link>
      <UserMenu
        @mouseover="onMouseOver"
        v-if="visibleUserMenu"
        :class="$style.menu"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Avatar from '@/components/common/User/Avatar';
import UserMenu from '@/components/common/UserMenu/UserMenu';
import { SETTING_CODES } from '@/config/userSettings';

export default {
  components: {
    UserMenu,
    Avatar
  },
  data() {
    return {
      visibleUserMenu: false,
      windowWidth: 0,
      visibleUserMenuTimeout: null
    };
  },
  computed: {
    ...mapGetters('chats', ['unreadCount']),
    ...mapGetters([
      'currentUserId',
      'userSettingValue',
      'currentUserProfile',
      'isMobileLayout',
      'isTabletLayout'
    ]),
    isOnConversationsRoute() {
      return (
        this.$route &&
        this.$route.path &&
        this.$route.path.startsWith('/conversations')
      );
    },
    isOnForumRoute() {
      return (
        this.$route &&
        this.$route.path &&
        this.$route.path.startsWith('/discussions')
      );
    },
    isOnProfileRoute() {
      return (
        this.$route &&
        this.$route.params &&
        this.$route.params.userId === this.currentUserId &&
        this.$route &&
        this.$route.name === 'user-profile'
      );
    }
  },
  methods: {
    onMouseOver() {
      clearTimeout(this.visibleUserMenuTimeout);
      this.visibleUserMenu = true;
    },
    onMouseLeave(event) {
      // Work around the fact that Firefox triggers mouseleave when hovering over a select option outside $style.itemWrapper
      // https://stackoverflow.com/questions/46831247/select-triggers-mouseleave-event-on-parent-element-in-mozilla-firefox
      if (event.relatedTarget === null) return;

      this.visibleUserMenuTimeout = setTimeout(() => {
        this.visibleUserMenu = false;
      }, 300);
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    }
  },
  mounted() {
    this.getWindowWidth();
  },
  created() {
    this.SETTING_CODES = SETTING_CODES;
  }
};
</script>

<style lang="scss" module>
@import './ActionBar.scss';
</style>
