<template>
  <router-link
    :to="{ name: 'blog-post', params: { postId: blogPost.post_id } }"
    tag="div"
    :class="{
      [$style.wrapper]: true,
      [$style.official]: hasTag('official'),
      [$style.saveTheDate]: hasTag('save-the-date'),
      [$style.platformUpdates]: hasTag('platform-news')
    }"
  >
    <section :class="$style.header">
      <h2 :class="$style.title" v-line-clamp:1="1.5">
        <router-link
          :to="{ name: 'blog-post', params: { postId: blogPost.post_id } }"
          :title="blogPost.title"
          ><span v-html="blogPost.title"
        /></router-link>
      </h2>

      <time :datetime="blogPost.published_at" :class="$style.date">{{
        publishedAt(blogPost.published_at)
      }}</time>

      <ul :class="$style.tags">
        <li>
          <router-link
            :to="{ name: 'blog-filter', params: { tag: blogPost.tag.code } }"
          >
            {{
              blogPost.tag && blogPost.tag.label
                ? formatTagLabel(blogPost.tag.label)
                : ''
            }}</router-link
          >
        </li>
      </ul>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1830 135"
        :class="$style.decoration"
      >
        <g>
          <path
            d="M911.5.5H.5v20.4s427.2 94.3 911 94.3S1829 20.9 1829 20.9V.5H911.5z"
          />
        </g>
      </svg>
    </section>
    <section>
      <p v-html="blogPost.excerpt" v-line-clamp:2="1.5" />
    </section>
    <section :class="$style.footer">
      <p class="button-text">See more…</p>
      <p :class="$style.feedbackOverview" v-if="isFeedbackEnabled">
        <span>
          <img
            v-svg-inline
            :src="require(`@/assets/images/icons/reactions.svg`)"
            width="15"
            height="15"
          />
          {{ noOfReactions }}
        </span>
        <span>
          <img
            v-svg-inline
            :src="require(`@/assets/images/icons/comments.svg`)"
            width="15"
            height="15"
          />
          {{ noOfComments }}
        </span>
      </p>
    </section>
  </router-link>
</template>

<script>
import moment from 'moment';
import { formatTag } from '@/helpers/common';

export default {
  props: {
    blogPost: {
      type: Object
    }
  },
  computed: {
    isFeedbackEnabled() {
      return this.blogPost?.feedback_enabled || false;
    },
    noOfReactions() {
      return this.blogPost?.metadata?.reactions || 0;
    },
    noOfComments() {
      return this.blogPost?.metadata?.posts || 0;
    }
  },
  methods: {
    formatTagLabel(tag) {
      return formatTag(tag);
    },
    publishedAt(datetimeISO8601) {
      const m_publishedAt = moment(datetimeISO8601);

      return m_publishedAt.isValid()
        ? m_publishedAt.format('DD MMM, YYYY')
        : 'Not published yet';
    },
    hasTag(examinedTag) {
      return (
        this.blogPost &&
        this.blogPost.tag &&
        this.blogPost.tag.code === examinedTag
      );
    }
  }
};
</script>

<style module lang="scss">
@import './BlogPostPreview.scss';
</style>
