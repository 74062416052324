var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},_vm._l((Object.keys(_vm.container.days)),function(dayISO,dayIndex){
var _obj;
return _c('div',{key:dayIndex,class:( _obj = {}, _obj[_vm.$style.current] = _vm.isCurrent(dayISO), _obj ),style:({
      gridColumnEnd: ("span " + (_vm.getDayLength(dayISO)))
    })},[_c('DayHeader',{class:_vm.$style.header,attrs:{"dayISO":dayISO}}),_c('div',{class:_vm.$style.lessons,style:({
        gridTemplateRows: "1fr",
        gridTemplateColumns: ("repeat(" + (_vm.getDayLength(dayISO)) + ", 1fr)")
      })},[_vm._l((_vm.container.days[dayISO]),function(lesson,lessonIndex){
      var _obj;
return _c('MiniLesson',{key:lessonIndex,class:( _obj = {}, _obj[_vm.$style.lesson] = true, _obj[_vm.$style.active] = _vm.isActive(lesson), _obj ),attrs:{"lesson":lesson,"nowISO":_vm.nowISO,"isActive":_vm.isActive(lesson)}})}),(_vm.container.days[dayISO].length === 0)?_c('div',{class:_vm.$style.lesson},[(_vm.isLoading)?_c('InlineLoader'):_c('p',{class:_vm.$style.centered},[_vm._v(" No sessions today. ")])],1):_vm._e()],2)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }