<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <router-link
            to="/admin/cohorts"
            class="btn standard"
            :class="$style.backButton"
            ><i class="icon icon-chevron-left"
          /></router-link>
          <h6>Cohort - {{ cohortDetail.name }}</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :class="$style.table"
          :sort-options="{
            enabled: false
          }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'id'">
              <p class="adminPrimaryId">{{ props.row.id }}</p>
            </span>
            <span v-else-if="props.column.field === 'url'">
              <div
                v-if="props.row.url.length"
                @click="copyUrl(props.row.url)"
                :class="$style.url"
              >
                <p><i class="icon icon-paste" /> Copy</p>
                <i
                  id="urlCopied"
                  :class="$style.isCopied"
                  class="icon icon-check"
                />
              </div>
              <div v-else class="placeholder">No URL</div>
            </span>
          </template>
        </vue-good-table>
        <AdminCohortDetailStudents
          :class="$style.table"
          :cohortId="cohortDetail.cohort_id"
          :userGroupId="cohortDetail.user_group_id"
          :cohortMembers="cohortDetail.cohort_members"
          @reloadMembers="fetchCohortDetail($route.params.cohortId)"
        />
        <AdminCohortDetailPhases
          :class="$style.table"
          :cohortPhases="cohortDetail.cohort_phases"
        />
        <AdminCohortDetailGroups
          :class="$style.table"
          :userGroups="cohortDetail.user_groups"
          :cohortId="cohortDetail.cohort_id"
          @reloadMembers="fetchCohortDetail($route.params.cohortId)"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import AdminCohortDetailPhases from '@/views/Admin/AdminCohortDetail/AdminCohortDetailPhases';
import AdminCohortDetailGroups from '@/views/Admin/AdminCohortDetail/AdminCohortDetailGroups';
import AdminCohortDetailStudents from '@/views/Admin/AdminCohortDetail/AdminCohortDetailStudents';

import { VueGoodTable } from 'vue-good-table';
import copy from 'clipboard-copy';

import { GET_ADMIN_COHORT_DETAIL } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    AdminCohortDetailPhases,
    AdminCohortDetailGroups,
    AdminCohortDetailStudents,
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      cohortDetail: [],
      columns: [
        {
          label: 'Cohort ID',
          type: 'number',
          field: 'id'
        },
        {
          label: 'Start at',
          field: 'start'
        },
        {
          label: 'End at',
          field: 'end'
        },
        {
          label: 'Calendar Url',
          field: 'url'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchCohortDetail(this.$route.params.cohortId);
    }
  },
  methods: {
    copyUrl(data) {
      let inputEl = this.$el.querySelector('#urlCopied');
      copy(data)
        .then(() => {
          this.copied = true;
        })
        .catch(error => {
          logError(error);
          this.copied = false;
        });

      if (document.execCommand('copy') == true) {
        inputEl.style.opacity = '100';
        setTimeout(() => {
          inputEl.style.opacity = '0';
        }, 800);
      }
    },
    fetchCohortDetail(cohortId) {
      this.$store
        .dispatch(GET_ADMIN_COHORT_DETAIL, cohortId)
        .then(cohorts => {
          this.cohortDetail = cohorts;
        })
        .then(cohorts => {
          this.createCohortRow();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createCohortRow() {
      this.rows = [];

      this.rows.push({
        id: this.cohortDetail.cohort_id || '',
        start: moment(this.cohortDetail.start_at).format('DD.MM.yyyy') || '',
        end: moment(this.cohortDetail.end_at).format('DD.MM.yyyy') || '',
        url: this.cohortDetail.calendar_url || ''
      });
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminCohortDetail.scss';
</style>
