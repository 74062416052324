<template>
  <div :class="$style.wrapper">
    <Notice
      type="error"
      :contentStyle="
        'max-width: 100%; padding: 7px 7px 7px 56px; position: relative;'
      "
      :dismissable="false"
    >
      <template #icon>
        <i
          class="icon icon-exclamation-triangle"
          style="font-size: 21px; position: absolute; top: 14px; left: 14px"
        />
      </template>
      <slot>
        <p class="text-semibold">
          You might have found a bug
        </p>
        <p>
          Please help us to fix it and send us a screenshot of this message to
          <a href="mailto:support@edu.edu.mt">support@edu.edu.mt</a>. Thank you!
        </p>
      </slot>
      <div :class="$style.dumpValues">
        <span v-for="(value, property, index) in dumpValues" :key="property">
          {{ trim(property) }}:&nbsp;<strong>{{ normalize(value) }}</strong>
          {{ index + 1 === Object.keys(dumpValues).length ? '' : '|' }}
        </span>
      </div>
    </Notice>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import Notice from '@/components/notices/Notice/Notice';

export default {
  components: {
    Notice
  },
  props: {
    print: {
      type: Object,
      required: true
    }
  },
  methods: {
    trim(value) {
      return typeof value === 'string' ? value.trim() : value;
    },
    normalize(value) {
      if (typeof value === 'object') {
        return JSON.stringify(value);
      }

      return typeof value === 'string' ? value.trim() : value;
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
    userAgent() {
      return navigator && navigator.userAgent;
    },
    fullURL() {
      return document && document.URL;
    },
    nowISO() {
      return moment().toISOString(true);
    },
    dumpValues() {
      return {
        ...{
          date: this.nowISO,
          user_id: this.currentUserId,
          agent: this.userAgent,
          url: this.fullURL
        },
        ...this.print
      };
    }
  }
};
</script>

<style lang="scss" module>
@import './Debug.scss';
</style>
