<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <h6>Assignment Notes</h6>
          <router-link to="/admin" :class="$style.homeButton">
            <i class="icon icon-home"
          /></router-link>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          compactMode
          @on-column-filter="updateFilters"
          @on-sort-change="onSortChange"
          :sort-options="{
            enabled: true,
            initialSortBy: loadUrlWithSorting()
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
            v-if="props.row.assignmentUrl"
          >
            <span
              :ref="props.row.assignmentNoteId"
              v-if="props.column.field === 'assignmentId'"
            >
              <router-link
                :to="{
                  name: 'admin-assignment-node-detail',
                  params: { assignmentNoteId: props.row.assignmentNoteId }
                }"
              >
                {{ props.row.assignmentId }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'assignmentName'">
              <router-link
                :to="{
                  name: 'admin-assignment-node-detail',
                  params: { assignmentNoteId: props.row.assignmentNoteId }
                }"
              >
                {{ props.row.assignmentName }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'unitName'">
              <router-link
                v-if="props.row.unitName"
                :to="{
                  name: 'learning-unit-detail',
                  params: { learningUnitId: props.row.unitId }
                }"
              >
                {{ props.row.unitName }}
              </router-link>
              <span v-else>-</span>
            </span>
            <span v-else-if="props.column.field === 'authorName'">
              <router-link
                v-if="props.row.authorName"
                :to="{
                  name: 'admin-user-detail',
                  params: { userId: props.row.authorId }
                }"
                >{{ props.row.authorName }}
                <span class="adminSecondaryId">{{ props.row.authorId }}</span>
              </router-link>
              <span v-else>-</span>
            </span>
            <span v-else-if="props.column.field === 'link'">
              <a :href="props.row.link" target="_blank">visit page</a>
            </span>
          </template>
        </vue-good-table>
      </div>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { GET_ADMIN_ASSIGNMENT_NOTES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    Layout,
    NotFound,
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      notes: [],
      columns: [
        {
          label: 'assignment id',
          field: 'assignmentId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('assignmentId')
          }
        },
        {
          label: 'assignment name',
          field: 'assignmentName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('assignmentName')
          }
        },
        {
          label: 'module name',
          field: 'moduleName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('moduleName')
          }
        },
        {
          label: 'published at',
          field: 'publishedAt',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('publishedAt')
          }
        },
        {
          label: 'Assignment link',
          field: 'link'
        },
        {
          label: 'Unit',
          field: 'unitName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('unitName')
          }
        },
        {
          label: 'Author',
          field: 'authorName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('authorName')
          }
        }
      ],
      rows: [],
      scrollToId: null
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.params.assignmentNoteId) {
        vm.scrollToId = from.params.assignmentNoteId;
      }
    });
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchAssNotes(); // :)
    }
  },
  methods: {
    fetchAssNotes() {
      this.$store
        .dispatch(GET_ADMIN_ASSIGNMENT_NOTES)
        .then(notes => {
          this.notes = notes;
        })
        .then(notes => {
          this.createRows();
        })
        .then(() => {
          if (this.scrollToId) {
            this.$refs[this.scrollToId].scrollIntoView({ block: 'center' });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      this.rows = [];

      if (!this.notes) return [];

      this.notes.filter(note => {
        this.rows.push({
          assignmentId: note.assignment_id || [],
          assignmentName: note.assignment_name || [],
          assignmentUrl: note.assignment_url || [],
          moduleName: note.module_name || [],
          publishedAt:
            moment(note.published_at).format('DD.MM.yyyy HH:mm') || [],
          assignmentNoteId: note.assignment_note_id || [],
          link: note.assignment_url || [],
          unitName: note.unit_name || [],
          unitId: note.unit_id || [],
          authorName: this.authorName(note) || [],
          authorId: this.authorId(note) || []
        });
      });
    },
    authorName(note) {
      if (note && note.author) {
        return note.author.first_name + ' ' + note.author.last_name;
      }

      return '';
    },
    authorId(note) {
      if (note && note.author) {
        return note.author.user_id;
      }

      return '';
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'assignmentId':
          return this.$route.query.assignmentId;
          break;
        case 'assignmentName':
          return this.$route.query.assignmentName;
          break;
        case 'moduleName':
          return this.$route.query.moduleName;
          break;
        case 'publishedAt':
          return this.$route.query.publishedAt;
          break;
        case 'unitName':
          return this.$route.query.unitName;
          break;
        case 'authorName':
          return this.$route.query.authorName;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        assignmentId: column.assignmentId || [],
        assignmentName: column.assignmentName || [],
        moduleName: column.moduleName || [],
        publishedAt: column.publishedAt || [],
        unitName: column.unitName || [],
        authorName: column.authorName || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminAssignments.scss';
@import '../AdminTables.scss';
</style>
