<template>
  <ModalNew
    :class="$style.wrapper"
    :showModal="showModal"
    v-transfer-dom
    @close="handleClosed"
  >
    <p>{{ question }}</p>
    <div :class="$style.buttonsOperationConfirmation">
      <FormButton
        @click="agreedAction"
        :ghost="true"
        :class="{
          [$style.red]: optionOneStyle === 'red',
          [$style.green]: optionOneStyle === 'green'
        }"
        >{{ optionOne }}</FormButton
      >
      <FormButton @click="handleClosed" :ghost="true">{{
        optionTwo
      }}</FormButton>
    </div>
  </ModalNew>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    question: {
      type: String,
      default: 'Are you sure?'
    },
    optionOne: {
      type: String,
      default: 'Yes'
    },
    optionOneStyle: {
      type: String,
      default: 'Standard'
    },
    optionTwo: {
      type: String,
      default: 'Cancel'
    }
  },
  methods: {
    handleClosed() {
      this.$emit('handleClosed');
    },
    agreedAction() {
      this.$emit('agreedAction');
    }
  }
};
</script>

<style lang="scss" module>
@import './ConfirmationModal.scss';
</style>
