<template>
  <span :class="classes" class="form-input">
    <input v-bind="$attrs" :value="value" v-on="eventListeners" />
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    shaded: {
      type: Boolean,
      required: false,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      const classes = [this.$style.wrapper];

      if (this.shaded) {
        classes.push(this.$style.shaded);
      }

      if (this.withBorder) {
        classes.push(this.$style.whiteBackground);
      }

      return classes;
    },
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', event.target.value);
        }
      });
    }
  }
};
</script>

<style lang="scss" module>
@import './FormInput.scss';
</style>
