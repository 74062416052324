<template>
  <DoNotTrack placeholder="👤">
    <span
      :style="getStyle"
      :class="{
        avatar: true,
        default: !hasAvatar
      }"
    >
      <img
        v-if="hasAvatar"
        :src="avatarUrl"
        role="presentation"
        alt="User photo"
      />

      <img
        v-else
        src="~@/assets/images/icons/avatar-icon.svg"
        role="presentation"
        alt="User photo"
      />
    </span>
  </DoNotTrack>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String
    }
  },
  computed: {
    hasAvatar() {
      if (this.user && this.user.avatars && this.user.avatars.normal) {
        return true;
      }

      return false;
    },
    avatarUrl() {
      if (this.hasAvatar) {
        return this.user.avatars.normal;
      }

      return undefined;
    },
    getStyle() {
      if (this.user && this.user.role) {
        return `width: ${this.size}; height: ${this.size}; borderColor: ${this.user.role.color}`;
      }

      return `width: ${this.size}; height: ${this.size};`;
    }
  }
};
</script>

<style lang="scss">
@import './Avatar.scss';
</style>
