import mergeWith from 'lodash.mergewith';

import ApiService from '@/api/api.service';
import { UPLOAD_STEP } from '@/config/files';
import { ROLES } from '@/config/users';
import { addObjToArray } from '@/helpers/common';
import {
  findProfile,
  findProfileIdx,
  getUserTeamSlug,
  getUserTeamSlugs,
  hasUserRole,
  getFullUserProfile
} from '@/helpers/users';

import {
  SEARCH_USER_PROFILES,
  SEARCH_COHORTS,
  GET_BULK_USER_PROFILES,
  GET_USER_PROFILES,
  GET_USER_PROFILE,
  MAYBE_GET_USER_PROFILE,
  MAYBE_GET_USER_PROFILES,
  GET_USER_INFO,
  SEND_USER_WELCOME_EMAIL,
  UPDATE_USER_PROFILE,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_STATUS,
  GET_CURRENT_USER_PROFILE,
  GET_CURRENT_USER_PROFILE_DATA,
  GET_CURRENT_USER_COHORTS,
  SELECT_CURRENT_USER_COHORT,
  UPLOAD_AVATAR,
  POLL_AVATAR,
  DELETE_AVATAR
} from './actions.type';
import {
  RESET_STATE,
  SET_USER_PROFILES,
  SET_CURRENT_USER_PROFILE,
  SET_CURRENT_USER_COHORTS,
  ADD_USER_PROFILE,
  ADD_USER_PROFILE_SETTINGS,
  PURGE_USER_PROFILE_SETTINGS,
  SET_AVATARS,
  SET_CURRENT_USER_AVATARS,
  PURGE_AVATARS,
  SET_AVATAR_UPLOAD_STEP,
  SET_AVATAR_UPLOAD_PERCENT,
  PURGE_AVATAR_UPLOAD
} from './mutations.type';

export const state = {
  profiles: [],
  // currently used for current user avatar upload only, but it can work
  // as temporary storage for any user's avatar upload
  avatarUpload: {
    step: UPLOAD_STEP.waiting,
    uploadPercent: 0
  },
  // currently used for current user only, but it can work as temporary
  // storage for any user's settings
  profile: {},
  profileSettings: {
    personal_quote: null,
    bio: null,
    city_of_residence: null,
    country_id: null,
    links: null
  },
  currentUserCohorts: []
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  userProfiles(state) {
    return state.profiles;
  },
  studentProfilesByCohort(state) {
    return state.profiles.reduce(
      (accumulator, profile) => {
        const profileCohorts = (profile && profile.cohorts) || [];

        if (profileCohorts.length < 1) return accumulator;

        profileCohorts.map(cohort => {
          const cohortSlug = cohort.slug;

          // Put first occurence of a cohort to both cohorts & profiles structures
          if (!accumulator.profiles[cohortSlug]) {
            accumulator.cohorts.push(cohort);
            accumulator.profiles[cohortSlug] = [];
          }

          if (profile.role && profile.role.code == ROLES.student) {
            accumulator.profiles[cohortSlug].push(profile);
          }
        });

        return accumulator;
      },
      { cohorts: [], profiles: {} }
    );
  },
  userProfile: (state, getters) => userId => {
    if (userId === getters.currentUserId) {
      return getters.currentUserProfile;
    }

    return findProfile(state.profiles, userId);
  },
  currentUserProfile(state) {
    return state.profile;
  },
  isCurrentUserTeachingStaff(state, getters) {
    const currentUserProfile = getters.currentUserProfile;

    return !!(currentUserProfile && currentUserProfile.teaching_staff);
  },
  isCurrentUserStudent(state, getters) {
    return hasUserRole(getters.currentUserProfile, ROLES.student);
  },
  isCurrentUserAdmin(state, getters) {
    return hasUserRole(getters.currentUserProfile, ROLES.admin);
  },
  isCurrentUserExpert(state, getters) {
    return getters.isCurrentUserTeachingStaff;
    // return hasUserRole(getters.currentUserProfile, ROLES.expert);
  },
  isCurrentUserTutor(state, getters) {
    return getters.isCurrentUserTeachingStaff;
    // return hasUserRole(getters.currentUserProfile, ROLES.tutor);
  },
  isCurrentUserMentor(state, getters) {
    return getters.isCurrentUserTeachingStaff;
    // return hasUserRole(getters.currentUserProfile, ROLES.mentor);
  },
  isCurrentUserModuleLeader(state, getters) {
    return getters.isCurrentUserTeachingStaff;
    // return hasUserRole(getters.currentUserProfile, ROLES.moduleLeader);
  },
  currentUserAcceptedTerms(state, getters) {
    const currentUserProfile = getters.currentUserProfile;

    return !!(
      currentUserProfile &&
      currentUserProfile.tos_accepted_at &&
      currentUserProfile.pp_accepted_at
    );
  },
  currentUserCohorts(state) {
    return state.currentUserCohorts;
  },
  selectedCohortId(state) {
    if (!state.currentUserCohorts || !state.currentUserCohorts.length) {
      return null;
    }

    const selectedCohort = state.currentUserCohorts.find(
      cohort => cohort.is_selected === true
    );
    if (!selectedCohort) {
      return null;
    }

    return selectedCohort.cohort_id;
  },
  userTeamSlug: () => userProfile => {
    return getUserTeamSlug(userProfile);
  },
  currentUserTeamSlug(state, getters) {
    return getUserTeamSlug(getters.currentUserProfile);
  },
  currentUserTeamSlugs(state, getters) {
    return getUserTeamSlugs(getters.currentUserProfile);
  },
  userProfileSettings: (state, getters) => userId => {
    let profile = undefined;

    if (userId === getters.currentUserId) {
      profile = getters.currentUserProfile;
    } else {
      profile = findProfile(state.profiles, userId);
    }

    const profileDataWithSettings = mergeWith(
      profile.profile_data,
      {
        personal_quote: state.profileSettings.personal_quote,
        city_of_residence: state.profileSettings.city_of_residence
      },
      (profileValue, settingsValue) => {
        return settingsValue === null ? profileValue : settingsValue;
      }
    );

    return mergeWith(
      profile,
      {
        bio: state.profileSettings.bio,
        country_id: state.profileSettings.country_id,
        links: state.profileSettings.links
      },
      (profileValue, settingsValue, key) => {
        if (key === 'profile_data') {
          return profileDataWithSettings;
        }

        return settingsValue === null ? profileValue : settingsValue;
      }
    );
  },
  avatarUploadStep(state) {
    return state.avatarUpload.step;
  },
  avatarUploadPercent(state) {
    return state.avatarUpload.uploadPercent;
  },
  avatarUploadIsWaiting(state) {
    return state.avatarUpload.step === UPLOAD_STEP.waiting;
  },
  avatarIsUploading(state) {
    return state.avatarUpload.step === UPLOAD_STEP.uploading;
  },
  avatarIsBeingProcessed(state) {
    return state.avatarUpload.step === UPLOAD_STEP.processing;
  },
  hasAvatars: (state, getters) => userId => {
    let profile = undefined;

    if (userId === getters.currentUserId) {
      profile = getters.currentUserProfile;
    } else {
      profile = findProfile(state.profiles, userId);
    }

    if (!profile || !profile.avatars) {
      return false;
    }

    if (
      profile.avatars.normal &&
      profile.avatars.status &&
      profile.avatars.status === 'ok'
    ) {
      return true;
    }

    if (profile.avatars.normal && !profile.avatars.status) {
      return true;
    }

    return false;
  }
};

export const actions = {
  [SEARCH_USER_PROFILES](context, searchTerm) {
    return new Promise((resolve, reject) => {
      ApiService.searchUserProfiles(searchTerm)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SEARCH_COHORTS](context, searchTerm) {
    return new Promise((resolve, reject) => {
      ApiService.searchCohorts(searchTerm)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_BULK_USER_PROFILES](context, parameters) {
    return new Promise((resolve, reject) => {
      ApiService.getBulkUserProfiles(parameters)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_USER_PROFILES](context, parameters) {
    return new Promise((resolve, reject) => {
      ApiService.getUserProfiles(parameters)
        .then(({ data }) => {
          context.commit(SET_USER_PROFILES, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_CURRENT_USER_COHORTS](context, parameters) {
    return new Promise((resolve, reject) => {
      ApiService.getUserCohorts(parameters)
        .then(({ data }) => {
          context.commit(SET_CURRENT_USER_COHORTS, data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SELECT_CURRENT_USER_COHORT](context, cohortSlug) {
    return new Promise((resolve, reject) => {
      ApiService.selectUserCohort(cohortSlug)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_USER_PROFILE](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getBulkUserProfiles({ ids: [userId] })
        .then(({ data }) => {
          if (data.length) {
            if (userId === context.getters.currentUserId) {
              context.commit(SET_CURRENT_USER_PROFILE, data[0]);
            } else {
              context.commit(ADD_USER_PROFILE, data[0]);
            }
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [MAYBE_GET_USER_PROFILE](context, userId) {
    return new Promise((resolve, reject) => {
      if (Number.isNaN(parseInt(userId))) {
        return resolve();
      }

      const found = context.getters.userProfile(userId);

      if (found && found.hasOwnProperty('user_id')) {
        return resolve();
      }

      ApiService.getBulkUserProfiles({ ids: [userId] })
        .then(({ data }) => {
          if (data.length) {
            if (userId === context.getters.currentUserId) {
              context.commit(SET_CURRENT_USER_PROFILE, data[0]);
            } else {
              context.commit(ADD_USER_PROFILE, data[0]);
            }
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [MAYBE_GET_USER_PROFILES](context, userIds) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(userIds)) {
        return resolve();
      }

      const filteredUserIds = userIds
        .filter(userId => !Number.isNaN(parseInt(userId)))
        .filter(userId => userId !== context.getters.currentUserId)
        .filter(userId => {
          const found = context.getters.userProfile(userId);

          return found && found.hasOwnProperty('user_id') ? false : true;
        })
        .map(userId => parseInt(userId));

      if (!filteredUserIds.length) {
        return resolve();
      }

      ApiService.getBulkUserProfiles({ ids: filteredUserIds })
        .then(({ data }) => {
          const profiles = data;

          if (profiles.length) {
            profiles.map(profile => context.commit(ADD_USER_PROFILE, profile));
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_USER_INFO](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.getUserInfo(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [SEND_USER_WELCOME_EMAIL](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.sendUserWelcomeEmail(userId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_CURRENT_USER_PROFILE_DATA](context, currentUserId) {
    return new Promise((resolve, reject) => {
      ApiService.getBulkUserProfiles({ ids: [currentUserId] })
        .then(({ data }) => {
          if (data.length) {
            context.commit(SET_CURRENT_USER_PROFILE, data[0]);
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_USER_PROFILE](context, { userId, profileData }) {
    return new Promise((resolve, reject) => {
      ApiService.updateUserProfile(userId, profileData)
        .then(() => {
          return context.dispatch(GET_USER_PROFILE, userId);
        })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CHANGE_USER_PASSWORD](context, { userId, passwordData }) {
    return new Promise((resolve, reject) => {
      ApiService.changePassword(userId, passwordData)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CHANGE_USER_STATUS](context, desiredStatus) {
    return new Promise((resolve, reject) => {
      ApiService.changeUserStatus(desiredStatus)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_CURRENT_USER_PROFILE](context) {
    return Promise.all([
      context.dispatch(GET_CURRENT_USER_COHORTS, { inactive: false }),
      context.dispatch(
        GET_CURRENT_USER_PROFILE_DATA,
        context.getters.currentUserId
      )
    ]);
  },

  [UPLOAD_AVATAR](context, { file, userId }) {
    const onUploadProgress = progressEvent => {
      let percent = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      context.commit(SET_AVATAR_UPLOAD_PERCENT, percent);
    };

    return new Promise((resolve, reject) => {
      ApiService.uploadAvatar(file, onUploadProgress)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async [POLL_AVATAR](context, { userId, maxRequests = 30 }) {
    const msBetweenRequests = 500;
    let requestsCount = 0;
    let avatars = { status: null };

    const delay = ms => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    while (avatars.status !== 'ok' && requestsCount <= maxRequests) {
      if (requestsCount === maxRequests) {
        return Promise.reject(new Error('maxRequests reached'));
      }

      try {
        await delay(msBetweenRequests).then(async () => {
          const { data } = await ApiService.getAvatars(userId);
          avatars = data;
          requestsCount += 1;
        });
      } catch (error) {
        requestsCount += 1;
        return Promise.reject(error);
      }
    }

    if (context.getters.currentUserId === userId) {
      // Update both the list and also the detail profile data structures for current user
      context.commit(SET_CURRENT_USER_AVATARS, { userId, avatars });
      context.commit(SET_AVATARS, { userId, avatars });
    } else {
      // Update just the list of profiles for other users
      context.commit(SET_AVATARS, { userId, avatars });
    }

    context.commit(SET_AVATAR_UPLOAD_STEP, UPLOAD_STEP.success);

    return Promise.resolve();
  },

  [DELETE_AVATAR]() {
    return new Promise((resolve, reject) => {
      ApiService.deleteAvatar()
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  [SET_USER_PROFILES](state, profiles) {
    state.profiles = profiles;
  },
  [SET_CURRENT_USER_PROFILE](state, profile) {
    state.profile = getFullUserProfile(profile);
  },
  [SET_CURRENT_USER_COHORTS](state, cohorts) {
    state.currentUserCohorts = cohorts;
  },
  [ADD_USER_PROFILE](state, profile) {
    // push a profile to profiles if it's not there yet
    // otherwise overwrite an existing profile with a new, up-to-date, profile

    const userProfile = getFullUserProfile(profile);

    state.profiles = addObjToArray(state.profiles, userProfile, 'user_id');
  },
  [ADD_USER_PROFILE_SETTINGS](state, profileSettings) {
    state.profileSettings = {
      ...state.profileSettings,
      ...profileSettings
    };
  },
  [PURGE_USER_PROFILE_SETTINGS](state) {
    state.profileSettings = initialStateCopy.profileSettings;
  },
  [SET_AVATARS](state, { userId, avatars }) {
    const profiles = [...state.profiles];

    const profileIndex = findProfileIdx(state.profiles, userId);

    if (profileIndex === -1) {
      const newProfile = {
        user_id: userId,
        avatars
      };
      profiles.push(newProfile);
    } else {
      profiles[profileIndex].avatars = avatars;
    }

    state.profiles = profiles;
  },
  [SET_CURRENT_USER_AVATARS](state, { userId, avatars }) {
    state.profile.avatars = avatars;
  },
  [PURGE_AVATARS](state, userId) {
    const profileIndex = findProfileIdx(state.profiles, userId);

    if (profileIndex === -1) {
      return;
    }

    const profiles = [...state.profiles];
    profiles[profileIndex].avatars = null;

    state.profiles = profiles;
  },
  [SET_AVATAR_UPLOAD_STEP](state, step) {
    state.avatarUpload.step = step;
  },
  [SET_AVATAR_UPLOAD_PERCENT](state, percent) {
    state.avatarUpload.uploadPercent = percent;
  },
  [PURGE_AVATAR_UPLOAD](state) {
    state.avatarUpload.step = UPLOAD_STEP.waiting;
    state.avatarUpload.uploadPercent = 0;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
