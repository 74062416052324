<template>
  <NotFound v-if="display404" />
  <Layout variant="2" v-else>
    <template #pageSideA>
      <span v-if="userDetail">
        <AdminSideWidgetUserDetail
          :userDetail="userDetail"
          :studentDetail="studentDetail"
        />
      </span>
    </template>
    <template #pageContent>
      <div :class="$style.wrapper">
        <div :class="$style.header">
          <p></p>
          <div :class="$style.buttons">
            <router-link to="/admin/users" class="btn standard"
              >Users</router-link
            >

            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>

        <AdminUserDetailCohorts
          v-if="
            studentDetail &&
              userDetail &&
              userDetail.role &&
              userDetail.role.code === 'student'
          "
        />
        <div :class="$style.actionTables">
          <AdminUserDetailEdit
            @reloadUserDetail="fetchUserDetail()"
            :userDetail="userDetail"
            :class="$style.actionTable"
          />
          <AdminUserDetailActions
            :studentDetail="studentDetail"
            :userDetail="userDetail"
            :class="$style.actionTable"
          />
        </div>
        <AdminUserDetailEmails :class="$style.table" :userDetail="userDetail" />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import {
  GET_ADMIN_USER_DETAIL,
  GET_ADMIN_STUDENT_DETAIL
} from '@/store/actions.type';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';
import AdminSideWidgetUserDetail from '@/components/admin/AdminSideWidgetUserDetail/AdminSideWidgetUserDetail';
import AdminUserDetailCohorts from '@/views/Admin/AdminUserDetail/AdminUserDetailCohorts';
import AdminUserDetailActions from '@/views/Admin/AdminUserDetail/AdminUserDetailActions';
import AdminUserDetailEdit from '@/views/Admin/AdminUserDetail/AdminUserDetailEdit';
import AdminUserDetailEmails from '@/views/Admin/AdminUserDetail/AdminUserDetailEmails';

export default {
  components: {
    Layout,
    NotFound,
    AdminUserDetailCohorts,
    AdminSideWidgetUserDetail,
    AdminUserDetailActions,
    AdminUserDetailEdit,
    AdminUserDetailEmails
  },
  data() {
    return {
      display404: false,
      userDetail: null,
      studentDetail: null
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchUserDetail();
    }
  },
  methods: {
    fetchUserDetail() {
      this.$store
        .dispatch(GET_ADMIN_USER_DETAIL, this.$route.params.userId)
        .then(userDetail => {
          this.userDetail = userDetail;
        })
        .then(() => {
          this.fetchStudentDetail();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchStudentDetail() {
      if (
        this.userDetail &&
        this.userDetail.role &&
        this.userDetail.role.code === 'student'
      ) {
        this.$store
          .dispatch(GET_ADMIN_STUDENT_DETAIL, this.$route.params.userId)
          .then(studentDetail => {
            this.studentDetail = studentDetail;
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminUserDetail.scss';
@import '../AdminTables.scss';
</style>
