<template>
  <div>
    <h6>Cohort Phases</h6>
    <vue-good-table
      :pagination-options="{
        enabled: true
      }"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :columns="columns"
      :rows="rows"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'cohortPhaseId'">
          <span class="adminPrimaryId">{{ props.row.cohortPhaseId }}</span>
        </span>
        <span v-else-if="props.column.field === 'modulePhaseId'">
          <span class="adminSecondaryId">{{ props.row.modulePhaseId }}</span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';

export default {
  components: {
    VueGoodTable
  },
  props: {
    phases: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      display404: false,
      searchParams: {},
      columns: [
        {
          label: 'Cohort phase ID',
          field: 'cohortPhaseId',
          type: 'number',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('cohortPhaseId')
          }
        },
        {
          label: 'Name',
          field: 'phaseName',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('phaseName')
          }
        },
        {
          label: 'Type',
          field: 'type',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('type')
          }
        },
        {
          label: 'Module phase ID',
          field: 'modulePhaseId',
          type: 'number',
          width: '160px',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('modulePhaseId')
          }
        },
        {
          label: 'Duration',
          field: 'duration',
          filterOptions: {
            enabled: true,
            filterFn: this.filterGroups,
            filterValue: this.loadUrlWithFilters('duration')
          }
        }
      ],
      rows: []
    };
  },
  created() {
    this.createPhaseRows();
  },
  methods: {
    createPhaseRows() {
      this.phases.filter(phase => {
        this.rows.push({
          cohortPhaseId: phase.cohort_phase_id || '',
          phaseName: phase.label || '',
          type: phase.phase_type || '',
          modulePhaseId: phase.module_phase_id || '',
          duration:
            moment(phase.start_date).format('DD.MM.yyyy') +
              '  -  ' +
              moment(phase.end_date).format('DD.MM.yyyy') || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        phasesSort: sort[0].field || [],
        phasesOrder: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({
            ...this.$route.query,
            ...this.searchParams,
            ...sorting
          });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.phasesOrder) {
        return {
          field: this.$route.query.phasesSort,
          type: this.$route.query.phasesOrder
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'cohortPhaseId':
          return this.$route.query.cohortPhaseId;
          break;
        case 'phaseName':
          return this.$route.query.phaseName;
          break;
        case 'type':
          return this.$route.query.type;
          break;
        case 'duration':
          return this.$route.query.duration;
          break;
        case 'modulePhaseId':
          return this.$route.query.modulePhaseId;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        cohortPhaseId: column.cohortPhaseId || [],
        phaseName: column.phaseName || [],
        type: column.type || [],
        duration: column.duration || [],
        modulePhaseId: column.modulePhaseId || []
      };

      this.updateQueryFilters({ ...this.$route.query, ...this.searchParams });
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
</style>
