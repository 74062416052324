import { PROVIDERS } from '@/config/resources';

export const getProviderInfo = resource => {
  // Default to none provider
  // This ensures correct attributes structure
  // Default provider has no logo, no URL
  // but at least has a color at least
  let providerCode = 'none';
  let allowedProviderCodes = [
    'ditki',
    'ditky',
    'amboss',
    'oxford',
    '3d4medical',
    'biodigital',
    'edu'
  ];

  if (
    resource &&
    resource.resource_provider &&
    allowedProviderCodes.includes(resource.resource_provider.provider_code)
  ) {
    providerCode = resource.resource_provider.provider_code;
  }

  return PROVIDERS[providerCode];
};

export const getLibraryIcon = resource => {
  const defaultIcon = 'free-access-books.svg';

  if (!resource) return defaultIcon;

  const resourceType = resource.logical_type_code;

  switch (resourceType) {
    case 'video':
      return 'Video.svg';
      break;
    case 'audio':
      return 'Audio.svg';
      break;
    case 'tutorial':
      return 'interactive.svg';
      break;
    case 'text':
      return 'free-access-books.svg';
      break;
    default:
      return defaultIcon;
  }
};

export const getProviderLogo = (resource, variant = 'normal') => {
  if (!resource) return undefined;

  const provider = getProviderInfo(resource);

  if (!provider) return undefined;
  if (!provider.logo) return undefined;
  if (!provider.logo[variant]) return undefined;

  return provider.logo[variant];
};

export const getProviderColor = resource => {
  const provider = getProviderInfo(resource);

  return provider.color;
};
