<template>
  <span v-html="wrapAllMatches(text, regExp)" />
</template>

<script>
import { wrapAllMatches } from '@/helpers/common';

export default {
  name: 'HighlightMatch',
  props: {
    text: {
      type: String,
      required: true
    },
    regExp: {
      type: RegExp
    }
  },
  created() {
    this.wrapAllMatches = wrapAllMatches;
  }
};
</script>
