<template>
  <SideWidget :class="$style.wrapper">
    <template #name>Categories</template>
    <template #content>
      <div class="box-shadow-white p-1" :class="$style.content">
        <div :class="$style.group">
          <ul>
            <li>
              <router-link
                :to="{ name: 'blog' }"
                :class="{ [$style.activeCategory]: isActive(null) }"
                >All</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'blog-filter', params: { tag: 'official' } }"
                :class="{ [$style.activeCategory]: isActive('official') }"
                >Official Communication
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'blog-filter', params: { tag: 'platform-news' } }"
                :class="{ [$style.activeCategory]: isActive('platform-news') }"
              >
                Platform News</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'blog-filter', params: { tag: 'save-the-date' } }"
                :class="{ [$style.activeCategory]: isActive('save-the-date') }"
              >
                Save the Date</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import SideWidget from '@/components/common/SideWidget/SideWidget';

export default {
  components: {
    SideWidget
  },
  watch: {
    $route() {
      this.isActive(this.$route.params.tag);
    }
  },
  methods: {
    isActive(tag) {
      return this.$route.params.tag === tag;
    }
  }
};
</script>

<style module lang="scss">
@import './SideWidgetBlogOverview.scss';
</style>
