import DoNotTrack from './DoNotTrack/DoNotTrack';
import UserName from './User/Name';
import UserTeamName from './UserTeamName/UserTeamName';
import SeeMore from './SeeMore/SeeMore';
import Tooltip from './Tooltip/Tooltip';
import ModalNew from './ModalNew/ModalNew';
import EmojiPickerCustom from './EmojiPickerCustom/EmojiPicker';

export default {
  install(Vue) {
    Vue.component('DoNotTrack', DoNotTrack);
    Vue.component('UserName', UserName);
    Vue.component('UserTeamName', UserTeamName);
    Vue.component('SeeMore', SeeMore);
    Vue.component('Tooltip', Tooltip);
    Vue.component('ModalNew', ModalNew);
    Vue.component('EmojiPickerCustom', EmojiPickerCustom);
  }
};
