import { getUserFullNameWithTitles } from '@/helpers/users';

export const sumUnread = conversations => {
  if (!conversations || !conversations.length) {
    return 0;
  }

  return conversations.reduce(
    (acc, conversation) => acc + conversation.unread,
    0
  );
};

export const getConversationNameWithIds = (conversation, currentUserId) => {
  if (!conversation) {
    return [];
  }

  if (conversation.participants) {
    const other_participants = conversation.participants.filter(
      participant => currentUserId !== participant.user_id
    );

    return other_participants.map(participant => {
      const participants = {};

      if (other_participants.length == 1) {
        participants.name = getUserFullNameWithTitles(participant);
      } else if (participant.pre_nominal_title) {
        participants.name =
          participant.pre_nominal_title + ' ' + participant.first_name;
      } else {
        participants.name = participant.first_name;
      }
      participants.id = participant.user_id;
      return participants;
    });
  }

  return '';
};

export const charactersCounter = (message, maxCount) => {
  if (!maxCount) {
    return '';
  }

  if (!message || !message.length) {
    return maxCount;
  }

  return maxCount - new Blob([message]).size;
};
