var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.gradingSessionWrapper},[(!_vm.session)?[_c('InlineLoader')]:(!_vm.teamsFromSession.length)?[_c('div',{staticClass:"team empty"},[_vm._v(" No user groups associated with this session ")])]:_vm._l((_vm.teamsFromSession),function(team,teamIndex){
var _obj;
return _c('div',{key:teamIndex,staticClass:"team",attrs:{"data-cy":("session-team-" + teamIndex)}},[_c('h6',{attrs:{"title":("user_group_id: " + (team.user_group_id))}},[_vm._v(" "+_vm._s(team.object_name)+" ")]),_c('table',{class:( _obj = {}, _obj['grades'] = true, _obj['preview'] = _vm.inViewMode, _obj['without-grading'] = _vm.isWithoutGrading, _obj )},[_c('colgroup',[(_vm.columns.includes(_vm.COLUMNS.attendance))?_c('col',{class:_vm.$style.columnAttendance}):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.student))?_c('col',{class:_vm.$style.columnStudent}):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.score))?_c('col',{class:_vm.$style.columnScore}):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.score))?_c('col',{class:_vm.$style.columnScoreMax}):_vm._e(),(
              _vm.columns.includes(_vm.COLUMNS.comment) && _vm.isCurrentUserTeachingStaff
            )?_c('col',{class:_vm.$style.columnComment}):_vm._e(),(
              _vm.isCurrentUserTeachingStaff &&
                (_vm.columns.includes(_vm.COLUMNS.attendance) ||
                  _vm.columns.includes(_vm.COLUMNS.score) ||
                  _vm.columns.includes(_vm.COLUMNS.comment))
            )?_c('col',{class:_vm.$style.columnIndicator}):_vm._e()]),(!_vm.isWithoutGrading)?_c('thead',[(_vm.columns.includes(_vm.COLUMNS.attendance))?_c('th',{class:_vm.$style.columnAttendance},[_vm._v(" Attend. ")]):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.student))?_c('th',{class:_vm.$style.columnStudent},[_vm._v(" Student ")]):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.score))?_c('th',{class:_vm.$style.columnScore},[_vm._v(" Score ")]):_vm._e(),(_vm.columns.includes(_vm.COLUMNS.score))?_c('th',{class:_vm.$style.columnScoreMax}):_vm._e(),(
              _vm.columns.includes(_vm.COLUMNS.comment) && _vm.isCurrentUserTeachingStaff
            )?_c('th',{class:_vm.$style.columnComment},[_vm._v(" Comment "),_c('small',[_vm._v("Visible to teaching staff only")])]):_vm._e(),(
              _vm.isCurrentUserTeachingStaff &&
                (_vm.columns.includes(_vm.COLUMNS.attendance) ||
                  _vm.columns.includes(_vm.COLUMNS.score) ||
                  _vm.columns.includes(_vm.COLUMNS.comment))
            )?_c('th',{class:_vm.$style.columnIndicator}):_vm._e()]):_vm._e(),_c('tbody',[(_vm.teamMembers(team).length)?_vm._l((_vm.teamMembers(team)),function(user,index){return _c('GradingUser',{key:index,attrs:{"user":user,"session":_vm.session,"currentMode":_vm.currentMode,"scope":_vm.SCOPES.user,"isLastRow":index === _vm.teamMembers(team).length - 1,"data-cy":("grading-user-" + index)},on:{"has_errors":_vm.relayHasErrors,"has_edits":_vm.relayHasEdits,"in_action":_vm.handleInAction}})}):[_c('span',{staticStyle:{"text-align":"center","width":"100%"}},[_vm._v("No users found in this group.")])]],2)])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }