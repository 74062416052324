<template>
  <div
    v-click-outside="closeSubmenu"
    :class="{
      [$style.wrapper]: true,
      [$style.disabled]: disabled,
      [$style.opened]: submenuVisible,
      [$style.closed]: !submenuVisible,
      [$style.whiteBackground]: withBorder
    }"
    class="theme-color"
  >
    <div
      data-ref="header"
      class="header"
      :class="$style.header"
      @click="toggleSubmenu"
    >
      <span :class="$style.title">
        <slot name="title" />
      </span>
      <img
        :class="$style.action"
        v-if="action && getStateIcon(state)"
        :src="require('@/assets/images/icons/' + getStateIcon(state))"
      />
      <span v-else :class="$style.action">&nbsp;</span>
      <i
        :class="{
          icon: true,
          'icon-chevron-down': !submenuVisible
        }"
      />
    </div>
    <div
      :class="{
        [$style.subMenuWrapper]: true,
        [$style.whiteBackground]: withBorder
      }"
      class="sub-menu-wrapper"
    >
      <div
        data-ref="header"
        class="header"
        :class="$style.header"
        @click="toggleSubmenu"
      >
        <span :class="$style.title">
          <slot name="title" />
        </span>
        <img
          :class="$style.action"
          v-if="action && getStateIcon(state)"
          :src="require('@/assets/images/icons/' + getStateIcon(state))"
        />
        <span v-else :class="$style.action">&nbsp;</span>
        <i
          :class="{
            icon: true,
            'icon-chevron-up': submenuVisible
          }"
        />
      </div>
      <div class="sub-menu-background" :class="$style.subMenuBackground">
        <div
          data-ref="submenu"
          v-if="submenuVisible"
          class="sub-menu"
          :class="$style.subMenu"
        >
          <select
            v-bind="$attrs"
            :value="value"
            v-on="eventListeners"
            style="display: none;"
          >
            <slot name="options" />
          </select>
          <slot name="items" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import {
  STATES,
  ACTION_COMPLETE_TIMEOUT,
  CLEAR_SUCCESS_AFTER,
  CLEAR_ERROR_AFTER
} from '@/config/forms';

export default {
  directives: {
    ClickOutside
  },
  model: {
    event: 'change'
  },
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    submenuVisible: {
      type: Boolean,
      default: false
    },
    action: {
      type: Function // Parent must pass Function that returns a Function that returns a Promise
    },
    delayLoaderDisplay: {
      type: Number,
      default: ACTION_COMPLETE_TIMEOUT
    },
    clearSuccessAfter: {
      type: Number,
      default: CLEAR_SUCCESS_AFTER
    },
    clearErrorAfter: {
      type: Number,
      default: CLEAR_ERROR_AFTER
    },
    withBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      actionTimeoutId: undefined,
      successTimeoutId: undefined,
      errorTimeoutId: undefined,
      state: STATES.idle
    };
  },
  computed: {
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        change: event => {
          this.removeAllTimeouts();

          // this.$emit('state', STATES.typing);

          this.$emit('change', event.target.value);

          if (this.action && event.target.value) {
            this.setActionTimeout(() => {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.state = STATES.in_action;
            }, this.delayLoaderDisplay);

            this.action(event.target.value)
              .then(() => {
                this.removeActionTimeout(this.actionTimeoutId);

                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.state = STATES.success;
                this.$emit('success'); // Allow for direct use of @success at parent

                if (this.clearSuccessAfter && !this.successTimeoutId) {
                  this.setSuccessTimeout(() => {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.state = STATES.idle;
                  }, this.clearSuccessAfter);
                }
              })
              .catch(error => {
                this.removeActionTimeout(this.actionTimeoutId);

                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.state = STATES.error;
                this.$emit('error', error); // Allow for direct use of @error at parent

                if (this.clearErrorAfter && !this.errorTimeoutId) {
                  this.setErrorTimeout(() => {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.state = STATES.idle;
                  }, this.clearErrorAfter);
                }
              });
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.state = STATES.idle;
          }
        }
      });
    }
  },
  watch: {
    state(newState) {
      this.$emit('state', newState);
    }
  },
  methods: {
    updateState(newState) {
      this.state = newState;
    },
    getStateIcon(state) {
      if (state === STATES.typing) return 'saving.svg';
      if (state === STATES.in_action) return 'saving.svg';
      if (state === STATES.success) return 'saved.svg';
      if (state === STATES.error) return 'not_saved.svg';

      return undefined;
    },
    toggleSubmenu() {
      this.$emit('submenu_visibility', !this.submenuVisible);
    },
    closeSubmenu() {
      this.$emit('submenu_visibility', false);
    },
    setActionTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.actionTimeoutId = window.setTimeout(callback, delay);
    },
    setSuccessTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.successTimeoutId = window.setTimeout(callback, delay);
    },
    setErrorTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.errorTimeoutId = window.setTimeout(callback, delay);
    },
    removeActionTimeout(actionTimeoutId) {
      if (!actionTimeoutId) return;

      window.clearTimeout(actionTimeoutId);
      this.actionTimeoutId = undefined;
    },
    removeSuccessTimeout(successTimeoutId) {
      if (!successTimeoutId) return;

      window.clearTimeout(successTimeoutId);
      this.successTimeoutId = undefined;
    },
    removeErrorTimeout(errorTimeoutId) {
      if (!errorTimeoutId) return;

      window.clearTimeout(errorTimeoutId);
      this.errorTimeoutId = undefined;
    },
    removeAllTimeouts() {
      this.removeActionTimeout(this.actionTimeoutId);
      this.removeSuccessTimeout(this.successTimeoutId);
      this.removeErrorTimeout(this.errorTimeoutId);
    }
  },
  beforeDestroy() {
    this.removeAllTimeouts();
  }
};
</script>

<style lang="scss" module>
@import './FilterSelect.scss';
</style>
