export const GROUP_TYPES = {
  team: 'team',
  vcGroup: 'vc_group',
  crGroup: 'cr_group'
};

export const OPERATIONS = {
  deleteUser: 'delete_user',
  putStudentOnHold: 'put_student_on_hold'
};
