<template>
  <div :class="$style.wrapper">
    <div
      v-for="(dayISO, dayIndex) in Object.keys(container.days)"
      :key="dayIndex"
      :style="{
        gridColumnEnd: `span ${container.days[dayISO].length}`
      }"
      :class="{
        [$style.current]: isCurrent(dayISO)
      }"
      data-cy="day-container"
    >
      <DayHeader :class="$style.header" :dayISO="dayISO" />
      <div
        :class="$style.lessons"
        :style="{
          gridTemplateRows: `1fr`,
          gridTemplateColumns: `repeat(${container.days[dayISO].length}, 1fr)`
        }"
      >
        <div :class="$style.lesson">
          <InlineLoader v-if="isLoading" />

          <template
            v-else-if="container.type === CYCLE_TYPES.flex && !isPhoneLayout"
          >
            <div>
              <p class="text-semibold">Flex Week</p>
              <p
                v-html="
                  'As you travel back from your teaching hospital this week, make sure to schedule time to review the module and finish any open tasks that you may have.'
                "
                v-line-clamp:3="1"
              />
            </div>
          </template>

          <template
            v-else-if="container.type === CYCLE_TYPES.flex && isPhoneLayout"
          >
            <div :class="$style.note">
              <div :class="$style.typeIcon">
                <img
                  width="75"
                  v-svg-inline
                  :src="require('@/assets/images/icons/empty_flex.svg')"
                />
              </div>
              <div>
                <p class="text-semibold">Flex Week</p>
              </div>
            </div>
          </template>

          <template
            v-else-if="container.type === CYCLE_TYPES.cr && !isPhoneLayout"
          >
            <div>
              <p class="text-semibold" :class="$style.title">
                Clinical Rotation
              </p>
              <p v-line-clamp:3="1" v-if="!isPhoneLayout">
                This is your clinical rotation phase. Class may be out, but the
                learning doesn’t stop! Head to the library to find videos and
                readings that will enhance your clinical experience.
              </p>
              <span :class="$style.footerLink">
                <router-link
                  :to="{
                    name: 'learning-units-current'
                  }"
                >
                  Check the Units...
                </router-link>
              </span>
            </div>
          </template>
          <template
            v-else-if="container.type === CYCLE_TYPES.cr && isPhoneLayout"
          >
            <div :class="$style.note">
              <div :class="$style.typeIcon">
                <img
                  width="75"
                  v-svg-inline
                  :src="require('@/assets/images/icons/empty_cr.svg')"
                />
              </div>
              <div>
                <p class="text-semibold">Clinical Rotation</p>
                <span :class="$style.footerLink">
                  <router-link
                    :to="{
                      name: 'learning-units-current'
                    }"
                  >
                    Check Units
                  </router-link>
                </span>
              </div>
            </div>
          </template>

          <template
            v-else-if="container.type === CYCLE_TYPES.holiday && !isPhoneLayout"
          >
            <div>
              <p class="text-semibold">It's Holiday!</p>
              <p v-line-clamp:3="1">
                More time to study, yay!
              </p>
            </div>
          </template>
          <template
            v-else-if="container.type === CYCLE_TYPES.holiday && isPhoneLayout"
          >
            <div :class="$style.note">
              <div :class="$style.typeIcon">
                <img
                  width="75"
                  v-svg-inline
                  :src="require('@/assets/images/icons/empty_holiday.svg')"
                />
              </div>
              <div>
                <p class="text-semibold">It's Holiday!</p>
              </div>
            </div>
          </template>

          <p v-else :class="$style.centered">
            No sessions today.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { CYCLE_TYPES } from '@/config/schedule';
import DayHeader from '@/views/MiniSchedule/DayHeader';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

export default {
  name: 'MiniScheduleDaysEmpty',
  components: {
    DayHeader,
    InlineLoader
  },
  props: {
    container: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isPhoneLayout'])
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  },
  methods: {
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    }
  }
};
</script>

<style lang="scss" module>
@import './MiniSchedule.scss';
@import './MiniScheduleDaysSpanEmpty.scss';
@import './WeekTypeFlex.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>
