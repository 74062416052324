<template>
  <div :class="$style.wrapper">
    <template v-for="cohort in orderedCohorts">
      <UsersGroup
        class="box-shadow-white"
        :class="{ [$style.isCurrentCohort]: isCurrentCohort(cohort) }"
        v-if="orderedCohorts.length && cohort && cohort.slug"
        :users="studentProfilesByCohort.profiles[cohort.slug]"
        :key="cohort.slug"
        :sectionTitle="cohort.name"
        :group="cohort.slug"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_USER_PROFILES } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import UsersGroup from '@/components/user-profile/UsersGroup/UsersGroup';

export default {
  components: {
    UsersGroup
  },
  computed: {
    ...mapGetters(['studentProfilesByCohort', 'currentUserCohorts']),
    orderedCohorts() {
      if (
        this.studentProfilesByCohort &&
        this.studentProfilesByCohort.cohorts &&
        this.currentUserCohorts[0]
      ) {
        const studentCohorts = this.studentProfilesByCohort.cohorts;

        const index = studentCohorts.findIndex(cohort =>
          this.isCurrentCohort(cohort)
        );

        studentCohorts.push(...studentCohorts.splice(0, index));

        return studentCohorts;
      }

      return [];
    }
  },
  created() {
    this.$store.dispatch(GET_USER_PROFILES, { inactive: true }).catch(error => {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    });
  },
  methods: {
    isCurrentCohort(cohort) {
      return cohort.cohort_id == this.currentUserCohorts[0].cohort_id;
    }
  }
};
</script>

<style lang="scss" module>
@import './UsersStudents.scss';
</style>
