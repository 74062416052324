<template>
  <div :title="false">
    <div :class="$style.wrapper">
      <div :class="$style.content">
        <p class="text-dark-grey" :class="$style.cohortsTitle">
          Which cohorts will be able to see this blog post?
        </p>
        <FormLabel>
          <FormCheckbox
            :class="$style.checkbox"
            v-model="allCohorts"
            @click.native.prevent="selectAllCohorts()"
          />
          All Cohorts
        </FormLabel>
        <p class="text-dark-grey" :class="$style.cohortsSubtitle">
          Or select individual cohorts below:
        </p>
        <ul :class="$style.cohortsList" data-cy="cohorts_list">
          <li v-for="(cohort, index) in cohorts" :key="index">
            <FormLabel>
              <FormCheckbox
                :class="$style.checkbox"
                :value="cohort.cohort_id"
                v-model="selectedCohortIds"
                @change="cohortPicker(cohort.cohort_id)"
              />
              {{ cohort.name }}
            </FormLabel>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import {
  GET_ADMIN_USER_DETAIL,
  GET_ADMIN_COHORTS,
  ASSIGN_COHORTS_ADMIN_BLOG_DETAIL,
  DELETE_COHORTS_ADMIN_BLOG_DETAIL
} from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  data() {
    return {
      cohorts: [],
      newlySelectedCohorts: [],
      allCohorts: true
    };
  },
  props: {
    currentCohortsFromBE: {
      type: Array,
      default: () => {}
    },
    postId: {
      type: Number
    }
  },
  created() {
    this.fetchCohorts();
  },
  watch: {
    newlySelectedCohorts() {
      this.allCohorts = !this.selectedCohortIds.length ? true : false;
    }
  },
  computed: {
    selectedCohortIds: {
      get() {
        return this.newlySelectedCohorts;
      },
      set(value) {
        this.newlySelectedCohorts = value;
      }
    }
  },
  methods: {
    ...mapActions([ASSIGN_COHORTS_ADMIN_BLOG_DETAIL]),
    fetchCohorts() {
      this.cohorts = [];
      this.$store
        .dispatch(GET_ADMIN_COHORTS)
        .then(cohorts => {
          this.cohorts = cohorts;
        })
        .then(() => {
          if (this.currentCohortsFromBE) {
            this.newlySelectedCohorts = this.currentCohortsFromBE;
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    cohortPicker(cohortId) {
      if (this.newlySelectedCohorts.includes(cohortId)) {
        this.$store
          .dispatch(ASSIGN_COHORTS_ADMIN_BLOG_DETAIL, {
            postId: this.$route.params.postId || this.postId,
            cohortId: cohortId
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      } else {
        this.$store
          .dispatch(DELETE_COHORTS_ADMIN_BLOG_DETAIL, {
            postId: this.$route.params.postId || this.postId,
            cohortId: cohortId
          })
          .catch(error => {
            this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
          });
      }
    },
    selectAllCohorts() {
      // all cohorts selected => don't send any cohorts to BE.
      if (this.newlySelectedCohorts.length) {
        this.removeAllCohorts();
        this.newlySelectedCohorts = [];
      }
      this.allCohorts = true;
    },
    removeAllCohorts() {
      // remove cohorts from BE and from FE data.
      if (this.currentCohortsFromBE) {
        this.currentCohortsFromBE.filter(cohort => {
          this.$store
            .dispatch(DELETE_COHORTS_ADMIN_BLOG_DETAIL, {
              postId: this.$route.params.postId || this.postId,
              cohortId: cohort
            })
            .catch(error => {
              this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
            });
        });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './AdminCohortPicker.scss';
</style>
