<template>
  <div :class="$style.wrapper">
    <h6>
      Manage users in this group:
    </h6>
    <p :class="$style.notice" class="placeholder">
      Carefully! Removing or adding a new user is not an operation that requires
      approval!
    </p>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      compactMode
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom'
      }"
    >
      <div slot="table-actions">
        <AdminSearchNewUser
          :addUserGroupId="userGroupId"
          :eligibleUsersGroupId="userGroupId"
          @reloadMembers="reloadMembers"
        />
      </div>
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'removeMember'"
          @click="handleModalOpened(props.row.memberId)"
        >
          <i :class="$style.closeIcon" class="icon icon-close" />
        </span>
        <span v-else-if="props.column.field === 'memberId'">
          <router-link
            class="adminPrimaryId"
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.memberId }
            }"
          >
            {{ props.row.memberId }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'memberFirstName'">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.memberId }
            }"
          >
            {{ props.row.memberFirstName }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'memberLastName'">
          <router-link
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.memberId }
            }"
          >
            {{ props.row.memberLastName }}
          </router-link>
        </span>
      </template>
    </vue-good-table>

    <!-- Confirmation pop up for Approve  -->
    <ConfirmationModal
      :question="removeMemberQuestion"
      @handleClosed="handleModalClosed"
      @agreedAction="removeMember(approveID)"
      :showModal="showModal"
      :optionOne="'Remove'"
      :optionOneStyle="'red'"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { VueGoodTable } from 'vue-good-table';

import { ADMIN_GROUP_DETAIL_REMOVE_USER } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import AdminSearchNewUser from '@/components/admin/AdminSearchNewUser/AdminSearchNewUser';
import ConfirmationModal from '@/components/admin/ConfirmationModal/ConfirmationModal';

export default {
  components: {
    VueGoodTable,
    AdminSearchNewUser,
    ConfirmationModal
  },
  props: {
    groupMembers: {
      type: Array,
      default: () => []
    },
    userGroupId: {
      type: Number
    }
  },
  data() {
    return {
      removeMemberQuestion: 'Are you sure you want to remove this member?',
      showModal: false,
      approveID: null,
      columns: [
        {
          label: '',
          field: 'removeMember',
          width: '50px',
          tdClass: 'center-icon',
          thClass: 'removeMemberHeader',
          filterOptions: {
            styleClass: 'removeMemberHeader'
          }
        },
        {
          label: 'User ID',
          field: 'memberId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('memberId')
          }
        },
        {
          label: 'First name',
          field: 'memberFirstName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('memberFirstName')
          }
        },
        {
          label: 'Last name',
          field: 'memberLastName',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('memberLastName')
          }
        }
      ],
      rows: []
    };
  },
  watch: {
    groupMembers() {
      this.createStudentsRows();
    }
  },
  methods: {
    handleModalOpened(approveID) {
      this.approveID = approveID;
      this.showModal = true;
    },
    handleModalClosed() {
      this.approveID = null;
      this.showModal = false;
    },
    createStudentsRows() {
      this.rows = [];

      if (!this.groupMembers) return [];
      this.groupMembers.filter(member => {
        this.rows.push({
          memberId: member.user_id || '',
          memberFirstName: member.first_name || '',
          memberLastName: member.last_name || ''
        });
      });
    },
    removeMember(id) {
      this.$store
        .dispatch(ADMIN_GROUP_DETAIL_REMOVE_USER, {
          userGroupId: this.userGroupId,
          userId: id
        })
        .then(() => {
          this.$emit('reloadMembers');
          this.showModal = false;
          this.approveID = null;
        })
        .then(() => {
          this.createStudentsRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    reloadMembers() {
      this.$emit('reloadMembers');
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'memberId':
          return this.$route.query.memberId;
          break;
        case 'memberFirstName':
          return this.$route.query.memberFirstName;
          break;
        case 'memberLastName':
          return this.$route.query.memberLastName;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        memberId: column.memberId || '',
        memberFirstName: column.memberFirstName || '',
        memberLastName: column.memberLastName || ''
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';

@import './AdminGroupDetail.scss';
</style>
