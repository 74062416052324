import { SITES } from '@/config/tracking';

// Hard-coded element id that needs to be present in index.html
const scriptPlaceholderId = '#mf';

const getTrackingSiteIndex = () => {
  return SITES.findIndex(site => site.hostname === window.location.hostname);
};

const shouldTrackCurrentSite = () => {
  return getTrackingSiteIndex() !== -1;
};

const Mouseflow = {
  getTrackingSite() {
    const index = getTrackingSiteIndex();
    return SITES[index];
  },
  createMfq(options) {
    window._mfq = window._mfq || [];

    if (options && options.tags) {
      options.tags.map(tag => {
        _mfq.push(['tag', tag]);
      });
    }
  },
  addTrackingScript(tracking_key) {
    if (!tracking_key) return;

    const mf = document.querySelector(scriptPlaceholderId);

    if (!mf) {
      return;
    }

    // Bail out if already activated (prevents memory leaking?)
    if (this.isActivated() === false) {
      mf.type = 'text/javascript';
      mf.async = true;
      mf.src = '//cdn.mouseflow.com/projects/' + tracking_key + '.js';
    }
  },
  install(Vue) {
    Vue.prototype.$mf = {
      push: this.push,
      logRouteChange: this.logRouteChange,
      logFullPath: this.logFullPath,
      activate: this.activate,
      isActivated: this.isActivated
    };
  },
  isActivated() {
    const mf = document.querySelector(scriptPlaceholderId);

    // If placeholder found check if the src attribute has value and convert that to Boolean
    if (mf) return !!mf.src;

    return false;
  },
  activate(options) {
    if (shouldTrackCurrentSite()) {
      this.createMfq(options);
      const trackingSite = this.getTrackingSite();
      this.addTrackingScript(trackingSite.tracking_key);
    }
  },
  push(...args) {
    if (!shouldTrackCurrentSite()) return;

    if (this.isActivated() === false) return;

    if (!window._mfq) return;

    window._mfq.push([...args]);
  },
  logFullPath(fullPath) {
    if (!shouldTrackCurrentSite()) return;

    if (this.isActivated() === false) return;

    this.push('newPageView', fullPath);
  },
  logRouteChange(to, options) {
    if (!shouldTrackCurrentSite()) return;

    if (this.isActivated() === false) return;

    const path =
      (options || {}).includeRouteParams === false
        ? to.matched[to.matched.length - 1].path
        : to.fullPath;

    this.push('newPageView', path);
  }
};

export default Mouseflow;
