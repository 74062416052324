<template>
  <ul :class="$style.wrapper">
    <template v-if="learningUnits && learningUnits.length">
      <li v-for="(learningUnitId, index) in learningUnits" :key="index">
        <router-link
          :to="{
            name: 'learning-unit-detail',
            params: {
              learningUnitId: `${learningUnitId}`
            }
          }"
          target="_blank"
        >
          Unit Page
        </router-link>
      </li>
    </template>

    <template v-else></template>
  </ul>
</template>

<script>
export default {
  name: 'ListUnits',
  props: {
    learningUnits: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" module>
.wrapper {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0 7px 0 0;
  }

  li > a {
    display: flex;
    font-weight: 600;
  }

  li > span {
    display: flex;
  }
}
</style>
