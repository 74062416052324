import { logWarning } from '@/helpers/errors';
import { ROLES } from '@/config/users';
import {
  SOCIAL_LINKS_SKELETON,
  USER_PROFILE_SKELETON,
  ROLE_SKELETON,
  AVATARS_SKELETON,
  PROFILE_DATA_SKELETON
} from '@/store/users.constants';

import { TEAM_SKELETON, TEAM_MEMBER_SKELETON } from '@/store/teams.constants';

export const getUserFullName = userProfile => {
  if (!userProfile) {
    return '';
  }

  let firstName = userProfile.first_name || '';
  let lastName = userProfile.last_name || '';
  let fullName = userProfile.full_name || '';

  firstName = firstName.trim();
  lastName = lastName.trim();
  fullName = fullName.trim();

  return fullName ? fullName : `${firstName} ${lastName}`;
};

export const getUserFullNameWithTitles = userProfile => {
  if (!userProfile) {
    return '';
  }

  let preTitles = userProfile.pre_nominal_title || '';

  return `${preTitles} ` + getUserFullName(userProfile);
};

export const getUserLocation = (userProfile, countriesList) => {
  if (!userProfile) {
    return '';
  }

  let separator = '';
  let countryName = '';
  let city =
    (userProfile &&
      userProfile.profile_data &&
      userProfile.profile_data.city_of_residence) ||
    '';
  let country = countriesList.find(
    country => country.country_id === userProfile.country_id
  );
  if (country) {
    countryName = country.name || '';
  }

  if (city && countryName) {
    separator = ', ';
  }

  city = city.trim();
  countryName = countryName.trim();

  return `${city}${separator}${countryName}`;
};

// Find profile by user ID in array of profiles as specified by API.
export const findProfile = (profiles, userId) => {
  if (!userId || !profiles) {
    return {};
  }

  return (
    profiles.find(
      profile =>
        profile &&
        profile.user_id &&
        profile.user_id.toString() === userId.toString()
    ) || {}
  );
};

// Find profile index by user ID in array of profiles as specified by API.
export const findProfileIdx = (profiles, userId) => {
  if (!userId) {
    return -1;
  }

  if (!Array.isArray(profiles)) {
    return -1;
  }

  return profiles.findIndex(profile => {
    if (!profile) return false;
    if (!profile.user_id) return false;

    return profile.user_id.toString() === userId.toString();
  });
};

// Retrieve team slug from user profile.
// Return undefined in case a user is a not member of any team.
// Return first team in case a user is a member of more teams.
export const getUserTeamSlug = userProfile => {
  if (!userProfile) {
    return undefined;
  }

  if (userProfile.teams && userProfile.teams.length) {
    if (userProfile.teams.length > 1) {
      logWarning('User is a member of more teams', { silenceSentry: true });
    }

    return userProfile.teams[0].slug;
  }

  return undefined;
};

export const getUserTeamSlugs = userProfile => {
  if (!userProfile) {
    return undefined;
  }

  if (userProfile.teams && userProfile.teams.length) {
    return userProfile.teams.map(team => team.slug);
  }

  return undefined;
};

export const hasUserRole = (userProfile, userRole) => {
  if (!userProfile) {
    return undefined;
  }

  if (!userRole) {
    throw new Error('User role required!');
    return undefined;
  }

  if (!Object.values(ROLES).includes(userRole)) {
    throw new Error(`Unknown user role ${userRole}!`);
    return undefined;
  }

  if (userProfile.role && userProfile.role === userRole) {
    return true;
  }

  if (userProfile.role && userProfile.role.code === userRole) {
    return true;
  }

  // todo test coverage
  // todo add fallback for (soon to be deprecated) `teaching_staff`` flag in user profile

  return false;
};

export const getFullRole = role => {
  if (!role) return ROLE_SKELETON;

  return { ...ROLE_SKELETON, ...role };
};

export const getFullAvatars = avatars => {
  if (!avatars) return AVATARS_SKELETON;

  return { ...AVATARS_SKELETON, ...avatars };
};

export const getFullSocialLinks = links => {
  if (!links) return SOCIAL_LINKS_SKELETON;

  return { ...SOCIAL_LINKS_SKELETON, ...links };
};

export const getFullTeamMember = member => {
  if (!member) return TEAM_MEMBER_SKELETON;

  let fullMember = { ...TEAM_MEMBER_SKELETON, ...member };

  fullMember.role = getFullRole(member.role);
  fullMember.avatars = getFullAvatars(member.avatars);

  return fullMember;
};

export const getFullTeam = team => {
  let fullTeam = { ...TEAM_SKELETON, ...team };

  fullTeam.members = team.members
    ? team.members.map(member => getFullTeamMember(member))
    : [];

  return fullTeam;
};

export const getFullProfileData = profileData => {
  if (!profileData) return PROFILE_DATA_SKELETON;

  return { ...PROFILE_DATA_SKELETON, ...profileData };
};

export const getFullUserProfile = profile => {
  if (!profile) return;

  let userProfile = { ...USER_PROFILE_SKELETON, ...profile };

  const allowsMultipleTeams = !!(
    profile.teams &&
    Array.isArray(profile.teams) &&
    !profile.team
  );

  if (!allowsMultipleTeams) {
    userProfile.teams = [getFullTeam(profile.team)];
  } else {
    userProfile.teams = profile.teams.map(team => getFullTeam(team));
  }

  userProfile.links = getFullSocialLinks(profile.links);
  userProfile.profile_data = getFullProfileData(profile.profile_data);

  return userProfile;
};
