<template>
  <div :class="$style.wrapper" v-if="!userCreated">
    <h5>Create new user</h5>
    <form aria-label="Enter your e-mail" @submit.prevent="createUser">
      <FormLabel>
        <span>User role</span>
        <FilterSelect
          :submenuVisible="roleSubmenuVisible"
          @submenu_visibility="selectRole"
        >
          <template #title>
            <span>{{ selectedRole.label }}</span>
          </template>
          <template #items>
            <fieldset
              v-for="role in roles"
              :value="role.label"
              :key="role.label"
              @click="handleRoleChange(role)"
            >
              <FormLabel>
                {{ role.label }}
              </FormLabel>
            </fieldset>
          </template>
        </FilterSelect>
      </FormLabel>
      <FormLabel v-if="selectedStudent">
        <span>Student ID</span>
        <FormInput name="id" v-model="id" data-cy="id" />
      </FormLabel>
      <FormLabel>
        <span>First name</span>
        <FormInput name="firstName" v-model="firstName" data-cy="firstName" />
      </FormLabel>
      <FormLabel>
        <span>Last name</span>
        <FormInput name="lastName" v-model="lastName" data-cy="lastName" />
      </FormLabel>
      <FormLabel>
        <span>EDU e-mail</span>
        <FormInput data-ref="email" name="email" v-model="email" />
        <FormFieldErrors :errors="emailErrors" />
      </FormLabel>
      <FormFieldErrors :errors="[{ message: errorMessages }]" />
      <FormButton :standard="true">Create</FormButton>
    </form>
  </div>
  <div :class="$style.wrapperCreated" v-else>
    <i class="icon icon-check" />
    <p>
      User <span>{{ firstName }} {{ lastName }}</span> with
      <span>{{ selectedRole.label }}</span> role was successfully created!
    </p>
  </div>
</template>

<script>
import { REGISTER_CONFIG } from '@/config/registration';
import { ROLES } from '@/config/users';

import { mapActions } from 'vuex';
import { hasErrorStatus } from '@/helpers/errors';

import { GET_ADMIN_USER_ROLES, CREATE_NEW_USER } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

import focusMixin from '@/mixins/focusMixin';
import validatedEmailMixin from '@/mixins/validatedEmailMixin';

export default {
  components: {
    FilterSelect
  },
  mixins: [focusMixin, validatedEmailMixin],
  data() {
    return {
      roles: [],
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      selectedRole: {},
      roleSubmenuVisible: false,
      userCreated: false,
      errorMessages: ''
    };
  },
  created() {
    this.fetchRoles();
  },
  computed: {
    selectedStudent() {
      return this.selectedRole.role_code === ROLES.student;
    }
  },
  methods: {
    ...mapActions([CREATE_NEW_USER]),
    checkForm() {
      this.errors = [];
      this.errorMessages = '';

      if (!this.validateEmail) {
        this.focusInput(this.$el.querySelector('[data-ref=email]'));
        this.errors.push(REGISTER_CONFIG.errors.email);
      }

      if (
        (this.selectedRole.role_code === ROLES.student && !this.id.trim()) ||
        !this.firstName.trim() ||
        !this.lastName.trim() ||
        this.selectedRole === {}
      ) {
        this.errorMessages = 'Please fill out all inputs';
      }

      if (!this.errors.length && this.errorMessages === '') {
        return true;
      }

      return false;
    },
    fetchRoles() {
      this.$store
        .dispatch(GET_ADMIN_USER_ROLES)
        .then(roles => {
          this.roles = roles;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleroleSubmenuChange(newSubmenuState) {
      this.roleSubmenuVisible = newSubmenuState;
    },
    handleRoleChange(role) {
      if (role.role_code !== ROLES.student) {
        this.id = '';
      }

      this.selectedRole = role;
      this.roleSubmenuVisible = false;
    },
    selectRole(newSubmenuState) {
      this.roleSubmenuVisible = newSubmenuState;
    },
    createUser() {
      if (this.checkForm()) {
        this.CREATE_NEW_USER({
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          role_code: this.selectedRole.role_code,
          student_id: this.id
        })
          .then(() => {
            this.userCreated = true;

            // when user is created, order them in desc order to see newly created user
            if (
              this.$route.query.sort != 'id' ||
              this.$route.query.order != 'desc'
            ) {
              this.$router.replace({
                query: { sort: 'id', order: 'desc' }
              });
            }
          })
          .then(() => {
            this.$emit('reloadPage');
          })
          .catch(error => {
            if (hasErrorStatus(409, error)) {
              this.errorMessages = error.response.data.message;
            } else {
              this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminCreateUser.scss';
</style>
