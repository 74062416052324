<script>
import moment from 'moment';

export default {
  props: {
    useScopedSlot: {
      type: Boolean,
      default: true
    },
    startAt: {
      type: String,
      default: ''
    },
    freezeTime: {
      type: Boolean,
      default: false
    },
    tickEverySeconds: {
      type: Number,
      default: 1
    },
    emit: {
      type: String,
      default: ''
    },
    emitAfterTicks: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      currentTime: this.startAt ? moment(this.startAt) : moment(),
      intervalId: null,
      ticksToEmit: this.emitAfterTicks
    };
  },
  watch: {
    currentTime(newTime) {
      if (this.emit) {
        const cadence = parseInt(this.emitAfterTicks);

        this.ticksToEmit = this.ticksToEmit - 1;

        if (this.ticksToEmit === 0) {
          this.$emit(this.emit, newTime.toISOString(true));
          this.ticksToEmit = cadence;
        }
      }
    }
  },
  created() {
    this.intervalId = setInterval(this.tick, this.tickEverySeconds * 1000);
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    tick() {
      if (this.freezeTime) return;

      this.currentTime = this.startAt
        ? moment(this.currentTime).add(this.tickEverySeconds, 'seconds')
        : moment();
    }
  },
  render() {
    return this.useScopedSlot
      ? this.$scopedSlots.default({
          currentTime: this.currentTime.toISOString(true)
        })
      : null;
  }
};
</script>
