import { AVATARS_SKELETON, ROLE_SKELETON } from './users.constants';

export const TEAM_MEMBER_SKELETON = {
  user_id: null,
  first_name: null,
  last_name: null,
  role: ROLE_SKELETON,
  avatars: AVATARS_SKELETON,
  status: null
};

export const TEAM_SKELETON = {
  team_id: null,
  cohort_id: null,
  cohort_phase_ids: [],
  slug: null,
  name: null,
  description: null,
  members: []
};
