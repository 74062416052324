<template>
  <div :class="$style.wrapper">
    <FormLabel
      v-for="option in options"
      :key="option[optionIdAttrName(option)]"
      :class="{
        [$style.option]: true,
        [$style.disabled]: disabled,
        correct: isCorrect(option),
        incorrect: isIncorrect(option)
      }"
    >
      <FormRadio
        :class="{
          [$style.input]: true,
          [$style.gold]: useFallbackResultsColor
        }"
        v-model="selectedValue"
        :value="`${option[optionIdAttrName(option)]}`"
        :disabled="disabled ? true : null"
      />
      <p>
        {{ option[optionContentAttrName(option)] }}
      </p>
    </FormLabel>
  </div>
</template>

<script>
import { getSelectedOptionsIds } from '@/helpers/common';

export default {
  props: {
    value: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styleAsResults: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const found = getSelectedOptionsIds(this.options);
    this.selectedValue = found.length > 0 ? `${found[0]}` : '';
  },
  data() {
    return {
      selectedValue: this.value
    };
  },
  computed: {
    areCorrectAnswersIndicated() {
      if (!this.options || !this.options.length) return undefined;

      const found = this.options.find(option => option.is_correct === true);

      return found ? true : false;
    },
    useFallbackResultsColor() {
      return this.styleAsResults && this.areCorrectAnswersIndicated
        ? false
        : true;
    }
  },
  watch: {
    selectedValue() {
      this.$emit('input', this.selectedValue);
    },
    value() {
      this.selectedValue = this.value;
    },
    options: {
      handler(newOptions) {
        const found = getSelectedOptionsIds(newOptions);
        this.selectedValue = found.length > 0 ? `${found[0]}` : '';
      },
      deep: true
    }
  },
  methods: {
    optionIdAttrName(option) {
      return option.hasOwnProperty('option_id') ? 'option_id' : 'id';
    },
    optionContentAttrName(option) {
      return option.hasOwnProperty('answer') ? 'answer' : 'content';
    },
    isCorrect(option) {
      if (this.areCorrectAnswersIndicated === false) return undefined;

      if (!option) return undefined;

      if (!Object.keys(option).includes('is_correct')) return undefined;

      if (!this.styleAsResults) return undefined;

      if (typeof option.is_correct === 'boolean') {
        return option.is_correct;
      }

      return undefined;
    },
    isIncorrect(option) {
      if (this.areCorrectAnswersIndicated === false) return undefined;

      if (!this.styleAsResults) return undefined;

      if (option && option.selected && this.isCorrect(option) === true) {
        return false;
      }

      if (option && option.selected && this.isCorrect(option) === false) {
        return true;
      }

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import './ExamAnswerSingleChoice.scss';
</style>
