<template>
  <div :class="$style.gradingSessionWrapper">
    <template v-if="!session">
      <InlineLoader />
    </template>

    <template v-else-if="!teamsFromSession.length">
      <div class="team empty">
        No user groups associated with this session
      </div>
    </template>

    <template v-else>
      <div
        v-for="(team, teamIndex) of teamsFromSession"
        :key="teamIndex"
        class="team"
        :data-cy="`session-team-${teamIndex}`"
      >
        <h6 :title="`user_group_id: ${team.user_group_id}`">
          {{ team.object_name }}
        </h6>

        <table
          :class="{
            ['grades']: true,
            ['preview']: inViewMode,
            ['without-grading']: isWithoutGrading
          }"
        >
          <colgroup>
            <col
              v-if="columns.includes(COLUMNS.attendance)"
              :class="$style.columnAttendance"
            />
            <col
              v-if="columns.includes(COLUMNS.student)"
              :class="$style.columnStudent"
            />
            <col
              v-if="columns.includes(COLUMNS.score)"
              :class="$style.columnScore"
            />
            <col
              v-if="columns.includes(COLUMNS.score)"
              :class="$style.columnScoreMax"
            />
            <col
              v-if="
                columns.includes(COLUMNS.comment) && isCurrentUserTeachingStaff
              "
              :class="$style.columnComment"
            />
            <col
              v-if="
                isCurrentUserTeachingStaff &&
                  (columns.includes(COLUMNS.attendance) ||
                    columns.includes(COLUMNS.score) ||
                    columns.includes(COLUMNS.comment))
              "
              :class="$style.columnIndicator"
            />
          </colgroup>

          <thead v-if="!isWithoutGrading">
            <th
              v-if="columns.includes(COLUMNS.attendance)"
              :class="$style.columnAttendance"
            >
              Attend.
            </th>
            <th
              v-if="columns.includes(COLUMNS.student)"
              :class="$style.columnStudent"
            >
              Student
            </th>
            <th
              v-if="columns.includes(COLUMNS.score)"
              :class="$style.columnScore"
            >
              Score
            </th>
            <th
              v-if="columns.includes(COLUMNS.score)"
              :class="$style.columnScoreMax"
            ></th>
            <th
              v-if="
                columns.includes(COLUMNS.comment) && isCurrentUserTeachingStaff
              "
              :class="$style.columnComment"
            >
              Comment <small>Visible to teaching staff only</small>
            </th>
            <th
              v-if="
                isCurrentUserTeachingStaff &&
                  (columns.includes(COLUMNS.attendance) ||
                    columns.includes(COLUMNS.score) ||
                    columns.includes(COLUMNS.comment))
              "
              :class="$style.columnIndicator"
            ></th>
          </thead>
          <tbody>
            <template v-if="teamMembers(team).length">
              <GradingUser
                v-for="(user, index) of teamMembers(team)"
                :key="index"
                :user="user"
                :session="session"
                :currentMode="currentMode"
                :scope="SCOPES.user"
                @has_errors="relayHasErrors"
                @has_edits="relayHasEdits"
                @in_action="handleInAction"
                :isLastRow="index === teamMembers(team).length - 1"
                :data-cy="`grading-user-${index}`"
              />
            </template>
            <template v-else>
              <span style="text-align: center; width: 100%;"
                >No users found in this group.</span
              >
            </template>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import GradingUser from './GradingUser';
import intervalParse from 'math-interval-parser';

import { removeDuplicateObjByKey } from '@/helpers/common';
import {
  getSessionColumns,
  getSessionGradingComponent
} from '@/helpers/grading';

import { MODES, COLUMNS, SCOPES, GRADING_TYPES } from '@/config/grading';

export default {
  name: 'GradingSession',
  components: { InlineLoader, GradingUser },
  props: {
    currentMode: {
      type: String,
      default: MODES.VIEW
    },
    session: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isInAction: false
    };
  },
  computed: {
    ...mapGetters([
      'isCurrentUserTeachingStaff',
      'isCurrentUserStudent',
      'currentUserId'
    ]),
    ...mapGetters('grading', ['leadSessionGradingComponent']),
    columns() {
      return getSessionColumns(this.session);
    },
    isWithoutGrading() {
      return [GRADING_TYPES.TYPENULL].includes(
        getSessionGradingComponent(this.session)
      );
    },
    inViewMode() {
      return this.currentMode === MODES.VIEW;
    },
    inEditMode() {
      return this.currentMode === MODES.EDIT;
    },
    teamsFromSession() {
      if (!this.userGroups) return [];

      const allProfileTeams = this.userGroups.filter(
        p =>
          p.group_type === 'team' ||
          p.group_type === 'vc_group' ||
          p.group_type === 'cohort'
      );

      return removeDuplicateObjByKey(allProfileTeams, 'user_group_id');
    },
    userGroups() {
      if (!this.session) return [];
      if (!this.session.user_groups) return [];

      if (this.isCurrentUserStudent) {
        return this.session.user_groups.filter(ug =>
          ug.members.find(m => m.user_id === this.currentUserId)
        );
      }

      const sessionUserGroupsClone = [...this.session.user_groups];

      return sessionUserGroupsClone.sort((itemA, itemB) => {
        const a = parseInt(itemA.user_group_id);
        const b = parseInt(itemB.user_group_id);

        if (Number.isNaN(a) || Number.isNaN(b)) return 0;

        if (a === b) return 0;

        return a < b ? -1 : 1;
      });
    }
  },
  methods: {
    handleInAction(maybeInAction) {
      this.isInAction = maybeInAction;
      this.$emit('in_action', maybeInAction);
    },
    teamMembers(team) {
      if (!team) return [];

      if (this.isCurrentUserStudent) {
        return team.members.filter(m => m.user_id === this.currentUserId);
      }

      const withoutNullMembers = team.members.filter(m => m);

      return withoutNullMembers;
    },
    relayHasErrors(maybeHasErrors) {
      this.$emit('has_errors', maybeHasErrors);
    },
    relayHasEdits(maybeHasEdits) {
      this.$emit('has_edits', maybeHasEdits);
    }
  },
  created() {
    this.COLUMNS = COLUMNS;
    this.SCOPES = SCOPES;
  }
};
</script>

<style lang="scss" module>
@import './styles/Columns.scss';
@import './styles/GradingSession.scss';
</style>
