export const state = {
  forum: {},
  topics: [],
  newTopic: {
    subject: null,
    content: null
  },
  newTopicErrors: {
    subject: [],
    content: []
  }
};

export const getters = {
  forum(state) {
    return state.forum;
  },
  topics(state) {
    return state.topics;
  },
  newTopicSubject(state) {
    return state.newTopic.subject;
  },
  newTopicContent(state) {
    return state.newTopic.content;
  },
  newTopicHasErrors(state) {
    return !!(
      (state.newTopicErrors.subject && state.newTopicErrors.subject.length) ||
      (state.newTopicErrors.content && state.newTopicErrors.content.length)
    );
  },
  newTopicSubjectErrors(state) {
    return state.newTopicErrors.subject;
  },
  newTopicContentErrors(state) {
    return state.newTopicErrors.content;
  }
};

export const mutations = {
  setForum(state, forum) {
    state.forum = forum;
  },
  setTopics(state, topics) {
    state.topics = topics;
  },
  setNewTopicSubject(state, subject) {
    state.newTopic.subject = subject;
  },
  setNewTopicContent(state, content) {
    state.newTopic.content = content;
  },
  purgeNewTopic(state) {
    state.newTopic = {
      subject: null,
      content: null
    };
  },
  setNewTopicSubjectErrors(state, errors) {
    state.newTopicErrors.subject = errors;
  },
  setNewTopicContentErrors(state, errors) {
    state.newTopicErrors.content = errors;
  },
  purgeNewTopicErrors(state) {
    state.newTopicErrors = {
      subject: [],
      content: []
    };
  },
  purge(state) {
    state.forum = {};
    state.topics = [];
  }
};

export default {
  state,
  getters,
  mutations
};
