<template>
  <div :class="$style.wrapper">
    <template v-if="displayLoader">
      <CircleLoader :radius="80" :class="$style.loader">
        <span>Loading...</span>
      </CircleLoader>
    </template>
    <template v-else-if="activeQuestion">
      <ProgressBar
        v-if="activeQuestion && !hasEnded"
        :class="$style.progressBar"
        :progressPercent="progressPercent"
      />

      <div :class="$style.question">
        <div :class="$style.title">
          <strong>Question {{ progressStep }}</strong>
          <span
            v-if="hasEnded"
            class="icon icon-lock"
            :class="$style.lock"
            title="Assessment ended, no changes possible"
            data-cy="exam_locked"
          />
        </div>

        <div data-cy="exam_question">
          {{ activeQuestion.question }}
        </div>
      </div>

      <ExamAnswer
        :questionId="activeQuestion.question_id"
        :kind="QUESTION_KIND.options"
        :maxChoices="activeQuestion.max_choices"
        :options="activeQuestion.options"
        :textAnswer="activeQuestion.text_answer"
        :disabled="hasEnded ? true : null"
        @answered="onAnswered"
        data-cy="exam_answer"
        :class="$style.answer"
      />

      <template v-if="isActiveFirstQuestion">
        <span :class="$style.prev" />
      </template>
      <template v-else>
        <FormButton
          :class="$style.prev"
          :standard="true"
          :disabled="canGoBack ? null : true"
          @click="previousQuestion(activeQuestionIndex)"
          data-cy="exam_previous_question"
        >
          <i class="icon icon-chevron-left" />
        </FormButton>
      </template>
      <template>
        <FormButton
          :standard="true"
          :class="$style.next"
          v-if="canGoForward && !isActiveLastQuestion"
          @click="nextQuestion(activeQuestionIndex)"
          data-cy="exam_next_question"
        >
          <i class="icon icon-chevron-right" />
        </FormButton>
        <span v-else :class="$style.next"></span>
      </template>

      <template v-if="isActiveLastQuestion">
        <div :class="$style.footer">
          <FormButton
            :standard="true"
            :disabled="canGoForward ? null : true"
            @click="nextQuestion(activeQuestionIndex)"
            data-cy="exam_last_question"
          >
            Submit
          </FormButton>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CircleLoader from '@/components/common/CircleLoader/CircleLoader';
import ProgressBar from '@/components/common/ProgressBar/ProgressBar';
import ExamAnswer from '@/components/exams/ExamAnswer/ExamAnswer';
import { QUESTION_KIND } from '@/config/assessments';

export default {
  name: 'QuizPlayer',
  components: { CircleLoader, ExamAnswer, ProgressBar },
  data() {
    return {
      displayLoader: false,
      activeQuestionIndex: undefined,
      answer: undefined
    };
  },
  computed: {
    ...mapGetters('quiz', [
      'firstNotAnsweredQuestionIndex',
      'quizId',
      'attemptId',
      'questions',
      'hasEnded',
      'hasAllQuestionsAnswered'
    ]),
    activeQuestion() {
      return this.questions[this.activeQuestionIndex];
    },
    activeQuestionId() {
      return this.activeQuestion && this.activeQuestion.question_id
        ? this.activeQuestion.question_id
        : undefined;
    },
    isActiveLastQuestion() {
      return this.activeQuestionIndex === this.questions.length - 1;
    },
    isActiveFirstQuestion() {
      return this.activeQuestionIndex === 0;
    },
    canGoBack() {
      if (!this.isActiveFirstQuestion) return true;
      return false;
    },
    canGoForward() {
      if (this.hasNoAnswer && this.hasEnded) return true;
      if (this.hasAnswer) return true;
      return false;
    },
    hasAnswer() {
      return !this.hasNoAnswer;
    },
    hasNoAnswer() {
      if (
        this.answer &&
        this.answer.checked_option_ids &&
        this.answer.checked_option_ids.length === 0
      )
        return true;
      if (this.answer) return false;
      // There might not be any answer from user yet
      // when he/she reloads an assessment page
      // and hits previous button without selecting any option first
      // so this checks for his already saved answers too
      if (this.activeQuestionIndex < this.firstNotAnsweredQuestionIndex)
        return false;
      return true;
    },
    progressStep() {
      const total = this.questions.length;
      let answered;
      if (this.activeQuestionIndex === 0) {
        answered = 1;
      } else {
        answered = this.activeQuestionIndex + 1;
      }
      return `${answered} / ${total}`;
    },
    progressPercent() {
      if (this.hasAllQuestionsAnswered) return 100;
      if (this.firstNotAnsweredQuestionIndex === 0) return 0;
      return Math.floor(
        (this.firstNotAnsweredQuestionIndex / this.questions.length) * 100
      );
    }
  },
  async created() {
    this.QUESTION_KIND = QUESTION_KIND;
    this.activeQuestionIndex = this.firstNotAnsweredQuestionIndex
      ? this.firstNotAnsweredQuestionIndex
      : 0;
  },
  methods: {
    ...mapActions('quiz', ['saveAnswer']),
    ...mapMutations('quiz', ['setContinueActive']),
    onAnswered(answer) {
      this.answer = answer;
    },
    async previousQuestion(activeQuestionIndex) {
      // Don't go beyond first question
      if (this.isActiveFirstQuestion) return;
      if (this.answer && !this.hasEnded) {
        try {
          await this.saveAnswer({
            quizId: this.quizId,
            quizAttemptId: this.attemptId,
            answerContent: this.answer
          });
          this.setContinueActive(true);
        } catch (error) {
          this.$emit('error', error);
          // error needs to stop execution here
          // and prevent moving to next question
          return;
        }
      }
      const previousIndex = activeQuestionIndex - 1;
      // Really, don't go beyond first question
      if (previousIndex < 0) return;
      this.activeQuestionIndex = previousIndex;
      this.answer = undefined;
    },
    async nextQuestion(activeQuestionIndex) {
      if (this.hasNoAnswer && !this.hasEnded) return;
      if (!this.hasEnded) {
        try {
          await this.saveAnswer({
            quizId: this.quizId,
            quizAttemptId: this.attemptId,
            answerContent: this.answer
          });
          this.setContinueActive(true);
        } catch (error) {
          this.$emit('error', error);
          // error needs to stop execution here
          // and prevent moving to next question
          return;
        }
      }
      if (this.isActiveLastQuestion) {
        this.$emit('completed');
        return;
      }
      const nextIndex = activeQuestionIndex + 1;
      if (nextIndex < 1) return;
      this.activeQuestionIndex = nextIndex;
      this.answer = undefined;
    }
  }
};
</script>
<style lang="scss" module>
@import './QuizPlayer.scss';
</style>
