export const CYCLE_TYPES = {
  onboarding: 'onboarding',
  study: 'study',
  cr: 'clinical_rotation',
  holiday: 'holiday',
  flex: 'flex'
};

export const VIEW_AS_OBJECT_TYPES = {
  user: 'user',
  cohort: 'cohort'
};

export const ALLOW_JOIN_IN_ADVANCE_MIN = 5;
