<template>
  <span class="inline-loader">
    <span class="loader-box"></span>
    <span class="loader-box"></span>
    <span class="loader-box"></span>
  </span>
</template>

<script>
// import { THEME_DEFAULT } from '@/config/theme';

export default {
  props: {},
  computed: {}
};
</script>

<style lang="scss">
@import '~@/assets/css/generated-variables.scss';
@import '~@/assets/css/animations.scss';

$vertical-margin: 0px;
$horizontal-margin: 2px;
$width: 8px;
$height: 8px;

.inline-loader {
  height: 8px;
  width: (6 * $horizontal-margin) + (3 * $width);
}

.loader-box {
  display: inline-block;
  height: $height;
  width: $width;
  margin: $vertical-margin $horizontal-margin;
  background-color: rgb(
    red($primaryColor),
    green($primaryColor),
    blue($primaryColor)
  );
  animation-name: fadeOutIn;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-radius: 3px;

  &:nth-child(1) {
    animation-delay: 250ms;
  }

  &:nth-child(2) {
    animation-delay: 500ms;
  }

  &:nth-child(3) {
    animation-delay: 750ms;
  }
}

@keyframes fadeOutIn {
  0% {
    background-color: rgba(
      red($primaryColor),
      green($primaryColor),
      blue($primaryColor),
      1
    );
  }
  100% {
    background-color: rgba(
      red($primaryColor),
      green($primaryColor),
      blue($primaryColor),
      0
    );
  }
}
</style>
