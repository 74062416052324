export const clientErrorTypes = [
  {
    path: 'user.personal_quote',
    message: 'Validation error'
  },
  {
    path: 'user.bio',
    message: 'Validation error'
  },
  {
    path: 'team.name',
    message: 'Validation error'
  },
  {
    path: 'team.description',
    message: 'Validation error'
  }
];
