<template>
  <div v-if="!pageIsLoading">
    <NotFound v-if="display404" />
    <Layout variant="5" v-else :hideNavigationItems="true">
      <template #pageContent>
        <div :class="$style.content">
          <h2>Please read and accept our Terms & Conditions</h2>

          <div :class="$style.iframeWrapper">
            <iframe src="https://info.edu.edu.mt/platform-policies/" />
          </div>

          <div :class="$style.controls">
            <div>
              <FormLabel data-cy="conditions">
                <FormCheckbox v-model="agreeTermsAndConditions" />
                I have read and agree to the Terms & Conditions
              </FormLabel>

              <FormLabel data-cy="policy">
                <FormCheckbox v-model="agreePrivacyPolicy" />
                I have read and agree to the Privacy Policy
              </FormLabel>

              <FormErrors :errors="errors" />

              <FormButton
                data-cy="agreed"
                :special="true"
                :class="$style.submitBtn"
                @click="submit"
              >
                Finalize setup
              </FormButton>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  GET_CURRENT_USER_PROFILE,
  STORE_CURRENT_USER_CONSENTS
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

import NotFound from '@/components/common/NotFound/NotFound';
import Layout from '@/views/Layout/Layout';

export default {
  components: {
    Layout,
    NotFound
  },
  data() {
    return {
      agreeTermsAndConditions: false,
      agreePrivacyPolicy: false,
      errors: [],
      pageIsLoading: true,
      display404: false
    };
  },
  computed: {
    ...mapGetters(['currentUserAcceptedTerms'])
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentUserAcceptedTerms) {
      return next();
    }

    return next({ name: 'terms-and-conditions' });
  },
  async created() {
    try {
      await this.$store.dispatch(GET_CURRENT_USER_PROFILE);
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }

    this.pageIsLoading = false;

    if (this.currentUserAcceptedTerms) {
      this.display404 = true;
    }
  },
  methods: {
    async submit() {
      this.errors = [];

      if (!this.agreeTermsAndConditions) {
        this.errors.push('You need to agree to the Terms & Conditions');
      }

      if (!this.agreePrivacyPolicy) {
        this.errors.push('You need to agree to the Privacy Policy');
      }

      if (this.errors.length) {
        return;
      }

      try {
        await this.$store.dispatch(STORE_CURRENT_USER_CONSENTS);
        await this.$store.dispatch(GET_CURRENT_USER_PROFILE);

        this.$router.push({ name: 'dashboard' });
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './TermsAndConditions.scss';
</style>
