var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Assignment Notes")]),_c('router-link',{class:_vm.$style.homeButton,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","sort-options":{
          enabled: true,
          initialSortBy: _vm.loadUrlWithSorting()
        }},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'postId')?_c('span',[(props.row.objectInfo[0] === 'blog_post')?[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
                  name: 'blog-post',
                  params: {
                    postId: props.row.objectInfo[1]
                  }
                }}},[_vm._v(" "+_vm._s(props.row.postId)+" ")])]:[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
                  name: 'assignment-detail',
                  params: {
                    assignmentId: props.row.objectInfo[1]
                  }
                }}},[_vm._v(" "+_vm._s(props.row.postId)+" ")])]],2):(props.column.field === 'content')?_c('span',{class:_vm.$style.content},[(props.row.objectInfo[0] === 'blog_post')?[_c('router-link',{attrs:{"to":{
                  name: 'blog-post',
                  params: {
                    postId: props.row.objectInfo[1]
                  }
                }},domProps:{"innerHTML":_vm._s(props.row.content)}})]:[_c('router-link',{attrs:{"to":{
                  name: 'assignment-detail',
                  params: {
                    assignmentId: props.row.objectInfo[1]
                  }
                }},domProps:{"innerHTML":_vm._s(props.row.content)}})]],2):(props.column.field === 'objectInfo')?_c('span',[_vm._v(" "+_vm._s(props.row.objectInfo[0])+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(" "+_vm._s(props.row.objectInfo[1])+" ")])]):(props.column.field === 'user')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.user[2] }
              }}},[_vm._v(" "+_vm._s(props.row.user[0] + ' ' + props.row.user[1])+" "),_c('span',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.user[2]))])])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }