// Use nodejs module export style

const edu = require('./theme-edu');
const candena = require('./theme-candena');

module.exports = {
  DEFAULT_THEME: 'edu',
  THEMES: {
    edu,
    candena
  }
};
