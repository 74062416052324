<template>
  <span
    :class="{
      [$style.wrapper]: true,
      [$style.noActionIndicator]: withActionIndicator === false
    }"
    class="form-textarea"
  >
    <InputTextarea
      v-bind="$attrs"
      :action="action"
      :value="value"
      :validation="validation"
      :maxlength="maxCount"
      @state="updateState"
      @input="relayInput"
      @success="relaySuccess"
      @error="relayError"
      @validation_result="relayValidationResult"
    />

    <span
      class="paragraph-small"
      :class="$style.info"
      v-if="withActionIndicator"
    >
      <img
        :class="{
          [$style.state]: true,
          [$style.blinking]: isBlinking
        }"
        v-if="getStateIcon(state)"
        :title="stateTitle"
        :src="require('@/assets/images/icons/' + getStateIcon(state))"
      />

      <span v-if="showCounter">{{ characterCount }}/{{ maxCount }}</span></span
    >
  </span>
</template>

<script>
import { STATES } from '@/config/forms';
import InputTextarea from '@/components/forms/InputTextarea/InputTextarea';

export default {
  components: {
    InputTextarea
  },
  props: {
    value: {},
    showCounter: Boolean,
    maxCount: Number,
    action: {
      type: Function // Parent must pass Function that returns a Function that returns a Promise
    },
    validation: {
      type: Function, // Parent should pass Function that returns a Boolean
      required: false
    },
    inActionTitle: {
      type: String,
      default: 'Saving...'
    },
    onSuccessTitle: {
      type: String,
      default: 'Saved'
    },
    onErrorTitle: {
      type: String
    },
    withActionIndicator: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      state: STATES.idle,
      errorMessage: ''
    };
  },
  computed: {
    isBlinking() {
      return this.state === STATES.typing;
    },
    characterCount() {
      return typeof this.value === 'string' ? this.value.length : 0;
    },
    stateTitle() {
      let titleText = '';

      switch (this.state) {
        case STATES.in_action:
          titleText = this.inActionTitle;
          break;
        case STATES.success:
          titleText = this.onSuccessTitle;
          break;
        case STATES.error:
          if (this.onErrorTitle && this.errorMessage) {
            titleText = this.onErrorTitle;
            titleText += '\n';
            titleText += this.errorMessage;
          }

          if (!this.onErrorTitle && this.errorMessage) {
            titleText += this.errorMessage;
          }

          break;
        default:
          titleText = '';
      }

      return titleText;
    }
  },
  methods: {
    updateState(newState) {
      this.state = newState;
      this.$emit('state', newState);
    },
    relayInput(inputValue) {
      this.$emit('input', inputValue);
    },
    relaySuccess(newValue) {
      this.$emit('success', newValue);
    },
    relayError(newValue) {
      this.$emit('error', newValue);
    },
    relayValidationResult(validationResult) {
      this.$emit('validation_result', validationResult);
    },
    getStateIcon(state) {
      if (state === STATES.typing) return 'saving.svg';
      if (state === STATES.in_action) return 'saving.svg';
      if (state === STATES.success) return 'saved.svg';
      if (state === STATES.error) return 'not_saved.svg';

      return undefined;
    }
  }
};
</script>

<style lang="scss" module>
@import '../FormTextarea/FormTextarea.scss';
@import './FormTextareaAutoSave.scss';
</style>
