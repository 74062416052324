import axios from 'axios';
import ApiService from '@/api/api.service';
import { GRADING_TYPES, COLUMNS } from '@/config/grading';
import { RESET_STATE } from '@/store/mutations.type';
import { sortingByDate, removeDuplicateObjByKey } from '@/helpers/common';
import { logError } from '@/helpers/errors';
import { FROM_OLDEST } from '@/config/dates';

import {
  GET_ASSIGNMENT,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_QUIZ_STATS,
  GET_BULK_ASSIGNMENTS,
  GET_SESSION_GRADES,
  PUBLISH_GRADING,
  GET_GRADING_EVALUATION_STATUS,
  REOPEN_ADMIN_OPERATION
} from '@/store/actions.type';

export const state = {
  assignments: undefined,
  assignment: undefined,
  assignmentId: undefined,
  inAction: undefined
};

export const initialStateCopy = Object.assign({}, state); // must be non-reactive copy

export const getters = {
  assignments(state) {
    return state.assignments;
  },
  assignment(state) {
    return state.assignment;
  },
  assignmentId(state) {
    return state.assignmentId;
  },
  sessions(state, getters, rootState, rootGetters) {
    if (!state.assignment) return [];

    if (rootGetters.isCurrentUserStudent) {
      return sortingByDate(
        state.assignment.sessions.filter(s =>
          s.user_groups.some(ug =>
            ug.members.some(m => m.user_id === rootGetters.currentUserId)
          )
        ),
        'start_at',
        FROM_OLDEST
      );
    }

    return sortingByDate(state.assignment.sessions, 'start_at', FROM_OLDEST);
  },
  isInAction(state) {
    return state.inAction;
  },
  leadSession(state) {
    if (!state.assignment) return undefined;
    if (!state.assignment.sessions) return undefined;

    const found = state.assignment.sessions.find(
      session => session.lead_assignment === true
    );

    return found ? found : undefined;
  },
  leadSessionAssignmentId(state, getters) {
    if (getters.leadSession && getters.leadSession.assignment_id) {
      return getters.leadSession.assignment_id;
    }

    return undefined;
  },
  leadSessionGradingComponent(state, getters) {
    if (
      getters.leadSession &&
      getters.leadSession.grading &&
      getters.leadSession.grading.grading_component
    ) {
      return getters.leadSession.grading.grading_component;
    }

    return undefined;
  },
  leadSessionType(state, getters) {
    if (getters.leadSession && getters.leadSession.session_type) {
      return getters.leadSession.session_type;
    }

    return undefined;
  },
  featuresAssignmentResources(state) {
    if (!state.assignment) return undefined;

    if (state.assignment && state.assignment.hasOwnProperty('note_supported')) {
      return state.assignment.note_supported;
    }

    return undefined;
  },
  rubricURL(state, getters) {
    if (
      getters.leadSession &&
      getters.leadSession.grading &&
      getters.leadSession.grading.rubric_external_url
    ) {
      return getters.leadSession.grading.rubric_external_url;
    }

    return undefined;
  }
};

export const actions = {
  [GET_BULK_ASSIGNMENTS](context, parameters) {
    return new Promise((resolve, reject) => {
      ApiService.getBulkAssignments(parameters)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [PUBLISH_GRADING](
    context,
    { assignmentId = undefined, scheduledAssignmentId = undefined }
  ) {
    if (!assignmentId)
      return Promise.reject('No assignmentId. Action aborted.');
    if (!scheduledAssignmentId)
      return Promise.reject('No scheduledAssignmentId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.publishGrading(assignmentId, scheduledAssignmentId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          logError(error);

          reject(error);
        });
    });
  },
  [GET_ASSIGNMENT](context, assignmentId) {
    if (!assignmentId)
      return Promise.reject('No assignmentId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.getAssignment(assignmentId)
        .then(({ data }) => {
          context.commit('setAssignment', { assignment: data, assignmentId });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_ASSIGNMENT_QUIZ_STATS](context, assignmentId) {
    if (!assignmentId)
      return Promise.reject('No assignmentId. Action aborted.');

    return new Promise((resolve, reject) => {
      ApiService.getAssignmentQuizStats(assignmentId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_ASSIGNMENTS](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAssignments(options)
        .then(({ data }) => {
          const normalizedData = !Array.isArray(data) ? [] : data;
          context.commit('setAssignments', normalizedData);
          resolve(normalizedData);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_GRADING_EVALUATION_STATUS](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAdminGradingEvaluation(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REOPEN_ADMIN_OPERATION](context, { func, objectType, objectId, reference }) {
    return new Promise((resolve, reject) => {
      ApiService.adminOperations(func, objectType, objectId, reference)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  setAssignment(state, { assignment, assignmentId }) {
    if (!assignment) return;

    state.assignment = assignment;
    state.assignmentId = assignmentId;
  },
  setAssignments(state, data) {
    state.assignments = data;
  },
  setMaybeInAction(state, maybeInAction) {
    state.inAction = maybeInAction;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
