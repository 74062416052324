<template>
  <div
    data-ref="launcher"
    v-if="room && room.newrow_context_id"
    @click="launchTeamRoom"
    :class="{
      ['launcher-disabled']: disabled
    }"
  >
    <form :id="`ltiLaunchForm${elementId}`" target="_blank" method="POST">
      <div>
        <input
          v-for="(value, key) of roomDetails"
          :key="key"
          type="hidden"
          :name="key"
          :value="value"
        />
        <slot />
      </div>
    </form>
  </div>
</template>

<script>
import hmacsha1 from 'hmacsha1';
import { v4 as uuidv4 } from 'uuid';

import { removeNullItemsFromObj } from '@/helpers/common';
import { logWarning } from '@/helpers/errors';

import { GET_NEWROW_AUTH_TOKEN } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    room: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      default: 'https://smart.newrow.com/backend/lti/course'
    }
  },
  data() {
    return {
      disabled: false,
      roomDetails: null,
      elementId: ['development', 'production'].includes(process.env.NODE_ENV)
        ? uuidv4()
        : '00000000-0000-0000-0000-000000000000'
    };
  },
  mounted() {
    if (!this.$el.querySelector) return;

    this.$el
      .querySelector('[id*="ltiLaunchForm"]')
      .addEventListener('submit', this.preventDefault);
  },
  beforeDestroy() {
    if (!this.$el.querySelector) return;

    this.$el
      .querySelector('[id*="ltiLaunchForm"]')
      .removeEventListener('submit', this.preventDefault);
  },
  methods: {
    preventDefault(evt) {
      evt.preventDefault();
    },
    async launchTeamRoom() {
      this.disabled = true;
      this.$emit('launching', true);

      try {
        const newrow_auth = await this.$store.dispatch(GET_NEWROW_AUTH_TOKEN);

        if (!newrow_auth || !newrow_auth.token) {
          logWarning('Missing NewRow Auth token!');
          return;
        }

        this.roomDetails = this.getRequestSignature(newrow_auth);

        this.$nextTick(() => {
          if (this.roomDetails.oauth_signature && this.$el.querySelector) {
            const formEl = this.$el.querySelector(
              `#ltiLaunchForm${this.elementId}`
            );

            if (formEl && formEl.querySelectorAll('input').length > 0) {
              formEl.action = this.url;
              formEl.submit();
            }
          }
        });
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }

      this.disabled = false;
      this.$emit('launching', false);
    },
    getRequestSignature(newrow_auth) {
      let launchData = {
        user_id: this.user.user_id,
        roles: this.room.role,
        lis_person_name_given: this.user.first_name || null,
        lis_person_name_family: this.user.last_name || null,
        lis_person_contact_email_primary: `
          support${this.user.user_id}@edu.edu.mt
        `,
        tool_consumer_info_product_family_code: 'NewRow Integration',
        context_id: this.room.newrow_context_id,
        context_title: this.room.newrow_room_name || this.room.name,
        lti_version: 'LTI-1p0',
        oauth_callback: 'about:blank',
        oauth_consumer_key: newrow_auth.key,
        oauth_version: '1.0',
        oauth_nonce: uuidv4(),
        oauth_token: newrow_auth.token,
        oauth_timestamp: new Date().valueOf(),
        oauth_signature_method: 'HMAC-SHA1'
      };

      launchData = removeNullItemsFromObj(launchData);

      // https://oauth1.wp-api.org/docs/basics/Signing.html
      // In OAuth, request parameters must be sorted by name
      const launchDataKeys = Object.keys(launchData).sort();
      const launchParams = launchDataKeys.map(
        key => `${key}=${encodeURIComponent(launchData[key])}`
      );
      const encodedUrl = encodeURIComponent(this.url);
      const encodedParams = encodeURIComponent(launchParams.join('&'));
      const encodedKey = `${encodeURIComponent(newrow_auth.key)}&`;
      const baseString = `POST&${encodedUrl}&${encodedParams}`;

      // https://github.com/pH200/hmacsha1-js
      // result is already base64 encoded!
      const oauth_signature = hmacsha1(encodedKey, baseString);

      return { ...launchData, ...{ oauth_signature } };
    }
  }
};
</script>

<style lang="scss" module>
@import './NewRoomLauncher.scss';
</style>
