<template>
  <div :class="$style.wrapper" class="box-shadow-white">
    <p :class="$style.title">{{ overviewData.title }}</p>
    <div :class="$style.data">
      <div v-for="item in orderedOverviewData" :key="item.metric_name">
        <p :class="$style.dataTitle">{{ item.metric_title }}</p>
        <p
          :class="{
            [$style.dataNumber]: true,
            [$style.numberHighlight]: item.highlight
          }"
        >
          {{ item.data }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overviewData: {
      type: Object
    }
  },
  computed: {
    orderedOverviewData() {
      return _.orderBy(this.overviewData.data, 'position');
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminOverviewBox.scss';
</style>
