<template>
  <div
    data-ref="launcher"
    @click="launch"
    :class="{
      ['launcher-disabled']: disabled
    }"
  >
    <form
      :id="`uptodateLaunchForm${elementId}`"
      target="_blank"
      name="uptodate"
      method="POST"
    >
      <div>
        <input type="hidden" :value="tokens && tokens.token_d" name="portal" />
        <input
          type="hidden"
          :value="tokens && tokens.token_c"
          name="key"
          id="key"
        />

        <slot />
      </div>
    </form>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import { GET_INTEGRATION_TOKENS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  props: {
    url: {
      type: String,
      default: 'https://www.uptodate.com/portal-login'
    }
  },
  data() {
    return {
      disabled: false,
      tokens: null,
      elementId: ['development', 'production'].includes(process.env.NODE_ENV)
        ? uuidv4()
        : '00000000-0000-0000-0000-000000000000'
    };
  },
  mounted() {
    if (!this.$el.querySelector) return;

    this.$el
      .querySelector('[id*="uptodateLaunchForm"]')
      .addEventListener('submit', this.preventDefault);

    this.launch();
  },
  beforeDestroy() {
    if (!this.$el.querySelector) return;

    this.$el
      .querySelector('[id*="uptodateLaunchForm"]')
      .removeEventListener('submit', this.preventDefault);
  },
  methods: {
    preventDefault(evt) {
      evt.preventDefault();
    },
    async launch() {
      this.disabled = true;
      this.$emit('launching', true);

      try {
        this.tokens = await this.$store.dispatch(
          GET_INTEGRATION_TOKENS,
          'uptodate'
        );

        this.$nextTick(() => {
          if (
            this.tokens &&
            !isNaN(this.tokens.token_c) &&
            this.$el.querySelector
          ) {
            const formEl = this.$el.querySelector(
              `#uptodateLaunchForm${this.elementId}`
            );

            if (formEl && formEl.querySelectorAll('input').length > 0) {
              formEl.action = this.url;
              formEl.submit();
            }
          }
        });
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }

      this.disabled = false;
      this.$emit('launching', false);
    }
  }
};
</script>
