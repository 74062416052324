var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('h6',[_vm._v("Users ("+_vm._s(_vm.totalRecords)+")")]),_c('div',{class:_vm.$style.buttons},[_c('FormButton',{class:_vm.$style.greenButton,on:{"click":_vm.handleOpened}},[_c('i',{staticClass:"icon icon-user-plus"}),_vm._v("Create user ")]),_c('div',{class:_vm.$style.homeButton},[_c('span',[_vm._v("| ")]),_c('router-link',{class:_vm.$style.button,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1)],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
          enabled: true,
          initialSortBy: _vm.loadUrlWithSorting()
        },"pagination-options":{
          enabled: true,
          infoFn: function (params) { return _vm.setTotalRecords(params.totalRecords); }
        },"compactMode":""},on:{"on-column-filter":_vm.updateFilters,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{staticClass:"adminPrimaryId",attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.id)+" ")])],1):(props.column.field === 'firstName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.firstName)+" ")])],1):(props.column.field === 'lastName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-user-detail',
                params: { userId: props.row.id }
              }}},[_vm._v(" "+_vm._s(props.row.lastName)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('ModalNew',{directives:[{name:"transfer-dom",rawName:"v-transfer-dom"}],class:_vm.$style.adminModal,attrs:{"showModal":_vm.showModal},on:{"close":_vm.handleClosed}},[_c('AdminCreateUser',{on:{"reloadPage":function($event){return _vm.fetchUsers()}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }