<template>
  <div class="main-title">
    <div :class="$style.header">
      <a v-if="!isAtTop" :class="$style.backArrow" @click="$emit('navigate_up')"
        ><img v-svg-inline :src="require(`@/assets/images/icons/nav_arrow.svg`)"
      /></a>

      <h6 data-ref="name" :class="$style.mobileTitle" v-line-clamp:1="1">
        <span v-if="currentConversation && currentConversation.object_name">{{
          currentConversation.object_name
        }}</span>
        <router-link
          v-else
          v-for="(participant, index) in fullConversationNameWithIds"
          :key="index"
          :to="{
            name: 'user-profile',
            params: { userId: participant.id }
          }"
        >
          <DoNotTrack
            ><span :class="$style.comma">{{
              participant.name
            }}</span></DoNotTrack
          >
        </router-link>
      </h6>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getConversationNameWithIds } from '@/helpers/conversations';

export default {
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapGetters('chats', ['currentConversation']),
    fullConversationNameWithIds() {
      return getConversationNameWithIds(
        this.currentConversation,
        this.currentUserId
      );
    },
    isAtTop() {
      return this.$route.name === 'conversations-list';
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationHeader.scss';
</style>
