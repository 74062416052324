<template>
  <div
    data-ref="trigger"
    key="trigger"
    style="clear: both; background-color: transparent; height: 1px;"
  >
    &nbsp;
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        // See https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        // for explanation of the attribute values
        return {
          root: null,
          rootMargin: '0px 0px 0px 0px',
          threshold: 0
        };
      }
    }
  },
  data() {
    return {
      observer: null
    };
  },

  mounted() {
    this.observer = new IntersectionObserver(entries => {
      this.handleIntersect(entries[0]);
    }, this.options);

    this.$nextTick(() => {
      this.observer.observe(this.$el);
    });
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    handleIntersect(entry) {
      if (!entry.isIntersecting) {
        this.$emit('trigerredLeave');
      } else {
        this.$emit('trigerredEnter');
      }

      this.$emit('trigerredChange');
    }
  }
};
</script>
