<template>
  <Notice :type="'error'" v-if="message">
    <template #icon>
      <i class="icon icon-exclamation-triangle" />
    </template>
    <p>
      {{ message }} If the problem persists, please
      <a href="mailto:support@edu.edu.mt">contact us</a>.
    </p>
  </Notice>
</template>

<script>
import Notice from '@/components/notices/Notice/Notice';

export default {
  components: {
    Notice
  },
  props: {
    message: {
      type: String
    }
  }
};
</script>
