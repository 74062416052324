import axios from 'axios';

import { ID_DROP_TOKEN_KEY } from './config';
import router from '@/router';
import store from '@/store';
import { LOGOUT } from '@/store/actions.type';
import ApiClient from './api.client';

const containsDropAuthHeader = response => {
  return response.headers && ID_DROP_TOKEN_KEY in response.headers;
};

const dropAuth = (store, router) => {
  store.dispatch(LOGOUT);
  router.push({ name: 'login' });
};

const axiosInstance = axios.create();

export const processResponse = (response, store, router) => {
  if (containsDropAuthHeader(response)) {
    dropAuth(store, router);
  }
};

axiosInstance.interceptors.response.use(
  response => {
    processResponse(response, store, router);

    return response;
  },
  error => {
    // the request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response) {
      processResponse(error.response, store, router);
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  requestConfig => {
    if (store.getters.isPhoneLayout !== true) return requestConfig;

    requestConfig.headers = {
      ...requestConfig.headers,
      ...{
        'X-Mobile-Viewport': true
      }
    };

    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

export default new ApiClient(axiosInstance);
