var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:( _obj = {}, _obj[_vm.$style.wrapper] = true, _obj[_vm.$style.collapsed] = _vm.collapsed === true, _obj )},[_c('ListFacilitators',{class:_vm.$style.facilitators,attrs:{"facilitators":_vm.session.facilitators}}),_c('span',{class:_vm.$style.sessionType},[_vm._v(_vm._s(_vm.sessionType(_vm.session)))]),_c('h5',{class:_vm.$style.sessionDuration,attrs:{"data-ignore":"snapshot"}},[_vm._v(" "+_vm._s(_vm._f("duration")(_vm.session))+" ")]),_c('div',{class:_vm.$style.launcher},[(_vm.showFeedbackLink)?_c('a',{staticClass:"btn ghost",class:_vm.$style.feedback,attrs:{"href":_vm.session.session_feedback_url,"target":"_blank"}},[_vm._v(" Give Feedback ")]):_vm._e(),_c('NewRoomLauncherBeforeStart',{staticClass:"launcher",attrs:{"user":_vm.currentUserProfile,"session":_vm.session,"nowISO":_vm.nowISO,"data-cy":"launcher"}},[_c('h5',{staticClass:"join-button uppercase",class:_vm.$style.joinAction},[_vm._v(" Join! ")])])],1),_c('div',{class:_vm.$style.gradingState},[(
        _vm.sessionGradingComponent !== _vm.GRADING_TYPES.TYPENULL &&
          _vm.hasEnded === true &&
          _vm.isPublished === true
      )?_c('span',{staticClass:"grading-state-evaluated",attrs:{"data-cy":"grading-state"}},[_vm._v(" ● EVALUATED ")]):_vm._e(),(
        _vm.sessionGradingComponent !== _vm.GRADING_TYPES.TYPENULL &&
          _vm.hasEnded === true &&
          _vm.isPublished === false
      )?_c('span',{staticClass:"grading-state-pending",attrs:{"data-cy":"grading-state"}},[_vm._v(" ● PENDING ")]):_vm._e()]),_c('a',{staticClass:"link",class:_vm.$style.flipLink,on:{"click":function($event){return _vm.$emit('flip', !_vm.collapsed)}}},[(_vm.collapsed === true)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.collapsed === false)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }