import * as Sentry from '@sentry/browser';

export const hasErrorStatus = (code, error) => {
  if (!code) {
    return false;
  }

  return !!(
    error &&
    error.response &&
    error.response.status &&
    error.response.status.toString() === code.toString()
  );
};

export const getResponse = error => {
  if (error && error.response) return error.response;

  return undefined;
};

export const getServerErrorMessages = error => {
  if (
    !error ||
    !error.response ||
    !error.response.data ||
    !error.response.data.errors ||
    !error.response.data.errors.length
  ) {
    return [];
  }

  return error.response.data.errors.map(error => error.message);
};

export const logError = (error, options = {}) => {
  const silenceSentry = (options && options.silenceSentry) || false;

  switch (process.env.NODE_ENV) {
    case 'production':
      if (!silenceSentry) {
        Sentry.captureException(error);
      }
      break;
    case 'test':
    case 'e2e':
      void error;
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(error);
  }
};

export const logWarning = (warning, options = {}) => {
  const silenceSentry = (options && options.silenceSentry) || false;

  switch (process.env.NODE_ENV) {
    case 'production':
      if (!silenceSentry) {
        Sentry.captureMessage(warning);
      }
      break;
    case 'test':
    case 'e2e':
      void warning;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn(warning);
  }
};
