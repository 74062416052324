<template>
  <div :class="$style.wrapper">
    <div
      v-for="(dayISO, dayIndex) in Object.keys(container.days)"
      :key="dayIndex"
      :style="{
        gridColumnEnd: `span ${getDayLength(dayISO)}`
      }"
      :class="{
        [$style.current]: isCurrent(dayISO)
      }"
    >
      <DayHeader :class="$style.header" :dayISO="dayISO" />
      <div
        :class="$style.lessons"
        :style="{
          gridTemplateRows: `1fr`,
          gridTemplateColumns: `repeat(${getDayLength(dayISO)}, 1fr)`
        }"
      >
        <MiniLesson
          v-for="(lesson, lessonIndex) in container.days[dayISO]"
          :lesson="lesson"
          :key="lessonIndex"
          :nowISO="nowISO"
          :isActive="isActive(lesson)"
          :class="{
            [$style.lesson]: true,
            [$style.active]: isActive(lesson)
          }"
        />

        <div v-if="container.days[dayISO].length === 0" :class="$style.lesson">
          <InlineLoader v-if="isLoading" />
          <p v-else :class="$style.centered">
            No sessions today.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { CYCLE_TYPES } from '@/config/schedule';
import MiniLesson from '@/views/MiniSchedule/MiniLesson';
import DayHeader from '@/views/MiniSchedule/DayHeader';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';

export default {
  name: 'MiniScheduleDays',
  components: {
    MiniLesson,
    DayHeader,
    InlineLoader
  },
  props: {
    nowISO: {
      type: String,
      required: true
    },
    container: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isPhoneLayout'])
  },
  created() {
    this.CYCLE_TYPES = CYCLE_TYPES;
  },
  methods: {
    isCurrent(dayISO) {
      const m_day = moment(dayISO);
      const m_today = moment();

      return m_today.isSame(m_day, 'day');
    },
    isActive(lesson) {
      const m_event_start = moment(lesson.start_at);

      if (!m_event_start.isValid()) return false;

      return moment(this.nowISO).isSameOrAfter(m_event_start);
    },
    getDayLength(dayISO) {
      if (this.isPhoneLayout) return 1;

      return this.container.days[dayISO].length;
    }
  }
};
</script>

<style lang="scss" module>
@import './MiniSchedule.scss';
@import './WeekTypeFlex.scss';
@import './WeekTypeStudy.scss';
@import './WeekTypeHoliday.scss';
@import './WeekTypeClinicalRotation.scss';
</style>
