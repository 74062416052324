export const SITES = [
  {
    hostname: 'study.edu.edu.mt',
    tracking_key: 'aba3a9bc-569b-4953-8867-b11f9bd969cb',
    version_checking: true // whether this hostname should keep track of it's app code versions
  }
];

export const ROUTE_CHANGED_EVENT = 'ROUTE_CHANGED';
export const AUTH_CHANGED_EVENT = 'AUTH_CHANGED';
export const NEWER_VERSION_EVENT = 'NEWER_VERSION_AVAILABLE';

const VIRTUAL_PAGE_PREFIX = 'mf_virt-';

const prefix = page => {
  return `${VIRTUAL_PAGE_PREFIX}${page}`.replace('--', '-');
};

// This is a list of virtual page views that can be triggered
// for events that are supposed to be tracked but do not
// manifest themselves in browser URL (like opening test player modal)
export const VIRTUAL_PAGES = {
  TEST_PLAYER: prefix('test-player')
};
