<template>
  <ul :class="$style.wrapper">
    <li v-for="(user, index) in facilitators" :key="index">
      <User :user="user" size="25px" :withNameLink="true" />
    </li>
  </ul>
</template>

<script>
import User from '@/components/common/User/User';

export default {
  name: 'ListFacilitators',
  components: {
    User
  },
  props: {
    facilitators: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" module>
.wrapper {
  list-style-type: none;
  padding: 0;
  margin: 7px 0;

  li {
    padding: 0;
    font-size: 16px;
    line-height: 25px;
    margin: 7px 0;

    a {
      font-weight: 600;
    }
  }
}
</style>
