var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display404)?_c('NotFound'):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.header},[_c('router-link',{staticClass:"btn standard",class:_vm.$style.backButton,attrs:{"to":_vm.backLink}},[_c('i',{staticClass:"icon icon-chevron-left"})]),_c('h6',[_vm._v(_vm._s(_vm.groupDetail.object_name))]),_c('router-link',{class:_vm.$style.homeButton,attrs:{"to":"/admin"}},[_c('i',{staticClass:"icon icon-home"})])],1),(_vm.groupDetail.cohort)?_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columnsStudents,"rows":_vm.rows,"sort-options":{
          enabled: false
        },"compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'userGroupId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.userGroupId))])]):(props.column.field === 'teamId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.teamId))])]):(props.column.field === 'cohortName')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-cohort-detail',
                params: { cohortId: props.row.cohortId }
              }}},[_vm._v(" "+_vm._s(props.row.cohortName)+" "),_c('p',{staticClass:"adminSecondaryId"},[_vm._v(_vm._s(props.row.cohortId))])])],1):_vm._e()]}}],null,false,269034387)}):_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columnsAdministrative,"rows":_vm.rows,"sort-options":{
          enabled: false
        },"compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'userGroupId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.userGroupId))])]):(props.column.field === 'teamId')?_c('span',[_c('p',{staticClass:"adminPrimaryId"},[_vm._v(_vm._s(props.row.teamId))])]):_vm._e()]}}])}),_c('AdminGroupDetailUsers',{class:_vm.$style.students,attrs:{"groupType":_vm.groupDetail.group_type,"userGroupId":_vm.groupDetail.user_group_id,"groupMembers":_vm.groupDetail.members},on:{"reloadMembers":function($event){return _vm.fetchGroupDetail(_vm.$route.params.userGroupId)}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }