<template>
  <div v-if="displayScore" :class="$style.wrapper">
    <section>
      <div
        :class="$style.grade"
        v-if="displayScore || isCurrentUserTeachingStaff"
      >
        <h6 v-if="displayScore" :class="$style.points" data-cy="points_reached">
          Points reached: {{ scoreReached }}/{{ scoreMax }}
        </h6>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    scorePoints: {
      type: Number,
      default: NaN
    },
    scoreMaxPoints: {
      type: Number,
      default: NaN
    }
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff']),
    displayScore() {
      return (
        this.hasScorePoints &&
        this.hasScoreMaxPoints &&
        this.scoreMax > 0 &&
        this.scoreReached <= this.scoreMax
      );
    },
    hasScorePoints() {
      return !Number.isNaN(this.scoreReached);
    },
    scoreReached() {
      if (Number.isNaN(parseFloat(this.scorePoints))) return NaN;

      return parseFloat(this.scorePoints);
    },
    hasScoreMaxPoints() {
      return !Number.isNaN(this.scoreMax);
    },
    scoreMax() {
      if (Number.isNaN(parseFloat(this.scoreMaxPoints))) return NaN;

      return parseFloat(this.scoreMaxPoints);
    }
  }
};
</script>

<style lang="scss" module>
@import './AssessmentFeedback.scss';
</style>
