<template>
  <div :class="$style.wrapper">
    <div :class="{ [$style.status]: true, [$style.online]: isUserOnline }">
      You're <strong>{{ isUserOnline ? 'online' : 'offline' }}</strong>
      <label
        :class="$style.switch"
        @click="isUserOnline ? changeStatus('away') : changeStatus('online')"
      >
        <input type="checkbox" :checked="isUserOnline" />
        <span :class="$style.sliderRound"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_TEAM_PROFILE, CHANGE_USER_STATUS } from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  computed: {
    ...mapGetters([
      'currentUserId',
      'currentUserTeamSlug',
      'currentTeamProfile'
    ]),
    isUserOnline() {
      if (!this.currentUserId) return false;

      if (
        !this.currentTeamProfile ||
        !this.currentTeamProfile.members ||
        !Array.isArray(this.currentTeamProfile.members)
      ) {
        return false;
      }

      const currentUserInTeam = this.currentTeamProfile.members.find(
        member => member.user_id === this.currentUserId
      );

      if (!currentUserInTeam) return false;

      if (currentUserInTeam.status === 'online') return true;

      return false;
    }
  },
  methods: {
    async changeStatus(desiredStatus) {
      try {
        await this.$store.dispatch(CHANGE_USER_STATUS, desiredStatus);
        if (this.currentUserTeamSlug) {
          await this.$store.dispatch(
            GET_TEAM_PROFILE,
            this.currentUserTeamSlug
          );
        }
      } catch (error) {
        this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './StatusSwitcher.scss';
</style>
