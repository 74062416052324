<template>
  <TickTack
    :emitAfterTicks="10"
    emit="update"
    @update="updateNow"
    :class="$style.wrapper"
  >
    <Layout variant="3">
      <template #pageSideB>
        <div
          :class="{
            [$style.side]: true,
            [$style.stickyNavigation]: !userSettingValue(
              SETTING_CODES.FIXED_NAV
            )
          }"
        >
          <SideWidgetDeadlines :nowISO="nowISO" v-if="isCurrentUserStudent" />
          <SideWidgetKeyDates />
          <SideWidgetAssessments :currentTime="nowISO" />
        </div>
      </template>
      <template #pageContent>
        <MiniSchedule
          :class="$style.miniSchedule"
          :nowISO="nowISO"
          v-if="!isCurrentUserAdmin"
        />
        <Tasks :class="$style.tasks" />
        <NewsfeedBlog :news="posts" />
      </template>
    </Layout>
  </TickTack>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { SETTING_CODES } from '@/config/userSettings';

import {
  GET_BLOG_POSTS,
  GET_CURRENT_USER_PROFILE,
  GET_TEAM_PROFILE
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { toServerFormat } from '@/helpers/datetime';

import Layout from '@/views/Layout/Layout';
import NewsfeedBlog from '@/components/blog/NewsfeedBlog/NewsfeedBlog';
import Tasks from '@/views/Tasks/Tasks';
import MiniSchedule from '@/views/MiniSchedule/MiniSchedule';
import TickTack from '@/components/common/TickTack/TickTack';
import SideWidgetDeadlines from '@/components/common/SideWidgetDeadlines/SideWidgetDeadlines';
import SideWidgetKeyDates from '@/components/common/SideWidgetKeyDates/SideWidgetKeyDates';
import SideWidgetAssessments from '@/components/schedule/SideWidgetAssessments/SideWidgetAssessments';

export default {
  components: {
    Layout,
    NewsfeedBlog,
    Tasks,
    MiniSchedule,
    TickTack,
    SideWidgetDeadlines,
    SideWidgetKeyDates,
    SideWidgetAssessments
  },
  data() {
    return {
      since: null,
      nowISO: moment().toISOString(true),
      posts: []
    };
  },
  computed: {
    ...mapGetters([
      'currentUserTeamSlug',
      'userSettingValue',
      'isCurrentUserAdmin',
      'isCurrentUserStudent'
    ]),
    ...mapGetters('blog', ['postsPreviews'])
  },
  mounted() {
    this.since = moment();

    this.fetchBlogPosts();
  },
  async created() {
    this.SETTING_CODES = SETTING_CODES;

    try {
      await this.$store.dispatch(GET_CURRENT_USER_PROFILE);

      if (this.currentUserTeamSlug) {
        await this.$store.dispatch(GET_TEAM_PROFILE, this.currentUserTeamSlug);
      }
    } catch (error) {
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  },
  methods: {
    updateNow(dateISO) {
      this.nowISO = moment(dateISO).toISOString(true);
    },
    fetchBlogPosts() {
      const params = {
        limit: 2,
        offset: 0,
        timestamp: toServerFormat(this.since)
      };
      this.$store
        .dispatch(GET_BLOG_POSTS, params)
        .then(posts => {
          this.posts = posts;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    }
  }
};
</script>

<style lang="scss" module>
@import './Dashboard.scss';
</style>
