<template>
  <div :class="$style.wrapper">
    <h6>{{ spec.label }}</h6>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :group-options="{
        enabled: true
      }"
      :sort-options="{
        enabled: false
      }"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'testLabel'">
          <p
            :class="{
              [$style.disabledDescription]: !props.row.testDescription,
              [$style.seeDescription]: true
            }"
          >
            <i
              class="icon icon-info"
              @click="handleOpened(props.row.testDescription)"
            />
            {{ props.row.testLabel }}
          </p>
        </span>
        <span v-else-if="props.column.field === 'testResult'">
          <div :class="$style.testResult">
            <i
              v-if="props.row.testResult == true"
              class="icon icon-check-circle"
              :class="$style.testGreen"
            />
            <i
              v-else-if="props.row.testResult == false"
              class="icon icon-times-circle"
              :class="$style.testRed"
            />
            <i
              v-else
              class="icon icon-question-circle-o"
              :class="$style.testDisabled"
            />
          </div>
        </span>
      </template>
    </vue-good-table>

    <ModalNew
      :class="$style.adminModal"
      :showModal="showModal"
      v-transfer-dom
      @close="handleClosed"
    >
      {{ description }}
    </ModalNew>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      showModal: false,
      description: '',
      columns: [
        {
          label: 'Name',
          field: 'testLabel',
          tdClass: this.disabledTest
        },
        {
          label: 'Last Run',
          field: 'testLastRun',
          width: '180px',
          tdClass: this.disabledTest
        },
        {
          label: 'Result',
          field: 'testResult',
          width: '65px',
          tdClass: this.disabledTest
        }
      ],
      rows: []
    };
  },
  props: {
    spec: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    this.createRows();
  },
  methods: {
    createRows() {
      this.rows = [];
      if (!this.spec || !this.spec.contexts) return [];

      this.spec.contexts.map(context => {
        this.rows.push({
          label: context.label || null,
          mode: 'span',
          children: context.tests.map(test => {
            return {
              testLabel: test.label,
              testLastRun: this.lastRun(test),
              testResult: test.last_run_succesful,
              testDisabled: test.enabled,
              testDescription: test.description
            };
          })
        });
      });
    },
    lastRun(test) {
      if (test.last_run) {
        return moment(test.last_run).format('DD.MM.yyyy, HH:mm');
      }

      return 'Not tested yet';
    },
    disabledTest(phase) {
      if (phase.testDisabled == false) {
        return 'disabledTest';
      } else if (phase.testResult == true) {
        return 'succesfulTest';
      } else if (phase.testResult == false) {
        return 'failedTest';
      }
    },
    handleOpened(description) {
      if (description) {
        this.description = description;
        this.showModal = true;
      }

      return;
    },
    handleClosed() {
      this.description = '';
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminSpecs.scss';
</style>
