<template>
  <button v-on:click="$emit('click')" :class="$style.wrapper">
    <slot>
      <i class="icon icon-question" />
    </slot>
  </button>
</template>

<script>
export default {
  name: 'IconButton'
};
</script>

<style lang="scss" module>
@import '~@/assets/css/generated-variables.scss';
.wrapper {
  background-color: $primaryColor;
  color: $white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}
</style>
