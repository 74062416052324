<template>
  <Layout variant="5">
    <template slot="pageContent">
      <template v-if="isLoading">
        <InlineLoader />
      </template>
      <template v-else-if="assignments.length">
        <div class="box-shadow-white" :class="$style.wrapper">
          <div :class="$style.perex">
            <span>
              <img
                src="~@/assets/images/icons/group-work.svg"
                width="120px"
                alt=""
              />
            </span>
            <div>
              <h6>
                There is no Group Assignment available this week.
              </h6>
              <p v-if="hasFutureItem">
                Next Group Assignment will be available on
                <router-link
                  :to="{
                    name: 'assignment-detail',
                    params: {
                      assignmentId:
                        futureItem.parent_assignment_id ||
                        futureItem.assignment_id,
                      scheduledAssignmentId: futureItem.scheduled_assignment_id
                    }
                  }"
                  >{{ formatDate(futureItem.start_at) }}</router-link
                >
              </p>
            </div>
            <p>
              <!-- Newrow button -->
            </p>
          </div>

          <div v-if="hasPastItems">
            <p :class="$style.tableHeading">
              Take a look at the latest Group Assignments
            </p>

            <section :class="$style.table">
              <template v-if="isCurrentUserTeachingStaff">
                <Item
                  v-for="(row, index) in pastItems.slice(
                    numberOfPastAssignments
                  )"
                  :key="index"
                  :item="row"
                />
              </template>
              <template v-else>
                <Item
                  v-for="(row, index) in pastItems"
                  :key="index"
                  :item="row"
                />
              </template>

              <footer></footer>
            </section>
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="$style.empty">
          <img
            v-svg-inline
            :src="require(`@/assets/images/icons/group-work.svg`)"
            height="250px"
            alt=""
          />
          <p class="text-light-grey paragraph-large text-bold semibold">
            There is no Group Assignment available for this module.
          </p>
        </div>
      </template>
    </template>
  </Layout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Layout from '@/views/Layout/Layout';
import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import Item from '@/views/GroupWork/GroupWorkItem';
import { GET_MODULE_GROUP_WORKS } from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { logError } from '@/helpers/errors';
import { periodsByTime } from '@/helpers/schedule';

export default {
  components: {
    Layout,
    InlineLoader,
    Item
  },
  props: {
    numberOfPastAssignments: {
      type: Number,
      default: -7
    }
  },
  data() {
    return {
      isLoading: true,
      assignments: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff']),
    assignmentsByTime() {
      if (!this.assignments.length) return undefined;

      return periodsByTime(
        this.assignments,
        moment().toISOString(),
        false,
        'start_at',
        'end_at',
        true
      );
    },
    pastItems() {
      if (this.hasPastItems) {
        const pastItemsClone = [...this.assignmentsByTime.past];
        return pastItemsClone.reverse();
      }

      return [];
    },
    hasPastItems() {
      if (this.assignmentsByTime === undefined) return false;

      if (this.assignmentsByTime.past.length) return true;

      return false;
    },
    hasFutureItem() {
      if (this.assignmentsByTime === undefined) return false;

      if (this.assignmentsByTime.future.length) return true;

      return false;
    },
    currentItem() {
      if (this.assignmentsByTime === undefined) return undefined;

      if (this.assignmentsByTime.all.length < 1) return null;

      if (this.assignmentsByTime.present.length > 0)
        return this.assignmentsByTime.present[0];

      return undefined;
    },
    futureItem() {
      if (this.hasFutureItem === false) return null;

      if (this.assignmentsByTime.future.length > 0)
        return this.assignmentsByTime.future[0];

      return undefined;
    }
  },
  methods: {
    formatDate(dateISOString) {
      if (!dateISOString) return '';

      return moment
        .tz(dateISOString, 'Europe/Berlin')
        .format('DD MMM YYYY dddd hh:mm a z');
    }
  },
  async created() {
    try {
      const currentModuleGroupWorks = await this.$store.dispatch(
        `groupWorkNext/${GET_MODULE_GROUP_WORKS}`
      );

      this.assignments = Array.isArray(currentModuleGroupWorks)
        ? currentModuleGroupWorks
        : [];

      this.isLoading = false;

      if (this.currentItem && this.currentItem.assignment_id) {
        this.$router
          .push({
            name: 'assignment-detail',
            params: {
              assignmentId:
                this.currentItem.parent_assignment_id ||
                this.currentItem.assignment_id,
              scheduledAssignmentId:
                this.currentItem.scheduled_assignment_id || undefined
            }
          })
          .catch(() => {});

        return;
      }
    } catch (error) {
      this.isLoading = false;
      this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
    }
  }
};
</script>

<style lang="scss" module>
@import './GroupWorkItem.scss';
</style>
