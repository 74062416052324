export const state = {
  categories: [],
  forums: []
};

export const getters = {
  categories(state) {
    return state.categories;
  },
  categoryForums: state => categorySlug => {
    if (!categorySlug || !state.forums || !state.forums.length) {
      return [];
    }

    return state.forums.filter(forum => forum.category_slug === categorySlug);
  }
};

export const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setForums(state, forums) {
    state.forums = forums;
  }
};

export default {
  state,
  getters,
  mutations
};
