<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <p class="text-dark-grey"><span :class="$style.dot" />Currently Online</p>
    </div>
    <div :class="$style.users">
      <div :class="$style.user" v-for="user in users" :key="user.user_id">
        <router-link
          :to="{ name: 'user-profile', params: { userId: user.user_id } }"
        >
          <span :class="$style.userDetail">
            <Avatar :user="user" :size="size" />
            <span :class="$style.userDetailText">
              <p><UserName :user="user" /></p>
              <p class="paragraph-small">{{ user.activity }}</p>
            </span>
          </span>
        </router-link>
        <a @click="onIconClick(user)"><i class="icon icon-comment-o"/></a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/User/Avatar';

export default {
  components: {
    Avatar
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: '40px'
    }
  },
  methods: {
    onIconClick(userProfile) {
      this.$emit('open_conversation', userProfile);
    }
  }
};
</script>

<style module lang="scss">
@import './OnlineUserList.scss';
</style>
