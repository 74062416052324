<template>
  <input
    type="checkbox"
    v-bind="$attrs"
    v-bind:value="value"
    v-bind:checked="modelValue"
    v-on="inputListeners"
  />
</template>

<script>
import debounce from 'lodash.debounce';
import {
  STATES,
  DEBOUNCE_CHANGE_DELAY,
  ACTION_COMPLETE_TIMEOUT,
  CLEAR_SUCCESS_AFTER,
  CLEAR_ERROR_AFTER
} from '@/config/forms';

export default {
  inheritAttrs: false, // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  props: {
    value: {
      type: Boolean
    },
    modelValue: {
      type: Boolean
    },
    action: {
      type: Function, // Parent should pass Function that returns a Function that returns a Promise
      required: false
    },
    validation: {
      type: Function, // Parent should pass Function that returns a Boolean
      required: false
    },
    delayLoaderDisplay: {
      type: Number,
      default: ACTION_COMPLETE_TIMEOUT
    },
    clearSuccessAfter: {
      type: Number,
      default: CLEAR_SUCCESS_AFTER
    },
    clearErrorAfter: {
      type: Number,
      default: CLEAR_ERROR_AFTER
    }
  },
  data() {
    return {
      actionTimeoutId: undefined,
      successTimeoutId: undefined,
      errorTimeoutId: undefined,
      debouncedActionHandler: undefined
    };
  },
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        click: event => {
          // At this point HTML API in browser has already changed the "checked" attribute :(
          // But it is not rendered yet, so...
          // We'll set the attribute back to oldValue at the top of this.actionHandler function
          // then (as soon as this.action resolves sucessfully) set it again
          // to the newValue from click event we're handling here

          const oldValue = this.modelValue;
          const newValue = event.target.checked;

          // The following lines will prevent native events like 'change' & 'input' from firing
          // and rendering the checkbox state before we know that the newValue is validated
          // and this.action resolved sucessfully
          event.preventDefault();
          event.stopPropagation();

          this.removeAllTimeouts();
          this.debouncedActionHandler(newValue, oldValue);
        },
        change: function(event) {
          throw new Error(
            'Native "change" event fired from InputSwitch which is not supposed to happen ever!',
            event
          );
        },
        input: function(event) {
          throw new Error(
            'Native "input" event fired from InputSwitch which is not supposed to happen ever!',
            event
          );
        }
      });
    }
  },
  created() {
    this.debouncedActionHandler = debounce(
      this.actionHandler,
      DEBOUNCE_CHANGE_DELAY
    );
  },
  methods: {
    actionHandler(newValue, oldValue) {
      // See click handler comments in inputListeners
      // on why $emit oldValue change here..
      this.$emit('change', oldValue);

      let validationResult = undefined;

      if (typeof this.validation !== 'function') {
        throw new Error('Validation function is required!');
      } else {
        validationResult = this.validation(newValue);
        const isBooleanResult = typeof validationResult === 'boolean';

        if (isBooleanResult) {
          this.$emit('validation_result', validationResult);
        } else {
          throw new Error('Validation must return Boolean!');
        }
      }

      if (this.action && validationResult === true) {
        this.setActionTimeout(() => {
          this.$emit('state', STATES.in_action);
        }, this.delayLoaderDisplay);

        this.action(newValue)
          .then(() => {
            this.removeActionTimeout(this.actionTimeoutId);
            this.$emit('change', newValue);
            this.$emit('state', STATES.success);
            this.$emit('success', newValue); // Allow for direct use of @success at parent

            if (this.clearSuccessAfter && !this.successTimeoutId) {
              this.setSuccessTimeout(() => {
                this.$emit('state', STATES.idle);
              }, this.clearSuccessAfter);
            }
          })
          .catch(error => {
            this.removeActionTimeout(this.actionTimeoutId);

            this.$emit('state', STATES.error);
            this.$emit('error', error); // Allow for direct use of @error at parent

            if (this.clearErrorAfter && !this.errorTimeoutId) {
              this.setErrorTimeout(() => {
                this.$emit('state', STATES.idle);
              }, this.clearErrorAfter);
            }
          });
      } else {
        this.$emit('state', STATES.idle);
      }
    },
    setActionTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.actionTimeoutId = window.setTimeout(callback, delay);
    },
    setSuccessTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.successTimeoutId = window.setTimeout(callback, delay);
    },
    setErrorTimeout(callback, delay) {
      if (!callback) return;
      if (!delay) return;

      this.errorTimeoutId = window.setTimeout(callback, delay);
    },
    removeActionTimeout(actionTimeoutId) {
      if (!actionTimeoutId) return;

      window.clearTimeout(actionTimeoutId);
      this.actionTimeoutId = undefined;
    },
    removeSuccessTimeout(successTimeoutId) {
      if (!successTimeoutId) return;

      window.clearTimeout(successTimeoutId);
      this.successTimeoutId = undefined;
    },
    removeErrorTimeout(errorTimeoutId) {
      if (!errorTimeoutId) return;

      window.clearTimeout(errorTimeoutId);
      this.errorTimeoutId = undefined;
    },
    removeAllTimeouts() {
      this.removeActionTimeout(this.actionTimeoutId);
      this.removeSuccessTimeout(this.successTimeoutId);
      this.removeErrorTimeout(this.errorTimeoutId);
    }
  },
  beforeDestroy() {
    this.removeAllTimeouts();
  }
};
</script>
