import ApiService from '@/api/api.service';

import {
  isAssignmentAfterDeadline,
  isAssignmentAfterStart
} from '@/helpers/assignments';
import {
  hasSingleFileUploadQuestion,
  hasSingleCollaborativeEditorQuestion
} from '@/helpers/submissions';

import { RESET_STATE } from '@/store/mutations.type';

export const state = {
  assignment: {},
  submission: {},
  resource: {}
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  assignment(state) {
    return state.assignment;
  },
  isAssignmentAfterDeadline: state => nowISO => {
    return isAssignmentAfterDeadline(state.assignment, nowISO);
  },
  isAssignmentAfterStart: state => nowISO => {
    return isAssignmentAfterStart(state.assignment, nowISO);
  },
  submission(state) {
    return state.submission;
  },
  resource(state) {
    return state.resource;
  },
  submissionId(state) {
    if (state.assignment.submission_id) {
      return state.assignment.submission_id;
    }

    return undefined;
  },
  questionId(state, getters) {
    if (!getters.isFileUploadTask && !getters.isCollaborativeTask)
      return undefined;

    const firstQuestion = state.submission.questions[0];
    const firstQuestionId = parseInt(firstQuestion.id);

    return !Number.isNaN(firstQuestionId) ? firstQuestionId : undefined;
  },
  isFileUploadTask(state) {
    if (
      !state.submission ||
      !state.submission.questions ||
      state.submission.questions.length < 1
    )
      return undefined;

    const isFileUpload = hasSingleFileUploadQuestion(
      state.submission.questions
    );

    return isFileUpload ? true : false;
  },
  isCollaborativeTask() {
    if (
      !state.submission ||
      !state.submission.questions ||
      state.submission.questions.length < 1
    )
      return undefined;

    const isCollaborativeText = hasSingleCollaborativeEditorQuestion(
      state.submission.questions
    );

    return isCollaborativeText ? true : false;
  },
  hasSubmission(state) {
    if (
      !state.submission ||
      !state.submission.questions ||
      state.submission.questions.length < 1
    )
      return false;

    const isFileUpload = hasSingleFileUploadQuestion(
      state.submission.questions
    );
    const isCollaborativeText = hasSingleCollaborativeEditorQuestion(
      state.submission.questions
    );

    const firstQuestion = state.submission.questions[0];

    if (isFileUpload && firstQuestion.resource_slug) return true;

    if (
      isCollaborativeText &&
      (firstQuestion.collaborative_editor_answer ||
        firstQuestion.firebase_rtdb_path)
    )
      return true;

    return false;
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      }
    }
  },
  setAssignment(state, assignment) {
    state.assignment = assignment;
  },
  setSubmission(state, submission) {
    state.submission = submission;
  },
  setResource(state, resource) {
    state.resource = resource;
  }
};

export const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
