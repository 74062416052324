<template>
  <div id="Emojis">
    <div ref="container-emoji" class="container-emoji">
      <template v-if="continuousList">
        <div
          v-for="(category, category_name) in dataFilteredByCategory"
          :key="category_name"
        >
          <CategoryLabel
            v-show="category.length"
            :name="category_name"
            :ref="category_name"
          />
          <div v-if="category.length" class="grid-emojis" :style="gridDynamic">
            <EmojiItem
              v-for="(emoji, index_e) in category"
              :key="`${category_name}-${index_e}`"
              :emoji="emoji"
              :size="emojiSize"
              :withBorder="emojiWithBorder"
              @click.native="onSelect(emoji)"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="grid-emojis" :style="gridDynamic">
          <EmojiItem
            v-for="(emoji, index) in dataFiltered"
            :key="index"
            :emoji="emoji"
            :size="emojiSize"
            :withBorder="emojiWithBorder"
            @click.native="onSelect(emoji)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EmojiItem from './EmojiItem.vue';
import CategoryLabel from './CategoryLabel.vue';

export default {
  components: {
    EmojiItem,
    CategoryLabel
  },
  props: {
    data: {
      required: true
    },
    emojisByRow: {
      type: Number
    },
    emojiWithBorder: {
      type: Boolean
    },
    emojiSize: {
      type: Number
    },
    filter: {
      type: String
    },
    continuousList: { type: Boolean },
    category: {
      type: String
    },
    hasSearch: {
      type: Boolean
    }
  },
  computed: {
    calcScrollTop() {
      return this.hasSearch ? 88 : 44;
    },
    gridDynamic() {
      const percent = 100 / this.emojisByRow;
      return {
        gridTemplateColumns: `repeat(${this.emojisByRow}, ${percent}%)`
      };
    },

    dataFiltered() {
      let data = this.data[this.category];
      const searchValue = this.filter.trim().toLowerCase();

      if (searchValue) {
        data = data.filter(emoji => this.searchByAlias(searchValue, emoji));
      }

      return data;
    },

    dataFilteredByCategory() {
      let _data = Object.assign({}, this.data);
      const searchValue = this.filter.trim().toLowerCase();

      if (searchValue) {
        this.categories.forEach(category => {
          _data[category] = this.data[category].filter(item =>
            this.searchByAlias(searchValue, item)
          );
        });
      }

      return _data;
    },

    categories() {
      return Object.keys(this.data);
    },

    containerEmoji() {
      return this.$refs['container-emoji'];
    }
  },
  methods: {
    searchByAlias(term, emoji) {
      const isRelevant = alias => alias.toLowerCase().includes(term);

      return emoji.aliases.some(alias => isRelevant(alias));
    },

    onSelect(emoji) {
      this.$emit('select', emoji);
      return emoji;
    }
  },
  watch: {
    data() {
      this.containerEmoji.scrollTop = 0;
    },
    category(newValue) {
      if (this.continuousList) {
        const categoryEl = this.$refs[newValue][0].$el;

        this.containerEmoji.scrollTop =
          categoryEl.offsetTop - this.calcScrollTop();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#Emojis {
  font-family: Twemoji, NotomojiColor, Notomoji, EmojiOne Color, Symbola, Noto,
    Segoe UI Emoji, OpenSansEmoji, monospace;
  display: block;
  width: 100%;
  max-width: 100%;
  color: var(--ep-color-text);

  // Custom Scroll
  ::-webkit-scrollbar {
    border-radius: 4px;
    width: 4px;
    overflow: hidden;
  }
}

.container-emoji {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 350px;
}

.grid-emojis {
  display: grid;
  margin: 5px 0;
  justify-items: center;
}
</style>
