<template>
  <div :class="$style.wrapper">
    <section :class="$style.header">
      <h1 data-ref="title" :class="$style.title" v-html="title" />

      <div v-if="author" :class="$style.author">
        <AvatarEDU size="40px" />
        <div :class="$style.info">
          <p :class="$style.name">
            <label for="posted">
              Posted by
              <router-link
                v-if="author.user_id"
                :to="{
                  name: 'user-profile',
                  params: { userId: author.user_id }
                }"
              >
                <UserName :preTitles="true" :user="author" />
              </router-link>
              <span v-else>EDU Staff</span>
            </label>
          </p>

          <div>
            <time
              data-ref="publishedAt"
              :datetime="publishedAtISO"
              :class="$style.date"
            >
              {{ publishedAt }}
            </time>

            <ul :class="$style.tags">
              <li>
                <router-link
                  :to="{ name: 'blog-filter', params: { tag: tag.code } }"
                >
                  {{
                    tag && tag.label ? formatTagLabel(tag.label) : ''
                  }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <input type="hidden" data-cy="audience" :value="cohortIds" />
  </div>
</template>

<script>
import moment from 'moment';
import { formatTag } from '@/helpers/common';
import AvatarEDU from '@/components/common/User/AvatarEDU';

export default {
  components: {
    AvatarEDU
  },
  props: {
    title: {
      type: String
    },
    author: {
      type: Object
    },
    publishedAtISO: {
      type: String
    },
    tag: {
      type: Object
    },
    cohortIds: {
      type: Array
    }
  },
  computed: {
    publishedAt() {
      const m_publishedAt = moment(this.publishedAtISO);

      return m_publishedAt.isValid()
        ? m_publishedAt.format('DD MMM, YYYY | HH:mm')
        : 'Not published yet';
    }
  },
  methods: {
    formatTagLabel(tag) {
      return formatTag(tag);
    }
  }
};
</script>

<style module lang="scss">
@import './BlogPostHeader.scss';
</style>
