<template>
  <div :class="$style.wrapper">
    <header>
      <a @click="displayPreviousMonth" :class="$style.link">
        <i class="icon icon-chevron-left"></i>
      </a>
      <span :class="$style.month"
        ><strong>{{ displayMonthMoment.format('MMMM') }}</strong></span
      >
      <span :class="$style.year">{{ displayMonthMoment.format('YYYY') }}</span>
      <a @click="displayNextMonth" :class="$style.link">
        <i class="icon icon-chevron-right"></i>
      </a>
    </header>
    <section>
      <div :class="$style.calendarGrid">
        <span
          v-for="(dayName, dayNameIndex) in daysOfWeek"
          :key="`day-name-${dayNameIndex}`"
          :class="$style.week"
        >
          {{ dayName }}
        </span>
        <div style="display: contents;" :class="$style.days">
          <span
            v-for="(day, dayMonthIndex) in daysOfMonth"
            :class="{
              // [$style.isSameMonth]: isSameMonth(day),
              // [$style.isDifferentMonth]: !isSameMonth(day),
              [$style.isSelectedWeek]: isSelectedWeek(day),
              [$style.isCurrentWeek]: isCurrentWeek(day),
              [$style.isWorkDay]: isWorkDay(day),
              [$style.isSelectable]: isSelectable(day)
            }"
            @click="onSelected(day)"
            :key="`day-month-${dayMonthIndex}`"
          >
            {{ day.format('D') }}
          </span>
        </div>
      </div>
    </section>
    <footer>
      <a @click="onSelected(null)" :class="$style.link">This week</a>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { weekdaysBetween } from '@/helpers/schedule';
import { sortingByDate } from '@/helpers/common';

export default {
  name: 'CalendarWeekSelect',
  props: {
    selectedWeekStartAt: {
      type: String
    },
    weeks: {
      type: Array
    }
  },
  data() {
    return {
      displayMonthMoment: undefined
    };
  },
  created() {
    this.displayMonthMoment = this.selectedWeekMoment.clone().startOf('month');
  },
  computed: {
    ...mapGetters(['currentUserProfile']),
    isoWeeksRange() {
      const sortedWeeks = sortingByDate(this.weeks, 'start_date');

      const minISOWeek = moment
        .min(sortedWeeks.map(week => moment(week.start_date)))
        .startOf('isoWeek');
      const maxISOWeek = moment
        .max(sortedWeeks.map(week => moment(week.start_date)))
        .startOf('isoWeek');

      return { min: minISOWeek, max: maxISOWeek };
    },
    selectedWeekMoment() {
      return moment(this.selectedWeekStartAt).startOf('isoWeek');
    },
    currentWeekMoment() {
      return moment().startOf('isoWeek');
    },
    daysOfWeek() {
      return [1, 2, 3, 4, 5, 6, 7].map(i =>
        moment()
          .isoWeekday(i)
          .format('ddd')
      );
    },
    daysOfMonth() {
      const month_start = this.displayMonthMoment
        .clone()
        .startOf('month')
        .startOf('isoWeek');
      const month_end = this.displayMonthMoment
        .clone()
        .endOf('month')
        .endOf('isoWeek');

      return this.weeksBetween(month_start, month_end);
    }
  },
  methods: {
    // isSameMonth(m_day) {
    //   return m_day.clone().isSame(this.displayMonthMoment, 'month');
    // },
    isSelectedWeek(m_day) {
      return m_day.clone().isSame(this.selectedWeekMoment, 'isoWeek');
    },
    isCurrentWeek(m_day) {
      return m_day.clone().isSame(this.currentWeekMoment, 'isoWeek');
    },
    isWorkDay(m_day) {
      return ![6, 7].includes(m_day.clone().isoWeekday());
    },
    isSelectable(m_day) {
      if (!m_day) return false;

      return (
        m_day.isSameOrAfter(this.isoWeeksRange.min, 'isoWeek') &&
        m_day.isSameOrBefore(this.isoWeeksRange.max, 'isoWeek')
      );
    },
    weeksBetween(m_periodStart, m_periodEnd) {
      let currentDay = m_periodStart.clone();
      let days = [];

      while (!currentDay.isAfter(m_periodEnd)) {
        days.push(currentDay);

        currentDay = currentDay.clone().add(1, 'days');
      }

      return days;
    },
    onSelected(m_day) {
      if (m_day === null) {
        this.$emit(
          'calendar_week_selected',
          this.currentWeekMoment.toISOString(true)
        );
      }

      if (this.isSelectable(m_day) === false) return;
      if ([6, 7].includes(m_day.isoWeekday())) return;

      this.$emit(
        'calendar_week_selected',
        m_day
          .clone()
          .startOf('isoWeek')
          .toISOString(true)
      );
    },
    displayPreviousMonth() {
      this.displayMonthMoment = this.displayMonthMoment
        .clone()
        .subtract(1, 'month');
    },
    displayNextMonth() {
      this.displayMonthMoment = this.displayMonthMoment.clone().add(1, 'month');
    }
  }
};
</script>

<style lang="scss" module>
@import './CalendarWeekSelect.scss';
</style>
