<template>
  <span :class="$style.wrapper">
    <input
      :checked="isChecked"
      :value="value"
      v-bind="$attrs"
      type="radio"
      v-on="eventListeners"
    />
    <span />
  </span>
</template>

<script>
export default {
  inheritAttr: false,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {},
    modelValue: {}
  },
  computed: {
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        change: event => {
          this.$emit('change', event.target.value);
        }
      });
    },
    isChecked() {
      return this.value === this.modelValue;
    }
  }
};
</script>

<style lang="scss" module>
@import 'FormRadio.scss';
</style>
