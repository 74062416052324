var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SideWidget',{class:_vm.$style.wrapper,attrs:{"title":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AdminUserInfo',{class:_vm.$style.info,attrs:{"user":_vm.userDetail,"studentId":_vm.userDetail.student_id}}),_c('div',{class:_vm.$style.basic},[(_vm.userDetail.role && _vm.userDetail.role.code === 'student')?_c('div',{class:_vm.$style.activity},[_c('p',{staticClass:"text-bold",class:_vm.$style.status},[(
              _vm.studentDetail &&
                _vm.studentDetail.cohort &&
                _vm.studentDetail.cohort.active
            )?_c('span',{class:_vm.$style.statusActive},[_vm._v("ACTIVE")]):_c('span',{class:_vm.$style.statusHold},[_vm._v("ON HOLD")])]),(
            _vm.studentDetail &&
              _vm.studentDetail.cohort &&
              _vm.studentDetail.cohort.active
          )?_c('p',{class:_vm.$style.highlightSpan},[_vm._v(" in cohort "),_c('span',[_c('router-link',{attrs:{"to":{
                name: 'admin-cohort-detail',
                params: { cohortId: _vm.studentDetail.cohort.cohort_id }
              }}},[_vm._v(" "+_vm._s(_vm.studentDetail.cohort.cohort_name)+" ")])],1)]):_vm._e()]):_vm._e(),_c('div',{class:_vm.$style.email},[_c('a',{attrs:{"href":("mailto:" + (_vm.userDetail.email))}},[_c('i',{staticClass:"icon icon-envelope-o"}),_vm._v(" "+_vm._s(_vm.userDetail.email))])])]),_c('ul',{class:_vm.$style.listDates},[_c('li',[_c('h6',[_vm._v(" Created ")]),_c('p',{staticClass:"paragraph-large"},[_vm._v(" "+_vm._s(_vm.formatDays(_vm.userDetail.created_at))+" ")]),_c('p',[_vm._v(_vm._s(_vm.formatTime(_vm.userDetail.created_at)))])]),(_vm.userDetail && _vm.userDetail.last_activity)?_c('li',[_c('h6',[_vm._v(" Last login ")]),_c('p',{staticClass:"paragraph-large"},[_vm._v(" "+_vm._s(_vm.formatDays(_vm.userDetail.last_activity))+" ")]),_c('p',[_vm._v(_vm._s(_vm.formatTime(_vm.userDetail.last_activity)))])]):_c('li',[_c('h6',[_vm._v(" Last login ")]),_c('p',{class:_vm.$style.highlight},[_vm._v(" Never ")])]),_c('li',[_c('h6',[_vm._v(" Password changed ")]),_c('p',{staticClass:"paragraph-large"},[_vm._v(" "+_vm._s(_vm.formatDays(_vm.userDetail.password_changed_at))+" ")]),_c('p',[_vm._v(_vm._s(_vm.formatTime(_vm.userDetail.password_changed_at)))])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }