<template>
  <div :class="$style.wrapper" class="border">
    <div :class="$style.quote">
      <div :class="$style.quoteField">
        <FormTextareaAutoSave
          data-ref="personalQuote"
          data-cy="personalQuote"
          :showCounter="true"
          :maxCount="200"
          v-model="personal_quote"
          :rows="5"
          :action="
            updateProfile({
              personal_quote: personal_quote
            })
          "
          placeholder="Pick an inspiring quote, motto or something you believe on."
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_USER_PROFILE } from '@/store/actions.type';
import { ADD_USER_PROFILE_SETTINGS } from '@/store/mutations.type';

export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateProfile(params) {
      return () => {
        return this.$store.dispatch(UPDATE_USER_PROFILE, {
          userId: this.userId,
          profileData: params
        });
      };
    }
  },
  computed: {
    ...mapGetters(['userProfileSettings']),
    userId() {
      if (!this.user) return undefined;
      if (!this.user.user_id) return undefined;

      return parseInt(this.user.user_id);
    },
    personal_quote: {
      get() {
        const profile = this.userProfileSettings(this.userId);

        if (
          !profile ||
          !profile.profile_data ||
          !profile.profile_data.personal_quote
        ) {
          return undefined;
        }

        return profile.profile_data.personal_quote;
      },
      set(value) {
        this.$store.commit(ADD_USER_PROFILE_SETTINGS, {
          personal_quote: value
        });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './UserProfileImageSettings.scss';
</style>
