<template>
  <div :class="$style.heading">
    <a v-if="!isAtTop" @click="$emit('navigate_up')"
      ><i class="icon icon-chevron-left"
    /></a>
    <span v-else></span>
    <p>
      Conversations
    </p>
    <i
      class="icon icon-plus"
      title="Start a conversation"
      @click="createConversation"
    />
  </div>
</template>

<script>
export default {
  name: 'ConversationsHeader',
  computed: {
    isAtTop() {
      return this.$route.name === 'conversations-list';
    }
  },
  methods: {
    createConversation() {
      this.$router
        .replace({
          name: 'conversation-new'
        })
        .catch(error => void error);
    }
  }
};
</script>

<style lang="scss" module>
@import './ConversationsHeaderMobile.scss';
</style>
