<template>
  <span :class="$style.wrapper">
    <input
      :checked="isChecked"
      :value="value"
      v-bind="$attrs"
      type="checkbox"
      v-on="eventListeners"
    />
    <span><i class="icon icon-check"/></span>
  </span>
</template>

<script>
export default {
  inheritAttr: false,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {},
    modelValue: {}
  },
  computed: {
    // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    eventListeners() {
      return Object.assign({}, this.$listeners, {
        change: event => {
          const checked = event.target.checked;

          // for a group of more related checkboxes (=> v-model Array)
          if (this.modelValue instanceof Array) {
            let newModelValue = [...this.modelValue];

            if (checked) {
              newModelValue = [...new Set([...this.modelValue, this.value])]; // merges two arrays without possible duplicate values
            } else {
              newModelValue.splice(newModelValue.indexOf(this.value), 1);
            }

            this.$emit('change', newModelValue);
            // for a single checkbox (=> v-model Boolean)
          } else {
            this.$emit('change', checked);
          }
        }
      });
    },
    isChecked() {
      // for a group of more related checkboxes (=> v-model Array)
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      // for a single checkbox (=> v-model Boolean)
      return !!this.modelValue;
    }
  }
};
</script>

<style lang="scss" module>
@import 'FormCheckbox.scss';
</style>
