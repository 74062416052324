// contains data for learning units, objectives and related stuff

import ApiService from '@/api/api.service';

import { GET_LEARNING_UNIT, GET_LEARNING_UNITS } from '@/store/actions.type';

export const state = {
  learningUnit: undefined,
  learningUnits: [],
  showCompleted: undefined,
  showCurrent: undefined,
  showUpcoming: undefined
};

export const getters = {
  showCompleted(state) {
    return state.showCompleted;
  },
  showCurrent(state) {
    return state.showCurrent;
  },
  showUpcoming(state) {
    return state.showUpcoming;
  }
};

export const actions = {
  [GET_LEARNING_UNITS](context, moduleVersionId = 'current') {
    return new Promise((resolve, reject) => {
      ApiService.getLearningUnits(moduleVersionId)
        .then(({ data }) => {
          context.commit('setLearningUnits', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_LEARNING_UNIT](context, learningUnitId) {
    return new Promise((resolve, reject) => {
      ApiService.getLearningUnit(learningUnitId)
        .then(({ data }) => {
          context.commit('setLearningUnit', data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  setLearningUnit(state, data) {
    state.learningUnit = data;
  },
  setLearningUnits(state, data) {
    state.learningUnits = data;
  },
  setShowCompleted(state, data) {
    state.showCompleted = data;
  },
  setShowCurrent(state, data) {
    state.showCurrent = data;
  },
  setShowUpcoming(state, data) {
    state.showUpcoming = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
