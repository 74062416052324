<template>
  <div :class="$style.wrapper" data-cy="assignment-type2">
    <!-- TYPE2 -->
    <Type2Header
      :assignment="assignment"
      :leadSession="leadSession"
      :nowISO="nowISO"
      data-cy="type2-header"
    />

    <div :class="$style.pageSection" v-if="featuresAssignmentResources">
      <h2>
        Resources
      </h2>

      <AssignmentResources
        class="box-shadow-white pt-1 pb-1 pl-2 pr-2"
        :assignment="assignment"
        :leadSession="leadSession"
      />
    </div>

    <div :class="{ [$style.pageSection]: true, [$style.withModal]: true }">
      <h2>
        Discussion
      </h2>

      <i
        @click="showModal = true"
        class="icon icon-help-circle"
        title="Show Help"
      />

      <Comments
        class="box-shadow-white pt-2 pb-2 pl-2 pr-2 overflow-visible"
        objectType="assignment"
        :objectId="leadSession.assignment_id"
      />
    </div>

    <ModalNew
      :class="$style.modalWrapper"
      :showModal="showModal"
      :closeWithClickOutside="true"
      v-transfer-dom
      @close="showModal = false"
    >
      <div :class="$style.description">
        <i class="icon icon-info-circle color-info" />

        <div>
          Discussion is where session-related questions or comments between the
          students and the facilitator can take place, either prior or after the
          class. When posting a comment, both the facilitator and the students
          will be notified via email. When replying to a comment - only people
          in the thread will be notified.
        </div>
      </div>
    </ModalNew>

    <div :class="{ [$style.gradingTitles]: true, [$style.pageSection]: true }">
      <h2>
        Grading
      </h2>
      <p v-if="rubricURL" class="text-bold">
        <a :href="rubricURL" target="_blank">
          See Grading Rubric
        </a>
      </p>
    </div>

    <InlineLoader v-if="!sessions.length" />

    <template v-else>
      <template
        v-for="(session, index) of sessions.filter(
          session => session.lead_assignment === false
        )"
      >
        <ContainerSession
          :key="`session-${session.scheduled_assignment_id}`"
          class="box-shadow-white"
          :data-cy="`container-session-${index}`"
          :session="session"
          :collapsed="shouldStartCollapsed(session)"
          :canPreview="canPreview(session)"
          :canEdit="canEdit(session)"
          :canPublish="canPublish(session)"
          :isPublished="isPublished(session)"
          :hasEdits="hasEdits(session)"
          :isInAction="isInAction"
          :nowISO="nowISO"
          v-slot="{ currentMode }"
        >
          <GradingSession
            :session="session"
            :currentMode="currentMode"
            @has_errors="handleErrors"
            @has_edits="handleEdits"
            @in_action="handleInAction"
          />
        </ContainerSession>
      </template>

      <h2>Submission</h2>
      <ContainerGroupWork
        :data-cy="`container-lead-session`"
        :session="leadSession"
        :collapsed="shouldStartCollapsed(leadSession) ? false : false"
        :canPreview="canPreview(leadSession)"
        :canEdit="canEdit(leadSession)"
        :canPublish="canPublish(leadSession)"
        :isPublished="isPublished(leadSession)"
        :hasEdits="hasEdits(leadSession)"
        :isInAction="isInAction"
        :nowISO="nowISO"
        v-slot="{ currentMode }"
        class="box-shadow-white"
      >
        <div
          v-for="(team, teamIndex) of teamsFromSession"
          :data-cy="`lead-session-team-${teamIndex}`"
          :key="teamIndex"
        >
          <div class="team" :key="`team-header-${team.slug}`">
            <h6>{{ team.object_name }}</h6>
          </div>

          <GradingGroupWork
            :key="`team-${team.slug}`"
            :currentMode="currentMode"
            :session="leadSession"
            :nowISO="nowISO"
            :userGroupId="team.user_group_id"
            @in_action="handleInAction"
            @has_edits="handleEdits"
          />
        </div>
      </ContainerGroupWork>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContainerSession from '../ContainerSession';
import ContainerGroupWork from '../ContainerGroupWork';

import Type2Header from './Type2Header';
import GradingSession from '../GradingSession';
import GradingGroupWork from './GradingGroupWork';

import InlineLoader from '@/components/common/InlineLoader/InlineLoader';
import AssignmentResources from '../AssignmentResources';
import Comments from '@/views/Comments/Comments';
import { isEvaluationPublished } from '@/helpers/grading';
import { removeDuplicateObjByKey } from '@/helpers/common';

export default {
  name: 'Type2',
  components: {
    InlineLoader,
    Type2Header,
    ContainerSession,
    ContainerGroupWork,
    GradingSession,
    GradingGroupWork,
    AssignmentResources,
    Comments
  },
  props: {
    focusAtId: {
      type: Number,
      default: NaN
    },
    nowISO: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      submissions: [],
      isLoading: true,
      showModal: false,
      isInAction: false,
      errors: [],
      edits: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserStudent', 'currentUserId']),
    ...mapGetters('grading', [
      'assignment',
      'sessions',
      'leadSession',
      'rubricURL',
      'featuresAssignmentResources'
    ]),
    teamsFromSession() {
      const allProfileTeams = this.userGroups.filter(
        p =>
          p.group_type === 'team' ||
          p.group_type === 'vc_group' ||
          p.group_type === 'cohort'
      );

      return removeDuplicateObjByKey(allProfileTeams, 'user_group_id');
    },
    userGroups() {
      if (!this.leadSession) return [];
      if (!this.leadSession.user_groups) return [];

      let filteredGroups = this.leadSession.user_groups;

      if (this.isCurrentUserStudent) {
        filteredGroups = this.leadSession.user_groups.filter(ug => {
          return ug.members.some(m => m.user_id === this.currentUserId);
        });
      }

      return filteredGroups.sort((itemA, itemB) => {
        const a = parseInt(itemA.user_group_id);
        const b = parseInt(itemB.user_group_id);

        if (Number.isNaN(a) || Number.isNaN(b)) return 0;

        if (a === b) return 0;

        return a < b ? -1 : 1;
      });
    }
  },
  methods: {
    canPreview(session) {
      return !this.hasErrors(session);
    },
    canEdit(session) {
      return !this.isPublished(session);
    },
    canPublish(session) {
      return !this.hasErrors(session) && !this.isPublished(session);
    },
    isPublished(session) {
      return isEvaluationPublished(session);
    },
    handleInAction(maybeInAction) {
      this.isInAction = maybeInAction;
    },
    shouldStartCollapsed(session) {
      return parseInt(session.scheduled_assignment_id) !== this.focusAtId;
    },
    hasErrors(session) {
      const saErrors = this.errors.filter(origin => {
        return origin.startsWith(`${session.scheduled_assignment_id}_`);
      });

      return saErrors.length > 0 ? true : false;
    },
    handleErrors(event) {
      if (event.has_errors === true) {
        this.errors.push(event.origin);
      } else {
        const foundItem = this.errors.find(e => e === event.origin);
        if (foundItem) {
          this.errors.splice(event.origin, 1);
        }
      }
    },
    hasEdits(session) {
      const saEdits = this.edits.filter(origin => {
        return origin.startsWith(`${session.scheduled_assignment_id}_`);
      });

      return saEdits.length > 0 ? true : false;
    },
    handleEdits(event) {
      if (event.has_edits === true) {
        this.edits.push(event.origin);
      } else {
        const foundItem = this.edits.find(e => e === event.origin);
        if (foundItem) {
          this.edits.splice(event.origin, 1);
        }
      }
    }
  }
};
</script>

<style lang="scss" module>
@import '../styles/Grading.scss';
</style>
