var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.modules,attrs:{"data-cy":"modules_nav"}},_vm._l((_vm.modulesFiltered),function(moduleObject,index){
var _obj;
return _c('div',{key:index,class:( _obj = {}, _obj[_vm.$style.module] = true, _obj[_vm.$style.active] = _vm.isActive(index, moduleObject), _obj[_vm.$style.inactive] = !_vm.isActive(index, moduleObject), _obj[_vm.$style.running] = moduleObject.module_version_id === _vm.runningModuleVersionId, _obj[_vm.$style.selected] = moduleObject.module_version_id === _vm.routeParamValue, _obj )},[_vm._t("default",function(){return [(_vm.isActive(index, moduleObject))?[_c('router-link',{attrs:{"to":{
            name: 'schedule-module-version-detail',
            params: {
              moduleVersionId: moduleObject.module_version_id
            },
            query: _vm.$route.query
          }}},[_vm._v(" "+_vm._s(moduleObject.name)+" ")])]:[_vm._v(" "+_vm._s(moduleObject.name)+" ")]]},{"moduleObject":moduleObject,"isActive":_vm.isActive(index, moduleObject)})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }