<template>
  <NotFound v-if="display404" />
  <Layout variant="2" v-else>
    <template #pageSideA>
      <div :class="$style.AdminBlogDetailSide" v-if="postDetail">
        <div :class="$style.sideHeader">
          <router-link to="/admin/blog/published" v-if="postDetail.published_at"
            ><i class="icon icon-chevron-left" /> Back to published</router-link
          >
          <router-link to="/admin/blog/drafts" v-else
            ><i class="icon icon-chevron-left" /> Back to Drafts</router-link
          >
        </div>
        <AdminSideWidgetBlogInfo
          :postDetail="postDetail"
          @reloadPost="fetchDetail()"
        />
      </div>
    </template>
    <template slot="pageContent">
      <div :class="$style.wrapper" v-if="postDetail">
        <div :class="$style.header">
          <div :class="$style.buttonsLeft">
            <h6 :class="$style.sideHeader">
              Blog detail
              <p class="adminSecondaryId">ID: {{ postDetail.post_id }}</p>
            </h6>
          </div>
          <div v-if="!loadingButtons" :class="$style.buttonsRight">
            <FormButton
              v-if="!postDetail.published_at"
              :standard="true"
              :class="{ [$style.greenButton]: true, [$style.publish]: true }"
              @click="handleOpenedPublish"
              :disabled="editMode"
              >Publish</FormButton
            >
            <FormButton v-else :ghost="true" @click="handleOpenedUnpublish"
              >Unpublish</FormButton
            >
            <FormButton
              v-if="!postDetail.published_at"
              :class="$style.remove"
              @click="handleOpenedDelete"
              :ghost="true"
              :disabled="editMode"
              ><i class="icon icon-trash-o"
            /></FormButton>

            <div :class="$style.homeButton">
              <span>| </span>
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <div
          class="box-shadow"
          :class="{
            [$style.default]: true,
            [$style.official]: hasTag('official'),
            [$style.saveTheDate]: hasTag('save-the-date'),
            [$style.platformUpdates]: hasTag('platform-news')
          }"
        />

        <div v-if="!editMode" class="box-shadow-white" :class="$style.content">
          <FormButton
            v-if="!editMode"
            :class="$style.editButton"
            :ghost="true"
            @click="editMode = true"
            ><i class="icon icon-pencil"
          /></FormButton>
          <h1 v-html="postDetail.title" />
          <p class="placeholder">Short description</p>
          <h6 v-html="postDetail.excerpt" />
          <p class="placeholder">Content</p>
          <p v-html="postDetail.content" />
          <p class="placeholder pt-1">Reactions and discussion</p>
          <p>
            {{ postDetail.feedback_enabled ? 'Allowed' : 'Not Allowed' }}
          </p>
        </div>

        <AdminEditBlog
          v-else
          @closeEditMode="editMode = false"
          @updateBlog="fetchDetail"
        />

        <!-- // MODALS // -->
        <ConfirmationModal
          :question="publishQuestion"
          @handleClosed="handleClosed"
          @agreedAction="publishBlog"
          :showModal="showPublishModal"
          :optionOne="'Publish'"
          :optionOneStyle="'green'"
        />
        <ConfirmationModal
          :question="unpublishQuestion"
          @handleClosed="handleClosed"
          @agreedAction="unpublishBlog"
          :showModal="showUnpublishModal"
        />
        <ConfirmationModal
          :question="deleteQuestion"
          @handleClosed="handleClosed"
          @agreedAction="deleteBlog"
          :showModal="showDeleteModal"
          :optionOne="'Delete'"
          :optionOneStyle="'red'"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '@/views/Layout/Layout';
import ConfirmationModal from '@/components/admin/ConfirmationModal/ConfirmationModal';
import AdminSideWidgetBlogInfo from '@/components/admin/AdminSideWidgetBlogInfo/AdminSideWidgetBlogInfo';
import AdminEditBlog from '@/views/Admin/AdminBlog/AdminEditBlog';

import {
  GET_ADMIN_BLOG_DETAIL,
  PUBLISH_ADMIN_BLOG,
  UNPUBLISH_ADMIN_BLOG,
  DELETE_ADMIN_BLOG
} from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    Layout,
    ConfirmationModal,
    AdminSideWidgetBlogInfo,
    AdminEditBlog
  },
  data() {
    return {
      loadingButtons: true,
      display404: false,
      postDetail: {},
      showUnpublishModal: false,
      showPublishModal: false,
      showDeleteModal: false,
      editMode: false,
      publishQuestion: 'Are you sure you want to publish this blog post?',
      unpublishQuestion: 'Are you sure you want to unpublish this blog post?',
      deleteQuestion: 'Are you sure you want to delete this blog post?'
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchDetail();
    }
  },
  beforeRouteLeave(to, from, next) {
    // prevent accidentaly leaving unsaved changes
    if (this.editMode) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      );
      answer ? next() : next(false);
    } else {
      next();
    }
  },
  methods: {
    fetchDetail() {
      this.$store
        .dispatch(GET_ADMIN_BLOG_DETAIL, this.$route.params.postId)
        .then(post => {
          this.postDetail = post;
          this.loadingButtons = false;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    publishBlog() {
      this.$store
        .dispatch(PUBLISH_ADMIN_BLOG, this.$route.params.postId)
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    unpublishBlog() {
      this.$store
        .dispatch(UNPUBLISH_ADMIN_BLOG, this.$route.params.postId)
        .then(() => {
          this.$router.push({
            name: 'admin-blog-drafts'
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    deleteBlog() {
      this.$store
        .dispatch(DELETE_ADMIN_BLOG, this.$route.params.postId)
        .then(() => {
          this.$router.push({
            name: 'admin-blog-drafts'
          });
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleOpenedUnpublish() {
      this.showUnpublishModal = true;
    },
    handleOpenedPublish() {
      this.showPublishModal = true;
    },
    handleOpenedDelete() {
      this.showDeleteModal = true;
    },
    handleClosed() {
      this.showUnpublishModal = false;
      this.showPublishModal = false;
      this.showDeleteModal = false;
    },
    hasTag(examinedTag) {
      if (this.postDetail && this.postDetail.tag_data) {
        return this.postDetail.tag_data.code == examinedTag;
      }
    }
  }
};
</script>

<style lang="scss" module>
@import './AdminBlog.scss';
@import '../AdminTables.scss';
</style>
