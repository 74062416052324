<template>
  <NotFound v-if="display404" />
  <Layout variant="5" v-else>
    <template #pageContent>
      <div :class="$style.wrapper">
        <h6>Recommended resources</h6>

        <div :class="$style.header">
          <div>
            <div :class="$style.buttonsLeft">
              <router-link
                to="/admin/resources/pending"
                class="btn ghost"
                :class="{
                  [$style.selected]: $route.name === 'admin-pending-resources'
                }"
                >Pending</router-link
              >
              <router-link
                to="/admin/resources/approved"
                class="btn ghost"
                :class="{
                  [$style.selected]: $route.name === 'admin-approved-resources'
                }"
                >Approved</router-link
              >
              <router-link
                to="/admin/resources/rejected"
                class="btn ghost"
                :class="{
                  [$style.selected]: $route.name === 'admin-rejected-resources'
                }"
                >Rejected</router-link
              >
            </div>
          </div>
          <div :class="$style.buttons">
            <div :class="$style.homeButton">
              <router-link to="/admin" :class="$style.button">
                <i class="icon icon-home"
              /></router-link>
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '@/views/Layout/Layout';
import NotFound from '@/components/common/NotFound/NotFound';

export default {
  components: {
    Layout,
    NotFound
  },
  data() {
    return {
      display404: false
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminRecommendedResources.scss';
</style>
