<template>
  <span :class="classes">
    <transition name="fadeInDown">
      <span v-if="password && password.length">
        {{ strength }}
      </span>
    </transition>
  </span>
</template>

<script>
import { checkPassStrength } from '@/helpers/password';

export default {
  props: {
    password: {
      type: String
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const classes = [this.$style.wrapper];

      if (this.isValid) {
        classes.push(this.$style.valid);
      }

      return classes;
    },
    strength() {
      return checkPassStrength(this.password).name;
    }
  }
};
</script>

<style lang="scss" module>
@import './FormInputPasswordStrength';
</style>
