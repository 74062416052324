import moment from 'moment';
import ApiService from '@/api/api.service';
import { RESET_STATE } from '@/store/mutations.type';
import { addObjToArray } from '@/helpers/common';
import { logError } from '@/helpers/errors';

export const state = {
  tasks: [],
  pageBy: 5,
  showPages: 1
};

const initialStateCopy = JSON.parse(JSON.stringify(state)); // must be non-reactive copy

export const getters = {
  tasks(state) {
    return state.tasks.filter(task => !task.deleted_at);
  },
  pageBy(state) {
    return state.pageBy;
  },
  showPages(state) {
    return state.showPages;
  }
};

export const mutations = {
  [RESET_STATE](state) {
    for (const prop in state) {
      if (Object.keys(initialStateCopy).includes(prop)) {
        state[prop] = initialStateCopy[prop];
      } else {
        state[prop] = undefined;
      }
    }
  },
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
  updateTask(state, { taskId, payload }) {
    const found = state.tasks.find(task => task.user_task_id === taskId);

    if (found) {
      state.tasks = addObjToArray(
        state.tasks,
        { ...found, ...payload },
        'user_task_id'
      );
    }
  },
  archiveTask(state, taskId) {
    const found = state.tasks.find(task => task.user_task_id === taskId);

    if (found) {
      state.tasks = addObjToArray(
        state.tasks,
        { ...found, ...{ deleted_at: moment().toISOString() } },
        'user_task_id'
      );
    }
  },
  setShowPages(state, numberOfPages) {
    state.showPages = numberOfPages;
  }
};

export const actions = {
  fetchTasks(context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getTasks(params)
        .then(({ data }) => {
          context.commit('setTasks', data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateTask(context, { taskId, payload }) {
    return new Promise((resolve, reject) => {
      ApiService.updateTask(taskId, payload)
        .then(() => {
          context.commit('updateTask', { taskId, payload });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  archiveTask(context, taskId) {
    return new Promise((resolve, reject) => {
      ApiService.archiveTask(taskId)
        .then(() => {
          context.commit('archiveTask', taskId);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
