<template>
  <div class="PasswordReset" role="main" :class="$style.wrapper">
    <SessionView>
      <template v-if="!emailSent">
        <p class="text-uppercase text-align-left paragraph-large text-bold">
          Password reset
        </p>

        <p class="pb-2 text-align-left">
          To reset your password, enter the email address you use to sign in.
        </p>

        <form aria-label="Enter your e-mail" @submit.prevent="onSubmit">
          <FormLabel>
            Your EDU e-mail
            <FormInput
              data-ref="email"
              v-model="email"
              :large="true"
              type="text"
            />
            <FormFieldErrors :errors="emailErrors" />
          </FormLabel>

          <formButton
            :disabled="disableButton ? true : null"
            :class="$style.btn"
            :large="true"
          >
            Get reset link
          </formButton>
        </form>
      </template>

      <template v-else>
        <p class="paragraph-large text-uppercase" :class="$style.titleCenter">
          Email sent!
        </p>
        <div :class="$style.circleGreen">
          <span class="icon icon-check" />
        </div>
        <p :class="$style.description">
          An email with password reset instructions has been sent to your email
          address.
        </p>

        <p class="form-text-additional">
          If you do not receive an email, please check your spam folder.
        </p>
        <p class="form-text-additional">
          <label for="tos">
            Still not successful?
            <a href="#" @click.prevent="emailSent = false">Try again</a> or
            <a href="mailto:support@edu.edu.mt">contact us</a>.
          </label>
        </p>
      </template>
    </SessionView>
  </div>
</template>

<script>
import { INITIATE_PASSWORD_RESET } from '@/store/actions.type';
import { SET_PASSWORD_RESET_TOKEN } from '@/store/mutations.type';
import { REGISTER_CONFIG } from '@/config/registration';

import focusMixin from '@/mixins/focusMixin';
import validatedEmailMixin from '@/mixins/validatedEmailMixin';

import SessionView from '@/components/common/SessionView/SessionView';

export default {
  components: {
    SessionView
  },
  mixins: [focusMixin, validatedEmailMixin],
  data() {
    return {
      emailSent: false,
      disableButton: false
    };
  },
  created() {
    if (this.$route.params.token) {
      this.$store.commit(SET_PASSWORD_RESET_TOKEN, this.$route.params.token);
      this.$router.push({ name: 'set-new-password' }).catch(() => {});
    }
  },
  methods: {
    checkForm() {
      this.errors = [];

      if (!this.validateEmail) {
        this.focusInput(this.$el.querySelector('[data-ref=email]'));
        this.errors.push(REGISTER_CONFIG.errors.email);
      }

      if (!this.errors.length) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.checkForm()) {
        this.disableButton = true;

        this.$store
          .dispatch(INITIATE_PASSWORD_RESET, this.email)
          .then(() => {
            this.emailSent = true;
          })
          .catch(() => {})
          .then(() => {
            this.disableButton = false;
          });
      }
    }
  }
};
</script>

<style module lang="scss">
@import './PasswordReset.scss';
</style>
