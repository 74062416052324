<template>
  <div :class="$style.wrapper">
    <h6 :class="$style.title">Actions</h6>
    <div :class="$style.actions">
      <div
        v-if="!checkForAdmin"
        :class="{ [$style.actionActive]: true, [$style.deleteUser]: true }"
      >
        <div
          v-if="!studentDeletePending"
          @click="handleDeleteUserModalOpened()"
        >
          <i class="icon icon-close" />
          Delete User
        </div>
        <div v-else :class="$style.actionPending">
          <i class="icon icon-hourglass-2" />
          Delete User (Pending)
        </div>
      </div>

      <div
        v-if="
          studentDetail && studentDetail.cohort && studentDetail.cohort.active
        "
        :class="$style.actionActive"
      >
        <div v-if="!studentOnHoldPending" @click="handleOnHoldModalOpened()">
          <i class="icon icon-hourglass-2" />
          Put student on hold
        </div>
        <div v-else :class="$style.actionPending">
          <i class="icon icon-hourglass-2" />
          Put student on hold (Pending)
        </div>
      </div>
      <div :class="$style.actionActive" @click="handleEmailModalOpened()">
        <transition name="fadeInUp">
          <span v-if="!emailSent" key="send" style="position: absolute;"
            ><i class="icon icon-envelope" />Send welcome email</span
          >
          <span v-else-if="emailSent" key="done" :class="$style.sentText"
            ><i class="icon icon-check" />Sent</span
          >
        </transition>
      </div>
      <div :class="$style.actionActive" @click="handlePwdModalOpened()">
        <transition name="fadeInUp">
          <span v-if="!pwdRequestSent" key="send" style="position: absolute;"
            ><i class="icon icon-envelope" />Send reset password e-mail</span
          >
          <span v-else-if="pwdRequestSent" key="done" :class="$style.sentText"
            ><i class="icon icon-check" />Sent</span
          >
        </transition>
      </div>
    </div>

    <ConfirmationModal
      :question="deleteUserQuestion"
      @handleClosed="handleDeleteUserModalClosed"
      @agreedAction="deleteUser()"
      :showModal="showDeleteUserModal"
      :optionOne="'Delete'"
      :optionOneStyle="'red'"
    />

    <ConfirmationModal
      :question="onHoldQuestion"
      @handleClosed="handleOnHoldModalClosed"
      @agreedAction="putStudentOnHold()"
      :showModal="showOnHoldModal"
      :optionOne="'Send request'"
      :optionOneStyle="'green'"
    />

    <ConfirmationModal
      :question="welcomeEmailQuestion"
      @handleClosed="handleEmailModalClosed"
      @agreedAction="sendWelcomeEmail()"
      :showModal="showEmailModal"
      :optionOne="'Send'"
    />

    <ConfirmationModal
      :question="pwdResetQuestion"
      @handleClosed="handlePwdModalClosed"
      @agreedAction="requestResetPassword()"
      :showModal="showPwdModal"
      :optionOne="'Send'"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
  GET_ADMIN_OPERATION_STATUS,
  REOPEN_ADMIN_OPERATION,
  SEND_USER_WELCOME_EMAIL,
  ADMIN_RESET_PASSWORD
} from '@/store/actions.type';

import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
import { OPERATIONS } from '@/config/admin';
import { ROLES } from '@/config/users';
import ConfirmationModal from '@/components/admin/ConfirmationModal/ConfirmationModal';

export default {
  components: {
    ConfirmationModal
  },
  data() {
    return {
      showOnHoldModal: false,
      showEmailModal: false,
      showPwdModal: false,
      showDeleteUserModal: false,
      emailSent: false,
      pwdRequestSent: false,
      studentOnHoldPending: null,
      studentDeletePending: null,
      deleteUserQuestion: 'Are you sure you want to delete this user?',
      welcomeEmailQuestion:
        'Are you sure you want to send this user a welcome email?',
      pwdResetQuestion:
        'Are you sure you want to send password reset email to this user?',
      onHoldQuestion: 'You are about to put this student on hold.'
    };
  },
  props: {
    studentDetail: {
      type: Object,
      required: false
    },
    userDetail: {
      type: Object,
      required: false
    }
  },
  created() {
    this.OPERATIONS = OPERATIONS;
    this.ROLES = ROLES;

    if (this.$route.params.userId) {
      this.fetchOperationStatus();
    }
  },
  computed: {
    checkForAdmin() {
      return (
        this.userDetail &&
        this.userDetail.role &&
        this.userDetail.role.code === this.ROLES.admin
      );
    }
  },
  methods: {
    ...mapActions([REOPEN_ADMIN_OPERATION]),
    requestResetPassword() {
      this.$store
        .dispatch(ADMIN_RESET_PASSWORD, this.$route.params.userId)
        .then(() => {
          this.showPwdModal = false;
          this.pwdRequestSent = true;

          setTimeout(() => {
            this.pwdRequestSent = false;
          }, 3000);
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    sendWelcomeEmail() {
      this.$store
        .dispatch(SEND_USER_WELCOME_EMAIL, this.$route.params.userId)
        .then(() => {
          this.showEmailModal = false;
          this.emailSent = true;

          setTimeout(() => {
            this.emailSent = false;
          }, 3000);
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    fetchOperationStatus() {
      this.$store
        .dispatch(GET_ADMIN_OPERATION_STATUS, this.$route.params.userId)
        .then(statuses => {
          if (statuses) {
            statuses.filter(status => {
              if (status && status.func == this.OPERATIONS.putStudentOnHold) {
                this.studentOnHoldPending = true;
              }

              if (status && status.func == this.OPERATIONS.deleteUser) {
                this.studentDeletePending = true;
              }
            });
          }
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    putStudentOnHold() {
      this.REOPEN_ADMIN_OPERATION({
        func: 'put_student_on_hold',
        objectType: 'user',
        objectId: Number(this.$route.params.userId),
        reference: this.$route.fullPath
      })
        .then(() => {
          this.showOnHoldModal = false;
          this.fetchOperationStatus();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    deleteUser() {
      this.REOPEN_ADMIN_OPERATION({
        func: 'delete_user',
        objectType: 'user',
        objectId: Number(this.$route.params.userId),
        reference: this.$route.fullPath
      })
        .then(() => {
          this.showDeleteUserModal = false;
          this.fetchOperationStatus();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleDeleteUserModalOpened() {
      this.showDeleteUserModal = true;
    },
    handleDeleteUserModalClosed() {
      this.showDeleteUserModal = false;
    },
    handlePwdModalOpened() {
      this.showPwdModal = true;
    },
    handlePwdModalClosed() {
      this.showPwdModal = false;
    },
    handleOnHoldModalOpened() {
      this.showOnHoldModal = true;
    },
    handleOnHoldModalClosed() {
      this.showOnHoldModal = false;
    },
    handleEmailModalOpened() {
      this.showEmailModal = true;
    },
    handleEmailModalClosed() {
      this.showEmailModal = false;
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminUserDetailActions.scss';
</style>
