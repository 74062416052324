var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.resource)?_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.wrapperContent},[(
        _vm.resource.type === 'pdf' ||
          _vm.resource.type === 'audio' ||
          _vm.resource.embedded_html
      )?_c('div',{class:_vm.$style.attachment},[(_vm.resource.type === 'pdf')?[_c('iframe',{attrs:{"src":_vm.setPathPDF(_vm.resource),"width":"100%","height":"700","data-cy":"resource_detail_pdf"}})]:(_vm.resource.type === 'audio')?[_c('iframe',{attrs:{"src":("/audio/web/audio.html?file=" + (_vm.resource.file.path)),"width":"100%"}})]:[_c('div',{domProps:{"innerHTML":_vm._s(_vm.resource.embedded_html)}})]],2):_vm._e(),(_vm.resource.tags)?_c('div',{class:_vm.$style.tags},[_c('p',{staticClass:"text-dark-grey"},[_vm._v("Filed under")]),_c('p',{staticClass:"text-dark-grey"},[_vm._v(_vm._s(_vm.tags))])]):_vm._e(),(_vm.isPhoneLayout && _vm.displayRelated)?_c('SideWidgetResourceDetail',{class:_vm.$style.resourceDetail,attrs:{"resource":_vm.resource}}):_vm._e(),(_vm.displayRelated)?_c('div',{staticClass:"box-shadow-white",class:_vm.$style.learningWrapper},[(_vm.hasLearningUnits)?[_c('div',{class:_vm.$style.header,on:{"click":function($event){return _vm.flipDisplayLUs(_vm.displayLUs)}}},[_c('p',{staticClass:"text-bold paragraph-large",class:_vm.$style.headerText},[_vm._v(" Related Learning Units ")]),(!_vm.displayLUs)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.displayLUs)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()]),_c('TransitionExpand',[(_vm.displayLUs)?_c('div',[_c('ul',{class:_vm.$style.list},_vm._l((_vm.resource.lo_units),function(unit,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
                    name: 'learning-unit-detail',
                    params: {
                      learningUnitId: unit.lo_unit_id
                    }
                  }}},[_vm._v(_vm._s(unit.name))])],1)}),0)]):_vm._e()])]:[_c('div',{class:_vm.$style.emptyHeader},[_c('p',{staticClass:"text-bold paragraph-large",class:_vm.$style.headerText},[_vm._v(" Related Learning Units ")]),_c('p',{staticClass:"placeholder",class:_vm.$style.text},[_vm._v(" There are no Learning Units to show for this Resource ")])])],(_vm.hasLearningObjectives)?[_c('div',{class:_vm.$style.header,on:{"click":function($event){return _vm.flipDisplayLOs(_vm.displayLOs)}}},[_c('p',{staticClass:"text-bold paragraph-large",class:_vm.$style.headerText},[_vm._v(" Related Learning Objectives ")]),(!_vm.displayLOs)?_c('i',{staticClass:"icon icon-chevron-down"}):_vm._e(),(_vm.displayLOs)?_c('i',{staticClass:"icon icon-chevron-up"}):_vm._e()]),_c('TransitionExpand',[(_vm.displayLOs)?_c('div',[_c('ul',{class:_vm.$style.list},_vm._l((_vm.resource.learning_objectives),function(objective,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
                    name: 'library',
                    query: {
                      objective: objective.learning_objective_id
                    }
                  }}},[_vm._v(_vm._s(objective.name))])],1)}),0)]):_vm._e()])]:[_c('div',{class:_vm.$style.emptyHeader},[_c('p',{staticClass:"text-bold paragraph-large",class:_vm.$style.headerText},[_vm._v(" Related Learning Objectives ")]),_c('p',{staticClass:"placeholder",class:_vm.$style.text},[_vm._v(" There are no Learning Objectives to show for this Resource ")])])]],2):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }