var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.display404)?_c('NotFound',[_c('p',[_vm._v("This topic doesn't exist")]),_c('p',[_vm._v(" Go to "),_c('router-link',{attrs:{"to":{
          name: 'forum-topics',
          params: {
            forumSlug: _vm.$route.params.forumSlug
          }
        }}},[_vm._v(" list of topics ")])],1)]):_c('Layout',{attrs:{"variant":"5"},scopedSlots:_vm._u([{key:"pageContent",fn:function(){return [_c('div',{staticClass:"box-shadow-white overflow-visible"},[(_vm.posts && _vm.posts.length <= 1)?[_c('TopicIntroductionPost',{class:_vm.$style.roundBorder,attrs:{"topic":_vm.topic,"post":_vm.posts[0]},on:{"subscribe":_vm.subscribeTopic,"unsubscribe":_vm.unsubscribeTopic}})]:(_vm.posts && _vm.posts.length)?[_c('TopicIntroductionPost',{attrs:{"topic":_vm.topic,"post":_vm.posts[0]},on:{"subscribe":_vm.subscribeTopic,"unsubscribe":_vm.unsubscribeTopic}}),_vm._l((_vm.posts.slice(1)),function(post){return _c('TopicPost',{key:post.post_id,class:_vm.$style.post,attrs:{"post":post,"id":("id-" + (post.post_id))}})})]:_vm._e()],2),_c('div',{staticClass:"box-shadow-white",class:_vm.$style.replyForm},[_c('h6',[_vm._v("your reply")]),_c('Editor',{attrs:{"config":{
            toolbar: {
              viewportTopOffset:
                _vm.userSettingValue(_vm.SETTING_CODES.FIXED_NAV) === true ? 0 : 80,
              items: [
                'heading',
                '|',
                'Bold',
                'Italic',
                'bulletedList',
                'blockQuote',
                'link',
                '|',
                'Undo',
                'Redo'
              ]
            }
          }},model:{value:(_vm.newPostContent),callback:function ($$v) {_vm.newPostContent=$$v},expression:"newPostContent"}}),_c('div',{class:_vm.$style.replyBtn},[_c('FormButton',{attrs:{"standard":true},on:{"click":function($event){$event.preventDefault();return _vm.sendPost.apply(null, arguments)}}},[_c('i',{staticClass:"icon icon-mail-reply"}),_vm._v(" Post new reply ")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }