<template>
  <SideWidget :title="false">
    <template #content>
      <div :class="$style.wrapper">
        <AdminUserInfo
          :class="$style.info"
          :user="postDetail.author"
          :title="'Author'"
        />
        <div :class="$style.basic" class="box-shadow-white overflow-visible">
          <p class="text-bold" :class="$style.subtitle">Published at:</p>
          <div :class="$style.date" v-if="postDetail.published_at">
            {{ publishedAt }}
          </div>
          <div :class="$style.date" v-else class="text-dark-grey">
            Not published yet
          </div>
          <div :class="$style.tagsTitle">
            <p class="text-bold" :class="$style.subtitle">Category:</p>
            <p
              @click="switchTagEditMode()"
              v-if="!tagEditMode"
              class="text-semibold"
              :class="$style.edit"
            >
              <i class="icon icon-pencil" />
            </p>
            <p
              @click="switchTagEditMode()"
              v-else
              :class="$style.edit"
              class="text-semibold"
            >
              Done
            </p>
          </div>
          <FilterSelect
            v-if="tagEditMode"
            :submenuVisible="tagSubmenuVisible"
            @submenu_visibility="selectTag"
            :class="$style.tags"
          >
            <template #title>
              <span v-if="selectedTag">{{ selectedTag.label }}</span>
              <span v-else-if="postDetail.tag_data">{{
                postDetail.tag_data.code
              }}</span>
              <span v-else>{{ 'Select a Category' }}</span>
            </template>
            <template #items>
              <fieldset
                v-for="tag in tags"
                :value="tag.label"
                :key="tag.label"
                @click="handleTagChange(tag)"
              >
                <FormLabel>
                  {{ tag.label }}
                </FormLabel>
              </fieldset>
            </template>
          </FilterSelect>
          <div v-else>
            <div v-if="postDetail.tag_data" :class="$style.tag">
              # {{ postDetail.tag_data.code || selectedTag.label }}
            </div>
            <div v-else :class="$style.tag" class="text-dark-grey">
              Not tagged
            </div>
          </div>
          <div :class="$style.cohortsTitle">
            <p class="text-bold" :class="$style.subtitle">Visible for:</p>
            <p
              @click="switchCohortsEditMode()"
              v-if="!cohortEditMode"
              class="text-semibold"
              :class="$style.edit"
            >
              <i class="icon icon-pencil" />
            </p>
            <p
              @click="switchCohortsEditMode()"
              v-else
              :class="$style.edit"
              class="text-semibold"
            >
              Done
            </p>
          </div>
          <span v-if="!cohortEditMode">
            <div v-if="cohorts && cohorts.length" :class="$style.cohorts">
              <div v-for="cohort in cohorts" :key="cohort.cohort_id">
                <router-link to="" class="text-semibold"
                  >{{ cohort.name
                  }}<span class="adminSecondaryId">{{
                    cohort.cohort_id
                  }}</span></router-link
                >
              </div>
            </div>
            <div v-else class="text-dark-grey" :class="$style.cohorts">
              Everyone
            </div>
          </span>
          <AdminCohortPicker
            :currentCohortsFromBE="postDetail.cohorts"
            v-else
          />
        </div>
      </div>
    </template>
  </SideWidget>
</template>

<script>
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

import SideWidget from '@/components/common/SideWidget/SideWidget';
import AdminUserInfo from '@/components/admin/AdminUserInfo/AdminUserInfo';
import AdminCohortPicker from '@/components/admin/AdminCohortPicker/AdminCohortPicker';
import FilterSelect from '@/components/common/FilterSelect/FilterSelect';

import {
  GET_ADMIN_USER_DETAIL,
  GET_ADMIN_COHORTS,
  GET_BLOG_POST_TAGS,
  UPDATE_TAG_ADMIN_BLOG_DETAIL
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    SideWidget,
    AdminUserInfo,
    AdminCohortPicker,
    FilterSelect
  },
  props: {
    postDetail: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      cohorts: [],
      tags: [],
      cohortEditMode: false,
      tagEditMode: false,
      selectedTag: null,
      tagSubmenuVisible: false
    };
  },
  created() {
    this.$watch(
      'postDetail',
      function() {
        this.fetchCohorts();
        this.fetchTags();
      },
      { immediate: false }
    );
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff']),
    publishedAt() {
      return moment(this.postDetail.published_at).format('DD.MM.yyyy, HH:mm');
    }
  },
  methods: {
    ...mapActions([UPDATE_TAG_ADMIN_BLOG_DETAIL]),
    fetchCohorts() {
      this.cohorts = [];
      if (this.postDetail) {
        this.$store.dispatch(GET_ADMIN_COHORTS).then(cohorts => {
          cohorts.filter(cohort => {
            if (this.postDetail.cohorts.includes(cohort.cohort_id)) {
              this.cohorts.push(cohort);
            }
          });
        });
      }
    },
    switchCohortsEditMode() {
      if (this.cohortEditMode == true) {
        this.cohortEditMode = false;
        this.$emit('reloadPost');
      } else {
        this.cohortEditMode = true;
      }
    },
    switchTagEditMode() {
      if (this.tagEditMode == true) {
        this.tagEditMode = false;
        this.updateTag();
      } else {
        this.tagEditMode = true;
      }
    },
    fetchTags() {
      this.$store
        .dispatch(GET_BLOG_POST_TAGS, {
          category: 'blog_posts'
        })
        .then(tags => {
          this.tags = tags;
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    updateTag() {
      this.$store
        .dispatch(UPDATE_TAG_ADMIN_BLOG_DETAIL, {
          postId: this.$route.params.postId,
          tagId: this.selectedTag.tag_id
        })
        .then(() => {
          this.$emit('reloadPost');
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    handleTagChange(tag) {
      this.selectedTag = tag;
      this.tagSubmenuVisible = false;
    },
    selectTag(newSubmenuState) {
      this.tagSubmenuVisible = newSubmenuState;
    }
  }
};
</script>

<style module lang="scss">
@import './AdminSideWidgetBlogInfo.scss';
</style>
