<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :class="$style.table"
      @on-column-filter="updateFilters"
      @on-sort-change="onSortChange"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'rejectedAt', type: 'desc' }
      }"
      :pagination-options="{
        enabled: true
      }"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'operationId'">
          <p class="adminPrimaryId">{{ props.row.operationId }}</p>
        </span>
        <span v-else-if="props.column.field === 'reference'">
          <a
            :href="
              props.row.reference.startsWith('#')
                ? props.row.reference
                : `#${props.row.reference}`
            "
            target="_blank"
            >Visit Page</a
          >
        </span>
        <span v-else-if="props.column.field === 'requestedBy'">
          <router-link
            :to="{
              name: 'user-profile',
              params: { userId: props.row.userId }
            }"
          >
            {{ props.row.requestedBy }}
            <p class="adminSecondaryId">{{ props.row.userId }}</p>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'rejectedBy'">
          <router-link
            :to="{
              name: 'user-profile',
              params: { userId: props.row.operationById }
            }"
          >
            {{ props.row.rejectedBy }}
            <p class="adminSecondaryId">{{ props.row.rejectedId }}</p>
          </router-link>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { VueGoodTable } from 'vue-good-table';
import { GET_REJECTED_OPERATIONS } from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';
export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      columns: [
        {
          label: 'ID',
          type: 'number',
          field: 'operationId',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('operationId')
          }
        },
        {
          label: 'Type',
          field: 'function',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('function')
          }
        },
        {
          label: 'Requested by',
          field: 'requestedBy',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('requestedBy')
          }
        },
        {
          label: 'Created at',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('createdAt')
          }
        },
        {
          label: 'Rejected by',
          field: 'rejectedBy',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('rejectedBy')
          }
        },
        {
          label: 'Rejected at',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          field: 'rejectedAt',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('rejectedAt')
          }
        },
        {
          label: 'Reference',
          field: 'reference'
        }
      ],
      rows: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.fetchOperations();
    }
  },
  methods: {
    fetchOperations() {
      this.$store
        .dispatch(GET_REJECTED_OPERATIONS)
        .then(operations => {
          this.operations = operations;
        })
        .then(operations => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    createRows() {
      if (!this.operations) return [];

      this.operations.filter(operation => {
        const apr = operation.rejected_by;
        const req = operation.requested_by;
        this.rows.push({
          operationId: operation.operation_id || '',
          function: operation.func || '',
          requestedBy:
            (req && req.name.first_name) + ' ' + (req && req.name.last_name) ||
            '',
          userId: (req && req.user_id) || '',
          createdAt: moment(operation.created_at).format('DD.MM.yyyy') || '',
          rejectedBy:
            (apr && apr.name.first_name) + ' ' + (apr && apr.name.last_name) ||
            '',
          rejectedAt:
            moment(operation.deleted_at).format('DD.MM.yyyy HH:mm') || '',
          rejectedId: apr && apr.user_id,
          operationById: (apr && apr.user_id) || '',
          reference: operation.object.reference || ''
        });
      });
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {};
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'operationId':
          return this.$route.query.operationId;
          break;
        case 'function':
          return this.$route.query.function;
          break;
        case 'requestedBy':
          return this.$route.query.requestedBy;
          break;
        case 'rejectedBy':
          return this.$route.query.rejectedBy;
          break;
        case 'rejectedAt':
          return this.$route.query.rejectedAt;
          break;
        case 'date':
          return this.$route.query.date;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        operationId: column.operationId || [],
        function: column.function || [],
        requestedBy: column.requestedBy || [],
        rejectedBy: column.rejectedBy || [],
        rejectedAt: column.rejectedAt || [],
        date: column.date || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminOperations.scss';
</style>
