<template>
  <div :class="$style.wrapper">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :class="$style.table"
      :sort-options="{
        enabled: true,
        initialSortBy: loadUrlWithSorting()
      }"
      :pagination-options="{
        enabled: true,
        infoFn: () => {}
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="updateFilters"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'postId'">
          <router-link
            :to="{
              name: 'admin-blog-detail',
              params: { postId: props.row.postId }
            }"
            class="adminPrimaryId"
            >{{ props.row.postId }}</router-link
          >
        </span>
        <span v-else-if="props.column.field === 'postAuthor'">
          <router-link
            v-if="props.row.postAuthor"
            :to="{
              name: 'admin-user-detail',
              params: { userId: props.row.postAuthorId }
            }"
            >{{ props.row.postAuthor }}
            <span class="adminSecondaryId">{{ props.row.postAuthorId }}</span>
          </router-link>
          <span v-else>-</span>
        </span>

        <span v-else-if="props.column.field === 'postTitle'">
          <router-link
            :to="{
              name: 'admin-blog-detail',
              params: { postId: props.row.postId }
            }"
            >{{ props.row.postTitle }}
          </router-link>
        </span>

        <span
          v-else-if="props.column.field === 'postCohorts'"
          v-line-clamp:1="1"
        >
          <span v-if="props.row.postCohorts == 'All'">All</span>
          <span v-else v-for="(cohort, i) in props.row.postCohorts" :key="i">
            <router-link
              :to="{
                name: 'admin-cohort-detail',
                params: { cohortId: cohort[1] }
              }"
              >{{ cohort[0] }}
              <span class="adminSecondaryId">{{ cohort[1] }}</span></router-link
            >
            <span v-if="i != props.row.postCohorts.length - 1" class="bold"
              >,
            </span></span
          >
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';

import {
  GET_ADMIN_BLOG_PUBLISHED,
  GET_ADMIN_COHORTS
} from '@/store/actions.type';
import { SET_GLOBAL_ERROR } from '@/store/mutations.type';

export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      display404: false,
      columns: [
        {
          label: 'Post ID',
          field: 'postId',
          type: 'number',
          width: '90px',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postId')
          }
        },
        {
          label: 'Title',
          field: 'postTitle',
          tdClass: 'postTitle',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postTitle')
          }
        },
        {
          label: 'Tag',
          field: 'postTag',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postTag')
          }
        },
        {
          label: 'Cohorts',
          field: 'postCohorts',
          tdClass: 'postCohorts',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postCohorts')
          }
        },
        {
          label: 'Published at',
          field: 'publishedAt',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy - HH:mm',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('publishedAt')
          }
        },
        {
          label: 'Author',
          field: 'postAuthor',
          filterOptions: {
            enabled: true,
            filterValue: this.loadUrlWithFilters('postAuthor')
          }
        }
      ],
      rows: [],
      published: [],
      allCohorts: []
    };
  },
  computed: {
    ...mapGetters(['isCurrentUserTeachingStaff'])
  },
  async created() {
    if (!this.isCurrentUserTeachingStaff) {
      this.display404 = true;
    } else {
      this.$store
        .dispatch(GET_ADMIN_COHORTS)
        .then(cohorts => {
          this.allCohorts = cohorts;
        })
        .then(() => {
          this.fetchPublished();
        });
    }
  },
  methods: {
    fetchPublished() {
      this.$store
        .dispatch(GET_ADMIN_BLOG_PUBLISHED)
        .then(published => {
          this.published = published;
        })
        .then(published => {
          this.createRows();
        })
        .catch(error => {
          this.$store.commit(SET_GLOBAL_ERROR, { error, log: true });
        });
    },
    onSortChange(sort) {
      var sorting = {
        sort: sort[0].field || [],
        order: sort[0].type || []
      };

      return sort[0].type === 'none'
        ? this.updateQueryFilters(this.searchParams)
        : this.updateQueryFilters({ ...this.searchParams, ...sorting });
    },
    loadUrlWithSorting() {
      if (this.$route && this.$route.query && this.$route.query.order) {
        return {
          field: this.$route.query.sort,
          type: this.$route.query.order
        };
      }

      return {
        field: 'publishedAt',
        type: 'desc'
      };
    },
    loadUrlWithFilters(param) {
      switch (param) {
        case 'postId':
          return this.$route.query.postId;
          break;
        case 'postTitle':
          return this.$route.query.postTitle;
          break;
        case 'postTag':
          return this.$route.query.postTag;
          break;
        case 'postCohorts':
          return this.$route.query.postCohorts;
          break;
        case 'publishedAt':
          return this.$route.query.publishedAt;
          break;
        case 'postAuthor':
          return this.$route.query.postAuthor;
          break;
        case 'postAuthorId':
          return this.$route.query.postAuthorId;
          break;
        default:
          return '';
      }
    },
    updateFilters(filterString) {
      var column = filterString.columnFilters;

      this.searchParams = {
        postId: column.postId || [],
        postTitle: column.postTitle || [],
        postTag: column.postTag || [],
        postCohorts: column.postCohorts || [],
        publishedAt: column.publishedAt || [],
        postAuthor: column.postAuthor || [],
        postAuthorId: column.postAuthorId || []
      };

      this.updateQueryFilters(this.searchParams);
    },
    updateQueryFilters(newQuery) {
      this.$router.replace({ query: newQuery }).catch(() => {});
    },
    createRows() {
      this.rows = [];

      if (!this.published || !this.published.length) return [];

      this.published.filter(post => {
        this.rows.push({
          postId: post.post_id || '',
          postTitle: post.title || '',
          postTag: (post.tag_data && post.tag_data.code) || '',
          postCohorts: this.filterCohorts(post.cohorts) || '',
          publishedAt:
            moment(post.published_at).format('DD.MM.yyyy HH:mm') || '',
          postAuthor: this.authorName(post) || '',
          postAuthorId: this.authorId(post) || ''
        });
      });
    },
    filterCohorts(cohorts) {
      if (!this.allCohorts) return [];
      if (!cohorts) return 'All';

      var res = [];
      this.allCohorts.filter(cohort => {
        if (cohorts && cohorts.includes(cohort.cohort_id)) {
          res.push([cohort.name, cohort.cohort_id]);
        }
      });

      return res;
    },
    authorName(post) {
      if (post && post.author) {
        return post.author.first_name + ' ' + post.author.last_name;
      }

      return '';
    },
    authorId(post) {
      if (post && post.author) {
        return post.author.user_id;
      }

      return '';
    }
  }
};
</script>

<style lang="scss" module>
@import '../AdminTables.scss';
@import './AdminBlog.scss';
</style>
