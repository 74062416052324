import axios from 'axios';
import ApiService from '@/api/api.service';

import {
  SAVE_GRADING,
  REMOVE_GRADING,
  CHECK_MISSING_MANDATORY_VALUES,
  RESET_MANDATORY_VALUES_CHECK
} from '@/store/actions.type';

export default {
  namespaced: true,
  state() {
    return {
      uploadFeedbackGradeId: undefined,
      uploadFeedbackGrade: undefined,
      uploadFeedbackFile: undefined,
      uploadFeedbackGradeMandatory: undefined,
      uploadFeedbackGradeIsMissing: undefined,
      textFeedbackGradeId: undefined,
      textFeedbackGrade: undefined,
      textFeedbackGradeMandatory: undefined,
      textFeedbackGradeIsMissing: undefined,
      scoreGradeId: undefined,
      scoreGrade: undefined,
      scoreGradeMin: undefined,
      scoreGradeMax: undefined,
      scoreGradeMandatory: undefined,
      scoreGradeIsMissing: undefined
    };
  },
  getters: {
    uploadFeedbackGradeId(state) {
      return state.uploadFeedbackGradeId;
    },
    uploadFeedbackGrade(state) {
      return state.uploadFeedbackGrade;
    },
    uploadFeedbackFile(state) {
      return state.uploadFeedbackFile;
    },
    uploadFeedbackGradeMandatory(state) {
      return state.uploadFeedbackGradeMandatory;
    },
    uploadFeedbackGradeIsMissing(state) {
      return state.uploadFeedbackGradeIsMissing;
    },
    textFeedbackGradeId(state) {
      return state.textFeedbackGradeId;
    },
    textFeedbackGrade(state) {
      return state.textFeedbackGrade;
    },
    textFeedbackGradeMandatory(state) {
      return state.textFeedbackGradeMandatory;
    },
    textFeedbackGradeIsMissing(state) {
      return state.textFeedbackGradeIsMissing;
    },
    scoreGradeId(state) {
      return state.scoreGradeId;
    },
    scoreGrade(state) {
      return state.scoreGrade;
    },
    scoreGradeMin(state) {
      return state.scoreGradeMin;
    },
    scoreGradeMax(state) {
      return state.scoreGradeMax;
    },
    scoreGradeMinIsValid(state) {
      if (state.scoreGradeMin === undefined) return undefined;

      if (isNaN(parseFloat(state.scoreGradeMin))) return false;
      if (isNaN(parseFloat(state.scoreGradeMax))) return false;

      return parseFloat(state.scoreGradeMin) < parseFloat(state.scoreGradeMax);
    },
    scoreGradeMaxIsValid(state) {
      if (state.scoreGradeMax === undefined) return undefined;

      if (isNaN(parseFloat(state.scoreGradeMax))) return false;
      if (isNaN(parseFloat(state.scoreGradeMin))) return false;

      return parseFloat(state.scoreGradeMax) > parseFloat(state.scoreGradeMin);
    },
    scoreGradeIsMissing(state) {
      return state.scoreGradeIsMissing;
    },
    hasEdits(state) {
      if ([true, false].includes(state.attendanceGrade)) return true;
      if (state.uploadFeedbackGrade) return true;
      if (state.uploadFeedbackFile) return true;
      if (state.textFeedbackGrade) return true;
      if (state.scoreGrade) return true;

      return false;
    }
  },
  actions: {
    RESET_MANDATORY_VALUES_CHECK: {
      root: false,
      handler({ commit }) {
        return new Promise((resolve, reject) => {
          commit('textFeedbackGradeIsMissingSet', undefined);
          commit('uploadFeedbackGradeIsMissingSet', undefined);
          commit('scoreGradeIsMissingSet', undefined);
          resolve();
        });
      }
    },
    CHECK_MISSING_MANDATORY_VALUES: {
      root: false,
      handler({ state, commit }, payload) {
        // console.warn('payload', payload);

        return new Promise((resolve, reject) => {
          let anythingMissing = [];

          commit('textFeedbackGradeIsMissingSet', undefined);
          if (
            state.textFeedbackGradeMandatory === true &&
            (!state.textFeedbackGrade || state.textFeedbackGrade.length < 1)
          ) {
            commit('textFeedbackGradeIsMissingSet', true);
            anythingMissing.push(true);
          } else {
            commit('textFeedbackGradeIsMissingSet', false);
          }

          commit('uploadFeedbackGradeIsMissingSet', undefined);
          if (
            state.uploadFeedbackGradeMandatory === true &&
            (!state.uploadFeedbackGrade || state.uploadFeedbackGrade.length < 1)
          ) {
            commit('uploadFeedbackGradeIsMissingSet', true);
            anythingMissing.push(true);
          } else {
            commit('uploadFeedbackGradeIsMissingSet', false);
          }

          commit('scoreGradeIsMissingSet', undefined);
          if (
            state.scoreGradeMandatory === true &&
            Number.isNaN(parseFloat(state.scoreGrade))
          ) {
            commit('scoreGradeIsMissingSet', true);
            anythingMissing.push(true);
          } else {
            commit('scoreGradeIsMissingSet', false);
          }

          resolve(anythingMissing.some(result => result));
        });
      }
    },
    [SAVE_GRADING](
      context,
      {
        assignmentId = undefined,
        scheduledAssignmentId = undefined,
        payload = {}
      }
    ) {
      if (!assignmentId)
        return Promise.reject('No assignmentId. Action aborted.');
      if (!scheduledAssignmentId)
        return Promise.reject('No scheduledAssignmentId. Action aborted.');
      if (!payload || !Object.values(payload).length) {
        return Promise.reject('No payload. Action aborted.');
      }

      return new Promise((resolve, reject) => {
        ApiService.saveGrading(assignmentId, scheduledAssignmentId, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [REMOVE_GRADING](
      context,
      {
        assignmentId = undefined,
        scheduledAssignmentId = undefined,
        gradeId = undefined,
        userGroupId = undefined
      }
    ) {
      if (!assignmentId)
        return Promise.reject('No assignmentId. Action aborted.');
      if (!scheduledAssignmentId)
        return Promise.reject('No scheduledAssignmentId. Action aborted.');
      if (!gradeId) {
        return Promise.reject('No gradeId. Action aborted.');
      }
      if (!userGroupId) {
        return Promise.reject('No userGroupId. Action aborted.');
      }

      return new Promise((resolve, reject) => {
        ApiService.removeGrading(
          assignmentId,
          scheduledAssignmentId,
          gradeId,
          userGroupId
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    uploadFeedbackGradeIdSet(state, value) {
      state.uploadFeedbackGradeId = value;
    },
    uploadFeedbackGradeSet(state, value) {
      state.uploadFeedbackGrade = value;
    },
    uploadFeedbackFileSet(state, value) {
      state.uploadFeedbackFile = value;
    },
    uploadFeedbackGradeMandatorySet(state, value) {
      state.uploadFeedbackGradeMandatory = value;
    },
    uploadFeedbackGradeIsMissingSet(state, value) {
      state.uploadFeedbackGradeIsMissing = value;
    },
    textFeedbackGradeIdSet(state, value) {
      state.textFeedbackGradeId = value;
    },
    textFeedbackGradeSet(state, value) {
      state.textFeedbackGrade = value;
    },
    textFeedbackGradeMandatorySet(state, value) {
      state.textFeedbackGradeMandatory = value;
    },
    textFeedbackGradeIsMissingSet(state, value) {
      state.textFeedbackGradeIsMissing = value;
    },
    scoreGradeIdSet(state, value) {
      state.scoreGradeId = value;
    },
    scoreGradeSet(state, value) {
      state.scoreGrade = value;
    },
    scoreGradeMinSet(state, value) {
      state.scoreGradeMin = value;
    },
    scoreGradeMaxSet(state, value) {
      state.scoreGradeMax = value;
    },
    scoreGradeMandatorySet(state, value) {
      state.scoreGradeMandatory = value;
    },
    scoreGradeIsMissingSet(state, value) {
      state.scoreGradeIsMissing = value;
    }
  }
};
