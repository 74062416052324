// Mapping of our constants to API setting codes

export const SETTING_CODES = {
  FIXED_NAV: 'static_main_nav',
  MSG_NOTIFICATION: 'new_msg_notif_enabled', // TODO: deprecated, to be removed
  TRANSLATIONS_ENABLED: 'translations_enabled', // TODO: deprecated, to be removed
  FLEXIBLE_QUIZ_ENABLED: 'flexible_quiz_enabled', // TODO: deprecated, to be removed
  NOTIFY_SESSION_NOTES: 'email_notify_session_notes',
  NOTIFY_DISCUSSIONS: 'email_notify_discussions'
};
